import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';
import {
  AUTH_TYPE,
  HERE_TOKEN_ENDPOINTS,
} from '../../views/settingsPanel/constants';
import credentials from 'config/credentials';

export const DEFAULT_PRESET = 'PRODUCTION';

const SETTINGS = {
  [modulesEnum.ROUTING]: {
    currentPreset: 'PRODUCTION_APIKEY',
    [formsEnum.CALCULATE_ROUTE]: {
      PRODUCTION: {
        title: 'Production - appId, appCode',
        host: 'route.ls.hereapi.com',
        path: '/routing/7.2/calculateroute.json',
        useSSL: true,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: credentials.PRODUCTION.app_id,
          app_code: credentials.PRODUCTION.app_code,
          settingsPresetType: 'PRODUCTION',
        },
      },
//////////////////////
///////////////////
////////////////////////////
/////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////
/////////////////////
///////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////
///////////////////////////
////////////////////
///////////////////////////
//////////////////////////////
/////////////////////
///////////////////////
/////////////////////////////////////////
////////////////////////////////////
//////////
////////
////////////////
      CUSTOM: {
        title: 'Custom',
        host: 'localhost:5000',
        path: '/routing/7.2/calculateroute.json',
        useSSL: false,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: '',
          app_code: '',
          settingsPresetType: 'CUSTOM',
        },
      },
    },
    [formsEnum.CALCULATE_MATRIX]: {
      PRODUCTION: {
        title: 'Production - appId, appCode',
        host: 'matrix.route.ls.hereapi.com',
        path: '/routing/7.2/calculatematrix.json',
        useSSL: true,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: credentials.PRODUCTION.app_id,
          app_code: credentials.PRODUCTION.app_code,
          settingsPresetType: 'PRODUCTION',
        },
      },
//////////////////////
///////////////////
////////////////////////////
////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////
/////////////////////
///////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////
///////////////////////////
////////////////////
///////////////////////////
//////////////////////////////
/////////////////////
///////////////////////
/////////////////////////////////////////
////////////////////////////////////
//////////
////////
////////////////
      CUSTOM: {
        title: 'Custom',
        host: 'localhost:5000',
        path: '/routing/7.2/calculatematrix.json',
        useSSL: false,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: '',
          app_code: '',
          settingsPresetType: 'CUSTOM',
        },
      },
    },
    [formsEnum.CALCULATE_ISOLINE]: {
      PRODUCTION: {
        title: 'Production - appId, appCode',
        host: 'isoline.route.ls.hereapi.com',
        path: '/routing/7.2/calculateisoline.json',
        useSSL: true,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: credentials.PRODUCTION.app_id,
          app_code: credentials.PRODUCTION.app_code,
          settingsPresetType: 'PRODUCTION',
        },
      },
//////////////////////
///////////////////
////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////
/////////////////////
///////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////
///////////////////////////
////////////////////
///////////////////////////
//////////////////////////////
/////////////////////
///////////////////////
/////////////////////////////////////////
////////////////////////////////////
//////////
////////
////////////////
      CUSTOM: {
        title: 'Custom',
        host: 'localhost:5000',
        path: '/routing/7.2/calculateisoline.json',
        useSSL: false,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: '',
          app_code: '',
          settingsPresetType: 'CUSTOM',
        },
      },
    },
    [formsEnum.GET_ROUTE_INFO]: {
      PRODUCTION: {
        title: 'Production - appId, appCode',
        host: 'route.api.here.com',
        path: '/routing/7.2/getroute.json',
        useSSL: true,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: credentials.PRODUCTION.app_id,
          app_code: credentials.PRODUCTION.app_code,
          settingsPresetType: 'PRODUCTION',
        },
      },
//////////////////////
///////////////////
////////////////////////////
///////////////////////////////////
///////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////
/////////////////////
///////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////
///////////////////////////
////////////////////
///////////////////////////
//////////////////////////////
/////////////////////
///////////////////////
/////////////////////////////////////////
////////////////////////////////////
//////////
////////
////////////////
      CUSTOM: {
        title: 'Custom',
        host: 'localhost:5000',
        path: '/routing/7.2/getroute.json',
        useSSL: false,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: '',
          app_code: '',
          settingsPresetType: 'CUSTOM',
        },
      },
    },
////////////////////
///////////////////////////////////////
///////////////////
/////////////////////////////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
///////////////////
////////////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////
/////////////////////
///////////////////////
//////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////
///////////////////////////
////////////////////
///////////////////////////
//////////////////////////////
/////////////////////
///////////////////////
/////////////////////////////////////////
////////////////////////////////////
//////////
////////
///////////////
////////////////////////
///////////////////////////////
////////////////////////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
//////////////
    [formsEnum.OLS]: {
      PRODUCTION: {
        title: 'Production - HERE token',
        host: 'router.hereapi.com',
        path: '/v8/routes',
        acceptablePaths: ['/v1/routes'],
        useSSL: true,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.HERE_TOKEN,
          authEndpoint: HERE_TOKEN_ENDPOINTS.PRODUCTION,
          proxy: credentials.OLS.proxy_url,
          consumer_key: credentials.OLS.consumer_key,
          consumer_secret: credentials.OLS.consumer_secret,
          app_id: '',
          app_code: '',
          settingsPresetType: 'PRODUCTION',
          apikey: '',
        },
      },
      PRODUCTION_APIKEY: {
        title: 'Production - Api Key',
        host: 'router.hereapi.com',
        path: '/v8/routes',
        acceptablePaths: ['/v1/routes'],
        useSSL: true,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APIKEY,
          authEndpoint: '',
          proxy: '',
          consumer_key: '',
          consumer_secret: '',
          app_id: '',
          app_code: '',
          apikey: credentials.PRODUCTION.apikey,
          settingsPresetType: 'PRODUCTION_APIKEY',
        },
      },
//////////////////////
///////////////////////
///////////////////////////////////
//////////////////////////////////
///////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////
///////////////////////////
////////////////////
///////////////////////////
//////////////////////////////
/////////////////////
///////////////////////
////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////
////////
////////////
///////////////////////////////////////////////////////
///////////////////////////////////////
///////////////////////////
////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
/////////////////////
///////////////////////
////////////////////////////////////
/////////////////////
//////////
////////
/////////////////
//////////////////////////////////////////////////
///////////////////////////////////
///////////////////////////
////////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
/////////////////////
///////////////////////
/////////////////////
///////////////////////////////////////////
//////////
////////
////////////////
      CUSTOM: {
        title: 'Custom',
        host: 'localhost:8080',
        path: '/v8/routes',
        useSSL: false,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APP_ID_AND_APP_CODE,
          proxy: '',
          app_id: '',
          app_code: '',
          settingsPresetType: 'CUSTOM',
          apikey: '',
        },
      },
    },
  },
//////////////////
//////////////////////////////////////
//////////////////////////////////
///////////////////////////
///////////////////
/////////////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
/////////////////////////////////////////////////////////////
//////////////////////////////////////////
//////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////
//////////
////////
///////////////
////////////////////////
//////////////////////////////////////////
//////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
///////////////////////////
///////////////////
/////////////////////////////////////////////
////////////////////////////////////
///////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
///////////////////////////////////////////////////////////
////////////////////////////////////////
///////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////
//////////
////////
////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////
///////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////
//////////
////////
///////////////
////////////////////////
///////////////////////////////
///////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
////
/////////////////////////
//////////////////////////////////
/////////////////////////
///////////////////
/////////////////////////////////////////////
////////////////////////////////////
//////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////
//////////
////////
///////////////
/////////////////////////////////////////
////////////////////////////////////////
///////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////
//////////
////////
///////////////
////////////////////////
////////////////////////////////////
//////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
////
//////////////////////////////
//////////////////////////////////
//////////////////////////////
///////////////////
/////////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////
/////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////
//////////
////////
///////////////
////////////////////////
////////////////////////////////////////
////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
////
//////////////////////////
//////////////////////////////////
////////////////////////////////
///////////////////
/////////////////////////////////////////////
/////////////////////////////////////
////////////////////////////////////////////
//////////////////////
////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////
/////////////////////
////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////
//////////
////////
///////////////
////////////////////////
///////////////////////////////
////////////////////////////////////////////
//////////////////////
////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
////////////////////////////
///////////////////
/////////////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////
//////////////////////
////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////
//////////
////////
////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////
/////////////////////
////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////
//////////
////////
///////////////
////////////////////////
///////////////////////////////
///////////////////////////////////////
//////////////////////
////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
////
/////////////////////////////
//////////////////////////////////
////////////////////////////
///////////////////
////////////////////////////
///////////////////////////////////
//////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////////
//////////
////////
///////////////
////////////////////////
///////////////////////////////
//////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
////
//////////////////////
//////////////////////////////////
//////////////////////
///////////////////
/////////////////////////////////////////////
///////////////////////////////////
//////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////////
//////////
////////
///////////////
////////////////////////
///////////////////////////////////
//////////////////////////////////////
//////////////////////
/////////////////////
///////////////////
////////////////////
//////////////////////////
///////////////////
///////////////////
//////////////////////////
//////////////////////////////////////////////////
////////////////////
/////////////////////
///////////////////////
///////////////////////////////////////
//////////
////////
//////
////
////////////
  [modulesEnum.TRANSIT]: {
    currentPreset: 'PRODUCTION_APIKEY',
    [formsEnum.INTERMODAL]: {
      PRODUCTION: {
        title: 'Production - HERE token',
        host: 'intermodal.router.hereapi.com',
        path: '/v8/routes',
        useSSL: true,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.HERE_TOKEN,
          authEndpoint: HERE_TOKEN_ENDPOINTS.PRODUCTION,
          proxy: credentials.OLS.proxy_url,
          consumer_key: credentials.OLS.consumer_key,
          consumer_secret: credentials.OLS.consumer_secret,
          app_id: '',
          app_code: '',
          settingsPresetType: 'PRODUCTION',
        },
      },
      PRODUCTION_APIKEY: {
        title: 'Production - Api Key',
        host: 'intermodal.router.hereapi.com',
        path: '/v8/routes',
        acceptablePaths: ['/v1/routes'],
        useSSL: true,
        jsonp: false,
        urlQuery: {
          token: '',
          tokenExpiry: '',
          user: '',
          pass: '',
          auth_server: '',
          authType: AUTH_TYPE.APIKEY,
          authEndpoint: '',
          proxy: '',
          consumer_key: '',
          consumer_secret: '',
          app_id: '',
          app_code: '',
          apikey: credentials.PRODUCTION.apikey,
          settingsPresetType: 'PRODUCTION_APIKEY',
        },
      },
    },
  },
};

export function getPresetByTitle(module, form, title) {
  const formSettings = SETTINGS[module][form];
  return Object.keys(formSettings).find(
    (key) => formSettings[key].title === title
  );
}

export default SETTINGS;
