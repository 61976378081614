import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LayerToggle from '../forms/common/LayerToggle';

class ZoomControls extends Component {
  onZoomIn() {
    let map = this.props.getMap();
    map.setZoom(map.getZoom() + 1, true);
  }

  onZoomOut() {
    let map = this.props.getMap();
    map.setZoom(map.getZoom() - 1, true);
  }

  render() {
    return <div>
      <div className="rf-map__zoom-controls">
        <LayerToggle
          onChange={::this.onZoomIn}
          tooltipText="Zoom in"
        />
        <LayerToggle
          onChange={::this.onZoomOut}
          tooltipText="Zoom out"
        />
      </div>
    </div>;
  }
}

ZoomControls.propTypes = {
  getMap: PropTypes.func.isRequired,
};

export default ZoomControls;
