import React from 'react';
import PropTypes from 'prop-types';
import ExpandFormRow from '../../shared/expandFormRow';
import './styles/linkInfoDataAdvanced.scss';
import { pdeAttributesDescription } from 'config/platformDataExtension';
import LinkInfoDataItem from './LinkInfoDataItem';

export class LinkInfoDataAdvanced extends React.Component {
  static propTypes = {
    link: PropTypes.object.isRequired,
  };

  render() {
    const pdeResponse = this.props.link.pdeResponse;
    const content = Object.keys(pdeResponse).map((layerKey) => {
      const items = [];
      Object.keys(pdeResponse[layerKey] || {}).forEach((attrKey) => {
        if (pdeResponse[layerKey][attrKey]) {
          items.push((
            <LinkInfoDataItem
              key={`${layerKey}.${attrKey}`}
              layerKey={layerKey}
              attrKey={attrKey}
              value={pdeResponse[layerKey][attrKey]}
              tooltip={pdeAttributesDescription[layerKey][attrKey]}
            />
          ));
        }
      });
      return (
        <div key={layerKey}>
        <h4>{layerKey}</h4>
          {items}
        </div>
      );
    });
    return (
      <ExpandFormRow label="Advanced" cssClasses="rf-result-panel__link-info-data-advanced">
        <div className="rf-result-panel__tabs-gap">
          {content}
        </div>
      </ExpandFormRow>
    );
  }
}
