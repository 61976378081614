import utils from 'utils';
import ModuleBase from '../../../modules/_Base';
import { createPolyline } from '../../../utils/PolylineHelper';
import * as mapMarkers from '../../../utils/MarkersHelper';
import { LOCATION_TYPES } from 'state/tabs/tab/formData/routingTab/approachPathRouterForm/fields/constants';
import { TraceDirection } from 'state/tabs/tab/formData/routingTab/approachPathRouterForm/selectedTrace/constants';
import { addOpacityToHexColor } from 'config/map';

const forwardTraceColor = '#cc0000';
const forwardTraceBorderColor = '#660000';
const backwardTraceColor = '#ff7777';
const backwardTraceBorderColor = '#9b4e4e';
const arrowColor = '#000';

const directionToColor = {
  [TraceDirection.FORWARD_TRACES]: addOpacityToHexColor(forwardTraceColor, 0.6),
  [TraceDirection.BACKWARD_TRACES]: addOpacityToHexColor(backwardTraceColor, 0.6),
};

export default class Traces extends ModuleBase {
  process(nextProps) {
    this.clearGroups();
    this.renderTraces(nextProps);
  }

  renderShapes({ shapes, color, borderColor, lineWidth = 7, renderMarker = false, drawDirection = false,
                 direction = null, reverseShape = false, nextProps }) {
    const group = this.getGroup();
    const markers = [];
    const routes = [];
    const childRoutes = [];
    const borders = [];

    shapes.forEach((trace, id) => {
      let shape = [...trace.shape];
      if (!shape.length) {
        return;
      }

      if (reverseShape) {
        shape = shape.reverse();
      }

      if (renderMarker && shape.length === 1) {
        const [latitude, longitude] = shape[0].split(',');
        const mappedMarker = mapMarkers.createMappedMarker({ mappedPosition: { latitude, longitude } });
        markers.push(mappedMarker);
      }

      if (trace.parentTraceReference) {
        const parentPoint = utils.getObject(shapes,
          `${trace.parentTraceReference.traceIndex}.shape.${trace.parentTraceReference.pointIndex}`);
        if (parentPoint) {
          shape.push(parentPoint);
        }
      }

      if (shape.length < 2) {
        return;
      }

      shape = utils.flatten(shape.map(point => point.split(',')));

      const border = createPolyline({
        shape,
        strokeColor: borderColor,
        lineWidth,
      });

      const route = createPolyline({
        shape,
        strokeColor: color,
        lineWidth: lineWidth - 2,
      });

      if (drawDirection) {
        route.setArrows(new H.map.ArrowStyle({ fillColor: arrowColor, frequency: 3 }));
      }

      if (direction !== null) {
        route.addEventListener('tap', () => {
          const selectedTrace = utils.getObject(nextProps, 'tabData.formData.selectedTrace');
          if (selectedTrace && selectedTrace.traceDirection === direction && selectedTrace.traceId === id) {
            nextProps.setSelectedTrace(null);
          } else {
            nextProps.setSelectedTrace(direction, id);
          }
        });
        route.addEventListener('pointerenter', () => {
          this.map.getElement().style.cursor = 'pointer';
        });
        route.addEventListener('pointerleave', () => {
          this.map.getElement().style.cursor = 'auto';
        });
      }

      borders.push(border);
      if (trace.parentTraceReference) {
        childRoutes.push(route);
      } else {
        routes.push(route);
      }
    });

    group.addObjects(borders);
    group.addObjects(childRoutes);
    group.addObjects(routes);
    group.addObjects(markers);
  }

  renderSelectedTrace(forwardTraces, backwardTraces, selectedTrace) {
    if (!selectedTrace) {
      return;
    }
    const traces = selectedTrace.traceDirection === TraceDirection.FORWARD_TRACES ? forwardTraces : backwardTraces;
    const trace = traces[selectedTrace.traceId];

    let shape = [...trace.shape];

    if (trace.parentTraceReference) {
      const parentPoint = utils.getObject(traces,
        `${trace.parentTraceReference.traceIndex}.shape.${trace.parentTraceReference.pointIndex}`);
      if (parentPoint) {
        shape.push(parentPoint);
      }
    }

    shape = utils.flatten(shape.map(point => point.split(',')));
    const route = createPolyline({
      shape,
      strokeColor: directionToColor[selectedTrace.traceDirection],
      lineWidth: 14,
    });

    const group = this.getGroup();
    group.addObject(route);
  }

  renderTraces(nextProps) {
    const response = utils.getObject(nextProps, 'tabData.response.data.response.approachPath');
    const fields = utils.getObject(nextProps, 'tabData.formData.fields');
    const forwardTraces = utils.getObject(response, 'forwardTrace', []);
    const backwardTraces = utils.getObject(response, 'backwardTrace', []);

    if (response) {
      this.renderSelectedTrace(forwardTraces, backwardTraces,
        utils.getObject(nextProps, 'tabData.formData.selectedTrace'));

      this.renderShapes({
        shapes: response.matchedLocation,
        color: nextProps.tabData.tabColorPalette.primary,
        borderColor: nextProps.tabData.tabColorPalette.primaryDarker,
        lineWidth: 11,
        drawDirection: !response.backwardTrace || !response.forwardTrace,
        reverseShape: !!response.backwardTrace,
        renderMarker: true,
        nextProps,
      });

      this.renderShapes({
        shapes: forwardTraces,
        color: forwardTraceColor,
        borderColor: forwardTraceBorderColor,
        drawDirection: true,
        direction: TraceDirection.FORWARD_TRACES,
        nextProps,
      });

      this.renderShapes({
        shapes: backwardTraces,
        color: backwardTraceColor,
        borderColor: backwardTraceBorderColor,
        drawDirection: true,
        direction: TraceDirection.BACKWARD_TRACES,
        nextProps,
      });
    }

    if (fields.locationType === LOCATION_TYPES.GEO && fields.locationGeo.value && fields.locationGeo.isValid) {
      const marker = mapMarkers.createMainMarker({
        color: nextProps.tabData.tabColorPalette.primary,
        strokeColor: nextProps.tabData.tabColorPalette.primaryDarker,
        point: fields.locationGeo,
        title: 'A',
      });
      this.getGroup().addObject(marker);
    } else if (fields.locationType === LOCATION_TYPES.LINK && fields.displayPosition.value &&
      fields.displayPosition.isValid) {
      const marker = mapMarkers.createMainMarker({
        color: nextProps.tabData.tabColorPalette.primary,
        strokeColor: nextProps.tabData.tabColorPalette.primaryDarker,
        point: fields.displayPosition,
        title: 'A',
      });
      this.getGroup().addObject(marker);
    } else if (fields.locationType === LOCATION_TYPES.STREET) {
      let point;
      if (fields.displayPosition.value && fields.displayPosition.isValid) {
        point = fields.displayPosition;
      } else if (fields.locationStreet.value && fields.locationStreet.isValid) {
        point = fields.locationStreet;
      } else {
        return;
      }
      const marker = mapMarkers.createMainMarker({
        color: nextProps.tabData.tabColorPalette.primary,
        strokeColor: nextProps.tabData.tabColorPalette.primaryDarker,
        point,
        title: 'A',
      });
      this.getGroup().addObject(marker);
    }
  }
}
