import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomSelect from 'shared/select';

import './styles/featureRow.scss';

class FeatureRow extends Component {
  onChange(label, e) {
    let { value } = e.target;
    if (value === '') {
      this.props.onChange({ [label]: { isChecked: false } });
    } else {
      this.props.onChange({ [label]: { value: e.target.value, isChecked: true } });
    }
  }

  getSelect() {
    let { formConfig, label, value, selectValue, isChecked } = this.props;
    selectValue = isChecked ? selectValue : '';

    return <CustomSelect
      addEmptyOption
      value={selectValue}
      options={formConfig.featureSet}
      onChange={this.onChange.bind(this, value)}
      label={label}
    />;
  }

  render() {
    return (
      <div className="rf-feature-row rf-clearfix">
        {this.getSelect()}
      </div>
    );
  }
}

FeatureRow.defaultProps = {
  selectValue: '',
  isChecked: false,
};

FeatureRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  selectValue: PropTypes.string.isRequired,
  formConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FeatureRow;
