import {
  FEATURE_NAMES,
  FEATURE_NAMES_BACKWARD,
  FEATURE_SET,
  FEATURE_SET_BACKWARD,
  WAYPOINT_TYPES_BACKWARD,
} from './importExportMapping';

export function parseDeparture(importJson, fields) {
  const routeTime = _.get(importJson, 'route_plan.route_time', [])[0];
  if (!routeTime) {
    return fields;
  }

  const { type, time } = routeTime.$;
  if (type === 'DEPARTURE') {
    return { ...fields, routeDate: { key: 'departure', value: time } };
  }
  return fields;
}

export function parseRouteOptions(importJson, fields) {
  const routeOptions = _.get(importJson, 'route_plan.route_options')[0];

  const { route_type: type = 'fastest', alternatives, routing_mode: routingMode = 'car' } = routeOptions.$;
  const transport = _.get(routeOptions, 'allow_carpool.length') > 0 ? 'carHOV' : routingMode.toLowerCase();
  const importFeatures = _.get(routeOptions, 'feature_weight', []);
  let features = { ...fields.mode.features };
  if (importFeatures.length > 0) {
    importFeatures.forEach((feature) => {
      const { type: importFeatureType, value } = feature.$;
      if (importFeatureType === 'HIGHWAY') {
        return;
      }
      const featureType = FEATURE_NAMES[importFeatureType] || importFeatureType.toLowerCase();
      features[featureType] = { value: FEATURE_SET[value], isChecked: true };
    });
  }
  let res = {
    ...fields,
    mode: { ...fields.mode, transport, type: type.toLowerCase(), features },
  };

  if (alternatives) {
    res = { ...res, alternatives };
  }

  return res;
}

export function xmlifyWaypoints(waypointsData) {
  return waypointsData.map((waypoint) => {
    const [lat = '', lng = ''] = waypoint.coords.value.split(',');
    const waypointEl = document.createElementNS(null, 'waypoint');
    waypointEl.setAttribute('lat', lat);
    waypointEl.setAttribute('lng', lng);
    waypointEl.setAttribute('type', WAYPOINT_TYPES_BACKWARD[waypoint.type]);

    return waypointEl;
  });
}

export function xmlifyDeparture(fieldsData) {
  if (_.get(fieldsData, 'routeDate.key') === 'departure') {
    const time = _.get(fieldsData, 'routeDate.value');
    const departureTimeEl = document.createElementNS(null, 'route_time');
    departureTimeEl.setAttribute('type', 'DEPARTURE');
    departureTimeEl.setAttribute('time', time);

    return departureTimeEl;
  }

  return null;
}

export function xmlifyRouteOptions(fieldsData) {
  const { transport, type, features } = fieldsData.mode;
  const routeOptionsEl = document.createElementNS(null, 'route_options');
  routeOptionsEl.setAttribute('route_type', type.toUpperCase());
  routeOptionsEl.setAttribute('routing_mode', 'CAR');
  if (fieldsData.alternatives) {
    routeOptionsEl.setAttribute('alternatives', fieldsData.alternatives);
  }

  Object.keys(features).forEach((featureName) => {
    if (featureName === 'motorway') {
      return;
    }
    const feature = features[featureName];
    if (feature.value === '-2') {
      const featureEl = document.createElementNS(null, 'feature_weight');
      featureEl.setAttribute('type', FEATURE_NAMES_BACKWARD[featureName] || featureName.toUpperCase());
      featureEl.setAttribute('value', FEATURE_SET_BACKWARD[feature.value]);

      routeOptionsEl.appendChild(featureEl);
    }
  });

  if (transport === 'carHOV') {
    const allowCarpoolEl = document.createElementNS(null, 'allow_carpool');
    routeOptionsEl.appendChild(allowCarpoolEl);
  }

  return routeOptionsEl;
}
