import Scrollspy from 'react-scrollspy';
import PropTypes from 'prop-types';

export default class CustomScrollSpy extends Scrollspy {
  // override method to support high-ppi screens
  _isAtBottom() {
    const { rootEl } = this.props;
    const { scrollTop, scrollHeight } = this._getScrollDimension();
    const winH = rootEl ? document.querySelector(rootEl).getBoundingClientRect().height : window.innerHeight;
    return Math.ceil(scrollTop + winH) >= scrollHeight;
  }
}

CustomScrollSpy.propTypes = {
  rootEl: PropTypes.any,
};
