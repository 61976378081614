import modulesEnum from 'config/modulesEnum';

import routing from './routing';
////////////////
//////////////////////////////
////////////////////////////////
////////////////////////////////////
////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////
//////////
import transit from './transit';

export default {
  [modulesEnum.ROUTING]: routing,
//////////////////
///////////////////////////////
/////////////////////////////////
//////////////////////////////////////
/////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////
////////////
  [modulesEnum.TRANSIT]: transit,
};
