import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { getTabs } from 'state/tabs';

import './styles/tabs.scss';
import { getSelectedTab } from 'state/selectedTab';
import * as TabsAction from 'state/tabs/actions';
import { toggleNewTabShown } from '../../state/isNewTabShown/actions';
import * as NotificationActions from 'state/notification/actions';
import * as SelectedTabActions from 'state/selectedTab/actions';
import { setIsTabActive } from 'state/tabs/tab/isActive/actions';
import { setSettings } from 'state/appSettings/base/actions';
import Sortable, { SortableContainer } from 'react-anything-sortable';
import ShareContainer from './ShareContainer';
import ScrollControls from './ScrollControls';
import Tab from './Tab';
import { setTabTitle } from 'state/tabs/tab/title/actions';
import utils from 'utils';
import DIRECTIONS from './Directions';

const controlsMargin = 76;

export class TabsContainer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {
      tabs: nextProps.tabs,
    };
    if (state.tabs.length > prevState.tabs.length) {
      document.removeEventListener('click', prevState.hideNewTab, false);
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state.hideNewTab = ::this.hideNewTab;
    this.canScrollDebounced = utils.debounce(::this.canScroll, 1000);
  }

  state = {
      canScrollLeft: false,
      canScrollRight: false,
      tabs: [],
      hideNewTab: null,
  };

  componentDidMount() {
    window.addEventListener('resize', this.canScrollDebounced);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tabs.length !== this.props.tabs.length || this.hasAnyTitleChanged(prevProps)) {
      this.canScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.canScrollDebounced);
  }

  onTabRemove(index, e) {
    e.stopPropagation();
    let { tabs, onTabRemove, notify } = this.props;
    if (tabs.length === 1) {
      notify({
        message: `You can't close last tab`,
        impact: 'significant',
      });
      return;
    }
    onTabRemove(index);
  }

  onTabToggle(index, isActive, e) {
    e.stopPropagation();
    this.props.onTabToggle(index, isActive);
  }

  getNewTabButton() {
    return <div className="rf-nav-tabs__add-new-tab" onClick={::this.showNewTab}>
      New tab <span className="rf-nav-tabs__add-new-tab__plus">+</span>
    </div>;
  }

  getTabs() {
    let { tabs, selectedTab, setSelectedTab, setTabTitle, duplicateTab } = this.props;
    return tabs.map((tab, index) => {
      const isSelected = selectedTab === index;
      let classes = classnames('rf-nav-tabs__tab', {
        'rf-nav-tabs__tab_selected': isSelected,
      });
      let color = tab.tabColorPalette.primary;
      let styles = tab.isActive || isSelected ? {
        borderColor: color,
      } : {};
      return (
        <SortableContainer sortData={index} key={index} className={classes}>
          <div onClick={setSelectedTab.bind(this, index)}>
            <div className="rf-nav-tabs__tab__inner" style={styles}>
              <Tab
                title={tab.title}
                isSelected={isSelected}
                isActive={tab.isActive}
                close={this.onTabRemove.bind(this, index)}
                setTitle={setTabTitle.bind(this, index)}
                setActive={this.onTabToggle.bind(this, index)}
                color={color}
                showCloseButton={tabs.length > 1}
                duplicate={duplicateTab.bind(this, index)}
                isLoading={tab.isLoading}
              />
            </div>
          </div>
        </SortableContainer>
      );
    });
  }

  canScroll() {
    const node = ReactDOM.findDOMNode(this.refs.tabs);
    const tabNodes = node.childNodes;
    const canScrollLeft = node.scrollLeft !== 0;
    let tabsWidth = 0;
    for (let i = 0; i < tabNodes.length; i++) {
      tabsWidth += tabNodes[i].getBoundingClientRect().width;
    }
    const canScrollRight = (tabsWidth - node.scrollLeft) > (node.getBoundingClientRect().width - controlsMargin) + 5;

    if (canScrollRight !== this.state.canScrollRight || canScrollLeft !== this.state.canScrollLeft) {
      this.setState({ canScrollLeft, canScrollRight });
    }
  }

  handleSort(indexes) {
    this.props.reorderTabs(indexes);
  }

  hasAnyTitleChanged(props) {
    return !!this.props.tabs.some((tab, index) => tab.title !== props.tabs[index].title);
  }

  hideNewTab() {
    this.props.toggleNewTabShown(false);
    document.removeEventListener('click', this.state.hideNewTab, false);
  }

  showNewTab() {
    this.props.toggleNewTabShown(true);
    document.addEventListener('click', this.state.hideNewTab, false);
  }

  scroll(direction) {
    let node = ReactDOM.findDOMNode(this.refs.tabs);
    let tabNodes = node.childNodes;
    let rightPoint = direction === DIRECTIONS.RIGHT ?
      (node.getBoundingClientRect().width + node.scrollLeft + 5) - controlsMargin :
      node.scrollLeft - 6;
    let nextTabPosition = 0;
    for (let i = 0; i < tabNodes.length; i++) {
      let tab = tabNodes[i];
      let tabWidth = tab.getBoundingClientRect().width;
      nextTabPosition += tabWidth;
      if (nextTabPosition > rightPoint) {
        if (direction === DIRECTIONS.LEFT) {
          nextTabPosition -= tabWidth;
        }
        break;
      }
    }
    if (direction === DIRECTIONS.RIGHT) {
      node.scrollLeft = (nextTabPosition - node.getBoundingClientRect().width) + controlsMargin;
    } else {
      node.scrollLeft = nextTabPosition;
    }
    this.canScroll();
  }

  render() {
    return (
      <nav className="rf-nav-tabs">
        {this.getNewTabButton()}
        <Sortable
          onSort={::this.handleSort}
          className="rf-nav-tabs__sortable"
          direction="horizontal"
          dynamic
          ref="tabs"
        >
          {this.getTabs()}
        </Sortable>
        <ScrollControls
          scroll={::this.scroll}
          canScrollLeft={this.state.canScrollLeft}
          canScrollRight={this.state.canScrollRight}
        />
        <ShareContainer />
      </nav>
    );
  }
}

TabsContainer.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTab: PropTypes.number.isRequired,
  onTabToggle: PropTypes.func.isRequired,
  onTabRemove: PropTypes.func.isRequired,
  toggleNewTabShown: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  setSettings: PropTypes.func.isRequired,
  isNewTabShown: PropTypes.bool.isRequired,
  reorderTabs: PropTypes.func.isRequired,
  setTabTitle: PropTypes.func.isRequired,
  duplicateTab: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    tabs: getTabs(state),
    selectedTab: getSelectedTab(state),
    isNewTabShown: state.isNewTabShown,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onTabToggle: bindActionCreators(setIsTabActive, dispatch),
    onTabRemove: bindActionCreators(TabsAction.removeTab, dispatch),
    toggleNewTabShown: bindActionCreators(toggleNewTabShown, dispatch),
    notify: bindActionCreators(NotificationActions.set, dispatch),
    setSelectedTab: bindActionCreators(SelectedTabActions.setSelectedTab, dispatch),
    setSettings: bindActionCreators(setSettings, dispatch),
    reorderTabs: bindActionCreators(TabsAction.reorderTabs, dispatch),
    setTabTitle: bindActionCreators(setTabTitle, dispatch),
    duplicateTab: bindActionCreators(TabsAction.duplicateTab, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabsContainer);
