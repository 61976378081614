export default {
  SPEED_LIMITS_COND_FC: {
    DATE_TIMES: speedLimitCondDateTime,
  },
};

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function speedLimitCondDateTime(value) {
  if (!value) {
    return value;
  }
  const datePeriods = value.split(',');
  return datePeriods.map((datePeriod) => {
    switch (datePeriod.charAt(0)) {
      case '1': {
        const days = datePeriod.substr(6, 7);
        let daysStr = '';
        if (days === 'XXXXXXX') {
          daysStr = 'all days';
        } else {
          [...days].forEach((char, index) => {
            if (char === 'X') {
              const prefix = daysStr.length === 0 ? '' : ', ';
              daysStr += `${prefix}${DAYS[index]}`;
            }
          });
        }
        const rightParts = datePeriod.split(':');
        const from = parseTime(rightParts[rightParts.length - 2]);
        const to = parseTime(rightParts[rightParts.length - 1]);
        return `${daysStr} from ${from} to ${to}`;
      }
      default:
        return datePeriod;
    }
  }).join(', ');
}

function parseTime(time) {
  while (time.length !== 4) {
    time = `0${time}`;
  }
  const [a, b, c, d] = time;
  return `${a}${b}:${c}${d}`;
}
