import Immutable from 'immutable';

import * as types from './constants';
import * as formDataTypes from 'state/tabs/tab/formData/constants';

import utils from 'utils';

function isList(o) {
  return Immutable.List.isList(o);
}

function formatValue(o, validate) {
  let value = o.value === undefined ? o : o.value;
  return utils.isFunction(validate) ? { value, isValid: validate(value) } : o;
}

let merger = utils.getDeepMapMerger(Immutable);

export function fieldsReducer(state, action = {}) {
  let { validation = {}, data, params } = action;
  if (state === undefined) {
    state = action.defaultState;
  }

  switch (action.type) {
    case types.UPDATE:
    case types.MAP_UPDATE:
      return Immutable.fromJS(state).updateIn(data.key.split('.'), item => {
        if (item === undefined) {
          return undefined;
        }
        let value = formatValue(data.value, validation[data.key]);
        return utils.isFunction(item.merge) && !isList(item) ? item.merge(value) : value;
      }).toJS();

    case formDataTypes.RESTORE_FROM_URL:
      return Immutable.fromJS(state).map((item, key) => {
        let value = data.value[key];
        return value === undefined ? item : formatValue(value, validation[key]);
      }).toJS();

    case types.MULTI_UPDATE:
      if (!params) {
        params = data;
      }
      return Immutable.fromJS(state).mergeWith(merger, params).map((item, key) => {
        let o = utils.isFunction(item.toJS) ? item.toJS() : item,
            value = formatValue(o, validation[key]);
        return utils.isFunction(item.merge) && !isList(item) ? item.merge(value) : value;
      }).toJS();
    case types.IMPORT:
      return { ...data.fields };
    default:
      return state;
  }
}

export default fieldsReducer;
