export default {
  isActive: false,

  maxcharge: 0,
  initialcharge: 0,
  minchargeatstop: 0,
  isMaxChargeActive: false,
  isInitialChargeActive: false,
  isMinChargeAtStopActive: false,
  isChargingAllowed: true,
};
