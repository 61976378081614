import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BaseContextMenu from '../../_ContextMenu';
import * as WaypointsActions from 'state/tabs/tab/formData/common/waypoints/actions';
import { inspectLink } from 'state/inspectLink/actions';
import * as NotificationActions from 'state/notification/actions';

import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getInspectLinkSettings } from 'state/appSettings/inspectLink/reducer';

class ContextMenuContainer extends BaseContextMenu {
  setWaypoint(key) {
    let { currentForm, data, waypointUpdate } = this.props;
    let value = { key, coords: { value: data.geo } };
    waypointUpdate({
      currentForm,
      value,
      index: 0,
    });
    this.hide();
  }

  getMenuItems(data) {
    let items = [
          { label: 'Add Start', iconLabel: 'S', fn: this.setWaypoint.bind(this, 'start') },
          { label: 'Add Destination', iconLabel: 'D', fn: this.setWaypoint.bind(this, 'destination') },
        ];
    return items.concat(super.getMenuItems(data));
  }
}

ContextMenuContainer.defaultProps = {
  data: {},
};

ContextMenuContainer.propTypes = {
  data: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  waypointUpdate: PropTypes.func.isRequired,
  inspectLink: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentForm: getForm(state),
    module: getModule(state),
    settingsData: state.settingsData.present,
    inspectLinkSettings: getInspectLinkSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    waypointUpdate: bindActionCreators(WaypointsActions.update, dispatch),
    inspectLink: bindActionCreators(inspectLink, dispatch),
    textCopiedNotify: bindActionCreators(NotificationActions.textCopiedNotify, dispatch),
    requestErrorNotify: bindActionCreators(NotificationActions.requestErrorNotify, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextMenuContainer);
