import commonOlsDefaultState from '../../common/commonOlsDefaultState';
import commonOlsConsumptionDefaultState from '../../common/commonOlsConsumptionDefaultState';
import truckDefaultState from './truckDefaultState';

export default {
  ...commonOlsDefaultState,
  ...commonOlsConsumptionDefaultState,
  selectedPoint: '',
  hoveredPoint: '',
  departureTime: '',
  arrivalTime: '',
  routingMode: '',
  transportMode: 'car',
  selectedManeuver: {
    shape: null,
    index: null,
  },
  selectedSpan: {
    shape: null,
    index: null,
  },
  selectedTurnByTurnAction: {
    shape: null,
    index: null,
  },
  'exclude[countries]': '',
  ...truckDefaultState,
  'avoid[areas]': [{
    value: '',
    isValid: true,
    isShown: false,
  }],
  'vehicle[hovOccupancy]': '',
};
