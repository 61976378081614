import { isCoordsValid, isCoordValidAndDefined } from '../../validation/coordsValidation';
import gatherWaypoints from './gatherWaypoints';
import consumptionDefaultState from 'state/tabs/tab/formData/routingTab/common/consumptionDefaultState';
import consumptionValidation from 'state/tabs/tab/formData/routingTab/common/consumptionValidation';
import truckProfileDefaultState from 'state/tabs/tab/formData/common/fields/truckProfileDefaultState';
import isNumberValid from 'utils/validation/numericValidation';
import pointSplit from 'utils/matrix/pointSplit';
import utils from 'utils';
import { olsBboxValidation } from '../../validation/bboxValidation';

const customHandlers = {
  splitArrayToObject(keyVal) {
    return {
      key: keyVal[0],
      value: keyVal[1],
    };
  },

  splitArray(keyVal) {
    return (keyVal[1] || '').split(',');
  },

  splitArrayFor(attributesConfig) {
    return (...args) => this.splitArray(...args)
      .map(attr => {
        const configEntry = utils.find(attributesConfig,
          entry => entry.enumeration.toLowerCase() === attr.toLowerCase());
        return configEntry ? configEntry.value : attr;
      });
  },

  splitResolutionParams(keyVal) {
    let [view, snap] = keyVal[1].split(':');

    return {
      view: { value: view, isChecked: !!view },
      snap: { value: snap || '', isChecked: !!snap },
    };
  },

  splitModeParams(keyVal) {
    let [type = '', transport = '', additional = '', additionalExtra = ''] = (keyVal[1] || '').split(';'),
        isTraffic = additional.indexOf('traffic') > -1,
        traffic = isTraffic ? additional : '',
        featuresStr = isTraffic ? additionalExtra : additional,
        features = {};

    if (featuresStr.length > 0) {
      featuresStr.split(',').forEach(feature => {
        let featureData = feature.split(':');

        features[featureData[0]] = { isChecked: true, value: featureData[1] };
      });
    }

    return {
      type,
      transport,
      traffic,
      features,
    };
  },

  splitAttributesParams(keyVal) {
    const acceptedAggrValues = ['all', 'none'];
    let value = (keyVal[1] || '').split(','),
      aggregation = acceptedAggrValues.indexOf(value[0]) !== -1 ? value[0] : '',
      attributes = (aggregation ? value.slice(1) : value).map(attr => attr.replace('-', ''));

    return {
      aggregation,
      attributes,
    };
  },

  splitAttributesParamsFor(attributesConfig) {
    return (...args) => {
      let params = this.splitAttributesParams(...args);
      params.attributes = params.attributes.map(attr => {
        const configEntry = utils.find(attributesConfig,
            entry => entry.enumeration.toLowerCase() === attr.toLowerCase());
        return configEntry ? configEntry.value : attr;
      });
      return params;
    };
  },

  splitConsumptionParams(field) {
    return keyVal => {
      let val = keyVal[1];
      let parsedRes = val.split(';')
        .reduce((result, param) => {
          let [paramKey, value] = param.split(/[,=](.+)/);
          let retTrue = () => true;
          let validationFn = consumptionValidation[`${field}.${paramKey}`] || retTrue;
          let isValid = validationFn(value);
          result[paramKey] = { value, isValid };
          return result;
        }, {});
      let emptyDefault = Object.keys(consumptionDefaultState[field])
        .reduce((res, key) => {
          res[key] = { isValid: true, value: '' };
          return res;
        }, {});
      return Object.assign({}, emptyDefault, parsedRes);
    };
  },

  splitChargingStationFilters(keyVal) {
    const params = customHandlers.splitConsumptionParams('chargingstationfilters')(keyVal);
    params.plugtype.value = params.plugtype.value.split(',');
    return params;
  },

  gatherWaypoints,
  gatherIsolineWaypoints(keyVal) {
    return Object.assign(gatherWaypoints(keyVal), { key: keyVal[0] });
  },

  prettifySequence(keyVal) {
    return {
      isPreset: true,
      tolerance: String(keyVal[1]).replace(/,/g, ', '),
    };
  },

  normalizeJsonAttributes(keyVal) {
    let val = +keyVal[1];
    /* eslint-disable no-bitwise */
    return {
      lowerCaseFirstCharacter: !!(val & 1),
      includeTypeElement: !!(val & 8),
      usePluralNaming: !!(val & 16),
      shapesToArraysWithPairs: !!(val & 32),
      shapesToArraysWithTriplets: !!(val & 64),
      jsonWrapper: !!(val & 128),
    };
    /* eslint-enable no-bitwise */
  },

  gatherAreas(keyVal) {
    let values = String(keyVal[1]).split('!');
    return values.map(item => ({
        value: String(item).trim(),
        isShown: true,
        isValid: true,
      })
    );
  },

  getHandlerForMultiCheckboxes(defaultState) {
    return keyVal => {
      let values = keyVal[1].split(',');
      let result = Object.assign({}, defaultState);
      values.forEach(value => {
        result[value] = true;
      });
      return result;
    };
  },

  getHandlerForMultiCheckboxesDefaultTrue(defaultState) {
    return keyVal => {
      let values = keyVal[1].split(',');
      let result = Object.assign({}, defaultState);
      Object.keys(result).forEach(item => {
        if (!values.includes(item)) {
          result[item] = false;
        }
      });
      return result;
    };
  },

  vehicleType(keyVal) {
    const [type, consumption] = keyVal[1].split(',');

    return {
      type,
      consumption: {
        value: consumption,
        isValid: isNumberValid()(+consumption),
      },
    };
  },

  isNewPointsFormat(field, keyVal) {
    return keyVal[0] === field;
  },

  parsePoints(keyVal) {
    return pointSplit(keyVal[1]);
  },

  groups(validator = () => true) {
    return keyVal => {
      const result = {};
      keyVal[1].split(';')
        .forEach(group => {
          const [key, value] = group.split(':');
          result[key] = {
            isValid: validator(value),
            value,
          };
        });
      return result;
    };
  },

  weightsPerAxleGroup(keyVal) {
    const formatter = customHandlers.groups(isNumberValid({ min: 0, max: 1000 }));
    return { ...truckProfileDefaultState.weightsPerAxleGroup, ...formatter(keyVal) };
  },

  coordinate(keyVal) {
    let center = keyVal[1] || '';
    return { value: center, isValid: isCoordValidAndDefined(center) };
  },

  olsAvoidAreas(keyVal) {
    const values = keyVal[1].split('|');

    return values.map((value) => ({
      value,
      isValid: olsBboxValidation(value),
      isShown: true,
    }));
  },

//////////////////
///////////////////////
//////////////////////////
///////////////////////////////////////////////////////////////////////
////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
//////
////

////////////////////////////////
////////////////////////////
////////////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
//////
////

////////////////////////////////
//////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
//////
////

/////////////////////
//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////
////////////
////////////////////////////////////////////////////////////////
//////////////////////
//////
////

////////////////////
//////////////////////////////////////////////
/////////////////////////////
//////////////////////////////////
////////////
////////////////////////////////////////////////////////////////
////////////
//////
////

////////////////////////////////
///////////////////////////////////
////////////
////////////////////////////////////////////////////////////////
//////
////
////////////
};

export default customHandlers;
