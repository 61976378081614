import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomComponent from 'shared/component';

import './styles/expandFormRow.scss';

class ExpandFormRow extends CustomComponent {
  state = {
    isExpanded: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if ((nextProps.toggleProgrammatically && nextProps.isExpanded !== prevState.isExpanded)
      || prevState.isExpanded === null) {
      return { isExpanded: nextProps.isExpanded };
    }
    return {};
  }

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    let isExpanded = !this.state.isExpanded;
    this.setState({ isExpanded });
  }

  render() {
    const { onToggle, isHidden, label, children, id } = this.props;

    let cssClasses = classnames('rf-expand-form-row', {
      'rf-expand-form-row_expanded': this.state.isExpanded,
    });
    if (isHidden) {
      return null;
    }
    return (
      <div className={this.getCssClasses(cssClasses)} id={id} ref="container">
        <div className="rf-expand-form-row__label" onClick={onToggle || this.onToggle}>
          <div className="rf-expand-form-row__toggler"></div>
          <span className="rf-expand-form-row__label-text">
            {label}
          </span>
        </div>
        <div className="rf-expand-form-row__container">
          {children}
        </div>
      </div>
    );
  }
}

ExpandFormRow.defaultProps = {
  label: '',
  id: '',
  toggleProgrammatically: false,
  isExpanded: false,
  isHidden: false,
};

ExpandFormRow.propTypes = {
  label: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleProgrammatically: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
};

export default ExpandFormRow;
