import PropTypes from 'prop-types';
import React from 'react';

import FormRow from 'shared/formRow';
import CustomCheckboxList from 'shared/checkboxList';
import ExpandFormRow from 'shared/expandFormRow';
import CustomComponent from 'shared/component';

import './styles/jsonAttributesBlock.scss';

class JsonAttributesBlock extends CustomComponent {
  render() {
    let { jsonAttributes, attributes, onChange } = this.props;
    let params = attributes.map(item => {
      let isChecked = !!jsonAttributes[item.value];
      return Object.assign({}, item, {
        isChecked,
        onChange: onChange.bind(null, `jsonattributes.${item.value}`),
      });
    }, this);
    return (
      <ExpandFormRow cssClasses={this.getCssClasses('rf-json-attributes')} label="Json Attributes" isExpanded id="json">
        <FormRow>
          <CustomCheckboxList params={params} />
        </FormRow>
      </ExpandFormRow>
    );
  }
}

JsonAttributesBlock.propTypes = {
  jsonAttributes: PropTypes.object.isRequired,
  attributes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default JsonAttributesBlock;
