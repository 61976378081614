import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';
import CustomCheckbox from 'shared/checkbox';

import './styles/checkboxList.scss';

class CustomCheckboxList extends CustomComponent {
  getItems() {
    return this.props.params.map((param) => (
        <li className="rf-checkbox-list__item" key={param.value}>
          <CustomCheckbox {...param} />
        </li>
      )
    );
  }

  render() {
    return (
      <ul className="rf-checkbox-list rf-clearfix">
        {this.getItems()}
      </ul>
    );
  }
}

CustomCheckbox.defaultProps = {
  params: [],
};

CustomCheckbox.propTypes = {
  params: PropTypes.array.isRequired,
};

export default CustomCheckboxList;
