import * as types from './constants';
import * as formDataTypes from 'state/tabs/tab/formData/constants';
import reducer from './reducer';
import truckFields from './truckProfileDefaultState';
import truckValidation from './truckProfileValidation';

import utils from 'utils';

export default function proxy(args = {}) {
  let { validation, currentForm, hasTruckFields, defaultState = {} } = args;
  if (hasTruckFields) {
    defaultState = Object.assign({}, defaultState, truckFields);
    validation = Object.assign({}, validation, truckValidation);
  }

  return function (state = defaultState, action) {
    let _types = utils.values(types);
    if (_types.indexOf(action.type) !== -1 && action.data.currentForm === currentForm) {
      return reducer(state, Object.assign(action, { validation }));
    }

    if (formDataTypes.RESTORE_FROM_URL === action.type && action.data.currentForm === currentForm) {
      return reducer(defaultState, Object.assign(action, { validation }));
    }

    return state;
  };
}
