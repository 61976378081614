import * as types from './constants';
import * as geocoderUtils from 'utils/api/geocoder';
import * as resultPanelActions from 'state/resultPanel/resultPanelActions';

export function storeResult(data) {
  return { type: types.STORE_RESULT, data };
}

export function remove() {
  return { type: types.REMOVE };
}

export function reverseGeocode(coords, urlQuery) {
  return async (dispatch) => {
    const reverseGeocodeRes = await geocoderUtils.reverseGeocode(coords, urlQuery, true);
    dispatch(storeResult(reverseGeocodeRes.data));
    dispatch(resultPanelActions.setVisibility(true));
  };
}
