import * as types from '../constants';
import { normalizeRoutingData } from '../responseHandlers';

export default function apiResponseRouting(state = { data: { response: {} } }, action) {
  switch (action.type) {
    case types.RECEIVE_DATA_ISOLINE:
      return normalizeRoutingData(action.result);

    default:
      return state;
  }
}
