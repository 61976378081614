import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import Datetime from 'react-datetime';
import moment from 'moment';

import CustomComponent from 'shared/component';
import { CustomInput } from 'shared/input';
import utilsDate from 'utils/date';
import classnames from 'classnames';

import 'react-datetime/css/react-datetime.css';

import './styles/datetime.scss';

export class CustomDateTime extends CustomComponent {
  constructor(props) {
    super(props);
    this.closeDateTime = ::this.closeDateTime;
    this.state = {
      dateTimeOpen: false,
    };
  }

  onInputChange(e) {
    this.props.onChange(e.target.value);
  }

  onDateTimeChange(momentDate) {
    const momentLocalDate = utilsDate.getLocalDate(momentDate);
    this.props.onChange(momentLocalDate.toISOString());
  }

  toggleDateTime() {
    if (this.props.isReadonly) {
      return;
    }
    if (this.state.dateTimeOpen) {
      this.closeDateTime();
      return;
    }
    this.setState({ dateTimeOpen: true });
    setTimeout(() => {
      let domNode = ReactDOM.findDOMNode(this.refs.datetime);
      const inputNode = ReactDOM.findDOMNode(this.refs.dateTimeInput.refs.input);
      let picker = domNode.querySelector('.rdtPicker');
      const inputBounds = inputNode.getBoundingClientRect();
      picker.style.top = `${inputBounds.top - picker.getBoundingClientRect().height}px`;
      picker.style.left = `${inputBounds.left}px`;
      document.addEventListener('click', this.closeDateTime, false);
    }, 0);
  }

  closeDateTime() {
    this.setState({ dateTimeOpen: false });
    document.removeEventListener('click', this.closeDateTime, false);
  }

  render() {
    let { label, value, isReadonly } = this.props,
      labelEl = label ? <span className="rf-datetime__caption">{label}</span> : '';
    const calendarBtnClasses = classnames('rf-datetime__calendar-btn',
      { 'rf-datetime__calendar-btn_readonly': isReadonly });
    const momentValue = moment(value);

    return (
      <div className={this.getCssClasses(['rf-datetime'])}>
        {labelEl}
        <div className={calendarBtnClasses}>
          <i className="rf-datetime-icon" onClick={::this.toggleDateTime} />
        </div>
        <CustomInput
          blurOnEnter
          debounce
          onBlur={::this.onInputChange}
          value={value}
          ref="dateTimeInput"
          disabled={isReadonly}
          onClick={e => e.nativeEvent.stopImmediatePropagation()}
        />
        <div onClick={e => e.nativeEvent.stopImmediatePropagation()}>
          <Datetime
            ref="datetime"
            className="rf-datetime__field"
            value={value && momentValue.isValid() ? momentValue : value}
            closeOnSelect
            onChange={::this.onDateTimeChange}
            input={false}
            open={this.state.dateTimeOpen}
          />
        </div>
      </div>
    );
  }
}

CustomDateTime.defaultProps = {
  value: '',
  isReadonly: false,
};

CustomDateTime.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomDateTime;
