import React from 'react';
import FormRow from 'shared/formRow';
import ExpandFormRow from 'shared/expandFormRow';
import SpeedGroup from './SpeedGroup';
import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import PropTypes from 'prop-types';
import utils from 'utils';

import './styles/olsConsumption.scss';
import olsElectricPreset, { preset80kWh } from 'config/form/olsElectricPreset';
import CustomCheckbox from 'shared/checkbox';
import CheckboxAttributesSet from './CheckboxAttributesSet';
import adjustLUIStyle from 'utils/adjustLUIStyle';

const consumptionParameters = [
  {
    label: 'Ascent',
    key: 'ascent',
    tooltip: 'Ascent multiplier that is multiplied by absolute height increase to model energy expenditure [E/m]'
  },
  {
    label: 'Descent',
    key: 'descent',
    tooltip: 'Descent multiplier that is multiplied by absolute height decrease to model energy gain [E/m]'
  },
  { label: 'Auxiliary', key: 'auxiliaryConsumption', tooltip: 'Auxiliary [E/s]' },
  { label: 'Acceleration', key: 'acceleration', tooltip: 'Accel. [E/km/h)^2]' },
  { label: 'Deceleration', key: 'deceleration', tooltip: 'Decel. [E/km/h)^2] (-)' },
  { label: 'High speed threshold', key: 'highSpeedThreshold' },
];

const batteryParameters = [
  { key: 'maxCharge', label: 'Max charge' },
  { key: 'initialCharge', label: 'Initial charge' },
  { key: 'maxChargingVoltage', label: 'Max charging voltage' },
  { key: 'maxChargingCurrent', label: 'Max charging current' },
];

const chargingParameters = [
  { key: 'maxChargeAfterChargingStation', label: 'Max charge after charging station' },
  { key: 'minChargeAtChargingStation', label: 'Min charge at charging station' },
  { key: 'minChargeAtDestination', label: 'Min charge at destination' },
  { key: 'chargingSetupDuration', label: 'Charging setup duration' },
];

const connectorTypes = [
  { value: 'iec62196Type1Combo', label: 'SAE J1772' },
  { value: 'iec62196Type2Combo', label: 'Mennekes' },
  { value: 'Chademo', label: 'Chademo' },
  { value: 'Tesla', label: 'Tesla' },
];

class OlsConsumption extends React.Component {
  state = {
    preset: 'custom',
  };

  componentDidMount() {
    adjustLUIStyle(document.querySelectorAll('.rf-ols-consumption lui-button'), 'lui-button');
  }

  getFields(fields) {
    return fields.map(param => {
      let field = utils.getObject(this.props, `formData.fields.${param.key}`, {});
      return (
        <FormRow key={param.key}>
          <CustomInput
            label={param.label}
            value={field.value}
            isValid={field.isValid}
            onChange={this.onChange.bind(this, `${param.key}`)}
            tooltip={param.tooltip}
            tooltipPlacement="right"
            debounce
          />
        </FormRow>
      );
    });
  }

  onPresetChange = (preset) => {
    const { onMultiUpdate } = this.props;
    const value = preset.target ? preset.target.value : preset;

    this.setState({
      preset: value,
    });

    const newPreset = utils.find(olsElectricPreset, { value });
    if (!newPreset) {
      return;
    }

    onMultiUpdate(newPreset.preset);
  };

  onToggle = (e) => {
    const { onChange } = this.props;

    onChange('evEnabled', e.target.checked);
  }

  setDefaultConsumption = () => {
    const { onMultiUpdate } = this.props;
    onMultiUpdate(preset80kWh.preset);
    this.setState({
      preset: '80kwh',
    });
  }

  onChange = (key, value) => {
    this.props.onChange(key, value);
    this.setState({
      preset: 'custom',
    });
  }

  render() {
    const { formData = {}, formConfig } = this.props;
    const { fields = {} } = formData;
    const { evEnabled } = fields;

    return (
      <ExpandFormRow label="EV Parameters" cssClasses="rf-ols-consumption">
        <FormRow>
          <lui-button onClick={this.setDefaultConsumption}>Set default values</lui-button>
        </FormRow>
        <FormRow>
          <CustomCheckbox
            label="Include EV Parameters"
            isChecked={evEnabled}
            onChange={this.onToggle}
          />
        </FormRow>

        {!!olsElectricPreset.length && <FormRow>
          <CustomSelect
            label="Configuration Preset"
            options={olsElectricPreset}
            value={this.state.preset}
            onChange={::this.onPresetChange}
            notSupportedLabel="Custom"
          />
        </FormRow>}

        <FormRow>
          <CustomSelect
            label="Make Reachable"
            options={formConfig.bool}
            value={fields.makeReachable}
            addEmptyOption
            onChange={this.onChange.bind(this, 'makeReachable')}
          />
        </FormRow>

        <CheckboxAttributesSet
          label="Connector Types"
          options={connectorTypes}
          data={fields['ev[connectorTypes]']}
          isExpanded
          onChange={this.onChange.bind(this, 'ev[connectorTypes]')}
        />

        <ExpandFormRow label="Charge Aware Routing" cssClasses="rf-battery-fields">
          <ExpandFormRow label="Free Flow Speed Table">
            <SpeedGroup
              speedArray={fields.freeFlowSpeedTable.value.split(',')}
              isValid={fields.freeFlowSpeedTable.isValid}
              onChange={this.onChange.bind(this, `freeFlowSpeedTable`)}
            />
          </ExpandFormRow>
          <ExpandFormRow label="Traffic Speed Table">
            <SpeedGroup
              speedArray={fields.trafficSpeedTable.value.split(',')}
              isValid={fields.trafficSpeedTable.isValid}
              onChange={this.onChange.bind(this, `trafficSpeedTable`)}
            />
          </ExpandFormRow>

          {this.getFields(consumptionParameters)}
        </ExpandFormRow>

        <ExpandFormRow label="Battery Parameters" cssClasses="rf-battery-fields">
          <ExpandFormRow label="Charging curve">
            <SpeedGroup
              speedArray={fields.chargingCurve.value.split(',')}
              isValid={fields.chargingCurve.isValid}
              onChange={this.onChange.bind(this, `chargingCurve`)}
              vLabel="energy"
              hLabel="power"
              vTooltip="Energy"
              hTooltip="Power"
            />
          </ExpandFormRow>
          {this.getFields(batteryParameters)}
        </ExpandFormRow>

        <ExpandFormRow label="Charging Parameters" cssClasses="rf-battery-fields">
          {this.getFields(chargingParameters)}
        </ExpandFormRow>
      </ExpandFormRow>
    );
  }
}

OlsConsumption.propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onMultiUpdate: PropTypes.func.isRequired,
  formConfig: PropTypes.object.isRequired,
};

export default OlsConsumption;
