import isResultPanelShown from './resultPanel/resultPanelReducer';
import settingsData from './settingsPreset/settingsDataReducer';
import inspectLink from './inspectLink/reducer';
import reverseGeocode from './reverseGeocode/reducer';
import search from './search/reducer';
import notification from './notification/reducer';
import map from './map/reducer';
import appSettings from './appSettings/reducer';
import tabs from './tabs/reducer';
import selectedTab from './selectedTab/reducer';
import isNewTabShown from './isNewTabShown/reducer';
import isHistoryShown from './isHistoryShown/reducer';
import ui from './ui/reducer';
import urlSettings from './urlSettings/reducer';

export const appReducers = {
  isResultPanelShown,
  settingsData,
  inspectLink,
  reverseGeocode,
  search,
  notification,
  map,
  appSettings,
  tabs,
  selectedTab,
  isNewTabShown,
  isHistoryShown,
  ui,
  urlSettings,
};
