import Immutable from 'immutable';

import * as types from './constants';
import * as formDataTypes from '../../../constants';

import { defaultPointsState, pointTemplate } from './defaultState';
import { isCoordValid } from 'utils/validation/coordsValidation';

import utils from 'utils';

export function pointsReducer(state = defaultPointsState, action) {
  let { data } = action || {};
  let newState = null;
  let formatValue = data => Immutable.fromJS(data)
          .update('coords', coords => coords.merge({ isValid: isCoordValid(coords.get('value')) }));

  switch (action.type) {
    case types.POINT_UPDATE_MATRIX:
      return Immutable.fromJS(state)
        .update(data.key, list => list.update(data.index, item => item.merge(formatValue(data.value)))).toJS();

    case types.POINT_UPDATE_ALL_MATRIX:
      return Immutable.fromJS(state).update(data.key, () => Immutable.List(data.value)
        .map(value => Immutable.Map(pointTemplate).merge(formatValue(value)))).toJS();

    case types.POINT_ADD_MATRIX:
      return Immutable.fromJS(state).update(data.key, list => {
        let map = Immutable.Map(pointTemplate).merge(formatValue(data.value));
        while (list.size > 0 && list.get(list.size - 1).get('coords').get('value') === '') {
          list = list.delete(list.size - 1);
        }
        return list.push(map);
      }).toJS();

    case types.POINT_SET_MATRIX:
      newState = Immutable.fromJS(state);
      ['start', 'destination'].forEach(key => {
        newState = newState.update(key, list => {
          let _list = list.clear();
          if (key === data.key) {
            let map = Immutable.Map(pointTemplate).merge(formatValue(data.value));
            return _list.push(map);
          }
          return _list;
        });
      });
      return newState.toJS();

    case types.POINT_REMOVE_MATRIX:
      return Immutable.fromJS(state).update(data.key, list => list.delete(data.index)).toJS();

    case formDataTypes.RESTORE_FROM_URL:
      return Immutable.fromJS(state).map((list, key) => {
        let _list = utils.getObject(action.data.value, `points[${key}]`) || [];
        return _list.map(item => formatValue(item).toJS());
      }).toJS();

    default:
      return state;
  }
}

export default pointsReducer;
