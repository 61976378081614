import credentials from 'config/credentials';
import { TILE_TYPES } from '../tiles/constants';

export default {
  type: TILE_TYPES.VECTOR,
  isActive: false,
  raster: {
    host: 'https://tiles.traffic.api.here.com/traffic/6.0/tiles',
    imagesize: '256',
    imageformat: 'png',
    profile: '',
    extraparams: '',
  },
  vector: {
    url: 'traffic.vector.hereapi.com',
    path: '/v2/traffictiles/flow/mc/',
    apiKey: credentials.MAP.apiKey,
    refreshInterval: 60,
  }
};
