import PropTypes from 'prop-types';

import RoutingResultPanelForm from '../routing/ResultPanelForm';

class ResultPanelForm extends RoutingResultPanelForm {
  getRoutes(response) {
    return [response.route];
  }
}

ResultPanelForm.propTypes = {
  responseData: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  onShowFormattedResult: PropTypes.func.isRequired,
  onRemoveInspectLink: PropTypes.func.isRequired,
};

export default ResultPanelForm;
