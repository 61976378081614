import { combineReducers } from 'redux';

import fieldsProxy from '../../common/fields/proxy';
import fieldsValidation from './fields/validation';
import defaultFieldsState from './fields/defaultState';
import formsEnum from 'config/formsEnum';

let currentForm = formsEnum.GET_ROUTE_INFO;
export default combineReducers(Object.assign({}, {
  fields: fieldsProxy({ currentForm, defaultState: defaultFieldsState, validation: fieldsValidation }),
}));
