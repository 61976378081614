import PropTypes from 'prop-types';
import React, { Component } from 'react';

import utils from 'utils';

import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import ExpandFormRow from 'shared/expandFormRow';

import CheckboxAttributesSet from './CheckboxAttributesSet';

class TruckProfileBlock extends Component {
  onTruckRestrictionsChange(e) {
    let val = utils.extractData(e);
    this.props.onChange('truckRestrictionPenalty', val);
    if (!this.props.fields.routeattributes) {
      return;
    }
    let attributesChanged = false;
    let routeattributes = this.props.fields.routeattributes.attributes.slice();
    if (val === 'soft' || val === 'strict') {
      if (routeattributes.indexOf('no') === -1) {
        routeattributes.push('no');
        this.props.onChange('routeattributes', { attributes: routeattributes });
        attributesChanged = true;
      }
    } else if (routeattributes.indexOf('no') !== -1) {
      routeattributes.splice(routeattributes.indexOf('no'), 1);
      this.props.onChange('routeattributes', { attributes: routeattributes });
      attributesChanged = true;
    }
    let notesState = (val === 'soft' || val === 'strict') ? 'enabled' : 'disabled';
    if (attributesChanged) {
      this.props.setNotification({
        children: <div>
          The &quot;Notes&quot; route attribute was automatically {notesState} by this action.
        </div>,
        impact: 'significant',
      });
    }
  }

  render() {
    let { label, isHidden, formConfig, fields, onChange } = this.props,
        {
          truckType,
          trailersCount,
          shippedHazardousGoods,
          limitedWeight,
          weightPerAxle,
          axlecount,
          weightsPerAxleGroup,
          height,
          width,
          length,
          tunnelCategory,
          truckRestrictionPenalty,
          avoidturns,
        } = fields;
    return (
      <ExpandFormRow label={label} isHidden={isHidden} cssClasses="rf-grey-box">
        <FormRow>
          <CustomSelect
            label="Truck Type"
            value={truckType}
            options={formConfig.truckTypes}
            onChange={onChange.bind(this, 'truckType')}
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Trailers Count"
            type="number"
            min="0"
            max="4"
            value={trailersCount.value}
            isValid={trailersCount.isValid}
            onBlur={onChange.bind(this, 'trailersCount')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CheckboxAttributesSet
            label="Shipped Hazardous Goods"
            options={formConfig.shippedHazardousGoods}
            data={shippedHazardousGoods}
            onChange={onChange.bind(this, 'shippedHazardousGoods')}
            isExpanded
          />
        </FormRow>
        <FormRow>
          <CheckboxAttributesSet
            label="Avoid Turns"
            options={[{ label: 'difficult', value: 'difficult' }]}
            data={avoidturns}
            onChange={onChange.bind(this, 'avoidturns')}
            isExpanded
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Limited Weight (tons)"
            type="number"
            min="0"
            max="1000"
            value={limitedWeight.value}
            isValid={limitedWeight.isValid}
            onBlur={onChange.bind(this, 'limitedWeight')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Weight Per Axle (tons)"
            type="number"
            min="0"
            max="1000"
            value={weightPerAxle.value}
            isValid={weightPerAxle.isValid}
            onBlur={onChange.bind(this, 'weightPerAxle')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Weight Per Single Axle (tons)"
            type="number"
            min="0"
            max="1000"
            value={weightsPerAxleGroup.single.value}
            isValid={weightsPerAxleGroup.single.isValid}
            onBlur={onChange.bind(this, 'weightsPerAxleGroup.single')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Weight Per Tandem Axle (tons)"
            type="number"
            min="0"
            max="1000"
            value={weightsPerAxleGroup.tandem.value}
            isValid={weightsPerAxleGroup.tandem.isValid}
            onBlur={onChange.bind(this, 'weightsPerAxleGroup.tandem')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Axle Count"
            type="number"
            min="0"
            max="254"
            value={axlecount.value}
            isValid={axlecount.isValid}
            onBlur={onChange.bind(this, 'axlecount')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Height (meters)"
            type="number"
            min="0"
            max="50"
            value={height.value}
            isValid={height.isValid}
            onBlur={onChange.bind(this, 'height')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Width (meters)"
            type="number"
            min="0"
            max="50"
            value={width.value}
            isValid={width.isValid}
            onBlur={onChange.bind(this, 'width')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Length (meters)"
            type="number"
            min="0"
            max="300"
            value={length.value}
            isValid={length.isValid}
            onBlur={onChange.bind(this, 'length')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomSelect
            label="Tunnel Category"
            value={tunnelCategory}
            options={formConfig.tunnelCategories}
            onChange={onChange.bind(this, 'tunnelCategory')}
          />
        </FormRow>
        <FormRow>
          <CustomSelect
            label="Truck Restrictions"
            value={truckRestrictionPenalty}
            options={formConfig.truckRestrictionPenalty}
            onChange={::this.onTruckRestrictionsChange}
          />
        </FormRow>
      </ExpandFormRow>
    );
  }
}

TruckProfileBlock.defaultProps = {
  isHidden: false,
};

TruckProfileBlock.propTypes = {
  label: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  formConfig: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

export default TruckProfileBlock;
