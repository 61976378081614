import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomComponent from 'shared/component';

import './styles/tabs.scss';

class CustomTabs extends CustomComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    let data = nextProps.data;
    if (nextProps.currentIndex !== null) {
      return {
        currentIndex: data[nextProps.currentIndex] ? nextProps.currentIndex : data.length - 1,
      };
    }
    if (!data[prevState.currentIndex]) {
      return {
        currentIndex: data.length - 1,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    this.onSetTab = this.onSetTab.bind(this);
  }

  state = {
    currentIndex: 0,
  };

  getTabsEl() {
    return this.props.data.map((item, idx) => {
      let baseClass = 'rf-tabs__item',
        cssClasses = classnames(baseClass, {
          [`${baseClass}_active`]: idx === this.state.currentIndex,
        });
      return (
        <div
          key={`rf-tabs__item_${idx}`}
          className={cssClasses}
          data-index={idx}
        >
          { item.title }
        </div>
      );
    }, this);
  }

  onSetTab(e) {
    let currentIndex = parseInt(e.target.getAttribute('data-index'));
    if (!isNaN(currentIndex)) {
      this.setState({ currentIndex });
      this.props.onChange(currentIndex);
    }
  }

  render() {
    let { data } = this.props;

    return (
      <div className={this.getCssClasses(['rf-tabs'])} onClick={this.onSetTab}>
        <div className="rf-tabs__wrapper">{this.getTabsEl()}</div>
        <div className="rf-tabs__content">
          {data[this.state.currentIndex].content}
        </div>
      </div>
    );
  }
}

CustomTabs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  currentIndex: PropTypes.number,
};

CustomTabs.defaultProps = {
  onChange: () => {},
  currentIndex: null,
};

export default CustomTabs;
