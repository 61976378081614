import * as types from './constants';

import { assignTabDataAndDispatch } from 'state/tabs/tab/formData/actions';
import { setRoute } from 'state/tabs/tab/formData/routingTab/routingForm/currentRoute/currentRouteActions';
import { setSelectedEntity } from 'state/tabs/tab/formData/routingTab/matrixForm/selectedEntity/actions';
import ifetch from 'isomorphic-fetch';
import { setSettingsValue } from 'state/settingsPreset/settingsPresetActions';
import * as resultPanelActions from 'state/resultPanel/resultPanelActions';
import * as NotificationActions from 'state/notification/actions';

import utils from 'utils';
import fetch from 'utils/fetch';
import { RESPONSE_TIMEOUT } from 'config/main';
import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';
import { addHistory } from '../history/actions';
import { getSelectedTab } from 'state/selectedTab';
import { getSettings } from '../settings';
import { setIsLoading } from '../isLoading/actions';
import { getID } from '../id';
import { getTabIndexById } from 'state/tabs';
import { AUTH_TYPE } from '../../../../views/settingsPanel/constants';
import { getCurrentFormData } from '../formData';
import OAuth from 'oauth-1.0a';
import crypto from 'crypto';

export function receiveDataRouting(module, result) {
  return {
    type: types.RECEIVE_DATA_ROUTING,
    module,
    result,
  };
}

export function receiveDataMatrix(module, result) {
  return {
    type: types.RECEIVE_DATA_MATRIX,
    module,
    result,
  };
}

function receiveDataIsoline(module, result) {
  return {
    type: types.RECEIVE_DATA_ISOLINE,
    module,
    result,
  };
}

function receiveDataRouteInfo(module, result) {
  return {
    type: types.RECEIVE_DATA_ROUTE_INFO,
    module,
    result,
  };
}

/* eslint-disable no-trailing-spaces */
////////////////
////////////////////////////////////////////
//////////
////////////////////////////////////
///////////
///////////
////
 

//////////////////////////////////////////////////
//////////
///////////////////////////////////////////
///////////
///////////
////
 

//////////////////////////////////////////////
//////////
///////////////////////////////////////
///////////
///////////
////
 

/////////////////////////////////////////
//////////
/////////////////////////////////
///////////
///////////
////
 

//////////////////////////////////////////////
//////////
//////////////////////////////////////
///////////
///////////
////
 

/////////////////////////////////////////////
//////////
//////////////////////////////////////
///////////
///////////
////
 

////////////////////////////////////////////////////////
//////////
//////////////////////////////////////////////////
///////////
///////////
////
 

//////////
function receiveDataOlsApi(module, result) {
  return {
    type: types.RECEIVE_DATA_OLS_API,
    module,
    result,
  };
}

export function receiveDataIntermodal(module, result) {
  return {
    type: types.RECEIVE_DATA_INTERMODAL,
    module,
    result,
  };
}

/* eslint-enable no-trailing-spaces */

export function timeoutNotifier(dispatch, getState) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (getState().isLoading) {
        dispatch(NotificationActions.set({
          message: 'Server response time is slow. Do you want to cancel the request?',
          impact: 'significant',
          action: {
            label: 'Cancel',
            callback: () => {
              if (getState().isLoading) {
                dispatch(setIsLoading(false));
                reject();
              }
            },
          },
        }));
      }
    }, RESPONSE_TIMEOUT);
  });
}

export const RESPONSE_HANDLERS = {
  [modulesEnum.ROUTING]: {
    [formsEnum.CALCULATE_ROUTE]: receiveDataRouting,
    [formsEnum.CALCULATE_MATRIX]: receiveDataMatrix,
    [formsEnum.CALCULATE_ISOLINE]: receiveDataIsoline,
    [formsEnum.GET_ROUTE_INFO]: receiveDataRouteInfo,
////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////
//////////////
    [formsEnum.OLS]: receiveDataOlsApi,
  },
//////////////////
/////////////////////////
//////////////////////////////////////////
////
//////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////
////
/////////////////////////////
///////////////////////////////////////////////
////
//////////////////////
////////////////////////////////////
////
//////////////////////////////////////
//////////////////////////////////////////////
/////////////////////////////////////////////
////
////////////
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: receiveDataIntermodal,
  },
};

export function fetchToken(settings = null, callback = () => {}, errorCallback = () => {}) {
  return dispatch => {
    const { consumer_key, consumer_secret, authEndpoint } = settings.urlQuery;
    let responseStatus;

    const body = {
      grant_type: 'client_credentials'
    };
    const oauth = OAuth({
      consumer: {
        key: consumer_key, //Access key
        secret: consumer_secret, //Secret key
      },
      signature_method: 'HMAC-SHA256',
      hash_function(base_string, key) {
        return crypto
          .createHmac('sha256', key)
          .update(base_string)
          .digest('base64');
      },
    });
    const request_data = {
      url: authEndpoint,
      method: 'POST',
      data: body,
    };

    ifetch(request_data.url, {
      method: request_data.method,
      headers: {
        Authorization: oauth.toHeader(oauth.authorize(request_data)).Authorization,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'grant_type=client_credentials',
    }).then(response => {
      responseStatus = response.status;
      return response.json();
    }).then(response => {
      if (responseStatus >= 400) {
        dispatch(NotificationActions.set({
          message: `Failed to fetch token: ${JSON.stringify(response, null, 2)}`,
          impact: 'negative',
        }));
        errorCallback();
        return;
      }
      let tokenExpiryDate = new Date();
      tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + (response.expires_in || response.expiresIn));
      let tokenExpiry = tokenExpiryDate.getTime();
      callback(response.access_token || response.accessToken, tokenExpiry);
    }).catch(() => {
      dispatch(NotificationActions.set({
        message: 'Failed to fetch token.',
        impact: 'negative',
      }));
      errorCallback();
    });
  };
}

export function getToken(settings, dispatch, tabIndex, currentPreset) {
  return new Promise((resolve, reject) => {
    const { urlQuery } = settings;
    const proxy = {
      url: urlQuery.proxy,
      token: urlQuery.token,
      authType: urlQuery.authType
    };
    const isTokenExpired = urlQuery.tokenExpiry && new Date().getTime() >= urlQuery.tokenExpiry;

    if (!proxy.token || isTokenExpired) {
      dispatch(fetchToken(
        settings,
        (token, tokenExpiry) => {
          dispatch(setSettingsValue({
            value: {
              urlQuery: {
                token,
                tokenExpiry
              },
            },
            currentPreset,
          }, tabIndex));
          proxy.token = token;
          proxy.tokenExpiry = tokenExpiry;
          resolve(proxy);
        },
        reject,
      ));
    } else {
      resolve(proxy);
    }
  });
}

export function fetchData(module, currentForm, url, tabIndex, postData) {
  return (dispatch, getState) => {
    if (!url) return false; // Prevent errors
    dispatch(setIsLoading(true, tabIndex));
    let settings = getSettings(getState(), tabIndex);
    let jsonp = settings ? settings.jsonp : false;
    const { urlQuery = {} } = settings;
    let proxy = [AUTH_TYPE.APP_ID_AND_APP_CODE, AUTH_TYPE.APIKEY].indexOf(urlQuery.authType) === -1 ?
      { url: urlQuery.proxy, token: urlQuery.token, authType: urlQuery.authType, skipProxy: urlQuery.skipProxy } :
      undefined;
    const tabId = getID(getState());

    // We're checking for empty expiry for the case of manual token change
    const isTokenExpired = urlQuery.tokenExpiry && new Date().getTime() >= urlQuery.tokenExpiry;
    if (proxy && (!proxy.token || isTokenExpired)) {
      return getToken(settings, dispatch, tabIndex)
        .then(tokenData => {
          proxy = { ...proxy, ...tokenData };
          _fetchData(dispatch, getState, url, jsonp, proxy, module, currentForm, tabIndex, tabId);
        })
        .catch(() => dispatch(setIsLoading(false)));
    }

    const formData = getCurrentFormData(getState());
    const customHeaders = {};

    if (utils.getObject(formData, 'ifModifiedSince.enabled')) {
      customHeaders['If-Modified-Since'] = (new Date(formData.ifModifiedSince.date)).toUTCString();
    }

    const proxyObj = {
      url: urlQuery.proxy,
      token: urlQuery.token,
      tokenExpiry: urlQuery.tokenExpiry,
      skipProxy: urlQuery.skipProxy
    };
    return _fetchData(dispatch, getState, url, jsonp, proxyObj, module, currentForm, tabIndex, tabId, postData,
      customHeaders);
  };
}

function _fetchData(dispatch, getState, url, jsonp, proxy, module, currentForm, tabIndex, tabId, postData,
                    customHeaders) {
  return Promise.race([
    fetch(url, jsonp, proxy, postData, customHeaders),
    timeoutNotifier(dispatch, getState),
  ]).then(response => {
    tabIndex = tabIndex === undefined ? getTabIndexById(getState(), tabId) : tabIndex;
    if (tabIndex === undefined) {
      // Received a response for closed tab. Exiting.
      return;
    }
    dispatch(setIsLoading(false, tabIndex));
    if (RESPONSE_HANDLERS[module] && utils.isFunction(RESPONSE_HANDLERS[module][currentForm])) {
      dispatch(assignTabDataAndDispatch(RESPONSE_HANDLERS[module][currentForm](module, response), tabIndex));
    }

    if (currentForm === formsEnum.CALCULATE_MATRIX) {
      dispatch(setSelectedEntity(null, tabIndex));
    }
    dispatch(setRoute(0, tabIndex));

    dispatch(resultPanelActions.setVisibility(true));
    const state = getState();
    const selectedTab = tabIndex === undefined ? getSelectedTab(state) : tabIndex;
    dispatch(addHistory(url, state.tabs[selectedTab], tabIndex));
  });
}
