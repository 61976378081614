import * as types from './constants';
import Immutable from 'immutable';

export default function map(state = {}, action) {
  switch (action.type) {
    case types.SET_MAP_BOUNDS:
      return Immutable.fromJS(action.data).toJS();
    default:
      return state;
  }
}
