import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormRow from 'shared/formRow';
import CustomTextarea from 'shared/textarea';
import CustomCheckbox from 'shared/checkbox';
import CloseButton from 'shared/closeButton';

import utils from 'utils';
import adjustLUIStyle from 'utils/adjustLUIStyle';

import './styles/avoidAreasGroup.scss';

class AvoidAreasGroup extends Component {

  constructor(props) {
    super(props);
    this.onToggleAll = this.onToggleAll.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.checkTyping = this.checkTyping.bind(this);
  }

  componentDidMount() {
    adjustLUIStyle(document.querySelectorAll('.rf-avoid-areas-group lui-button'), 'lui-button');
  }

  shouldComponentUpdate(nextProps) {
    return utils.isPropChanged(nextProps, this.props, 'value');
  }

  onToggle(index, e) {
    this.props.onUpdate({ index, value: { isShown: e.target.checked }, currentForm: this.props.currentForm });
  }

  onToggleAll(e) {
    let value = this.props.value.map(() => ({ isShown: e.target.checked }));
    this.props.onUpdateAll({ value, currentForm: this.props.currentForm });
  }

  onAdd() {
    this.props.onAdd({ currentForm: this.props.currentForm });
  }

  onChange(index, e) {
    this.props.onUpdate({ index, value: { value: e.target.value }, currentForm: this.props.currentForm });
  }

  onRemove(index) {
    this.props.onRemove({ index, currentForm: this.props.currentForm });
  }

  getAreaFields(value = []) {
    return value.map((item, index) => (
        <div className="rf-avoid-areas-group__item" key={`AvoidAreasGroupItem${index}`}>
          <FormRow cssClasses="rf-avoid-areas-group__item-row">
            <CustomTextarea
              label={`Avoid Area - ${index + 1}`}
              value={item.value}
              isValid={item.isValid}
              onBlur={this.onChange.bind(this, index)}
              onKeyPress={this.checkTyping}
            />
          </FormRow>
          <FormRow cssClasses="rf-avoid-areas-group__item-row">
            <CustomCheckbox
              cssClasses="rf-avoid-areas-group__item-checkbox"
              isChecked={item.isShown}
              onChange={this.onToggle.bind(this, index)}
            />
          </FormRow>
          <CloseButton onClick={this.onRemove.bind(this, index)} />
        </div>
    ), this);
  }

  checkTyping(e) {
    let keyCode = e.which || e.keyCode || 0;
    if (keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    let { value = [] } = this.props,
        isShown = value.every(item => item.isShown);
    return (
      <div className="rf-avoid-areas-group">
        <div>
          {this.getAreaFields(this.props.value)}
        </div>
        <lui-button class="rf-avoid-areas-group__add-btn" onClick={this.onAdd}>Add New Area</lui-button>
        <CustomCheckbox
          label="Show all on the map"
          isChecked={isShown}
          onChange={this.onToggleAll}
        />
      </div>
    );
  }
}

AvoidAreasGroup.propTypes = {
  currentForm: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateAll: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default AvoidAreasGroup;
