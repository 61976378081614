import { NS as MAP_NS } from '../constants';

const NS = 'tiles';

export const SET_DATA = `${MAP_NS}/${NS}/SET_DATA`;
export const SET_TYPE = `${MAP_NS}/${NS}/SET_TYPE`;

export const USE_CIT = {
  AUTO: 'AUTO',
  YES: true,
  NO: false,
};

export const TILE_TYPES = {
  RASTER: 'raster',
  VECTOR: 'vector',
};

export const TILE_URLS = [
  'vector.hereapi.com',
  'origin.vector.hereapi.com',
  'vector.sit.hereapi.com',
  'origin.vector.sit.hereapi.com',
];

export const TILE_STYLE_URLS = [
  'https://js.api.here.com/v3/3.1/styles/omv/normal.day.yaml',
  'http://js.api.here.com/v3/3.1/styles/omv/oslo/japan/normal.day.yaml',
];
