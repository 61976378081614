import { combineReducers } from 'redux';
import inspectLink from './inspectLink/reducer';
import olsSearch from './olsSearch/reducer';
import appSettingsBase from './base/reducer';

export default combineReducers({
  base: appSettingsBase,
  inspectLink,
  olsSearch,
});
