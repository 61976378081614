export default class UnitBase {
  constructor(map, refs) {
    this.map = map;
    this.refs = refs;
  }

  init() {}

  destroy() {}
}
