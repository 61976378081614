import React from 'react';
import FormRow from '../../../shared/formRow';
import PropTypes from 'prop-types';
import getConfig from 'config/form';
import formsEnum from 'config/formsEnum';
import ExpandFormRow from 'shared/expandFormRow';
import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import './styles/olsWaypointAdvanced.scss';

const formConfig = getConfig(formsEnum.OLS);

class OlsWaypointAdvanced extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onChangePassThrough = ({ target: { value } }) => {
    const { onChange, data: { stopDuration } } = this.props;

    if (value === 'true' && stopDuration.value) {
      onChange('stopDuration', '');
    }
    onChange('passThrough', value);
  }

  onChangeStopDuration = ({ target: { value } }) => {
    const { onChange, data: { passThrough } } = this.props;

    if (value && passThrough === 'true') {
      onChange('passThrough', '');
    }

    onChange('stopDuration', value);
  }

  render() {
    const { data, onChange } = this.props;
    const {
      course, sideOfStreetHint, matchSideOfStreet, nameHint,
      radius, minCourseDistance, stopDuration, passThrough,
    } = data;

    return (
      <ExpandFormRow
        label="Advanced"
        cssClasses="rf-expanded-form__no-border"
      >
        <FormRow>
          <CustomInput
            label="Course"
            type="number"
            placeholder="Degrees, clock-wise from north"
            value={course.value}
            isValid={course.isValid}
            onBlur={e => onChange('course', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Side Of Street Hint"
            placeholder="{lat},{lng}"
            value={sideOfStreetHint.value}
            isValid={sideOfStreetHint.isValid}
            onBlur={e => onChange('sideOfStreetHint', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomSelect
            label="Match Side Of Street"
            value={matchSideOfStreet.value}
            options={formConfig.matchSideOfStreetList}
            onChange={e => onChange('matchSideOfStreet', e)}
            addEmptyOption
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Name Hint"
            value={nameHint}
            onBlur={e => onChange('nameHint', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Radius"
            type="number"
            placeholder="Meters"
            value={radius.value}
            isValid={radius.isValid}
            onBlur={e => onChange('radius', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Min Course Distance"
            type="number"
            placeholder="Meters"
            value={minCourseDistance.value}
            isValid={minCourseDistance.isValid}
            onBlur={e => onChange('minCourseDistance', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow isHidden={passThrough === undefined}>
          <CustomSelect
            label="Pass Through"
            value={passThrough}
            options={['true', 'false']}
            onChange={this.onChangePassThrough}
            addEmptyOption
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Stop Duration"
            type="number"
            placeholder="Seconds"
            value={stopDuration.value}
            isValid={stopDuration.isValid}
            onBlur={this.onChangeStopDuration}
            blurOnEnter
          />
        </FormRow>
        <div className="rf-hotisontal-line" />
      </ExpandFormRow>
    );
  }
}

export default OlsWaypointAdvanced;
