export default {
  ROUTING: 'routing',
//////////////////
////////////////////////////////////////////
///////////////////
////////////////////////////
/////////////////////
//////////////////////////
/////////////
////////////
  TRANSIT: 'transit',
  OFFLINE_TOOLS: 'offlineTools',
};
