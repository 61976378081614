import PolygonBase from '../../../modules/Polygon';

import utils from 'utils';

export default class IsolinePolygon extends PolygonBase {
  getData(nextProps) {
    return utils.getObject(nextProps, 'formData.isolineWaypoints.value') || [];
  }

  renderPolygon(nextProps) {
    if (!utils.getObject(nextProps, 'formData.isolineWaypoints.isHidden')) {
      super.renderPolygon(nextProps);
    }
  }
}
