export const FIELDS_MAPPER = {
  tunnelCategory: (val) => ({ 'truck[tunnelCategory]': val.toLowerCase() }),
  limitedWeight: (val) => ({ 'truck[grossWeight]': tToKgFormatter(val) }),
  weightPerAxle: (val) => ({ 'truck[weightPerAxle]': tToKgFormatter(val) }),
  shippedHazardousGoods: (val) => ({
    'truck[shippedHazardousGoods]': val.map((hazard) => {
      if (hazard === 'radioActive') {
        return hazard.toLowerCase();
      }
      return hazard;
    }),
  }),
  height: (val) => ({ 'truck[height]': mToCmFormatter(val) }),
  width: (val) => ({ 'truck[width]': mToCmFormatter(val) }),
  length: (val) => ({ 'truck[length]': mToCmFormatter(val) }),
  axlecount: (val) => ({ 'truck[axleCount]': getValue(val) }),
  excludecountries: (val) => ({ 'exclude[countries]': val }),
  alternatives: (val) => ({ alternatives: val }),
  metricSystem: (val) => ({ units: val }),
  language: (val) => ({ lang: val }),
  walkSpeed: (val) => ({ 'pedestrian[speed]': getValue(val) }),
  truckType: (val) => ({ 'truck[type]': val }),
  trailersCount: (val) => ({ 'truck[trailerCount]': getValue(val) }),
  licensePlate: (val) => ({ 'vehicle[licensePlate]': getValue(val) }),
};

export const ROUTING_MODE_MAPPER = {
  fastest: 'fast',
  balanced: 'short',
};

function getValue(obj) {
  return obj.value;
}

function mToCmFormatter(val) {
  const res = Math.round(+val.value * 100);
  return res ? `${res}` : '';
}

function tToKgFormatter(val) {
  const res = Math.round(+val.value * 1000);
  return res ? `${res}` : '';
}
