import * as types from '../constants';
import { normalizeRoutingData } from '../responseHandlers';

const DEFAULT_STATE = { data: { response: { route: [] } } };

export default function apiResponse(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case types.RECEIVE_DATA_ROUTING:
      return normalizeRoutingData(action.result) || DEFAULT_STATE;

    default:
      return state;
  }
}
