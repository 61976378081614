import UnitBase from './_Base';
import { calculateResolution } from '../utils/resolutionHelper';

export default class Resolution extends UnitBase {
  constructor(map, refs) {
    super(map, refs);
    this.zoom = 0;
  }

  handleViewChangeEnd(e, props) {
    if (e.target.getZoom && e.target.getZoom() !== this.zoom) {
      this.zoom = e.target.getZoom();
      this.update(e.target, props);
    }
  }

  update(map, props) {
    let { view, snap } = props.formData.fields.resolution,
        mapRes = calculateResolution(map);

    props.mapObjectUpdate({
      currentForm: props.currentForm.present,
      key: 'resolution',
      value: {
        view: { isChecked: view.isChecked, value: view.isChecked ? mapRes : view.value },
        snap: { isChecked: snap.isChecked, value: snap.isChecked ? mapRes : snap.value },
      },
    });
  }
}
