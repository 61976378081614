import handlers from './customHandlers';
import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';
////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////
import intermodalDefaultState from 'state/tabs/tab/formData/transitTab/intermodalForm/fields/defaultState';

import formConfig from 'config/form';

const config = formConfig();
const matrixConfig = formConfig(formsEnum.CALCULATE_MATRIX);

/**
 * dest - determines into which key parsed value will be assigned
 * fn - function that will receive [key, value] that was parsed from url
 *      and return the object after parsing the value
 * accumulated - flag which determines whether values will be assigned to a single attribute
 *               e.g. (waypoint0=...&waypoint1=... will be assigned to waypoint)
 */
export default {
  [modulesEnum.ROUTING]: {
    [formsEnum.CALCULATE_ROUTE]: {
      departure: { dest: 'routeDate', fn: handlers.splitArrayToObject },
      arrival: { dest: 'routeDate', fn: handlers.splitArrayToObject },
      waypoint: [
        { dest: 'waypoints', accumulated: true, fn: handlers.gatherWaypoints },
        { dest: 'useNewWaypointsFormat', accumulated: false, fn: handlers.isNewPointsFormat.bind(this, 'waypoint') },
      ],
      mode: { fn: handlers.splitModeParams },
      routeattributes: { fn: handlers.splitAttributesParamsFor(config.routeAttributes) },
      legattributes: { fn: handlers.splitAttributesParamsFor(config.legAttributes) },
      maneuverattributes: { fn: handlers.splitAttributesParamsFor(config.maneuverAttributes) },
      linkattributes: { fn: handlers.splitAttributesParamsFor(config.linkAttributes) },
      lineattributes: { fn: handlers.splitAttributesParamsFor(config.lineAttributes) },
      resolution: { fn: handlers.splitResolutionParams },
      generalizationtolerances: { fn: handlers.prettifySequence },
      avoidareas: { dest: 'avoidAreas', fn: handlers.gatherAreas },
      shippedhazardousgoods: { dest: 'shippedHazardousGoods', fn: handlers.splitArray },
      avoidturns: { fn: handlers.splitArray },
      excludezonetypes: { fn: handlers.splitArray },
      customconsumptiondetails: { fn: handlers.splitConsumptionParams('customconsumptiondetails') },
      batteryparameters: { fn: handlers.splitConsumptionParams('batteryparameters') },
      chargingstationfilters: { fn: handlers.splitChargingStationFilters },
      jsonattributes: { fn: handlers.normalizeJsonAttributes },
      avoidtransporttypes: { dest: 'avoidTransportTypes', fn: handlers.splitArray },
      vehicletype: { fn: handlers.vehicleType },
      weightsperaxlegroup: { fn: handlers.weightsPerAxleGroup },
    },
    [formsEnum.CALCULATE_MATRIX]: {
      start: [
        { dest: 'startPoints', accumulated: true, fn: handlers.parsePoints },
        { dest: 'useNewPointsFormat', accumulated: false, fn: handlers.isNewPointsFormat.bind(this, 'start') },
      ],
      destination: [
        { dest: 'destinationPoints', accumulated: true, fn: handlers.parsePoints },
        { dest: 'useNewPointsFormat', accumulated: false, fn: handlers.isNewPointsFormat.bind(this, 'start') },
      ],
      departure: { dest: 'routeDate', fn: handlers.splitArrayToObject },
      arrival: { dest: 'routeDate', fn: handlers.splitArrayToObject },
      avoidareas: { dest: 'avoidAreas', fn: handlers.gatherAreas },
      mode: { fn: handlers.splitModeParams },
      matrixattributes: {
        dest: 'matrixAttributes',
        fn: handlers.splitArrayFor(matrixConfig.matrixAttributes),
      },
      summaryattributes: {
        dest: 'summaryAttributes',
        fn: handlers.splitArrayFor(matrixConfig.summaryAttributes),
      },
      shippedhazardousgoods: { dest: 'shippedHazardousGoods', fn: handlers.splitArray },
      weightsperaxlegroup: { fn: handlers.weightsPerAxleGroup },
    },
    [formsEnum.CALCULATE_ISOLINE]: {
      start: { dest: 'waypoints', accumulated: true, fn: handlers.gatherIsolineWaypoints },
      destination: { dest: 'waypoints', accumulated: true, fn: handlers.gatherIsolineWaypoints },
      departure: { dest: 'routeDate', fn: handlers.splitArrayToObject },
      arrival: { dest: 'routeDate', fn: handlers.splitArrayToObject },
      mode: { fn: handlers.splitModeParams },
      customconsumptiondetails: { fn: handlers.splitConsumptionParams('customconsumptiondetails') },
      batteryparameters: { fn: handlers.splitConsumptionParams('batteryparameters') },
      chargingstationfilters: { fn: handlers.splitChargingStationFilters },
      jsonattributes: { fn: handlers.normalizeJsonAttributes },
      shippedhazardousgoods: { dest: 'shippedHazardousGoods', fn: handlers.splitArray },
      weightsperaxlegroup: { fn: handlers.weightsPerAxleGroup },
    },
    [formsEnum.GET_ROUTE_INFO]: {
      departure: { dest: 'routeDate', fn: handlers.splitArrayToObject },
      arrival: { dest: 'routeDate', fn: handlers.splitArrayToObject },
      maneuverattributes: { fn: handlers.splitAttributesParamsFor(config.maneuverAttributes) },
      routeattributes: { fn: handlers.splitAttributesParamsFor(config.routeAttributes) },
      generalizationtolerances: { fn: handlers.prettifySequence },
      legattributes: { fn: handlers.splitAttributesParamsFor(config.legAttributes) },
      linkattributes: { fn: handlers.splitAttributesParamsFor(config.linkAttributes) },
      resolution: { fn: handlers.splitResolutionParams },
      jsonattributes: { fn: handlers.normalizeJsonAttributes },
      mode: { fn: handlers.splitModeParams },
    },
    [formsEnum.APPROACH_PATH_ROUTER]: {
      traceattributes: { fn: handlers.splitArray, dest: 'traceAttributes' },
      linkattributes: { fn: handlers.splitArray, dest: 'linkAttributes' },
      countlimit: { fn: handlers.splitArray, dest: 'countLimit' },
      lengthlimit: { fn: handlers.splitArray, dest: 'lengthLimit' },
      timelimit: { fn: handlers.splitArray, dest: 'timeLimit' },
    },
    [formsEnum.MATCH_ROUTE]: {
      maneuverattributes: { fn: handlers.splitAttributesParamsFor(config.maneuverAttributes) },
      routeattributes: { fn: handlers.splitAttributesParamsFor(config.routeAttributes) },
      generalizationtolerances: { fn: handlers.prettifySequence },
      legattributes: { fn: handlers.splitAttributesParamsFor(config.legAttributes) },
      linkattributes: { fn: handlers.splitAttributesParamsFor(config.linkAttributes) },
      jsonattributes: { fn: handlers.normalizeJsonAttributes },
    },
    [formsEnum.OLS]: {
      avoid: { dest: 'avoid', fn: handlers.splitArray },
      return: { dest: 'olsReturn', fn: handlers.splitArray },
      spans: { dest: 'spans', fn: handlers.splitArray },
      'avoid[features]': { fn: handlers.splitArray },
      'avoid[areas]': { fn: handlers.olsAvoidAreas },
      mode: { fn: handlers.splitModeParams },

      'ev[freeflowspeedtable]': { dest: 'freeFlowSpeedTable' },
      'ev[trafficspeedtable]': { dest: 'trafficSpeedTable' },
      'ev[auxiliaryconsumption]': { dest: 'auxiliaryConsumption' },
      'ev[ascent]': { dest: 'ascent' },
      'ev[descent]': { dest: 'descent' },
      'ev[acceleration]': { dest: 'acceleration' },
      'ev[deceleration]': { dest: 'deceleration' },
      'ev[highspeedthreshold]': { dest: 'highSpeedThreshold' },

      'ev[initialcharge]': { dest: 'initialCharge' },
      'ev[maxcharge]': { dest: 'maxCharge' },
      'ev[chargingcurve]': { dest: 'chargingCurve' },
      'ev[maxchargingvoltage]': { dest: 'maxChargingVoltage' },
      'ev[maxchargingcurrent]': { dest: 'maxChargingCurrent' },

      'ev[maxchargeafterchargingstation]': { dest: 'maxChargeAfterChargingStation' },
      'ev[minchargeatchargingstation]': { dest: 'minChargeAtChargingStation' },
      'ev[minchargeatdestination]': { dest: 'minChargeAtDestination' },
      'ev[chargingsetupduration]': { dest: 'chargingSetupDuration' },

      'ev[makereachable]': { dest: 'makeReachable' },
      'ev[connectortypes]': { dest: 'ev[connectorTypes]', fn: handlers.splitArray },

      'truck[shippedhazardousgoods]': { dest: 'truck[shippedHazardousGoods]', fn: handlers.splitArray },
      'truck[tunnelcategory]': { dest: 'truck[tunnelCategory]' },
      'truck[axlecount]': { dest: 'truck[axleCount]' },
      'truck[weightperaxle]': { dest: 'truck[weightPerAxle]' },
      'truck[grossweight]': { dest: 'truck[grossWeight]' },
      via: { accumulated: true },
      'vehicle[hovoccupancy]': { dest: 'vehicle[hovOccupancy]' },
    },
  },
//////////////////
//////////////////////////
////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////////
//////
////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////
////
//////////////////////////////
//////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////////////////////
//////
////
//////////////////////////////////////
///////////////////////////
/////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////////////////////
//////
///////////////////////////
//////////////////////////////////////////////////////////////
//////
////
/////////////////////////////
////////////////////////////
//////////////////////////////////////////////////
//////
////
////////////
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: {
      origin: { fn: handlers.coordinate },
      destination: { fn: handlers.coordinate },
      intermodalModes: { fn: handlers.getHandlerForMultiCheckboxes(intermodalDefaultState.intermodalModes) },
      transitModes: { fn: handlers.getHandlerForMultiCheckboxes(intermodalDefaultState.transitModes) },
      return: { dest: 'olsReturn', fn: handlers.splitArray },
    },
  },
};
