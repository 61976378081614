import handlers from './customHandlers';
import truckFormatter from './common/truckFormatter';

export default {
  ...truckFormatter,
  jsonattributes: handlers.jsonattributesFormatter,
  maneuverattributes: handlers.attributesFormatter,
  routeattributes: handlers.attributesFormatter,

  generalizationtolerances: handlers.generalisationsFormatter,
  legattributes: handlers.attributesFormatter,
  linkattributes: handlers.attributesFormatter,
  lineattributes: handlers.attributesFormatter,
  resolution: handlers.resolutionFormatter,
  mode: handlers.modeFormatter,
};
