import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'shared/toggleButton';
import './styles/autoZoomToggler.scss';

class AutoZoomToggler extends Component {
  onToggle(e) {
    this.props.setSettings({ isAutoZoomOn: e.target.checked });
  }

  render() {
    return <div className="rf-auto-zoom">
      <span className="rf-auto-zoom__label">Auto Zoom:</span>
      <ToggleButton
        defaultChecked={this.props.isAutoZoomOn}
        onChange={::this.onToggle}
      />
    </div>;
  }
}

AutoZoomToggler.propTypes = {
  isAutoZoomOn: PropTypes.bool.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default AutoZoomToggler;
