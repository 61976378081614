import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';
import Modal from 'shared/modal';
import CustomSelect from 'shared/select';
import getConfig from 'config/form';

import './styles.scss';

const config = getConfig();

const modulesToShow = [];
config.modules.forEach(module => modulesToShow.push(...config[module].forms.map(form => ({ form, module }))));

class SelectModulePopup extends CustomComponent {
  static propTypes = {
    settingsData: PropTypes.object.isRequired,
  };

  state = {
    isShown: false,
    selectedPreset: '',
    selectedModule: '',
  };

  show({ preset = '', form, module }) {
    this.setState({ isShown: true, selectedPreset: preset });
    if (form && module) {
      const module = modulesToShow.find(moduleData => moduleData.module === module && moduleData.form === form);
      const index = modulesToShow.indexOf(module);
      if (index !== -1) {
        this.setState({ selectedModule: index });
      }
    }
    return new Promise(resolve => {
      this.done = resolve;
    });
  }

  onSave = () => {
    this.setState({ isShown: false });
    this.done({ ...modulesToShow[this.state.selectedModule], preset: this.state.selectedPreset });
  };

  getPresetSelector() {
    const { form, module } = modulesToShow[this.state.selectedModule];
    const presets = this.props.settingsData.settingsPresets[module][form];
    return <CustomSelect
      options={Object.keys(presets).map(presetName => ({ label: presets[presetName].title, value: presetName }))}
      value={this.state.selectedPreset}
      onChange={e => this.setState({ selectedPreset: e.target.value })}
      addEmptyOption
    />;
  }

  render() {
    return (
      <div className="rf-select-module-popup">
      <lui-default-theme dark>
        <Modal
          title="Select Module and Preset"
          isShown={this.state.isShown}
          cssClass="rf-select-module-popup"
        >

          <CustomSelect
            options={modulesToShow.map((moduleAndForm, key) => {
              const moduleName = config[moduleAndForm.module].label;
              const formName = config[moduleAndForm.module].formTemplates[moduleAndForm.form].label;
              return {
                label: `${moduleName} - ${formName}`,
                value: `${key}`,
              };
            })}
            value={this.state.selectedModule}
            onChange={e => this.setState({ selectedModule: e.target.value })}
            addEmptyOption
          />

          {this.state.selectedModule && this.getPresetSelector()}

          <lui-button-group class="rf-select-module__modal-button-group">
            <lui-button
              onClick={this.onSave}
              secondary
              disabled={!this.state.selectedModule || !this.state.selectedPreset}
            >Save
            </lui-button>
          </lui-button-group>
        </Modal>
      </lui-default-theme>
      </div>
    );
  }
}

export default SelectModulePopup;
