import { assignTabDataAndDispatch } from '../../../actions';

const SET = 'currentIsoline/SET';

export default function reducer(state = 0, action) {
  switch (action.type) {
    case SET:
      return action.currentIsoline;

    default:
      return state;
  }
}

export function setIsoline(currentIsoline) {
  return assignTabDataAndDispatch({ type: SET, currentIsoline });
}
