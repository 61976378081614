import { combineReducers } from 'redux';

import fieldsProxy from '../../common/fields/proxy';
import defaultFieldsState from './fields/defaultState';
import fieldsValidation from './fields/validation';

import waypointsProxy from '../../common/waypoints/proxy';
import { defaultWaypointState as defaultState } from './waypoints/defaultState';
import formsEnum from 'config/formsEnum';
import currentIsoline from './currentIsoline';

let currentForm = formsEnum.CALCULATE_ISOLINE;
export default combineReducers(Object.assign({}, {
  fields: fieldsProxy({
    currentForm,
    hasTruckFields: true,
    defaultState: defaultFieldsState,
    validation: fieldsValidation,
  }),
  waypoints: waypointsProxy({ currentForm, defaultState }),
  currentIsoline,
}));
