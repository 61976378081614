import React, { Component } from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';

import ManeuversListItem from './ManeuversListItem';
import uManeuversList from 'utils/response/maneuversList';
import utils from 'utils';

import './styles/maneuversList.scss';

export class ManeuversList extends Component {

  constructor(...args) {
    super(...args);
    this.state = {
      selectedItem: null,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!utils.isEqual(nextProps, this.props)) {
      this.props.highlightManeuver();
      this.setState({
        selectedItem: null,
      });
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.props.highlightManeuver();
  }

  onClick(id, shape) {
    if (this.state.selectedItem !== id) {
      this.props.highlightManeuver(shape);
    } else {
      this.props.highlightManeuver();
    }

    this.setState({
      selectedItem: this.state.selectedItem === id ? null : id,
    });
  }

  getManeuverGroupsEl() {
    let data = this.props.data,
      maneuversList = uManeuversList(_.get(data, 'leg[0].maneuver', []));
    let { shape = [], maneuverGroup = [] } = data;
    this.isPublicTransportChecked = !!data.publicTransportLine;

    return maneuverGroup.map((group, idx) => {
      let maneuvers = maneuversList.slice(group.firstManeuver, group.lastManeuver);

      return (
        <li className="rf-maneuvers-list__group" key={`rf-maneuvers-list__group_${idx}`}>
          <div className="rf-maneuvers-list__group-summary">{striptags(group.summaryDescription)}</div>
          <div className="rf-maneuvers-list__group-wait">{striptags(group.waitDescription)}</div>
          <ul className="rf-maneuvers-list__group-items">
            {this.getManeuversEl(maneuvers, group, shape)}
          </ul>
          <div className="rf-maneuvers-list__group-arrival">{striptags(group.arrivalDescription)}</div>
        </li>
      );
    }, this);
  }

  getMessages() {
    let messages = [];
    if (!this.hasDirection()) {
      messages.push(<div className="rf-maneuvers-list__message" key="no-direction">
        No information about maneuvers direction was requested from server.<br />
        In order to fetch this information, please
        select <b>Route attributes -&gt; Maneuver Groups, Leg attributes -&gt; Maneuvers</b> in the Output params.
      </div>);
    }
    return messages;
  }

  getManeuversEl(maneuvers, group, shapes) {
    let { selectedItem } = this.state;
    let { pointArgsCount } = this.props;

    return maneuvers.map((item, idx) => {
      let shape = shapes.slice(item.firstPoint * pointArgsCount, (item.lastPoint + 1) * pointArgsCount);
      if (pointArgsCount > 2) {
        shape = shape.filter((coord, index) => (index + 1) % pointArgsCount);
      }
      let isDisabled = shape.length === 0;

      return <ManeuversListItem
        key={`rf-maneuvers-list-item_${idx}`}
        data={item}
        isPublicTransportChecked={this.isPublicTransportChecked}
        groupPublicTransport={group.publicTransportType}
        onClick={isDisabled ? () => { /* noOp */ } : this.onClick.bind(this, idx, shape)}
        isSelected={idx === selectedItem}
        isDisabled={isDisabled}
      />;
    }, this);
  }

  hasDirection() {
    return !!this.props.data.maneuverGroup;
  }

  render() {
    return (
      <ul className="rf-maneuvers-list">
        {this.getMessages()}
        {this.getManeuverGroupsEl()}
      </ul>
    );
  }
}

ManeuversList.propTypes = {
  data: PropTypes.object.isRequired,
  highlightManeuver: PropTypes.func.isRequired,
  pointArgsCount: PropTypes.number.isRequired,
};

export default ManeuversList;
