import utils from 'utils';

import preprocessors from './preprocessor';
import getFormatter from './formatter';
import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';
import { AUTH_TYPE } from '../../../views/settingsPanel/constants';

let mergeMap = {
  [modulesEnum.ROUTING]: {
    [formsEnum.CALCULATE_ROUTE]: (formData, settingsData) => {
      let { fields = {}, waypoints, avoidAreas, rangeOnRoute } = formData;
      let preprocessedFields = preprocessors.routing.routing(Object.assign(fields, { rangeOnRoute }));
      return Object.assign({}, preprocessedFields, { waypoints, avoidAreas }, settingsData);
    },

    [formsEnum.CALCULATE_MATRIX]: (formData, settingsData) => {
      delete formData.fields.points;
      return Object.assign({}, preprocessors.routing.matrix(formData.fields), formData.points,
        { avoidAreas: formData.avoidAreas }, settingsData);
    },

    [formsEnum.CALCULATE_ISOLINE]: (formData, settingsData) => Object.assign({},
      preprocessors.routing.isoline(formData.fields),
      { waypoints: formData.waypoints }, settingsData
    ),

    [formsEnum.GET_ROUTE_INFO]: (formData, settingsData) => Object.assign({},
      preprocessors.routing.routeInfo(formData.fields), settingsData
    ),

////////////////////
///////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
//////

//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////
//////////////
    [formsEnum.OLS]: (formData, settingsData) => Object.assign({},
      preprocessors.routing.olsApi(formData.fields), settingsData
    ),
  },
//////////////////
/////////////////////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////
////
//////////////////////////
////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
//////
////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//////
////
/////////////////////////////
////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
//////
////
//////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////
////
//////////////////////////////////////
///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//////
///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
//////
////
//////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////
////
////////////
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: (formData, settingsData) => Object.assign({},
      preprocessors.transit.intermodal(formData.fields), settingsData
    ),
  }
};

function mergeValues(formData, settingsData, module, currentForm, presetData) {
  if (mergeMap[module] && utils.isFunction(mergeMap[module][currentForm])) {
    return mergeMap[module][currentForm](formData, settingsData, presetData);
  }
  return Object.assign({}, formData.fields, settingsData);
}

export default function stringifyQuery(formData = {}, settingsData = {}, module = '', currentForm = '') {
  const filteredSettings = filterSettings(settingsData);
  let values = mergeValues(formData, filteredSettings, module, currentForm);
  let queryParams = Object.keys(values)
      .filter(key => !!values[key]) // TODO: refactor this
      .map(key => {
        let node = values[key];
        let isObject = utils.isObject(node);
        let saveFormatting = Array.isArray(node) ? node.some(i => i.saveFormatting) : node.saveFormatting;
        return {
          key: node.key !== undefined ? node.key : key,
          value: node.value === undefined ? node : node.value,
          isValid: node.isValid,
          saveFormatting,
          isObject,
        };
      })
      .filter(args => (args.isObject ? args.key : !!args.value))
      .map(args => getFormatter(args, formData, module, currentForm))
      .filter(Boolean);
  return { queryParams: utils.uniq(queryParams).join('&') };
}

function filterSettings(params) {
  if (!params) {
    return {};
  }
  if (params.authType === AUTH_TYPE.APP_ID_AND_APP_CODE) {
    return {
      app_code: params.app_code,
      app_id: params.app_id,
    };
  }
  if (params.authType === AUTH_TYPE.APIKEY) {
    return {
      apikey: params.apikey,
    };
  }

  return {};
}
