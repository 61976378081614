import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormRow from 'shared/formRow';
import CustomTabs from 'shared/tabs';
import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import CustomTooltip from 'shared/tooltip';
import Columns from 'shared/columns';
import RadioGroup from 'shared/radioGroup';

import AttributesSet from './AttributesSet';
import GeneralisationsField from './GeneralisationsField';
import ResolutionField from './ResolutionField';
import JsonAttributesBlock from './JsonAttributesBlock';

import utils from 'utils';
import getConfig from 'config/form';
import CustomCheckbox from '../../shared/checkbox';

export class _RoutingFormBase extends Component {
  state = {
    tabIndex: 0,
  };

  onChange(key, e, needsRerender = false) {
    this.props.filterValueUpdate({
      key,
      currentForm: this.props.currentForm,
      value: utils.extractData(e),
      needsRerender,
    });
  }

  onElevationChange(e) {
    let val = utils.extractData(e);
    let bool = val === 'true';
    this.props.filterValueMultiUpdate(this.props.currentForm, {
      returnelevation: val,
      jsonattributes: {
        shapesToArraysWithPairs: !bool,
        shapesToArraysWithTriplets: bool,
      },
    });
  }

  onModeChange(attrKey, e) {
    this.onChange('mode', { [attrKey]: utils.extractData(e) });
  }

  onVehicleTypeChange(e) {
    const val = utils.extractData(e);
    const { fields = {} } = this.props.formData;
    const { routeattributes = {}, vehicletype } = fields;
    const { attributes: routeAttr = [] } = routeattributes;
    const smIndex = routeAttr.indexOf('sm');
    let action = null;
    let attributes = [...routeAttr];

    if (val && smIndex === -1) {
      action = 'enabled';
      attributes.push('sm');
    } else if (!val && smIndex > -1) {
      action = 'disabled';
      attributes.splice(smIndex, 1);
    }

    this.props.filterValueMultiUpdate(this.props.currentForm, {
      vehicletype: {
        ...vehicletype,
        type: val,
      },
      routeattributes: {
        ...routeattributes,
        attributes,
      },
    });
    this.props.setNotification({
      message: `The "Summary" route attribute was automatically ${action} by this action.`,
      impact: 'significant',
    });
  }

  onAttrChange(dependentKey, dependentValue, parentKey, parentValue, key, e) {
    const attr = _.get(this.props, `formData.fields.${parentKey}`, {});
    const { aggregation, attributes = [] } = attr;
    let shouldNotify = false;
    if (aggregation === 'none' && attributes.indexOf(dependentKey) === -1) {
      this.props.filterValueMultiUpdate(this.props.currentForm, {
        [key]: utils.extractData(e),
        [parentKey]: {
          ...attr,
          attributes: [...attributes, dependentKey],
        },
      });
      shouldNotify = true;
    } else if (aggregation === 'all' && attributes.indexOf(dependentKey) !== -1) {
      const mnIndex = attributes.indexOf(dependentKey);
      this.props.filterValueMultiUpdate(this.props.currentForm, {
        [key]: utils.extractData(e),
        [parentKey]: {
          ...attr,
          attributes: [...attributes.slice(0, mnIndex), ...attributes.slice(mnIndex + 1)],
        },
      });
      shouldNotify = true;
    } else {
      this.onChange(key, e);
    }
    if (shouldNotify) {
      this.props.setNotification({
        children: <div>
          The &quot;{dependentValue}&quot; {parentValue} attribute was automatically enabled by this action.
        </div>,
        impact: 'significant',
      });
    }
  }

  onLineAttrChange(e) {
    const isTransportMode = _.get(this.props, 'formData.fields.mode.transport') === 'publicTransport';
    if (isTransportMode) {
      this.onAttrChange('li', 'Lines', 'routeattributes', 'route', 'lineattributes', e);
      return;
    }
    this.props.setNotification({
      children: <div>
        Line attributes are available only for public transport mode.
      </div>,
      impact: 'significant',
    });
  }

  onTabChange(tabIndex) {
    this.setState({ tabIndex });
  }

  getRequestTabContent() {}

  getMiscellaneousTabContent(formConfig) {
    let { fields = {} } = this.props.formData,
      {
        combinechange,
        returnelevation,
        metricsystem,
        alternatives,
        generalizationtolerances,
        resolution,
        distancebeforefirstturn,
        avoidseasonalclosures,
        maxnumberofchanges,
        walkradius,
        walkspeed,
        licenseplate,
        extraparams,
        mode = {},
        vehicletype = {},
        computemltraffictime,
      } = fields,
      isPublicTransport = mode.transport === 'publicTransport' || mode.transport === 'publicTransportTimeTable',
      isPTorWalk = mode.transport === 'pedestrian' || isPublicTransport,
      alternativesContent = this.getAlternativesContent(alternatives);
    const { consumption: vehicleTypeConsumption = {} } = vehicletype;
    return (
      <div>
        <FormRow isHidden={!isPublicTransport}>
          <CustomSelect
            label="Combine change"
            options={formConfig.bool}
            value={combinechange}
            addEmptyOption
            onChange={this.onChange.bind(this, 'combinechange')}
          />
        </FormRow>
        <Columns>
          <FormRow>
            <CustomSelect
              label="Elevation profile"
              options={formConfig.bool}
              value={returnelevation}
              addEmptyOption
              onChange={::this.onElevationChange}
            />
          </FormRow>
          <FormRow>
            {alternativesContent}
          </FormRow>
        </Columns>
        <FormRow>
          <RadioGroup
            label="Measure system"
            options={formConfig.metricsystems}
            value={metricsystem}
            onChange={this.onChange.bind(this, 'metricsystem')}
          />
        </FormRow>
        <FormRow>
          <GeneralisationsField
            label="Generalisations"
            data={generalizationtolerances}
            options={formConfig.generalisationPresets}
            onChange={this.onChange.bind(this, 'generalizationtolerances')}
          />
        </FormRow>
        <FormRow>
          <ResolutionField
            label="Resolution [m/px]"
            value={resolution}
            onChange={this.onChange.bind(this, 'resolution')}
          />
        </FormRow>
        <Columns>
          <FormRow isHidden={isPTorWalk}>
            <CustomInput
              min="0"
              type="number"
              label="Distance to Turn [m]"
              value={distancebeforefirstturn}
              onBlur={this.onChange.bind(this, 'distancebeforefirstturn')}
              blurOnEnter
            />
          </FormRow>
        </Columns>
        <FormRow>
          <CustomCheckbox
            label="ML traffic time"
            onChange={this.onChange.bind(this, 'computemltraffictime')}
            isChecked={computemltraffictime}
          />
        </FormRow>
        <FormRow isHidden={isPTorWalk}>
          <CustomSelect
            label="Avoid seasonal closures"
            options={formConfig.bool}
            value={avoidseasonalclosures}
            addEmptyOption
            onChange={this.onChange.bind(this, 'avoidseasonalclosures')}
          />
        </FormRow>
        <FormRow>
          <CustomSelect
            label="Vehicle type"
            options={formConfig.vehicleTypes}
            value={vehicletype.type}
            addEmptyOption
            onChange={::this.onVehicleTypeChange}
          />
          {vehicletype.type && <CustomInput
            type="number"
            label="Vehicle type consumption"
            isValid={vehicleTypeConsumption.isValid}
            value={vehicleTypeConsumption.value}
            onBlur={this.onChange.bind(this, 'vehicletype.consumption')}
            blurOnEnter
          />}
        </FormRow>
        <FormRow isHidden={!isPublicTransport}>
          <CustomInput
            min="0"
            max="10"
            type="number"
            label="Max number of changes"
            isValid={maxnumberofchanges.isValid}
            value={maxnumberofchanges.value}
            onBlur={this.onChange.bind(this, 'maxnumberofchanges')}
            blurOnEnter
          />
        </FormRow>
        <FormRow isHidden={!isPublicTransport}>
          <CustomInput
            min="0"
            max="6000"
            type="number"
            label="Walk Radius (meters)"
            isValid={walkradius.isValid}
            value={walkradius.value}
            onBlur={this.onChange.bind(this, 'walkradius')}
            blurOnEnter
          />
        </FormRow>
        <FormRow isHidden={!isPTorWalk}>
          <CustomInput
            min="0.5"
            max="2"
            step="0.01"
            type="number"
            label="Walk Speed [m/s]"
            isValid={walkspeed.isValid}
            value={walkspeed.value}
            onBlur={this.onChange.bind(this, 'walkspeed')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            type="text"
            label="License plate last character"
            placeholder="Enter character"
            isValid={licenseplate.isValid}
            maxLength="1"
            value={licenseplate.value}
            onBlur={this.onChange.bind(this, 'licenseplate')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            type="text"
            label="Extra params"
            placeholder="Enter parameters"
            isValid={extraparams.isValid}
            value={extraparams.value}
            onBlur={this.onChange.bind(this, 'extraparams')}
            blurOnEnter
          />
        </FormRow>
      </div>
    );
  }

  getResponseTabContent(currentForm, formConfig) {
    let { fields = {} } = this.props.formData,
      {
        routeattributes,
        legattributes,
        maneuverattributes,
        linkattributes,
        lineattributes,
        jsonattributes,
      } = fields;

    return (
      <div>
        <ul className="rf-icons-bar" />
        <FormRow>
          <AttributesSet
            label="Route attributes"
            attributes={formConfig.routeAttributes}
            attributeAggregation={formConfig.attributeAggregation}
            value={routeattributes}
            onChange={this.onChange.bind(this, 'routeattributes')}
          />
        </FormRow>
        <FormRow>
          <AttributesSet
            label="Leg attributes"
            attributes={formConfig.legAttributes}
            attributeAggregation={formConfig.attributeAggregation}
            value={legattributes}
            onChange={this.onAttrChange.bind(this, 'lg', 'Legs', 'routeattributes', 'route', 'legattributes')}
          />
        </FormRow>
        <FormRow>
          <AttributesSet
            label="Maneuver attributes"
            attributes={formConfig.maneuverAttributes}
            attributeAggregation={formConfig.attributeAggregation}
            value={maneuverattributes}
            onChange={this.onAttrChange.bind(this, 'mn', 'Maneuver', 'legattributes', 'leg', 'maneuverattributes')}
          />
        </FormRow>
        <FormRow>
          <AttributesSet
            label="Link attributes"
            attributes={formConfig.linkAttributes}
            attributeAggregation={formConfig.attributeAggregation}
            value={linkattributes}
            onChange={this.onAttrChange.bind(this, 'li', 'Link', 'legattributes', 'leg', 'linkattributes')}
          />
        </FormRow>
        <FormRow>
          <AttributesSet
            label="Line attributes"
            attributes={formConfig.lineAttributes}
            attributeAggregation={formConfig.attributeAggregation}
            value={lineattributes}
            onChange={::this.onLineAttrChange}
          />
        </FormRow>
        {!!jsonattributes && <JsonAttributesBlock
          jsonAttributes={jsonattributes}
          onChange={::this.onChange}
          attributes={formConfig.jsonAttributes}
        />}
      </div>
    );
  }

  getAlternativesContent(alternatives) {
    let { waypoints } = this.props.formData;
    let hasIntermidWaypoints = !!waypoints.find(point => point.type === 'passThrough');
    let form = (
      <CustomInput
        type="number"
        label="Alternative routes"
        min="0"
        max="9"
        step="1"
        placeholder="0-9"
        value={alternatives}
        onBlur={this.onChange.bind(this, 'alternatives')}
        isReadonly={hasIntermidWaypoints}
        blurOnEnter
      />
    );
    if (hasIntermidWaypoints) {
      return (
        <CustomTooltip
          placement="right"
          tooltip="You can't specify alternative routes when intermediate waypoint is specified."
        >
          <div>{form}</div>
        </CustomTooltip>
      );
    }
    return form;
  }

  render() {
    let { currentForm } = this.props;
    let formConfig = getConfig(currentForm);
    let tabs = [
      { title: 'Input Params', content: this.getRequestTabContent(currentForm, formConfig) },
      { title: 'Output Params', content: this.getResponseTabContent(currentForm, formConfig) },
    ];
    return (
      <CustomTabs data={tabs} currentIndex={this.state.tabIndex} onChange={::this.onTabChange} />
    );
  }
}

_RoutingFormBase.propTypes = {
  formData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

export default _RoutingFormBase;
