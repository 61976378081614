import Immutable from 'immutable';

import * as types from './constants';
import * as formDataTypes from 'state/tabs/tab/formData/constants';

import { defaultAvoidAreasState, avoidAreaTemplate } from './defaultState';

import { isCoordsValid } from 'utils/validation/coordsValidation';

import utils from 'utils';

export function avoidAreasData(state = defaultAvoidAreasState, action) {
  let { data } = action || {},
      _state,
      formatValue = (data = {}, mergeWithTemplate = false) => {
        let item = Object.assign({}, data, { isValid: isCoordsValid(data.value || '') });
        return mergeWithTemplate ? Immutable.Map(avoidAreaTemplate).merge(item) : item;
      };

  switch (action.type) {
    case types.AVOID_AREA_UPDATE:
      return Immutable.fromJS(state).update(data.index, item => item.merge(formatValue(data.value))).toJS();

    case types.AVOID_AREA_UPDATE_ALL:
      return Immutable.fromJS(state).map((item, index) => item.merge(formatValue(data.value[index]))).toJS();

    case types.AVOID_AREA_ADD: {
      _state = Immutable.fromJS(state);
      let lastItem = _state.last();
      if (lastItem && !lastItem.get('value')) {
        _state = _state.set(_state.size - 1, formatValue(data.value, true));
      } else {
        _state = _state.push(formatValue(data.value, true));
      }

      return _state.toJS();
    }

    case types.AVOID_AREA_REMOVE:
      _state = Immutable.fromJS(state).delete(action.data.index);
      if (_state.size === 0) {
        _state = _state.push(formatValue({}, true));
      }

      return _state.toJS();

    case formDataTypes.RESTORE_FROM_URL: {
      let avoidAreas = utils.getObject(action.data.value, `avoidAreas`);
      if (!avoidAreas) {
        return state;
      }
      return avoidAreas.map(item => formatValue(item, true).toJS());
    }

    default:
      return state;
  }
}

export default avoidAreasData;
