import React from 'react';
import PropTypes from 'prop-types';
import './styles/apiUrlTextarea.scss';
import ContentEditable from 'react-contenteditable';
import ToggleButton from 'shared/toggleButton';

const transformToHtml = (value, isMultiline) => {
  const [host = '', params = ''] = value.split('?');
  const htmlParams = params.split('&')
    .map((param) => {
      const [key, value] = param.split(/=(.*)/);
      let classes = 'rf-api-url-textarea__content__param';
      if (isMultiline) {
        classes += ' rf-api-url-textarea__content__param_multiline';
      }
      return `<strong class="${classes}">${key}</strong>=${value}`;
    });
  let br = '';
  let join = '&';
  if (isMultiline) {
    br = '<br />';
    join = '&<br />';
  }
  return `<strong class="rf-api-url-textarea__content__host">${host}</strong>?${br}${htmlParams.join(join)}`;
};

function stripHtml(html) {
   const tmp = document.createElement('DIV');
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || '';
}

class ApiUrlTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: transformToHtml(props.value, props.isApiUrlMultiline),
      prevPropsValue: props.value,
      prevPropsIsMultiline: props.isApiUrlMultiline,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const nextState = {};
    const { prevPropsValue, prevPropsIsMultiline } = state;
    if (prevPropsValue === null) {
      nextState.prevPropsValue = props.value;
    }
    if (prevPropsValue !== null && props.value !== prevPropsValue) {
      nextState.value = transformToHtml(props.value, props.isApiUrlMultiline);
      nextState.prevPropsValue = props.value;
    }

    if (prevPropsIsMultiline !== props.isApiUrlMultiline) {
      nextState.value = transformToHtml(stripHtml(state.value), props.isApiUrlMultiline);
      nextState.prevPropsIsMultiline = props.isApiUrlMultiline;
    }
    return nextState;
  }

  onKeyPress = (e) => {
    const { onChange, hideTextarea } = this.props;
    const { textContent, innerText } = e.target;

    if (e.key === 'Enter') {
      e.preventDefault();
      onChange({ target: { value: textContent || innerText } });
      hideTextarea();
    }
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  onMouseLeave = (e) => {
    const { target } = e;
    const { hideTextarea } = this.props;
    if (target !== document.activeElement) {
      hideTextarea();
    }
  }

  onBlur = (e) => {
    const { onChange, hideTextarea } = this.props;
    const { textContent, innerText } = e.target;

    onChange({ target: { value: textContent || innerText } });
    hideTextarea();
  }

  onChangeView = ({ target: { checked } }) => {
    const { setAppSettings } = this.props;
    setAppSettings({ isApiUrlMultiline: checked });
  }

  render() {
    const { value } = this.state;
    const { style, isApiUrlMultiline } = this.props;

    return (
      <div onMouseLeave={this.onMouseLeave} className="rf-api-url-textarea" style={style}>
        <ContentEditable
          className="rf-api-url-textarea__content"
          html={value}
          spellCheck="false"
          onBlur={this.onBlur}
          onKeyPress={this.onKeyPress}
          onChange={this.onChange}
        />
        <ToggleButton
          label="Multi line view"
          defaultChecked={isApiUrlMultiline}
          onChange={this.onChangeView}
          cssClasses="rf-api-url-textarea__toggle"
        />
      </div>
    );
  }
}

ApiUrlTextarea.propTypes = {
  value: PropTypes.string.isRequired,
  hideTextarea: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setAppSettings: PropTypes.func.isRequired,
  isApiUrlMultiline: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

export default ApiUrlTextarea;
