import GeoItemBase from '../../../common/waypointsGroup/GeoItem';
import DataSwitcher from './DataSwitcher';

class GeoItem extends GeoItemBase {
  getDragItem() {
    return null;
  }

  getDataSwitcherEl() {
    return DataSwitcher;
  }
}

export default GeoItem;
