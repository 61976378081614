import * as types from './constants';
import { DUPLICATE_TAB } from '../../constants';

const defaultState = [];
export default function (state = defaultState, action) {
  switch (action.type) {
    case types.ADD_HISTORY: {
      const { data } = action;
      return [...state, { url: data.url, tabData: data.tabData }];
    }
    case DUPLICATE_TAB:
      return defaultState;
    default:
      return state;
  }
}
