import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomCheckbox from 'shared/checkbox';

import utils from 'utils';

import './styles/resolutionField.scss';

class ResolutionField extends Component {
  onChange(key, value) {
    this.props.onChange({ [key]: value });
  }

  getChild(data = {}) {
    let { value, label, key } = data;
    return (
      <div className="rf-resolution-input__field-item">
        <span className="rf-resolution-input__title">{label}</span>
        <CustomCheckbox
          cssClasses="rf-resolution-input__checkbox"
          isChecked={value.isChecked}
          onChange={this.handleCheckbox.bind(this, key, !value.isChecked)}
        />
        <input
          disabled={!value.isChecked}
          className="rf-resolution-input__field"
          min="0"
          step="1"
          value={value.value}
          onChange={this.handleInput.bind(this, key)}
        />
      </div>
    );
  }

  handleCheckbox(key, isChecked) {
    let value = utils.getObject(this.props, `value[${key}]`);
    this.onChange(key, Object.assign({}, value, { isChecked }));
  }

  handleInput(key, e) {
    let value = utils.getObject(this.props, `value[${key}]`);
    this.onChange(key, Object.assign({}, value, { value: e.target.value }));
  }

  render() {
    let { label, value } = this.props;

    return (
      <div>
        <label className="rf-resolution-input">
          <span className="rf-resolution-input__caption">{label}</span>
          <div className="rf-resolution-input__field-wrapper">
            { this.getChild({ value: value.view, key: 'view', label: 'View' }) }
            { this.getChild({ value: value.snap, key: 'snap', label: 'Snap' }) }
          </div>
        </label>
      </div>
    );
  }
}

ResolutionField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ResolutionField;
