import { DirectionBase } from '../../../modules';

import utils from 'utils';

export default class SearchDirection extends DirectionBase {
  getLinks(props) {
    return utils.getObject(props, 'results') || [];
  }

  renderDirection(nextProps) {
    if (nextProps.results) {
      super.renderDirection(nextProps);
      this.setViewBounds();
    }
  }

  setViewBounds() {
    this.map.getViewModel().setLookAtData({ bounds: this.getGroup().getBoundingBox() });
  }
}
