import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomInput from 'shared/input';

import './styles/apiUrlInput.scss';
import CustomCopyButton from '../shared/copyButton';
import ApiUrlTextarea from './ApiUrlTextarea';
import adjustLUIStyle from 'utils/adjustLUIStyle';

export default class ApiUrlInputContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showTextarea: false,
    };
    this.inputEl = React.createRef();
  }

  componentDidMount() {
    adjustLUIStyle(document.querySelectorAll('.rf-api-url lui-button'), 'lui-button');
  }

  onChange = (e) => {
    this.props.apiUrlUpdate(e.target.value);
  }

  getStyles = () => {
    const el = this.refs.input;
    if (el) {
      const inputRef = el.refs.input;
      const { bottom: elBottom, left, width } = inputRef.getBoundingClientRect();
      const bottom = document.body.clientHeight - elBottom - 1;
      return { left: left - 1, bottom, width: width + 2 };
    }
    return {};
  }

  showTextarea = () => {
    this.setState({ showTextarea: true });
  }

  hideTextarea = () => {
    this.setState({ showTextarea: false });
  }


  render() {
    const { apiUrl, isLoading, sendRequest, isApiUrlMultiline, setAppSettings } = this.props;
    const { showTextarea } = this.state;


    const [hostAndPath = '', params = ''] = apiUrl.split('?');
    const encodedParams = params.split('&').map((param) => {
      const [key, value] = param.split(/=(.*)/);
      return `${key}=${encodeURIComponent(value)}`;
    }).join('&');
    const encodedUrl = `${hostAndPath}?${encodedParams}`;
    return (
      <form onSubmit={sendRequest} className="rf-api-url__form">
        <CustomInput
          ref="input"
          cssClasses="rf-api-url-input"
          label="API Call"
          type="text"
          value={apiUrl}
          onBlur={this.onChange}
          blurOnEnter
          spellCheck="false"
          isValid={this.props.isValid}
          onMouseEnter={this.showTextarea}
        />
        {showTextarea && <ApiUrlTextarea
          value={apiUrl}
          onChange={this.onChange}
          hideTextarea={this.hideTextarea}
          style={this.getStyles()}
          isApiUrlMultiline={isApiUrlMultiline}
          setAppSettings={setAppSettings}
        />}
        <CustomCopyButton
          value={encodedUrl}
          btnClass="rf-input__btn-copy rf-api-url-copy-btn"
        />
        <lui-button onClick={sendRequest} state={isLoading ? 'processing' : ''}>Send</lui-button>
      </form>

    );
  }
}

ApiUrlInputContainer.propTypes = {
  apiUrlUpdate: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sendRequest: PropTypes.func.isRequired,
  setAppSettings: PropTypes.func.isRequired,
  isApiUrlMultiline: PropTypes.bool.isRequired,
};
