import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomInput from 'shared/input';
import TabsControl from 'shared/tabsControl';

import './styles/rangeField.scss';

class RangeField extends Component {
  render() {
    let { label, type, range, formConfig, onChange } = this.props;
    let suffix = {
      distance: 'm',
      time: 's',
    }[type];
    let tabsData = formConfig.rangeTypes.map(item => ({
      label: item.label,
      name: item.value,
    }));

    return (
      <div className="rf-range-field" id="range-isoline">
        <h3>{label}</h3>
        <TabsControl currentTabName={type} onChange={onChange.bind(this, 'rangetype')} tabsData={tabsData} />
        <div className="rf-range-field__wrapper">
          <CustomInput
            label={`Value${suffix ? `, ${suffix}` : ''}`}
            isValid={range.isValid}
            value={range.value}
            onBlur={onChange.bind(this, 'range')}
            blurOnEnter
          />
        </div>
      </div>
    );
  }
}

RangeField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  range: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RangeField;
