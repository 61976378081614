import * as types from './constants';
import { ADD_TAB, REMOVE_TAB, REORDER_TABS } from 'state/tabs/constants';
import utils from 'utils';

export default (state = 0, action) => {
  switch (action.type) {
    case types.SET_SELECTED_TAB:
      return action.index;
    case REORDER_TABS:
    case REMOVE_TAB:
      return utils.getObject(action, 'data.selectedTab', state);
    case ADD_TAB:
      return 0;
    default:
      return state;
  }
};
