import LinkItemBase from '../../../common/waypointsGroup/LinkItem';
import DataSwitcher from './DataSwitcher';

class LinkItem extends LinkItemBase {
  getDataSwitcherEl() {
    return DataSwitcher;
  }
}

export default LinkItem;
