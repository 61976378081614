import * as types from './constants';

function setSelectedEntity(state = null, action) {
  switch (action.type) {
    case types.SELECTED_ENTITY_MATRIX:
      return action.data || null;

    default:
      return state;
  }
}

export default setSelectedEntity;
