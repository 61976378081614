import { validateLat, validateLon } from './coordsValidation';

export function olsBboxValidation(value) {
  if (value === '') {
    return true;
  }

  if (!value.startsWith('bbox:')) {
    return false;
  }

  const [west, south, east, north] = value.replace('bbox:', '').split(',');

  return validateLat(south) && validateLat(north) && validateLon(west) && validateLon(east);
}
