import { olsEvParameters } from '../../../stringify/preprocessor/routing/olsApi';
import { advancedWaypointValidation } from 'state/tabs/tab/formData/routingTab/common/commonOlsValidation';
import { getAdvancedWaypointParams } from 'state/tabs/tab/formData/routingTab/common/commonOlsDefaultState';

export default function exec(data) {
  if (Object.keys(data).find(key => olsEvParameters.indexOf(key) > -1)) {
    data.evEnabled = true;
  }
  if (data.via && data.via.length >= 0) {
    data.via.forEach((val, key) => {
      data.via[key] = splitAdvancedWaypointParams(val, true);
    });
  }

  data.origin = assignAdvancedWaypointParams(data.origin, 'originAdvanced', data);
  data.destination = assignAdvancedWaypointParams(data.destination, 'destinationAdvanced', data);

  return data;
}

function assignAdvancedWaypointParams(waypointStr, advancedFieldName, data) {
  const waypointData = splitAdvancedWaypointParams(waypointStr);
  data[advancedFieldName] = waypointData.advanced;
  return waypointData.coords;
}

export function splitAdvancedWaypointParams(waypointStr = '', isVia) {
  let [waypointAndPlaceOptions, ...waypointOptions] = waypointStr.split('!');
  let [coords, ...placeOptions] = waypointAndPlaceOptions.split(';');
  const advancedOptions = [...waypointOptions, ...placeOptions];
  const advancedFields = {};
  const validation = advancedWaypointValidation();

  advancedOptions.forEach(option => {
    const [key, value] = option.split('=');
    const validator = validation[key];
    if (validator) {
      advancedFields[key] = {
        value,
        isValid: validator(value),
      };
    } else {
      advancedFields[key] = value;
    }
  });

  return {
    coords: coords.replace(' ', ''),
    advanced: {
      ...getAdvancedWaypointParams(isVia),
      ...advancedFields,
    },
  };
}
