import modulesEnum from 'config/modulesEnum';
import { getTabTitle } from 'utils/tabs';
import formsEnum from 'config/formsEnum';
import * as tabsTypes from '../../constants';
import * as types from './constants';

const defaultState = getTabTitle([], modulesEnum.ROUTING, formsEnum.CALCULATE_ROUTE);

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.SET_TAB_TITLE:
      return action.title;
    case tabsTypes.ADD_TAB:
    case tabsTypes.DUPLICATE_TAB:
      return action.tabData.title;
    default:
      return state;
  }
}
