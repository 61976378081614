import moment from 'moment';

function getLocalDate(date = new Date()) {
  let m = moment(date);
  return m.add(m.utcOffset(), 'minutes');
}

function splitDate(str = '') {
  let timezoneRegexp = /[+-]\d{2}:\d{2}|Z$/,
    date = str.split(timezoneRegexp)[0],
    timezone = str.match(timezoneRegexp);
  return {
    date,
    timezone: timezone ? timezone[0] : '',
  };
}

function generateTimezoneList(range = 12) {
  let timeZoneOptions = [],
    parseTimeZone = (o) => {
      let addLeadingZero = (num) => {
        let abs = Math.abs(num),
            result = String(abs < 10 ? `0${abs}` : abs);

        return `${num < 0 ? '-' : '+'}${result}`;
      };

      return typeof o === 'number' ? `${addLeadingZero(o)}:00` : o;
    };

  for (let i = -range; i <= range; i++) {
    timeZoneOptions.push(parseTimeZone(i === 0 ? 'Z' : i));
  }

  return timeZoneOptions;
}

function secondsToISOString(seconds) {
  return new Date(seconds * 1000).toISOString().split('.')[0];
}

export default {
  getLocalDate,
  generateTimezoneList,
  splitDate,
  secondsToISOString,
};
