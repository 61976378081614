export default function execTruckParams(data) {
  let _data = Object.assign({}, data);
  const { mode = {} } = _data;
  const isTruckTransport = mode.transport === 'truck';

  if (!isTruckTransport) {
    [
      'tunnelCategory',
      'truckRestrictionPenalty',
      'trailersCount',
      'truckType',
      'shippedHazardousGoods',
      'limitedWeight',
      'weightPerAxle',
      'axlecount',
      'height',
      'width',
      'length',
      'speedprofile',
      'avoidturns',
    ].forEach(field => delete _data[field]);
  }

  return _data;
}
