import formsEnum from 'config/formsEnum';
import * as tabTypes from '../../constants';
import { RESTORE_FROM_URL } from '../formData/constants';

export default (state = formsEnum.CALCULATE_ROUTE, action) => {
  switch (action.type) {
    case tabTypes.ADD_TAB:
      return action.tabData.form;
    case RESTORE_FROM_URL:
      return action.data.currentForm;
    default:
      return state;
  }
};
