import * as powerFilterReducer from './powerFilterReducer';
import * as ifModifiedSinceReducer from './ifModifiedSinceReducer';
import { assignTabDataAndDispatch } from '../../actions';

export function setPowerFilter(value) {
  return assignTabDataAndDispatch({ type: powerFilterReducer.SET, value });
}

export function toggleIfModifiedSince(value) {
  return assignTabDataAndDispatch({ type: ifModifiedSinceReducer.TOGGLE_IF_MODIFIED_SINCE, value });
}

export function setDate(value) {
  return assignTabDataAndDispatch({ type: ifModifiedSinceReducer.SET, value });
}
