export default {
  olsReturn: [
    { label: 'Polyline', value: 'polyline' },
    { label: 'Actions', value: 'actions' },
    { label: 'Turn by turn actions', value: 'turnByTurnActions' },
    { label: 'Instructions', value: 'instructions' },
    { label: 'Summary', value: 'summary' },
    { label: 'Travel summary', value: 'travelSummary' },
    { label: 'Elevation', value: 'elevation' },
    { label: 'Route handle', value: 'routeHandle' },
    { label: 'Pass through', value: 'passthrough' },
    { label: 'ML Duration', value: 'mlDuration' },
  ],

  avoidFeatures: [
    { value: 'seasonalClosure', label: 'Seasonal Closure' },
    { value: 'tollRoad', label: 'Toll Road' },
    { value: 'controlledAccessHighway', label: 'Controlled Access Highway' },
    { value: 'ferry', label: 'Ferry' },
    { value: 'carShuttleTrain', label: 'Car Shuttle Train' },
    { value: 'tunnel', label: 'Tunnel' },
    { value: 'dirtRoad', label: 'Dirt Road' },
    { value: 'difficultTurns', label: 'Difficult Turns' },
  ],

  spans: [
    { value: 'streetAttributes', label: 'Street attributes' },
    { value: 'walkAttributes', label: 'Walk Attributes' },
    { value: 'carAttributes', label: 'Car Attributes' },
    { value: 'truckAttributes', label: 'Truck Attributes' },
    { value: 'names', label: 'Names' },
    { value: 'length', label: 'Length' },
    { value: 'duration', label: 'Duration' },
    { value: 'baseDuration', label: 'Base duration' },
    { value: 'countryCode', label: 'Country Code' },
    { value: 'functionalClass', label: 'Functional Class' },
    { value: 'routeNumbers', label: 'Route Numbers' },
    { value: 'speedLimit', label: 'Speed Limit' },
    { value: 'dynamicSpeedInfo', label: 'Dynamic Speed Info' },
    { value: 'segmentId', label: 'Segment Id' },
    { value: 'consumption', label: 'Consumption' },
  ],

  routingModes: [
    { label: 'Fast', value: 'fast' },
    { label: 'Short', value: 'short' },
  ],

  transportModes: [
    { label: 'Car', value: 'car' },
    { label: 'Truck', value: 'truck' },
    { label: 'Bike', value: 'bike' },
  ],

  routeTransports: [
    { label: 'Car', value: 'car' },
    { label: 'Pedestrian', value: 'pedestrian' },
    { label: 'Truck', value: 'truck' },
    { label: 'Bicycle', value: 'bicycle' },
  ],

  shippedHazardousGoods: [
    { value: 'explosive', label: 'Explosive' },
    { value: 'gas', label: 'Gas' },
    { value: 'flammable', label: 'Flammable' },
    { value: 'combustible', label: 'Combustible' },
    { value: 'organic', label: 'Organic' },
    { value: 'poison', label: 'Poison' },
    { value: 'radioactive', label: 'Radioactive' },
    { value: 'corrosive', label: 'Corrosive' },
    { value: 'poisonousInhalation', label: 'Poisonous Inhalation' },
    { value: 'harmfulToWater', label: 'Harmful To Water' },
    { value: 'other', label: 'Other' },
  ],

  matchSideOfStreetList: [
    { label: 'Always', value: 'always' },
    { label: 'Only If Divided', value: 'onlyIfDivided' },
  ],
};
