import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as WaypointsActions from 'state/tabs/tab/formData/common/waypoints/actions';
import * as MatrixPointsActions from 'state/tabs/tab/formData/routingTab/matrixForm/points/actions';
import { avoidAreaUpdate } from 'state/tabs/tab/formData/common/avoidAreas/actions';
import { setSelectedEntity } from 'state/tabs/tab/formData/routingTab/matrixForm/selectedEntity/actions';
import * as NotificationActions from 'state/notification/actions';
import { setMapBounds } from 'state/map/bounds/actions';
import { setSettings } from 'state/appSettings/base/actions';
////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////

import * as RangeOnRouteActions from 'state/tabs/tab/formData/common/rangeOnRoute/actions';
import * as currentRouteActions from 'state/tabs/tab/formData/routingTab/routingForm/currentRoute/currentRouteActions';
import * as highlightedRouteActions from
  'state/tabs/tab/formData/routingTab/routingForm/highlightedRoute/highlightedRouteActions';
import { setSelectedTrace } from 'state/tabs/tab/formData/routingTab/approachPathRouterForm/selectedTrace/actions';

import utils from 'utils';

import Tooltip from 'shared/tooltip';
import Forms from './forms';
import { Dnd, Resolution, Search } from './units';
import { createPlatform, initializeMap, simulateMapResize } from './utils';
import LayerToggle from '../forms/common/LayerToggle';
import ZoomControls from './ZoomControls';
import RoRContainer from './RoRContainer';
import TilesContainer from './TilesContainer';
import TrafficOverlaysContainer from './TrafficOverlaysContainer';
import PowerContainer from './PowerContainer';
import MatchRouteConfigContainer from './MatchRouteConfigContainer';
import formsEnum from 'config/formsEnum';
import { createTrafficOverlayLayer, vectorTrafficStyles } from './utils/TrafficOverlaysHelper';

import {
  MAP_TRUCK_LAYER_PARAMS,
  INIT_ZOOM,
  getInitCoords,
  AVOID_AREA_DEFAULT_SIZE,
} from 'config/map';

import './styles/map.scss';
import settingUtils from 'utils/settings';
import { getSettingsData } from 'state/settingsPreset';
import { createBoundsRect, createGeoRect } from './utils/geo';
import { getTilesData } from 'state/map/tiles/index';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getResponseData } from 'state/tabs/tab/response';
import { getModule } from 'state/tabs/tab/module';
import { getForm } from 'state/tabs/tab/form';
import { getInspectLink } from 'state/inspectLink';
import { getReverseGeocodeInfo } from 'state/reverseGeocode';
import { getIsLeftPanelShown } from '../../state/ui';
import { getTileLayer, getVectorTileLayer, TILE_TYPE_ENGINE_MAP } from './utils/TilesHelper';
import { TILE_TYPES } from '../../state/map/tiles/constants';
import { getAppSettings } from 'state/appSettings/base';

export class MapContainer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {
      props: nextProps,
    };

    let map = prevState.map;

    const oldProps = prevState.props;
    if (!oldProps) {
      return state;
    }

    const updatedMap = MapContainer.updateMap(nextProps, oldProps, prevState.map);
    if (updatedMap) {
      state.platform = updatedMap.platform;
      state.map = updatedMap.map;
      map = state.map;
    }

    const forceRender = MapContainer.updateMapForms(oldProps, nextProps, prevState.forms, map);
    const RESOLUTION_PATH = 'formData.fields.resolution';
    const resolutionView = utils.getObject(nextProps, `${RESOLUTION_PATH}.view`);
    const resolutionSnap = utils.getObject(nextProps, `${RESOLUTION_PATH}.snap`);

    if ((utils.isPropChanged(oldProps, nextProps, 'formData.fields.resolution.view.isChecked') ||
        utils.isPropChanged(oldProps, nextProps, 'formData.fields.resolution.snap.isChecked')) &&
        resolutionView !== undefined && resolutionSnap !== undefined) {
        prevState.units.resolution.update(map, nextProps);
    }

    prevState.forms.forEach((form, index) => {
      let convertedNextProps = MapContainer.convertPropsForParticularTab(index, nextProps);
      let convertedOldProps = forceRender ? convertedNextProps :
        MapContainer.convertPropsForParticularTab(index, oldProps);
      form.process.call(form, convertedOldProps, convertedNextProps, forceRender);
    });

    if (!utils.isEqual(oldProps.search.results, nextProps.search.results)) {
      prevState.units.search.process(nextProps.search, prevState.onContextMenu);
    }

    state.trafficOverlaysLayer = MapContainer.updateTrafficOverlays(oldProps, nextProps, map,
      prevState.trafficOverlaysLayer);

    state.vectorTrafficOverlaysLayer = MapContainer.updateVectorTrafficOverlays(oldProps, nextProps, map,
      state.platform || prevState.platform, prevState.vectorTrafficOverlaysLayer);

    if (nextProps.isAutoZoomOn && (
         utils.isPropChanged(oldProps, nextProps, `tabs[${nextProps.selectedTab}].response.data.response`) ||
         nextProps.adjustViewport
    )) {
      MapContainer.setViewBounds(prevState.forms, nextProps.selectedTab, true);
      if (nextProps.adjustViewport) {
        nextProps.setSettings({ adjustViewport: false });
      }
    }

    if (MapContainer.isApiUrlBarToggled(nextProps, oldProps) || MapContainer.isHistoryBarToggled(nextProps, oldProps)) {
      setTimeout(simulateMapResize, 500);
    }

    const isTilesTypeChanged = utils.isPropChanged(nextProps, oldProps, 'tilesData.type');
    return {
      ...state,
      isTruckTileChecked: isTilesTypeChanged ? false : prevState.isTruckTileChecked,
    };
  }

  static isHistoryBarToggled(nextProps, oldProps) {
    return nextProps.isHistoryShown !== oldProps.isHistoryShown;
  }

  static isApiUrlBarToggled(nextProps, oldProps) {
    return nextProps.apiUrl !== oldProps.apiUrl && (nextProps.apiUrl === '' || oldProps.apiUrl === '');
  }

  static setViewBounds(forms, selectedTab, ...args) {
    if (!forms[selectedTab]) {
      return;
    }
    forms[selectedTab].apply('setViewBounds', args);
  }

  static setupForm(index, tabsCount, tab, forms, map) {
    if (forms[index]) {
      forms[index].destroy();
      forms[index] = null;
    }

    let { module, form, isActive } = tab;
    let Form = Forms[module][form];

    if (isActive && Form) {
      forms[index] = new Form(map, { index, tabsCount });
    }
  }

  static convertPropsForParticularTab(tabIndex, props) {
    return {
      ...props,
      tabData: props.tabs[tabIndex],
    };
  }

  static updateMap(nextProps, oldProps) {
    let { tilesData, map: { bounds } } = nextProps;
    if (!utils.isEqual(oldProps.tilesData, tilesData)) {
      const platform = createPlatform(tilesData);
      document.querySelector('#map').innerHTML = '';
      const hasBounds = !!Object.keys(bounds).length;
      const options = {
        engineType: TILE_TYPE_ENGINE_MAP[tilesData.type],
      };
      if (hasBounds) {
        options.bounds = createBoundsRect(bounds);
      } else {
        options.center = getInitCoords();
        options.zoom = INIT_ZOOM;
      }
      let map = initializeMap(document.querySelector('#map'), getTileLayer(platform, tilesData), options);

      return { map, platform };
    }
    return null;
  }

  static updateTrafficOverlays(oldProps, nextProps, map, trafficOverlaysLayer) {
    let TRAFFIC_OVERLAYS_PATH = 'map.trafficOverlays';
    if (utils.isPropChanged(oldProps, nextProps, TRAFFIC_OVERLAYS_PATH)) {
      let trafficOverlays = utils.getObject(nextProps, TRAFFIC_OVERLAYS_PATH);
      map.removeLayer(trafficOverlaysLayer);
      if (trafficOverlays.isActive && trafficOverlays.type === TILE_TYPES.RASTER) {
        const trafficOverlayOptions = trafficOverlays[trafficOverlays.type];
        let { settingsData, currentForm, module } = nextProps;
        let nextCredentials = settingUtils.getCurrentCredentials(settingsData, currentForm.present, module);
        trafficOverlaysLayer = createTrafficOverlayLayer({ ...trafficOverlayOptions, settings: nextCredentials });
        map.addLayer(trafficOverlaysLayer);
      }
    }
    return trafficOverlaysLayer;
  }

  static updateVectorTrafficOverlays(oldProps, nextProps, map, platform, vectorTrafficOverlaysLayer) {
    let VECTOR_TRAFFIC_OVERLAYS_PATH = 'map.trafficOverlays';
    if (utils.isPropChanged(oldProps, nextProps, VECTOR_TRAFFIC_OVERLAYS_PATH)) {
      let trafficOverlays = utils.getObject(nextProps, VECTOR_TRAFFIC_OVERLAYS_PATH);
      map.removeLayer(vectorTrafficOverlaysLayer);
      clearInterval(this.trafficRefreshTimer);
      if (trafficOverlays.isActive && trafficOverlays.type === TILE_TYPES.VECTOR) {
        const trafficOverlayOptions = trafficOverlays[trafficOverlays.type];
        vectorTrafficOverlaysLayer = getVectorTileLayer(platform, trafficOverlayOptions, vectorTrafficStyles,
          { mapVersion: '' });
        map.addLayer(vectorTrafficOverlaysLayer);

        if (trafficOverlayOptions.refreshInterval > 0) {
          this.trafficRefreshTimer = setInterval(() => {
            vectorTrafficOverlaysLayer.getProvider().getCache().removeAll();
            vectorTrafficOverlaysLayer.getProvider().reload(true);
          }, trafficOverlayOptions.refreshInterval * 1000);
        }
      }
    }
    return vectorTrafficOverlaysLayer;
  }

  static updateMapForms(oldProps, nextProps, forms, map) {
    if (!oldProps) {
      return false;
    }
    const updatedFormTabs = oldProps.tabs
      .map((tab, index) => ({ tab, index }))
      .filter(({ tab, index }) => {
        if (tab.form !== utils.getObject(nextProps, `tabs[${index}].form`)) {
          return true;
        }
        let isPrevActive = tab.isActive;
        let isNextActive = utils.getObject(nextProps, `tabs[${index}].isActive`);
        return isNextActive !== isPrevActive;
      });

    if (
      nextProps.tabs.length !== oldProps.tabs.length ||
      updatedFormTabs.length > 0 ||
      utils.isPropChanged(oldProps, nextProps, 'tilesData')
    ) {
      forms.forEach(form => form.destroy());
      forms.length = 0;
      let tabsCount = nextProps.tabs.length;
      utils.forEachDesc(nextProps.tabs, (tab, index) => {
        MapContainer.setupForm(index, tabsCount, tab, forms, map);
      });

      return true;
    }
    return false;
  }

  constructor(props) {
    super(props);
    this.resizeMap = this.resizeMap.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.hideContextMenu = this.hideContextMenu.bind(this);
    this.handleViewChangeEnd = this.handleViewChangeEnd.bind(this);

    this.state.onContextMenu = this.onContextMenu;
  }

  state = {
    isTruckTileChecked: false,
    units: null,
    forms: [],
    map: null,
    platform: null,
    trafficOverlaysLayer: null,
  };

  componentDidMount() {
    let { tabs } = this.props;
    // This global element could be useful for e2e tests
    window.rfmap = this.state.map = this.initMap();
    this.initUnits();

    let tabsCount = tabs.length;
    utils.forEachDesc(tabs, (tab, index) => {
      MapContainer.setupForm(index, tabsCount, tab, this.state.forms, this.state.map);
    });

    this.state.forms.forEach((form, index) => {
      let props = MapContainer.convertPropsForParticularTab(index, this.props);
      form.process.call(form, props, props, true);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let getIsHidden = props => utils.getObject(props, 'formData.isolineWaypoints.isHidden');
    return nextProps.isResultPanelShown !== this.props.isResultPanelShown ||
      nextProps.isLeftPanelShown !== this.props.isLeftPanelShown ||
      getIsHidden(nextProps) !== getIsHidden(this.props) ||
      utils.isPropChanged(this.props, nextProps, 'formData.rangeOnRoute') ||
      this.state.isTruckTileChecked !== nextState.isTruckTileChecked ||
      utils.isPropChanged(this.props, nextProps, 'tilesData');
  }

  componentDidUpdate(prevProps) {
    if (utils.isPropChanged(this.props, prevProps, 'tilesData')) {
      const { map } = this.state;
      this.initMapBehaviour(map);

      this.initUnits();
    }
  }

  componentWillUnmount() {
    this.hideContextMenu();
    window.removeEventListener('resize', this.resizeMap);
    this.state.map.removeEventListener('contextmenu', this.onContextMenu, false);
    this.state.map.removeEventListener('mapviewchangestart', this.hideContextMenu, false);
    this.state.map.removeEventListener('mapviewchangeend', this.handleViewChangeEnd, false);

    if (this.refs.map) {
      this.refs.map.removeEventListener('transitionend', this.resizeMap);
    }

    utils.iterObject(this.state.units, (unit) => {
      unit.destroy();
    });

    this.form.destroy();
    this.state.form = null;
    this.state.units = null;
  }

  onContextMenu(ev, coords) {
    this.props.onContextMenu(this.getContextMenuData(ev, coords));
  }

  onToggleTruckTile() {
    let isTruckTileChecked = !this.state.isTruckTileChecked;
    const { tilesData } = this.props;
    const { map } = this.state;
    this.setState({ isTruckTileChecked });
    if (isTruckTileChecked) {
      if (tilesData.type === 'raster') {
        this.initTruckLayer();
        map.addLayer(this.truckLayer);
      } else {
        map.getBaseLayer().getProvider().setStyle(
          new H.map.Style('https://js.api.here.com/v3/3.1/styles/omv/miami/truck.day.yaml')
        );
      }
    } else if (tilesData.type === 'raster') {
      this.state.map.removeLayer(this.truckLayer);
    } else {
      map.getBaseLayer().getProvider().setStyle(
        new H.map.Style(tilesData.vector.styleUrl)
      );
    }
  }

  getContextMenuData(ev, coords) {
    let target = ev.target,
        eventX = ev.viewportX || ev.currentPointer.viewportX,
        eventY = ev.viewportY || ev.currentPointer.viewportY,
        geo = this.state.map.screenToGeo(eventX, eventY),
        areaSize = AVOID_AREA_DEFAULT_SIZE,
        getPointStr = coords => `${coords.lat.toFixed(6)},${coords.lng.toFixed(6)}`,
        targetData = utils.isFunction(target.getData) ? target.getData() : {},
        avoidArea = [geo, this.state.map.screenToGeo(eventX + areaSize, eventY + areaSize)].map(getPointStr).join(';');

    if (this.refs.map) {
      eventX += this.refs.map.offsetLeft;
      eventY += this.refs.map.offsetTop;
    }

    return {
      screenX: eventX + 1,
      screenY: eventY + 1,
      geo: coords ? coords.join(',') : getPointStr(geo),
      formData: this.props.formData,
      targetData,
      avoidArea,
      target,
    };
  }

  getBoundingBox() {
    let { selectionNWCorner = {}, selectionSECorner = {} } = utils.getObject(this.props, 'formData.fields.bbox', {});
    if (selectionSECorner.isValid && selectionNWCorner.isValid && selectionNWCorner.value && selectionSECorner.value) {
      return createGeoRect(selectionNWCorner.value, selectionSECorner.value);
    }
    return null;
  }

  initUnits() {
    let { map, forms } = this.state;
    const { selectedTab } = this.props;

    const units = {
      dnd: new Dnd(map, this.refs),
      resolution: new Resolution(map, this.refs),
      search: new Search(map, this.refs),
    };

    units.dnd.init({
      onDragStart: (targetData) => {
        this.hideContextMenu();
        if (targetData) {
          map.behavior.disable();
          forms[selectedTab].apply('onDragStart', [targetData]);
        }
      },

      onDragEnd: (targetData, value) => {
        if (targetData) {
          forms[selectedTab].apply('onDragEnd', [targetData, value]);
        }

        map.behavior.enable();
        map.behavior.disable(H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM);
      },

      onDrag: (target, coords) => {
        forms[selectedTab].apply('onDrag', [target, coords]);
      },
    });

    this.setState({ units });
  }

  initTruckLayer() {
    let mapTileService = this.state.platform.getMapTileService({ type: 'base' });
    this.truckLayer = mapTileService.createTileLayer(...MAP_TRUCK_LAYER_PARAMS);
  }

  initMap() {
    const { tilesData } = this.props;
    this.state.platform = createPlatform(tilesData);
    let map = initializeMap(document.querySelector('#map'), getTileLayer(this.state.platform, tilesData), {
      center: getInitCoords(),
      zoom: INIT_ZOOM,
      engineType: TILE_TYPE_ENGINE_MAP[tilesData.type],
      // if bounds value is not null it will override center and zoom values
      bounds: this.getBoundingBox(),
    });

    return this.initMapBehaviour(map);
  }

  initMapBehaviour(map) {
    const { tilesData } = this.props;
    map.behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    if (tilesData.type === TILE_TYPES.RASTER) {
      map.behavior.disable(H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM);
    }
    window.addEventListener('resize', this.resizeMap);
    map.addEventListener('contextmenu', this.onContextMenu, false);
    map.addEventListener('mapviewchangestart', this.hideContextMenu, false);
    map.addEventListener('mapviewchangeend', this.handleViewChangeEnd, false);

    if (this.refs.map) {
      this.refs.map.addEventListener('transitionend', this.resizeMap, false);
    }

    return map;
  }

  hideContextMenu() {
    this.props.onContextMenu();
  }

  handleViewChangeEnd(e) {
    let rect = this.state.map.getViewModel().getLookAtData().bounds.getBoundingBox();
    this.props.setMapBounds({
      top: rect.getTop(),
      right: rect.getRight(),
      bottom: rect.getBottom(),
      left: rect.getLeft(),
    });
    if (this.state.units && this.state.units.resolution &&
      this.props.currentForm.present === formsEnum.CALCULATE_ROUTE) {
      this.state.units.resolution.handleViewChangeEnd(e, this.props);
    }
  }

  resizeMap() {
    this.state.map.getViewPort().resize();
    this.hideContextMenu();
    MapContainer.setViewBounds(this.state.forms, this.props.selectedTab);
  }

  render() {
    let mapClasses = classnames('rf-map', {
      'rf-map_collapsed-left': this.props.isLeftPanelShown,
      'rf-map_collapsed-right': this.props.isResultPanelShown && this.props.apiUrl,
    });

    return (
      <div className={mapClasses} ref="map">
        <div id="map" />
        <ZoomControls getMap={() => this.state.map} />
        <div className="rf-map__truck-icon">
          <LayerToggle
            active={this.state.isTruckTileChecked}
            onChange={::this.onToggleTruckTile}
            tooltipText="Truck Restrictions"
          />
        </div>
        <div className="rf-map__adjust_viewport">
          <Tooltip tooltip="Adjust viewport to tab content">
            <div
              className="rf-map__adjust_viewport__icon"
              onClick={() => MapContainer.setViewBounds(this.state.forms, this.props.selectedTab, true)}
            />
          </Tooltip>
        </div>
        <div className="rf-marker-labels" />
        <MatchRouteConfigContainer />
        <RoRContainer />
        <PowerContainer />
        <TilesContainer />
        <TrafficOverlaysContainer />
      </div>
    );
  }
}

MapContainer.defaultProps = {
  responseData: {},
  formData: {},
};

MapContainer.propTypes = {
  responseData: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  tilesData: PropTypes.object.isRequired,
  currentForm: PropTypes.object.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabs: PropTypes.array.isRequired,
  mapObjectUpdate: PropTypes.func.isRequired,
  waypointUpdate: PropTypes.func.isRequired,
  addWaypoint: PropTypes.func.isRequired,
  avoidAreaUpdate: PropTypes.func.isRequired,
  updatePointMatrix: PropTypes.func.isRequired,
  setSelectedEntity: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  module: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  isHistoryShown: PropTypes.bool.isRequired,
  isResultPanelShown: PropTypes.bool.isRequired,
  isAutoZoomOn: PropTypes.bool.isRequired,
  adjustViewport: PropTypes.bool.isRequired,
  currentRoute: PropTypes.number,
  currentIsoline: PropTypes.number,
  apiUrl: PropTypes.string.isRequired,
  settingsData: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  setRangeOnRouteData: PropTypes.func.isRequired,
  setMapBounds: PropTypes.func.isRequired,
  setRoute: PropTypes.func.isRequired,
  setHighlightedRoute: PropTypes.func.isRequired,
  setSelectedTrace: PropTypes.func.isRequired,
  isLeftPanelShown: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  let module = getModule(state);
  let form = getForm(state);
  let formData = getCurrentFormData(state);
  const appSettings = getAppSettings(state);
  return {
    responseData: getResponseData(state),
    tilesData: getTilesData(state),
    formData,
    inspectLink: getInspectLink(state),
    reverseGeocode: getReverseGeocodeInfo(state),
    settingsData: getSettingsData(state),
    module,
    form,
    // TODO refactor this to string
    currentForm: {
      present: form,
      store: [],
    },
    isHistoryShown: state.isHistoryShown,
    isResultPanelShown: state.isResultPanelShown,
    isAutoZoomOn: appSettings.isAutoZoomOn,
    adjustViewport: appSettings.adjustViewport,
    currentRoute: formData.currentRoute,
    currentIsoline: formData.currentIsoline,
    search: state.search,
    highlightedManeuver: formData.highlightedManeuver,
    map: state.map,
    tabs: state.tabs,
    selectedTab: state.selectedTab,
    isLeftPanelShown: getIsLeftPanelShown(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mapObjectUpdate: bindActionCreators(FieldsActions.mapObjectUpdate, dispatch),
    waypointUpdate: bindActionCreators(WaypointsActions.update, dispatch),
    addWaypoint: bindActionCreators(WaypointsActions.add, dispatch),
    avoidAreaUpdate: bindActionCreators(avoidAreaUpdate, dispatch),
    updatePointMatrix: bindActionCreators(MatrixPointsActions.update, dispatch),
    setSelectedEntity: bindActionCreators(setSelectedEntity, dispatch),
    alternativesIgnoredNotify: bindActionCreators(NotificationActions.alternativesIgnoredNotify, dispatch),
    resetAlternatives: bindActionCreators(FieldsActions.resetAlternatives, dispatch),
    setRangeOnRouteData: bindActionCreators(RangeOnRouteActions.setData, dispatch),
    setMapBounds: bindActionCreators(setMapBounds, dispatch),
    setSettings: bindActionCreators(setSettings, dispatch),
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////

    setRoute: bindActionCreators(currentRouteActions.setRoute, dispatch),
    setHighlightedRoute: bindActionCreators(highlightedRouteActions.setHighlightedRoute, dispatch),
    setSelectedTrace: bindActionCreators(setSelectedTrace, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapContainer);
