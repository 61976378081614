import modulesEnum from 'config/modulesEnum';
import routing from './routing';
////////////////
//////////////////////////////
////////////////////////////////
////////////////////////////////////
////////////////////////
//////////////////////////////////////////////////////
//////////

export default {
  [modulesEnum.ROUTING]: routing,
//////////////////
///////////////////////////////
/////////////////////////////////
//////////////////////////////////////
/////////////////////////
////////////////////////////////////////////////////////
////////////
};
