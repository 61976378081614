import React from 'react';
import PropTypes from 'prop-types';
import FormRow from '../../../shared/formRow';
import CheckboxAttributesSet from '../../common/CheckboxAttributesSet';
import getConfig from 'config/form';
import formsEnum from 'config/formsEnum';
import CustomInput from '../../../shared/input';
import CustomSelect from '../../../shared/select';

const formConfig = getConfig(formsEnum.OLS);

class TruckParams extends React.Component {
  render() {
    const { onChange, fields, isShown } = this.props;

    if (!isShown) {
      return null;
    }

    const inputs = [
      { value: 'grossWeight', label: 'Gross Weight (kg)' },
      { value: 'weightPerAxle', label: 'Weight Per Axle (kg)' },
      { value: 'height', label: 'Height (cm)' },
      { value: 'width', label: 'Width (cm)' },
      { value: 'length', label: 'Length (cm)' },
      { value: 'axleCount', label: 'Axle Count' },
    ].map((input) => (
      <FormRow key={input.value}>
        <CustomInput
          value={fields[`truck[${input.value}]`]}
          onBlur={e => onChange(`truck[${input.value}]`, e)}
          label={input.label}
          blurOnEnter
        />
      </FormRow>
    ));

    return (
      <>
        <h4>Truck params</h4>
        <FormRow>
          <CheckboxAttributesSet
            label="Shipped Hazardous Goods"
            options={formConfig.shippedHazardousGoods}
            data={fields['truck[shippedHazardousGoods]']}
            isExpanded={false}
            onChange={e => onChange('truck[shippedHazardousGoods]', e)}
          />
        </FormRow>
        <FormRow>
          <CustomSelect
            onChange={e => onChange('truck[tunnelCategory]', e)}
            options={['b', 'c', 'd', 'e']}
            value={fields['truck[tunnelCategory]']}
            label="Tunnel Category"
            addEmptyOption
          />
        </FormRow>
        {inputs}
      </>
    );
  }
}

TruckParams.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
};

export default TruckParams;
