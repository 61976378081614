import isNumberValid from 'utils/validation/numericValidation';

export default {
  trailersCount: isNumberValid({ min: 0, max: 4 }),
  limitedWeight: isNumberValid({ min: 0, max: 1000 }),
  weightPerAxle: isNumberValid({ min: 0, max: 1000 }),
  axlecount: isNumberValid({ min: 0, max: 254 }),
  height: isNumberValid({ min: 0, max: 50 }),
  width: isNumberValid({ min: 0, max: 50 }),
  length: isNumberValid({ min: 0, max: 300 }),
};
