import utils from 'utils';

import RoutingFields from 'state/tabs/tab/formData/routingTab/routingForm/fields/defaultState';
import MatrixFields from 'state/tabs/tab/formData/routingTab/matrixForm/fields/defaultState';
import IsolineFields from 'state/tabs/tab/formData/routingTab/isolineForm/fields/defaultState';
import RouteInfoFields from 'state/tabs/tab/formData/routingTab/routeInfoForm/fields/defaultState';
////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////
import OlsMergedApiFields from 'state/tabs/tab/formData/routingTab/OlsForm/fields/defaultState';
import truckFields from 'state/tabs/tab/formData/common/fields/truckProfileDefaultState';
import intermodalFields from 'state/tabs/tab/formData/transitTab/intermodalForm/fields/defaultState';

import defaultPresetsState, { DEFAULT_PRESET } from 'state/settingsPreset/defaultPresetsState';
import formsEnum from 'config/formsEnum';

const FORM_FIELDS = {
  [formsEnum.CALCULATE_ROUTE]: Object.assign({}, RoutingFields, truckFields),
  [formsEnum.CALCULATE_MATRIX]: Object.assign({}, MatrixFields, truckFields),
  [formsEnum.CALCULATE_ISOLINE]: Object.assign({}, IsolineFields, truckFields),
  [formsEnum.GET_ROUTE_INFO]: RouteInfoFields,
  [formsEnum.OLS]: OlsMergedApiFields,
//////////////////
////////////////////////////////////////////////
////////////////////////////////////////
////////////
  [formsEnum.INTERMODAL]: intermodalFields,
};

function isExtraParam(module, currentForm, param) {
  let formFields = FORM_FIELDS[currentForm];
  let formUrlPath = `${module}.${currentForm}.${DEFAULT_PRESET}.urlQuery`;
  let formUrlParams = utils.getObject(defaultPresetsState, formUrlPath) || {};
  let paramsList = Object.keys(formFields)
    .concat(Object.keys(formUrlParams))
    .map(param => param.toLowerCase());
  return paramsList.indexOf(param.toLowerCase()) === -1;
}

function collect(keyVal = [], result = {}) {
  let extraparams = result.extraparams ? { ...result.extraparams } : { value: '' };
  extraparams.value = [extraparams.value, keyVal.join('=')].filter(Boolean).join('&');
  return Object.assign(result, { extraparams });
}

function isSupported(currentForm) {
  return !!(FORM_FIELDS[currentForm] || {}).extraparams;
}

export default {
  collect,
  isSupported,
  isExtraParam,
};
