import Routing from './routing';
import Matrix from './matrix';
import Isoline from './isoline';
import RouteInfo from './routeInfo';
import OlsApi from './olsApi';
////////////////
//////////////////////////////////////////////////////
//////////

import formsEnum from 'config/formsEnum';

export default {
  [formsEnum.CALCULATE_ROUTE]: Routing,
  [formsEnum.CALCULATE_MATRIX]: Matrix,
  [formsEnum.CALCULATE_ISOLINE]: Isoline,
  [formsEnum.GET_ROUTE_INFO]: RouteInfo,
//////////////////
///////////////////////////////////////////////////////
////////////
  [formsEnum.OLS]: OlsApi,
};
