import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import utils from 'utils';
import classnames from 'classnames';
import './styles/shareContainer.scss';
import copyToClipboard from 'copy-to-clipboard';
import { getTabs } from 'state/tabs';
import * as NotificationActions from 'state/notification/actions';
import { bindActionCreators } from 'redux';
import settingsUtils from 'utils/settings';
import { getSettingsData } from 'state/settingsPreset';
import stringifyQuery from 'utils/url/stringify/stringifyQuery';
import TabsControl from 'shared/tabsControl';
import { stringifyUrl } from 'utils/url';
import CustomTooltip from 'shared/tooltip';

const shareMethods = {
  currentTab: 0,
  activeTabs: 1,
  allTabs: 2,
};

const shareMethodTitles = {
  [shareMethods.currentTab]: 'Current tab',
  [shareMethods.activeTabs]: 'Active tabs',
  [shareMethods.allTabs]: 'All tabs',
};

class ShareContainer extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      showMenu: false,
      shareMethod: shareMethods.allTabs,
    };
    this.onHidePopup = this.onHidePopup.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onHidePopup, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onHidePopup, false);
  }

  onHidePopup(e) {
    if (this.state.showMenu && !this.refs.shareContainer.contains(e.target)) {
      this.setState({
        showMenu: false,
      });
    }
  }

  onShare() {
    copyToClipboard(this.getShareUrl());
    this.props.notify({
      message: 'Url was copied to clipboard',
      impact: 'info',
      autoDismiss: 2,
    });
  }

  onShareMethodChange(shareMethod) {
    this.setState({ shareMethod });
  }

  getShareUrl() {
    let { selectedTab } = this.props;
    let selectedTabObj = this.props.tabs[selectedTab];
    const tabUrls = this.props.tabs.filter((tab, index) => {
      if (this.state.shareMethod === shareMethods.allTabs) {
        return true;
      } else if (this.state.shareMethod === shareMethods.activeTabs && tab.isActive) {
        return true;
      } else if (this.state.shareMethod === shareMethods.currentTab && index === this.props.selectedTab) {
        return true;
      }
      return false;
    }).map(tab => {
      let { module, form, title, tabColorPalette, isActive, settings, formData } = tab;
      let apiUrl = null;
      let usePostReq = null;
      if (settings.urlQuery) {
        const preset = settings.urlQuery.settingsPresetType;
        const presetSettings = settingsUtils.getPresetSettings(module, form, this.props.settingsData, preset);
        usePostReq = utils.getObject(tab, 'formData.fields.usePostReq', null);
        ({ apiUrl } = stringifyUrl(stringifyQuery(
          tab.formData,
          presetSettings.urlQuery,
          module,
          form,
        ), presetSettings));
      }
      const data = {
        title,
        tabColorPalette,
        isActive,
        isSelected: selectedTabObj === tab,
        module,
        form,
      };
      if (settings.urlQuery) {
        data.preset = settings.urlQuery.settingsPresetType;
      }
      if (apiUrl) {
        data.url = encodeURIComponent(apiUrl);
        data.usePostReq = usePostReq;
      } else {
        data.formData = formData;
      }
      return data;
    });

    return `${location.origin}/#${encodeURIComponent(JSON.stringify(tabUrls))}`;
  }


  getShareMenu() {
    const tooltip = (
      <div>To simplify url you can use
        <a href="https://shorturl.in.here.com/" target="_blank" rel="noopener noreferrer">HERE shoertener</a>
        service
      </div>
    );
    let tabsData = [shareMethods.currentTab, shareMethods.activeTabs, shareMethods.allTabs].map(shareMethod => ({
      label: shareMethodTitles[shareMethod],
      name: shareMethod,
    }));
    return <div className="rf-share-container__menu">
      <h3>Share Tabs</h3>
      <TabsControl currentTabName={this.state.shareMethod} onChange={::this.onShareMethodChange} tabsData={tabsData} />
      <h4>Share Code</h4>
      <CustomTooltip tooltip={tooltip}>
        <lui-textfield>
          <textarea readOnly value={this.getShareUrl()} />
        </lui-textfield>
        <lui-button secondary onClick={::this.onShare}>
          <i className="rf-share-container__menu__share-button" />Copy to clipboard
        </lui-button>
      </CustomTooltip>
    </div>;
  }

  toggleShareMenu() {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  }

  render() {
    let iconClass = classnames('rf-share-container__icon', {
      'rf-share-container__icon_active': this.state.showMenu,
    });
    return (
      <div ref="shareContainer">
        <div className="rf-share-container" onClick={::this.toggleShareMenu}>
          <i className={iconClass} />
        </div>
        {this.state.showMenu && this.getShareMenu()}
      </div>
    );
  }
}

ShareContainer.propTypes = {
  tabs: PropTypes.array.isRequired,
  settingsData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  notify: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    tabs: getTabs(state),
    selectedTab: state.selectedTab,
    settingsData: getSettingsData(state),
    state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notify: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareContainer);
