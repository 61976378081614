import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import CustomComponent from 'shared/component';
import FormRow from 'shared/formRow';
import CustomTextarea from 'shared/textarea';
import { CustomInput } from 'shared/input';
import TabsControl from 'shared/tabsControl';
import { isCoordValidAndDefined } from 'utils/validation/coordsValidation';
import Sortable, { SortableContainer } from 'react-anything-sortable';

import './style/advancedPointFields.scss';
import DragIcon from '../icons/dragIcon';
import adjustLUIStyle from 'utils/adjustLUIStyle';

class AdvancedPointFields extends CustomComponent {
  static PointTabs = {
    TEXT_AREA: 'textArea',
    POINTS: 'points',
  };

  state = {
    currentPointsTab: AdvancedPointFields.PointTabs.TEXT_AREA,
  };

  componentDidUpdate() {
    adjustLUIStyle(document.querySelectorAll('.rf-add-point lui-button'), 'lui-button');
  }

  parseCommaSeparatedPoints(value) {
    const valueArr = value ? value.split(',') : [];
    const res = [];
    for (let i = 0; i < valueArr.length; i += 2) {
      if (!valueArr[i + 1]) {
        res.push(valueArr[i] || '');
      } else {
        res.push(`${valueArr[i]},${valueArr[i + 1]}`);
      }
    }

    return res;
  }

  parsePointsArr(value, separator) {
    return separator === ',' ? this.parseCommaSeparatedPoints(value) : value.split(separator);
  }

  parseCoords(value, separator) {
    return this.parsePointsArr(value, separator);
  }

  stringifyPoints(value) {
    return value;
  }

  onChangePoint(num, e) {
    let newValue = utils.extractData(e);
    const { value, onChange, separator } = this.props;
    const pointsArr = this.parsePointsArr(value, separator);
    pointsArr[num] = newValue;
    onChange(pointsArr.join(separator));
  }

  onRemovePoint(num) {
    const { value, onChange, separator } = this.props;
    const pointsArr = this.parsePointsArr(value, separator);
    pointsArr.splice(num, 1);
    onChange(pointsArr.join(separator));
  }

  onAddPoint() {
    const { value, onChange, separator } = this.props;

    onChange(`${value}${separator}`);
  }

  getAdvancedWaypointContent() { // to be redefined
    return null;
  }

  setPoints(e) {
    const points = e.target.value;
    this.props.onChange(points);
  }

  getTextAreaEl() {
    let { value, isValid } = this.props;

    return <CustomTextarea
      label="Points"
      value={this.stringifyPoints(value)}
      isValid={isValid}
      onBlur={this.setPoints.bind(this)}
    />;
  }

  handleSort = (pointsArr) => {
    const { onChange, separator } = this.props;
    onChange(pointsArr.join(separator));
  }

  getPointsEl() {
    const { value, separator } = this.props;
    const pointsArr = this.parsePointsArr(value, separator);
    const coordsArr = this.parseCoords(value, separator);
    const fields = coordsArr.map((point, num) => (
      <SortableContainer key={`${point}${num}`} sortData={pointsArr[num]}>
        <div>
          <FormRow cssClasses="rf-point-item">
            <div className="rf-point-item__drag">
              <DragIcon />
            </div>
            <CustomInput
              cssClasses="rf-clearfix rf-point-item__input"
              label={`Point ${num + 1}`}
              placeholder="Coordinates"
              isValid={isCoordValidAndDefined(point)}
              value={point}
              onBlur={e => this.onChangePoint(num, e)}
              blurOnEnter
            />
            {value !== '' && <i
              className="rf-close-button rf-webfont-icon rf-webfont-icon_close"
              onClick={this.onRemovePoint.bind(this, num)}
            />}
          </FormRow>
          {this.getAdvancedWaypointContent(point, num)}
        </div>
      </SortableContainer>
    ));

    return (
      <div className="rf-add-point">
        <Sortable
          onSort={this.handleSort}
          direction="vertical"
          dynamic
          containment
          sortHandle="rf-point-item__drag"
        >
          {fields}
        </Sortable>
        <FormRow>
          <div className="rf-clearfix">
            <lui-button class="rf-add-point" onClick={::this.onAddPoint}>Add point</lui-button>
          </div>
        </FormRow>
      </div>
    );
  }

  render() {
    const pointsViewEl = this.state.currentPointsTab === AdvancedPointFields.PointTabs.TEXT_AREA ?
      this.getTextAreaEl() : this.getPointsEl();
    const tabsData = [
      { label: 'Text', name: AdvancedPointFields.PointTabs.TEXT_AREA },
      { label: 'Points', name: AdvancedPointFields.PointTabs.POINTS },
    ];

    return <div className={this.getCssClasses('rf-advanced-point-fields')}>
      <TabsControl
        tabsData={tabsData}
        currentTabName={this.state.currentPointsTab}
        onChange={e => this.setState({ currentPointsTab: e })}
      />
      {pointsViewEl}
    </div>;
  }
}

AdvancedPointFields.defaultProps = {
  separator: ';',
};

AdvancedPointFields.propTypes = {
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  separator: PropTypes.string.isRequired,
};

export default AdvancedPointFields;
