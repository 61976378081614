import * as types from './constants';
import defaultState from './defaultState';
import localStorageReducerDecorator from 'utils/localStorageReducerDecorator';

function setAppSettings(state, action) {
  switch (action.type) {
    case types.SET:
      return Object.assign({}, state, action.data);

    default:
      return Object.assign({}, state);
  }
}

export default localStorageReducerDecorator(setAppSettings, 'appSettings.v2', defaultState);
