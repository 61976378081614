import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormRow from 'shared/formRow';
import CustomCheckbox from 'shared/checkbox';
import AdvancedPointFields from 'shared/advancedPointFields';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { bindActionCreators } from 'redux';
import * as routeFromPointsActions
  from 'state/tabs/tab/formData/offlineToolsTab/visualizeDataPointsForm/routeFromPoints/routeFromPointsActions';

export class VisualizeDataPointsForm extends Component {
  onChange(key, e) {
    this.props.setData({ [key]: e.target.checked });
  }

  applyChanges() {
    this.props.applyChangesAction();
  }

  render() {
    let { routeFromPoints } = this.props.formData;
    return (
      <div>
        <ul className="rf-icons-bar" />
        <h3>Visualize Data Points form</h3>
        <FormRow>
          <CustomCheckbox
            label="Show Polyline"
            isChecked={routeFromPoints.showPolyline}
            onChange={this.onChange.bind(this, 'showPolyline')}
          />
          <CustomCheckbox
            label="Show Points"
            isChecked={routeFromPoints.showPoints}
            onChange={this.onChange.bind(this, 'showPoints')}
          />
          <AdvancedPointFields
            value={this.props.formData.routeFromPoints.points.value}
            isValid={this.props.formData.routeFromPoints.points.isValid}
            onChange={this.props.setPointsAction}
          />
        </FormRow>
        <FormRow>
          <lui-button onClick={this.applyChanges.bind(this)}>Apply</lui-button>
        </FormRow>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    formData: getCurrentFormData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPointsAction: bindActionCreators(routeFromPointsActions.setPoints, dispatch),
    setData: bindActionCreators(routeFromPointsActions.setRouteFromPointsData, dispatch),
    applyChangesAction: bindActionCreators(routeFromPointsActions.updateTimestamp, dispatch),
  };
}

VisualizeDataPointsForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  setPointsAction: PropTypes.func.isRequired,
  applyChangesAction: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisualizeDataPointsForm);
