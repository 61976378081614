import routing from './routing';
import matrix from './matrix';
import isoline from './isoline';
import routeInfo from './routeInfo';
import approachPathRouter from './approachPathRouter';
import matchRouter from './matchRouter';
import olsApi from './olsApi';

export default {
  routing,
  matrix,
  isoline,
  routeInfo,
  approachPathRouter,
  matchRouter,
  olsApi
};
