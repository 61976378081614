import { LOCATION_TYPES } from 'state/tabs/tab/formData/routingTab/approachPathRouterForm/fields/constants';
import { parseGeo, parseLink, parseStreet } from '../../gatherWaypoints';

export default function exec(data) {
  const _data = { ...data };

  const location = _data.location;
  if (location) {
    const [locationType, locationValue] = location.split(/!(.*)/);
    let locationOptions;
    _data.locationType = locationType;

    switch (_data.locationType) {
      case LOCATION_TYPES.GEO:
        locationOptions = parseGeo(locationValue.split('!'));
        _data.locationGeo = locationOptions.coords.value;
        _data.heading = locationOptions.heading.value;
        _data.transitRadius = locationOptions.transitRadius;
        _data.userLabel = locationOptions.userLabel;
        break;
      case LOCATION_TYPES.LINK:
        locationOptions = parseLink(locationValue.split('!'));
        _data.locationLink = locationOptions.link.value;
        _data.displayPosition = locationOptions.displayPosition.value;
        _data.userLabel = locationOptions.userLabel;
        break;
      case LOCATION_TYPES.STREET:
        locationOptions = parseStreet(locationValue.split('!'));
        _data.locationStreet = locationOptions.coords.value;
        _data.displayPosition = locationOptions.displayPosition.value;
        _data.heading = locationOptions.heading.value;

        _data.street = locationOptions.street.name;
        _data.streetPosition = locationOptions.street.value;

        _data.transitRadius = locationOptions.transitRadius;
        _data.userLabel = locationOptions.userLabel;
        break;
      case LOCATION_TYPES.OPEN_LR:
        _data.locationOpenLR = locationValue;
        break;
      case LOCATION_TYPES.TMC: {
        const parts = locationValue.split(',');
        if (parts.length > 2) {
          _data.TMCOffset = parts[1];
          _data.TMCLength = parts[2];
        } else if (parts.length === 2) {
          _data.TMCOffset = parts[1];
        }

        const extentMatches = parts[0].match(/(.+)e(.+)/);
        if (extentMatches && extentMatches.length > 2) {
          _data.locationTMC = extentMatches[1];
          _data.TMCExtent = extentMatches[2];
        } else {
          _data.locationTMC = parts[0];
        }

        break;
      }
      case LOCATION_TYPES.ETL:
        _data.locationETL = locationValue;
        break;
      default:
        throw new Error(`Unknown location type - ${_data.locationType}`);
    }
  }

  return _data;
}
