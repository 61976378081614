import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as NotificationActions from 'state/notification/actions';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import BaseContextMenu from '../../_ContextMenu';
import { inspectLink } from 'state/inspectLink/actions';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';
import { getSelectedTabs } from 'state/tabs';
import { getIsAutoRefreshOn } from 'state/appSettings/base';
import { getInspectLinkSettings } from 'state/appSettings/inspectLink/reducer';

export class ContextMenuContainer extends BaseContextMenu {

  setWaypoint(fieldName) {
    let { currentForm, data, filterValueUpdate } = this.props;
    filterValueUpdate({ key: fieldName, currentForm, value: { value: data.geo, isValid: true } });
  }

  getMenuItems(data) {
    let items = [];

    items.push({
      label: 'Route from',
      iconLabel: 'A',
      fn: this.setWaypoint.bind(this, 'origin'),
    });

    items.push({
      label: 'Route to',
      iconLabel: 'B',
      fn: this.setWaypoint.bind(this, 'destination'),
    });

    return items.concat(super.getMenuItems(data));
  }
}

ContextMenuContainer.defaultProps = {
  data: {},
};

ContextMenuContainer.propTypes = {
  data: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentForm: getForm(state),
    module: getModule(state),
    settingsData: getSettingsData(state),
    formData: getCurrentFormData(state),
    selectedTabId: getSelectedTabs(state).id,
    isAutoRefreshOn: getIsAutoRefreshOn(state),
    inspectLinkSettings: getInspectLinkSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    textCopiedNotify: bindActionCreators(NotificationActions.textCopiedNotify, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
    inspectLink: bindActionCreators(inspectLink, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextMenuContainer);
