import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CustomTooltip from 'shared/tooltip';
import CustomScrollSpy from 'shared/scrollSpy';

class IconsBar extends Component {
  onIconClick(id) {
    const { container } = this.props;
    let panel = document.querySelector('.rf-filter-panel .rf-sidebar__inner');
    let el = ReactDOM.findDOMNode(container).querySelector(`#${id}`);
    if (el === null) {
      return;
    }
    panel.scrollTop = el.offsetTop + 5;
  }

  render() {
    const { items } = this.props;
    const baseClassName = 'rf-icons-bar__item';

    return <CustomScrollSpy
      className="rf-icons-bar"
      rootEl=".rf-filter-panel .rf-sidebar__inner"
      currentClassName="rf-icons-bar__item_active"
      items={items.map(item => item.name)}
    >
      {items.map(item => <li
        className={`${baseClassName} ${baseClassName}_${item.name}`}
        onClick={() => this.onIconClick(item.name)}
        key={item.name}
      >
        <CustomTooltip tooltip={item.title}>
          <i className="rf-icons-bar__item__inner" />
        </CustomTooltip>
      </li>)}
    </CustomScrollSpy>;
  }
}

IconsBar.propTypes = {
  items: PropTypes.array.isRequired,
  container: PropTypes.object, // React component instance
};


export default IconsBar;
