import utils from 'utils';
import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';

import routingFormatter from './routingFormatter';
import matrixFormatter from './matrixFormatter';
import isolineFormatter from './isolineFormatter';
import routeInfoFormatter from './routeInfoFormatter';
import olsAPIFormatter from './olsAPIFormatter';

////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
//////////
import intermodalFormatter from './transitTab/intermodalFormatter';

const EXTRA_FIELDS_PER_FORM = {
  [modulesEnum.ROUTING]: {
    [formsEnum.CALCULATE_ROUTE]: ['extraparams', 'waypoints'],
    [formsEnum.CALCULATE_MATRIX]: ['start', 'destination'],
    [formsEnum.CALCULATE_ISOLINE]: ['extraparams', 'waypoints'],
    [formsEnum.GET_ROUTE_INFO]: ['extraparams'],
    [formsEnum.OLS]: ['extraparams', 'via'],
  },
//////////////////
//////////////////////////
///////////////////////////////////////////////
///////////////////////////////////////////
////
////////////
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: ['waypoints'],
  },
};

const FORMATTERS = {
  [modulesEnum.ROUTING]: {
    [formsEnum.CALCULATE_ROUTE]: routingFormatter,
    [formsEnum.CALCULATE_MATRIX]: matrixFormatter,
    [formsEnum.CALCULATE_ISOLINE]: isolineFormatter,
    [formsEnum.GET_ROUTE_INFO]: routeInfoFormatter,
////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
//////////////
    [formsEnum.OLS]: olsAPIFormatter,
  },
//////////////////
//////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////////
////
/////////////////////////////
//////////////////////////////////////////////////////
////
//////////////////////////////////////
////////////////////////////////////////////
///////////////////////////////////////////
////
//////////////////////////////
/////////////////////////////////////////////////
////
/////////////////////////
////////////////////////////////////////
////
////////////
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: intermodalFormatter,
  },
};

const CASE_SENSITIVE = {
//////////////////
/////////////////////////
////////////////////////////////////////////
////
////////////
  [modulesEnum.ROUTING]: {
    [formsEnum.OLS]: ['departureTime', 'arrivalTime', 'routingMode', 'transportMode'],
  },
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: ['departureTime', 'arrivalTime'],
  },
};

export default function formatter(args, formData, module = '', currentForm = '') {
  let fn = utils.getObject(FORMATTERS, [module, currentForm, args.key]);
  let extraField = utils.getObject(EXTRA_FIELDS_PER_FORM, [module, currentForm], []);
  let value = typeof fn === 'function' ? fn(args.value, formData) : args.value;
  let keyValPair;
  if (extraField.indexOf(args.key) > -1) {
    keyValPair = value;
  } else {
    const isCaseSensitive = utils.getObject(CASE_SENSITIVE, `${module}.${currentForm}`);
    keyValPair = `${isCaseSensitive ? args.key : args.key.toLowerCase()}=${value}`;
  }
  // Here is checking for empty instance: null, undefined, '', []
  if (value === null || value === '') {
    return null;
  }
  return keyValPair;
}
