import * as types from './constants';

export default function (state = false, action) {
  switch (action.type) {
    case types.TOGGLE_NEW_TAB:
      return action.isShown;
    default:
      return state;
  }
}
