const emptyObj = () => ({
  value: '',
  isValid: true,
});

export default {
  evEnabled: false,

  // Consumption Parameters
  freeFlowSpeedTable: emptyObj(),
  trafficSpeedTable: emptyObj(),
  auxiliaryConsumption: emptyObj(),
  ascent: emptyObj(),
  descent: emptyObj(),
  acceleration: emptyObj(),
  deceleration: emptyObj(),
  highSpeedThreshold: emptyObj(),

  // Battery Parameters
  initialCharge: emptyObj(),
  maxCharge: emptyObj(),
  chargingCurve: emptyObj(),
  maxChargingVoltage: emptyObj(),
  maxChargingCurrent: emptyObj(),

  // Charging Parameters
  maxChargeAfterChargingStation: emptyObj(),
  minChargeAtChargingStation: emptyObj(),
  minChargeAtDestination: emptyObj(),
  chargingSetupDuration: emptyObj(),

  // Enable ECAR
  makeReachable: '',
  'ev[connectorTypes]': [],
};
