function distance(meters, format = 'km', precision = 3) {
  const METERS_IN_MILE = 1609.344;
  const METERS_IN_KM = 1000;
  let result = [],
      convertMeters = (meters, coeff, precision) => (meters / coeff).toFixed(precision);

  if (typeof meters !== 'number') {
    if (meters !== undefined) {
      console.warn('utils#distance: Incorrect input format');
    }

    return '';
  }

  if (format.indexOf('km') !== -1) {
    let kilometers = convertMeters(meters, METERS_IN_KM, precision);
    result.push(kilometers > 1 ? `${kilometers} km` : `${meters} m`);
  }

  if (format.indexOf('mi') !== -1) {
    result.push(`${convertMeters(meters, METERS_IN_MILE, precision)} mi`);
  }

  if (result.length > 1) {
    result[1] = `(${result[1]})`;
  }

  return result.join(' ');
}

function time(s, format = 'long') {
  const MINUTES_IN_HOUR = 60;
  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
  const UNITS = {
    long: {
      h: ' h',
      m: ' min',
      s: ' sec',
    },
    short: {
      h: 'h',
      m: '\'',
      s: '\'\'',
    },
  };
  let hours = 0,
      minutes = 0,
      seconds = 0,
      result = [],
      addLeadingZero = (num) => {
        let abs = Math.abs(num),
            result = String(abs < 10 ? `0${abs}` : abs);

        return num > 0 ? result : (`-${result}`);
      };

  if (typeof s !== 'number') {
    if (s !== undefined) {
      console.warn('utils#format: Incorrect input format');
    }

    return '';
  }
  hours = Math.floor(s / SECONDS_IN_HOUR);
  if (hours > 0) {
    result.push(`${addLeadingZero(hours)}${UNITS[format].h}`);
  }
  minutes = Math.floor((s - (hours * SECONDS_IN_HOUR)) / SECONDS_IN_MINUTE);
  seconds = s - (hours * SECONDS_IN_HOUR) - (minutes * SECONDS_IN_MINUTE);
  if (minutes > 0) {
    result.push(`${addLeadingZero(minutes)}${UNITS[format].m}`);
  } else if (hours > 0 && seconds > 0) {
    result.push(`00${UNITS[format].m}`);
  }
  if (seconds > 0) {
    result.push(`${addLeadingZero(seconds)}${UNITS[format].s}`);
  }
  return result.slice(0, result.length).join(' ');
}

export default {
  distance, time,
};
