import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomInput from 'shared/input';
import FormRow from 'shared/formRow';
import CustomCheckbox from 'shared/checkbox';
import MapFormToggler from './MapFormToggler';

export default class RoRFormElectric extends Component {
  render() {
    let { formData, onChange, onCheckboxChange } = this.props;
    return <div className="rf-map__tooltip rf-map__ror-module rf-map__ror-module_electric" ref="form">
      <h2>Range on Route</h2>
      <MapFormToggler getFormRef={() => this.refs.form} {...this.props} />

      <FormRow>
        <CustomCheckbox
          label="Max Charge"
          isChecked={formData.rangeOnRoute.isMaxChargeActive}
          onChange={onCheckboxChange.bind(this, 'isMaxChargeActive')}
        />
        <CustomInput
          cssClasses="rf-clearfix"
          placeholder="Max Charge"
          disabled={!formData.rangeOnRoute.isMaxChargeActive}
          isValid
          value={formData.rangeOnRoute.maxcharge}
          onChange={onChange.bind(this, 'maxcharge')}
          type="number"
          debounce
        />
      </FormRow>

      <FormRow>
        <CustomCheckbox
          label="Initial Charge"
          isChecked={formData.rangeOnRoute.isInitialChargeActive}
          onChange={onCheckboxChange.bind(this, 'isInitialChargeActive')}
        />
        <CustomInput
          cssClasses="rf-clearfix"
          placeholder="Initial Charge"
          disabled={!formData.rangeOnRoute.isInitialChargeActive}
          isValid
          value={formData.rangeOnRoute.initialcharge}
          onChange={onChange.bind(this, 'initialcharge')}
          type="number"
          debounce
        />
        <span>{(formData.rangeOnRoute.initialcharge / formData.rangeOnRoute.maxcharge * 100).toFixed(1)}%</span>
      </FormRow>

      <FormRow>
        <CustomCheckbox
          label="Min Charge At Stop"
          isChecked={formData.rangeOnRoute.isMinChargeAtStopActive}
          onChange={onCheckboxChange.bind(this, 'isMinChargeAtStopActive')}
        />
        <CustomInput
          cssClasses="rf-clearfix"
          placeholder="Min Charge At Stop"
          disabled={!formData.rangeOnRoute.isMinChargeAtStopActive}
          isValid
          value={formData.rangeOnRoute.minchargeatstop}
          onChange={onChange.bind(this, 'minchargeatstop')}
          type="number"
          debounce
        />
        <span>{(formData.rangeOnRoute.minchargeatstop / formData.rangeOnRoute.maxcharge * 100).toFixed(1)}%</span>
      </FormRow>

      <FormRow>
        <CustomCheckbox
          label="Allow charging"
          isChecked={formData.rangeOnRoute.isChargingAllowed}
          onChange={onCheckboxChange.bind(this, 'isChargingAllowed')}
        />
      </FormRow>
    </div>;
  }
}

RoRFormElectric.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
};
