import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';

import CustomComponent from 'shared/component';

import './style/checkbox.scss';

class CustomCheckbox extends CustomComponent {
  constructor(props) {
    super(props);
    this.checkbox = React.createRef();
  }

  componentDidMount() {
    if (this.props.isChecked && this.checkbox.current.shadowRoot) {
      this.checkbox.current.shadowRoot.querySelector('input').checked = this.props.isChecked;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isChecked !== this.props.isChecked) {
      this.checkbox.current.shadowRoot.querySelector('input').checked = this.props.isChecked;
    }
  }

  render() {
    let { label, onChange, isChecked, value, disabled, ...checkboxProps } = this.props;

    if (utils.isString(isChecked)) {
      isChecked = isChecked.toLowerCase() === 'true';
    }

    if (disabled) {
      checkboxProps.disabled = true;
    }
    if (isChecked) {
      checkboxProps.checked = true;
    }

    return <lui-checkbox
      value={value}
      class={this.getCssClasses(['rf-checkbox', { 'rf-checkbox_disabled': disabled }])}
      onClick={onChange}
      ref={this.checkbox}
      {...checkboxProps}
    >
      {label}
    </lui-checkbox>;
  }
}

CustomCheckbox.defaultProps = {
  value: '',
  disabled: false,
};

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomCheckbox;
