import utils from 'utils';
import config from 'config/tiles';
import { TILE_TYPES } from '../../../state/map/tiles/constants';

export const TILE_TYPE_ENGINE_MAP = {
  [TILE_TYPES.RASTER]: H.map.render.RenderEngine.EngineType.P2D,
  [TILE_TYPES.VECTOR]: H.map.render.RenderEngine.EngineType.WEBGL,
};

export function getTileLayer(platform, tilesData) {
  const { type } = tilesData;
  switch (type) {
    case TILE_TYPES.RASTER:
      return getRasterTileLayer(platform, tilesData[type]);
    case TILE_TYPES.VECTOR:
      return getVectorTileLayer(platform, tilesData[type]);
    default:
      throw new Error(`Failed to create tile layer. Unsupported tile type: "${type}"`);
  }
}

function getRasterTileLayer(
  platform,
  { apiKey, appId, appCode, type, scheme, size, format, lg, additionalParameters, url }) {

  let serviceType = config.type.find(item => item.value === type).serviceType;
  const tileServiceOpts = { type: serviceType };
  if (!apiKey) {
    tileServiceOpts.baseUrl = new H.service.Url('https', url, 'maptile/2.1', {
      app_id: appId,
      app_code: appCode,
    });
  }
  const mapTileService = platform.getMapTileService(tileServiceOpts);
  let additionalParametersObj = {};
  if (utils.isString(additionalParameters)) {
    additionalParameters.split('&').forEach(pair => {
      let [key, val = ''] = pair.split('=');
      additionalParametersObj[key] = val;
    });
  } else {
    additionalParametersObj = additionalParameters;
  }
  return mapTileService.createTileLayer(type, scheme, size, format, { ...additionalParametersObj, lg });
}

export function getVectorTileLayer(platform, urlOptions, styles, serviceOptions = {}) {
  const { url, apiKey, path, styleUrl } = urlOptions;
  const service = platform.getOMVService({
    subDomain: '',
    baseUrl: new H.service.Url('https', url, path.replace(/^\/|\/$/g, ''), {
      apikey: apiKey,
    }),
    ...serviceOptions,
  });
  const provider = new H.service.omv.Provider(
    service,
    new H.map.Style(styles || styleUrl),
  );
  return new H.map.layer.TileLayer(provider, { max: 22 });
}
