import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import FormRow from 'shared/formRow';
import ExpandFormRow from 'shared/expandFormRow';
import CloseButton from 'shared/closeButton';
import AutosizeInput from 'shared/autosizeInput';

import DataSwitcher from './DataSwitcher';

import classnames from 'classnames';
import utils from 'utils';

import './styles/item.scss';
import ReverseIcon from '../ReverseIcon';
import DragIcon from '../../../shared/icons/dragIcon';

class Item extends Component {
  static getCoords(props) {
    const COORDS_PATH = 'data.coords.value';
    return utils.getObject(props, COORDS_PATH);
  }

  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
    this.removeWaypoint = this.props.onChange.bind(this);
    this.constructor.defaultProps = Item.defaultProps;
    this.constructor.propTypes = Item.propTypes;
  }

  onChange(key, e) {
    let val = e.target ? e.target.value : e;
    let { street, type } = this.props.data;
    let value = {
        displayPosition: { value: val },
        coords: { value: val },
        link: { value: val },
        street: Object.assign({}, street, { name: val }),
        stopOverDuration: { value: val },
        heading: { value: val },
      }[key] || val;
    if (val && key === 'stopOverDuration' && type !== 'stopOver') {
      this.props.setNotification({
        impact: 'significant',
        message: `Stop Over Duration will be ignored for passThrough waypoint!`,
      });
    }
    this.props.onChange({ [key]: value });
  }

  onEditLabel(e) {
    e.stopPropagation();
    this.setState({ isEdit: true });
  }

  onChangeTitle(e) {
    const title = utils.extractData(e);
    if (title !== '') {
      this.onChange('userLabel', e);
    }
    this.setState({ isEdit: false });
  }

  getDeleteIcon() {
    return (
      <CloseButton onClick={this.props.removeWaypoint} />
    );
  }

  getDataSwitcherEl() {
    return DataSwitcher;
  }

  getMainContent() {
    const { formConfig, data } = this.props;
    if (!data.category && !data.type) {
      return null;
    }
    const DataSwitcherEl = this.getDataSwitcherEl();
    return (
      <DataSwitcherEl
        formConfig={formConfig}
        data={data}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  getAdvancedContent() {
    const stopOverDuration = utils.getObject(this.props, 'data.stopOverDuration', {});

    if (!this.props.data.category && !this.props.data.type) {
      return null;
    }

    return (
      <div>
        {this.renderInput('stopOverDuration', stopOverDuration.value, 'Stop Over Duration (seconds)',
          stopOverDuration.isValid)}
      </div>
    );
  }

  getEVContent() {
    const { power, supplyType, voltage, current, phases } = utils.getObject(this.props, 'data', {});
    return <ExpandFormRow label="EV Params">
      {this.renderInput('power', power, 'Power (kW)')}
      <FormRow>
        <CustomSelect
          value={supplyType}
          options={this.props.formConfig.supplyTypes}
          addEmptyOption
          onChange={this.onChange.bind(this, 'supplyType')}
          label="Power supply current type"
        />
      </FormRow>
      {this.renderInput('voltage', voltage, 'Voltage (V)')}
      {this.renderInput('current', current, 'Amperes (A)')}
      {this.renderInput('phases', phases, 'Number of phases')}
    </ExpandFormRow>;
  }

  getLabel() {
    let { label, data } = this.props;
    return data.userLabel || label.charAt(0).toUpperCase() + label.slice(1);
  }

  getLabelEl() {
    let label = this.getLabel();
    if (this.state.isEdit) {
      return (
        <AutosizeInput
          ref="titleInput"
          value={label}
          onBlur={::this.onChangeTitle}
          cssClasses="rf-nav-tabs__tab__title-input"
        />
      );
    } else if (label !== '') {
      return <h3 className="rf-waypoints-group-item__header__label">{label}</h3>;
    }
    return <h3 className="rf-waypoints-group-item__header__label rf-waypoints-group-item__header__label_empty">
      - label is not set -
    </h3>;
  }

  getDragItem() {
    return <div className="rf-waypoints-group-item__header__drag"><DragIcon /></div>;
  }

  getReverseItem() {
    return <div className="rf-waypoints-group-item__header__reverse">
      <ReverseIcon label="Reverse all" reverse={this.props.reverseWaypoints} />
    </div>;
  }

  renderInput(key, value, label, isValid = true) {
    return (
      <FormRow>
        <CustomInput
          cssClasses="rf-clearfix"
          label={label}
          placeholder={label}
          isValid={isValid}
          value={value}
          onBlur={this.onChange.bind(this, key)}
          blurOnEnter
        />
      </FormRow>
    );
  }

  render() {
    let { data, index, reverseWaypoints } = this.props;
    let deleteEl = data.isWaypoint ? this.getDeleteIcon() : '';
    let cssClasses = classnames('rf-waypoints-group-item', 'rf-clearfix', {
      'rf-waypoints-group-item_waypoint': data.isWaypoint,
    });

    return (
      <div className={cssClasses}>
        <div className="rf-waypoints-group-item__header">
          {index !== undefined && this.getDragItem()}
          {this.getLabelEl()}
          {this.state.isEdit ? null :
            <i className="rf-waypoints-group-item__header__edit" onClick={::this.onEditLabel} />}
          {deleteEl}
          {index === 0 && reverseWaypoints && this.getReverseItem()}
        </div>
        {this.getMainContent()}
        <ExpandFormRow label="Advanced">
          {this.getAdvancedContent()}
        </ExpandFormRow>
        {this.getEVContent()}
      </div>
    );
  }
}

Item.defaultProps = {
  removeWaypoint() {},
};

Item.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  removeWaypoint: PropTypes.func.isRequired,
  reverseWaypoints: PropTypes.func,
  onGetStreetAddress: PropTypes.func,
  onGetLinkId: PropTypes.func,
  label: PropTypes.string.isRequired,
  route: PropTypes.object,
  index: PropTypes.number,
  setNotification: PropTypes.func.isRequired,
};

export default Item;
