import { assignTabDataAndDispatch } from '../../../actions';
import { SET } from './constants';

export function setRenderRoutePlan(renderRoutePlan = true) {
  return assignTabDataAndDispatch({ type: SET, data: { renderRoutePlan } });
}

export function setRenderLegSplits(renderLegSplits = true) {
  return assignTabDataAndDispatch({ type: SET, data: { renderLegSplits } });
}
