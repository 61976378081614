import fetch from 'utils/fetch';
import { timeoutNotifier } from '../../../../response/actions';
import { assignTabDataAndDispatch } from '../../../actions';
import * as types from './reducer';

export function fetchOperableStatus(url) {
  return (dispatch, getState) => Promise.race([
    fetch(url),
    timeoutNotifier(dispatch, getState),
  ]).then(response => dispatch(setOperableStatus(response)));
}

export function setOperableStatus(data) {
  return assignTabDataAndDispatch({ type: types.SET, data });
}
