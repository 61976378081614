import { createSelector } from 'reselect';
import queryParamsSelector from './queryParamsSelector';
import { stringifyUrl } from 'utils/url';
import settingsUtils from 'utils/settings';
import { getModule } from 'state/tabs/tab/module';
import { getForm } from 'state/tabs/tab/form';
import { getSettingsData } from 'state/settingsPreset';

export default createSelector(
  queryParamsSelector,
  state => settingsUtils.getPresetSettings(getModule(state), getForm(state), getSettingsData(state)),
  stringifyUrl
);
