import FormBase from '../../_Base';
import Traces from './traces';

export default class ApproachPathRouter extends FormBase {
  initModules() {
    return {
      ...super.initModules(),
      traces: new Traces(this.map, this.state),
    };
  }

  process(props, nextProps, forceRender) {
    if (this.isPropChanged(nextProps, 'tabData.response.data') ||
      this.isPropChanged(nextProps, 'tabData.formData.fields') ||
      this.isPropChanged(nextProps, 'tabData.formData.selectedTrace') ||
      forceRender) {
      this.modules.traces.process(nextProps);
      this.setViewBounds();
    }

    super.process(props, nextProps, forceRender);
    this.setProps(nextProps);
  }

  setViewBounds(force = false) {
    super.setViewBounds(this.modules.traces.getGroup(), force);
  }
}
