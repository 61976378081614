import React, { Component } from 'react';

export default class LeftArrow extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="9.32" height="15.82" viewBox="0 0 9.32 15.82">
        <path
          d="M2608.41,1886.48l-1.41,1.42-7.9-7.9,7.9-7.9,1.41,1.41-6.48,6.49Z"
          transform="translate(-2599.09 -1872.09)"
        />
      </svg>
    );
  }
}
