import Immutable from 'immutable';

import * as types from './constants';
import defaultState from './defaultState';
import { SET_IS_LOADING } from '../tabs/tab/isLoading/constants';

function setSearch(state = defaultState, action) {
  switch (action.type) {
    case types.SET_VALUE:
      return Object.assign({}, state, action.data);

    case types.SELECT_RESULT:
      return Object.assign({}, state, {
        results: Immutable.fromJS(state.results).map(
          (result, index) => result.set('selected', index === action.data.index)
        ).toJS(),
      });

    case types.RESET:
      return Immutable.fromJS(defaultState).toJS();

    case SET_IS_LOADING:
      return Object.assign({}, defaultState);

    default:
      return Object.assign({}, state);
  }
}

export default setSearch;
