import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';

class Modal extends CustomComponent {
   constructor(props) {
    super(props);
    this.overlay = React.createRef();
  }

  render() {
    return <lui-overlay ref={this.overlay} class={this.getCssClasses()}>
      <h1 className="lui-h1">{this.props.title}</h1>
      {this.props.children}
    </lui-overlay>;
  }

  componentDidUpdate() {
    this.toggleOverlayVisibility();
  }

  componentDidMount() {
    this.toggleOverlayVisibility();
  }

  toggleOverlayVisibility() {
    setTimeout(() => {
      if (!this.overlay.current) {
        return;
      }
      if (this.props.isShown) {
        this.overlay.current.showModal();
      } else {
        this.overlay.current.close();
      }
    }, 1);
  }
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  isShown: PropTypes.bool,
};

Modal.defaultProps = {
  isShown: false,
};

export default Modal;
