import defaultPresetsState, { DEFAULT_PRESET } from 'state/settingsPreset/defaultPresetsState';
import { getHostName, stringifyUrl } from '../index';
import formsEnum from 'config/formsEnum';
import utils from 'utils';

export function getData(url, paramsStr = '', currentModule, currentForm, settingsPresets = defaultPresetsState) {
  const { module, form } = getModuleAndForm(url, currentModule, currentForm, settingsPresets);
  const preset = (module === null || form === null) ? null : getPreset(url, module, form, paramsStr, settingsPresets);
  return { module, form, preset };
}

export function getPreset(url, module, form, paramsStr, settingsPresets = defaultPresetsState) {
  let url_ = `${url}?`;
  let preset = null;
  const presets = Object.keys(settingsPresets[module][form]).filter(preset_ => {
    let generatedUrl = stringifyUrl({ queryParams: '' }, settingsPresets[module][form][preset_]);
    return url_ === generatedUrl.apiUrl;
  });
  if (presets.length > 1) {
    if (form === formsEnum.OLS && paramsStr.toLowerCase().indexOf('apikey=') !== -1) {
      preset = 'PRODUCTION_APIKEY';
    } else {
      preset = 'PRODUCTION';
    }
  } else if (presets.length) {
    preset = presets[0];
  }
  return preset;
}

export function getModuleAndForm(url = '', currentModule, currentForm, settingsPresets = defaultPresetsState) {
  const FORM_URLS = {};
  const FORM_PATH = Object.keys(settingsPresets).reduce((result, module) => {
    Object.keys(settingsPresets[module]).forEach(form => {
      if (form === 'currentPreset') {
        return;
      }
      let path = utils.getObject(settingsPresets, `${module}.${form}.${DEFAULT_PRESET}.path`);
      const acceptablePaths
        = utils.getObject(settingsPresets, `${module}.${form}.${DEFAULT_PRESET}.acceptablePaths`, []);
      const paths = [path, ...acceptablePaths];

      const presets = utils.getObject(settingsPresets, `${module}.${form}`);

      Object.keys(presets).forEach(presetName => {
        const key = `${presets[presetName].host}${presets[presetName].path}`;
        FORM_URLS[key] = { form, module };
      });

      paths.forEach(path => {
        if (!result[`${path}`]) {
          result[`${path}`] = [];
        }
        result[`${path}`].push({ form, module });
      });
    });
    return result;
  }, {});

  const [fullPath = ''] = url.split('?');
  let path = fullPath.replace(new RegExp('^https?:\\/\\/[^\\/]+'), '').replace(new RegExp('/$'), '');
  const apiUrl = `${getHostName(fullPath)}${path}`;


  if (FORM_URLS[apiUrl]) {
    return FORM_URLS[apiUrl];
  }

  if (FORM_PATH[path] && FORM_PATH[path].length === 1) {
    return FORM_PATH[path][0];
  }

  if (FORM_PATH[path] && FORM_PATH[path].length > 1 && currentModule && currentForm) {
    const current = FORM_PATH[path].find(({ form, module }) =>
      form === currentForm && module === currentModule);
    if (current) {
      return current;
    }
  }

  return { module: null, form: null };
}

export default {
  getModuleAndForm,
  getPreset,
  getData,
};
