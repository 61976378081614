import * as types from './constants';
import { assignTabDataAndDispatch } from '../../actions';

export function filterValueUpdate(data) {
  return assignTabDataAndDispatch({ type: types.UPDATE, data });
}

export function filterValueMultiUpdate(currentForm, params, needsRerender) {
  let data = { currentForm, needsRerender };
  return assignTabDataAndDispatch({ type: types.MULTI_UPDATE, data, params });
}

export function resetAlternatives(currentForm) {
  return assignTabDataAndDispatch({
    type: types.UPDATE,
    data: {
      key: 'alternatives',
      value: '',
      currentForm,
    },
  });
}

export function mapObjectUpdate(data) {
  return assignTabDataAndDispatch({ type: types.MAP_UPDATE, data });
}

export function importFields(data) {
  return assignTabDataAndDispatch({ type: types.IMPORT, data });
}
