import copyToClipboard from 'copy-to-clipboard';

import utils from 'utils';
import fetch, { xmlErrorParse } from 'utils/fetch';
import syncFetch from 'utils/syncFetch';
import { stringifyUrl, objectToQueryString } from 'utils/url';
import getGeocoderSettings from 'config/geocoder';
import { getToken } from '../tabs/tab/response/actions';

export function asyncCopyAddress(coords, settings) {
  const LABEL_PATH = 'Location.Address.Label';
  let params = {
    app_id: settings.urlQuery.app_id,
    app_code: settings.urlQuery.app_code,
    searchtext: coords,
  };
  let url = stringifyUrl(
    { queryParams: objectToQueryString(params) },
    getGeocoderSettings('search').url,
  ).apiUrl;

  return new Promise((resolve, reject) => {
    syncFetch(url).then(xhr => {
      let response = JSON.parse(xhr.responseText);
      let label;
      response.Response.View[0].Result.find((result) => utils.getObject(result, LABEL_PATH));
      if (label) {
        copyToClipboard(label);
      }
      resolve();
    }).catch(err => {
      try {
        reject(JSON.parse(err.responseText));
      } catch (e) {
        reject(xmlErrorParse(err.responseText));
      }
    });
  });
}

export function asyncCopyAddressOls(coords, callback, onError) {
  return async (dispatch, getState) => {
    const state = getState();
    const settings = state.settingsData.settingsPresets.routing.OLS.PRODUCTION;
    const proxyParams = {
      url: settings.urlQuery.proxy,
      token: (await getToken(settings, dispatch, state.selectedTab, 'PRODUCTION')).token,
    };
    const params = {
      at: coords,
      limit: 1,
    };
    let geocoderSettings = getGeocoderSettings('reversegeocode', 'ols');
    let url = stringifyUrl({
      queryParams: objectToQueryString(params),
    }, geocoderSettings.url).apiUrl;

    fetch(url, geocoderSettings.url.jsonp, proxyParams).then(res => {
      if (res.isError && !res.data.items.length) {
        onError();
        return;
      }
      callback(res.data.items[0].title);
    });
  };
}
