const NS = 'state';

export default function (reducer, key, defaultState = {}) {
  let fullKey = `${NS}.${key}`;
  let storedState = JSON.parse(localStorage.getItem(fullKey) || '{}');
  let initialState = Object.assign({}, defaultState, storedState);
  return (state = initialState, action) => {
    let newState = reducer(state, action);
    localStorage.setItem(fullKey, JSON.stringify(newState));
    return newState;
  };
}
