import React from 'react';

import CustomComponent from 'shared/component';

import './styles/notification.scss';

class CustomNotifications extends CustomComponent {
  state = {
    notifications: [],
  };

  render() {
    return <div className="rf-notifications">
      {this.state.notifications.map((notification, key) => {
        const { impact = 'positive' } = notification;
        const body = notification.children ? <div slot="bodytext">{notification.children}</div> : notification.message;
        return <lui-default-theme><lui-notification open impact={impact} key={key}>
          {body}
          <lui-clear
            slot="controls"
            onClick={this.dismiss.bind(this, notification)}
          />
        </lui-notification></lui-default-theme>;
      })}
    </div>;
  }

  addNotification(notification) {
    if (notification.autoDismiss) {
      notification.timeoutId = setTimeout(this.dismiss.bind(this, notification), notification.autoDismiss * 1000);
    }
    this.setState({
      notifications: [...this.state.notifications, notification],
    });
  }

  dismiss(notification) {
    clearTimeout(notification.timeoutId);
    this.setState({
      notifications: this.state.notifications.filter(currentNotification => currentNotification !== notification),
    });
  }
}

export default CustomNotifications;
