import utils from 'utils';

const waypointOptions = ['stopDuration', 'passThrough'];

function stringifyAdvancedWaypoint(data) {
  let placeOptionsStr = '';
  let waypointOptionsStr = '';

  Object.keys(data).forEach(key => {
    let param = data[key];
    const isValid = utils.getObject(param, 'isValid', true);
    const value = utils.getObject(param, 'value', param);

    if (!isValid || value === '') {
      return;
    }

    if (waypointOptions.indexOf(key) !== -1) {
      waypointOptionsStr += `!${key}=${value}`;
    } else {
      placeOptionsStr += `;${key}=${value}`;
    }
  });

  return `${placeOptionsStr}${waypointOptionsStr}`;
}

export default stringifyAdvancedWaypoint;
