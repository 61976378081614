import { isLinkListValid } from 'utils/validation/linkValidation';
import isExtraValid from 'utils/validation/extraParamsValidation';
import isNumericSequence from 'utils/validation/toleranceValidation';
import isNumberValid from 'utils/validation/numericValidation';

export default {
  extraparams: isExtraValid,
  generalizationtolerances: isNumericSequence,
  avoidlinks: isLinkListValid,
  maxnumberofchanges: isNumberValid({ min: 0, max: 10 }),
  walkradius: isNumberValid({ min: 0, max: 6000 }),
  walkspeed: isNumberValid({ min: 0.5, max: 2 }),
};
