import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomButton from 'shared/button';
import { mapFan } from './compareUtils';

class CompareMapFanButton extends Component {
  open = () => {
    const { getWaypoints, getOptions } = this.props;
    const waypoints = getWaypoints(this.props);
    if (!waypoints) {
      throw new Error('Can\'t get waypoints');
    }
    const options = getOptions(this.props);
    mapFan.open(waypoints, options);
  }

  render() {
    return (
      <CustomButton onClick={this.open} title="Compare to MapFan" />
    );
  }
}

CompareMapFanButton.propTypes = {
  formData: PropTypes.object,
  getWaypoints: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
};

export default CompareMapFanButton;
