import * as types from './constants';
import { assignTabDataAndDispatch } from '../../../actions';

export function add(data) {
  return assignTabDataAndDispatch({ type: types.POINT_ADD_MATRIX, data });
}

export function update(data) {
  return assignTabDataAndDispatch({ type: types.POINT_UPDATE_MATRIX, data });
}

export function set(data) {
  return assignTabDataAndDispatch({ type: types.POINT_SET_MATRIX, data });
}

export function updateAll(data) {
  return assignTabDataAndDispatch({ type: types.POINT_UPDATE_ALL_MATRIX, data });
}

export function remove(data) {
  return assignTabDataAndDispatch({ type: types.POINT_REMOVE_MATRIX, data });
}
