import { listValidation, boolValidation, enumValidation } from '../common';
import formConfig from 'config/form';
import modeValidation from '../common/modeValidation';
import formsEnum from 'config/formsEnum';
import avoidAreasValidation from '../common/avoidAreasValidation';
import avoidLinksValidation from '../common/avoidLinksValidation';
import dateTimeValidation from '../common/dateTimeValidation';
import numericValidation from '../common/numericValidation';
import waypointValidation from '../common/waypointValidation';
import routeCommonValidation from './routeCommonValidation';
import consumptionValidation from '../common/consumptionValidation';
import { getIntegerSequenceValidation } from '../common/integerValidation';
import getTruckValidation from '../common/truckValidation';
import countryCodes from 'config/countryCodes';

const config = formConfig(formsEnum.CALCULATE_ROUTE);

export default {
  ...routeCommonValidation,
  ...getTruckValidation(config),
  waypoint: waypointValidation,
  mode: modeValidation(formsEnum.CALCULATE_ROUTE),
  avoidareas: avoidAreasValidation,
  avoidlinks: avoidLinksValidation,
  avoidseasonalclosures: boolValidation,
  departure: dateTimeValidation,
  arrival: dateTimeValidation,
  alternatives: numericValidation({ min: 0, max: 9, key: 'alternatives' }),
  resolution: resolutionValidation,
  maxnumberofchanges: numericValidation({ min: 0, max: 10, key: 'maxnumberofchanges' }),
  avoidtransporttypes: listValidation('avoidtransporttypes', config.transportTypes.map(mapValue)),
  walkspeed: numericValidation({ min: 0.5, max: 2, key: 'walkspeed' }),
  walkradius: numericValidation({ min: 0, max: 6000, key: 'walkradius' }),
  combinechange: boolValidation,
  consumptionmodel: enumValidation('consumptionmodel', config.consumptionTypes.map(mapValue)),
  customconsumptiondetails: consumptionValidation,
  excludezones: getIntegerSequenceValidation(),
  allowedzones: getIntegerSequenceValidation(),
  excludezonetypes: listValidation('excludezonetypes', config.excludeZoneTypes.map(mapValue)),
  excludecountries: listValidation('excludecountries', countryCodes),
};

function mapValue(val) {
  return val.value;
}

function resolutionValidation(value) {
  let [viewRes, snapRes] = value.split(':');
  let errors = [];
  let viewResValidation = numericValidation({ key: 'viewresolution' });
  let snapResValidation = numericValidation({ key: 'snapresolution' });

  if (viewRes) {
    errors = errors.concat(viewResValidation(viewRes));
  }
  if (snapRes) {
    errors = errors.concat(snapResValidation(snapRes));
  }
  return errors;
}
