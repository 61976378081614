import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';

import FormRow from 'shared/formRow';
import ExpandFormRow from 'shared/expandFormRow';
import CustomSelect from 'shared/select';

import _RoutingFormBase from '../common/_RoutingFormBase';
import RouteDateField from '../common/RouteDateField';
import ModeFieldSet from '../common/ModeFieldSet';
import SpeedProfile from '../common/SpeedProfile';
import WaypointsGroup from '../common/WaypointsGroup';
import TruckProfileBlock from '../common/TruckProfileBlock';
import ConsumptionBlock from '../common/Consumption';
import AvoidFeatures from '../common/AvoidFeatures';
import IconsBar from '../common/IconsBar';

import * as WaypointsActions from 'state/tabs/tab/formData/common/waypoints/actions';
import * as AvoidAreasActions from 'state/tabs/tab/formData/common/avoidAreas/actions';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as NotificationActions from 'state/notification/actions';
import * as TabsAction from 'state/tabs/actions';
import { setTabTitle } from 'state/tabs/tab/title/actions';

import settings from 'utils/settings';
import utils from 'utils';
import { getResponseData } from 'state/tabs/tab/response';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getSelectedTab } from 'state/selectedTab';

import languagesList from 'config/languagesList';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';
import { getUrlSettings } from 'state/urlSettings';
import ImportRoute from './routingForm/ImportRoute';
import ExportRoute from './routingForm/ExportRoute';
import { getSelectedTabs } from '../../../state/tabs';
import ImportEVParameters from './routingForm/importEVParameters';

export class RoutingForm extends _RoutingFormBase {
  componentDidUpdate(prevProps) {
    let { rangeOnRoute = {} } = this.props.formData;
    let prevRangeOnRoute = prevProps.formData.rangeOnRoute || {};
    if (rangeOnRoute.isActive && !prevRangeOnRoute.isActive) {
      let consumptionEl = ReactDOM.findDOMNode(this.refs.consumptionBlock);
      if (consumptionEl && consumptionEl.scrollIntoView) {
        consumptionEl.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onGetStreetAddress(index, coords) {
    let { currentForm, settingsData, getStreetAddress, module } = this.props;
    let settingsPreset = settings.getPresetSettings(module, currentForm, settingsData);
    getStreetAddress({
      settings: settingsPreset,
      currentForm,
      index,
      coords,
    });
  }

  onGetLinkId(index, coords) {
    let { getLinkId, currentForm, settingsData, module } = this.props;
    let settingsPreset = settings.getPresetSettings(module, currentForm, settingsData);
    getLinkId(coords, currentForm, settingsPreset, index);
  }

  onRouteDateChange = (data) => {
    const routeDate = { ...data };
    if (data.key === 'now') {
      routeDate.key = 'departure';
      routeDate.value = 'now';
    }
    this.onChange('routeDate', routeDate);
  }

  getTimeValue = (routeDate) => {
    if (routeDate.key === 'now') {
      return 'departure: now';
    } else if (routeDate.key === '') {
      return null;
    }
    return `${routeDate.key}: ${routeDate.value}`;
  }

  getRequestTabContent(currentForm, formConfig) {
    let {
      formData, responseData, setNotification, setWaypoints, duplicateTab, selectedTab, setTabTitle, tabTitle,
      waypointUpdate, filterValueMultiUpdate,
    } = this.props;
    let route = utils.getObject(responseData, 'data.response.route[0]');
    let { fields = {}, waypoints = {}, avoidAreas = {} } = formData,
        {
          routeDate: fieldsRouteDate,
          mode,
          avoidTransportTypes,
          language,
          speedprofile,
          useNewWaypointsFormat,
          enhancedbicyclerouting,
        } = fields,
        isTruckTransportMode = mode.transport === 'truck';
    const routeDate = { value: fieldsRouteDate.value };
    routeDate.key = fieldsRouteDate.key;
    if (fieldsRouteDate.value === 'now') {
      routeDate.key = 'now';
    }
    return (
      <div>
        <IconsBar
          items={[
            { name: 'waypoints', title: 'Waypoints' },
            { name: 'mode', title: 'Mode' },
            { name: 'time', title: 'Route Time' },
            { name: 'advanced', title: 'Advanced Parameters' },
            { name: 'avoid-features', title: 'Avoid Features' },
            { name: 'language', title: 'Language' },
          ]}
          container={this}
        />
        <WaypointsGroup
          currentForm={currentForm}
          waypoints={waypoints}
          formConfig={formConfig}
          canAddWaypoints
          isDNDEnabled
          onChange={this.props.updateWaypoint}
          removeWaypoint={this.props.removeWaypoint}
          changeWaypointsOrder={this.props.changeWaypointsOrder}
          reverseWaypoints={this.props.reverseWaypoints}
          addWaypoint={this.props.addWaypoint}
          onGetStreetAddress={::this.onGetStreetAddress}
          onGetLinkId={::this.onGetLinkId}
          route={route}
          setNotification={this.props.setNotification}
          useNewWaypointsFormat={useNewWaypointsFormat}
          updateWaypointFormat={(e) => this.onChange('useNewWaypointsFormat', e)}
        />
        <ModeFieldSet
          label="Mode"
          value={mode}
          formConfig={formConfig}
          onChange={::this.onChange}
          avoidTransportTypes={avoidTransportTypes}
          scooter={this.props.scooter}
          enhancedbicyclerouting={enhancedbicyclerouting}
          duplicateTab={duplicateTab}
          selectedTab={selectedTab}
          setTabTitle={setTabTitle}
          tabTitle={tabTitle}
        />
        <SpeedProfile
          formConfig={formConfig}
          value={speedprofile}
          onChange={this.onChange.bind(this, 'speedprofile')}
          isHidden={!isTruckTransportMode}
        />
        <ConsumptionBlock
          fields={fields}
          onChange={::this.onChange}
          label="Consumption"
          formConfig={formConfig}
          filterValueMultiUpdate={this.props.filterValueMultiUpdate}
          currentForm={this.props.currentForm}
          setNotification={this.props.setNotification}
          ref="consumptionBlock"
        />
        <TruckProfileBlock
          label="Truck Profile"
          fields={fields}
          formConfig={formConfig}
          isHidden={!isTruckTransportMode}
          setNotification={this.props.setNotification}
          onChange={this.onChange.bind(this)}
        />
        <FormRow cssClasses="rf-grey-box rf-route-time" id="time">
          <RouteDateField
            label="Route Time"
            value={this.getTimeValue(routeDate)}
            formConfig={formConfig}
            routeDate={routeDate}
            onChange={this.onRouteDateChange}
          />
        </FormRow>
        <ExpandFormRow
          label="Advanced Parameters"
          cssClasses="rf-grey-box rf-advanced-params"
          id="advanced"
          isExpanded
        >
          { this.getMiscellaneousTabContent(formConfig) }
        </ExpandFormRow>
        <AvoidFeatures
          onChange={::this.onChange}
          avoidAreaAdd={this.props.avoidAreaAdd}
          avoidAreas={avoidAreas}
          avoidAreaUpdate={this.props.avoidAreaUpdate}
          avoidAreaUpdateAll={this.props.avoidAreaUpdateAll}
          avoidAreaRemove={this.props.avoidAreaRemove}
          currentForm={currentForm}
          fields={fields}
          notify={setNotification}
        />
        <FormRow cssClasses="rf-language rf-grey-box" id="language">
          <CustomSelect
            label="Language"
            value={language}
            options={languagesList}
            onChange={e => this.onChange('language', e)}
          />
        </FormRow>
        <FormRow cssClasses="rf-grey-box">
          <ImportRoute
            setWaypoints={setWaypoints}
            currentForm={currentForm}
            onImport={this.props.importFields}
          />
        </FormRow>
        <FormRow cssClasses="rf-grey-box">
          <ImportEVParameters
            setNotification={setNotification}
            waypointUpdate={waypointUpdate}
            filterValueMultiUpdate={filterValueMultiUpdate}
          />
        </FormRow>
        <FormRow cssClasses="rf-grey-box">
          <ExportRoute
            formData={formData}
          />
        </FormRow>
      </div>
    );
  }

  render() {
    let content = super.render();
    return (
      <div className="rf-form-content">
        {content}
      </div>
    );
  }
}

RoutingForm.propTypes = {
  formData: PropTypes.object.isRequired,
  settingsData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  removeWaypoint: PropTypes.func.isRequired,
  changeWaypointsOrder: PropTypes.func.isRequired,
  addWaypoint: PropTypes.func.isRequired,
  updateWaypoint: PropTypes.func.isRequired,
  avoidAreaUpdate: PropTypes.func.isRequired,
  avoidAreaUpdateAll: PropTypes.func.isRequired,
  avoidAreaAdd: PropTypes.func.isRequired,
  avoidAreaRemove: PropTypes.func.isRequired,
  getStreetAddress: PropTypes.func.isRequired,
  getLinkId: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  scooter: PropTypes.bool,
  importFields: PropTypes.func.isRequired,
  setWaypoints: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabTitle: PropTypes.string.isRequired,
  duplicateTab: PropTypes.func.isRequired,
  setTabTitle: PropTypes.func.isRequired,
  waypointUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    responseData: getResponseData(state),
    formData: getCurrentFormData(state),
    settingsData: getSettingsData(state),
    module: getModule(state),
    scooter: getUrlSettings(state).scooter,
    selectedTab: getSelectedTab(state),
    tabTitle: getSelectedTabs(state).title,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
    removeWaypoint: bindActionCreators(WaypointsActions.remove, dispatch),
    changeWaypointsOrder: bindActionCreators(WaypointsActions.changeOrder, dispatch),
    reverseWaypoints: bindActionCreators(WaypointsActions.reverse, dispatch),
    addWaypoint: bindActionCreators(WaypointsActions.add, dispatch),
    updateWaypoint: bindActionCreators(WaypointsActions.update, dispatch),
    setWaypoints: bindActionCreators(WaypointsActions.set, dispatch),
    importFields: bindActionCreators(FieldsActions.importFields, dispatch),
    avoidAreaUpdate: bindActionCreators(AvoidAreasActions.avoidAreaUpdate, dispatch),
    avoidAreaUpdateAll: bindActionCreators(AvoidAreasActions.avoidAreaUpdateAll, dispatch),
    avoidAreaAdd: bindActionCreators(AvoidAreasActions.avoidAreaAdd, dispatch),
    avoidAreaRemove: bindActionCreators(AvoidAreasActions.avoidAreaRemove, dispatch),
    getStreetAddress: bindActionCreators(WaypointsActions.getStreetAddress, dispatch),
    getLinkId: bindActionCreators(WaypointsActions.getLinkId, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
    duplicateTab: bindActionCreators(TabsAction.duplicateTab, dispatch),
    setTabTitle: bindActionCreators(setTabTitle, dispatch),
    waypointUpdate: bindActionCreators(WaypointsActions.update, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutingForm);
