export const WAYPOINT_TYPES = {
  STOP: 'stopOver',
  VIA: 'passThrough',
};

export const WAYPOINT_TYPES_BACKWARD = _.invert(WAYPOINT_TYPES);

export const FEATURE_SET = {
  SOFT_EXCLUDE: '-2',
};

export const FEATURE_SET_BACKWARD = _.invert(FEATURE_SET);

export const FEATURE_NAMES = {
  FERRY: 'boatFerry',
  RAIL_FERRY: 'railFerry',
  DIRT_ROAD: 'dirtRoad',
  TOLL_ROAD: 'tollroad',
};

export const FEATURE_NAMES_BACKWARD = _.invert(FEATURE_NAMES);
