import { calculateResolution } from '../utils/resolutionHelper';

let DRAG_STATES = {
  DRAG: 0,
  RESIZE_TOP: 1,
  RESIZE_LEFT: 2,
  RESIZE_BOTTOM: 3,
  RESIZE_RIGHT: 4,
  RESIZE_TOP_LEFT: 5,
  RESIZE_TOP_RIGHT: 6,
  RESIZE_BOTTOM_LEFT: 7,
  RESIZE_BOTTOM_RIGHT: 8,
};

export class Rect {
  constructor(map, rectOptions, onChange, onDrag) {
    this.rect = new H.map.Rect(rectOptions.bounds, rectOptions.options);
    this.map = map;
    this.onChange = onChange;
    let noOp = () => {};
    this.onDragCallback = onDrag || noOp;

    this.initRectEvents();
  }

  initRectEvents() {
    this.rect.draggable = true;

    this.rect.addEventListener('dragstart', ::this.onDragStart);
    this.rect.addEventListener('drag', ::this.onDrag);
    this.rect.addEventListener('dragend', ::this.onDragEnd);

    this.rect.addEventListener('pointermove', ::this.onPointerMove);
    this.rect.addEventListener('pointerleave', ::this.onPointerLeave);
  }

  onDragStart(targetData) {
    this.map.behavior.disable();
    let { currentPointer } = targetData;
    this.prevCoords = this.map.screenToGeo(currentPointer.viewportX, currentPointer.viewportY);
  }

  onDrag(targetData) {
    let swap = (val1, val2) => {
      let temp = val1;
      val1 = val2;
      val2 = temp;
      return [val1, val2];
    };
    let { currentPointer } = targetData;
    let currentCoords = this.map.screenToGeo(currentPointer.viewportX, currentPointer.viewportY);
    let latDelta = this.prevCoords.lat - currentCoords.lat;
    let lngDelta = this.prevCoords.lng - currentCoords.lng;
    let prevBounds = this.rect.getBoundingBox();
    let bounds;

    switch (this.dragState) {
      case DRAG_STATES.RESIZE_TOP: {
        let top = prevBounds.getTop() - latDelta;
        let bottom = prevBounds.getBottom();
        if (top < bottom) {
          [top, bottom] = swap(top, bottom);
          this.dragState = DRAG_STATES.RESIZE_BOTTOM;
        }
        bounds = new H.geo.Rect(top, prevBounds.getLeft(), bottom, prevBounds.getRight());
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM: {
        let bottom = prevBounds.getBottom() - latDelta;
        let top = prevBounds.getTop();
        if (bottom > top) {
          [top, bottom] = swap(top, bottom);
          this.dragState = DRAG_STATES.RESIZE_TOP;
        }
        bounds = new H.geo.Rect(top, prevBounds.getLeft(), bottom, prevBounds.getRight());
        break;
      }
      case DRAG_STATES.RESIZE_LEFT: {
        let left = prevBounds.getLeft() - lngDelta;
        let right = prevBounds.getRight();
        if (left > right) {
          [left, right] = swap(left, right);
          this.dragState = DRAG_STATES.RESIZE_RIGHT;
        }
        bounds = new H.geo.Rect(prevBounds.getTop(), left, prevBounds.getBottom(), right);
        break;
      }
      case DRAG_STATES.RESIZE_RIGHT: {
        let right = prevBounds.getRight() - lngDelta;
        let left = prevBounds.getLeft();
        if (right < left) {
          [left, right] = swap(left, right);
          this.dragState = DRAG_STATES.RESIZE_LEFT;
        }
        bounds = new H.geo.Rect(prevBounds.getTop(), left, prevBounds.getBottom(), right);
        break;
      }
      case DRAG_STATES.RESIZE_TOP_LEFT: {
        let top = prevBounds.getTop() - latDelta;
        let left = prevBounds.getLeft() - lngDelta;
        let bottom = prevBounds.getBottom();
        let right = prevBounds.getRight();
        if (top < bottom) {
          [top, bottom] = swap(top, bottom);
          if (left > right) {
            [left, right] = swap(left, right);
            this.dragState = DRAG_STATES.RESIZE_BOTTOM_RIGHT;
          } else {
            this.dragState = DRAG_STATES.RESIZE_BOTTOM_LEFT;
          }
        } else if (left > right) {
          [left, right] = swap(left, right);
          this.dragState = DRAG_STATES.RESIZE_TOP_RIGHT;
        }
        bounds = new H.geo.Rect(top, left, bottom, right);
        break;
      }
      case DRAG_STATES.RESIZE_TOP_RIGHT: {
        let top = prevBounds.getTop() - latDelta;
        let left = prevBounds.getLeft();
        let bottom = prevBounds.getBottom();
        let right = prevBounds.getRight() - lngDelta;
        if (top < bottom) {
          [top, bottom] = swap(top, bottom);
          if (right < left) {
            [right, left] = swap(right, left);
            this.dragState = DRAG_STATES.RESIZE_BOTTOM_LEFT;
          } else {
            this.dragState = DRAG_STATES.RESIZE_BOTTOM_RIGHT;
          }
        } else if (right < left) {
          [right, left] = swap(right, left);
          this.dragState = DRAG_STATES.RESIZE_TOP_LEFT;
        }
        bounds = new H.geo.Rect(top, left, bottom, right);
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM_RIGHT: {
        let top = prevBounds.getTop();
        let left = prevBounds.getLeft();
        let bottom = prevBounds.getBottom() - latDelta;
        let right = prevBounds.getRight() - lngDelta;
        if (bottom > top) {
          [bottom, top] = swap(bottom, top);
          if (right < left) {
            [right, left] = swap(right, left);
            this.dragState = DRAG_STATES.RESIZE_TOP_LEFT;
          } else {
            this.dragState = DRAG_STATES.RESIZE_TOP_RIGHT;
          }
        } else if (right < left) {
          [right, left] = swap(right, left);
          this.dragState = DRAG_STATES.RESIZE_BOTTOM_LEFT;
        }
        bounds = new H.geo.Rect(top, left, bottom, right);
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM_LEFT: {
        let top = prevBounds.getTop();
        let left = prevBounds.getLeft() - lngDelta;
        let bottom = prevBounds.getBottom() - latDelta;
        let right = prevBounds.getRight();
        if (bottom > top) {
          [bottom, top] = swap(bottom, top);
          if (left > right) {
            [left, right] = swap(left, right);
            this.dragState = DRAG_STATES.RESIZE_TOP_RIGHT;
          } else {
            this.dragState = DRAG_STATES.RESIZE_TOP_LEFT;
          }
        } else if (left > right) {
          [left, right] = swap(left, right);
          this.dragState = DRAG_STATES.RESIZE_BOTTOM_RIGHT;
        }
        bounds = new H.geo.Rect(top, left, bottom, right);
        break;
      }
      default: {
        bounds = new H.geo.Rect(
          prevBounds.getTop() - latDelta,
          prevBounds.getLeft() - lngDelta,
          prevBounds.getBottom() - latDelta,
          prevBounds.getRight() - lngDelta,
        );
      }
    }

    this.rect.setBoundingBox(bounds);
    this.onDragCallback(bounds);
    this.prevCoords = currentCoords;
  }

  onDragEnd() {
    this.map.behavior.enable();
    this.onChange(this.rect.getBoundingBox());
  }

  onPointerMove(targetData) {
    let { currentPointer } = targetData;
    let currentCoords = this.map.screenToGeo(currentPointer.viewportX, currentPointer.viewportY);
    let bounds = this.rect.getBoundingBox();
    let cursorStyle = 'move';
    let topPoint = new H.geo.Point(bounds.getTop(), currentCoords.lng);
    let bottomPoint = new H.geo.Point(bounds.getBottom(), currentCoords.lng);
    let leftPoint = new H.geo.Point(currentCoords.lat, bounds.getLeft());
    let rightPoint = new H.geo.Point(currentCoords.lat, bounds.getRight());
    let topRight = new H.geo.Point(bounds.getTop(), bounds.getRight());
    let bottomLeft = new H.geo.Point(bounds.getBottom(), bounds.getLeft());
    let borderDistanceInPixels = 10;
    let resolution = calculateResolution(this.map);
    let borderDistance = borderDistanceInPixels * resolution;

    if (bounds.getTopLeft().distance(currentCoords) < borderDistance) {
      this.dragState = DRAG_STATES.RESIZE_TOP_LEFT;
      cursorStyle = 'nwse-resize';
    } else if (topRight.distance(currentCoords) < borderDistance) {
      this.dragState = DRAG_STATES.RESIZE_TOP_RIGHT;
      cursorStyle = 'nesw-resize';
    } else if (bounds.getBottomRight().distance(currentCoords) < borderDistance) {
      this.dragState = DRAG_STATES.RESIZE_BOTTOM_RIGHT;
      cursorStyle = 'nwse-resize';
    } else if (bottomLeft.distance(currentCoords) < borderDistance) {
      this.dragState = DRAG_STATES.RESIZE_BOTTOM_LEFT;
      cursorStyle = 'nesw-resize';
    } else if (topPoint.distance(currentCoords) < borderDistance) {
      this.dragState = DRAG_STATES.RESIZE_TOP;
      cursorStyle = 'ns-resize';
    } else if (bottomPoint.distance(currentCoords) < borderDistance) {
      this.dragState = DRAG_STATES.RESIZE_BOTTOM;
      cursorStyle = 'ns-resize';
    } else if (leftPoint.distance(currentCoords) < borderDistance) {
      this.dragState = DRAG_STATES.RESIZE_LEFT;
      cursorStyle = 'ew-resize';
    } else if (rightPoint.distance(currentCoords) < borderDistance) {
      this.dragState = DRAG_STATES.RESIZE_RIGHT;
      cursorStyle = 'ew-resize';
    } else {
      this.dragState = DRAG_STATES.DRAG;
    }
    this.map.getElement().style.cursor = cursorStyle;
  }

  onPointerLeave() {
    this.map.getElement().style.cursor = 'auto';
  }
}
