import DirectionBase from './index';

export default class Maneuver extends DirectionBase {
  constructor(...args) {
    super(...args);
    this.getGroup().setZIndex(105);
  }

  getCircleOptions(props) {
    let options = super.getCircleOptions();
    options.style.strokeColor = props.color;
    return options;
  }

  getPolylineOptions() {
    return {
      strokeColor: '#48dad0',
      lineWidth: 12,
    };
  }

  getCircleRadius() {
    return 20;
  }

  getLinks(props) {
    return [props];
  }
}
