import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../sidebar';
import { FORMS_LIST } from './formsListConstant';
import NewTab from '../forms/newTab';

import utils from 'utils';
import getConfig from 'config/form';

import './styles/filterPanelContainer.scss';
import { getModule } from 'state/tabs/tab/module';
import { getForm } from 'state/tabs/tab/form';
import { getIsLeftPanelShown } from '../../state/ui';
import { setIsLeftPanelShown } from '../../state/ui/reducer';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import Toggler from '../shared/toggler';

export class FilterPanelContainer extends Component {
  stopPropagation(e) {
    if (this.props.isNewTabShown) {
      e.stopPropagation();
      e.nativeEvent.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  toggleVisibility() {
    this.props.setIsLeftPanelShown(!this.props.isLeftPanelShown);
  }

  render() {
    let { currentForm, module, isNewTabShown, isLeftPanelShown } = this.props;
    let formTemplates = (utils.getObject(getConfig(), `${module}.formTemplates`) || []);
    let configForm = formTemplates[currentForm] || {};
    let formLabel;
    let FormEl;
    if (isNewTabShown) {
      FormEl = NewTab;
    } else {
      FormEl = FORMS_LIST[module][currentForm];
      formLabel = configForm.label;
    }

    if (!FormEl) {
      throw new Error(`FormEl is not provided. module = ${module}, currentForm = ${currentForm}`);
    }

    const sidebarClasses = classnames('rf-filter-panel', {
      'rf-filter-panel_shown': isLeftPanelShown,
    });
    return (
      <Sidebar cssClasses={sidebarClasses} onClick={::this.stopPropagation}>
        <FormEl currentForm={currentForm} label={formLabel} />
        <Toggler rightArrow={!isLeftPanelShown} toggle={::this.toggleVisibility} />
      </Sidebar>
    );
  }
}

FilterPanelContainer.propTypes = {
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  isNewTabShown: PropTypes.bool.isRequired,
  isLeftPanelShown: PropTypes.bool.isRequired,
  setIsLeftPanelShown: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentForm: getForm(state),
    module: getModule(state),
    isNewTabShown: state.isNewTabShown,
    isLeftPanelShown: getIsLeftPanelShown(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setIsLeftPanelShown: bindActionCreators(setIsLeftPanelShown, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterPanelContainer);
