import * as types from './settingsPresetConstants';
import { getModule } from 'state/tabs/tab/module';
import { assignTabDataAndDispatch } from 'state/tabs/tab/formData/actions';
import { getForm } from 'state/tabs/tab/form';
import settingsUtils from 'utils/settings';
import { getSettingsData } from './index';

import { getCurrentPreset } from 'state/settingsPreset';
import { getSelectedTab } from '../selectedTab';

export function setSettingsValue(data, tabIndex) {
  return (dispatch, getState) => {
    tabIndex = tabIndex === undefined ? getSelectedTab(getState()) : tabIndex;
    let state = getState();
    data.module = data.currentModule || getModule(state);
    data.currentForm = data.currentForm || getForm(state);
    data.currentPreset = data.currentPreset || getCurrentPreset(state);
    dispatch(assignTabDataAndDispatch({ type: types.VALUE_UPDATE_SETTINGS, data }, tabIndex));
  };
}

export function setCurrentSettingsPreset(preset, tabIndex) {
  return (dispatch, getState) => {
    tabIndex = tabIndex === undefined ? getSelectedTab(getState()) : tabIndex;
    const state = getState();
    const module = getModule(state);
    const form = getForm(state);
    const settingsData = getSettingsData(state);
    const presetSettings = settingsUtils.getPresetSettings(module, form, settingsData, preset);
    dispatch(assignTabDataAndDispatch({
      type: types.SET_CURRENT_SETTINGS,
      data: { preset, module, presetSettings },
    }, tabIndex));
  };
}
