import { isLinkListValid } from 'utils/validation/linkValidation';
import isExtraValid from 'utils/validation/extraParamsValidation';
import isNumericSequence from 'utils/validation/toleranceValidation';
import isNumberValid from 'utils/validation/numericValidation';
import isIntegerSequence from 'utils/validation/integersValidation';
import consumptionValidation from '../../common/consumptionValidation';
import formConfig from 'config/form';
import formsEnum from 'config/formsEnum';

const config = formConfig(formsEnum.CALCULATE_ROUTE);

let isPositiveNumber = isNumberValid({ min: 0 });

function excludeZoneTypesValidation(value) {
  return !!config.excludeZoneTypes.find(zoneType => zoneType.value === value);
}

function licensePlateValidation(value) {
  return value.length <= 1;
}

export default {
  extraparams: isExtraValid,
  generalizationtolerances: isNumericSequence,
  avoidlinks: isLinkListValid,
  maxnumberofchanges: isNumberValid({ min: 0, max: 10 }),
  walkradius: isNumberValid({ min: 0, max: 6000 }),
  walkspeed: isNumberValid({ min: 0.5, max: 2 }),
  energy: isPositiveNumber,
  excludezones: isIntegerSequence,
  allowedzones: isIntegerSequence,
  excludezonetypes: excludeZoneTypesValidation,
  licenseplate: licensePlateValidation,
  'vehicletype.consumption': isNumberValid(),
  'weightsPerAxleGroup.tandem': isNumberValid({ min: 0, max: 1000 }),
  'weightsPerAxleGroup.single': isNumberValid({ min: 0, max: 1000 }),
  ...consumptionValidation,
};
