import isNumberValid from 'utils/validation/numericValidation';
import speedValidation from 'utils/validation/speedValidation';

const isPositiveNumber = isNumberValid({ min: 0 });

export default {
  freeFlowSpeedTable: speedValidation,
  trafficSpeedTable: speedValidation,
  auxiliaryConsumption: isPositiveNumber,
  ascent: isPositiveNumber,
  descent: isPositiveNumber,
  acceleration: isPositiveNumber,
  deceleration: isPositiveNumber,
  highSpeedThreshold: isPositiveNumber,

  initialCharge: isPositiveNumber,
  maxCharge: isPositiveNumber,
  chargingCurve: speedValidation,
  maxChargingVoltage: isPositiveNumber,
  maxChargingCurrent: isPositiveNumber,


  maxChargeAfterChargingStation: isPositiveNumber,
  minChargeAtChargingStation: isPositiveNumber,
  minChargeAtDestination: isPositiveNumber,
  chargingSetupDuration: isPositiveNumber,
};
