import FormBase from '../../_Base';

import Markers from './Markers';
import Polygon from './Polygon';

const STORE = {
  isoline: 'tabData.response.data.response.isoline',
};

export default class Isoline extends FormBase {
  initModules() {
    return {
      markers: new Markers(this.map, this.state),
      polygon: new Polygon(this.map, this.state),
    };
  }

  _handleDragging(method, args) {
    this.applyModule(this.modules.polygon, method, args);
  }

  onDrag(...args) {
    this._handleDragging('onDrag', args);
  }

  onDragEnd(...args) {
    this._handleDragging('onDragEnd', args);
  }

  getPath(key) {
    return super.getPath(key, STORE);
  }

  setViewBounds(force) {
    super.setViewBounds(this.modules.polygon.getGroup(), force);
  }

  process(oldProps, nextProps, forceRender = false) {
    super.process(oldProps, nextProps, forceRender);
    let { isError } = nextProps.tabData.response;
    if (isError) {
      this.clearGroups();
    }

    let isNewRequestReceived = this.isTimestampChanged(nextProps);

    if (forceRender || isError || isNewRequestReceived || this.isCoordsChanged(nextProps) ||
      (nextProps.selectedTab !== oldProps.selectedTab)) {
      this.modules.markers.process(nextProps);
    }

    if (forceRender || isNewRequestReceived || this.isPolygonChanged(nextProps)
      || this.isSelectedIsolineChanged(nextProps)) {
      this.modules.polygon.process(nextProps);
    }

    this.setProps(nextProps);
  }

  isCoordsChanged(nextProps) {
    return this.isPropChanged(nextProps, 'tabData.formData.waypoints');
  }

  isSelectedIsolineChanged(nextProps) {
    return this.isPropChanged(nextProps, 'tabData.formData.currentIsoline');
  }

  isPolygonChanged(nextProps) {
    return this.isPropChanged(nextProps, this.getPath('isoline'));
  }
}
