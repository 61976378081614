import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/directionIcon.scss';

class DirectionIcon extends Component {
  render() {
    let { direction } = this.props;

    let iconClass = `rf-direction-icon_${direction}`;
    let cssClass = classnames('rf-direction-icon', {
      [iconClass]: !!direction,
    });

    return (
      <div className={cssClass}></div>
    );
  }
}

DirectionIcon.propTypes = {
  direction: PropTypes.string,
};

export default DirectionIcon;
