import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/radiobuttonList.scss';

class RadiobuttonList extends Component {
  getItems() {
    let { data, onChange, getColor, itemLabel, currentIndex, onMouseOver, onMouseOut,
          highlightedIndex } = this.props;
    return data.map((item, id) => {
      let isActive = currentIndex === id;
      let isHighlighted = highlightedIndex === id;
      let lbl = item.label ? item.label.join(', ') : `${itemLabel} ${id}`;
      let title = item.label ? lbl : null;
      let cssClasses = classnames('rf-radiobutton-list__item', {
        'rf-radiobutton-list__item_long-labels': item.label !== undefined,
      });

      let background = getColor(isActive, isHighlighted);

      return (
        <label
          onMouseOver={() => onMouseOver(id)}
          onMouseOut={() => onMouseOut(id)}
          className={cssClasses}
          key={`rf-radiobutton-list__item_${id}`}
          style={{ background }}
          title={title}
        >

          <input
            type="radio"
            className="rf-radiobutton-list__input"
            onChange={() => onChange(id)}
            checked={isActive}
          /> {lbl}

        </label>
      );
    });
  }

  render() {
    return (
      <div className="rf-radiobutton-list">
        {this.getItems()}
      </div>
    );
  }
}

RadiobuttonList.defaultProps = {
  currentItemIndex: 0,
  highlightedIndex: -1,
  onMouseOver: () => {},
  onMouseOut: () => {},
};

RadiobuttonList.propTypes = {
  data: PropTypes.array.isRequired,
  currentIndex: PropTypes.number.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  itemLabel: PropTypes.string.isRequired,
  getColor: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
};

export default RadiobuttonList;
