import * as types from './highlightedRouteConstants';

const defaultState = -1;

function setHighlightedRoute(state = defaultState, action) {
  switch (action.type) {
    case types.SET_HIGHLIGHTED_ROUTE:
      return action.highlightedRoute;

    default:
      return state;
  }
}

export default setHighlightedRoute;
