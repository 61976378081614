import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isWaypointEmpty } from 'utils/waypoint';
import BaseContextMenu from '../../_ContextMenu';
import * as WaypointsActions from 'state/tabs/tab/formData/common/waypoints/actions';
import { avoidAreaAdd } from 'state/tabs/tab/formData/common/avoidAreas/actions';
import { inspectLink } from 'state/inspectLink/actions';
import * as NotificationActions from 'state/notification/actions';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as TabsActions from 'state/tabs/actions';
import { toggleNewTabShown } from 'state/isNewTabShown/actions';
import * as OriginalRouteTabActions from 'state/tabs/tab/formData/placesTab/placesForm/originalRouteTab/actions';

import utils from 'utils';
import formsEnum from 'config/formsEnum';
import modulesEnum from 'config/modulesEnum';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';
import { getSelectedTabs } from 'state/tabs';
import * as appSettingsActions from 'state/appSettings/base/actions';
import { set } from '../../../../state/notification/actions';
import {
  getHlsWaypoints,
  google,
  myDrive,
  getHlsOptions,
  mapFan,
} from '../../../resultPanel/routingTab/routing/compareUtils';
import { getIsAutoRefreshOn } from 'state/appSettings/base';
import { getInspectLinkSettings } from 'state/appSettings/inspectLink/reducer';
import openOlsTab from './openOlsTab';

export class ContextMenuContainer extends BaseContextMenu {
  addWaypoint() {
    let {
      currentForm,
      data,
      addWaypoint,
      alternativeIgnoredNotify,
      resetAlternatives,
    } = this.props;
    let alternatives = parseInt(
      utils.getObject(this.props, 'formData.fields.alternatives', '0')
    );
    addWaypoint({
      currentForm,
      value: {
        coords: { value: data.geo },
        type: 'passThrough',
        isWaypoint: true,
      },
    });
    if (alternatives) {
      alternativeIgnoredNotify();
      resetAlternatives(currentForm);
    }
  }

  setWaypoint(index) {
    let { currentForm, data, waypointUpdate } = this.props;
    waypointUpdate({
      currentForm,
      value: { coords: { value: data.geo } },
      index,
    });
  }

  addAvoidArea() {
    let { currentForm, data, avoidAreaAdd } = this.props;
    let value = { value: data.avoidArea, isShown: true };
    avoidAreaAdd({ value, currentForm });
  }

  openEVChargingStationsTab() {
    this.props.addTab({
      module: modulesEnum.EV_CHARGING_STATION,
      form: formsEnum.STATIONS,
    });

    const latLngAltArray = this.props.data.target
      .getGeometry()
      .getLatLngAltArray();
    const chunked = _.chunk(latLngAltArray, 3);
    const route = `${chunked
      .map((latLngAlt) => `${latLngAlt[0]},${latLngAlt[1]}`)
      .join(';')}`;

    this.props.filterValueMultiUpdate(formsEnum.STATIONS, {
      selectionMode: 'corridor',
      usePostReq: true,
    });
    this.props.filterValueUpdate({
      key: 'corridor.points',
      value: route,
      currentForm: formsEnum.STATIONS,
      needsRerender: true,
    });
    this.props.setOriginalRouteTab(this.props.selectedTabId);
  }

  openPlacesAPITab() {
    this.props.addTab({ module: modulesEnum.PLACES, form: formsEnum.PLACES });

    const latLngAltArray = this.props.data.target
      .getGeometry()
      .getLatLngAltArray();
    const chunked = _.chunk(latLngAltArray, 3);
    const route = `${chunked
      .map((latLngAlt) => `${latLngAlt[0]},${latLngAlt[1]}`)
      .join(';')}`;

    this.props.setSettings({ isAutoRefreshOn: false });
    this.props.filterValueUpdate({
      key: 'corridor.points',
      value: route,
      currentForm: formsEnum.PLACES,
    });
    this.props.filterValueMultiUpdate(formsEnum.PLACES, {
      selectionMode: 'corridor',
    });
    setTimeout(
      () =>
        this.props.setSettings({ isAutoRefreshOn: this.props.isAutoRefreshOn }),
      1
    );
  }

  openIncidentsDataTab() {
    this.props.addTab({
      module: modulesEnum.TRAFFIC,
      form: formsEnum.INCIDENT_DATA,
    });

    const latLngAltArray = this.props.data.target
      .getGeometry()
      .getLatLngAltArray();
    const chunked = _.chunk(latLngAltArray, 3);
    const route = `${chunked
      .map((latLngAlt) => `${latLngAlt[0]},${latLngAlt[1]}`)
      .join(';')}`;

    this.props.filterValueUpdate({
      key: 'selectionMode',
      currentForm: formsEnum.INCIDENT_DATA,
      value: 'corridor',
    });
    this.props.filterValueUpdate({
      key: 'corridor.points',
      currentForm: formsEnum.INCIDENT_DATA,
      value: route,
      needsRerender: true,
    });
  }

  openFlowDataTab() {
    this.props.addTab({
      module: modulesEnum.TRAFFIC,
      form: formsEnum.FLOW_DATA,
    });

    const latLngAltArray = this.props.data.target
      .getGeometry()
      .getLatLngAltArray();
    const chunked = _.chunk(latLngAltArray, 3);
    const route = `${chunked
      .map((latLngAlt) => `${latLngAlt[0]},${latLngAlt[1]}`)
      .join(';')}`;

    this.props.filterValueUpdate({
      key: 'selectionMode',
      currentForm: formsEnum.FLOW_DATA,
      value: 'corridor',
    });
    this.props.filterValueUpdate({
      key: 'corridor.points',
      currentForm: formsEnum.FLOW_DATA,
      value: route,
      needsRerender: true,
    });
  }

  openOlsTab = () => {
    openOlsTab(this.props);
  };

  compareToGoogle() {
    google.open(getHlsWaypoints(this.props));
  }

  compareToMapFan = () => {
    mapFan.open(getHlsWaypoints(this.props), getHlsOptions(this.props));
  };

  compareToTomTom() {
    this.props.setNotification({
      message: `IMPORTANT! Please check and/or adjust the start and destination of the route
      in TomTom MyDrive to properly compare the routes!`,
    });
    myDrive.open(getHlsWaypoints(this.props), getHlsOptions(this.props));
  }

  removeMarker(index) {
    this.props.removeWaypoint({
      index,
      currentForm: this.props.currentForm,
      needsRerender: true,
    });
  }

  addChargingStation(data) {
    let {
      currentForm,
      addWaypoint,
      alternativeIgnoredNotify,
      resetAlternatives,
    } = this.props;
    let alternatives = parseInt(
      utils.getObject(this.props, 'formData.fields.alternatives', '0')
    );
    const stationData = data.targetData.getData();
    const port = this.getProperPort(stationData.ports);
    const position =
      stationData.noiseMarkers[data.targetData.getData().id].getGeometry();

    addWaypoint({
      currentForm,
      value: {
        coords: { value: `${position.lat},${position.lng}` },
        type: 'stopOver',
        isWaypoint: true,
        power: port.powerInKW,
        voltage: port.voltageInV,
        current: port.currentInA,
        phases: port.phases || '',
        supplyType: port.currentType,
      },
    });

    if (alternatives) {
      alternativeIgnoredNotify();
      resetAlternatives(currentForm);
    }
  }

  getProperPort(ports) {
    let filteredPorts = [];
    const plugTypes =
      this.props.formData.fields.chargingstationfilters.plugtype.value;

    if (
      this.props.formData.fields.consumptionmodel === 'electric' &&
      plugTypes.length
    ) {
      filteredPorts = ports.filter(
        (port) => plugTypes.indexOf(port.connectorType) > -1
      );
    }

    if (!filteredPorts.length) {
      filteredPorts = [...ports];
    }

    const sortedPorts = filteredPorts.sort((connector1, connector2) => {
      if (+connector1.powerInKW > +connector2.powerInKW) {
        return -1;
      } else {
        return 1;
      }
    });

    return sortedPorts[0];
  }

  getMenuItems(data) {
    let { waypoints } = this.props.formData;
    let items = [];

    const type = _.get(this.props, 'data.targetData.type');
    const index = _.get(this.props, 'data.targetData.index');

    if (
      type === 'MAIN' &&
      index !== this.props.formData.waypoints.length - 1 &&
      index !== 0
    ) {
      items.push({
        label: 'Remove Marker',
        iconLabel: 'RM rf-webfont-icon rf-webfont-icon_close',
        fn: this.removeMarker.bind(this, index),
      });
    }

    items.push({
      label: 'Route from',
      iconLabel: 'A',
      fn: this.setWaypoint.bind(this, 0),
    });

    items.push({
      label: 'Route to',
      iconLabel: 'B',
      fn: this.setWaypoint.bind(this, -1),
    });

    let addWaypointAttrs = {
      label: 'Add waypoint',
      iconLabel: 'N',
      fn: ::this.addWaypoint,
    };

    if (
      isWaypointEmpty(waypoints[0]) ||
      isWaypointEmpty(waypoints[waypoints.length - 1])
    ) {
      addWaypointAttrs.isDisabled = true;
    }
    items.push(addWaypointAttrs);

    items.push({
      label: 'Add Avoid Area',
      iconLabel: 'R',
      fn: ::this.addAvoidArea,
    });

////////////////////
///////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
//////////////////////////////////
///////////////////////////////////

//////////////////////////
//////////////////
//////////////////////////////////////
////////////////////////
/////////////////////////////////////////////////////
/////////
/////
//////////////

    if (type === 'ROUTE') {
//////////////////////
//////////////////
//////////////////////////////////////
////////////////////////
/////////////////////////////////////////////
/////////
//////////////////
////////////////////////////
///////////////////////
////////////////////////////////////
/////////
//////////////////
///////////////////////////////////
////////////////////////
////////////////////////////////////////
/////////
//////////////////
//////////////////////////////
////////////////////////
///////////////////////////////////
/////////
////////////////

      items.push({
        label: 'Compare to...',
        iconLabel: 'CT',
        fn: ::this.stay,
        children: [
          {
            label: 'Routing V8',
            iconLabel: 'OLS',
            fn: this.openOlsTab,
          },
//////////////////////////
///////////
////////////////////////////
/////////////////////////////
///////////////////////////////////////
////////////
///////////
////////////////////////////
/////////////////////////////
///////////////////////////////////////
////////////
///////////
////////////////////////////
/////////////////////////////
///////////////////////////////////////
////////////
////////////////////
        ],
      });
    }

    return items.concat(super.getMenuItems(data));
  }
}

ContextMenuContainer.defaultProps = {
  data: {},
};

ContextMenuContainer.propTypes = {
  data: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  waypointUpdate: PropTypes.func.isRequired,
  addWaypoint: PropTypes.func.isRequired,
  removeWaypoint: PropTypes.func.isRequired,
  avoidAreaAdd: PropTypes.func.isRequired,
  alternativeIgnoredNotify: PropTypes.func.isRequired,
  resetAlternatives: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  toggleNewTabShown: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  setOriginalRouteTab: PropTypes.func.isRequired,
  isAutoRefreshOn: PropTypes.bool.isRequired,
  setSettings: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentForm: getForm(state),
    module: getModule(state),
    settingsData: getSettingsData(state),
    formData: getCurrentFormData(state),
    selectedTabId: getSelectedTabs(state).id,
    isAutoRefreshOn: getIsAutoRefreshOn(state),
    inspectLinkSettings: getInspectLinkSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    waypointUpdate: bindActionCreators(WaypointsActions.update, dispatch),
    addWaypoint: bindActionCreators(WaypointsActions.add, dispatch),
    removeWaypoint: bindActionCreators(WaypointsActions.remove, dispatch),
    avoidAreaAdd: bindActionCreators(avoidAreaAdd, dispatch),
    inspectLink: bindActionCreators(inspectLink, dispatch),
    alternativeIgnoredNotify: bindActionCreators(
      NotificationActions.alternativesIgnoredNotify,
      dispatch
    ),
    textCopiedNotify: bindActionCreators(
      NotificationActions.textCopiedNotify,
      dispatch
    ),
    resetAlternatives: bindActionCreators(
      FieldsActions.resetAlternatives,
      dispatch
    ),
    requestErrorNotify: bindActionCreators(
      NotificationActions.requestErrorNotify,
      dispatch
    ),
    addTab: bindActionCreators(TabsActions.addTab, dispatch),
    toggleNewTabShown: bindActionCreators(toggleNewTabShown, dispatch),
    filterValueMultiUpdate: bindActionCreators(
      FieldsActions.filterValueMultiUpdate,
      dispatch
    ),
    filterValueUpdate: bindActionCreators(
      FieldsActions.filterValueUpdate,
      dispatch
    ),
    setOriginalRouteTab: bindActionCreators(
      OriginalRouteTabActions.setOriginalRouteTab,
      dispatch
    ),
    setSettings: bindActionCreators(appSettingsActions.setSettings, dispatch),
    setNotification: bindActionCreators(set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextMenuContainer);
