import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

import CloseButton from 'shared/closeButton';
import * as SearchActions from 'state/search/actions';

import './styles/searchResults.scss';

class SearchResultsContainer extends Component {
  getResultItem(result, index) {
    let classNames = classnames('rf-search-results__list__item', {
      'rf-search-results__list__item-selected': result.selected,
    });
    return (
      <li className={classNames} onClick={this.props.selectSearchResult.bind(this, index)} key={index}>
        <span>{index + 1}.</span> {result.label}
      </li>
    );
  }

  render() {
    let { searchData, removeSearchResults } = this.props;
    let streets = searchData.results.map(
      ::this.getResultItem
    );

    if (searchData.results.length === 0) {
      return null;
    }
    return (
      <div className="rf-search-results">
        <h3 className="rf-sidebar__header">Search Results</h3>
        <ul className="rf-search-results__list">
          {streets}
        </ul>
        <CloseButton onClick={removeSearchResults} />
      </div>
    );
  }
}

SearchResultsContainer.propTypes = {
  searchData: PropTypes.object.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  selectSearchResult: PropTypes.func.isRequired,
  removeSearchResults: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    searchData: state.search,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearchValue: bindActionCreators(SearchActions.setValue, dispatch),
    selectSearchResult: bindActionCreators(SearchActions.selectResult, dispatch),
    removeSearchResults: bindActionCreators(SearchActions.reset, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsContainer);
