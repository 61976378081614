import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormRow from 'shared/formRow';
import CustomSelect from 'shared/select';

class SpeedProfile extends Component {
  render() {
    let { value, formConfig, onChange, isHidden } = this.props;

    if (isHidden) {
      return null;
    }

    return <div className="rf-speedprofile rf-grey-box" id="speedprofile">
      <h3>Speed Profile</h3>
      <FormRow>
        <CustomSelect
          options={formConfig.speedProfiles}
          value={value}
          onChange={onChange}
        />
      </FormRow>
    </div>;
  }
}

SpeedProfile.propTypes = {
  formConfig: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
};

export default SpeedProfile;
