import PropTypes from 'prop-types';
import React, { Component } from 'react';

import formConfig from 'config/form';
import ExpandFormRow from 'shared/expandFormRow';
import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import CheckboxAttributesSet from './CheckboxAttributesSet';
import AvoidAreasGroup from './AvoidAreasGroup';

import utils from 'utils';
import formsEnum from 'config/formsEnum';

const config = formConfig(formsEnum.CALCULATE_ROUTE);

export default class AvoidFeatures extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {
      avoidAreas: nextProps.avoidAreas,
      fields: {
        avoidLinks: nextProps.fields.avoidLinks,
        excludezones: nextProps.fields.excludezones,
        allowedzones: nextProps.fields.excludezones,
        excludezonetypes: nextProps.fields.excludezonetypes,
      },
    };

    if (!prevState.isExpanded && AvoidFeatures.isChanged(state, prevState)) {
      state.isExpanded = true;
    }

    return state;
  }

  static isChanged(props, oldProps) {
    return utils.isPropChanged(props, oldProps, 'avoidAreas') ||
      utils.isPropChanged(props, oldProps, 'fields.avoidLinks') ||
      utils.isPropChanged(props, oldProps, 'fields.excludezones') ||
      utils.isPropChanged(props, oldProps, 'fields.allowedzones') ||
      utils.isPropChanged(props, oldProps, 'fields.excludezonetypes');
  }

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    return AvoidFeatures.isChanged(nextProps, this.props);
  }

  onUpdateZoneFields(field, e) {
    const value = utils.extractData(e);
    const { notify, onChange, fields = {} } = this.props;
    const { routeattributes } = fields;
    const anotherZoneFields = ['excludezones', 'allowedzones', 'excludezonetypes'].filter(v => v !== field);

    if (value || anotherZoneFields.some(el => !!fields[el].value)) {
      if (routeattributes.attributes.indexOf('zo') === -1) {
        onChange('routeattributes', {
          attributes: routeattributes.attributes.concat(['zo']),
        });
        _notify('enabled');
      }
    } else {
      const index = routeattributes.attributes.indexOf('zo');
      if (index !== -1) {
        onChange('routeattributes', {
          attributes: [
            ...routeattributes.attributes.slice(0, index),
            ...routeattributes.attributes.slice(index + 1),
          ],
        });
        _notify('disabled');
      }
    }
    onChange(field, e);
    function _notify(action) {
      notify({
        children: <div>
          The following output params were automatically {action} by this action:
          <ul>
            <li>Route Attributes: Zones</li>
          </ul>
        </div>,
        impact: 'significant',
      });
    }
  }

  getZoneFields(excludezones, allowedzones, excludezonetypes) {
    return <div>
      <FormRow>
        <CheckboxAttributesSet
          label="Exclude zone types"
          options={config.excludeZoneTypes}
          data={excludezonetypes.value}
          onChange={this.onUpdateZoneFields.bind(this, 'excludezonetypes')}
          isExpanded
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Exclude zones"
          placeholder="Paste zones"
          value={excludezones.value}
          isValid={excludezones.isValid}
          onBlur={this.onUpdateZoneFields.bind(this, 'excludezones')}
          blurOnEnter
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Allowed zones"
          placeholder="Paste zones"
          value={allowedzones.value}
          isValid={allowedzones.isValid}
          onBlur={this.onUpdateZoneFields.bind(this, 'allowedzones')}
          blurOnEnter
        />
      </FormRow>
    </div>;
  }

  render() {
    const { onChange, currentForm, avoidAreas, fields = {} } = this.props;
    let { avoidlinks = {}, excludezones = {}, allowedzones = {}, excludezonetypes = {}, excludecountries = '' }
      = fields;

    return (
      <ExpandFormRow
        label="Avoid Features"
        isExpanded={this.state.isExpanded}
        toggleProgrammatically
        ref="avoidFeatures"
        cssClasses="rf-grey-box rf-avoid-features"
        id="avoid-features"
      >
        {currentForm === formsEnum.CALCULATE_ROUTE ?
          this.getZoneFields(excludezones, allowedzones, excludezonetypes) : null}
        <FormRow>
          <CustomInput
            label="Avoid links"
            placeholder="Paste link"
            value={avoidlinks.value}
            isValid={avoidlinks.isValid}
            onBlur={onChange.bind(this, 'avoidlinks')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Avoid countries"
            value={excludecountries}
            onBlur={e => onChange('excludecountries', e)}
            blurOnEnter
          />
        </FormRow>
        <AvoidAreasGroup
          value={avoidAreas}
          currentForm={currentForm}
          onUpdate={this.props.avoidAreaUpdate}
          onUpdateAll={this.props.avoidAreaUpdateAll}
          onAdd={this.props.avoidAreaAdd}
          onRemove={this.props.avoidAreaRemove}
        />
      </ExpandFormRow>
    );
  }
}

AvoidFeatures.propTypes = {
  currentForm: PropTypes.string.isRequired,
  avoidAreas: PropTypes.array.isRequired,
  avoidAreaAdd: PropTypes.func.isRequired,
  avoidAreaUpdate: PropTypes.func.isRequired,
  avoidAreaUpdateAll: PropTypes.func.isRequired,
  avoidAreaRemove: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
};
