import { listValidation, enumValidation } from '../common';
import numericValidation from './numericValidation';

export default function (config) {
  return {
    trucktype: enumValidation('trucktype', config.truckTypes.map(mapValue)),
    trailerscount: numericValidation({ min: 0, max: 4, key: 'trailerscount' }),
    shippedhazardousgoods: listValidation('shippedhazardousgoods', config.shippedHazardousGoods.map(mapValue)),
    limitedweight: numericValidation({ min: 0, max: 1000, key: 'limitedweight' }),
    weightperaxle: numericValidation({ min: 0, max: 1000, key: 'weightperaxle' }),
    axlecount: numericValidation({ min: 0, max: 254, key: 'axlecount' }),
    height: numericValidation({ min: 0, max: 50, key: 'height' }),
    width: numericValidation({ min: 0, max: 50, key: 'width' }),
    length: numericValidation({ min: 0, max: 300, key: 'length' }),
    tunnelcategory: enumValidation('tunnelcategory', config.tunnelCategories.map(mapValue)),
    truckrestrictionpenalty: enumValidation('truckrestrictionpenalty', config.truckRestrictionPenalty.map(mapValue)),
  };
}

function mapValue(val) {
  return val.value;
}
