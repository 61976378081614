import { ADD_TAB, DUPLICATE_TAB } from '../../constants';

export default (state, action) => {
  switch (action.type) {
    case ADD_TAB:
    case DUPLICATE_TAB:
      return new Date().getTime();
    default:
      return state || new Date().getTime();
  }
};
