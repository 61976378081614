import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { parseUrlParams, parseUrlSettings } from 'utils/url';
import { fetchData } from 'state/tabs/tab/response/actions';
import { fetchOperableStatus } from 'state/tabs/tab/formData/evChargingStations/evProxyForm/operableStatus/actions';
import { restoreFromUrl } from 'state/tabs/tab/formData/actions';
import { setCurrentSettingsPreset, setSettingsValue } from 'state/settingsPreset/settingsPresetActions';
import queryParamsSelector from 'state/selectors/queryParamsSelector';
import { getCurrentPreset, getSettingsData } from 'state/settingsPreset';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getIsLoading } from 'state/tabs/tab/isLoading';

import ApiUrlInputContainer from './ApiUrlInputContainer';
import requestPostProcessors from './requestPostProcessors';

import utils from 'utils';
import isFormPerformedForRequest from 'utils/smartRequestTriggering';
import Modal from 'shared/modal';
import { ErrorBlock } from './ErrorBlock';
import SelectModulePopup from '../selectModulePopup';
import validate from 'utils/url/validation';

import './styles/apiUrl.scss';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getHistory } from 'state/tabs/tab/history';
import parseHref from 'utils/url/parser/parseHref';
import { setIsHistoryShown } from 'state/isHistoryShown/actions';
import { getIsHistoryShown } from 'state/isHistoryShown';
import { getSelectedHistory } from 'state/tabs/tab/selectedHistory';
import { setSelectedHistory } from 'state/tabs/tab/selectedHistory/actions';
import { transformToPost } from '../../utils/url';
import { set as setNotification } from 'state/notification/actions';
import { getIsAutoRefreshOn } from 'state/appSettings/base';
import { routerActions } from 'react-router-redux';
import { setTabTitle } from '../../state/tabs/tab/title/actions';
import { getTabTitle } from '../../utils/tabs';
import { getTabs } from '../../state/tabs';
import { setSettings } from 'state/appSettings/base/actions';
import { getAppSettings } from '../../state/appSettings/base';
import adjustLUIStyle from 'utils/adjustLUIStyle';

export class ApiUrlContainer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.isShown && nextProps.isAutoRefreshOn && nextProps.apiUrl !== prevState.apiUrl &&
      nextProps.selectedTab === prevState.selectedTab &&
      ApiUrlContainer.historyCheck(prevState.selectedHistory, nextProps.selectedHistory)) {
      if (nextProps.selectedHistory !== -1) {
        nextProps.setSelectedHistory(-1);
      }
      ApiUrlContainer.smartRequest(nextProps);
    }

    return {
      apiUrl: nextProps.apiUrl,
      selectedTab: nextProps.selectedTab,
      selectedHistory: nextProps.selectedHistory,
    };
  }

  static historyCheck(oldSelectedHistory, newSelectedHistory) {
    const isSelectedHistoryChanged = oldSelectedHistory !== newSelectedHistory;
    const isHistorySelected = oldSelectedHistory === -1 && newSelectedHistory > -1;
    const isHistoryReselected = oldSelectedHistory > -1 && newSelectedHistory > -1 &&
      isSelectedHistoryChanged;
    return !isHistorySelected && !isHistoryReselected;
  }

  constructor(props) {
    super(props);
    this.selectModulePopupRef = React.createRef();
    this.sendRequestDebounced = utils.debounce(this.validateOrSendRequest, 500);
    ApiUrlContainer.smartRequest = ::this.smartRequest;
  }

  state = {
    isShown: false,
    errors: [],
    apiUrl: null,
    selectedTab: null,
  };

  componentDidMount() {
    const { urlRouter: { hash } } = this.props;
    if (hash.startsWith('#url')) {
      const url = decodeURIComponent(hash).replace('#url=', '');

      if (url) {
        this.restoreFromUrl(url)
          .then(() => {
            this.sendRequest();
          });
      }
    }
    adjustLUIStyle(document.querySelectorAll('.rf-select-module-popup lui-overlay'), 'lui-overlay');
  }

  componentDidUpdate(prevProps) {
    const { apiUrl, urlRouter: { hash: encodedUrlHash, action }, push } = this.props;
    const urlHash = decodeURIComponent(encodedUrlHash);
    const isQuickUrl = urlHash.indexOf('#url=') === 0;
    const hashApiUrl = urlHash.replace('#url=', '');
    if (isQuickUrl && action === 'POP' && prevProps.urlRouter.hash !== encodedUrlHash) {
      this.restoreFromUrl(hashApiUrl);
      return;
    }
    if ((isQuickUrl || !urlHash) && hashApiUrl !== apiUrl) {
      push(`#url=${apiUrl}`);
    }
  }

  setSettingsValues(preset, module, form, url) {
    this.props.setSettingsValue({
      currentPreset: preset,
      currentForm: form,
      value: parseUrlSettings(url, module, form),
    });
  }

  validateOrSendRequest = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { settingsData } = this.props;
    let value = this.state.apiUrl;
    const { module = this.props.module, form = this.props.currentForm } =
      parseHref.getModuleAndForm(value, this.props.module, this.props.currentForm, settingsData.settingsPresets);
    let errors = validate(value, module, form);

    if (utils.isEmpty(errors)) {
      this.sendRequest();
    } else {
      this.setState({ errors, isShown: true, apiUrl: value, validationModule: module, validationForm: form });
    }
  }

  restoreFromUrl = async (value) => {
    const {
      setNotification, setCurrentSettingsPreset, restoreFromUrl, settingsData, currentForm, tabs, setTabTitle,
      selectedTab,
    } = this.props;
    let [url, paramsStr] = decodeURIComponent(value).split('?');
    let data = parseHref.getData(url, paramsStr, this.props.module, this.props.currentForm,
      settingsData.settingsPresets);
    if (!data.preset || !data.form || !data.module) {
      data = await this.selectModulePopupRef.current.show(data);
    }
    const { preset, form, module } = data;
    let parsedParams;
    try {
      parsedParams = parseUrlParams(paramsStr, form, module);
    } catch (e) {
      console.error(e);
      setNotification({
        message: 'Failed to parse input url.',
        impact: 'negative',
        autoDismiss: 0,
      });
      return;
    }

    ReactDOM.unstable_batchedUpdates(() => {
      if (preset === 'CUSTOM') {
        this.setSettingsValues(preset, module, form, url);
      }
      if (preset !== this.props.currentPreset) {
        setCurrentSettingsPreset(preset);
      }

      restoreFromUrl({
        module,
        currentForm: form,
        currentPreset: preset,
        value: parsedParams,
      });
      if (form !== currentForm) {
        setTabTitle(selectedTab, getTabTitle(tabs, module, form));
      }
    });
  }

  sendRequest() {
    let { request, module, currentForm, formData = {} } = this.props;
    let { apiUrl } = this.state;
    const { fields = {} } = formData;

    if (!this.props.isLoading) {
      if (fields.usePostReq) {
        const { url, postData } = transformToPost(apiUrl);
        request(module, currentForm, url, undefined, postData);
      } else {
        request(module, currentForm, apiUrl);
      }
      this.applyRequestPostProcessor();
    }
    this.close();
  }

  applyRequestPostProcessor() {
    let { module, currentForm } = this.props;
    const requestPostProcessor = utils.getObject(requestPostProcessors, `${module}.${currentForm}`);
    if (requestPostProcessor) {
      requestPostProcessor(this.props, this.state.apiUrl);
    }
  }

  smartRequest(nextProps) {
    let { module, currentForm, formData, apiUrl } = nextProps;
    if (isFormPerformedForRequest(module, currentForm, formData, apiUrl, this.props.apiUrl)) {
      this.sendRequestDebounced();
    }
  }

  close() {
    this.setState({ isShown: false });
  }

  errorBlock(errorParam) {
    let { errors, value, key } = errorParam;
    let { validationModule, validationForm } = this.state;
    return (
      <ErrorBlock
        errors={errors}
        rawValue={value}
        key={key + value}
        errorKey={key}
        onChange={::this.updateApiUrl}
        module={validationModule}
        form={validationForm}
      />
    );
  }

  isApiUrlValid() {
    let { module, currentForm, formData } = this.props;
    return !isFormPerformedForRequest(module, currentForm, formData) ||
      utils.isEmpty(validate(this.state.apiUrl, module, currentForm));
  }

  updateApiUrl(key, rawValue, newRawValue) {
    let oldValue = `${key}=${rawValue}`;
    let newValue = `${key}=${newRawValue}`;
    let newApiUrl = this.state.apiUrl.replace(oldValue, newValue);
    this.setState({ apiUrl: newApiUrl });
    this.restoreFromUrl(newApiUrl);
  }

  render() {
    let { isLoading, isHistoryShown, setIsHistoryShown, setSettings, isApiUrlMultiline, } = this.props;
    let errors = this.state.errors.map(error => this.errorBlock(error));
    return (
      <lui-default-theme>
        <Modal
          title="Parsing errors:"
          cssClasses="rf-parsing-errors"
          isShown={this.state.isShown}
          closeModal={::this.close}
        >
          {errors}

          <lui-button-group class="rf-api-url__modal-button-group">
            <lui-button onClick={::this.close} secondary>Cancel</lui-button>
            <lui-button onClick={::this.sendRequest}>Send Request</lui-button>
          </lui-button-group>
        </Modal>
        <div className="rf-api-url">
          <ApiUrlInputContainer
            sendRequest={::this.sendRequest}
            apiUrl={this.state.apiUrl}
            apiUrlUpdate={this.restoreFromUrl}
            isLoading={isLoading}
            isValid={this.isApiUrlValid()}
            setAppSettings={setSettings}
            isApiUrlMultiline={isApiUrlMultiline}
          />

          <div
            className="rf-api-url__history-btn"
            onClick={() => setIsHistoryShown(!isHistoryShown)}
          >
            {isHistoryShown ? 'Hide' : 'Show'} history
            <i className="rf-api-url__history-btn__counter">{this.props.historyLength}</i>
          </div>
        </div>
        <SelectModulePopup ref={this.selectModulePopupRef} settingsData={this.props.settingsData} />
      </lui-default-theme>
    );
  }
}

function mapStateToProps(state) {
  let module = getModule(state);
  return {
    module,
    currentForm: getForm(state),
    selectedTab: state.selectedTab,
    isLoading: getIsLoading(state),
    currentPreset: getCurrentPreset(state),
    queryParams: queryParamsSelector(state).queryParams,
    isAutoRefreshOn: getIsAutoRefreshOn(state),
    formData: getCurrentFormData(state),
    isHistoryShown: getIsHistoryShown(state),
    selectedHistory: getSelectedHistory(state),
    historyLength: getHistory(state).length,
    settingsData: getSettingsData(state),
    urlRouter: state.routing.locationBeforeTransitions,
    tabs: getTabs(state),
    isApiUrlMultiline: getAppSettings(state).isApiUrlMultiline,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    request: bindActionCreators(fetchData, dispatch),
    fetchOperableStatus: bindActionCreators(fetchOperableStatus, dispatch),
    restoreFromUrl: bindActionCreators(restoreFromUrl, dispatch),
    setCurrentSettingsPreset: bindActionCreators(setCurrentSettingsPreset, dispatch),
    setSettingsValue: bindActionCreators(setSettingsValue, dispatch),
    setIsHistoryShown: bindActionCreators(setIsHistoryShown, dispatch),
    setSelectedHistory: bindActionCreators(setSelectedHistory, dispatch),
    setNotification: bindActionCreators(setNotification, dispatch),
    push: bindActionCreators(routerActions.push, dispatch),
    setTabTitle: bindActionCreators(setTabTitle, dispatch),
    setSettings: bindActionCreators(setSettings, dispatch),
  };
}

ApiUrlContainer.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  queryParams: PropTypes.string.isRequired,
  request: PropTypes.func.isRequired,
  fetchOperableStatus: PropTypes.func.isRequired,
  module: PropTypes.string.isRequired,
  currentForm: PropTypes.string.isRequired,
  selectedTab: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isAutoRefreshOn: PropTypes.bool.isRequired,
  restoreFromUrl: PropTypes.func.isRequired,
  setCurrentSettingsPreset: PropTypes.func.isRequired,
  setSettingsValue: PropTypes.func.isRequired,
  currentPreset: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  isHistoryShown: PropTypes.bool.isRequired,
  setIsHistoryShown: PropTypes.func.isRequired,
  setSelectedHistory: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  selectedHistory: PropTypes.number.isRequired,
  historyLength: PropTypes.number.isRequired,
  settingsData: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  urlRouter: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  setTabTitle: PropTypes.func.isRequired,
  isApiUrlMultiline: PropTypes.bool.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiUrlContainer);
