import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class TabActions extends Component {
  constructor(props) {
    super(props);

    this.hideDropdown = ::this.hideDropdown;
    this.state = {
      isDropdownShown: false,
      dropdownStyles: { top: 0, left: 0 },
    };
  }

  onAction(action, e) {
    e.stopPropagation();
    this.hideDropdown(e, true);
    action(e);
  }

  getDropdownEl() {
    const { onEdit, onDuplicate } = this.props;

    if (this.state.isDropdownShown) {
      return (
        <div className="rf-nav-tabs__tab__caption__actions__dropdown" style={this.state.dropdownStyles}>
          <div
            className="rf-nav-tabs__tab__caption__actions__dropdown__item"
            onClick={this.onAction.bind(this, onEdit)}
          >Edit Title</div>
          <div
            className="rf-nav-tabs__tab__caption__actions__dropdown__item"
            onClick={this.onAction.bind(this, onDuplicate)}
          >Duplicate</div>
        </div>
      );
    }
    return null;
  }

  toggleDropdown(e) {
    e.stopPropagation();
    if (this.state.isDropdownShown) {
      this.hideDropdown(e, true);
    } else {
      const actionsIconRect = this.refs.actionsIcon.getBoundingClientRect();
      this.setState({
        isDropdownShown: true,
        dropdownStyles: { top: actionsIconRect.top + 20, left: actionsIconRect.left },
      });
      window.addEventListener('mousedown', this.hideDropdown, false);
    }
  }

  hideDropdown(e, force) {
    if (!force && this.refs.dropdown.contains(e.target)) {
      e.stopPropagation();
      return;
    }
    this.setState({ isDropdownShown: false });
    window.removeEventListener('mousedown', this.hideDropdown, false);
  }

  render() {
    const { styles } = this.props;
    const classes = classnames('rf-nav-tabs__tab__caption__actions', {
      'rf-nav-tabs__tab__caption__actions_active': this.state.isDropdownShown,
    });

    return (
      <div className={classes} ref="dropdown">
        <i
          className="fa fa-ellipsis-v"
          onClick={e => e.stopPropagation()}
          onMouseDown={::this.toggleDropdown}
          style={styles}
          ref="actionsIcon"
        />
        {this.getDropdownEl()}
      </div>
    );
  }
}

TabActions.propTypes = {
  styles: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};
