import utils from 'utils';
import ModuleBase from '../../../modules/_Base';
import * as mapMarkers from '../../../utils/MarkersHelper';

export default class LegSplitsModule extends ModuleBase {
  legSplitMarkers = [];

  process(route, formMapConfig) {
    this.getGroup().removeObjects(this.legSplitMarkers);
    this.legSplitMarkers = [];

    if (!formMapConfig.renderLegSplits) {
      return;
    }

    utils.getObject(route, 'leg', []).forEach(leg => {
      const point = leg.start.originalPosition;
      const marker = mapMarkers.createMappedMarker({ originalPosition: point, mappedPosition: point });
      this.legSplitMarkers.push(marker);
    });

    this.getGroup().addObjects(this.legSplitMarkers);
  }
}
