import modulesEnum from 'config/modulesEnum';
import routing from './routing';
////////////////
//////////////////////////////
////////////////////////////////
////////////////////////////////////
////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////
//////////
import transit from './transit';
import offlineTools from './offlineTools';

export default {
  [modulesEnum.ROUTING]: routing,
//////////////////
///////////////////////////////
/////////////////////////////////
//////////////////////////////////////
/////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////
////////////
  [modulesEnum.TRANSIT]: transit,
  [modulesEnum.OFFLINE_TOOLS]: offlineTools,
};
