import '../../node_modules/font-awesome/scss/font-awesome.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Main from './main/Main';
import { connect } from 'react-redux';
import apiUrlSelector from 'state/selectors/apiUrlSelector';
import { hot } from 'react-hot-loader/root';

class App extends Component {
  render() {
    return <Main apiUrl={this.props.apiUrl} />;
  }
}

App.propTypes = {
  apiUrl: PropTypes.string.isRequired,
};

export default hot(connect(apiUrlSelector)(App));
