import AvoidAreas from '../../../modules/AvoidAreas';
import formsEnum from '../../../../../config/formsEnum';

class OlsAvoidAreas extends AvoidAreas {
  process(olsAvoidAreas = []) {
    const avoidAreas = olsAvoidAreas.map((avoidArea) => {
      const [west, south, east, north] = avoidArea.value.replace('bbox:', '').split(',');
      const value = `${south},${west};${north},${east}`;
      return {
        ...avoidArea,
        value
      };
    });
    super.process(avoidAreas);
  }

  onChange(index, bounds) {
    const avoidAreas = _.get(this.props, ['tabData', 'formData', 'fields', 'avoid[areas]'], []);
    const value = `bbox:${bounds.getLeft().toFixed(6)},${bounds.getTop().toFixed(6)},` +
                      `${bounds.getRight().toFixed(6)},${bounds.getBottom().toFixed(6)}`;
    const updatedAvoidArea = { ...avoidAreas[index], value };
    this.props.filterValueMultiUpdate(formsEnum.OLS, {
      'avoid[areas]': [...avoidAreas.slice(0, index), updatedAvoidArea, ...avoidAreas.slice(index + 1)],
    });
  }
}

export default OlsAvoidAreas;
