import { isCoordValid } from 'utils/validation/coordsValidation';
import isNumberValid from 'utils/validation/numericValidation';
import isExtraValid from 'utils/validation/extraParamsValidation';
import formConfig from 'config/form';
import formsEnum from 'config/formsEnum';
import listValidation from 'utils/validation/listValidation';

const config = formConfig(formsEnum.OLS);

export default {
  origin: isCoordValid,
  destination: isCoordValid,
  alternatives: isNumberValid({ min: 0 }),
  extraparams: isExtraValid,
  ...advancedWaypointValidation('originAdvanced'),
  ...advancedWaypointValidation('destinationAdvanced'),
};

export function advancedWaypointValidation(field = '') {
  if (field !== '') {
    field = `${field}.`;
  }
  return {
    [`${field}course`]: isNumberValid(),
    [`${field}sideOfStreetHint`]: isCoordValid,
    [`${field}matchSideOfStreet`]: listValidation(config.matchSideOfStreetList),
    [`${field}radius`]: isNumberValid(),
    [`${field}minCourseDistance`]: isNumberValid(),
    [`${field}stopDuration`]: isNumberValid(),
  };
}
