import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';

import './styles/button.scss';

class CustomButton extends CustomComponent {
  render() {
    let { title, type, onClick, disabled } = this.props;

    return <input
      type={type}
      className={this.getCssClasses(['rf-button'])}
      value={title}
      onClick={onClick}
      disabled={disabled}
    />;
  }
}

CustomButton.defaultProps = {
  type: 'button',
  disabled: false,
  title: '',
  onClick() {},
};

CustomButton.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomButton;
