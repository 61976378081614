export function getBoundsRect(coords) {
  if (coords.length === 0) {
    return null;
  }
  let [lat, lng] = coords[0];
  let maxLat = lat,
    maxLng = lng,
    minLat = lat,
    minLng = lng;

  coords.forEach(([lat, lng]) => {
    if (lat > maxLat) {
      maxLat = lat;
    }
    if (lng > maxLng) {
      maxLng = lng;
    }
    if (lat < minLat) {
      minLat = lat;
    }
    if (lng < minLng) {
      minLng = lng;
    }
  });

  return [maxLat, minLng, minLat, maxLng];
}

export function calculateBearing(a, b, c, d) {
  a = toRad(a);
  c = toRad(c);
  d = toRad(d - b);
  b = Math.sin(d) * Math.cos(c);
  a = ((Math.cos(a) * Math.sin(c)) - (Math.sin(a) * Math.cos(c))) * Math.cos(d);
  return toBrng(Math.atan2(b, a));
}

export function toRad(a) {
  return (a * Math.PI) / 180;
}

export function toDeg(a) {
  return (180 * a) / Math.PI;
}

export function toBrng(a) {
  return (toDeg(a) + 360) % 360;
}

export function createPlatform(tilesData) {
  const { type } = tilesData;
  const { apiKey, url } = tilesData[type];
  return new H.service.Platform({
    // We need API_KEY placeholder cause without this we receive exception in case of empty apikey
    apikey: apiKey || 'API_KEY',
    baseUrl: H.service.Url.parse(`https://${url}`),
  });
}

export function initializeMap(selector, defaultLayer, options) {
  return new H.Map(selector, defaultLayer, options);
}

export function createDefaultMapUI(map, defaultLayers) {
  let ui = H.ui.UI.createDefault(map, defaultLayers);
  ui.removeControl('mapsettings');
  ui.removeControl('zoom');
}

export function combineFlowTiles(lines) {
  let len = lines.length;
  do {
    lines = _combine(lines);
    if (len === lines.length) {
      break;
    }
    len = lines.length;
  } while (lines.length > 1);

  return lines;
}

function _combine(lines) {
  let res = [];
  let iLast,
    jLast;

  for (let i = 0; i < lines.length; i++) {
    let hasMatch = false;
    for (let j = 0; j < res.length; j++) {
      iLast = lines[i].length - 1;
      jLast = res[j].length - 1;
      if (lines[i][0] === res[j][jLast - 1] && lines[i][1] === res[j][jLast]) {
        lines[i].shift();
        lines[i].shift();
        res[j] = res[j].concat(lines[i]);
        hasMatch = true;
        break;
      }
      if (lines[i][iLast - 1] === res[j][0] && lines[i][iLast] === res[j][1]) {
        res[j].shift();
        res[j].shift();
        res[j] = lines[i].concat(res[j]);
        lines[i].splice(i, 1);
        hasMatch = true;
        break;
      }
    }
    if (!hasMatch) {
      res.push(lines[i]);
    }
  }

  return res;
}

export function simulateMapResize() {
  const resizeEvent = window.document.createEvent('UIEvents');
  resizeEvent.initUIEvent('resize', true, false, window, 0);
  window.dispatchEvent(resizeEvent);
}
