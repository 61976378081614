import rorDefault from 'state/tabs/tab/formData/common/rangeOnRoute/defaultState';

export default function exec(data) {
  (data.waypoints || []).forEach((point, index, arr) => {
    point.isWaypoint = index > 0 && index < arr.length - 1;
  });

  if (data.consumptionmodel) {
    data.rangeOnRoute = Object.assign({}, rorDefault, { isActive: true });
  }

  if (data.licenseplate) {
    data.licenseplate = data.licenseplate.replace(/^lastcharacter:/, '');
  }
  return data;
}
