import * as types from './constants';
import * as formDataTypes from 'state/tabs/tab/formData/constants';
import reducer from './reducer';

import utils from 'utils';

export default function proxy(args = {}) {
  let { currentForm } = args;

  return function (state = args.defaultState || {}, action) {
    let _types = utils.values(types);
    if (_types.indexOf(action.type) !== -1 && action.data.currentForm === currentForm) {
      return reducer(state, action);
    }

    if (action.type === formDataTypes.RESTORE_FROM_URL && action.data.currentForm === currentForm) {
      return reducer(args.defaultState, action);
    }

    return state;
  };
}
