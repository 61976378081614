export default {
  isActive: false,
  totalCapacity: 100000,
  batteryLevel: 100,

  maxcharge: 50,
  initialcharge: 35,
  minchargeatstop: 5,
  isMaxChargeActive: false,
  isInitialChargeActive: false,
  isMinChargeAtStopActive: false,
  isChargingAllowed: true,
};
