import Immutable from 'immutable';

import * as types from './settingsPresetConstants';
import * as formDataTypes from 'state/tabs/tab/formData/constants';

import utils from 'utils';
import settingsUtils from 'utils/settings';
import defaultState from './defaultPresetsState';

const getDefaultState = () => {
  const res = {};
  Object.keys(defaultState).forEach((module) => {
    Object.keys(defaultState[module]).forEach((form) => {
      if (form === 'currentPreset') {
        _.set(res, `${module}.${form}`, defaultState[module][form]);
        return;
      }
      Object.keys(defaultState[module][form]).forEach((preset) => {
        const storedSettings = settingsUtils.getSettingsFromLocalStorage({
          module,
          currentForm: form,
          currentPreset: preset,
        });
        const settings = _.merge(_.cloneDeep(defaultState[module][form][preset]), storedSettings);
        _.set(res, `${module}.${form}.${preset}`, settings);
      });
    });
  });
  return res;
};

export default function settingsPresets(state = getDefaultState(), action) {
  let { data } = action;

  let getUrlQueryPath = (data) => [data.module, data.currentForm, data.currentPreset, 'urlQuery'];

  switch (action.type) {
    case types.VALUE_UPDATE_SETTINGS:
      storeSettings(state, data, utils.getObject(data, 'value', {}));
      if (!utils.getObject(state, `${data.module}.${data.currentForm}`)) {
        return state;
      }
      return Immutable.fromJS(state).updateIn([data.module, data.currentForm, data.currentPreset],
          item => (item ? item.mergeDeep(data.value) : data.value)
      ).toJS();

    case formDataTypes.RESTORE_FROM_URL: {
      if (data.currentPreset === '') {
        return state;
      }
      const newState = Immutable.fromJS(state).updateIn(
        getUrlQueryPath(data),
        (item) => item.map((v, k) => data.value[k] || v)
      ).toJS();
      storeSettings(
        state,
        data,
        _.get(newState, `${data.module}.${data.currentForm}.${data.currentPreset}`),
      );
      return newState;
    }
    case types.SET_CURRENT_SETTINGS:
      return Immutable.fromJS(state).updateIn([data.module, 'currentPreset'], () => data.preset).toJS();

    default:
      return state;
  }
}

function storeSettings(state, args, settings) {
  const stateSettings = utils.clone(getStateSettings(state, args));
  const newSettings = utils.clone(settings);
  if (!utils.isEqual(stateSettings, newSettings)) {
    settingsUtils.setSettings(_.merge(stateSettings, newSettings), args);
  }
}

function getStateSettings(state, args) {
  return utils.getObject(state, `${args.module}.${args.currentForm}.${args.currentPreset}`, {});
}
