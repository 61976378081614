import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'utils/format';
import moment from 'moment';
import classnames from 'classnames';

import TransportTypeIcon from './TransportTypeIcon';

import './styles/resultLegendTable.scss';

class ResultRouteLegendTable extends Component {
  onChange(id) {
    this.props.setRoute(id);
  }

  getTransportIcons(transports) {
    return transports.map((transport, idx) => (
      <TransportTypeIcon
        key={`transport-type-icon_${idx}`}
        transportType={transport.type}
      />
    ));
  }

  getTableRows() {
    return this.props.responseData.map((route, id) => {
      let isActive = this.props.currentRoute === id,
          departure = route.leg[0].maneuver[0].time,
          lastLeg = route.leg.slice(-1)[0],
          arrival = lastLeg.maneuver.slice(-1)[0].time,
          rowClasses = classnames({
            'rf-result-legend__table-row': true,
            'rf-result-legend__table-row-active': isActive,
          });

      return (
        <tr className={rowClasses} onClick={this.onChange.bind(this, id)} key={`ResultRouteLegendTableRow${id}`}>
          <td className="rf-result-legend__table-row-timecol">{format.time(route.summary.travelTime, 'short')}</td>
          <td>{departure ? moment(departure).format('HH:mm:ss') : 'N/A'}</td>
          <td>{arrival ? moment(arrival).format('HH:mm:ss') : 'N/A'}</td>
          <td className="rf-result-legend__table-row-transportcol">
            {this.getTransportIcons(route.publicTransportLine)}
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <table className="rf-result-legend__table">
        <tbody>
          <tr className="rf-result-legend__table-header">
            <th>Travel Time</th>
            <th>Departure</th>
            <th>Arrival</th>
            <th>Transport</th>
          </tr>
          {this.getTableRows()}
        </tbody>
      </table>
    );
  }
}

ResultRouteLegendTable.propTypes = {
  responseData: PropTypes.array.isRequired,
  currentRoute: PropTypes.number.isRequired,
  setRoute: PropTypes.func.isRequired,
};

export default ResultRouteLegendTable;
