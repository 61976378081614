import * as types from './constants';
import getGeocoderSettings from 'config/geocoder';
import { pdeApiConfig } from 'config/platformDataExtension';
import { requestErrorNotifyDecorator } from 'state/notification/actions';
import * as resultPanelActions from 'state/resultPanel/resultPanelActions';

import fetch from 'utils/fetch';
import { stringifyUrl, objectToQueryString } from 'utils/url';
import utils from 'utils';
import * as pdeUtils from 'utils/api/platformDataExtension';
import credentials from '../../config/credentials';
import { getForm } from 'state/tabs/tab/form';
import formsEnum from '../../config/formsEnum';
import { getOlsSearchSettings } from '../appSettings/olsSearch/reducer';

const GEOCODER_JSON_ATTRIBUTES = 1;

function getParams(params = {}) {
  return {
    ...params,
    app_id: credentials.PRODUCTION.app_id,
    app_code: credentials.PRODUCTION.app_code,
  };
}

export function setValue(data) {
  return { type: types.SET_VALUE, data };
}

export function selectResult(index) {
  return { type: types.SELECT_RESULT, data: { index } };
}

export function reset() {
  return { type: types.RESET };
}

export function searchByCoordsAction(searchSuggestions = []) {
  let results = searchSuggestions.map(suggestion => {
    let coords = suggestion.position.split(',').map(v => parseFloat(v.trim()));
    return { coords, label: suggestion.label || coords.join(',') };
  });
  return { type: types.SET_VALUE, data: { results } };
}

export function searchByLinkIdAction(searchString = '') {
  return dispatch => {
    let linkId = searchString;
    let params = getParams();

    return pdeUtils.getIndex(linkId, params, 'LINK_ID', 'ROAD_GEOM_FCn')
      .then(requestErrorNotifyDecorator(response => {
        let layer = utils.getObject(response, 'data.Layers[0]');
        let index = {
          functionalClass: layer.layer.split('_FC').slice(-1)[0],
          level: layer.level,
          tileXY: layer.tileXYs[0],
        };

        return pdeUtils.getTiles(pdeApiConfig.search, params, index);
      }, dispatch, 'error_description'))
      .then(requestErrorNotifyDecorator(response => {
        let parsedRow = pdeUtils.extractData(response.data.Tiles, linkId, pdeApiConfig.search);
        let lats = pdeUtils.parseCoords(parsedRow.ROAD_GEOM_FC.LAT);
        let lngs = pdeUtils.parseCoords(parsedRow.ROAD_GEOM_FC.LON);
        let shape = [];
        utils.range(lats.length).forEach(index => {
          shape.push(lats[index]);
          shape.push(lngs[index]);
        });
        let results = [
          {
            label: linkId,
            linkId,
            shape,
          },
        ];
        dispatch({ type: types.SET_VALUE, data: { results } });
        dispatch(resultPanelActions.setVisibility(true));
      }, dispatch, 'error_description'));
  };
}

export function getSuggestionsAction(searchString = '') {
  if (searchString === '') {
    return { type: types.SET_VALUE, data: { suggestions: [] } };
  }

  return async (dispatch, getState) => {
    const state = getState();
    let { bounds = {} } = state.map;
    const form = getForm(state);
    const isOls = form === formsEnum.OLS;
    let params;
    if (isOls) {
      params = {
        q: searchString,
        apikey: getOlsSearchSettings(state).apiKey,
      };
    } else {
      params = getParams({
        searchtext: searchString,
        jsonattributes: GEOCODER_JSON_ATTRIBUTES,
        mapview: `${bounds.top},${bounds.left};${bounds.bottom},${bounds.right}`,
      });
    }

    let geocoderSettings = getGeocoderSettings('geocode', isOls ? 'ols' : 'hls');
    let url = stringifyUrl({
      queryParams: objectToQueryString(params),
    }, geocoderSettings.url).apiUrl;

    fetch(url, geocoderSettings.url.jsonp).then(requestErrorNotifyDecorator(response => {
      const suggestions = isOls ? getOlsSuggestions(response) : getHlsSuggestions(response);
      dispatch({ type: types.SET_VALUE, data: { suggestions } });
    }, dispatch));
  };
}

function getOlsSuggestions(response) {
  let suggestions = [];
  let hasSuggestions = !response.isError && response.data.items.length;
  if (hasSuggestions) {
    response.data.items.forEach(result => {
      const { title, position, id } = result;
      suggestions.push({
        label: title,
        position: `${position.lat},${position.lng}`,
        locationId: id,
      });
    });
  }
  return suggestions;
}

function getHlsSuggestions(response) {
  let suggestions = [];
  let hasSuggestions = !response.isError && response.data.response.response.view.length;
  if (hasSuggestions) {
    response.data.response.response.view[0].result.forEach((result) => {
      const { address, displayPosition, locationId } = result.location;
      suggestions.push({
        label: address.label,
        position: `${displayPosition.latitude},${displayPosition.longitude}`,
        locationId,
      });
    });
  }
  return suggestions;
}

export function getStreetAddress(coords = '') {
  let params = getParams({
    jsonattributes: GEOCODER_JSON_ATTRIBUTES,
    prox: coords,
    mode: 'retrieveAddresses',
    maxresults: 3,
  });
  let url = stringifyUrl({
    queryParams: objectToQueryString(params),
  }, getGeocoderSettings('reversegeocode').url).apiUrl;
  return fetch(url);
}

export function getLinkId(coords) {
  let params = getParams({
    jsonattributes: GEOCODER_JSON_ATTRIBUTES,
    prox: coords,
    mode: 'retrieveAddresses',
    maxresults: 1,
  });
  let url = stringifyUrl({
    queryParams: objectToQueryString(params),
  }, getGeocoderSettings('reversegeocode').url).apiUrl;
  return fetch(url);
}
