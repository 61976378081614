import getConfig from 'config/form';
import utils from 'utils';

export function getTabTitle(tabs, module, form) {
  const formTabs = tabs.filter(tab => tab.form === form);
  const numbers = formTabs.map(tab => {
    const match = tab.title.match(/\d+$/);
    return Array.isArray(match) ? +match[0] : null;
  }).filter(Boolean);
  const formTemplate = utils.getObject(getConfig(), `${module}.formTemplates.${form}`, {});
  const maxNumber = Math.max(...numbers);
  const number = utils.isFinite(maxNumber) ? maxNumber + 1 : 1;
  return `${formTemplate.shortLabel} ${number}`;
}
