import { UI_SIZE } from './constants';
import modulesEnum from '../../../config/modulesEnum';
import formsEnum from '../../../config/formsEnum';

export default {
  isAutoRefreshOn: false,
  isAutoZoomOn: false,
  adjustViewport: false,
  uiSize: UI_SIZE.MEDIUM,
  defaultForm: { module: modulesEnum.ROUTING, form: formsEnum.OLS },
  isApiUrlMultiline: true,
};
