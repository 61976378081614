import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import pointMarkerTemplate from '../../../map/utils/PointMarkerTemplate';
import CustomChart from 'shared/chart';

class EVTab extends React.Component {
  shouldComponentUpdate(nextProps) {
    return utils.isPropChanged(nextProps, this.props, 'responseData.data.response.metaInfo.timestamp') ||
      utils.isPropChanged(nextProps, this.props, 'formData.fields.requestedRangeOnRoute') ||
      utils.isPropChanged(nextProps, this.props, 'currentRoute');
  }

  render() {
    const { route, formData, color } = this.props;
    const { waypoint, leg } = route;
    const { initialcharge, maxcharge, minchargeatstop } = formData.fields.requestedRangeOnRoute;
    const chargingstopdeparturecharge = _.get(formData, 'fields.batteryparameters.chargingstopdeparturecharge.value');

    const chartPoints = {
      distance: [0],
      energy: [initialcharge],
    };

    const waypointsData = [{ distance: 0 }];

    leg.forEach((legItem, index) => {
      const lastDistance = chartPoints.distance[chartPoints.distance.length - 1];
      chartPoints.distance.push(lastDistance + legItem.length);
      chartPoints.distance.push(lastDistance + legItem.length);
      chartPoints.energy.push(legItem.arrivalBatteryCharge || 0);
      chartPoints.energy.push(legItem.targetBatteryCharge || 0);

      const currentWaypoint = waypoint[index + 1];
      const waypointData = {
        distance: lastDistance + legItem.length,
      };

      if (currentWaypoint.chargingStationInfo) {
        waypointData.power = currentWaypoint.chargingStationInfo.consumablePower;
      }

      waypointsData.push(waypointData);
    });

    const chartData = {
      x: 'distance',
      selection: {
        enabled: false,
        multiple: false,
      },
      columns: [
        ['distance'].concat(chartPoints.distance),
        ['energy'].concat(chartPoints.energy),
      ],
      colors: {
        energy: color,
      },
      types: {
        energy: 'area',
      },
      labels: {
        format: {
          energy: val => (val ? +val.toFixed(2) : val),
        }
      }
    };

    const options = {
      axis: {
        x: {
          label: 'Distance (km)',
          tick: {
            count: 10,
            format: x => (x / 1000).toFixed(),
          },
        },
        y: {
          max: +maxcharge,
          label: {
            text: 'Energy (kWh)',
            position: 'inner-bottom',
          },
        },
      },

      grid: {
        y: {
          lines: [
            {
              value: maxcharge,
              text: 'Total battery capacity',
              class: 'color-grid-maxcharge'
            },
            {
              value: minchargeatstop,
              text: 'Min arrival energy',
              class: 'color-grid-minchargeatstop'
            },
            {
              value: chargingstopdeparturecharge,
              text: 'Target battery charge',
              class: 'color-grid-chargingstopdeparturecharge'
            },
          ]
        }
      },

      tooltip: {
        format: {
          title: d => `Distance: ${+d.toFixed(3)} km`,
        },
      },
    };

    const waypointsCount = waypointsData.length;
    let currentWaypointIndex = 0;

    return (<>
      <CustomChart
        data={chartData}
        options={options}
      />
        <div className="rf-waipoints-line">
          {waypointsData.map((waypointData, index) => {
            const isChargingStation = !!waypointData.power;
            let title = '&#x1f50b;';
            if (!isChargingStation) {
              if (index === 0) {
                title = 'A';
              } else if (index === waypointsCount - 1) {
                title = 'B';
              } else {
                title = ++currentWaypointIndex;
              }
            }
            return <div
              style={{
                position: 'absolute',
                left: `${waypointData.distance / chartPoints.distance[chartPoints.distance.length - 1] * 33.8 + 1}rem`,
              }}
              key={index}
            >
              <div
                className="Container"
                /* eslint-disable react/no-danger */
                dangerouslySetInnerHTML={{ __html: pointMarkerTemplate({ title }) }}
                /* eslint-enable react/no-danger */
              />
              {waypointData.power || ''}
            </div>;
          })}
        </div>
      </>
    );
  }
}

EVTab.propTypes = {
  responseData: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  currentRoute: PropTypes.number.isRequired,
};

export default EVTab;
