import selectionModeEnum from 'config/form/trafficTab/selectionModeEnum';

export function isWaypointPerformed(waypoint) {
  let { category, coords = {}, link = {}, street = {} } = waypoint;
  if (category === 'geo') {
    return coords.isValid && coords.value !== '';
  } else if (category === 'link') {
    return link.isValid && link.value !== '';
  } else if (category === 'street') {
    return street.name !== '' && street.value !== '';
  }
  return true;
}

export function isSelectionPerformed(fields) {
  let { bbox = {}, selectionMode, corridor = {} } = fields;
  let { selectionNWCorner = {}, selectionSECorner = {} } = bbox;
  let { points = {} } = corridor;
  if (selectionMode === selectionModeEnum.BOUNDING_BOX && (
      selectionNWCorner.value === '' || !selectionNWCorner.isValid ||
      selectionSECorner.value === '' || !selectionSECorner.isValid
    )) {
    return false;
  } else if (selectionMode === selectionModeEnum.CORRIDOR && (
      points.value === '' || !points.isValid || points.value.split(';').filter(Boolean).length < 2
    )) {
    return false;
  }
  return true;
}
