import { assignTabDataAndDispatch } from '../../../actions';
import { SET } from './constants';

export function setSelectedTrace(traceDirection = null, traceId = null) {
  let data = null;
  if (traceDirection !== null) {
    data = {
      traceDirection,
      traceId,
    };
  }
  return assignTabDataAndDispatch({ type: SET, data });
}
