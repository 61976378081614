import React, { Component } from 'react';
import PropTypes from 'prop-types';

import utils from 'utils';
import AutosizeInput from 'shared/autosizeInput';
import CloseButton from 'shared/closeButton';
import Loader from 'shared/loader';
import TabActions from './TabActions';

export class Tab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
  }

  onSetTitle(e) {
    const title = utils.extractData(e);
    if (title !== '') {
      this.props.setTitle(title);
    }
    this.setState({ isEdit: false });
  }

  onEdit(e) {
    e.stopPropagation();
    this.setState({ isEdit: true });
  }

  getIsActive(isActive) {
    const { setActive, color } = this.props;
    if (isActive) {
      return (
        <i
          className="rf-nav-tabs__tab__is-active rf-webfont-icon rf-webfont-icon_eye_visible"
          style={{ color }}
          onClick={setActive.bind(this, false)}
        />
      );
    }
    return (
      <i
        className="rf-nav-tabs__tab__is-active rf-webfont-icon rf-webfont-icon_eye_invisible"
        onClick={setActive.bind(this, true)}
      />
    );
  }


  getTitle() {
    const { title, isSelected, color, duplicate } = this.props;
    let styles = isSelected ? { color } : {};
    let titleEl;
    if (this.state.isEdit) {
      titleEl = (
        <AutosizeInput
          ref="titleInput"
          value={title}
          onBlur={::this.onSetTitle}
          cssClasses="rf-nav-tabs__tab__title-input"
        />
      );
    } else {
      titleEl = <span className="rf-nav-tabs__tab__caption__label" style={styles}>{title}</span>;
    }
    return (
      <span className="rf-nav-tabs__tab__caption">
        {titleEl}&nbsp;
        <TabActions
          onEdit={::this.onEdit}
          onDuplicate={duplicate}
          styles={styles}
        />
      </span>
    );
  }

  getCloseButton() {
    const { isSelected, color } = this.props;
    let styles = isSelected ? { color } : {};
    return <CloseButton onClick={this.props.close} style={styles} />;
  }

  render() {
    const { isSelected, isActive, showCloseButton, isLoading } = this.props;
    return (
      <div>
        {isLoading ? <Loader /> : this.getIsActive(isActive, isSelected)}
        {this.getTitle()}
        {showCloseButton && this.getCloseButton()}
      </div>
    );
  }
}

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
  duplicate: PropTypes.func.isRequired,
};

export default Tab;
