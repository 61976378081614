import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isLinkValid } from 'utils/validation/linkValidation';
import { isCoordValid } from 'utils/validation/coordsValidation';
import * as SearchActions from 'state/search/actions';
import * as ResultPanelActions from 'state/resultPanel/resultPanelActions';

import CustomAutocomplete from 'shared/autocomplete';
import classnames from 'classnames';
import utils from 'utils';

import './styles/searchBar.scss';

let SEARCH_TYPES = {
  COORDS: 'coords',
  LINK: 'link',
  STRING: 'stringSearch',
};

class SearchBar extends Component {

  constructor(...args) {
    super(...args);
    this.getSuggestionsDelayed = utils.debounce(::this.getSuggestions, 300);
    this.state = {
      isActive: false,
    };
  }

  onChange(val) {
    this.props.setSearchValue({ searchString: val, results: [] });
    this.getSuggestionsDelayed(val);
  }

  onSelect(item) {
    this.onSearch({ coords: [item], type: SEARCH_TYPES.STRING });
    this.props.setSearchValue({ searchString: item.label });
    this.props.toggleResultPanel(true);
  }

  onSearch(data) {
    let { searchByCoordsAction, searchByLinkIdAction } = this.props;

    switch (data.type) {
      case SEARCH_TYPES.LINK: {
        searchByLinkIdAction(data.link);
        break;
      }
      case SEARCH_TYPES.COORDS: {
        let coords = [{ position: data.coords }];
        searchByCoordsAction(coords);
        break;
      }
      case SEARCH_TYPES.STRING: {
        searchByCoordsAction(data.coords);
        break;
      }
      default:
        console.warn(`SEARCH_TYPE ${data.type} is not supported.`);
    }
  }

  getSuggestions(searchString) {
    let { getSuggestionsAction } = this.props;
    getSuggestionsAction(searchString);
  }

  search(e) {
    e.preventDefault();

    let { searchString } = this.props.searchData;
    let _isLinkValid = isLinkValid(searchString);
    let _isCoordValid = isCoordValid(searchString);

    if (_isLinkValid) {
      let type = SEARCH_TYPES.LINK;
      this.onSearch({ link: searchString, type });
    } else if (_isCoordValid) {
      let type = SEARCH_TYPES.COORDS;
      this.onSearch({ coords: searchString, type });
    } else {
      let type = SEARCH_TYPES.STRING;
      this.onSearch({ coords: this.props.searchData.suggestions, type });
    }
    this.props.toggleResultPanel(true);
  }

  render() {
    let { suggestions, searchString } = this.props.searchData;
    let addKeyToSuggestions = (suggestion) => Object.assign(suggestion, { key: suggestion.locationId });
    let className = classnames('rf-search-bar', {
      'rf-search-bar_active': this.state.isActive,
    });

    return (
      <form className={className} onSubmit={::this.search}>
        <button type="submit" className="rf-button rf-button-search" />
        <CustomAutocomplete
          onChange={::this.onChange}
          onSelect={::this.onSelect}
          inputProps={{
            onFocus: () => this.setState({ isActive: true }),
            onBlur: () => this.setState({ isActive: false }),
          }}
          value={searchString}
          suggestions={suggestions.map(addKeyToSuggestions)}
          placeholder="Search"
        />
      </form>
    );
  }
}

SearchBar.propTypes = {
  searchData: PropTypes.object.isRequired,
  searchByCoordsAction: PropTypes.func.isRequired,
  searchByLinkIdAction: PropTypes.func.isRequired,
  getSuggestionsAction: PropTypes.func.isRequired,
  toggleResultPanel: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  searchData: state.search,
});

const mapDispatchToProps = {
  searchByCoordsAction: SearchActions.searchByCoordsAction,
  searchByLinkIdAction: SearchActions.searchByLinkIdAction,
  getSuggestionsAction: SearchActions.getSuggestionsAction,
  setSearchValue: SearchActions.setValue,
  toggleResultPanel: ResultPanelActions.setVisibility,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchBar);
