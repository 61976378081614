import formsEnum from 'config/formsEnum';

import ContextMenuRouting from './routing/ContextMenuContainer';
import ContextMenuIsoline from './isoline/ContextMenuContainer';
import ContextMenuMatrix from './matrix/ContextMenuContainer';
import ContextMenuRouteInfo from './routeInfo/ContextMenuContainer';
import ContextMenuApproachPathRouter from './approachPathRouter/ContextMenuContainer';
import ContextMenuMatchRoute from './matchRoute/ContextMenuContainer';
import ContextMenuOlsApi from './olsApi/ContextMenuContainer';

export default {
  [formsEnum.CALCULATE_ROUTE]: ContextMenuRouting,
  [formsEnum.CALCULATE_ISOLINE]: ContextMenuIsoline,
  [formsEnum.CALCULATE_MATRIX]: ContextMenuMatrix,
  [formsEnum.GET_ROUTE_INFO]: ContextMenuRouteInfo,
  [formsEnum.APPROACH_PATH_ROUTER]: ContextMenuApproachPathRouter,
  [formsEnum.MATCH_ROUTE]: ContextMenuMatchRoute,
  [formsEnum.OLS]: ContextMenuOlsApi,
};
