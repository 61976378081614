import handlers from './customHandlers';
import truckFormatter from './common/truckFormatter';

function waypointsFormatter(points, formData) {
  return handlers
    .waypointsFormatter(points, formData)
    .split('&')
    .map((itemStr, index) => {
      let pair = itemStr.split('='),
          key = points[index].key;
      return `${key}=${pair[1]}`;
    })
    .join('&');
}

export default {
  ...truckFormatter,
  waypoints: waypointsFormatter,
  mode: handlers.modeFormatter,
  jsonattributes: handlers.jsonattributesFormatter,
  customconsumptiondetails: handlers.consumptionFormatter,
  batteryparameters: handlers.complexFormatter(),
  chargingstationfilters: handlers.complexFormatter(),
};
