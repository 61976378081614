import * as types from './constants';

export default (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_LOADING:
      return action.isLoading;

    default:
      return state;
  }
};
