import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import Columns from 'shared/columns';
import CustomCheckboxList from 'shared/checkboxList';

import { setPowerFilter } from 'state/tabs/tab/formData/evChargingStations/evProxyForm/actions';
import LayerToggle from '../forms/common/LayerToggle';
import { getCurrentFormData } from 'state/tabs/tab/formData';

import { getForm } from 'state/tabs/tab/form';
import formsEnum from '../../config/formsEnum';
import utils from '../../utils';

class PowerContainer extends Component {
  constructor(...args) {
    super(...args);
    this.state = { formExpanded: false };
  }

  onToggleForm() {
    this.setState({
      formExpanded: !this.state.formExpanded,
    });
  }

  onChange(key, e) {
    this.props.setPowerFilter({ [key]: utils.extractData(e) });
  }

  onPlugTypesChange(val) {
    const isChecked = utils.extractData(val);
    const { value } = val.target;
    let { plugTypes } = this.props.formData.powerFilter;
    if (isChecked && plugTypes.indexOf(value) === -1) {
      plugTypes = [...plugTypes, value];
    } else if (!isChecked && plugTypes.indexOf(value) > -1) {
      plugTypes = utils.without(plugTypes, value);
    } else {
      return;
    }

    this.onChange('plugTypes', plugTypes);
  }

  renderPowerForm() {
    const isValid = (+this.props.formData.powerFilter.from <= this.props.formData.powerFilter.to)
      || this.props.formData.powerFilter.from === '' || this.props.formData.powerFilter.to === '';
    const { plugTypes } = this.props.formData.powerFilter;

    const filterPlugTypes = ['AC_DC_COMBO_MENNEKES_TYPE_2', 'J1772_COMBO', 'CHA_DE_MO', 'TESLA_CONNECTOR'];

    const filterPlugTypesOptions = filterPlugTypes.map(filterPlugType => ({
      label: filterPlugType,
      value: filterPlugType,
      isChecked: plugTypes.indexOf(filterPlugType) > -1,
      onChange: ::this.onPlugTypesChange,
    }));

    return <div className="rf-map__tooltip rf-map__power-module" ref="form">
      <Columns>
        <FormRow>
          <h2>Filter by plug type</h2>
          <CustomCheckboxList params={filterPlugTypesOptions} />
        </FormRow>
        <FormRow>
          <h2>Filter by power range</h2>
          <FormRow>
            <CustomInput
              type="number"
              label="From (kW)"
              value={this.props.formData.powerFilter.from}
              isValid={isValid}
              onBlur={this.onChange.bind(this, 'from')}
              blurOnEnter
            />
          </FormRow>
          <FormRow>
            <CustomInput
              type="number"
              label="To (kW)"
              value={this.props.formData.powerFilter.to}
              isValid={isValid}
              onBlur={this.onChange.bind(this, 'to')}
              blurOnEnter
            />
          </FormRow>
        </FormRow>
      </Columns>
    </div>;
  }

  render() {
    if (this.props.currentForm !== formsEnum.EV_PROXY) {
      return null;
    }
    return <div>
      <div className="rf-map__filter_by_power-toggle">
        <LayerToggle
          active={this.props.formData.powerFilter.from !== '' || this.props.formData.powerFilter.to !== ''}
          disabled={false}
          onChange={::this.onToggleForm}
          tooltipText="Filter by power range"
        />
      </div>
      {this.state.formExpanded && this.renderPowerForm()}
    </div>;
  }
}

PowerContainer.propTypes = {
  formData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  setPowerFilter: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    formData: getCurrentFormData(state),
    currentForm: getForm(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPowerFilter: bindActionCreators(setPowerFilter, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowerContainer);

