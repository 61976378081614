import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomComponent from 'shared/component';

import './styles/select.scss';

class CustomSelect extends CustomComponent {
  render() {
    let { label, onChange, options, value, isReadonly, addEmptyOption } = this.props;
    let optionsList = options.map(option => {
      if (option.value !== undefined) {
        return option.value;
      }
      return option;
    });
    let hasError = false;
    if (value !== '' && optionsList.indexOf(value) === -1) {
      options = [{ label: this.props.notSupportedLabel, value: '' }].concat(options);
      hasError = true;
    }
    if (addEmptyOption) {
      options = [{ label: this.props.notSelectedLabel, value: '' }].concat(options);
    }
    let _optionElements = options.map((v, idx) => {
      let value = v.value !== undefined ? v.value : v;
      return <option key={idx} value={value}>{v.label || v}</option>;
    });
    let labelEl = label ? <span className="rf-select__caption">{label}</span> : '';
    let selectClasses = classnames('rf-select__field', { 'rf-select__field_has-error': hasError });

    return (
      <label className={this.getCssClasses(['rf-select'])}>
        {labelEl}
        <span className="rf-select__wrapper">
          <select
            className={selectClasses}
            value={value || ''}
            onChange={onChange}
            disabled={isReadonly}
          >
            {_optionElements}
          </select>
        </span>
      </label>
    );
  }
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  notSupportedLabel: PropTypes.string,
  notSelectedLabel: PropTypes.string,
  options: PropTypes.array.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  addEmptyOption: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

CustomSelect.defaultProps = {
  label: '',
  addEmptyOption: false,
  isReadonly: false,
  notSupportedLabel: '- Not supported -',
  notSelectedLabel: '- Not selected -',
};

export default CustomSelect;
