import PropTypes from 'prop-types';

let metaInfoShape = PropTypes.shape({
  interfaceVersion: PropTypes.string.isRequired,
  mapVersion: PropTypes.string.isRequired,
  moduleVersion: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
});

let responseWrapperShape = PropTypes.shape({
  metaInfo: metaInfoShape,
  metaEntry: PropTypes.array,
  route: PropTypes.array,
});

const responseDataShape = PropTypes.shape({
  isError: PropTypes.bool,
  data: PropTypes.shape({
    response: responseWrapperShape,
  }),
});

export default responseDataShape;
