import utils from 'utils';
import mapPolygon from '../utils/PolygonHelper';
import pointArgsCount from 'utils/response/pointArgsCount';
import { addOpacityToHexColor, ZINDEX } from 'config/map';

import ModuleBase from './_Base';

export default class Polygon extends ModuleBase {
  process(nextProps) {
    this.clearGroups();
    this.renderPolygon(nextProps);
  }

  getData() {
    return [];
  }

  getPolygonOptions(index, fields, fillColor, strokeColor) {
    let startIndex = ZINDEX.POLYGON;
    return {
      strokeColor,
      fillColor,
      zIndex: startIndex - index,
      pointArgsCount: pointArgsCount(fields.jsonattributes, fields.returnelevation),
    };
  }

  renderPolygon(nextProps) {
    let group = this.getGroup();
    let data = this.getData(nextProps) || [];
    let polygons = [];
    data.forEach((item, index) => {
      (item.component || []).forEach(component => {
        let { shape } = component;
        let tabColorPalette = nextProps.tabData.tabColorPalette || this.props.tabData.tabColorPalette;
        let { currentIsoline = 0 } = nextProps.tabData.formData || this.props.tabData.formData;

        let color;
        if (index === currentIsoline && index === data.length - 1) {
          color = addOpacityToHexColor(tabColorPalette.primary, 0.75);
        } else if (index === currentIsoline || index === data.length - 1) {
          color = addOpacityToHexColor(tabColorPalette.primary, 0.5);
        } else {
          color = 'rgba(0,0,0,0)';
        }

        let args = Object.assign({}, { shape },
          this.getPolygonOptions(
            index,
            utils.getObject(nextProps.tabData, 'formData.fields',
              utils.getObject(this.props.tabData, 'formData.fields', {})),
            color,
            addOpacityToHexColor(tabColorPalette.primary),
          ));
        polygons.push(mapPolygon.createPolygon(args));
      });
    });
    if (polygons.length > 0) {
      group.addObjects(polygons);
    }
  }
}
