import handlers from './customHandlers';
import truckFormatter from './common/truckFormatter';

export default {
  ...truckFormatter,
  mode: handlers.modeFormatter,
  jsonattributes: handlers.jsonattributesFormatter,
  routeattributes: handlers.attributesFormatter,
  legattributes: handlers.attributesFormatter,
  maneuverattributes: handlers.attributesFormatter,
  linkattributes: handlers.attributesFormatter,
  lineattributes: handlers.attributesFormatter,
  resolution: handlers.resolutionFormatter,
  waypoints: handlers.waypointsFormatter,
  generalizationtolerances: handlers.generalisationsFormatter,
  walkspeed: handlers.objectFormatter,
  walkradius: handlers.objectFormatter,
  avoidlinks: handlers.objectFormatter,
  maxnumberofchanges: handlers.objectFormatter,
  avoidAreas: handlers.avoidAreasFormatter,
  customconsumptiondetails: handlers.consumptionFormatter,
  batteryparameters: handlers.complexFormatter(),
  chargingstationfilters: handlers.complexFormatter(),
  avoidTransportTypes: handlers.arrayFormatter,
  vehicletype: handlers.vehicleType,
  licenseplate: handlers.licensePlate,
  excludezonetypes: handlers.arrayFormatter,
};
