import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import FormRow from 'shared/formRow';
import AdvancedViaFields from './AdvancedViaFields';

import formsEnum from 'config/formsEnum';
import languagesList from 'config/languagesList';
import getConfig from 'config/form';
import CheckboxAttributesSet from '../../common/CheckboxAttributesSet';
import './styles/olsApiForm.scss';
import ReverseIcon from '../../common/ReverseIcon';
import ExpandFormRow from '../../../shared/expandFormRow';
import OlsAvoidAreas from './OlsAvoidAreas';
import OlsWaypointAdvanced from './OlsWaypointAdvanced';

const formConfig = getConfig(formsEnum.OLS);

class OlsApiForm extends Component {
  reverse = () => {
    const {
      filterValueMultiUpdate,
      formData: { fields: { origin, destination, originAdvanced, destinationAdvanced } = {} } = {}
    } = this.props;

    filterValueMultiUpdate(formsEnum.OLS, {
      origin: destination,
      destination: origin,
      originAdvanced: destinationAdvanced,
      destinationAdvanced: originAdvanced,
    });
  };

  onWaypointAdvancedChanged = (field, subfield, value) => {
    this.props.onChange(`${field}.${subfield}`, value);
  };

  onChangeOrigin = ({ target: { value } }) => {
    const { onChange } = this.props;

    onChange('origin', value.replace(' ', ''));
  }

  onChangeDestination = ({ target: { value } }) => {
    const { onChange } = this.props;

    onChange('destination', value.replace(' ', ''));
  }

  render() {
    const { formData = {}, children, onChange, isAutoRefreshOn } = this.props;
    const { fields = {} } = formData;
    const { origin, destination, alternatives, extraparams, lang, units, olsReturn, via } = fields;
    const onChangeDebounced = _.debounce(onChange, 500);
    let extraParamsProps;
    let alternativesProps;
    const extraParamsChange = (e) => onChange('extraparams', e);
    if (isAutoRefreshOn) {
      extraParamsProps = { onBlur: extraParamsChange };
      alternativesProps = { onChange: (e) => onChangeDebounced('alternatives', e) };
    } else {
      extraParamsProps = { onChange: extraParamsChange };
      alternativesProps = { onChange: (e) => onChange('alternatives', e) };
    }

    return <div className="rf-olsApiForm">
      <ul className="rf-icons-bar" />
      <h3>OLS API Form</h3>
      <FormRow cssClasses="rf-olsApiForm__origin">
        <div className="rf-olsApiForm__origin__reverse">
          <ReverseIcon label="Reverse origin, destination" reverse={this.reverse} />
        </div>
        <CustomInput
          label="Origin"
          value={origin.value}
          isValid={origin.isValid}
          onBlur={this.onChangeOrigin}
          blurOnEnter
        />
      </FormRow>
      <OlsWaypointAdvanced
        data={fields.originAdvanced}
        onChange={this.onWaypointAdvancedChanged.bind(this, 'originAdvanced')}
      />
      <FormRow>
        <CustomInput
          label="Destination"
          value={destination.value}
          isValid={destination.isValid}
          onBlur={this.onChangeDestination}
          blurOnEnter
        />
      </FormRow>
      <OlsWaypointAdvanced
        data={fields.destinationAdvanced}
        onChange={this.onWaypointAdvancedChanged.bind(this, 'destinationAdvanced')}
      />
      <FormRow cssClasses="rf-grey-box rf-olsApiForm__via">
        <h4>Via</h4>
        <AdvancedViaFields
          onChange={e => onChange('via', e)}
          value={via}
          isValid
          separator=";"
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Alternatives"
          value={alternatives.value}
          isValid={alternatives.isValid}
          type="number"
          blurOnEnter
          {...alternativesProps}
        />
      </FormRow>

      <CheckboxAttributesSet
        label="Return options"
        options={formConfig.olsReturn}
        data={olsReturn}
        isExpanded
        onChange={e => onChange('olsReturn', e)}
      />

      {children}

      <FormRow cssClasses="rf-grey-box">
        <CustomInput
          type="text"
          label="Extra params"
          isValid={extraparams.isValid}
          value={extraparams.value}
          blurOnEnter
          {...extraParamsProps}
        />
      </FormRow>
      <ExpandFormRow label="Avoid" isExpanded cssClasses="rf-olsApiForm__avoid">
        <OlsAvoidAreas value={fields['avoid[areas]']} onChange={onChange} />
        <CheckboxAttributesSet
          label="Avoid Features"
          options={formConfig.avoidFeatures}
          data={fields['avoid[features]']}
          isExpanded={false}
          onChange={e => onChange('avoid[features]', e)}
        />
        <FormRow>
          <CustomInput
            label="Avoid Zone Ids"
            value={fields['avoid[zoneIds]']}
            onBlur={e => onChange('avoid[zoneIds]', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Avoid Zone Exceptions"
            value={fields['avoid[zoneExceptions]']}
            onBlur={e => onChange('avoid[zoneExceptions]', e)}
            blurOnEnter
          />
        </FormRow>
      </ExpandFormRow>
      <FormRow>
        <CustomInput
          label="Exclude Countries"
          value={fields['exclude[countries]']}
          blurOnEnter
          onChange={e => onChange('exclude[countries]', e)}
        />
      </FormRow>
      <FormRow>
        <CustomSelect
          label="Language"
          value={lang}
          options={languagesList}
          onChange={e => onChange('lang', e)}
          addEmptyOption
        />
      </FormRow>
      <FormRow>
        <CustomSelect
          label="Units"
          value={units}
          options={formConfig.metricsystems}
          onChange={e => onChange('units', e)}
          addEmptyOption
        />
      </FormRow>
    </div>;
  }
}

OlsApiForm.propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isAutoRefreshOn: PropTypes.bool.isRequired,
};

export default OlsApiForm;
