import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import CustomTabs from 'shared/tabs';
import CustomCheckbox from 'shared/checkbox';
import Tooltip from 'shared/tooltip';

import PointsFieldSet from './matrixForm/PointsFieldSet';

import RouteDateField from '../common/RouteDateField';
import ModeFieldSet from '../common/ModeFieldSet';
import SpeedProfile from '../common/SpeedProfile';
import CheckboxAttributesSet from '../common/CheckboxAttributesSet';
import TruckProfileBlock from '../common/TruckProfileBlock';
import AvoidFeatures from '../common/AvoidFeatures';
import IconsBar from '../common/IconsBar';
import { getSelectedTabs } from '../../../state/tabs';

import * as MatrixPointsActions from 'state/tabs/tab/formData/routingTab/matrixForm/points/actions';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as AvoidAreasActions from 'state/tabs/tab/formData/common/avoidAreas/actions';
import * as NotificationActions from 'state/notification/actions';
import * as TabsAction from 'state/tabs/actions';
import { setTabTitle } from 'state/tabs/tab/title/actions';

import getConfig from 'config/form';
import utils from 'utils';
import formsEnum from 'config/formsEnum';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getUrlSettings } from 'state/urlSettings';
import { getSelectedTab } from 'state/selectedTab';

class MatrixForm extends Component {
  onChange = (key, e) => {
    this.props.filterValueUpdate({ key, currentForm: formsEnum.CALCULATE_MATRIX, value: utils.extractData(e) });
  }

  onPointUpdate(key, index, e) {
    this.props.updatePoint({ key, index, value: utils.extractData(e) });
  }

  onPointsAllUpdate(key, e) {
    this.props.updateAllPoints({ key, value: utils.extractData(e) });
  }

  onPointRemove(key, index) {
    this.props.removePoint({ key, index });
  }

  getRequestTabContent(currentForm, formConfig) {
    const { setNotification, formData, scooter, duplicateTab, selectedTab, setTabTitle, tabTitle } = this.props;
    let { points = {}, avoidAreas = {}, fields = {} } = formData,
        {
          routeDate,
          mode,
          searchRange,
          speedprofile,
          useNewPointsFormat,
          enhancedbicyclerouting,
        } = fields,
        isTruckTransportMode = mode.transport === 'truck';
    return (
      <div>
        <IconsBar
          items={[
            { name: 'waypoints', title: 'Matrix Points' },
            { name: 'mode', title: 'Mode' },
            { name: 'time', title: 'Route Time' },
            { name: 'avoid-features', title: 'Avoid Features' },
          ]}
          container={this}
        />
        <FormRow cssStyle={{ margin: 0 }}>
          <PointsFieldSet
            label="Start"
            subLabel="S"
            data={points.start}
            onUpdate={this.onPointUpdate.bind(this, 'start')}
            onUpdateAll={this.onPointsAllUpdate.bind(this, 'start')}
            onRemove={this.onPointRemove.bind(this, 'start')}
          />
        </FormRow>
        <FormRow cssStyle={{ margin: 0 }}>
          <PointsFieldSet
            label="Destination"
            subLabel="D"
            data={points.destination}
            onUpdate={this.onPointUpdate.bind(this, 'destination')}
            onUpdateAll={this.onPointsAllUpdate.bind(this, 'destination')}
            onRemove={this.onPointRemove.bind(this, 'destination')}
          />
        </FormRow>
        <Tooltip tooltip="start=...&start=... instead of start0=...&start1=...">
          <CustomCheckbox
            label="Use new format"
            cssClasses="rf-waypoints-group__use-new-format-checkbox"
            isChecked={useNewPointsFormat}
            onChange={(e) => this.onChange('useNewPointsFormat', e)}
          />
        </Tooltip>
        <FormRow cssStyle={{ margin: '0 0 1.9rem 0' }}>
          <CustomInput
            label="Search Range [m]"
            type="number"
            value={searchRange}
            onBlur={this.onChange.bind(this, 'searchRange')}
            blurOnEnter
          />
        </FormRow>
        <ModeFieldSet
          label="Mode"
          value={mode}
          formConfig={formConfig}
          onChange={this.onChange}
          scooter={scooter}
          enhancedbicyclerouting={enhancedbicyclerouting}
          duplicateTab={duplicateTab}
          selectedTab={selectedTab}
          setTabTitle={setTabTitle}
          tabTitle={tabTitle}
        />
        <SpeedProfile
          formConfig={formConfig}
          value={speedprofile}
          onChange={this.onChange.bind(this, 'speedprofile')}
          isHidden={!isTruckTransportMode}
        />
        <TruckProfileBlock
          label="Truck Profile"
          fields={fields}
          formConfig={formConfig}
          isHidden={!isTruckTransportMode}
          setNotification={this.props.setNotification}
          onChange={this.onChange.bind(this)}
        />
        <FormRow cssClasses="rf-grey-box rf-route-time" id="time">
          <RouteDateField
            label="Route Time"
            formConfig={formConfig}
            routeDate={routeDate}
            onChange={this.onChange.bind(this, 'routeDate')}
          />
        </FormRow>
        <AvoidFeatures
          onChange={this.onChange}
          avoidAreaAdd={this.props.avoidAreaAdd}
          avoidAreas={avoidAreas}
          avoidAreaUpdate={this.props.avoidAreaUpdate}
          avoidAreaUpdateAll={this.props.avoidAreaUpdateAll}
          avoidAreaRemove={this.props.avoidAreaRemove}
          currentForm={currentForm}
          fields={fields}
          notify={setNotification}
        />
      </div>
    );
  }

  getResponseTabContent(currentForm, formConfig) {
    let { fields = {} } = this.props.formData,
        {
          matrixAttributes,
          summaryAttributes,
        } = fields;

    return (
      <div>
        <ul className="rf-icons-bar" />
        <CheckboxAttributesSet
          label="Matrix Attributes"
          options={formConfig.matrixAttributes}
          data={matrixAttributes}
          onChange={this.onChange.bind(this, 'matrixAttributes')}
        />
        <CheckboxAttributesSet
          label="Summary Attributes"
          options={formConfig.summaryAttributes}
          data={summaryAttributes}
          onChange={this.onChange.bind(this, 'summaryAttributes')}
        />
      </div>
    );
  }

  render() {
    let currentForm = formsEnum.CALCULATE_MATRIX;
    let formConfig = getConfig(currentForm);
    let tabs = [
      { title: 'Input Params', content: this.getRequestTabContent(currentForm, formConfig) },
      { title: 'Output Params', content: this.getResponseTabContent(currentForm, formConfig) },
    ];
    return (
      <div>
        <CustomTabs data={tabs} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formData: getCurrentFormData(state),
    scooter: getUrlSettings(state).scooter,
    selectedTab: getSelectedTab(state),
    tabTitle: getSelectedTabs(state).title,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    updatePoint: bindActionCreators(MatrixPointsActions.update, dispatch),
    updateAllPoints: bindActionCreators(MatrixPointsActions.updateAll, dispatch),
    removePoint: bindActionCreators(MatrixPointsActions.remove, dispatch),
    avoidAreaUpdate: bindActionCreators(AvoidAreasActions.avoidAreaUpdate, dispatch),
    avoidAreaUpdateAll: bindActionCreators(AvoidAreasActions.avoidAreaUpdateAll, dispatch),
    avoidAreaAdd: bindActionCreators(AvoidAreasActions.avoidAreaAdd, dispatch),
    avoidAreaRemove: bindActionCreators(AvoidAreasActions.avoidAreaRemove, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
    duplicateTab: bindActionCreators(TabsAction.duplicateTab, dispatch),
    setTabTitle: bindActionCreators(setTabTitle, dispatch),
  };
}

MatrixForm.propTypes = {
  formData: PropTypes.object.isRequired,
  updatePoint: PropTypes.func.isRequired,
  updateAllPoints: PropTypes.func.isRequired,
  removePoint: PropTypes.func.isRequired,
  avoidAreaUpdate: PropTypes.func.isRequired,
  avoidAreaUpdateAll: PropTypes.func.isRequired,
  avoidAreaAdd: PropTypes.func.isRequired,
  avoidAreaRemove: PropTypes.func.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  scooter: PropTypes.bool,
  selectedTab: PropTypes.number.isRequired,
  tabTitle: PropTypes.string.isRequired,
  duplicateTab: PropTypes.func.isRequired,
  setTabTitle: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatrixForm);
