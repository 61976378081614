import * as mapPolyline from '../../utils/PolylineHelper';
import * as mapMarkers from '../../utils/MarkersHelper';

import { getLinkInfoRouteColor, ZINDEX } from 'config/map';

import ModuleBase from '../_Base';

export default class DirectionBase extends ModuleBase {
  process(nextProps) {
    this.clearGroups();
    this.renderDirection(nextProps);
  }

  getCircleOptions() {
    let style = { lineWidth: 3, strokeColor: '#f00', fillColor: 'transparent' };
    return { style, zIndex: ZINDEX.DIRECTION };
  }

  getPolylineOptions() {
    return {
      strokeColor: getLinkInfoRouteColor(),
      lineWidth: 4,
    };
  }

  getCircleRadius() {
    return 2;
  }

  getLinks() {
    return [];
  }

  renderDirection(nextProps) {
    let objects = [];
    let group = this.getGroup();
    let data = this.getLinks(nextProps);
    data.forEach(item => {
      let { shape } = item;
      if (shape) {
        objects.push(mapMarkers.createCircle(
          shape.slice(0, 2),
          this.getCircleRadius(),
          this.getCircleOptions(nextProps)),
        );
        if (shape.length > 2) {
          objects.push(mapPolyline.createPolyline({ shape, ...this.getPolylineOptions() }));
        }
      }
    });
    if (objects.length > 0) {
      group.addObjects(objects);
    }
  }
}
