import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomInput from 'shared/input';
import FormRow from 'shared/formRow';
import CustomSlider from 'shared/slider';
import MapFormToggler from './MapFormToggler';

export default class RoRForm extends Component {
  onChangeBatteryLevel(value) {
    this.props.onChange('batteryLevel', { target: { value } });
  }

  render() {
    let { totalCapacity, batteryLevel, onChange } = this.props;
    return <div className="rf-map__tooltip rf-map__ror-module" ref="form">
      <h2>Range on Route</h2>
      <MapFormToggler getFormRef={() => this.refs.form} {...this.props} />
      <FormRow>
        <CustomInput
          cssClasses="rf-clearfix"
          label="Battery Capacity [(E)nergy]"
          placeholder="Battery Capacity [(E)nergy]"
          isValid
          value={totalCapacity}
          onChange={onChange.bind(this, 'totalCapacity')}
          type="number"
          debounce
        />
      </FormRow>
      <FormRow>
        <CustomSlider
          value={batteryLevel}
          onChange={::this.onChangeBatteryLevel}
          label="State of Charge [%]"
          debounced
        />
      </FormRow>
    </div>;
  }
}

RoRForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  totalCapacity: PropTypes.number.isRequired,
  batteryLevel: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};
