import * as settingsPresetsTypes from 'state/settingsPreset/settingsPresetConstants';
import settingsUtils from 'utils/settings';
import * as tabsTypes from 'state/tabs/constants';
import defaultState from './defaultState';
import defaultPresetsState from 'state/settingsPreset/defaultPresetsState';
import { RESTORE_FROM_URL } from '../formData/constants';
import Immutable from 'immutable';

export default function (state = defaultState, action) {
  switch (action.type) {
    case tabsTypes.ADD_TAB:
      return action.tabData.settings;
    case settingsPresetsTypes.SET_CURRENT_SETTINGS:
      return Object.assign({}, state, action.data.presetSettings);
    case settingsPresetsTypes.VALUE_UPDATE_SETTINGS:
      if (state === null) {
        return action.data.value;
      }
      return Immutable.fromJS(state).mergeDeep(action.data.value).toJS();
    case RESTORE_FROM_URL:
      return settingsUtils.getPresetSettings(
        action.data.module,
        action.data.currentForm,
        { settingsPresets: defaultPresetsState },
        action.data.currentPreset,
      );
    default:
      return state;
  }
}
