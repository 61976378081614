import * as types from './constants';
import * as settingsPresetsActions from 'state/settingsPreset/settingsPresetActions';
import { getSettings } from 'state/tabs/tab/settings';
import { getModule } from 'state/tabs/tab/module';
import { getForm } from 'state/tabs/tab/form';
import { getPresetByTitle } from 'state/settingsPreset/defaultPresetsState';
import { setSettings } from 'state/appSettings/base/actions';
import { getAppSettings } from 'state/appSettings/base';

export function setSelectedTab(index) {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_SELECTED_TAB, index });
    const state = getState();
    const form = getForm(state);
    const module = getModule(state);
    const settings = getSettings(state);
    const preset = settings && settings.title ? getPresetByTitle(module, form, settings.title) : '';
    dispatch(settingsPresetsActions.setSettingsValue({
      module,
      currentForm: form,
      currentPreset: preset,
      value: settings,
    }));
    dispatch(settingsPresetsActions.setCurrentSettingsPreset(preset));
    if (getAppSettings(state).isAutoZoomOn) {
      dispatch(setSettings({ adjustViewport: true }));
    }
  };
}
