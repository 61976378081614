const NS = 'ifModifiedSince';

export const TOGGLE_IF_MODIFIED_SINCE = `${NS}/TOGGLE_IF_MODIFIED_SINCE`;
export const SET = `${NS}/SET`;

const getDefaultState = () => ({
  enabled: false,
  date: '',
});

export default function (state = getDefaultState(), action) {
  switch (action.type) {
    case TOGGLE_IF_MODIFIED_SINCE:
      return { ...state, enabled: action.value };
    case SET:
      return { ...state, date: action.value };
    default:
      return state;
  }
}
