import formConfig from 'config/form';
import utils from 'utils';
import numericsValidation from '../../validation/numericsValidation';

let config = formConfig();

export function metricSystemsValidation(value) {
  let metricSystemValues = config.metricsystems.map(metricsystem => metricsystem.value);
  if (new RegExp(`^(${metricSystemValues.join('|')})$`).test(value)) {
    return [];
  }
  return ['Supported values for metricsystems are metric, imperial'];
}

export function listValidation(key, validatedValues) {
  return value => {
    let unsupportedValues = utils.difference(value.split(','), validatedValues);

    if (utils.isEmpty(unsupportedValues)) {
      return [];
    }
    if (unsupportedValues.length > 1) {
      return [`Values '${unsupportedValues}' are not supported by ${key}.`];
    }
    return [`Value '${unsupportedValues}' is not supported by ${key}.`];
  };
}

export function boolValidation(value) {
  if (!/^(true|false|1|0)$/.test(value)) {
    return ['Value should be true or false'];
  }
  return [];
}

export function enumValidation(key, validatedValues) {
  return value => {
    if (validatedValues.map(v => v.toLowerCase()).indexOf(value.toLowerCase()) === -1) {
      return [`Value '${value}' is not supported by ${key}.`];
    }
    return [];
  };
}

export function generalisationTolerancesValidation(value) {
  if (!numericsValidation(value)) {
    return [`Invalid generalisationTolerances value '${value}'`];
  }

  return [];
}
