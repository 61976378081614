import Immutable from 'immutable';

import * as types from './constants';
import * as formDataTypes from 'state/tabs/tab/formData/constants';
import { isWaypointEmpty } from 'utils/waypoint';

import { pointTemplate } from './defaultState';

import { isCoordValid } from 'utils/validation/coordsValidation';
import { isLinkValid } from 'utils/validation/linkValidation';

import utils from 'utils';
import numericValidation from 'utils/validation/numericValidation';

function formatItem(item) {
  [
    { key: 'coords', validate: isCoordValid },
    { key: 'displayPosition', validate: isCoordValid },
    { key: 'link', validate: isLinkValid },
    { key: 'stopOverDuration', validate: numericValidation() },
    { key: 'heading', validate: numericValidation({ min: 0, max: 360 }) },
  ].forEach(obj => {
    item = item.update(obj.key, data => (data ? data.merge({ isValid: obj.validate(data.get('value')) }) : data));
  });
  return item;
}

function resetStreet(data) {
  data.value.street = pointTemplate.street;
  data.value.streetSuggestions = pointTemplate.streetSuggestions;
}

function updateItem(state, data) {
  let index = data.index;
  if (index === -1) {
    index = state.length - 1;
  }
  if (data.value.category || data.value.coords) {
    resetStreet(data);
  }

  return Immutable.fromJS(state).update(index, item => formatItem(item.merge(data.value))).toJS();
}

export function waypointsData(state, action) {
  let { data } = action;
  if (state === undefined) {
    state = action.defaultState;
  }

  switch (action.type) {
    case types.SET_STREET:
    case types.UPDATE: {
      return updateItem(state, data);
    }

    case types.SET: {
      return [...data.waypoints];
    }

    case types.ADD: {
      if (data.index === undefined) {
        let emptyWaypointIndex = utils.findIndex(state, isWaypointEmpty);
        if (emptyWaypointIndex !== -1) {
          data.index = emptyWaypointIndex;
          return updateItem(state, data);
        }
      }

      let item = Immutable.fromJS(pointTemplate).merge(data.value);
      let index = data.index === undefined ? state.length - 1 : data.index;
      return Immutable.fromJS(state).insert(index, formatItem(item)).toJS();
    }

    case types.REMOVE:
      return Immutable.fromJS(state).delete(data.index).toJS();

    case types.CHANGE_ORDER: {
      let sorted = action.data.order.map(index => state[index]);
      let result = Immutable.fromJS(sorted);
      if (action.data.properties) {
        result = result.mergeDeep(action.data.properties);
      }
      return result.toJS();
    }

    case types.REVERSE: {
      return Immutable.fromJS(state).reverse().toJS();
    }

    case formDataTypes.RESTORE_FROM_URL: {
      let waypoints = utils.getObject(action.data.value, `waypoints`);
      if (Array.isArray(waypoints)) {
        return waypoints.map(item => formatItem(Immutable.fromJS(item)).toJS());
      }
      return state;
    }

    default:
      return state;
  }
}

export default waypointsData;
