const CATEGORY = 'geo!';
const CATEGORY_REGEXP = new RegExp(`^${CATEGORY}`);

export default function pointSplit(value) {
  return {
    category: value.match(CATEGORY_REGEXP) ? CATEGORY : '',
    coords: {
      value: value.replace(CATEGORY_REGEXP, ''),
      isValid: false,
    },
  };
}
