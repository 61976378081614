export default function maneuversList(list = []) {
  let store = {},
    linesMap = {},
    ids = [],
    _this = null;

  list.forEach(item => {
    store[item.id] = item;
    ids.push(item.id);
    if (item.line) {
      if (!Array.isArray(linesMap[item.line])) {
        linesMap[item.line] = [];
      }

      linesMap[item.line].push(item.id);
    }
  });

  _this = {
    slice: (startId = ids[0], endId) => {
      let startIndex = ids.indexOf(startId),
        endIndex = ids.indexOf(endId),
        _ids = ids.slice(startIndex, endIndex === -1 ? undefined : endIndex + 1);
      return _ids.map(id => store[id]);
    },

    groupShapesByLines: (lines = []) => {
      let result = [],
          lastPoint = _this.slice().slice(-1)[0].lastPoint,
          getStartPoint = (line) => {
            let pointId = linesMap[line.id][0];
            return store[pointId].firstPoint;
          },

          getEndPoint = (line) => {
            let pointId = linesMap[line.id].slice(-1)[0];
            return store[pointId].lastPoint;
          };

      lines.forEach((line, idx) => {
        let start = getStartPoint(line),
            end = getEndPoint(line),
            isFirstLineNotContainStartPoint = idx === 0 && start !== 0,
            isLineNotStartFromPrevious = idx > 0 && getEndPoint(lines[idx - 1]) !== start,
            isLastLineNotContainEndPoint = idx === lines.length - 1 && end !== lastPoint,
            iconType = null;
        if (isFirstLineNotContainStartPoint || isLineNotStartFromPrevious) {
          let startIndex = isFirstLineNotContainStartPoint ? 0 : getEndPoint(lines[idx - 1]);
          if (isLineNotStartFromPrevious) {
            iconType = 'leave';
          }

          result.push({ start: startIndex, end: start, iconType });
          iconType = 'enter';
        }

        result.push({ start, end, color: line.lineForeground, id: line.id, iconType: iconType || 'change' });

        if (isLastLineNotContainEndPoint) {
          result.push({ start: getEndPoint(line), end: lastPoint, iconType: 'leave' });
        }
      });
      return result;
    },
  };

  return _this;
}
