import React from 'react';
import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getInspectLinkSettings, setInspectLinkSettings } from 'state/appSettings/inspectLink/reducer';
import { getOlsSearchSettings, setOlsSearchSettings } from 'state/appSettings/olsSearch/reducer';
import credentials from 'config/credentials';
import './styles/appSettings.scss';
import adjustLUIStyle from 'utils/adjustLUIStyle';

const defaultInspectLinkCredentials = credentials.INSPECT_LINK;
const defaultOlsSearchCredentials = credentials.OLS_SEARCH;

class AppSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inspectLinkSettings: props.inspectLinkSettings,
      olsSearchSettings: props.olsSearchSettings,
    };
  }

  componentDidMount() {
    adjustLUIStyle(document.querySelectorAll('.rf-settings-form__app-settings lui-button'), 'lui-button');
  }

  onSave = () => {
    const { setInspectLinkSettings, setOlsSearchSettings } = this.props;
    const { inspectLinkSettings, olsSearchSettings } = this.state;

    setInspectLinkSettings({
      ...inspectLinkSettings,
    });
    setOlsSearchSettings({
      ...olsSearchSettings,
    });
  }

  onChange = (settingName, key, e) => {
    this.setState((prevState) => ({
      [settingName]: {
        ...prevState[settingName],
        [key]: e.target.value,
      },
    }));
  }

  resetInspectLinkCredentials = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ inspectLinkSettings: { ...defaultInspectLinkCredentials } });
  }

  resetOlsSearchCredentials = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ olsSearchSettings: { ...defaultOlsSearchCredentials } });
  }

  render() {
    const { inspectLinkSettings, olsSearchSettings } = this.state;
    const isDisabled = _.isEqual(inspectLinkSettings, this.props.inspectLinkSettings) &&
      _.isEqual(olsSearchSettings, this.props.olsSearchSettings);
    const canResetInspectLink = !_.isEqual(defaultInspectLinkCredentials, inspectLinkSettings);
    const canResetOlsSearch = !_.isEqual(defaultOlsSearchCredentials, olsSearchSettings);

    return (
      <div className="rf-settings-form__app-settings">
        <h3>Inspect link credentials</h3>
        <FormRow>
          <CustomInput
            label="App ID"
            value={inspectLinkSettings.appId}
            onChange={this.onChange.bind(this, 'inspectLinkSettings', 'appId')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="App Code"
            value={inspectLinkSettings.appCode}
            onChange={this.onChange.bind(this, 'inspectLinkSettings', 'appCode')}
            blurOnEnter
          />
        </FormRow>
        {canResetInspectLink && <FormRow>
          <div className="rf-settings-form__warning">
            <lui-button secondary onClick={this.resetInspectLinkCredentials}>Reset Credentials</lui-button>
          </div>
        </FormRow>}

        <hr />
        <h3>OLS Search credentials</h3>
        <FormRow>
          <CustomInput
            label="API Key"
            value={olsSearchSettings.apiKey}
            onChange={this.onChange.bind(this, 'olsSearchSettings', 'apiKey')}
            blurOnEnter
          />
        </FormRow>
        {canResetOlsSearch && <FormRow>
          <div className="rf-settings-form__warning">
            <lui-button secondary onClick={this.resetOlsSearchCredentials}>Reset Credentials</lui-button>
          </div>
        </FormRow>}

        <div className="rf-settings-form__action-btn-group">
          <lui-button type="cancel" secondary onClick={this.props.close}>Cancel</lui-button>
          <lui-button type="save" disabled={isDisabled} onClick={this.onSave}>Save</lui-button>
        </div>
      </div>
    );
  }
}

AppSettings.propTypes = {
  inspectLinkSettings: PropTypes.object.isRequired,
  setInspectLinkSettings: PropTypes.func.isRequired,
  olsSearchSettings: PropTypes.object.isRequired,
  setOlsSearchSettings: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  inspectLinkSettings: getInspectLinkSettings(state),
  olsSearchSettings: getOlsSearchSettings(state),
});

const mapDispatchToProps = {
  setInspectLinkSettings,
  setOlsSearchSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppSettings);
