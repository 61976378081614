import React from 'react';

import CustomComponent from 'shared/component';

import './styles/sidebar.scss';

class Sidebar extends CustomComponent {
  render() {
    return (
      <div className={this.getCssClasses(['rf-sidebar'])} onClick={this.props.onClick}>
        <div ref="container" className="rf-sidebar__inner">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Sidebar;
