import utils from 'utils';

const PLEURAL = {
  routes: 'route',
  incidents: 'incident',
  labels: 'label',
  legs: 'leg',
  maneuverGroups: 'maneuverGroup',
  publicTransportLines: 'publicTransportLine',
  notes: 'note',
  waypoints: 'waypoint',
  links: 'link',
  maneuvers: 'maneuver',
};

export function normalizeRoutingData(data) {
  return normalizeRoutingDataRecursively(addWrapper(data));
}

export function normalizeRoutingDataRecursively(data, saveTo) {
  let isArray = utils.isArray(data);
  if (saveTo === undefined) {
    saveTo = isArray ? [] : {};
  }
  utils.iterObject(data, (val, key) => {
    let newKey = utils.lowerFirst(key);
    if (PLEURAL[newKey]) {
      newKey = PLEURAL[newKey];
    }
    if (utils.isObject(val) || utils.isArray(val)) {
      if (newKey === 'shape' && utils.isArray(val)) {
        let flattenShape = [];
        val.forEach(shape => {
          if (utils.isString(shape)) {
            flattenShape = flattenShape.concat(shape.split(','));
          } else {
            flattenShape.push(shape);
          }
        });
        saveTo.shape = flattenShape;
      } else if (saveTo[newKey] !== undefined) {
        val = normalizeRoutingDataRecursively(val, saveTo[newKey]);
      } else {
        val = normalizeRoutingDataRecursively(val);
        saveTo[newKey] = val;
      }
    } else {
      saveTo[newKey] = val;
    }
  });
  return saveTo;
}

export function addWrapper(result) {
  let data = result.data;
  if (!result.isError) {
    if (!data.response && !data.Response) {
      data.response = {};
    }
    let response = data.response || data.Response;
    utils.iterObject(data, (val, key) => {
      if (key.toLowerCase() === 'response') {
        return;
      }
      response[key] = val;
      delete data[key];
    });
  }
  return result;
}
