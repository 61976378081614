import React from 'react';

import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';

import utils from 'utils';

export function getInputEl(args = {}, idx) {
  let { key, label, ctx, fn, ns, units = '' } = args,
      value = utils.isFunction(fn) ? fn(ctx[key]) : ctx[key];
  return (
    <FormRow isHidden={value == null} key={`${ns}${idx}`}>
      <CustomInput
        label={label}
        isReadonly
        value={`${value} ${units}`}
      />
    </FormRow>
  );
}
