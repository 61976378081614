import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomInput from 'shared/input';
import FormRow from 'shared/formRow';

import './styles/productsField.scss';

export class TransitModeField extends Component {

  getValue() {
    let { value = {} } = this.props;
    if (!Array.isArray(value.transportModes)) {
      return '';
    }

    return {
      publicTransportTimeTable: 'Timetable',
      publicTransport: 'Estimated',
    }[value.transportModes[0]] || '';
  }

  render() {
    let { label, isReadonly } = this.props,
      value = this.getValue();

    return (
      <FormRow isHidden={!value}>
        <CustomInput
          type="text"
          label={label}
          isReadonly={isReadonly}
          value={value}
        />
      </FormRow>
    );
  }
}

TransitModeField.defaultProps = {
  isReadonly: false,
};

TransitModeField.propTypes = {
  label: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  value: PropTypes.object.isRequired,
};

export default TransitModeField;
