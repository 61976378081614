export default function getUpdatedParams(keys, queryBase, queryOther = '') {
  let store = {},
      fillStore = query => {
        query.split('&').forEach(param => {
          let [key, value] = param.split('=');
          store[key] = value;
        });
      },

      generateUrlPart = (key) => `${key}=${store[key]}`,

      fetchByRegexp = regexp => {
        let storeKeys = Object.keys(store).filter(key => key.match(regexp));
        return storeKeys.map(generateUrlPart).join('&');
      };

  fillStore(queryBase);
  fillStore(queryOther);

  return keys
    .map(key => {
      if (key instanceof RegExp) {
        return fetchByRegexp(key);
      }

      return store[key] !== undefined ? generateUrlPart(key) : null;
    })
    .filter(Boolean)
    .join('&');
}
