import StreetItemBase from '../../../common/waypointsGroup/StreetItem';
import DataSwitcher from './DataSwitcher';

class StreetItem extends StreetItemBase {
  getDataSwitcherEl() {
    return DataSwitcher;
  }
}

export default StreetItem;
