import utils from 'utils';

import * as mapMarkers from '../../../utils/MarkersHelper';
import ModuleBase from '../../../modules/_Base';

export default class MatrixMarkers extends ModuleBase {
  constructor(...args) {
    super(...args);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.getGroup().addEventListener('tap', this.onMarkerClick, false);
  }

  initHandlers(handlers = {}) {
    this.handlers = handlers;
    return this;
  }

  destroy() {
    this.getGroup().removeEventListener('tap', this.onMarkerClick);
    super.destroy();
  }

  process(nextProps) {
    this.clearGroups();
    this.renderMarkers(nextProps);
  }

  onDragStart() {
    this.map.getElement().style.cursor = 'move';
  }

  onDragEnd(data, value) {
    let { key, index } = data,
        coords = { value };
    this.props.updatePointMatrix({ key, index, value: { coords } });
  }

  onMarkerClick(e) {
    let handler = this.handlers.onMarkerClick;
    if (utils.isFunction(handler)) {
      handler(e.target.getData());
    }
  }

  renderMarkers(nextProps) {
    let group = this.getGroup();
    ['start', 'destination'].forEach(key => {
      let { points = {} } = nextProps.tabData.formData,
          title = key[0].toUpperCase(),
          markers = [];
      markers = (points[key] || [])
      .filter(item => item.coords.value && item.coords.isValid)
      .map((item, index) => {
        let marker = mapMarkers.createMainMarker({
          point: {
            value: item.coords.value,
          },
          title: `${title}${index}`,
          color: nextProps.tabData.tabColorPalette.primary,
          strokeColor: nextProps.tabData.tabColorPalette.primaryDarker,
          fontSize: 11,
        });
        if (this.formState.index === nextProps.selectedTab) {
          marker.draggable = true;
        }
        marker.setData(Object.assign({}, marker.getData() || {}, { key, index, type: 'MAIN' }));
        return marker;
      });

      if (markers.length) {
        group.addObjects(markers);
      }
    }, this);
  }
}
