import * as types from './constants';
import defaultState from './defaultState';

function highlightedManeuver(state = defaultState, action) {
  switch (action.type) {
    case types.SET:
      return Object.assign({}, state, action.data);

    default:
      return state;
  }
}

export default highlightedManeuver;
