import React from 'react';
import PropTypes from 'prop-types';
import ResultPanelFormBase from '../../_ResultPanelFormBase';
import CustomTabs from '../../../shared/tabs';
import Maneuvers from './Maneuvers';
import ResultRouteLegendTable from './ResultRouteLegendTable';
import formsEnum from '../../../../config/formsEnum';
import './styles/olsApiResults.scss';
import OlsSummary from './OlsSummary';
import CustomChart from 'shared/chart';
import Tree from 'shared/tree';
import pointMarkerTemplate from '../../../map/utils/PointMarkerTemplate';
import utils from 'utils';
import { parseTurnByTurnActions, parseSpans, parseManeuvers } from './parsers';
import CheckboxAttributesSet from '../../../forms/common/CheckboxAttributesSet';
import { decode } from '../../../../utils/flexPolyline';

const RESPONSE_PATH = 'responseData.data';
const INIT_SELECTED_MANEUVER = {
  shape: null,
  index: null,
};

export default class OlsApiResult extends ResultPanelFormBase {
  state = {
    selectedProfiles: [],
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    const response = utils.getObject(nextProps, 'responseData.data.response');
    const oldResponse = prevState.response;
    if (!utils.isEqual(response, oldResponse)) { // reset zoom data before chart reload
      nextState.zoomStart = undefined;
      nextState.zoomEnd = undefined;
      nextState.response = response;
    }
    return nextState;
  }

  constructor(props) {
    super(props);
    this.onMouseOutElevationChart = utils.debounce(this.onMouseOutElevationChart, 100);
    this.onElevationSelected = utils.debounce(this.onElevationChartEvent, 100);
    this.onElevationHovered = utils.debounce(this.onElevationChartEvent, 100);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const evEnabled = utils.getObject(nextProps, 'formData.fields.evEnabled');
    if (!evEnabled) {
      let selectedProfiles = [...nextState.selectedProfiles];
      const evIndex = nextState.selectedProfiles.indexOf('ev');
      if (evIndex > -1) {
        selectedProfiles.splice(evIndex, 1);
      }
      const evPerKmIndex = nextState.selectedProfiles.indexOf('e_per_km');
      if (evPerKmIndex > -1) {
        selectedProfiles.splice(evPerKmIndex, 1);
      }
      if (selectedProfiles.length !== nextState.selectedProfiles.length) {
        this.setState({ selectedProfiles });
        return false;
      }
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { filterValueUpdate } = this.props;
    const response = utils.getObject(this.props, RESPONSE_PATH);
    const oldResponse = utils.getObject(prevProps, RESPONSE_PATH);
    if (!utils.isEqual(response, oldResponse)) {
      filterValueUpdate({ currentForm: formsEnum.OLS, key: 'selectedManeuver', value: INIT_SELECTED_MANEUVER });
      filterValueUpdate({ currentForm: formsEnum.OLS, key: 'selectedSpan', value: INIT_SELECTED_MANEUVER });
      filterValueUpdate({ currentForm: formsEnum.OLS, key: 'selectedTurnByTurnAction', value: INIT_SELECTED_MANEUVER });
    }
  }

  onProfileChanged = (values, e) => {
    if (values.length > 2) {
      this.props.setNotification({
        message: 'Only two profiles could be chosen',
        impact: 'negative',
        autoDismiss: 5,
      });
      e.currentTarget.checked = false;
      // workaround for LUI:
      e.currentTarget.shadowRoot.querySelector('input').checked = false;
      return;
    }
    if (values.indexOf('speed') > -1 && this.state.selectedProfiles.indexOf('speed') === -1) {
      this.props.filterValueUpdate({
        key: 'spans',
        currentForm: formsEnum.OLS,
        value: utils.uniq([...this.props.formData.fields.spans, 'dynamicSpeedInfo', 'length']),
        needsRerender: true,
      });
      this.props.setNotification({
        children: <div>
          The following span params were automatically enabled by this action:
          <ul>
            <li>Length</li>
            <li>Dynamic Speed Info</li>
          </ul>
        </div>,
        impact: 'significant',
      });
    }
    if (values.indexOf('ev') > -1 && this.state.selectedProfiles.indexOf('ev') === -1) {
      this.props.filterValueUpdate({
        key: 'olsReturn',
        currentForm: formsEnum.OLS,
        value: utils.uniq([...this.props.formData.fields.olsReturn, 'summary']),
        needsRerender: true,
      });
      this.props.setNotification({
        children: <div>
          <b>Summary</b> return param was automatically enabled by this action
        </div>,
        impact: 'significant',
      });
    }
    if (values.indexOf('e_per_km') > -1 && this.state.selectedProfiles.indexOf('e_per_km') === -1) {
      this.props.filterValueUpdate({
        key: 'spans',
        currentForm: formsEnum.OLS,
        value: utils.uniq([...this.props.formData.fields.spans, 'consumption', 'length']),
        needsRerender: true,
      });
      this.props.setNotification({
        children: <div>
          The following span params were automatically enabled by this action:
          <ul>
            <li>Length</li>
            <li>Consumption</li>
          </ul>
        </div>,
        impact: 'significant',
      });
    }
    if (values.indexOf('elevation') > -1 && this.state.selectedProfiles.indexOf('elevation') === -1) {
      this.props.filterValueUpdate({
        key: 'olsReturn',
        currentForm: formsEnum.OLS,
        value: utils.uniq([...this.props.formData.fields.olsReturn, 'elevation']),
        needsRerender: true,
      });
      this.props.setNotification({
        children: <div>
          <b>Elevation</b> return param was automatically enabled by this action
        </div>,
        impact: 'significant',
      });
    }
    if (values.indexOf('speedLimit') > -1 && this.state.selectedProfiles.indexOf('speedLimit') === -1) {
      this.props.filterValueUpdate({
        key: 'spans',
        currentForm: formsEnum.OLS,
        value: utils.uniq([...this.props.formData.fields.spans, 'speedLimit', 'length']),
        needsRerender: true,
      });
      this.props.setNotification({
        children: <div>
          The following span params were automatically enabled by this action:
          <ul>
            <li>length</li>
            <li>speedLimit</li>
          </ul>
        </div>,
        impact: 'significant',
      });
    }
    if (values.indexOf('frc') > -1 && this.state.selectedProfiles.indexOf('frc') === -1) {
      this.props.filterValueUpdate({
        key: 'spans',
        currentForm: formsEnum.OLS,
        value: utils.uniq([...this.props.formData.fields.spans, 'functionalClass', 'length']),
        needsRerender: true,
      });
      this.props.setNotification({
        children: <div>
          The following span params were automatically enabled by this action:
          <ul>
            <li>length</li>
            <li>functionalClass</li>
          </ul>
        </div>,
        impact: 'significant',
      });
    }
    this.setState({ selectedProfiles: values });
  }

  onMouseOutElevationChart = () => {
    this.props.mapObjectUpdate({
      key: 'hoveredPoint',
      currentForm: this.props.currentForm,
      value: '',
    });
  }

  onElevationChartEvent = (data, key, obj) => {
    const startIndex = obj.index * 3,
      point = data.shape.slice(startIndex, startIndex + 3);

    this.props.mapObjectUpdate({
      key,
      currentForm: this.props.currentForm,
      value: point.slice(0, 2).join(','),
    });
  }

  prepareElevationData(data) {
    let result = { x: [], y: [] },
      distance = 0;
    let p1;
    if (data.shape[0] && data.shape[1]) {
      p1 = new H.geo.Point(data.shape[0], data.shape[1]);
    }

    for (let i = 2; i < data.shape.length; i += 3) {
      let p2 = new H.geo.Point(data.shape[i - 2], data.shape[i - 1]);

      distance += p1.distance(p2) / 1000;

      result.x.push(distance);
      result.y.push(data.shape[i]);
      p1 = p2;
    }

    return result;
  }

  getResponseContent = () => {
    const response = utils.getObject(this.props, 'responseData.data.response');
    if (!response) {
      return null;
    }
    return this.getResponseTabContent(response);
  }

  getSpansContent = (route) => {
    const { formData: { fields } } = this.props;
    const spans = parseSpans(route);

    if (!spans || spans.length === 0) {
      return (
        <div className="rf-spans-empty">
          No information about spans was requested from server.<br />
          In order to fetch this information, please
          select<br /> <b>Spans</b>
        </div>
      );
    }

    return (
      <Maneuvers maneuvers={spans} onClick={this.highlightSpan} selectedManeuver={fields.selectedSpan || {}} />
    );
  }

  getManeuversContent = (route) => {
    const { formData: { fields } } = this.props;
    const maneuvers = parseManeuvers(route);

    if (!maneuvers || maneuvers.length === 0) {
      return (
        <div className="rf-maneuvers-empty">
          No information about maneuvers was requested from server.<br />
          In order to fetch this information, please
          select<br /> <b>Return options -&gt; Actions</b>
        </div>
      );
    }

    return (
      <Maneuvers
        maneuvers={maneuvers}
        onClick={this.highlightManeuver}
        selectedManeuver={fields.selectedManeuver}
      />
    );
  }

  getTurnByTurnActionsContent = (route) => {
    const { formData: { fields } } = this.props;
    const actions = parseTurnByTurnActions(route);

    if (!actions || actions.length === 0) {
      return (
        <div className="rf-empty">
          No information about maneuvers was requested from server.<br />
          In order to fetch this information, please
          select<br /> <b>Return options -&gt; Turn by turn actions</b>
        </div>
      );
    }

    return (
      <Maneuvers
        maneuvers={actions}
        onClick={this.highlightTurnByTurnAction}
        selectedManeuver={fields.selectedTurnByTurnAction}
      />
    );
  }

  onZoomChart = ([zoomStart, zoomEnd]) => {
    this.setState({ zoomStart, zoomEnd });
  }

  formatProfileData(distanceData, valuesData, type) {
    const formattedDistanceData = [];
    const formattedValuesData = [];
    if (distanceData.length && valuesData.length) {
      distanceData.forEach((distance, index) => {
        const value = valuesData[index];
        const formattedLength = formattedDistanceData.length;
        if (type === 'step' && formattedValuesData[formattedLength - 1] === value && index < distanceData.length - 1) {
          return;
        } else if (formattedValuesData[formattedLength - 1] === value &&
          formattedValuesData[formattedLength - 2] === value) {
          formattedDistanceData.length--;
          formattedValuesData.length--;
        }
        formattedDistanceData.push(distance);
        formattedValuesData.push(value);
      });
    }
    return { formattedDistanceData, formattedValuesData };
  }

  getEVTabContent(route) {
    const evEnabled = utils.getObject(this.props, 'formData.fields.evEnabled');
    const { maxChargeAfterChargingStation, minChargeAtChargingStation, maxCharge } = this.props.formData.fields;
    const isEVProfileEnabled = this.state.selectedProfiles.indexOf('ev') > -1;
    const isSpeedProfileEnabled = this.state.selectedProfiles.indexOf('speed') > -1;
    const isSpeedLimitProfileEnabled = this.state.selectedProfiles.indexOf('speedLimit') > -1;
    const isFRCProfileEnabled = this.state.selectedProfiles.indexOf('frc') > -1;
    const isEnergyPerKmProfileEnabled = this.state.selectedProfiles.indexOf('e_per_km') > -1;
    const isElevationProfileEnabled = this.state.selectedProfiles.indexOf('elevation') > -1;
    const waypointsData = [{ distance: 0 }];

    let currentLength = 0;
    const lengthData = [];
    const consumptionData = [];

    const spanSpeedDistanceData = [];
    const spanSpeedLimitDistanceData = [];
    const spanFRCDistanceData = [];
    const speedData = [];
    const speedLimitData = [];
    const functionalClassData = [];
    let currentPower = 0;
    const labelsIndexes = [];

    const energyPerMeterData = [];
    let currentSpanDistance = 0;

    route.sections.forEach(section => {
      lengthData.push(currentLength);
      consumptionData.push(+utils.getObject(section, 'departure.charge', 0).toPrecision(5));
      labelsIndexes.push(consumptionData.length - 2);
      currentLength += utils.getObject(section, 'summary.length', 0);
      lengthData.push(currentLength);
      consumptionData.push(+utils.getObject(section, 'arrival.charge', 0).toPrecision(5));
      labelsIndexes.push(consumptionData.length - 2);

      const chargingStation = utils.getObject(section, 'postActions', [])
        .find(postAction => postAction.action === 'charging');

      const consumablePower = utils.getObject(chargingStation, 'consumablePower', null);

      waypointsData.push({
        distance: currentLength,
        power: consumablePower,
      });

      currentPower = +section.departure.charge;

      if (section.spans) {
        section.spans.forEach(span => {
          if (!span.length) {
            return;
          }
          currentSpanDistance += span.length;
          if (span.consumption !== undefined) {
            // Calculate Consumption (from spans)
            currentPower -= span.consumption;
            const power = +currentPower.toPrecision(5);
            // avoid duplicates:
            if (!lengthData.find((val, index) => val === currentSpanDistance && consumptionData[index] === power)) {
              consumptionData.push(power);
              lengthData.push(currentSpanDistance);
            }

            // Calculate Energy per KM
            const consumptionPerMeter = span.consumption / span.length;
            for (let i = 0; i < span.length; i++) {
              energyPerMeterData.push(consumptionPerMeter);
            }
          }

          // Calculate Speed Profile
          if (span.dynamicSpeedInfo !== undefined) {
            spanSpeedDistanceData.push(currentSpanDistance);
            speedData.push(span.dynamicSpeedInfo.baseSpeed);
          }
          // Calculate Speed Limit Profile
          if (span.speedLimit !== undefined) {
            spanSpeedLimitDistanceData.push(currentSpanDistance);
            speedLimitData.push(span.speedLimit);
          }
          // Calculate Functional Class Profile
          if (span.functionalClass !== undefined) {
            spanFRCDistanceData.push(currentSpanDistance);
            functionalClassData.push(span.functionalClass);
          }
        });
      }
    });

    const energyPerKmData = utils.chunk(energyPerMeterData, 1000)
      .map(meters => meters.reduce((tatal, val) => tatal + val, 0));

    // Calculate Elevation Profile
    const shape = this.getShape(route);
    const elevationValues = this.prepareElevationData({ shape });

    const profiles = {
      ev: { name: 'energy', label: 'Energy (kWh)' },
      speed: { name: 'speed', label: 'Speed (m/s)' },
      e_per_km: { name: 'energy per km', label: 'Energy per km' },
      elevation: { name: 'elevation', label: 'Elevation (m)' },
      speedLimit: { name: 'speed limit', label: 'Speed Limit (m/s)' },
      frc: { name: 'functional class', label: 'Functional Class' },
    };

    const selectedProfiles = this.state.selectedProfiles.map(selectedProfile => profiles[selectedProfile]);
    const axes = {};
    if (selectedProfiles.length > 0) {
      axes[selectedProfiles[0].name] = 'y';
      if (selectedProfiles.length > 1) {
        axes[selectedProfiles[1].name] = 'y2';
      }
    }

    const colors = {};
    if (selectedProfiles.length) {
      colors[selectedProfiles[0].name] = '#0000cd';
    }
    if (selectedProfiles.length > 1) {
      colors[selectedProfiles[1].name] = '#cc6600';
    }

    const columnsData = [
      {
        distance: isEVProfileEnabled ? lengthData : [],
        values: isEVProfileEnabled ? consumptionData : [],
        xName: 'distance',
        yName: 'energy',
      },
      {
        distance: isSpeedProfileEnabled ? spanSpeedDistanceData : [],
        values: isSpeedProfileEnabled ? speedData : [],
        xName: 'speedDistance',
        yName: 'speed',
        type: 'step'
      },
      {
        distance: isSpeedLimitProfileEnabled ? spanSpeedLimitDistanceData : [],
        values: isSpeedLimitProfileEnabled ? speedLimitData : [],
        xName: 'speedLimitDistance',
        yName: 'speed limit',
        type: 'step'
      },
      {
        distance: isFRCProfileEnabled ? spanFRCDistanceData : [],
        values: isFRCProfileEnabled ? functionalClassData : [],
        xName: 'FRCDistance',
        yName: 'functional class',
        type: 'step'
      },
      {
        distance: isEnergyPerKmProfileEnabled ? utils.times(energyPerKmData.length, val => val * 1000) : [],
        values: isEnergyPerKmProfileEnabled ? energyPerKmData : [],
        xName: 'ePerKmDistance',
        yName: 'energy per km',
      },
      {
        distance: isElevationProfileEnabled ? elevationValues.x.map(val => val * 1000) : [],
        values: isElevationProfileEnabled ? elevationValues.y : [],
        xName: 'elevationDistance',
        yName: 'elevation',
      },
    ];

    const columns = [];
    const xs = {};
    const types = {};
    columnsData.forEach(data => {
      const { formattedDistanceData, formattedValuesData } =
        this.formatProfileData(data.distance, data.values, data.type);
      columns.push([data.xName].concat(formattedDistanceData));
      columns.push([data.yName].concat(formattedValuesData));
      xs[data.yName] = data.xName;
      if (data.type) {
        types[data.yName] = data.type;
      }
    });

    const chartData = {
      xs,
      selection: {
        enabled: false,
        multiple: false,
      },
      columns,
      types,
      axes,
      colors,
      labels: {
        format: {
          energy: (val, id, key) => {
            if (labelsIndexes.indexOf(key) === -1) {
              return '';
            }
            return (val ? +val.toFixed(2) : val);
          },
        }
      }
    };

    const axis = {
      x: {
        label: {
          text: 'Distance (km)',
          position: 'inner-center',
        },
        tick: {
          count: 10,
          format: x => (x / 1000).toFixed(),
        },
      },
    };

    if (selectedProfiles.length > 0) {
      axis.y = {
        label: {
          text: selectedProfiles[0].label,
          position: 'inner-bottom',
        },
      };
      if (selectedProfiles[0].name === 'ev') {
        axis.y.max = +maxCharge.value;
      } else if (selectedProfiles[0].name === 'functional class') {
        axis.y.min = 1;
        axis.y.max = 5;
        axis.y.inverted = true;
        axis.y.tick = {
          values: [1, 2, 3, 4, 5],
          count: 5,
        };
      }
    }

    if (selectedProfiles.length > 1) {
      axis.y2 = {
        show: true,
        label: {
          text: selectedProfiles[1].label,
          position: 'inner-bottom',
        },
      };
      if (selectedProfiles[1].name === 'ev') {
        axis.y2.max = +maxCharge.value;
      } else if (selectedProfiles[1].name === 'functional class') {
        axis.y2.min = 1;
        axis.y2.max = 5;
        axis.y2.inverted = true;
        axis.y2.tick = {
          values: [1, 2, 3, 4, 5],
          count: 5,
        };
      } else if (utils.isEqual([selectedProfiles[0].name, selectedProfiles[1].name].sort(), ['speed', 'speed limit'])) {
        const max = Math.max(...speedData, ...speedLimitData);
        axis.y.max = max;
        axis.y2.max = max;
        axis.y.min = 0;
        axis.y2.min = 0;
      }
    }

    const options = {
      axis,
      grid: {},
      zoom: {
        enabled: true,
        rescale: false,
        onzoom: this.onZoomChart,
      },
      legend: { show: true },
      point: {
        show: false
      },
      line: {
        step: {
          type: 'step-after',
        }
      },

      tooltip: {
        format: {
          title: d => `Distance: ${+(d / 1000).toFixed(3)} km`,
        },
      },
    };

    if (isEVProfileEnabled) {
      const evAxis = selectedProfiles[0].name === 'energy' ? 'y' : 'y2';
      options.grid.y = {
        lines: [
          {
            value: maxCharge.value,
            text: 'Total battery capacity',
            class: 'color-grid-maxcharge',
            axis: evAxis,
          },
          {
            value: minChargeAtChargingStation.value,
            text: 'Min arrival energy',
            class: 'color-grid-minchargeatstop',
            axis: evAxis,
          },
          {
            value: maxChargeAfterChargingStation.value,
            text: 'Target battery charge',
            class: 'color-grid-chargingstopdeparturecharge',
            axis: evAxis,
          },
        ]
      };
    }

    let waypointNumber = 0;

    // Waypoint markers position calculation
    const widthInRem = 33.3;
    const lastWaypointDistance = waypointsData[waypointsData.length - 1].distance;
    const offset = lastWaypointDistance * 0.01;
    let { zoomStart = -offset, zoomEnd = lastWaypointDistance + offset } = this.state;
    zoomStart += offset;
    zoomEnd -= offset;
    const length = zoomEnd - zoomStart;
    const lengthPerRem = length / widthInRem;

    const checkboxOptions = [
      { value: 'ev', label: 'EV', disabled: !evEnabled },
      { value: 'speed', label: 'Speed' },
      { value: 'speedLimit', label: 'Speed Limit' },
      { value: 'e_per_km', label: 'Energy/km', disabled: !evEnabled },
      { value: 'frc', label: 'FRC' },
      { value: 'elevation', label: 'Elevation' },
    ];
    return (<>
        <lui-default-theme>
        <CheckboxAttributesSet
          label="Shown Profiles:"
          options={checkboxOptions}
          data={this.state.selectedProfiles}
          isExpanded
          onChange={this.onProfileChanged}
        />
        <CustomChart
          data={chartData}
          options={options}
        />
        <div className="rf-waipoints-line">
          {waypointsData.map((waypointData, index) => {
            let title = '&#x1f50b;';
            if (index === 0) {
              title = 'A';
            } else if (index === waypointsData.length - 1) {
              title = 'B';
            } else if (waypointData.power === null) {
              title = ++waypointNumber;
            }

            const currentWaypointDistance = waypointData.distance;
            if (currentWaypointDistance < zoomStart) {
              return null;
            }

            const relPosition = currentWaypointDistance - zoomStart;

            return <div
              style={{
                position: 'absolute',
                left: `${relPosition / lengthPerRem + 1}rem`,
              }}
              key={index}
            >
              <div
                className="Container"
                /* eslint-disable react/no-danger */
                dangerouslySetInnerHTML={{ __html: pointMarkerTemplate({ title }) }}
                /* eslint-enable react/no-danger */
              />
              {waypointData.power || ''}
            </div>;
          })}
        </div>
        </lui-default-theme>
      </>
    );
  }

  getShape = data => {
    let shape = [];
    data.sections.forEach(sectionData => {
      const encodedPolyline = sectionData.polyline;
      if (!encodedPolyline) {
        return;
      }
      const decodedPolyline = encodedPolyline ? decode(encodedPolyline) : [];
      shape = [...shape, ...utils.flatten(decodedPolyline)];
    });
    return shape;
  }

  getSummaryContent(route) {
    if (route) {
      return <OlsSummary
        route={route}
        formData={this.props.formData}
        addTab={this.props.addTab}
        filterValueMultiUpdate={this.props.filterValueMultiUpdate}
        setWaypoints={this.props.setWaypoints}
      />;
    } else {
     return <Tree data={this.props.responseData.data} shouldExpandNode={() => true} />;
    }
  }

  highlightManeuver = (shape, index) => {
    const { filterValueUpdate, formData: { fields } } = this.props;
    const value = fields.selectedManeuver.index === index ? INIT_SELECTED_MANEUVER : { shape, index };
    filterValueUpdate({ currentForm: formsEnum.OLS, key: 'selectedManeuver', value });
  }

  highlightSpan = (shape, index) => {
    const { filterValueUpdate, formData: { fields } } = this.props;
    const value = fields.selectedSpan.index === index ? INIT_SELECTED_MANEUVER : { shape, index };
    filterValueUpdate({ currentForm: formsEnum.OLS, key: 'selectedSpan', value });
  }

  highlightTurnByTurnAction = (shape, index) => {
    const { filterValueUpdate, formData: { fields } } = this.props;
    const value = fields.selectedTurnByTurnAction.index === index ? INIT_SELECTED_MANEUVER : { shape, index };
    filterValueUpdate({ currentForm: formsEnum.OLS, key: 'selectedTurnByTurnAction', value });
  }

  render() {
    const routes = utils.getObject(this.props, 'responseData.data.routes', []);
    const { formData: { currentRoute, highlightedRoute, fields } } = this.props;
    const route = routes[currentRoute];

    let tabs = [];
    tabs.push({ title: 'Summary', content: this.getSummaryContent(route) });

    if (route) {
      tabs.push({ title: 'Maneuvers', content: this.getManeuversContent(route) });
      if (fields.olsReturn.indexOf('turnByTurnActions') !== -1) {
        tabs.push({ title: 'TBT actions', content: this.getTurnByTurnActionsContent(route) });
      }

      if (this.props.currentForm === formsEnum.OLS) {
        tabs.push({
          title: 'Profiles',
          content: this.getEVTabContent(route)
        });
      }

      if (fields.spans && fields.spans.length > 0) {
        tabs.push({
          title: 'Spans',
          content: this.getSpansContent(route)
        });
      }
    }
    tabs.push({ title: 'Response', content: this.getResponseContent() });

    return (
      <div>
        <ResultRouteLegendTable
          responseData={routes}
          currentRoute={this.props.currentRoute}
          setRoute={this.props.setRoute}
          highlightedRoute={highlightedRoute}
          setHighlightRoute={this.props.setHighlightedRoute}
          setUnhighlightRoute={() => this.props.setHighlightedRoute(-1)}
          colorPalette={this.props.selectedTabData.tabColorPalette}
        />
        <CustomTabs cssClasses="rf-result-panel__tabs" data={tabs} />
        {this.getInspectLinkData()}
        {this.getReverseGeocodeData()}
        {this.getSearchResults()}
      </div>
    );
  }
}

OlsApiResult.propTypes = {
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  responseData: PropTypes.object.isRequired,
  onShowFormattedResult: PropTypes.func.isRequired,
  selectedTabData: PropTypes.object.isRequired,
  setRoute: PropTypes.func.isRequired,
  setHighlightedRoute: PropTypes.func.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  mapObjectUpdate: PropTypes.func.isRequired,
};
