import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import CustomButton from 'shared/button';
import CustomCheckbox from 'shared/checkbox';
import FormRow from 'shared/formRow';
import RadioGroup from 'shared/radioGroup';

import utils from 'utils';
import settingsUtils from 'utils/settings';
import defaultSettings from 'state/settingsPreset/defaultPresetsState';
import { getCurrentPresetFromSettingsData } from 'state/settingsPreset';

import './styles/settingsForm.scss';
import { AUTH_TYPE, HERE_TOKEN_ENDPOINTS } from './constants';
import CustomTabs from 'shared/tabs';
import AppSettings from './AppSettings';
import adjustLUIStyle from 'utils/adjustLUIStyle';

export class SettingsFormContainer extends Component {
  static getDerivedStateFromProps(nextProps) {
    return {
      settings: SettingsFormContainer.getCurrentSettings(nextProps),
      currentPreset: SettingsFormContainer.getCurrentPreset(nextProps),
    };
  }

  static getCurrentSettings(props, preset) {
    let { settingsData = {}, currentForm, module } = props;
    preset = preset || SettingsFormContainer.getCurrentPreset(props);
    return settingsUtils.getPresetSettings(module, currentForm, settingsData, preset);
  }

  static getCurrentPreset(props) {
    let { settingsData, module } = props;
    return getCurrentPresetFromSettingsData(settingsData, module);
  }

  constructor(props) {
    super(props);

    this.state = {
      settings: SettingsFormContainer.getCurrentSettings(this.props),
      currentPreset: SettingsFormContainer.getCurrentPreset(this.props),
      fetchingToken: false,
    };
  }

  componentDidMount() {
    adjustLUIStyle(document.querySelectorAll('.rf-settings-form__action-btn-group lui-button'), 'lui-button');
  }

  onChangeEnv = (e) => {
    let currentPreset = e.target.value;
    let settings = SettingsFormContainer.getCurrentSettings(this.props, currentPreset);
    this.setState({ currentPreset, settings });
  }

  onChange(field, e) {
    let { settings } = this.state;
    utils.setObject(settings, field, utils.extractData(e));
    this.setState({ settings });
  }

  onChangeSkipProxy = (e) => {
    let { settings } = this.state;
    utils.setObject(settings, 'urlQuery.skipProxy', utils.extractData(e));
    this.setState({ settings });
  };

  onChangeToken = (e) => {
    const { settings } = this.state;
    utils.setObject(settings, 'urlQuery.token', utils.extractData(e));
    // setting tokenExpiry to null in the case of manual token edit so check for expiry will be skipped during request
    utils.setObject(settings, 'urlQuery.tokenExpiry', null);
    this.setState({ settings });
  }

  onSaveSettings = () => {
    let { settings, currentPreset } = this.state;

    if (SettingsFormContainer.getCurrentPreset(this.props) !== currentPreset) {
      this.props.setCurrentSettingsPreset(currentPreset);
    }

    if (!utils.isEqual(SettingsFormContainer.getCurrentSettings(this.props, currentPreset), settings)) {
      this.props.setSettingsValue({
        currentPreset,
        currentForm: this.props.currentForm,
        value: this.state.settings,
      });
    }
    this.props.close();
    if ([AUTH_TYPE.APP_ID_AND_APP_CODE, AUTH_TYPE.APIKEY].indexOf(settings.urlQuery.authType) === -1) {
      this.props.setNotification({
        message: `This environment requires an authorization token that will be fetched automatically before the first \
                  request to the API endpoint`,
        impact: 'significant',
      });
    }
  }

  onCancel = () => {
    this.props.close();
  }

  getDomNode() {
    return this.refs.container;
  }

  getUrlQuery(ctx, preset) {
    let { settingsData = {}, currentForm, module } = this.props,
        path = [
          module,
          currentForm,
          preset || getCurrentPresetFromSettingsData(settingsData, module),
        ].join('.');

    return _.cloneDeep(utils.getObject(ctx, path));
  }

  getToken(settings) {
    this.setState({ fetchingToken: true });
    this.props.fetchToken(settings, (token, tokenExpiry) => {
      this.setState({
        settings: {
          ...this.state.settings,
          urlQuery: { ...this.state.settings.urlQuery, token, tokenExpiry },
        },
        fetchingToken: false,
      });
    }, () => this.setState({ fetchingToken: false }));
  }

  getCredentialsEl(settings, isReadonly) {
    const fetchingToken = this.state.fetchingToken;
    const options = [
      { value: AUTH_TYPE.APP_ID_AND_APP_CODE, label: 'App ID, App Code' },
      { value: AUTH_TYPE.APIKEY, label: 'Api Key' },
      { value: AUTH_TYPE.HERE_TOKEN, label: 'HERE Token' },
    ];
    const appIDCodeForm = (
      <div className="rf-app-id-code-form">
        <FormRow>
          <CustomInput
            type="text"
            label="App ID"
            value={settings.urlQuery.app_id}
            onChange={(e) => this.onChange('urlQuery.app_id', e)}
          />
        </FormRow>
        <FormRow>
          <CustomInput
            type="text"
            label="App Code"
            value={settings.urlQuery.app_code}
            onChange={(e) => this.onChange('urlQuery.app_code', e)}
          />
        </FormRow>
      </div>
    );
    const apiKeyForm = (
      <div className="rf-app-id-code-form">
        <FormRow>
          <CustomInput
            type="text"
            label="Api Key"
            value={settings.urlQuery.apikey}
            onChange={(e) => this.onChange('urlQuery.apikey', e)}
          />
        </FormRow>
      </div>
    );

    const hereTokenForm = (
      <FormRow cssClasses="rf-token-form">
        <CustomSelect
          onChange={(e) => this.onChange('urlQuery.authEndpoint', e)}
          options={Object.keys(HERE_TOKEN_ENDPOINTS).map((key) => HERE_TOKEN_ENDPOINTS[key])}
          label="Auth endpoint"
          value={settings.urlQuery.authEndpoint}
        />
        <CustomInput
          type="text"
          label="Client Key"
          value={settings.urlQuery.consumer_key}
          isCompact
          onChange={(e) => this.onChange('urlQuery.consumer_key', e)}
        />
        <CustomInput
          type="text"
          label="Client Secret"
          value={settings.urlQuery.consumer_secret}
          isCompact
          onChange={(e) => this.onChange('urlQuery.consumer_secret', e)}
        />
        <div className="rf-token-control">
          <CustomInput
            type="text"
            label="HERE Token"
            value={settings.urlQuery.token}
            isCompact
            onChange={this.onChangeToken}
          />
          <CustomButton
            title={fetchingToken ? '\u231B' : '\u27F3'}
            disabled={fetchingToken}
            onClick={() => this.getToken(settings)}
          />
        </div>
        <CustomInput
          type="text"
          label="Expiry Date"
          value={settings.urlQuery.tokenExpiry ? new Date(+settings.urlQuery.tokenExpiry) : ''}
          isCompact
          readOnly
        />
      </FormRow>
    );

    const authTypeToForm = {
      [AUTH_TYPE.APP_ID_AND_APP_CODE]: appIDCodeForm,
      [AUTH_TYPE.APIKEY]: apiKeyForm,
      [AUTH_TYPE.HERE_TOKEN]: hereTokenForm,
    };

    return (
      <div>
        <RadioGroup
          label="Auth Type"
          options={options}
          onChange={this.onChange.bind(this, 'urlQuery.authType')}
          value={settings.urlQuery.authType}
          disabled={isReadonly}
          columns={1}
        />
        {authTypeToForm[settings.urlQuery.authType]}
        {this.getCredentialCustomizationEl()}
      </div>
    );
  }

  getCredentialCustomizationEl() {
    if (!this.props.canResetCredentials || this.checkDefaultCredentials()) {
      return '';
    }

    return (
      <FormRow>
        <div className="rf-settings-form__warning">
          <lui-button secondary onClick={this.resetCredentials}>Reset Credentials</lui-button>
        </div>
      </FormRow>
    );
  }

  getTabSettingsEl = () => {
    let { settingsData = {}, currentForm, module, color } = this.props;
    let { settingsPresets } = settingsData;

    let presetsPerForm = settingsPresets[module][currentForm];
    let { settings, currentPreset } = this.state;
    let listValues = Object.keys(presetsPerForm).map(value => ({ value, label: presetsPerForm[value].title }));
    let isButtonsDisabled = this.compareSettings();
    const isReadonly = currentPreset !== 'CUSTOM';
    const tokenAuth = settings.title.toUpperCase().indexOf('TOKEN') > -1 || currentPreset === 'CUSTOM';
    return (
      <>
        <h3><i className="rf-settings-form__color" style={{ background: color }} />Active Tab Settings</h3>
        <FormRow>
          <CustomSelect
            label="Environment"
            value={currentPreset}
            options={listValues}
            onChange={this.onChangeEnv}
          />
        </FormRow>
        <FormRow>
          <CustomInput
            type="text"
            label="Host"
            value={settings.host}
            isCompact
            onChange={(e) => this.onChange('host', e)}
            isReadonly={isReadonly}
          />
        </FormRow>
        <FormRow>
          <CustomInput
            type="text"
            label="Path"
            value={settings.path}
            isCompact
            onChange={(e) => this.onChange('path', e)}
            isReadonly={isReadonly}
          />
        </FormRow>
        <FormRow>
          <CustomCheckbox
            label="Use SSL"
            isChecked={settings.useSSL}
            onChange={(e) => this.onChange('useSSL', e)}
            disabled={isReadonly}
          />
        </FormRow>
        <hr />
        <h3>General Settings</h3>
        <FormRow>
          <CustomCheckbox
            label="Skip Proxy"
            isChecked={!!settings.urlQuery.skipProxy}
            onChange={this.onChangeSkipProxy}
            disabled={!tokenAuth}
          />
        </FormRow>
        <FormRow isHidden={!!settings.urlQuery.skipProxy}>
          <CustomInput
            type="text"
            label="Proxy URL"
            value={settings.urlQuery.proxy}
            isCompact
            onChange={(e) => this.onChange('urlQuery.proxy', e)}
            isReadonly={isReadonly}
          />
        </FormRow>
        {this.getCredentialsEl(settings, isReadonly)}
        <div className="rf-settings-form__action-btn-group">
          <lui-button type="cancel" secondary onClick={this.onCancel}>Cancel</lui-button>
          <lui-button type="save" disabled={isButtonsDisabled} onClick={this.onSaveSettings}>Save</lui-button>
        </div>
      </>
    );
  }

  resetCredentials = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    let { currentPreset } = this.state;
    let defaultUrlQuery = this.getUrlQuery(defaultSettings, currentPreset);

    this.setState({ settings: defaultUrlQuery });
  }

  checkDefaultCredentials() {
    let defaultUrlQuery = this.getUrlQuery(defaultSettings, this.state.currentPreset);
    let credentials = this.state.settings;
    if (!defaultUrlQuery) {
      return true;
    }
    return _.isEqual(defaultUrlQuery, credentials);
  }

  compareSettings() {
    let propSettings = SettingsFormContainer.getCurrentSettings(this.props, this.state.currentPreset);
    return utils.isEqual(propSettings, this.state.settings) &&
      this.state.currentPreset === SettingsFormContainer.getCurrentPreset(this.props);
  }

  render() {
    let formClasses = classnames({
      'rf-settings-form': true,
      'rf-settings-form_fadein': this.props.isSettingsShown,
    });

    return (
      <div className={formClasses} ref="container">
        <CustomTabs
          data={[
            { title: 'Tab settings', content: this.getTabSettingsEl() },
            { title: 'App settings', content: <AppSettings close={this.props.close} /> },
          ]}
        />
      </div>
    );
  }
}

SettingsFormContainer.defaultPros = {
  canResetCredentials: true,
};

SettingsFormContainer.propTypes = {
  isSettingsShown: PropTypes.bool.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  settingsData: PropTypes.object.isRequired,
  canResetCredentials: PropTypes.bool.isRequired,
  setSettingsValue: PropTypes.func.isRequired,
  setCurrentSettingsPreset: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  fetchToken: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

export default SettingsFormContainer;
