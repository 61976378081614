import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import ExpandFormRow from 'shared/expandFormRow';
import Columns from 'shared/columns';
import CustomCheckboxList from 'shared/checkboxList';
import consumptionDefaultState from 'state/tabs/tab/formData/routingTab/common/consumptionDefaultState';

import SpeedGroup from './SpeedGroup';
import utils from 'utils';

import './styles/consumption.scss';

const advancedFields = {
  standard: [
    { label: 'Turn Penalty', key: 'turnpenalty', tooltip: 'Turn [E/s]' },
    { label: 'Acceleration', key: 'acceleration', tooltip: 'Accel. [E/km/h)^2]' },
    { label: 'Deceleration', key: 'deceleration', tooltip: 'Decel. [E/km/h)^2] (-)' },
    { label: 'Auxiliary', key: 'auxiliaryconsumption', tooltip: 'Auxiliary [E/s]' },
  ],
  electric: [
    { label: 'Auxiliary', key: 'auxiliaryconsumption', tooltip: 'Auxiliary [E/s]' },
    { label: 'Acceleration', key: 'acceleration', tooltip: 'Accel. [E/km/h)^2]' },
    { label: 'Deceleration', key: 'deceleration', tooltip: 'Decel. [E/km/h)^2] (-)' },
  ]
};

class ConsumptionBlock extends Component {
  state = {
    preset: '80kwh',
  };

  onModelChange(e) {
    const { filterValueMultiUpdate, onChange, currentForm, setNotification, fields } = this.props;
    onChange('consumptionmodel', e);

    if (e.target.value === 'electric') {
      this.onPresetChange(this.state.preset);

      let legAttributes = fields.legattributes.attributes.slice();
      let attributesChanged = false;

      if (legAttributes.indexOf('bc') === -1) {
        legAttributes.push('bc');
        attributesChanged = true;
      }

      if (legAttributes.indexOf('st') === -1) {
        legAttributes.push('st');
        attributesChanged = true;
      }

      if (attributesChanged) {
        filterValueMultiUpdate(currentForm, { legattributes: { attributes: legAttributes } });
        setNotification({
          children: <div>
            The following output params were automatically enabled by this action:
            <ul>
              <li>Leg Attributes: Battery Charge</li>
              <li>Leg Attributes: Staying Time</li>
            </ul>
          </div>,
          impact: 'significant',
        });
      }
    } else {
      filterValueMultiUpdate(currentForm, utils.omit(consumptionDefaultState, 'consumptionmodel'));
    }
  }

  onPresetChange(preset) {
    const { filterValueMultiUpdate, formConfig, currentForm } = this.props;
    const value = preset.target ? preset.target.value : preset;

    this.setState({
      preset: value,
    });

    const newPreset = utils.find(formConfig.electricPresets, { value });
    if (!newPreset) {
      return;
    }

    filterValueMultiUpdate(currentForm, newPreset.preset);
  }

  onChange(key, value) {
    this.props.onChange(key, value);

    if (this.props.fields.consumptionmodel === 'electric') {
      this.setState({
        preset: 'custom',
      });
    }
  }

  onChargingStationFiltersChange(key, param, e) {
    const value = utils.extractData(e);
    const oldValue = utils.getObject(this.props.fields, `${key}.value`, []);
    const newValue = [...oldValue];
    if (!value && (newValue.indexOf(param) !== -1)) {
      newValue.splice(newValue.indexOf(param), 1);
    } else if (newValue.indexOf(param) === -1) {
      newValue.push(param);
    }
    this.onChange(key, newValue);
  }

  getAdvancedFields(fields) {
    return fields.map(param => {
      let field = utils.getObject(this.props, `fields.customconsumptiondetails.${param.key}`, {});
      return (
        <FormRow key={param.key}>
          <CustomInput
            label={param.label}
            value={field.value}
            isValid={field.isValid}
            onChange={this.onChange.bind(this, `customconsumptiondetails.${param.key}`)}
            tooltip={param.tooltip}
            tooltipPlacement="right"
            debounce
          />
        </FormRow>
      );
    });
  }

  getConsumptionComboboxes(force = false) {
    let { fields } = this.props;

    if (fields.consumptionmodel !== 'standard' && !force) {
      return null;
    }

    let { ascent, descent } = fields.customconsumptiondetails;

    const inputs = <>
      <FormRow>
        <CustomInput
          label="Ascent"
          value={ascent.value}
          isValid={ascent.isValid}
          onChange={this.onChange.bind(this, 'customconsumptiondetails.ascent')}
          tooltip="Ascent multiplier that is multiplied by absolute height increase to model energy expenditure [E/m]"
          tooltipPlacement="right"
          debounce
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Descent"
          value={descent.value}
          isValid={descent.isValid}
          onChange={this.onChange.bind(this, 'customconsumptiondetails.descent')}
          tooltip="Descent multiplier that is multiplied by absolute height decrease to model energy gain [E/m]"
          tooltipPlacement="right"
          debounce
        />
      </FormRow>
    </>;

    if (fields.consumptionmodel === 'electric') {
      return inputs;
    }

    return <div className="rf-form-inline">{inputs}</div>;
  }

  getStandardFields() {
    let { fields, children } = this.props;
    let { speed } = fields.customconsumptiondetails;

    return <div>
      <FormRow>
        <SpeedGroup
          speedArray={speed.value.split(',')}
          isValid={speed.isValid}
          onChange={this.onChange.bind(this, 'customconsumptiondetails.speed')}
        />
      </FormRow>

      {children}

      <ExpandFormRow label="Advanced">
        {this.getAdvancedFields(advancedFields.standard)}
      </ExpandFormRow>
    </div>;
  }

  getElectricFields() {
    let { fields, formConfig } = this.props;

    return <div className="rf-electric-fields">

      {!!formConfig.electricPresets.length && <FormRow>
        <CustomSelect
          label="Configuration Preset"
          options={formConfig.electricPresets}
          value={this.state.preset}
          onChange={::this.onPresetChange}
          notSupportedLabel="Custom"
        />
      </FormRow>}

      <FormRow>
        <CustomSelect
          label="Make Reachable"
          options={formConfig.bool}
          value={fields.makereachable}
          addEmptyOption
          onChange={this.onChange.bind(this, 'makereachable')}
        />
      </FormRow>

      <ExpandFormRow label="Charge Aware Routing" cssClasses="rf-battery-fields">
        <ExpandFormRow label="Free Flow Speed Table">
          <SpeedGroup
            speedArray={fields.customconsumptiondetails.freeflowspeedtable.value.split(',')}
            isValid={fields.customconsumptiondetails.freeflowspeedtable.isValid}
            onChange={this.onChange.bind(this, `customconsumptiondetails.freeflowspeedtable`)}
          />
        </ExpandFormRow>
        <ExpandFormRow label="Traffic Speed Table">
          <SpeedGroup
            speedArray={fields.customconsumptiondetails.trafficspeedtable.value.split(',')}
            isValid={fields.customconsumptiondetails.trafficspeedtable.isValid}
            onChange={this.onChange.bind(this, `customconsumptiondetails.trafficspeedtable`)}
          />
        </ExpandFormRow>
        <ExpandFormRow label="Traffic Scale Table">
          <SpeedGroup
            speedArray={fields.customconsumptiondetails.trafficscaletable.value.split(',')}
            isValid={fields.customconsumptiondetails.trafficscaletable.isValid}
            onChange={this.onChange.bind(this, `customconsumptiondetails.trafficscaletable`)}
            vLabel="fraction #1"
            hLabel="fraction #2"
            vTooltip=""
            hTooltip=""
          />
        </ExpandFormRow>

        {this.getConsumptionComboboxes(true)}
        {this.getAdvancedFields(advancedFields.electric)}
        <FormRow>
          <CustomInput
            label="High speed threshold"
            value={fields.highspeedthreshold.value}
            isValid={fields.highspeedthreshold.isValid}
            onChange={this.onChange.bind(this, `highspeedthreshold`)}
            debounce
          />
        </FormRow>
      </ExpandFormRow>

      {this.getBatteryFields()}
      {this.getChargingAttributes()}

      {this.getChargingStationFilterFields()}
    </div>;
  }

  getConsumptionFields() {
    let { fields } = this.props;

    if (fields.consumptionmodel === 'standard') {
      return this.getStandardFields();
    } else if (fields.consumptionmodel === 'electric') {
      return this.getElectricFields();
    }
    return null;
  }

  getBatteryFields() {
    let { fields } = this.props;
    const { chargingcurve } = fields.batteryparameters;

    return <ExpandFormRow label="Battery Parameters" cssClasses="rf-battery-fields rf-charging-curve">
      <ExpandFormRow label="Charging curve">
        <SpeedGroup
          speedArray={chargingcurve.value.split(',')}
          isValid={chargingcurve.isValid}
          onChange={this.onChange.bind(this, `batteryparameters.chargingcurve`)}
          vLabel="energy"
          hLabel="power"
          vTooltip="Energy"
          hTooltip="Power"
          chartType="piece-wise"
        />
      </ExpandFormRow>
      {[
        { key: 'maxcharge', label: 'max charge' },
        { key: 'initialcharge', label: 'initial charge' },
        { key: 'maxvoltage', label: 'max voltage' },
        { key: 'maxcurrent', label: 'max current' },
      ]
        .map(({ key, label }) =>
          <FormRow key={key}>
            <CustomInput
              label={label}
              value={fields.batteryparameters[key].value}
              isValid={fields.batteryparameters[key].isValid}
              onChange={this.onChange.bind(this, `batteryparameters.${key}`)}
              debounce
            />
          </FormRow>
        )}
    </ExpandFormRow>;
  }

  getChargingAttributes() {
    let { fields } = this.props;

    return <ExpandFormRow label="Charging Attributes" cssClasses="rf-battery-fields">
      {[
        { key: 'minchargeatstop', label: 'min charge at stop' },
        { key: 'chargingstopdeparturecharge', label: 'charging stop departure charge' },
        { key: 'chargingdurationoffset', label: 'charging duration offset' },
        { key: 'minchargeatchargingstop', label: 'min charge at charging stop' },
        { key: 'minchargeatfirstchargingstop', label: 'min charge at first charging stop' },
        { key: 'minchargeatdestination', label: 'min charge at destination' },
      ]
        .map(({ key, label }) =>
          <FormRow key={key}>
            <CustomInput
              label={label}
              value={fields.batteryparameters[key].value}
              isValid={fields.batteryparameters[key].isValid}
              onChange={this.onChange.bind(this, `batteryparameters.${key}`)}
              debounce
            />
          </FormRow>
        )}
    </ExpandFormRow>;
  }

  getChargingStationFilterFields() {
    let { formConfig, fields } = this.props;
    const { plugtype } = fields.chargingstationfilters;

    return <ExpandFormRow label="Charging station filters" cssClasses="rf-charging-station-filter-fields">
      <ExpandFormRow
        label="Plug Type"
        isExpanded
      >
        <CustomCheckboxList
          params={formConfig.plugTypes.map(plugTypeEntry => ({
            ...plugTypeEntry,
            onChange: e =>
              this.onChargingStationFiltersChange('chargingstationfilters.plugtype', plugTypeEntry.value, e),
            isChecked: plugtype.value.indexOf(plugTypeEntry.value) > -1,
          }))}
        />
      </ExpandFormRow>
    </ExpandFormRow>;
  }

  render() {
    let { fields, label, formConfig, isHidden } = this.props;

    if (isHidden) {
      return null;
    }

    return (
      <div className="rf-consumption-block rf-grey-box">
        <h3>{label}</h3>
        <Columns>
          <FormRow>
            <CustomSelect
              value={fields.consumptionmodel}
              label="Consumption Model"
              options={formConfig.consumptionTypes}
              onChange={::this.onModelChange}
              addEmptyOption
            />
          </FormRow>
          {this.getConsumptionComboboxes()}
        </Columns>

        {this.getConsumptionFields()}
      </div>
    );
  }
}

ConsumptionBlock.defaultProps = {
  isHidden: false,
  children: null,
};

ConsumptionBlock.propTypes = {
  label: PropTypes.string.isRequired,
  formConfig: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  currentForm: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  children: PropTypes.node,
  setNotification: PropTypes.func.isRequired,
};

export default ConsumptionBlock;
