import React from 'react';
import PropTypes from 'prop-types';
import CustomComponent from '../component';

import utils from 'utils';
import AutosizeInput from 'react-input-autosize';
import './styles/autosizeInput.scss';

export default class CustomAutosizeInput extends CustomComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  onChange(e) {
    this.setState({ value: utils.extractData(e) });
  }

  onKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.onBlur(e);
    }
  }

  render() {
    return (
      <div className={this.getCssClasses('rf-autosize-input')}>
        <AutosizeInput
          ref="autosizeInput"
          onKeyPress={::this.onKeyPress}
          onChange={::this.onChange}
          onBlur={this.props.onBlur}
          onFocus={event => event.target.select()}
          value={this.state.value}
          autoFocus
        />
      </div>
    );
  }
}

CustomAutosizeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
};
