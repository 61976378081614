import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BaseContextMenu from '../../_ContextMenu';
import { inspectLink } from 'state/inspectLink/actions';
import { reverseGeocode } from 'state/reverseGeocode/actions';
import { asyncCopyAddressOls } from 'state/asyncCopy/actions';
import * as NotificationActions from 'state/notification/actions';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';
import { getCurrentFormData } from '../../../../state/tabs/tab/formData';
import {
  getOlsWaypoints,
  google,
  myDrive,
  getOlsOptions,
  mapFan
} from '../../../resultPanel/routingTab/routing/compareUtils';
import { set as setWaypoints } from 'state/tabs/tab/formData/common/waypoints/actions';
import { addTab } from '../../../../state/tabs/actions';
import { getInspectLinkSettings } from 'state/appSettings/inspectLink/reducer';
import { getOlsSearchSettings } from 'state/appSettings/olsSearch/reducer';
import { getAdvancedWaypointParams } from '../../../../state/tabs/tab/formData/routingTab/common/commonOlsDefaultState';
import openHlsTab from './openHlsTab';

class ContextMenuContainer extends BaseContextMenu {
  labels = {
    copyAddress: 'Retrieve Address',
  };

  setOrigin = () => {
    this.props.filterValueMultiUpdate(this.props.currentForm, {
      origin: this.props.data.geo,
    });
  }

  setDestination = () => {
    this.props.filterValueMultiUpdate(this.props.currentForm, {
      destination: this.props.data.geo,
    });
  }

  reverseGeocode = () => {
    const { data, olsSearchSettings } = this.props;
    const urlQuery = {
      apikey: olsSearchSettings.apiKey
    };
    this.props.reverseGeocode(data.geo, urlQuery);
  }

  copyAddress(e) {
    this.stay(e);
    let { data, asyncCopyAddressOls, requestErrorNotify } = this.props;
    this.setState({ addressToCopy: '...' });
    asyncCopyAddressOls(data.geo, address => {
      this.setState({ addressToCopy: address });
    }, () => requestErrorNotify('Error'));
  }

  addVia = () => {
    const { currentForm, filterValueMultiUpdate, data: { geo }, formData: { fields: { via } } } = this.props;


    filterValueMultiUpdate(currentForm, {
      via: [...via, {
        coords: geo,
        advanced: getAdvancedWaypointParams(true),
      }],
    });
  }

  addAvoidArea = () => {
    const { filterValueMultiUpdate, data: { avoidArea }, formData: { fields }, currentForm } = this.props;
    const avoidAreas = fields['avoid[areas]'];
    const [latLng1, latLng2] = avoidArea.split(';');
    const [lat1, lng1] = latLng1.split(',').map(Number);
    const [lat2, lng2] = latLng2.split(',').map(Number);
    const west = Math.min(lng1, lng2);
    const south = Math.min(lat1, lat2);
    const east = Math.max(lng1, lng2);
    const north = Math.max(lat1, lat2);
    const value = `bbox:${west},${south},${east},${north}`;
    const newAvoidArea = { isValid: true, isShown: true, value };
    let newAvoidAreas;
    if (avoidAreas[avoidAreas.length - 1].value === '') {
      newAvoidAreas = [...avoidAreas.slice(0, -1), newAvoidArea];
    } else {
      newAvoidAreas = [...avoidAreas, newAvoidArea];
    }

    filterValueMultiUpdate(currentForm, {
      'avoid[areas]': newAvoidAreas,
    });
  }

  compareToGoogle = () => {
    const { formData: { fields } } = this.props;
    google.open(getOlsWaypoints(this.props), fields.transportMode);
  }

  compareToTomTom = () => {
    this.props.setNotification({
      message: `IMPORTANT! Please check and/or adjust the start and destination of the route
      in TomTom MyDrive to properly compare the routes!`,
    });
    myDrive.open(getOlsWaypoints(this.props), getOlsOptions(this.props));
  }

  compareToMapFan = () => {
    mapFan.open(getOlsWaypoints(this.props), getOlsOptions(this.props));
  }

  openHlsTab = () => {
    openHlsTab(this.props);
  }

  getMenuItems(data) {
    let items = [
      { label: 'Route from', iconLabel: 'A', fn: this.setOrigin },
      { label: 'Route to', iconLabel: 'B', fn: this.setDestination },
      { label: 'Add Via', iconLabel: 'N', fn: this.addVia },
      { label: 'Add Avoid Area', iconLabel: 'R', fn: this.addAvoidArea },
      { label: 'Reverse Geocode', iconLabel: 'L', fn: this.reverseGeocode },
    ];
    const type = _.get(this.props, 'data.targetData.type');

////////////////////
///////////////////////////
//////////////////
///////////////////////////////
////////////////////////
/////////////////////////////////
///////////////////
///////////
/////////////////////////
/////////////////////////////
////////////////////////////////
////////////
///////////
////////////////////////////
/////////////////////////////
/////////////////////////////////////
////////////
///////////
////////////////////////////
/////////////////////////////
/////////////////////////////////////
////////////
///////////
////////////////////////////
/////////////////////////////
/////////////////////////////////////
///////////
//////////
/////////
/////
//////////////

    return items.concat(super.getMenuItems(data));
  }
}

ContextMenuContainer.defaultProps = {
  data: {},
};

ContextMenuContainer.propTypes = {
  inspectLink: PropTypes.func.isRequired,
  reverseGeocode: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  settingsData: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentForm: getForm(state),
    module: getModule(state),
    settingsData: getSettingsData(state),
    formData: getCurrentFormData(state),
    inspectLinkSettings: getInspectLinkSettings(state),
    olsSearchSettings: getOlsSearchSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inspectLink: bindActionCreators(inspectLink, dispatch),
    reverseGeocode: bindActionCreators(reverseGeocode, dispatch),
    textCopiedNotify: bindActionCreators(NotificationActions.textCopiedNotify, dispatch),
    requestErrorNotify: bindActionCreators(NotificationActions.requestErrorNotify, dispatch),
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
    setWaypoints: bindActionCreators(setWaypoints, dispatch),
    addTab: bindActionCreators(addTab, dispatch),
    asyncCopyAddressOls: bindActionCreators(asyncCopyAddressOls, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextMenuContainer);
