export default {
  pnrChangeStrategy: [{
      label: 'Park Late',
      value: 'parkLate',
    }, {
      label: 'Park Early',
      value: 'parkEarly',
    }, {
      label: 'Diverse Choices',
      value: 'diverseChoices',
    },
  ],
  intermodalModes: [{
      label: 'P+R',
      value: 'car',
    }, {
      label: 'Taxi',
      value: 'taxi',
    }, {
      label: 'Bike Sharing',
      value: 'bicycleShare',
    }, {
      label: 'Bike',
      value: 'bicycle',
    },
  ],
  transitModes: [{
      label: 'HighSpeedTrain',
      value: 'highSpeedTrain',
    }, {
      label: 'IntercityTrain',
      value: 'intercityTrain',
    }, {
      label: 'InterRegionalTrain',
      value: 'interRegionalTrain',
    }, {
      label: 'RegionalTrain',
      value: 'regionalTrain',
    }, {
      label: 'CityTrain',
      value: 'cityTrain',
    }, {
      label: 'Bus',
      value: 'bus',
    }, {
      label: 'Ferry',
      value: 'ferry',
    }, {
      label: 'Subway',
      value: 'subway',
    }, {
      label: 'LightRail',
      value: 'lightRail',
    }, {
      label: 'PrivateBus',
      value: 'privateBus',
    }, {
      label: 'Inclined',
      value: 'inclined',
    }, {
      label: 'Aerial',
      value: 'aerial',
    }, {
      label: 'BusRapid',
      value: 'busRapid',
    }, {
      label: 'Monorail',
      value: 'monorail',
    }, {
      label: 'Flight',
      value: 'flight',
    },
  ],
  units: [{
      label: 'Metric',
      value: 'metric',
    }, {
      label: 'Imperial',
      value: 'imperial',
    },
  ],
  olsReturn: [
    { label: 'Polyline', value: 'polyline' },
    { label: 'Actions', value: 'actions' },
    { label: 'Fares', value: 'fares' },
    { label: 'Stops', value: 'intermediate' },
    { label: 'Summary', value: 'travelSummary' },
  ],
};
