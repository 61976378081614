import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CloseButton from 'shared/closeButton';
import ExpandFormRow from 'shared/expandFormRow';
import LinkInfoData from './common/LinkInfoData';
import ResponseTabComponent from './common/ResponseTabComponent';
import SearchResultsContainer from './search/SearchResultsContainer';

import utils from 'utils';
import { getInputEl } from './common/utils';

class ResultPanelFormBase extends Component {
  getInputEl(args = {}, idx) {
    let ns = 'ResultPanelForm';
    return getInputEl(Object.assign({ ns }, args), idx);
  }

  getInspectLinkData() {
    let { inspectLink = {}, onRemoveInspectLink } = this.props;
    let { response = {} } = inspectLink.data || {};
    let links = response.link || [];
    if (links.length === 0) {
      return '';
    }
    return (
      <div className="rf-result-panel__inspect-link">
        <h3 className="rf-sidebar__header">Inspected Link</h3>
        <LinkInfoData links={links} />
        <CloseButton onClick={onRemoveInspectLink} />
      </div>
    );
  }

  getReverseGeocodeData() {
    let { reverseGeocode = [], onRemoveReverseGeocode } = this.props;
    if (reverseGeocode.length === 0) {
      return null;
    }
    return <div className="rf-result-panel__inspect-link">
      <h3 className="rf-sidebar__header">Reverse Geocode</h3>
      <div>
        {reverseGeocode.map((data, key) => {
          let fieldsData = [
            { label: 'Address', ctx: data, key: 'address.label' },
            { label: 'Result Type', ctx: data, key: 'resultType' },
            { label: 'Position', ctx: data, key: 'position', fn: item => `${item.lat},${item.lng}` },
            { label: 'Distance', ctx: data, key: 'distance', units: 'meter(s)' },
          ];

          let content = fieldsData.map((args, idx) => {
            let _args = Object.assign({}, args, { ns: 'reverseGeocodeData' });
            return getInputEl(_args, idx);
          });

          return <ExpandFormRow
            label={data.title}
            key={key}
          >
            <div className="rf-result-panel__tabs-gap">
              {content}
            </div>
          </ExpandFormRow>;
        })}
      </div>
      <CloseButton onClick={onRemoveReverseGeocode} />
    </div>;
  }

  getSearchResults() {
    return <SearchResultsContainer />;
  }

  getTabColor(isActive = false, isHighlighted = false) {
    if (isActive) {
      return this.props.selectedTabData.tabColorPalette.primary;
    } else if (isHighlighted) {
      return this.props.selectedTabData.tabColorPalette.secondaryDarker;
    }
    return this.props.selectedTabData.tabColorPalette.secondary;
  }

  getResponseTabContent(responseData) {
    let timestamp = utils.getObject(responseData, 'metaInfo.timestamp');
    if (timestamp) {
      timestamp = new Date(timestamp).toString();
    }

    return (
      <ResponseTabComponent
        onShowFormattedResult={this.props.onShowFormattedResult}
        timestamp={timestamp}
        responseData={responseData}
      />
    );
  }
}

ResultPanelFormBase.propTypes = {
  selectedTabData: PropTypes.object.isRequired,
  inspectLink: PropTypes.object.isRequired,
  reverseGeocode: PropTypes.object.isRequired,
  onShowFormattedResult: PropTypes.func.isRequired,
  onRemoveInspectLink: PropTypes.func.isRequired,
  onRemoveReverseGeocode: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default ResultPanelFormBase;
