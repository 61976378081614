import utils from 'utils';

import * as mapMarkers from '../utils/MarkersHelper';
import UnitBase from './_Base';

export default class Dnd extends UnitBase {
  constructor(map, refs) {
    super(map, refs);
    this._onDragStart = this._onDragStart.bind(this);
    this._onDrag = this._onDrag.bind(this);
    this._onDragEnd = this._onDragEnd.bind(this);
  }

  isMarkerDragging(e) {
    return mapMarkers.isMarker(e.target);
  }

  destroy() {
    this.map.removeEventListener('dragstart', this._onDragStart, false);
    this.map.removeEventListener('drag', this._onDrag, false);
    this.map.removeEventListener('dragend', this._onDragEnd, false);
    super.destroy();
  }

  init(handlers = {}) {
    this.isFirstDrag = false;
    this.dragDelta = { x: 0, y: 0 };
    this.dragCoords = null;
    this.targetData = null;
    this.handlers = handlers;

    this.map.addEventListener('dragstart', this._onDragStart, false);
    this.map.addEventListener('drag', this._onDrag, false);
    this.map.addEventListener('dragend', this._onDragEnd, false);
  }

  _onDragStart(ev) {
    let handlers = this.handlers;
    this.targetData = this.isMarkerDragging(ev) ? ev.target.getData() : null;
    this.isFirstDrag = true;
    if (utils.isFunction(handlers.onDragStart)) {
      handlers.onDragStart(this.targetData);
    }

    this.isDragging = true;
  }

  _onDrag(ev) {
    let target = ev.target,
        map = this.map,
        pointer = ev.currentPointer,
        handlers = this.handlers;
    if (this.targetData) {
      if (this.isFirstDrag) {
        let targetPos = map.geoToScreen(target.getGeometry());
        this.dragDelta = { x: targetPos.x - pointer.viewportX, y: targetPos.y - pointer.viewportY };
        this.isFirstDrag = false;
      }

      let coords = map.screenToGeo(pointer.viewportX + this.dragDelta.x, pointer.viewportY + this.dragDelta.y),
          coordsStr = `${coords.lat},${coords.lng}`;

      if (coordsStr !== this.dragCoords) {
        if (utils.isFunction(handlers.onDrag)) {
          handlers.onDrag(this.targetData, coords);
        }

        target.setGeometry(coords);
      }

      this.dragCoords = coordsStr;
    }
  }

  _onDragEnd(ev) {
    let handlers = this.handlers;
    if (utils.isFunction(handlers.onDragEnd)) {
      handlers.onDragEnd(this.targetData, this._handleMarkerDragEnd(ev));
    }

    this.isDragging = false;
  }

  _handleMarkerDragEnd(e) {
    if (!this.targetData) {
      return '';
    }

    let pointer = e.currentPointer,
        coords = this.map.screenToGeo(pointer.viewportX + this.dragDelta.x, pointer.viewportY + this.dragDelta.y);

    this.isDragging = false;
    this.map.getElement().style.cursor = 'default';

    return `${coords.lat.toFixed(6)},${coords.lng.toFixed(6)}`;
  }
}
