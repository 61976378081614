import execTruckParams from '../common/truck';

export default function exec(data) {
  const _data = execTruckParams(data);
  const { vehicletype = {}, mode = {} } = _data;
  const isPublicTransport = mode.transport === 'publicTransport' || mode.transport === 'publicTransportTimeTable';
  const isPTorWalk = mode.transport === 'pedestrian' || isPublicTransport;

  delete _data.selectedPoint;
  delete _data.hoveredPoint;
  delete _data.requestedRangeOnRoute;
  delete _data.rangeOnRoute;
  delete _data.useNewWaypointsFormat;

  if (!_data.consumptionmodel) {
    delete _data.consumptionmodel;
    delete _data.customconsumptiondetails;
    delete _data.batteryparameters;
    delete _data.rangeOnRoute;
  }

  if (!_data.consumptionmodel || _data.consumptionmodel === 'default') {
    delete _data.customconsumptiondetails;
  }
  if (_data.consumptionmodel !== 'electric') {
    delete _data.batteryparameters;
    delete _data.chargingstationfilters;
    delete _data.makereachable;
    delete _data.highspeedthreshold;
  }

  if (!isPublicTransport) {
    delete _data.maxnumberofchanges;
    delete _data.walkradius;
    delete _data.combinechange;
  }

  if (!isPublicTransport && !isPTorWalk) {
    delete _data.walkspeed;
  }

  if (isPTorWalk) {
    delete _data.avoidseasonalclosures;
    delete _data.distancebeforefirstturn;
  }

  if (!vehicletype.type) {
    delete _data.vehicletype;
  }

  return _data;
}
