import React from 'react';
import ModuleBase from './_Base';

import utils from 'utils';
import fetch from 'utils/fetch';
import uManeuversList from 'utils/response/maneuversList';
import * as mapPolyline from '../utils/PolylineHelper';
import * as mapMarkers from '../utils/MarkersHelper';
import { createPoint } from '../utils/PointHelper';
import { calculateResolution } from '../utils/resolutionHelper';
import RouteHelper from '../utils/RouteHelper';

import { normalizeRoutingData } from 'state/tabs/tab/response/responseHandlers';
import { CONSUMPTION_COLORS, DEFAULT_PT_COLOR } from 'config/map';

export default class Routes extends ModuleBase {
  constructor(...args) {
    super(...args);
    this.setData({
      routeHandle: null,
      newWaypointIndex: null,
      isHandleDragging: false,
    });
    this.showPTNotification = utils.throttle(this.showPTNotification.bind(this), 4000, { trailing: false });
  }

  process(routes = [], tabColorPalette = null) {
    this.clearGroup();
    this.routeLines = [];
    routes.forEach((route, id) =>
      this.processRoute(route, id, id === this.currentRoute, tabColorPalette || this.props.tabData.tabColorPalette));
  }

  processRoute(route, id, isActive, tabColorPalette) {
    if (route.shape === undefined) return false;

    let legs = route.leg || [],
        waypoints = route.waypoint,
        isPublicTransport = (route.publicTransportLine || []).length !== 0,
        isTrafficRoute = isActive && utils.getObject(legs, '[0].link[0].dynamicSpeedInfo') !== undefined,
        isEV = this.energy !== null && Object.keys(this.energy).length &&
          utils.some(utils.getObject(legs, '[0].link', []), link => link.consumption > 0);

    this.showNotificationOnRubberBanding = false;
    const { shape, sections } = route;
    if (isEV) {
      this.renderEVRoute(legs, id, isActive, waypoints, shape, tabColorPalette);
    } else if (isPublicTransport) {
      this.renderPTRoute(route, id, isActive, waypoints, shape);
    } else if (isTrafficRoute) {
      this.renderTrafficRoute(legs, id, isActive, waypoints, shape);
    } else {
      this.renderRoute({ shape, id, isActive, waypoints, routeShape: shape, tabColorPalette, sections });
    }

    return true;
  }

  showPTNotification() {
    this.props.setNotification({
      children: <div>
        Following output params are required to render public transport route correctly:
        <ul>
          <li>Leg Attributes: Maneuvers</li>
          <li>Maneuver Attributes: Public transport line</li>
          <li>Maneuver Attributes: Indices</li>
        </ul>
      </div>,
      impact: 'significant',
    });
  }

  renderPTRoute(route = {}, id, isActive, waypoints, shape) {
    const fields = _.get(this.props, 'tabData.formData.fields', {});
    const maneuverAttr = _.get(fields, 'maneuverattributes.attributes', []);
    const { aggregation, attributes = [] } = _.get(fields, 'legattributes', {});
    const isLegAttrMnDisabled = (aggregation === 'all' && attributes.indexOf('mn') > -1) ||
      (aggregation === 'none' && attributes.indexOf('mn') === -1);
    let canRenderPTRoute = maneuverAttr.indexOf('pt') > -1 && maneuverAttr.indexOf('ix') > -1 && !isLegAttrMnDisabled;
    if (!canRenderPTRoute) {
      this.showPTNotification();
      this.renderRoute({ shape, id, isActive, waypoints, routeShape: shape, color: DEFAULT_PT_COLOR });
      return;
    }

    let publicTransportLines = route.publicTransportLine || [],
        allManeuvers = route.leg.reduce((result, current) => [...result, ...current.maneuver], []),
        maneuversList = uManeuversList(allManeuvers),
        sliceShape = (startIdx, endIdx) =>
          route.shape.slice(this.pointArgsCount * startIdx, this.pointArgsCount * (endIdx + 1));

    if (route.mode.transportModes && route.mode.transportModes.indexOf('publicTransportTimeTable') >= 0) {
      this.showNotificationOnRubberBanding = true;
    }

    maneuversList.groupShapesByLines(publicTransportLines).forEach(line => {
      if (!isActive) {
        return false;
      }

      let segmentColor = isActive ? line.color : DEFAULT_PT_COLOR;

      this.renderRoute({
        id,
        isActive,
        pointArgsCount: this.pointArgsCount,
        shape: sliceShape(line.start, line.end),
        color: segmentColor || DEFAULT_PT_COLOR,
        isDashed: !line.id,
        waypoints,
        routeShape: shape,
      }, line.iconType);

      return true;
    });
  }

  renderTrafficRoute(legs = [], id, isActive, waypoints, shape) {
    legs.forEach(leg => {
      leg.link.forEach(leg => {
        let color = mapPolyline.getJamFactorColor(leg.dynamicSpeedInfo.jamFactor);

        this.renderRoute({ waypoints, id, isActive, shape: leg.shape, color, routeShape: shape });
      });
    });
  }

  renderEVRoute(legs = [], id, isActive, waypoints, shape, tabColorPalette) {
    const { energy = {} } = this;
    let { minchargeatstop = 0, maxcharge = 0, initialcharge = 0, isChargingAllowed = false } = energy;
    let noEnergyRouteShape = [];
    let hasEnergyRouteShape = [];
    let lowEnergyRouteShapes = [[]];
    let currentCharge = initialcharge;

    const chargingStationLinks = waypoints
      .filter(waypoint => !!waypoint.chargingStationInfo)
      .map(waipoint => waipoint.linkId);

    legs.forEach(leg => {
      leg.link.forEach(link => {
        currentCharge -= link.consumption;
        if (currentCharge < 0) {
          noEnergyRouteShape.push(...link.shape);
        } else if (currentCharge < minchargeatstop) {
          lowEnergyRouteShapes[lowEnergyRouteShapes.length - 1].push(...link.shape);
        }

        hasEnergyRouteShape.push(...link.shape);

        if (isChargingAllowed && currentCharge >= 0 && chargingStationLinks.indexOf(link.linkId) > -1) {
          currentCharge = Math.min(maxcharge, currentCharge + leg.targetBatteryCharge);
          if (lowEnergyRouteShapes[lowEnergyRouteShapes.length - 1].length) {
            lowEnergyRouteShapes.push([]);
          }
        }
      });
    });
    let commonParams = { waypoints, id, isActive, routeShape: shape };

    if (hasEnergyRouteShape.length > 0) {
      this.renderRoute(Object.assign(commonParams, {
        shape: hasEnergyRouteShape,
        tabColorPalette,
        color: null,
      }));
    }

    if (noEnergyRouteShape.length > 0) {
      this.renderRoute(Object.assign(commonParams, {
        shape: noEnergyRouteShape,
        color: CONSUMPTION_COLORS.hasNoEnergyColor,
      }));
    }

    lowEnergyRouteShapes.forEach(lowEnergyRouteShape => {

      if (lowEnergyRouteShape.length > 0) {
        this.renderRoute(Object.assign(commonParams, {
          shape: lowEnergyRouteShape,
          color: CONSUMPTION_COLORS.lowEnergyColor,
        }));
      }
    });
  }

  getRouteData() {
    return {};
  }

  getEVGeometryForOLS(args) {
    const { shape, sections } = args;

    if (!this.energy.isActive) {
      return [];
    }

    const lowEnergyPaths = [];
    const noEnergyPath = [];
    let lowEnergy;

    let tatalSectionOffset = 0;
    const chunkedShape = utils.chunk(shape, 2);
    let charge = +this.energy.initialcharge;
    const minChargeAtChargingStation = +this.energy.minchargeatstop;
    const maxCharge = +this.energy.maxcharge;

    sections.forEach(section => {
      lowEnergy = false;
      const sectionOffset = section.actions[section.actions.length - 1].offset + 1;
      let currentOffset = tatalSectionOffset;

      const spans = (section.spans && section.spans[0].consumption !== undefined) ? section.spans :
        [{ consumption: section.departure.charge - section.arrival.charge, offset: 0 }];
      spans.forEach((span, spanIndex) => {
        const { consumption, offset } = span;
        const nextSpan = spans[spanIndex + 1];
        const spanPointsCount = (nextSpan ? nextSpan.offset : sectionOffset) - offset;
        const consumptionPerPoint = consumption / spanPointsCount;

        for (let i = currentOffset; i < currentOffset + spanPointsCount; i++) {
          charge -= consumptionPerPoint;
          if (charge <= 0) {
            noEnergyPath.push(chunkedShape[i][0], chunkedShape[i][1]);
          } else if (charge <= minChargeAtChargingStation) {
            if (!lowEnergy) {
              lowEnergyPaths.push([]);
            }
            lowEnergy = true;
            lowEnergyPaths[lowEnergyPaths.length - 1].push(chunkedShape[i][0], chunkedShape[i][1]);
          }
        }

        currentOffset += spanPointsCount;
      });

      tatalSectionOffset += sectionOffset;

      if (section.postActions && charge > 0 && this.energy.isChargingAllowed) {
        const { targetCharge } = section.postActions.find(postAction => postAction.action === 'charging');
        if (targetCharge) {
          charge = Math.min(targetCharge, maxCharge);
        }
      }
    });

    const polylines = lowEnergyPaths.map(shape =>
      mapPolyline.createRoute({ ...args, shape, color: CONSUMPTION_COLORS.lowEnergyColor }));
    if (noEnergyPath.length) {
      polylines.push(mapPolyline.createRoute({
        ...args,
        shape: noEnergyPath,
        color: CONSUMPTION_COLORS.hasNoEnergyColor,
      }));
    }

    return polylines;
  }

  renderRoute(args = {}, iconType = null) {
    args.pointArgsCount = this.pointArgsCount;
    let routeLine = mapPolyline.createRoute(args),
        { isActive = false, waypoints = [] } = args,
        group = this.getGroup();

    if (!routeLine) {
      return;
    }

    if (!isActive) {
      this.setHandlers(routeLine, args);
    }

    if (this.isRubberBandingAllowed) {
      this.setRubberBandingHandlers(routeLine, args);
    }

    if (iconType) {
      let icon = mapMarkers.createChangeTransportMarker(args.shape.slice(0, 2), iconType);
      group.addObject(icon);
    }

    if (isActive && !this.isDragging && this.showMappedMarkers) {
      waypoints.forEach(point => {
        this.renderMappedMarkers(point);
      });
    }

    let extraLines = [];
    if (utils.getObject(this.props, 'formData.fields.evEnabled')) {
      extraLines = this.getEVGeometryForOLS(args);
    }

    routeLine.setData(Object.assign({}, routeLine.getData(), this.getRouteData({
      shape: args.shape,
      waypoints,
    })));
    let borderColor = args.color ||
      (isActive ? args.tabColorPalette.primaryDarker : args.tabColorPalette.secondaryDarker);
    let routeLineBorder = mapPolyline.createRoute({ ...args, lineWidth: args.lineWidth || 7, color: borderColor });
    group.addObject(routeLineBorder);
    group.addObject(routeLine);
    group.addObjects(extraLines);

    this.routeLines[args.id] = { routeLine, routeLineBorder };
  }

  initRubberBanding() {
    this.isRubberBandingAllowed = true;
  }

  setRouteColor(routeLine, color) {
    let style = { ...routeLine.getStyle() };
    style.strokeColor = color;
    routeLine.setStyle(style);
  }

  highlight(oldHighlighted, nextHighlighted, currentRoute, palette) {
    const updateLine = (id, active) => {
      if (id === -1 || id === currentRoute || this.routeLines[id] === undefined) {
        return;
      }

      let { routeLine, routeLineBorder } = this.routeLines[id];
      let zIndex = routeLine.getZIndex() + (active ? 1 : -1);
      this.setRouteColor(routeLine, active ? palette.secondaryDarker : palette.secondary);
      routeLine.setZIndex(zIndex);
      routeLineBorder.setZIndex(zIndex);
    };

    updateLine(oldHighlighted, false);
    updateLine(nextHighlighted, true);
  }

  setHandlers(routeLine, args) {
    routeLine.addEventListener('pointerdown', () => {
      if (!this.isSelectedTab()) {
        return;
      }
      this.props.setRoute(args.id);
      this.removeHandle();
    });
    routeLine.addEventListener('pointerenter', () => {
      if (!this.isSelectedTab()) {
        return;
      }
      this.props.setHighlightedRoute(args.id);
    });
    routeLine.addEventListener('pointerleave', () => {
      if (!this.isSelectedTab()) {
        return;
      }
      this.props.setHighlightedRoute(-1);
    });
  }

  setRubberBandingHandlers(routeLine, args) {
    routeLine.draggable = true;

    if (this.showNotificationOnRubberBanding) {
      routeLine.addEventListener('dragstart', () => this.props.setNotification({
        message: 'Public Transport Time Table mode is only supported for two waypoints',
        impact: 'significant',
        autoDismiss: 5,
      }));
      return;
    }

    routeLine.addEventListener('pointerenter', ::this.onPointerEnter);
    routeLine.addEventListener('pointermove', ::this.onPointerMove);
    routeLine.addEventListener('pointerleave', ::this.onPointerLeave);

    routeLine.addEventListener('pointerdown', ::this.onPointerDown);

    routeLine.addEventListener('dragstart', this.onDragHandleStart.bind(this, args));
    routeLine.addEventListener('drag', ::this.onDragHandle);
    routeLine.addEventListener('dragend', ::this.onDragHandleEnd);

    routeLine.addEventListener('pointerup', ::this.onPointerUp);
  }

  onPointerEnter() {
    this.addHandle();
  }

  onPointerMove(event) {
    if (!this.routeHandle) {
      return;
    }
    this.moveHandle(event.currentPointer.viewportX, event.currentPointer.viewportY, event.target.style.strokeColor);
  }

  onPointerLeave() {
    if (!this.isHandleDragging) {
      this.removeHandle();
    }
  }

  onPointerDown() {
    this.isHandleDragging = true;
  }

  onDragHandleStart(args, event) {
    event.stopPropagation();

    let { alternativesIgnoredNotify, resetAlternatives, currentForm = {} } = this.props;
    let alternatives = parseInt(utils.getObject(this.props, 'formData.fields.alternatives', '0'));
    if (alternatives) {
      alternativesIgnoredNotify();
      resetAlternatives(currentForm.present);
    }

    if (this.newWaypointIndex !== null) {
      return;
    }

    let { shape, waypoints, routeShape } = args;

    if (routeShape) {
      shape = routeShape;
    }

    let currentPointer = event.currentPointer;
    let cursorGeoPosition = this.map.screenToGeo(currentPointer.viewportX, currentPointer.viewportY);

    let closestPoint = utils.chunk(shape, this.pointArgsCount)
      .map((coords, point) => {
        let p = createPoint(...coords);
        let distance = p.distance(cursorGeoPosition);
        return { distance, point };
      })
      .reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr))
      .point;

    this.newWaypointIndex = _.findLastIndex(waypoints, waypoint => waypoint.shapeIndex < closestPoint) + 1;
  }

  onDragHandle(event) {
    if (!this.routeHandle) {
      return;
    }
    event.stopPropagation();

    let left = event.currentPointer.viewportX;
    let top = event.currentPointer.viewportY;

    this.moveHandle(left, top, event.target.style.strokeColor);

    if (this.lastViewportX === left && this.lastViewportY === top) {
      return;
    }

    // debouncing:
    clearInterval(this.onDragInterval);
    this.onDragInterval = setTimeout(this._onDragHandle.bind(this, event), 50);
    this.lastViewportX = left;
    this.lastViewportY = top;
  }

  _onDragHandle(event) {
    let pointer = event.currentPointer;
    let cursorGeoPosition = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);

    this.props.filterValueUpdate({
      currentForm: this.props.currentForm.present,
      key: 'resolution',
      value: {
        snap: { isChecked: true, value: calculateResolution(this.map) },
      },
    });

    let newFormat = this.props.formData.fields.useNewWaypointsFormat;
    let url = RouteHelper.addNewWaypointToUrl(this.props.apiUrl, cursorGeoPosition, this.newWaypointIndex, newFormat);

    fetch(url).then((responseData = {}) => {
      let { response = {} } = normalizeRoutingData(responseData).data;
      this.process(response.route);
    });
  }

  onDragHandleEnd(event) {
    this.isHandleDragging = false;
    if (!this.routeHandle) {
      return;
    }

    this.removeHandle();
    clearInterval(this.onDragInterval);

    let { viewportX, viewportY } = event.currentPointer;
    let { lat, lng } = this.map.screenToGeo(viewportX, viewportY);

    this.props.addWaypoint({
      index: this.newWaypointIndex,
      needsRerender: true,
      currentForm: this.props.currentForm.present,
      value: {
        coords: { value: `${lat},${lng}` },
        isWaypoint: true,
        type: 'passThrough',
      },
    });
    this.newWaypointIndex = null;
  }

  onPointerUp() {
    this.isHandleDragging = false;
    this.removeHandle();
  }

  addHandle() {
    if (this.routeHandle || !this.isSelectedTab()) {
      return;
    }
    this.initHandle();
    let vpElement = this.map.getViewPort().element;
    vpElement.appendChild(this.routeHandle);
    this.map.getElement().style.cursor = 'pointer';
  }

  initHandle() {
    this.routeHandle = document.createElement('div');
    this.routeHandle.className = 'route-handle';
  }

  removeHandle() {
    if (!this.routeHandle) {
      return;
    }
    this.routeHandle.parentNode.removeChild(this.routeHandle);
    this.map.getElement().style.cursor = 'default';
    this.routeHandle = null;
  }

  moveHandle(left, top, color = 'transparent') {
    let style = this.routeHandle.style;
    style.transform = `translate(calc(${left}px - 0.6rem),calc(${top}px - 0.6rem))`;
    style.backgroundColor = color;
  }

  renderMappedMarkers(point) {
    if (!point.mappedPosition) {
      return;
    }
    let group = this.getGroup();
    group.addObject(mapPolyline.createMappedLine(point));
    group.addObject(mapMarkers.createMappedMarker(point));
  }

  setAdditionalData(currentRoute = 0, pointArgsCount = 2, energy = {}) {
    this.setData({
      currentRoute,
      pointArgsCount,
      energy,
    });
  }

  isSelectedTab() {
    return this.formState.index === this.props.selectedTab;
  }
}
