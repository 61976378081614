import React from 'react';
import utils from 'utils';
import * as types from './constants';
import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';
import {
  getSettingsData,
  getCurrentPreset,
  getPreset,
} from 'state/settingsPreset';
import settingsUtils from 'utils/settings';
import { getTabs } from './index';
import { getTabTitle } from 'utils/tabs';
import * as WaypointsActions from './tab/formData/common/waypoints/actions';
import { fetchData } from './tab/response/actions';
import apiUrlSelector from '../selectors/apiUrlSelector';
import { DEFAULT_PRESET } from 'state/settingsPreset/defaultPresetsState';
import { getSelectedTab } from 'state/selectedTab';
import * as settingsPresetsActions from '../settingsPreset/settingsPresetActions';
import { getForm } from './tab/form';
import { getModule } from './tab/module';
import { getSettings } from './tab/settings';
import { getPresetByTitle } from '../settingsPreset/defaultPresetsState';
import { assignTabDataAndDispatch } from './tab/formData/actions';
import { getNewTabColor } from './tab/tabColorPalette';
import { setSelectedTab } from 'state/selectedTab/actions';
import { set as setNotification } from '../notification/actions';

const showNewRefClientNotification = utils.debounce(
  (dispatch) =>
    dispatch(
      setNotification({
        children: (
          <div>
            To experience most recent V8 features, please visit{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
//////////////////////////////
/////////////////////////////////////////////////////
///////////////////////
              // eslint-disable-next-line react/jsx-no-duplicate-props
              href="https://demo.routing.ext.here.com"
////////////////////////
            >
              New RefClient
            </a>
          </div>
        ),
        impact: 'positive',
      })
    ),
  200
);

const olsForms = [formsEnum.OLS];

export function addTab(
  tabData = { module: modulesEnum.ROUTING, form: formsEnum.CALCULATE_ROUTE }
) {
  return (dispatch, getState) => {
    const state = getState();
    if (!tabData.tabColorPalette) {
      tabData.tabColorPalette = getNewTabColor(state);
    }
    const { module, form } = tabData;
    const settingsData = getSettingsData(state);
    const preset = getCurrentPreset(state, module);
    tabData.settings = settingsUtils.getPresetSettings(
      module,
      form,
      settingsData,
      preset
    );
    tabData.title = getTabTitle(getTabs(state), module, form);
    dispatch({ type: types.ADD_TAB, tabData });

    if (olsForms.indexOf(tabData.form) > -1) {
      showNewRefClientNotification(dispatch);
    }

    if (!getPreset(state, module, form, preset)) {
      dispatch(settingsPresetsActions.setCurrentSettingsPreset(DEFAULT_PRESET));
    }
  };
}

export function removeTab(index) {
  return (dispatch, getState) => {
    let { selectedTab, tabs } = getState();
    const data = {};
    if (selectedTab >= index && selectedTab !== 0 && tabs.length > 1) {
      data.selectedTab = selectedTab - 1;
    }
    dispatch({ type: types.REMOVE_TAB, tabData: { index }, data });
    if (selectedTab === index) {
      const state = getState();
      const form = getForm(state);
      const module = getModule(state);
      const settings = getSettings(state);
      const preset = getPresetByTitle(module, form, settings.title);
      dispatch(
        settingsPresetsActions.setSettingsValue({
          module,
          currentForm: form,
          currentPreset: preset,
          value: settings,
        })
      );
      dispatch(settingsPresetsActions.setCurrentSettingsPreset(preset));
    }
  };
}

export function reorderTabs(indexes) {
  return (dispatch, getState) => {
    const data = {
      indexes,
      selectedTab: indexes.indexOf(getSelectedTab(getState())),
    };
    dispatch({ type: types.REORDER_TABS, data });
  };
}

export function openRoutingInNewTab(start, end) {
  return (dispatch, getState) => {
    const module = modulesEnum.ROUTING;
    const form = formsEnum.CALCULATE_ROUTE;
    dispatch(addTab({ module, form }));
    dispatch(WaypointsActions.update(getUpdateActionData(0, start), 0));
    dispatch(WaypointsActions.update(getUpdateActionData(1, end), 0));
    dispatch(fetchData(module, form, apiUrlSelector(getState()).apiUrl, 0));

    function getUpdateActionData(index, coordValue) {
      return {
        index,
        currentForm: form,
        value: { coords: { value: coordValue } },
      };
    }
  };
}

export function duplicateTab(index, data = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const tabData = {
      tabColorPalette: getNewTabColor(state),
      title: getTabTitle(
        getTabs(state),
        getModule(state, index),
        getForm(state, index)
      ),
      ...data,
    };

    dispatch(
      assignTabDataAndDispatch({ type: types.DUPLICATE_TAB, tabData }, index)
    );
    if (index !== getSelectedTab(state)) {
      dispatch(setSelectedTab(index));
    }
  };
}
