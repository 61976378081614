import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as TileActions from 'state/map/tiles/actions';

import LayerToggle from '../forms/common/LayerToggle';
import RasterTilesForm from './RasterTilesForm';
import { getTilesData } from 'state/map/tiles';
import { TILE_TYPES } from '../../state/map/tiles/constants';
import VectorTilesForm from './VectorTilesForm';
import CustomToggleButton from '../shared/toggleButton';
import FormRow from '../shared/formRow';

export class TilesContainer extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      formExpanded: false,
    };
  }

  onToggle = () => {
    const { formExpanded } = this.state;
    if (formExpanded) {
      this.setState({ formExpanded: false });
      window.removeEventListener('click', this.onToggle);
    } else {
      this.setState({ formExpanded: true });
      window.addEventListener('click', this.onToggle);
    }
  }

  onChangeTileType = () => {
    const { setTilesType, tilesData } = this.props;
    setTilesType(tilesData.type === TILE_TYPES.RASTER ? TILE_TYPES.VECTOR : TILE_TYPES.RASTER);
  }

  stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  renderForm() {
    const { setTilesData, tilesData } = this.props;
    const { type } = tilesData;
    const formData = tilesData[type];
    const onChange = setTilesData.bind(this, tilesData.type);
    switch (type) {
      case TILE_TYPES.RASTER:
        return <RasterTilesForm onChange={onChange} {...formData} />;
      case TILE_TYPES.VECTOR:
        return <VectorTilesForm onChange={onChange} {...formData} />;
      default:
        throw new Error(`Unsupported tiles type: "${type}"`);

    }
  }

  render() {
    const { formExpanded } = this.state;
    const tooltipText = formExpanded ? undefined : 'Map Tiles';
    const { tilesData } = this.props;

    return <div onClick={this.stopPropagation}>
      <div className="rf-map__tile-toggle">
        <LayerToggle
          active={false}
          onChange={this.onToggle}
          tooltipText={tooltipText}
        />
      </div>
      {formExpanded &&
      <div className="rf-map__tooltip rf-map__tiles-module">
        <FormRow cssClasses="rf-map__tiles-module__vector-toggle">
          <CustomToggleButton
            checked={tilesData.type === TILE_TYPES.VECTOR}
            onChange={this.onChangeTileType}
            label="Vector Tiles:"
          />
        </FormRow>
        {this.renderForm()}
      </div>
      }
    </div>;
  }
}

TilesContainer.propTypes = {
  setTilesData: PropTypes.func.isRequired,
  setTilesType: PropTypes.func.isRequired,
  tilesData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    tilesData: getTilesData(state),
  };
}


const mapDispatchToProps = {
  setTilesData: TileActions.setData,
  setTilesType: TileActions.setType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TilesContainer);
