import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';

export default {
  [modulesEnum.ROUTING]: {
    [formsEnum.CALCULATE_ROUTE]: props => {
      let { responseData = {} } = props,
        data = responseData.isError ? {} : responseData.data.response.route[0] || {};
      return !!(Object.keys(data || {}).length > 0 || responseData.isError);
    },
    [formsEnum.CALCULATE_MATRIX]: props => {
      let { responseData = {} } = props,
        data = responseData.isError ? {} : responseData.data.response || {};
      return !!(Object.keys(data || {}).length > 0 || responseData.isError);
    },
    [formsEnum.CALCULATE_ISOLINE]: props => {
      let { responseData = {} } = props,
        data = responseData.isError ? {} : responseData.data.response || {};
      return !!(Object.keys(data || {}).length > 0 || responseData.isError);
    },
    [formsEnum.GET_ROUTE_INFO]: props => {
      let { responseData = {} } = props,
        data = responseData.isError ? {} : responseData.data.response || {};
      return !!((data.route !== undefined && data.route.length !== 0) || responseData.isError);
    },
////////////////////
////////////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
//////
///////////////////////////////////////
////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
//////////////
    [formsEnum.OLS]: props => {
      let { responseData = {} } = props,
        data = responseData.isError ? {} : responseData || {};
      return !!(Object.keys(data || {}).length > 0 || responseData.isError);
    },
  },
//////////////////
/////////////////////////
////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
////
//////////////////////////
///////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
///////////////////////////////////////
////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
////
/////////////////////////////
///////////////////////////////////////
////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
////
//////////////////////
/////////////////////////////////
////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
////
//////////////////////////////////////
//////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
////
//////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
////
////////////
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: props => {
      let { responseData = {} } = props,
        data = responseData.isError ? {} : responseData.data.routes[0] || {};
      return !!(Object.keys(data || {}).length > 0 || responseData.isError);
    },
  },
};
