import PropTypes from 'prop-types';

let dataShape = PropTypes.shape({
  type: PropTypes.string,
  subtype: PropTypes.string,
  details: PropTypes.string,
  message: PropTypes.string,
});

const responseErrorShape = PropTypes.shape({
  isError: PropTypes.bool,
  data: dataShape,
});

export default responseErrorShape;
