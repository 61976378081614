import React, { Component } from 'react';
import PropTypes from 'prop-types';

import utils from 'utils';
import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
//////////
import ProductsField from './ProductsField';
import TransitModeField from './TransitModeField';
import RouteHelper from '../../../map/utils/RouteHelper';

import format from 'utils/format';
import tabColors from 'config/tabColors';
import CustomTooltip from 'shared/tooltip';
import { getHlsOptions, getHlsWaypoints } from './compareUtils';
import CompareMapFanButton from './CompareMapFanButton';
import CustomButton from '../../../shared/button';
import openOlsTab from '../../../contextMenu/routing/routing/openOlsTab';

export class InfoFields extends Component {
  getRouteColorEl() {
    return (
      <div
        style={{ background: this.props.color }}
        className="rf-result-panel__color"
      />
    );
  }

  // eslint-disable-next-line no-unused-vars
  getCompareButtons(formData) {
////////////////////
////////////
///////////
/////////////////
///////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////////
////////////
//////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////
/////////////////////
//////////////////////////////////
///////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////
////////////////
//////////////////////
////////////////
////////////////////////
/////////////////
//////////////////////////////
///////////////////////////////
//////////////////////////////////////
//////////////////////////////////////////
////////////
//////////////////
/////////////////
//////////////////////////////
///////////////////////////////
//////////////////////////////////////
//////////////////////////////////////////
////////////
//////////////////
////////////
//////
/////////////
    // eslint-disable-next-line no-unreachable
    return (
      <div>
        <FormRow>
          <CustomButton
            onClick={openOlsTab.bind(this, this.props)}
            title="Compare to Routing V8"
          />
        </FormRow>
      </div>
    );
//////////////
  }

  render() {
    let { formData } = this.props;
    let data = this.props.route,
      { summary = {}, mode = {} } = data;
    const totalConsumption = utils.getObject(
      this.props,
      `formData.rangeOnRoute.isActive`
    )
      ? RouteHelper.getTotalConsumptionByLegs(this.props.route.leg)
      : null;

    return (
      <div>
        <FormRow isHidden={!summary.baseTime}>
          <CustomInput
            type="text"
            label="Base time"
            isReadonly
            value={format.time(summary.baseTime)}
          />
        </FormRow>
        <FormRow isHidden={!summary.trafficTime}>
          <CustomInput
            type="text"
            label="Traffic time"
            isReadonly
            value={format.time(summary.trafficTime)}
          />
        </FormRow>
        <FormRow isHidden={!summary.mlTrafficTime}>
          <CustomInput
            type="text"
            label="ML Traffic time"
            isReadonly
            value={format.time(summary.mlTrafficTime)}
          />
        </FormRow>
        <FormRow isHidden={!summary.distance}>
          <CustomInput
            type="text"
            label="Distance"
            isReadonly
            value={format.distance(summary.distance, 'km+mi')}
          />
        </FormRow>
        <TransitModeField label="Transit Mode" isReadonly value={mode} />
        <ProductsField label="Products" value={data} />
        <FormRow isHidden={!summary.travelTime}>
          <CustomInput
            type="text"
            label="Travel Time"
            isReadonly
            value={format.time(summary.travelTime)}
          />
        </FormRow>
        <FormRow isHidden={!mode.type}>
          <CustomInput type="text" label="Type" isReadonly value={mode.type} />
        </FormRow>
        <FormRow>
          <CustomInput
            type="text"
            label="Color"
            isReadonly
            value={this.getRouteColorEl()}
          />
        </FormRow>
        <FormRow isHidden={!data.routeId}>
          <CustomInput
            cssClasses="rf-result-panel__route-id"
            type="text"
            label="Route ID"
            isCopyAble
            isReadonly
            value={data.routeId}
          />
        </FormRow>
        <FormRow isHidden={totalConsumption === null}>
          <CustomInput
            type="text"
            label="Total consumption"
            isReadonly
            value={totalConsumption}
          />
        </FormRow>
        {this.getCompareButtons(formData)}
      </div>
    );
  }
}

InfoFields.defaultProps = {
  color: tabColors[0].primary,
};

InfoFields.propTypes = {
  formData: PropTypes.object,
  route: PropTypes.object,
  color: PropTypes.string.isRequired,
};

export default InfoFields;
