import * as types from './constants';

import * as SearchActions from 'state/search/actions';
import { requestErrorNotifyDecorator } from 'state/notification/actions';

import utils from 'utils';
import { assignTabDataAndDispatch } from '../../actions';

export function update(data) {
  return assignTabDataAndDispatch({ type: types.UPDATE, data });
}

export function set(data) {
  return assignTabDataAndDispatch({ type: types.SET, data });
}

export function add(data, tabIndex) {
  return assignTabDataAndDispatch({ type: types.ADD, data }, tabIndex);
}

export function remove(data) {
  return assignTabDataAndDispatch({ type: types.REMOVE, data });
}

export function changeOrder(data) {
  return assignTabDataAndDispatch({ type: types.CHANGE_ORDER, data });
}

export function reverse(data) {
  return assignTabDataAndDispatch({ type: types.REVERSE, data });
}

export function getStreetAddress(args = {}) {
  let { index, coords, settings, currentForm } = args;
  return dispatch => {
    SearchActions.getStreetAddress(coords, settings).then(requestErrorNotifyDecorator(response => {
      if (response.isError) {
        dispatch(assignTabDataAndDispatch({
          type: types.SET_STREET,
          data: { currentForm, index, error: true, value: { streetSuggestions: {} } },
        }));
        return;
      }
      const RESULTS_PATH = 'data.response.view[0].result';
      const ADDRESS_PATH = 'location.address.street';
      const LABEL_PATH = 'location.address.label';
      const COORDS_PATH = 'location.displayPosition';
      let streetSuggestions = {};
      utils.getObject(response, RESULTS_PATH).forEach(result => {
        let coords = utils.getObject(result, COORDS_PATH);
        let street = utils.getObject(result, ADDRESS_PATH);
        let name = !street ? utils.getObject(result, LABEL_PATH) : street;
        streetSuggestions[name] = `${coords.latitude},${coords.longitude}`;
      });
      dispatch(assignTabDataAndDispatch({
        type: types.SET_STREET,
        data: { currentForm, index, value: { streetSuggestions } },
      }));
    }, dispatch));
  };
}

export function getLinkId(coords, currentForm, settings, index) {
  return dispatch => {
    SearchActions.getLinkId(coords, settings)
      .then(requestErrorNotifyDecorator(response => {
        if (response.isError) {
          return;
        }
        const REFERENCE_ID_PATH = 'data.response.view[0].result[0].location.mapReference.referenceId';
        let referenceId = utils.getObject(response, REFERENCE_ID_PATH);
        if (!referenceId) {
          return;
        }
        let link = { value: `-${referenceId}` };
        dispatch(assignTabDataAndDispatch({
          type: types.UPDATE,
          data: { value: { link }, currentForm, index },
        }));
      }, dispatch));
  };
}
