import React from 'react';

import CustomComponent from 'shared/component';

import './styles/loader.scss';

export default class Loader extends CustomComponent {
    render() {
      return (
        <div className="rf-loader"></div>
      );
    }
}
