export default function exec(data) {
  let { olsReturn, pnrChangeStrategy, intermodalModes, transitModes, ..._data } = data;

  _data.return = olsReturn;
  delete _data.selectedManeuver;
  delete _data.selectedSpan;

  if (pnrChangeStrategy) {
    _data.via = `place:parkingLot;strategy=${pnrChangeStrategy}`;
  }

  let vehicleModes = [];
  if (intermodalModes.car) {
    vehicleModes.push('car');
  }
  if (intermodalModes.bicycle) {
    vehicleModes.push('bicycle');
  }
  if (vehicleModes.length > 0) {
    _data['vehicle[modes]'] = vehicleModes.join(',');
    _data['vehicle[enable]'] = 'routeHead';
  } else {
    _data['vehicle[enable]'] = '';
  }

  if (intermodalModes.taxi) {
    _data['taxi[modes]'] = 'car';
    _data['taxi[enable]'] = 'routeHead,routeTail,entireRoute';
  } else {
    _data['taxi[modes]'] = '-car';
  }

  if (intermodalModes.bicycleShare) {
    _data['rented[modes]'] = 'bicycle';
    _data['rented[enable]'] = 'routeHead,routeTail,entireRoute';
  } else {
    _data['rented[modes]'] = '-car,-bicycle';
  }

  const excludedTransitModes = Object.entries(transitModes)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, modeIncluded]) => !modeIncluded);

  if (excludedTransitModes) {
    _data['transit[modes]'] = excludedTransitModes.map(([mode]) => `-${mode}`).join(',');
  }

  return _data;
}
