import formsEnum from 'config/formsEnum';
import CalculateRouteValidation from './calculateRouteValidation';
import CalculateMatrixValidation from './calculateMatrixValidation';
import CalculateIsolineValidation from './calculateIsolineValidation';
import GetRouteValidation from './getRouteValidation';

export default {
  [formsEnum.CALCULATE_ROUTE]: CalculateRouteValidation,
  [formsEnum.CALCULATE_MATRIX]: CalculateMatrixValidation,
  [formsEnum.CALCULATE_ISOLINE]: CalculateIsolineValidation,
  [formsEnum.GET_ROUTE_INFO]: GetRouteValidation,
};
