import consumptionDefaultState from '../../common/consumptionDefaultState';

export default {
  routeDate: {
    key: '',
    value: '',
  },
  selectedPoint: '',
  hoveredPoint: '',
  mode: {
    type: 'fastest',
    transport: 'car',
    traffic: '',
    features: {
      tollroad: { value: '-3', isChecked: false },
      motorway: { value: '-3', isChecked: false },
      boatFerry: { value: '-3', isChecked: false },
      railFerry: { value: '-3', isChecked: false },
      tunnel: { value: '-3', isChecked: false },
      dirtRoad: { value: '-3', isChecked: false },
      park: { value: '-3', isChecked: false },
    },
  },
  routeattributes: {
    aggregation: '',
    attributes: ['sh', 'gr'],
    _default: ['sh', 'gr'],
    isExpanded: false,
  },
  legattributes: {
    aggregation: '',
    attributes: [],
    _default: [],
    isExpanded: false,
  },
  maneuverattributes: {
    aggregation: '',
    attributes: ['po', 'ti', 'pt', 'ac', 'di', 'fj', 'ix'],
    _default: ['po', 'ti', 'pt', 'ac', 'di', 'fj', 'ix'],
    isExpanded: false,
  },
  linkattributes: {
    aggregation: '',
    attributes: [],
    _default: [],
    isExpanded: false,
  },
  lineattributes: {
    aggregation: '',
    attributes: [],
    _default: [],
    isExpanded: false,
  },
  combinechange: '',
  returnelevation: '',
  alternatives: '',
  metricsystem: 'metric',
  speedprofile: '',
  generalizationtolerances: {
    value: {
      tolerance: '',
      isPreset: false,
    },
    isValid: true,
  },
  resolution: {
    view: {
      value: '',
      isChecked: false,
    },
    snap: {
      value: '',
      isChecked: false,
    },
  },
  distancebeforefirstturn: '',
  avoidseasonalclosures: '',
  avoidlinks: {
    value: '',
    isValid: true,
  },
  maxnumberofchanges: {
    value: '',
    isValid: true,
  },
  walkspeed: {
    value: '',
    isValid: true,
  },
  walkradius: {
    value: '',
    isValid: true,
  },
  jsonattributes: {
    lowerCaseFirstCharacter: true,
    includeTypeElement: true,
    usePluralNaming: false,
    shapesToArraysWithPairs: true,
    shapesToArraysWithTriplets: false,
    jsonWrapper: false,
  },
  extraparams: {
    value: '',
    isValid: true,
  },
  avoidTransportTypes: [],
  language: 'en-us',
  excludecountries: '',
  excludezones: {
    value: '',
    isValid: true,
  },
  allowedzones: {
    value: '',
    isValid: true,
  },
  excludezonetypes: {
    value: [],
    isValid: true,
  },
  licenseplate: {
    value: '',
    isValid: true,
  },
  vehicletype: {
    type: '',
    consumption: {
      value: '5.5',
      isValid: true,
    },
  },
  enhancedbicyclerouting: '',
  useNewWaypointsFormat: false,
  computemltraffictime: false,
  ...consumptionDefaultState,
};
