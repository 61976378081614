import utils from 'utils';

import * as mapMarkers from '../../../utils/MarkersHelper';
import { addOpacityToHexColor } from 'config/map';

import MarkersBase from '../../../modules/_MarkersBase';

import './styles/markers.scss';

export default class Markers extends MarkersBase {
  constructor(...args) {
    super(...args);
    this.isMarkerDraggable = true;
    this.infoBubbles = {};
    this.isLabelShown = false;
  }

  clearGroup(...args) {
    utils.iterObject(this.infoBubbles, bubble => {
      mapMarkers.removeInfoBubble(bubble);
    });
    super.clearGroup(...args);
  }

  renderChargingStation(latLon) {
    let group = this.getGroup();
    let color = this.props.tabData.tabColorPalette.primary;
    let strokeColor = this.props.tabData.tabColorPalette.primaryDarker;
    let marker = mapMarkers.createMainMarker({
      point: {
        value: latLon,
      },
      title: '&#x1f50b;',
      currentRoute: 0,
      color,
      strokeColor,
    });
    group.addObject(marker);
  }

  process(waypoints = [], currentRoute, route = {}) {
    let group = this.getGroup();

    this.clearGroup();

    if (route.waypoint) {
      const extraWaypoints = route.waypoint.filter(waypoint => waypoint.source === 'system');

      if (extraWaypoints.length) {
        extraWaypoints.forEach(waypoint => {
          this.renderChargingStation(`${waypoint.mappedPosition.latitude},${waypoint.mappedPosition.longitude}`);
        });
      }
    }

    waypoints = waypoints.filter(waypoint => {
      if (waypoint.isChargingStation) {
        this.renderChargingStation(waypoint.coords.value);
      }
      return !waypoint.isChargingStation;
    });

    waypoints.forEach((point, idx) => {
      let waypoints = route.waypoint || [];
      let routePosition = (waypoints[idx] || {}).originalPosition || {};
      let routeCoords = `${routePosition.latitude},${routePosition.longitude}`;
      let pointCoords;
      if (point.displayPosition && point.displayPosition.isValid && point.displayPosition.value !== '') {
        pointCoords = point.displayPosition;
      } else {
        pointCoords = point.coords || {};
      }
      let coords = pointCoords.value ? pointCoords :
        { isValid: routePosition.latitude !== undefined, value: routeCoords };
      if (coords.isValid && coords.value) {
        let currentTabIndex = this.props.tabs.findIndex((tab) => tab.id === this.props.tabData.id);
        let marker = this.renderMarker(coords, idx, point.isWaypoint, currentTabIndex);
        let transitRadius = parseInt(point.transitRadius);
        if (transitRadius) {
          this.addTransitRadius(marker, coords.value.split(','), transitRadius);
        }
        this.applyLabel(marker, point, this.props.tabData.tabColorPalette.primaryDarker);

        group.addObject(marker);
      }
    });
  }

  addTransitRadius(marker, coords, transitRadius) {
    let group = this.getGroup();
    let circle = mapMarkers.createCircle(coords, transitRadius, {
      style: {
        strokeColor: this.props.tabData.tabColorPalette.primaryDarker,
        lineWidth: 1,
        lineDash: [3, 2],
        fillColor: addOpacityToHexColor(this.props.tabData.tabColorPalette.primary, 0.25),
      },
    });
    let addCircle = () => group.addObject(circle);
    let removeCircle = () => {
      if (group.contains(circle)) {
        group.removeObject(circle);
      }
    };

    marker.addEventListener('pointerenter', addCircle);
    marker.addEventListener('pointerleave', removeCircle);
    marker.addEventListener('dragstart', removeCircle);
    marker.addEventListener('dragend', addCircle);
  }

  showBubble(e) {
    if (mapMarkers.isMarker(e.target)) {
      let index = utils.getObject(e.target.getData(), 'index');
      let infoBubble = this.infoBubbles[index];
      if (infoBubble && infoBubble.getState() !== 'open') {
        infoBubble.open();
      }
    }
  }

  onDragStart() {
    this.map.getElement().style.cursor = 'move';
  }

  onDragEnd(data, value) {
    this.props.waypointUpdate({
      index: data.index,
      value: { category: 'geo', coords: { value } },
      needsRerender: true,
      currentForm: this.props.currentForm.present,
    });
  }

  renderMarker(point, index, isWaypoint, currentRoute) {
    let
      title = (() => {
        if (isWaypoint) {
          return index;
        }

        return index === 0 ? 'A' : 'B';
      })();
    let color = this.props.tabData.tabColorPalette.primary;
    let strokeColor = this.props.tabData.tabColorPalette.primaryDarker;
    let marker = mapMarkers.createMainMarker({ point, title, currentRoute, color, strokeColor });
    marker.draggable = this.isMarkerDraggable && this.formState.index === this.props.selectedTab;
    marker.setData(Object.assign({}, marker.getData() || {}, { index, type: 'MAIN' }));
    return marker;
  }
}
