function isInteger(value) {
  return value === (+value).toFixed();
}

export default function getIntegerValidator(args = {}) {
  let { min = -Infinity, max = Infinity } = args;
  return value => {
    let errors = [];
    if (!isInteger(value) || (+value) < min || (+value) > max) {
      if (min === -Infinity && max === Infinity) {
        errors.push(`'${value}' should be integer value`);
      } else if (min === 0 && max === Infinity) {
        errors.push(`'${value}' should be positive integer value`);
      } else {
        errors.push(`'${value}' should be integer value in range [${min}...${max}]`);
      }
    }
    return errors;
  };
}

export function getIntegerSequenceValidation(args = {}) {
  const integerValidator = getIntegerValidator(args);
  return value => {
    let errors = [];
    value.split(',').forEach(val => {
      const valErrors = integerValidator(val);
      if (valErrors.length > 0) {
        errors.push(valErrors[0]);
      }
    });

    return errors;
  };
}
