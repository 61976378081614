import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomCheckboxList from 'shared/checkboxList';
import ExpandFormRow from 'shared/expandFormRow';

import utils from 'utils';

class CheckboxAttributesSet extends Component {
  onChange(e) {
    let data = utils.clone(this.props.data),
        { value, checked } = e.target,
        index = data.indexOf(value);
    if (index !== -1 && !checked) {
      data.splice(index, 1);
    } else if (index === -1 && checked) {
      data.push(value);
    }

    this.props.onChange(data, e);
  }

  render() {
    let { label, data, options, isExpanded } = this.props,
        params = options.map(item => ({
            label: item.label,
            value: item.value,
            disabled: item.disabled,
            isChecked: data.indexOf(item.value) !== -1,
            onChange: this.onChange.bind(this),
        }), this);
    return (
      <ExpandFormRow label={label} isExpanded={isExpanded}>
        <CustomCheckboxList params={params} />
      </ExpandFormRow>
    );
  }
}

CheckboxAttributesSet.defaultProps = {
  isExpanded: false,
};

CheckboxAttributesSet.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default CheckboxAttributesSet;
