import Isoline from './isoline';
import Matrix from './matrix';
import RouteInfo from './routeInfo';
import Routing from './routing';
import ApproachPathRouter from './approachPathRouter';
import MatchRoute from './matchRoute';
import olsApi from './olsApi';
import formsEnum from 'config/formsEnum';

export default {
  [formsEnum.CALCULATE_ROUTE]: Routing,
  [formsEnum.CALCULATE_MATRIX]: Matrix,
  [formsEnum.CALCULATE_ISOLINE]: Isoline,
  [formsEnum.GET_ROUTE_INFO]: RouteInfo,
  [formsEnum.APPROACH_PATH_ROUTER]: ApproachPathRouter,
  [formsEnum.MATCH_ROUTE]: MatchRoute,
  [formsEnum.OLS]: olsApi,
};
