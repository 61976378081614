import FormBase from '../../_Base';
import RouteByPoints from './RouteByPoints';

export default class VisualizeDataPointsMap extends FormBase {
  initModules() {
    return {
      route: new RouteByPoints(this.map, this.state),
    };
  }

  process(props, nextProps, forceRender) {
    if (this.isPropChanged(nextProps, 'tabData.formData.routeFromPoints.timestamp')
      || this.isPropChanged(nextProps, 'tabData.formData.routeFromPoints.showPolyline')
      || this.isPropChanged(nextProps, 'tabData.formData.routeFromPoints.showPoints')
      || forceRender) {
      this.modules.route.process(nextProps);
      this.setViewBounds();
    }

    super.process(props, nextProps, forceRender);
    this.setProps(nextProps);
  }

  setViewBounds(force = false) {
    super.setViewBounds(this.modules.route.getGroup(), force);
  }
}
