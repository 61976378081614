import utils from 'utils';
import defaultSettings, { DEFAULT_PRESET } from 'state/settingsPreset/defaultPresetsState';
import { getCurrentPresetFromSettingsData } from 'state/settingsPreset';

const localStorageExcludeKeys = ['urlQuery.token', 'urlQuery.tokenExpiry'];

function _get(key) {
  const dataStr = localStorage.getItem(key) || '{}';
  let result = {};
  try {
    result = JSON.parse(dataStr);
    result = _.merge(result, JSON.parse(sessionStorage.getItem(key) || '{}'));
  } catch (e) {
    // continue regardless of error
  }

  return result || {};
}

function getKey(args) {
  const { currentForm, module, currentPreset } = args;
  return `config.settings.v2.${module}.${currentForm}.${currentPreset}`;
}

function setSettings(settings, args) {
  const { currentForm, module, currentPreset } = args;
  const defaultPresetSettings = _.get(defaultSettings, [module, currentForm, currentPreset]);
  const localStorageSettings = _.omit(settings, localStorageExcludeKeys);
  if (_.isEqual(localStorageSettings, _.omit(defaultPresetSettings, localStorageExcludeKeys))) {
    localStorage.removeItem(getKey(args));
  } else {
    localStorage.setItem(getKey(args), JSON.stringify(localStorageSettings));
  }
  sessionStorage.setItem(getKey(args), JSON.stringify(_.pick(settings, localStorageExcludeKeys)));
}

function getSettings(args) {
  return _get(getKey(args));
}

function getCurrentCredentials(settingsData, currentForm, module, preset) {
  let result = {};
  let { settingsPresets } = settingsData;
  let currentPreset = preset || getCurrentPresetFromSettingsData(settingsData, module);
  let storedData = getSettings({ currentPreset, currentForm, module }).urlQuery || {};
  let getUrlQuery = (ctx) => {
    let path = [module, currentForm, currentPreset, 'urlQuery'].join('.');
    return utils.getObject(ctx, path);
  };
  let currUrlQuery = getUrlQuery(settingsPresets);
  if (!currUrlQuery) {
    return null;
  }
  ['app_code', 'app_id', 'authType', 'token', 'proxy', 'user', 'pass', 'auth_server', 'tokenExpiry', 'apikey']
    .forEach(attr => {
      if (storedData[attr] !== undefined && storedData[attr] !== '') {
        result[attr] = storedData[attr];
      } else {
        result[attr] = currUrlQuery[attr];
      }
    });
  return result;
}

function getPresetSettings(module, form, settingsData, preset) {
  let currentPreset = preset || getCurrentPresetFromSettingsData(settingsData, module);
  const getPreset = (preset) =>
    utils.clone(utils.getObject(settingsData, `settingsPresets.${module}.${form}.${preset}`));
  let result = getPreset(currentPreset) || getPreset(DEFAULT_PRESET);
  if (!result) {
    return null;
  }
  return _.merge(_.cloneDeep(result), getSettings({ module, currentForm: form, currentPreset }));
}

export default {
  setSettings,
  getCurrentCredentials,
  getPresetSettings,
  getSettingsFromLocalStorage: getSettings,
};
