import handlers from './customHandlers';
import truckFormatter from './common/truckFormatter';

export default {
  ...truckFormatter,
  start: handlers.pointsFormatter.bind(handlers, 'start'),
  destination: handlers.pointsFormatter.bind(handlers, 'destination'),
  avoidAreas: handlers.avoidAreasFormatter,
  avoidlinks: handlers.objectFormatter,
  mode: handlers.modeFormatter,
  matrixAttributes: handlers.arrayFormatter,
  summaryAttributes: handlers.arrayFormatter,
};
