import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { parseString } from 'xml2js';
import formsEnum from 'config/formsEnum';

export default class ImportEVParameters extends React.Component {
  static propTypes = {
    setNotification: PropTypes.func.isRequired,
    waypointUpdate: PropTypes.func.isRequired,
    filterValueMultiUpdate: PropTypes.func.isRequired,
  }

  readFile = (e) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      parseString(e.target.result, async (err, res) => {
        if (err) {
          this.props.setNotification({
            children: <div>
              Wrong XML format
            </div>,
            impact: 'negative',
          });
        }

        const start = res.route_plan.waypoint[0].access_point[0].$;
        const end = res.route_plan.waypoint[res.route_plan.waypoint.length - 1].access_point[0].$;

        const routeOptions = res.route_plan.route_options[0];
        const evOptions = routeOptions.ev_options[0];
        const consumptionParams = evOptions.consumption_params[0];

        const routeType = routeOptions.$.route_type.toLowerCase();
        const routingMode = routeOptions.$.routing_mode.toLowerCase();

        const minBatteryCharge = evOptions.$.min_battery_charge;
        const minBatteryChargeAtFirstChargingWaypoint = evOptions.$.min_battery_charge_at_first_charging_waypoint;
        const startBatteryCharge = evOptions.$.start_battery_charge;
        const maxBatteryCharge = evOptions.$.max_battery_charge;

        const accelMult = consumptionParams.$.accel_mult;
        const ascentMult = consumptionParams.$.ascent_mult;
        const auxiliaryCons = consumptionParams.$.auxiliary_cons;
        const decelMult = consumptionParams.$.decel_mult;
        const descentMult = consumptionParams.$.descent_mult;
        const turnTimeMult = consumptionParams.$.turn_time_mult;

        const speedParams = consumptionParams.speed_param
          .map(speedParam => `${speedParam.$.speed},${speedParam.$.value}`).join(',');

        const chargingCurvePoint = evOptions.charging_curve_point
          .map(chargingCurvePoint => `${chargingCurvePoint.$.point},${chargingCurvePoint.$.rate}`).join(',');

        const wrap = value => ({ value, isValid: true });

        this.props.waypointUpdate({
          currentForm: formsEnum.CALCULATE_ROUTE,
          value: { coords: { value: `${start.lat},${start.lng}` } },
          index: 0,
        });

        this.props.waypointUpdate({
          currentForm: formsEnum.CALCULATE_ROUTE,
          value: { coords: { value: `${end.lat},${end.lng}` } },
          index: 1,
        });

        this.props.filterValueMultiUpdate(formsEnum.CALCULATE_ROUTE, {
          consumptionmodel: 'electric',

          batteryparameters: {
            minchargeatstop: wrap(minBatteryCharge / 10000),
            minchargeatfirstchargingstop: wrap(minBatteryChargeAtFirstChargingWaypoint / 10000),
            initialcharge: wrap(startBatteryCharge / 10000),
            maxcharge: wrap(maxBatteryCharge / 10000),
            chargingcurve: wrap(chargingCurvePoint),
          },

          customconsumptiondetails: {
            acceleration: wrap(accelMult),
            ascent: wrap(ascentMult),
            auxiliaryconsumption: wrap(auxiliaryCons),
            deceleration: wrap(decelMult),
            descent: wrap(descentMult),
            turnpenalty: wrap(turnTimeMult),
            freeflowspeedtable: wrap(speedParams),
          },

          mode: {
            transport: routingMode,
            type: routeType,
          },
        }, true);
      });
    };
    reader.readAsText(e.target.files[0]);
  }

  resetInputFile = () => {
    ReactDOM.findDOMNode(this.refs.inputFile).value = '';
  }

  render() {
    return (
      <div onClick={this.resetInputFile}>
        <h3>Import EV Parameters from a file: </h3>
        <input type="file" onChange={this.readFile} ref="inputFile" />
      </div>
    );
  }
}
