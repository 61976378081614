import React from 'react';
import PropTypes from 'prop-types';

import responseDataShape from 'state/tabs/tab/response/routing/responseDataShape';

import ResultPanelFormBase from '../../_ResultPanelFormBase';
import ResultRouteLegend from './ResultRouteLegend';
import ManeuversList from './ManeuversList';
import InfoFields from './InfoFields';

import CustomTabs from 'shared/tabs';
import CustomChart from 'shared/chart';

import uPointArgsCount from '../../../../utils/response/pointArgsCount';

import './styles/resultPanelForm.scss';
import EVTab from './EVTab';
import utils from 'utils';
import formsEnum from '../../../../config/formsEnum';

class ResultPanelForm extends ResultPanelFormBase {
  componentDidMount() {
    const { filterValueMultiUpdate, formData: { fields: { rangeOnRoute } } } = this.props;
    const hasResponse = _.get(this.props, 'responseData.isError') === false;
    if (!this.props.formData.fields.requestedRangeOnRoute && hasResponse) {
      filterValueMultiUpdate(formsEnum.CALCULATE_ROUTE, {
        requestedRangeOnRoute: { ...rangeOnRoute },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (utils.isPropChanged(prevProps, this.props, 'responseData.data.response.metaInfo.timestamp')) {
      const { filterValueMultiUpdate, formData: { fields: { rangeOnRoute } } } = this.props;
      filterValueMultiUpdate(formsEnum.CALCULATE_ROUTE, {
        requestedRangeOnRoute: { ...rangeOnRoute },
      });
    }
  }

  onMouseOver(index) {
    this.props.setHighlightedRoute(index);
  }

  onMouseOut() {
    this.props.setHighlightedRoute(-1);
  }

  getRouteLegend(routes) {
    if (Object.keys(routes).length < 2) return '';

    return <ResultRouteLegend
      onMouseOver={::this.onMouseOver}
      onMouseOut={::this.onMouseOut}
      responseData={routes}
      setRoute={this.props.setRoute}
      currentRoute={this.props.currentRoute}
      highlightedRoute={this.props.highlightedRoute}
      selectedTabData={this.props.selectedTabData}
    />;
  }

  getManeuversTabContent(data, pointArgsCount = 2) {
    return (
      <ManeuversList
        data={data}
        highlightManeuver={this.props.highlightManeuver}
        pointArgsCount={pointArgsCount}
      />
    );
  }

  getInfoTabContent(route, formData) {
    return (
      <div className="rf-result-panel__tabs-gap">
        <InfoFields
          formData={formData}
          route={route}
          color={this.getTabColor()}
          addTab={this.props.addTab}
          filterValueMultiUpdate={this.props.filterValueMultiUpdate}
        />
      </div>
    );
  }

  prepareChartData(data) {
    let result = { x: [], y: [] },
      distance = 0,
      p1 = new H.geo.Point(data.shape[0], data.shape[1]);

    for (let i = 2; i < data.shape.length; i += 3) {
      let p2 = new H.geo.Point(data.shape[i - 2], data.shape[i - 1]);

      distance += p1.distance(p2) / 1000;

      result.x.push(distance);
      result.y.push(data.shape[i]);
      p1 = p2;
    }

    return result;
  }

  onMouseOutElevationChart() {
    this.props.mapObjectUpdate({
      key: 'hoveredPoint',
      currentForm: this.props.currentForm,
      value: '',
    });
  }

  onElevationChartEvent(data, key, obj) {
    let pointArgsCount = this.getPointArgsCount(),
      startIndex = obj.index * pointArgsCount,
      point = data.shape.slice(startIndex, startIndex + pointArgsCount);

    this.props.mapObjectUpdate({
      key,
      currentForm: this.props.currentForm,
      value: point.slice(0, 2).join(','),
    });
  }

  getProfileTabContent(data) {
    let
      values = this.prepareChartData(data),
      selectedPoint = this.props.formData.selectedPoint,
      chartData = {
        x: 'x',
        selection: {
          enabled: true,
          multiple: false,
        },
        columns: [
          ['x'].concat(values.x),
          ['point'].concat(values.y),
        ],
        colors: {
          point: this.props.selectedTabData.tabColorPalette.primary,
        },
        types: {
          point: 'area',
        },
      },

      options = {
        onrendered() {
          let selectedCoords = (selectedPoint || '').split(',').map(i => parseFloat(i)),
            selectedIndex = null;
          if (selectedCoords.length) {
            for (let i = 2; i < data.shape.length; i += 3) {
              if (selectedCoords[0] === data.shape[i - 2] && selectedCoords[1] === data.shape[i - 1]) {
                selectedIndex = Math.floor(i / 3);
                break;
              }
            }
          }

          if (selectedIndex !== null) {
            this.api.select(['point'], [selectedIndex]);
          }
        },

        axis: {
          x: {
            label: 'Distance (km)',
            tick: {
              count: 10,
              format: x => x.toFixed(2),
            },
          },
          y: {
            label: {
              text: 'Altitude (m)',
              position: 'inner-bottom',
            },
          },
        },

        point: {
          r: 0,
          focus: {
            expand: {
              r: 6,
              fill: 'red',
            },
          },
        },
      };

    return (
      <CustomChart
        data={chartData}
        options={options}
        onClick={this.onElevationChartEvent.bind(this, data, 'selectedPoint')}
        onMouseOver={this.onElevationChartEvent.bind(this, data, 'hoveredPoint')}
        onMouseOut={this.onMouseOutElevationChart.bind(this)}
      />
    );
  }

  getEVTabContent(data) {
    const { formData, responseData, selectedTabData: { tabColorPalette }, currentRoute } = this.props;
    return (
      <EVTab
        route={data}
        formData={formData}
        responseData={responseData}
        color={tabColorPalette.primary}
        currentRoute={currentRoute}
      />
    );
  }

  getPointArgsCount() {
    return uPointArgsCount(this.props.formData.fields.jsonattributes, this.props.formData.fields.returnelevation);
  }

  getRoutes(response) {
    return response.route;
  }

  getResponseForm(response, currentRoute) {
    let routes = this.getRoutes(response);
    if (!routes.length) {
      return null;
    }

    let data = routes[currentRoute] || {},
        formData = this.props.formData;

    let tabs = [
      { title: 'Summary', content: this.getInfoTabContent(data, formData) },
      { title: 'Maneuvers', content: this.getManeuversTabContent(data, this.getPointArgsCount()) },
    ];

    if (formData.fields.returnelevation && this.getPointArgsCount() === 3) {
      tabs.push(
        { title: 'Elevation', content: this.getProfileTabContent(data) }
      );
    }

    if (formData.fields.consumptionmodel === 'electric') {
      tabs.push(
        { title: 'EV', content: this.getEVTabContent(data) }
      );
    }

    tabs.push(
      { title: 'Response', content: this.getResponseTabContent(response) }
    );

    return (
      <CustomTabs cssClasses="rf-result-panel__tabs" data={tabs} />
    );
  }

  render() {
    let { responseData = {}, currentRoute } = this.props,
        { response = {} } = responseData.data,
        routeLegendEl = this.getRouteLegend(this.getRoutes(response)),
        responseFormEl = this.getResponseForm(response, currentRoute || 0);
    return (
        <div>
          {routeLegendEl}
          {responseFormEl}
          {this.getInspectLinkData()}
          {this.getSearchResults()}
        </div>
    );
  }
}

ResultPanelForm.propTypes = {
  responseData: responseDataShape,
  formData: PropTypes.object.isRequired,
  inspectLink: PropTypes.object.isRequired,
  selectedTabData: PropTypes.object.isRequired,
  setRoute: PropTypes.func.isRequired,
  setHighlightedRoute: PropTypes.func.isRequired,
  onShowFormattedResult: PropTypes.func.isRequired,
  onRemoveInspectLink: PropTypes.func.isRequired,
  mapObjectUpdate: PropTypes.func.isRequired,
  highlightManeuver: PropTypes.func.isRequired,
  currentRoute: PropTypes.number.isRequired,
  highlightedRoute: PropTypes.number.isRequired,
};

export default ResultPanelForm;
