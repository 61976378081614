import React from 'react';
import PropTypes from 'prop-types';
import JSONTree from 'react-json-tree';

import CustomComponent from 'shared/component';
import './styles/tree.scss';

class Tree extends CustomComponent {
  render() {
    return (
      <div className={this.getCssClasses('rf-tree')}>
        <JSONTree
          hideRoot {...this.props}
          theme={{ base00: 'rgba(0, 0, 0, 0)' }}
          invertTheme={false}
        />
      </div>
    );
  }
}

Tree.defaultProps = {
  expandAll: false,
  data: {},
};

Tree.propTypes = {
  expandAll: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default Tree;
