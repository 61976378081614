import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as NotificationActions from 'state/notification/actions';
import * as MatchRouteMapConfigActions from 'state/tabs/tab/formData/routingTab/matchRouteForm/mapConfig/actions';
import LayerToggle from '../forms/common/LayerToggle';
import { getCurrentFormData } from 'state/tabs/tab/formData';

import { getForm } from 'state/tabs/tab/form';
import formsEnum from '../../config/formsEnum';
import MatchRouteConfigForm from './MatchRouteConfigForm';

class MatchRouteConfigContainer extends Component {
  constructor(...args) {
    super(...args);
    this.state = { formExpanded: false };
  }

  onToggleForm() {
    this.setState({
      formExpanded: !this.state.formExpanded,
    });
  }

  onChange(attr, e) {
    const attrToAction = {
      renderRoutePlan: this.props.setRenderRoutePlan,
      renderLegSplits: this.props.setRenderLegSplits,
    };
    attrToAction[attr](e.target.checked);
  }

  updateFilterValue(key, value) {
    let { filterValueUpdate, currentForm } = this.props;
    filterValueUpdate({ currentForm, key, value });
  }

  renderForm(isActive) {
    return <MatchRouteConfigForm
      isActive={isActive}
      onChange={::this.onChange}
      onBlur={::this.onToggleForm}
      formData={this.props.formData}
    />;
  }

  render() {
    if (this.props.currentForm !== formsEnum.MATCH_ROUTE) {
      return null;
    }

    let tooltipText = this.state.formExpanded ? undefined : 'Match Route Config';

    return <div>
      <div className="rf-map__matchRouteConfig-toggle">
        <LayerToggle
          active={this.state.formExpanded}
          disabled={false}
          onChange={::this.onToggleForm}
          tooltipText={tooltipText}
        />
      </div>
      {this.state.formExpanded && this.renderForm(true)}
    </div>;
  }
}

MatchRouteConfigContainer.propTypes = {
  formData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  setRenderRoutePlan: PropTypes.func.isRequired,
  setRenderLegSplits: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    formData: getCurrentFormData(state),
    currentForm: getForm(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
    setRenderRoutePlan: bindActionCreators(MatchRouteMapConfigActions.setRenderRoutePlan, dispatch),
    setRenderLegSplits: bindActionCreators(MatchRouteMapConfigActions.setRenderLegSplits, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchRouteConfigContainer);

