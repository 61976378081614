import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import ExpandFormRow from 'shared/expandFormRow';

import _RoutingFormBase from '../common/_RoutingFormBase';
import GeneralisationsField from '../common/GeneralisationsField';
import ResolutionField from '../common/ResolutionField';

import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';

import languagesList from 'config/languagesList';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import ModeFieldSet from '../common/ModeFieldSet';
import RouteDateField from '../common/RouteDateField';

export class RouteInfoForm extends _RoutingFormBase {
  getMiscellaneousTabContent(formConfig) {
    let { fields = {} } = this.props.formData,
      {
        combinechange,
        returnelevation,
        metricsystem,
        generalizationtolerances,
        resolution,
        extraparams,
      } = fields;
    return (
      <div>
        <ul className="rf-icons-bar" />
        <FormRow>
          <CustomSelect
            label="Combine change"
            options={formConfig.bool}
            value={combinechange}
            addEmptyOption
            onChange={this.onChange.bind(this, 'combinechange')}
          />
        </FormRow>
        <FormRow>
          <CustomSelect
            label="Elevation profile"
            options={formConfig.bool}
            value={returnelevation}
            addEmptyOption
            onChange={::this.onElevationChange}
          />
        </FormRow>
        <FormRow>
          <CustomSelect
            label="Measure system"
            options={formConfig.metricsystems}
            value={metricsystem}
            onChange={this.onChange.bind(this, 'metricsystem')}
          />
        </FormRow>
        <FormRow>
          <GeneralisationsField
            label="Generalisations"
            data={generalizationtolerances}
            options={formConfig.generalisationPresets}
            onChange={this.onChange.bind(this, 'generalizationtolerances')}
          />
        </FormRow>
        <FormRow>
          <ResolutionField
            label="Resolution [m/px]"
            value={resolution}
            onChange={this.onChange.bind(this, 'resolution')}
          />
        </FormRow>
        <FormRow>
          <CustomInput
            type="text"
            label="Extra params"
            isValid={extraparams.isValid}
            value={extraparams.value}
            onBlur={this.onChange.bind(this, 'extraparams')}
            blurOnEnter
          />
        </FormRow>
      </div>
    );
  }

  onRouteDateChange = (data) => {
    const routeDate = { ...data };
    if (data.key === 'now') {
      routeDate.key = 'departure';
      routeDate.value = 'now';
    }
    this.onChange('routeDate', routeDate);
  }

  getTimeValue = (routeDate) => {
    if (routeDate.key === 'now') {
      return 'departure: now';
    } else if (routeDate.key === '') {
      return null;
    }
    return `${routeDate.key}: ${routeDate.value}`;
  }

  getRequestTabContent(currentForm, formConfig) {
    let { fields = {} } = this.props.formData,
        { routeid, traffic, language, mode, routeDate: fieldsRouteDate } = fields;
    const routeDate = { value: fieldsRouteDate.value };
    routeDate.key = fieldsRouteDate.key;
    if (fieldsRouteDate.value === 'now') {
      routeDate.key = 'now';
    }
    return (
      <div>
        <ul className="rf-icons-bar" />
        <FormRow>
          <CustomInput
            label="Route Id"
            value={routeid}
            isCopyAble
            onChange={this.onChange.bind(this, 'routeid')}
          />
        </FormRow>
        <ModeFieldSet
          label="Mode"
          value={mode}
          formConfig={formConfig}
          onChange={::this.onChange}
          scooter={this.props.scooter}
        />
        <FormRow>
          <CustomSelect
            label="Traffic routing"
            options={formConfig.routeTraffics}
            value={traffic}
            onChange={this.onChange.bind(this, 'traffic')}
          />
        </FormRow>
        <FormRow cssClasses="rf-grey-box rf-route-time" id="time">
          <RouteDateField
            label="Route Time"
            value={this.getTimeValue(routeDate)}
            formConfig={formConfig}
            routeDate={routeDate}
            onChange={this.onRouteDateChange}
          />
        </FormRow>
        <ExpandFormRow label="Advanced Parameters">
          { this.getMiscellaneousTabContent(formConfig) }
        </ExpandFormRow>
        <FormRow>
          <CustomSelect
            label="Language"
            value={language}
            options={languagesList}
            onChange={e => this.onChange('language', e)}
          />
        </FormRow>
      </div>
    );
  }
}

RouteInfoForm.propTypes = {
  formData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    formData: getCurrentFormData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteInfoForm);
