import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';

import './styles/fieldset.scss';

class Fieldset extends CustomComponent {
  render() {
    return (
      <fieldset className={this.getCssClasses('rf-fieldset')}>
        <legend className="rf-fieldset__legend">{this.props.legend}</legend>
        {this.props.children}
      </fieldset>
    );
  }
}

Fieldset.propTypes = {
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

Fieldset.defaultProps = {
  legend: '',
};

export default Fieldset;
