import Routes from '../../../modules/Routes';
import utils from 'utils';
import { decode } from '../../../../../utils/flexPolyline';

const ALTERNATIVES_COLOR = '#B2B7BB';
const WALK_COLOR = '#737475';
const DEFAULT_TAXI_COLOR = '#FECC00';
const DEFAULT_MODE_COLORS = {
  bicycle: '#1cb0e6',
  car: '#12456D',
};

export default class IntermodalRoutes extends Routes {

  process(res = {}, tabColorPalette = null) {
    let routes = res.routes || [];
    if (routes.length > 0) {
      this.clearGroup();
      this.routeLines = [];

      routes.forEach((route, id) => {
        this.processRoute(
          route,
          id,
          id === this.currentRoute,
          tabColorPalette || this.props.tabData.tabColorPalette
        );
      });
    }
  }

  getSectionColor(section) {
    switch (section.type) {
      case 'pedestrian':
        return WALK_COLOR;
      case 'transit':
        return section.transport.color || null;
      case 'vehicle':
        // Fallthrough
      case 'rented':
        return section.transport.color || DEFAULT_MODE_COLORS[section.transport.mode];
      case 'taxi':
        return section.transport.color || DEFAULT_TAXI_COLOR;
      default:
        return null;
    }
  }

  getSectionGeometryAndWaypoints(section) {
    const coordFromEvent = (event) => event.place.location;

    let geometry,
        waypoints;
    const encodedPolyline = section.polyline;
    if (encodedPolyline) {
      geometry = decode(encodedPolyline).map(([lat, lng]) => ({ lat, lng }));

      waypoints = (section.actions || []).map(({ offset }) => {
          const point = geometry[offset];
          if (!point) {
            return null;
          }

          const geoPoint = { latitude: point.lat, longitude: point.lng };
          return { originalPosition: geoPoint, mappedPosition: geoPoint };
        }).filter(Boolean);
    } else if (section.type === 'transit' && section.intermediateStops) {
      let intermediateShape = section.intermediateStops.map(stop => coordFromEvent(stop.departure));

      geometry = [
        coordFromEvent(section.departure),
        ...intermediateShape,
        coordFromEvent(section.arrival),
      ];
    } else {
      geometry = [
        coordFromEvent(section.departure),
        coordFromEvent(section.arrival),
      ];
    }

    return { geometry, waypoints };
  }

  extractLines(route) {
    let wasInPT = false;
    let wasInVehicle = false;
    return route.sections.map(section => {
      let iconType = null,
        nowInPT = section.type === 'transit',
        nowInVehicle = ['rented', 'vehicle'].includes(section.type);

      if (wasInVehicle) {
        iconType = 'park';
      } else if (wasInPT) {
        iconType = nowInPT ? 'change' : 'leave';
      } else if (nowInPT) {
        iconType = 'enter';
      }

      const color = this.getSectionColor(section),
            { geometry, waypoints } = this.getSectionGeometryAndWaypoints(section);

      wasInPT = nowInPT;
      wasInVehicle = nowInVehicle;

      const shape = utils.flatten(geometry.map(({ lat, lng }) => [lat, lng]));
      return {
        color,
        shape,
        waypoints,
        iconType,
        lineWidth: section.type === 'pedestrian' ? 3 : null,
      };
    });
  }

  highlight(oldHighlighted, nextHighlighted, currentRoute, palette) {
    const updateLine = (id, active) => {
      if (id === -1 || id === currentRoute || this.routeLines[id] === undefined) {
        return;
      }

      let { routeLine, routeLineBorder } = this.routeLines[id];
      let zIndex = routeLine.getZIndex() + (active ? 1 : -1);
      this.setRouteColor(routeLine, active ? palette.secondaryDarker : ALTERNATIVES_COLOR);
      routeLine.setZIndex(zIndex);
      routeLineBorder.setZIndex(zIndex);
    };

    updateLine(oldHighlighted, false);
    updateLine(nextHighlighted, true);
  }

  processRoute(route, id, isActive, tabColorPalette) {
    let lines = this.extractLines(route);
    if (!isActive) {
      const newShape = lines.flatMap(({ shape }) => shape);
      lines = [{ shape: newShape }];
    }

    lines.forEach((line) => {
      let { color, lineWidth } = line;
      if (!isActive) {
        color = ALTERNATIVES_COLOR;
      }
      this.renderRoute({
        id,
        isActive,
        pointArgsCount: 2,
        shape: line.shape,
        color,
        waypoints: line.waypoints,
        routeShape: line.shape,
        tabColorPalette,
        lineWidth,
      }, line.iconType);
    });
  }
}
