import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getInputEl } from './utils';

import ExpandFormRow from 'shared/expandFormRow';
import { LinkInfoDataAdvanced } from './LinkInfoDataAdvanced';

class LinkInfoData extends Component {
  getContent(links = []) {
    return links.map((link, index) => {
      let { linkId } = link;
      let data = [
        { label: 'Speed Limit', ctx: link, key: 'speedLimit', units: 'km/h' },
        { label: 'Functional Class', ctx: link, key: 'functionalClass' },
        { label: 'Road Name', ctx: link, key: 'roadName' },
        { label: 'Road Number', ctx: link, key: 'roadNumber' },
        { label: 'Length', ctx: link, key: 'length', units: 'meter(s)' },
        { label: 'Flags', ctx: link, key: 'flags', fn: item => (item || []).join(', ') },
      ];
      let content = data.map((args, idx) => {
        let _args = Object.assign({}, args, { ns: 'LinkInfoData' });
        return getInputEl(_args, idx);
      });
      return (
        <ExpandFormRow
          label={`Link ${linkId}`}
          key={`ResultPanelFormInfoTab${index}`}
        >
          <div className="rf-result-panel__tabs-gap">
            {content}
          </div>
          <LinkInfoDataAdvanced link={link} />
        </ExpandFormRow>
      );
    }, this);
  }

  render() {
    return (
      <div>
        {this.getContent(this.props.links)}
      </div>
    );
  }
}

LinkInfoData.propTypes = {
  links: PropTypes.array.isRequired,
};

export default LinkInfoData;
