import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Router, Route, browserHistory } from 'react-router';
import { syncHistoryWithStore, routerReducer, routerMiddleware } from 'react-router-redux';
import 'lodash';
import 'babel-polyfill';

import './webfonts/fonts';
import * as data from './state';
import App from './views/App';
import apiCallMiddleware from 'state/middlewares/apiCallMiddleware';
import createLogger from 'redux-logger';
import { addTab } from './state/tabs/actions';
import { getDefaultForm } from './state/appSettings/base';
import { composeWithDevTools } from 'redux-devtools-extension';

const reducer = combineReducers(Object.assign({}, data.appReducers, {
  routing: routerReducer,
}));

// Sync dispatched route actions to the history
const reduxRouterMiddleware = routerMiddleware(browserHistory);
const middlewares = [reduxRouterMiddleware, thunkMiddleware, apiCallMiddleware];

let createStoreWithMd;
if (NODE_ENV !== 'production') {
  createStoreWithMd = composeWithDevTools(
    applyMiddleware(...middlewares, createLogger({ collapsed: true }))
  )(createStore);
} else {
  createStoreWithMd = composeWithDevTools(applyMiddleware(...middlewares))(createStore);
}


const store = createStoreWithMd(reducer, {
  urlSettings: {
    scooter: window.location.search.indexOf('scooter') !== -1,
  },
});

const history = syncHistoryWithStore(browserHistory, store);

store.dispatch(addTab(getDefaultForm(store.getState())));

render(
  <Provider store={store}>
    <Router history={history}>
      <Route path={APP_ROOT_URL} component={App}>
        <Route path="/" component={App} />
      </Route>
    </Router>
  </Provider>,
  document.getElementById('root')
);
