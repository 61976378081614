import React from 'react';
import PropTypes from 'prop-types';

import TabsControl from 'shared/tabsControl';

import DataSwitcherBase from '../../../common/waypointsGroup/DataSwitcher';

class DataSwitcher extends DataSwitcherBase {
  render() {
    let { data, onChange } = this.props,
        keys = ['start', 'destination'];
    let tabsData = keys.map(key => ({
      label: key.charAt(0).toUpperCase() + key.slice(1),
      name: key,
    }));
    return (
      <div>
        <TabsControl currentTabName={data.key} onChange={onChange.bind(this, 'key')} tabsData={tabsData} />
        {super.render()}
      </div>
    );
  }
}

DataSwitcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
};

export default DataSwitcher;
