import React, { Component } from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import moment from 'moment';
import classnames from 'classnames';

import TransportTypeIcon from './TransportTypeIcon';
import DirectionIcon from './DirectionIcon';

import './styles/maneuversListItem.scss';

export class ManeuversListItem extends Component {

  getTransportType() {
    let maneuver = this.props.data;

    if (maneuver.id === 'M1') {
      return 'walk';
    }

    if (['enter', 'change'].indexOf(maneuver.action) !== -1) {
      return this.props.groupPublicTransport || 'enter';
    }

    if (maneuver.action === 'leave') {
      return 'leave';
    }

    return null;
  }

  getIconEl() {
    let { data, isPublicTransportChecked } = this.props,
      transportType = isPublicTransportChecked ? this.getTransportType() : null;
    if (isPublicTransportChecked && transportType) {
      return (
        <TransportTypeIcon transportType={transportType} />
      );
    }

    return (
      <DirectionIcon direction={data.direction} />
    );
  }

  getInstruction(data) {
    let result = [striptags(data.instruction)];
    if (data.time) {
      result.unshift(moment.parseZone(data.time).local().format('HH:mm:ss'));
    }

    return result.join(' - ');
  }

  getDatails(data) {
    if (!this.props.isSelected) {
      return null;
    }
    return <div className="rf-maneuvers-list-item__details">
      <div>Position: {data.position.latitude}, {data.position.longitude}</div>
      <div>Travel Time: {data.travelTime}s</div>
      <div>Length: {data.length}m</div>
    </div>;
  }

  getNotes(notes = []) {
    let notesEl = notes.map((note, index) => <li key={index}>{striptags(note.text)}</li>);
    return (
      <ul>
        {notesEl}
      </ul>
    );
  }

  render() {
    let { data, onClick, isSelected, isDisabled } = this.props;
    let listItemClasses = classnames('rf-maneuvers-list-item', {
      'rf-maneuvers-list-item_selected': isSelected,
      'rf-maneuvers-list-item_disabled': isDisabled,
    });

    return (
      <li className={listItemClasses} onClick={onClick}>
        <div className="rf-maneuvers-list-item__inner">
          { this.getIconEl() }
          <div className="rf-maneuvers-list-item__content">
            {this.getInstruction(data)}
          </div>
        </div>
        {this.getNotes(data.note)}
        {this.getDatails(data)}
      </li>
    );
  }
}

ManeuversListItem.defaultProps = {
  isPublicTransportChecked: false,
  data: PropTypes.object.isRequired,
  groupPublicTransport: '',
  isDisabled: false,
};

ManeuversListItem.propTypes = {
  data: PropTypes.object.isRequired,
  isPublicTransportChecked: PropTypes.bool.isRequired,
  groupPublicTransport: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ManeuversListItem;
