import * as types from './constants';
import defaultState from './defaultState';
import localStorageReducerDecorator from 'utils/localStorageReducerDecorator';

function setTiles(state = defaultState, action) {
  switch (action.type) {
    case types.SET_DATA: {
      const { type, data } = action.payload;
      return {
        ...state,
        [type]: { ...state[type], ...data },
      };
    }
    case types.SET_TYPE:
      return {
        ...state,
        type: action.payload.type,
      };
    default:
      return state;
  }
}

export default localStorageReducerDecorator(setTiles, 'mapTiles.v5', defaultState);
