import modulesEnum from 'config/modulesEnum';
import * as tabTypes from '../../constants';
import { RESTORE_FROM_URL } from '../formData/constants';

export default (state = modulesEnum.ROUTING, action) => {
  switch (action.type) {
    case tabTypes.ADD_TAB:
      return action.tabData.module;
    case RESTORE_FROM_URL:
      return action.data.module;
    default:
      return state;
  }
};
