import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Sortable, { SortableContainer } from 'react-anything-sortable';

import utils from 'utils';
import classnames from 'classnames';
import { isAnyWaypointEmpty } from 'utils/waypoint';

import FormRow from 'shared/formRow';
import CustomCheckbox from 'shared/checkbox';
import Tooltip from 'shared/tooltip';

import GeoItem from './waypointsGroup/GeoItem';
import LinkItem from './waypointsGroup/LinkItem';
import StreetItem from './waypointsGroup/StreetItem';

import './styles/sortable.scss';
import './styles/waypointsGroup.scss';

class WaypointsGroup extends Component {
  onChange(index, value) {
    let { currentForm } = this.props;
    this.props.onChange({ value, index, currentForm });
  }

  getGroupElClass(category) {
    return {
      geo: GeoItem,
      link: LinkItem,
      street: StreetItem,
    }[category];
  }

  getLabel(point, index, arr) {
    let label = `Waypoint ${index}`;
    if (index === 0) {
      label = 'start';
    } else if (index === arr.length - 1) {
      label = 'end';
    }

    return label;
  }

  getSeparatorEl(isDisabled, index) {
    let classes = classnames('rf-clearfix', 'rf-add-waypoint', {
      'rf-add-waypoint_disabled': isDisabled,
    });
    return (
      <div
        className={classes}
        key={`WaypointsGroupItemSeparator_${index}`}
        onClick={::this.addWaypoint}
        title="Add Waypoint"
      >
        <div className="rf-add-waypoint__button"></div>
      </div>
    );
  }

  getWaypoints() {
    let { canAddWaypoints, waypoints, formConfig, onGetStreetAddress, onGetLinkId, route,
      setNotification } = this.props;
    let isAddButtonDisabled = isAnyWaypointEmpty(waypoints);

    let waypointsArr = [];
    waypoints.forEach((point, index, arr) => {
      let WaypointsGroupItemEl = this.getGroupElClass(point.category);

      if (!WaypointsGroupItemEl) {
        throw Error('WaypointsGroup#getWaypoint: Cannot define Item class');
      }

      let row = <FormRow>
        <WaypointsGroupItemEl
          data={point}
          label={this.getLabel(point, index, arr)}
          formConfig={formConfig}
          removeWaypoint={this.removeWaypoint.bind(this, index)}
          reverseWaypoints={::this.reverseWaypoints}
          onGetStreetAddress={onGetStreetAddress.bind(this, index)}
          onGetLinkId={onGetLinkId.bind(this, index)}
          onChange={this.onChange.bind(this, index)}
          route={route}
          index={index}
          setNotification={setNotification}
        />
      </FormRow>;

      let key = `WaypointsGroupItem${index}`;

      if (!this.props.isDNDEnabled) {
        waypointsArr.push(<div key={key}>{row}</div>);
      } else {
        waypointsArr.push(<SortableContainer sortData={index} key={key}>
          <div>{row}</div>
        </SortableContainer>);
      }
      if (canAddWaypoints && index === waypoints.length - 2) {
        waypointsArr.push(this.getSeparatorEl(isAddButtonDisabled, key));
      } else {
        waypointsArr.push(this.getSeparatorEl(true, key));
      }
    }, this);

    return waypointsArr;
  }

  removeWaypoint(index) {
    let { currentForm } = this.props;
    this.props.removeWaypoint({ index, currentForm, needsRerender: true });
  }

  reverseWaypoints() {
    let { currentForm } = this.props;
    this.props.reverseWaypoints({ currentForm, needsRerender: true });
  }

  addWaypoint() {
    let { currentForm } = this.props;
    this.props.addWaypoint({ currentForm, value: { isWaypoint: true } });
  }

  handleSort(order) {
    if (Object.keys(order).toString() === order.toString()) {
      return;
    }

    let waypointsCount = order.length;
    let properties = utils.fill(new Array(waypointsCount), {
      isWaypoint: true,
    });
    properties[0] = properties[waypointsCount - 1] = {
      type: 'stopOver',
      isWaypoint: false,
    };

    this.props.changeWaypointsOrder({
      order,
      properties,
      currentForm: this.props.currentForm,
      needsRerender: true,
    });
  }

  render() {
    let { id, isDNDEnabled, useNewWaypointsFormat, updateWaypointFormat } = this.props;

    if (!isDNDEnabled) {
      return <div id={id}>{this.getWaypoints()}</div>;
    }

    return (
      <div id={id}>
        <Sortable
          onSort={::this.handleSort}
          direction="vertical"
          dynamic
          containment
          sortHandle="rf-waypoints-group-item__header__drag"
        >
          {this.getWaypoints()}
        </Sortable>
        <Tooltip tooltip="waypoint=...&waypoint=... instead of waypoint0=...&waypoint1=...">
          <CustomCheckbox
            label="Use new format"
            cssClasses="rf-waypoints-group__use-new-format-checkbox"
            isChecked={useNewWaypointsFormat}
            onChange={updateWaypointFormat}
          />
        </Tooltip>
      </div>
    );
  }
}

WaypointsGroup.defaultProps = {
  id: 'waypoints',
  canAddWaypoints: false,
  isDNDEnabled: false,
  removeWaypoint() {},
  addWaypoint() {},
  changeWaypointsOrder() {},
  reverseWaypoints() {},
  onGetStreetAddress() {},
};

WaypointsGroup.propTypes = {
  currentForm: PropTypes.string.isRequired,
  canAddWaypoints: PropTypes.bool.isRequired,
  waypoints: PropTypes.array.isRequired,
  formConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeWaypoint: PropTypes.func.isRequired,
  addWaypoint: PropTypes.func.isRequired,
  changeWaypointsOrder: PropTypes.func,
  reverseWaypoints: PropTypes.func,
  isDNDEnabled: PropTypes.bool,
  onGetStreetAddress: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onGetLinkId: PropTypes.func,
  updateWaypointFormat: PropTypes.func,
  route: PropTypes.object,
  useNewWaypointsFormat: PropTypes.bool,
};

export default WaypointsGroup;
