export default {
  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  },

  isArray(obj) {
    return _.isArray(obj);
  },

  isFunction(obj) {
    return typeof obj === 'function';
  },

  isNumber(obj) {
    return typeof obj === 'number';
  },

  isString(obj) {
    return typeof obj === 'string';
  },

  clone(obj) {
    return _.cloneDeep(obj);
  },

  debounce(...args) {
    return _.debounce(...args);
  },

  throttle(...args) {
    return _.throttle(...args);
  },

  iterObject(obj, cb) {
    _.forOwn(obj, cb);
  },

  getObject(...args) {
    return _.get(...args);
  },

  setObject(ctx, path, value) {
    return _.set(ctx, path, value);
  },

  isEqual(v1, v2) {
    return _.isEqual(v1, v2);
  },

  isEmpty(value) {
    return _.isEmpty(value);
  },

  isPropChanged(props1, props2, path) {
    let v1 = _.get(props1, path),
        v2 = _.get(props2, path);
    return !_.isEqual(v1, v2);
  },

  values(obj) {
    return _.values(obj);
  },

  uniq(arr) {
    return _.uniq(arr);
  },

  chunk(array, size, guard) {
    return _.chunk(array, size, guard);
  },

  flatten(array) {
    return _.flatten(array);
  },

  some(collection, predicate) {
    return _.some(collection, predicate);
  },

  every(collection, predicate) {
    return _.every(collection, predicate);
  },

  pickBy(object, predicate) {
    return _.pickBy(object, predicate);
  },

  fill(array, value, start, end) {
    return _.fill(array, value, start, end);
  },

  find(collection, predicate, fromIndex) {
    return _.find(collection, predicate, fromIndex);
  },

  findIndex(array, predicate, fromIndex) {
    return _.findIndex(array, predicate, fromIndex);
  },

  findLastIndex(array, predicate, fromIndex) {
    return _.findLastIndex(array, predicate, fromIndex);
  },

  omit(collection, keys) {
    return _.omit(collection, keys);
  },

  lowerFirst(string) {
    return _.lowerFirst(string);
  },

  getDeepMapMerger(Immutable) {
    let { fromJS, List: { isList } } = Immutable;
    return function merger(prev, next) {
      if (!prev.mergeWith) {
        prev = fromJS(prev);
      }
      if (!next.mergeWith) {
        next = fromJS(next);
      }

      if (isList(prev) || isList(next) || !prev.mergeWith || !next.mergeWith) { // if list or primitive
        return next;
      }

      return prev.mergeWith(merger, next);
    };
  },

  /**
   * @param data {Event|String}
   * @returns {Event|String}
   */
  extractData(data) {
    if (!data || !(data.nativeEvent instanceof Event)) {
      return data;
    }
    let target = data.currentTarget || data.target;
    return target.type === 'checkbox' ||
      (target.tagName && target.tagName.toLowerCase() === 'lui-checkbox') ?
      target.checked : target.value;
  },

  isExternalBuild() {
    return window.APP_ACCESS === 'external';
  },

  range(start, end, step) {
    return _.range(start, end, step);
  },

  times(n, iteratee) {
    return _.times(n, iteratee);
  },

  has(...args) {
    return _.has(...args);
  },

  map(...args) {
    return _.map(...args);
  },

  difference(...args) {
    return _.difference(...args);
  },

  isFinite(...args) {
    return _.isFinite(...args);
  },

  without(array, ...values) {
    return _.without(array, ...values);
  },

  objectDiffKeys(obj1, obj2) {
    let keys = Object.keys(obj1);
    return keys.filter(key => obj1[key] !== obj2[key])
      .concat(this.without(Object.keys(obj2), ...keys));
  },

  forEachDesc(arr, clb) {
    for (let index = arr.length - 1; index >= 0; index--) {
      clb(arr[index], index);
    }
  },

  downloadFile(filename, text) {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  },
};
