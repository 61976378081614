import { decode } from 'utils/flexPolyline';

export const parseTurnByTurnActions = (route) => {
  const actions = [];
  if (!route) {
    return actions;
  }
  route.sections.forEach((section) => {
    let shape;
    if (section.polyline) {
      shape = decode(section.polyline);
    }
    if (!section.turnByTurnActions) {
      return;
    }
    section.turnByTurnActions.forEach((action, index) => {
      const nextAction = section.turnByTurnActions[index + 1] || {};
      const nextOffset = nextAction.offset || action.offset + 1;
      const actionShape = shape ? shape.slice(action.offset, nextOffset) : [];
      const details = Object.keys(action).reduce((acc, actionKey) => ({
        ...acc,
        [actionKey]: JSON.stringify(action[actionKey]),
      }), {});
      actions.push({
        instruction: action.instruction || action.action,
        shape: actionShape.length ? actionShape : null,
        details,
      });
    });
  });

  return actions;
};

export function parseManeuvers(route) {
  const maneuvers = [];
  if (!route) {
    return maneuvers;
  }
  route.sections.forEach((section) => {
    let shape;
    if (section.polyline) {
      shape = decode(section.polyline);
    }
    if (!section.actions) {
      return;
    }
    section.actions.forEach((action, index) => {
      const nextAction = section.actions[index + 1] || {};
      const nextOffset = nextAction.offset || action.offset + 1;
      const maneuverShape = shape ? shape.slice(action.offset, nextOffset) : [];
      maneuvers.push({
        instruction: action.instruction || action.action,
        shape: maneuverShape.length ? maneuverShape : null,
        details: {
          action: action.action,
          offset: action.offset,
          duration: action.duration,
        }
      });
    });
  });

  return maneuvers;
}

export const parseSpans = (route) => {
  const spans = [];

  if (!route) {
    return spans;
  }
  route.sections.forEach((section) => {
    let shape;
    let shapeLength;
    if (section.polyline) {
      shape = decode(section.polyline);
      shapeLength = shape.length;
    }
    if (!section.spans) {
      return;
    }

    section.spans.forEach((span, index) => {
      const nextSpan = section.spans[index + 1] || { offset: shapeLength };
      const spanShape = shape ? shape.slice(span.offset, nextSpan.offset) : [];
      const names = span.names;
      let instruction;
      if (names) {
        instruction = names.map((name) => name.value).join(', ');
      } else {
        instruction = Object.keys(span).filter((key) => key !== 'offset').join(', ');
      }
      const details = Object.keys(span).reduce((acc, spanKey) => ({
        ...acc,
        [spanKey]: JSON.stringify(span[spanKey]),
      }), {});
      spans.push({
        instruction: `${index}. ${instruction}`,
        shape: spanShape.length ? spanShape : null,
        details,
      });
    });
  });

  return spans;
};
