import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomTooltip from 'shared/tooltip';

import './styles/radioOption.scss';

class RadioOption extends Component {
  constructor(props) {
    super(props);
    this.radio = React.createRef();
  }

  componentDidMount() {
    if (this.props.isChecked && this.radio.current.shadowRoot) {
      this.radio.current.shadowRoot.querySelector('input').checked = this.props.isChecked;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isChecked !== this.props.isChecked) {
      this.radio.current.shadowRoot.querySelector('input').checked = this.props.isChecked;
    }
  }

  getLabelEl() {
    let { tooltip, label } = this.props;
    return (
      tooltip ?
        <CustomTooltip tooltip={tooltip}>
          <span>{label}</span>
        </CustomTooltip> :
        <span>{label}</span>
    );
  }

  render() {
    let { isChecked, value, onChange, disabled, name, ...radioProps } = this.props;

    if (disabled) {
      radioProps.disabled = true;
    }
    if (isChecked) {
      radioProps.checked = true;
    }

    return <lui-radiobutton
      value={value}
      onClick={onChange}
      name={name}
      class={classnames('rf-radiooption', { 'rf-radiooption_disabled': disabled })}
      ref={this.radio}
      {...radioProps}
    >
      {this.getLabelEl()}
    </lui-radiobutton>;
  }
}

RadioOption.defaultProps = {
  value: '',
  tooltip: '',
  disabled: false,
};

RadioOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default RadioOption;
