import PropTypes from 'prop-types';
import React from 'react';

import utils from 'utils';
import classnames from 'classnames';
import CustomTooltip from 'shared/tooltip';
import CustomComponent from 'shared/component';

import './styles/transportMode.scss';

const scooterMode = { label: 'Scooter', value: 'scooter' };

class TransportMode extends CustomComponent {
  getCurrentTransportMode() {
    return utils.find([...this.props.routeTransports, scooterMode], { value: this.props.value });
  }

  render() {
    let { routeTransports, value, scooterEnabled } = this.props;
    let cssClasses = ['rf-transport-mode'];
    let currentTransportMode = this.getCurrentTransportMode();

    const transports = [...routeTransports];
    if (scooterEnabled) {
      transports.push(scooterMode);
    }

    return (
      <div className={this.getCssClasses(cssClasses)}>
        <h4 className="rf-transport-mode__title">Transport Mode: {_.get(currentTransportMode, 'label', '')}</h4>
        <div className="rf-transport-mode__items">
          {transports.map(transportType => {
            let classes = classnames(
              'rf-transport-mode__items__item',
              `rf-transport-mode__items__item_${transportType.value}`,
              {
                'rf-transport-mode__items__item_active': transportType.value === value,
              },
            );
            return <CustomTooltip tooltip={transportType.label} placement="top" key={transportType.value}>
              <i
                className={classes}
                onClick={() => this.props.onChange(transportType.value)}
              />
            </CustomTooltip>;
          })}
        </div>
      </div>
    );
  }
}

TransportMode.propTypes = {
  routeTransports: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  scooterEnabled: PropTypes.bool,
};

export default TransportMode;
