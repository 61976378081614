import React from 'react';
import utils from 'utils';

import CustomComponent from 'shared/component';

import './styles/columns.scss';

class Columns extends CustomComponent {
  render() {
    let { children } = this.props;
    let cssClasses = ['rf-columns', 'rf-clearfix'];
    let placeholder = <div className="rf-columns__placeholder" key="empty"></div>;

    children = Array.prototype.filter.call(children, el => el !== null);

    if (!utils.isArray(children)) {
      children = [children, placeholder];
    } else if (children.length % 2) {
      children = [...children, placeholder];
    }

    return (
      <div className={this.getCssClasses(cssClasses)}>
        {children}
      </div>
    );
  }
}

export default Columns;
