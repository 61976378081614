import isNumbersList from 'utils/validation/numericsValidation';
import isNumberValid from 'utils/validation/numericValidation';
import isExtraValid from 'utils/validation/extraParamsValidation';
import consumptionValidation from '../../common/consumptionValidation';

let isPositiveNumber = isNumberValid({ min: 0 });

export default {
  range: isNumbersList,
  quality: isNumberValid({ min: 1, max: 3 }),
  resolution: isPositiveNumber,
  maxpoints: isPositiveNumber,
  extraparams: isExtraValid,
  'weightsPerAxleGroup.tandem': isNumberValid({ min: 0, max: 1000 }),
  'weightsPerAxleGroup.single': isNumberValid({ min: 0, max: 1000 }),
  ...consumptionValidation,
};
