import { defaultAvoidAreasState } from './defaultState';
import * as types from './constants';
import * as formDataTypes from '../../constants';
import reducer from './reducer';

import utils from 'utils';

export default function proxy(currentForm) {
  return function (state = defaultAvoidAreasState, action) {
    let _types = utils.values(types);
    if (_types.indexOf(action.type) !== -1 && action.data.currentForm === currentForm) {
      return reducer(state, action);
    }

    if (formDataTypes.RESTORE_FROM_URL === action.type && action.data.currentForm === currentForm) {
      return reducer(defaultAvoidAreasState, action);
    }

    return state;
  };
}
