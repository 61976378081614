import * as types from './constants';

export default function (state = -1, action) {
  switch (action.type) {
    case types.SET_SELECTED_HISTORY:
      return action.index;
    default:
      return state;
  }
}
