export default {
  origin: {
    value: '',
    isValid: true,
  },
  destination: {
    value: '',
    isValid: true,
  },
  originAdvanced: getAdvancedWaypointParams(),
  destinationAdvanced: getAdvancedWaypointParams(),
  alternatives: {
    value: '',
    isValid: true,
  },
  lang: '',
  units: '',
  olsReturn: ['polyline', 'actions', 'instructions', 'summary'],
  'avoid[features]': [],
  'avoid[zoneIds]': '',
  'avoid[zoneExceptions]': '',
  spans: [],
  extraparams: {
    value: '',
    isValid: true,
  },
  via: [],
};

export function getAdvancedWaypointParams(isVia) {
  const res = {
    course: { value: '', isValid: true },
    sideOfStreetHint: { value: '', isValid: true },
    matchSideOfStreet: { value: '', isValid: true },
    nameHint: '',
    radius: { value: '', isValid: true },
    minCourseDistance: { value: '', isValid: true },
    stopDuration: { value: '', isValid: true },
  };
  if (isVia) {
    res.passThrough = '';
  }

  return res;
}
