import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import CustomComponent from 'shared/component';

import utils from 'utils';

import 'rc-slider/assets/index.css';
import './styles/slider.scss';

export default class CustomSlider extends CustomComponent {
  constructor(props) {
    super(props);

    this.debouncedOnChange = utils.debounce(props.onChange, 300);
    this.state = {
      value: props.value,
    };
  }

  onChange(value) {
    let { debounced, onChange } = this.props;
    this.setState({ value });
    if (debounced) {
      this.debouncedOnChange(value);
    } else {
      onChange(value);
    }
  }

  render() {
    let { label, sliderProps } = this.props;
    let labelEl = label ? <span className="rf-input__caption">{label}</span> : '';

    return (
      <label className={this.getCssClasses(['rf-input'])}>
        {labelEl}
        <span className="rf-input__container">
          <Slider value={this.state.value} onChange={::this.onChange} {...sliderProps} />
        </span>
      </label>
    );
  }
}

CustomSlider.defaultProps = {
  debounced: false,
  sliderProps: {},
  onChange: () => {},
};

CustomSlider.propTypes = {
  value: PropTypes.number.isRequired,
  debounced: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  sliderProps: PropTypes.object,
};
