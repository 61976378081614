export const pointTemplate = {
  category: '',
  coords: {
    isValid: true,
    value: '',
  },
};

export const defaultPointsState = {
  start: [],
  destination: [],
};
