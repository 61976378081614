import { emptyBatteryParameters } from 'config/form/electricPresets';

const emptyObj = () => ({
  value: '',
  isValid: true,
});

export default {
  consumptionmodel: '',
  customconsumptiondetails: {
    ascent: emptyObj(),
    descent: emptyObj(),
    acceleration: emptyObj(),
    deceleration: emptyObj(),
    auxiliaryconsumption: emptyObj(),

    // standard
    speed: emptyObj(),
    turnpenalty: emptyObj(),

    // electric:
    freeflowspeedtable: emptyObj(),
    trafficspeedtable: emptyObj(),
    trafficscaletable: emptyObj(),
  },
  batteryparameters: { ...emptyBatteryParameters },
  makereachable: '',
  highspeedthreshold: emptyObj(),
  chargingstationfilters: {
    plugtype: {
      value: [],
      isValid: true,
    },
  }
};
