import utils from 'utils';

export default function numericValidation(args = {}) {
  return value => {
    let number = +value;
    let { min, max, key } = args;

    if (!utils.isFinite(number)) {
      return [`Invalid number value '${value}'`];
    }

    if (min !== undefined && number < min) {
      return [`${key} should be greater then ${min}`];
    }

    if (max !== undefined && number > max) {
      return [`${key} should be less then ${max}`];
    }

    return [];
  };
}
