import * as types from './constants';

export default function reducer(state = [], action) {
  switch (action.type) {
    case types.STORE_RESULT: {
      let { items } = action.data || {};
      return items;
    }
    case types.REMOVE:
      return [];
    default:
      return state;
  }
}
