export default function exec(data) {
  const processedData = { ...data, points: { start: [], destination: [] } };
  processedData.points = {
    start: processedData.startPoints,
    destination: processedData.destinationPoints,
  };

  delete processedData.startPoints;
  delete processedData.destinationPoints;

  return processedData;
}
