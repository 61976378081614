import * as types from './constants';
import reducer from './reducer';
import defaultState from './defaultState';

import utils from 'utils';

export default function proxy(currentForm) {
  return function (state = defaultState, action) {
    let _types = utils.values(types);
    if (_types.indexOf(action.type) !== -1 && action.data.currentForm === currentForm) {
      return reducer(state, action);
    }
    return state;
  };
}
