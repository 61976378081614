import utils from 'utils';
import { getModule } from 'state/tabs/tab/module';
import { getForm } from 'state/tabs/tab/form';

export function getCurrentPreset(state, module) {
  if (!state) {
    throw new Error('state param is required');
  }

  if (!module) {
    module = getModule(state);
  }
  let PATH = `settingsData.settingsPresets.${module}.currentPreset`;
  return utils.getObject(state, PATH);
}

export function getPreset(state, module, currentForm, currentPreset) {
  if (!state) {
    throw new Error('state param is required');
  }

  if (!module) {
    module = getModule(state);
  }

  if (!currentForm) {
    currentForm = getForm(state);
  }

  if (!currentPreset) {
    currentPreset = getCurrentPreset(state, module);
  }
  let PATH = `settingsData.settingsPresets.${module}.${currentForm}.${currentPreset}`;
  return utils.getObject(state, PATH);
}
export function getCurrentPresetFromSettingsData(settingsData, module) {
  if (!settingsData || !module) {
    throw new Error('settingsData and module params are required');
  }

  let PATH = `settingsPresets.${module}.currentPreset`;
  return utils.getObject(settingsData, PATH);
}

export function getSettingsData(state) {
  return state.settingsData;
}
