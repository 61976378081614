import { assignTabDataAndDispatch } from '../../../actions';

export const types = {
  SET_DATA: 'routeFromPoints/SET_DATA',
};

export function setPoints(points) {
  return assignTabDataAndDispatch({ type: types.SET_DATA, data: { points } });
}

export function setRouteFromPointsData(data) {
  return assignTabDataAndDispatch({ type: types.SET_DATA, data });
}

export function updateTimestamp(timestamp = new Date().getTime()) {
  return assignTabDataAndDispatch({ type: types.SET_DATA, data: { timestamp } });
}
