import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';

import FormRow from 'shared/formRow';
import Columns from 'shared/columns';
import CustomMultiSelect from 'shared/multiSelect';
import CustomSelect from 'shared/select';
import TabsControl from 'shared/tabsControl';
import CustomButton from 'shared/button';
import utils from 'utils';

import FeaturesGroup from './modeFieldSet/FeaturesGroup';
import TransportMode from './modeFieldSet/TransportMode';

import './modeFieldSet/styles/mode.scss';

const bicycleModes = {
  beta: 'betaBicycleRouting',
  enhanced: 'enhancedBicycleRouting',
};

class ModeFieldSet extends Component {

  onModeChange(attrKey, e) {
    let { onChange } = this.props;
    let data = utils.extractData(e);
    onChange('mode', { [attrKey]: data });
    if (attrKey === 'transport') {
      onChange('avoidTransportTypes', []);
    }
  }

  onFeatureChanged(data) {
    let value = Immutable.fromJS(this.props.value).get('features');
    Object.keys(data).forEach(feature => {
      if (data[feature].isChecked && utils.isEmpty(data[feature].value)) {
        data[feature].value = this.props.formConfig.featureSet[0].value;
      }
    });
    this.onModeChange('features', value.mergeDeep(data).toJS());
  }

  onBicycleModeChanged(bicycleMode) {
    this.props.onChange('enhancedbicyclerouting', bicycleMode === bicycleModes.enhanced ? 'true' : '');
  }

  onChangeAvoidTransportTypes(options) {
    let mapValues = option => option.value;
    this.props.onChange('avoidTransportTypes', options.map(mapValues));
  }

  getFeaturesGroup() {
    let { value, formConfig } = this.props;
    return <FeaturesGroup
      label="Features"
      features={value.features}
      formConfig={formConfig}
      onChange={::this.onFeatureChanged}
    />;
  }

  getAvoidTransportTypes() {
    let { formConfig, avoidTransportTypes } = this.props;
    return <CustomMultiSelect
      values={avoidTransportTypes}
      options={formConfig.transportTypes}
      label="Avoid transport types"
      onChange={::this.onChangeAvoidTransportTypes}
    />;
  }

  openNewTab() {
    const title = this.props.tabTitle.replace(/ ?(\(.*\))$/, '');
    this.props.setTabTitle(this.props.selectedTab, title + (this.props.enhancedbicyclerouting === '' ?
      ' (Beta Bicycle Routing)' : ' (Enhanced Bicycle Routing)'));
    this.props.duplicateTab(this.props.selectedTab, {
      title: title + (this.props.enhancedbicyclerouting === '' ?
        ' (Enhanced Bicycle Routing)' : ' (Beta Bicycle Routing)'),
    });
    this.props.onChange('enhancedbicyclerouting', this.props.enhancedbicyclerouting === '' ? 'true' : '', true);
  }

  findByValue(value) {
    return obj => obj.value === value;
  }

  render() {
    let { label, value, formConfig, scooter, enhancedbicyclerouting, duplicateTab, setTabTitle } = this.props;
    let tabsData = [
      { label: 'Beta Bicycle Routing', name: 'betaBicycleRouting' },
      { label: 'Enhanced Bicycle Routing', name: 'enhancedBicycleRouting' },
    ];
    return (
      <div className="rf-mode rf-grey-box" id="mode">
        <h3>{label}</h3>
        <TransportMode
          routeTransports={formConfig.routeTransports}
          value={value.transport}
          onChange={this.onModeChange.bind(this, 'transport')}
          scooterEnabled={scooter}
        />
        <Columns>
          <FormRow>
            <CustomSelect
              label="Route Type"
              options={formConfig.routeTypes}
              value={value.type}
              onChange={this.onModeChange.bind(this, 'type')}
            />
          </FormRow>
          <FormRow>
            <CustomSelect
              label="Traffic routing"
              options={formConfig.routeTraffics}
              value={value.traffic}
              onChange={this.onModeChange.bind(this, 'traffic')}
            />
          </FormRow>
        </Columns>
        {value.transport === 'bicycle' && <>
          <h4 className="rf-bicycle-mode__title">Bicycle mode:</h4>
          <TabsControl
            currentTabName={enhancedbicyclerouting === 'true' ? bicycleModes.enhanced : bicycleModes.beta}
            onChange={::this.onBicycleModeChanged}
            tabsData={tabsData}
          />
          {duplicateTab && setTabTitle && <CustomButton
            cssClasses="rf-bicycle-mode__open-in-new-tab"
            onClick={::this.openNewTab}
            title={enhancedbicyclerouting === 'true' ?
              'Open Beta Bicycle Routing in new tab' : 'Open Enhanced Bicycle Routing in new tab'}
          />}
        </>}
        {this.getFeaturesGroup()}
      </div>
    );
  }
}

ModeFieldSet.propTypes = {
  label: PropTypes.string.isRequired,
  formConfig: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  avoidTransportTypes: PropTypes.array,
  scooter: PropTypes.bool,
  enhancedbicyclerouting: PropTypes.string,
  selectedTab: PropTypes.number,
  tabTitle: PropTypes.string,
  duplicateTab: PropTypes.func,
  setTabTitle: PropTypes.func,
};

export default ModeFieldSet;
