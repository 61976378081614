import { metricSystemsValidation, listValidation, generalisationTolerancesValidation, enumValidation, boolValidation }
  from '../common';
import numericValidation from '../common/numericValidation';
import formConfig from 'config/form';
import languagesList from 'config/languagesList';

const config = formConfig();
let mapValue = val => val.value;
const langValues = [];
languagesList.forEach(item => {
  const originalValue = item.value;
  langValues.push(originalValue);
  const alternativeValue = originalValue.replace('-', '_');
  if (originalValue !== alternativeValue) {
    langValues.push(alternativeValue);
  }
});

export default {
  metricsystem: metricSystemsValidation,
  maneuverattributes: routeOutputParamsValidation('maneuverattributes', config.maneuverAttributes.map(mapValue)),
  routeattributes: routeOutputParamsValidation('routeattributes', config.routeAttributes.map(mapValue)),
  legattributes: routeOutputParamsValidation('legattributes', config.legAttributes.map(mapValue)),
  linkattributes: routeOutputParamsValidation('linkattributes', config.linkAttributes.map(mapValue)),
  lineattributes: routeOutputParamsValidation('lineattributes', config.lineAttributes.map(mapValue)),
  generalisationtolerances: generalisationTolerancesValidation,
  language: enumValidation('language', langValues),
  jsonattributes: numericValidation(),
  returnelevation: boolValidation,
};

function routeOutputParamsValidation(key, validatedValues) {
  let additionalValues = ['all', 'none'];
  let allValues = additionalValues.concat(validatedValues).concat(validatedValues.map(val => `-${val}`));
  return listValidation(key, allValues);
}
