export default {
  shippedHazardousGoods: [],
  tunnelCategory: '',
  truckRestrictionPenalty: '',
  limitedWeight: {
    value: '',
    isValid: true,
  },
  weightPerAxle: {
    value: '',
    isValid: true,
  },
  weightsPerAxleGroup: {
    single: {
      value: '',
      isValid: true,
    },
    tandem: {
      value: '',
      isValid: true,
    },
  },
  axlecount: {
    value: '',
    isValid: true,
  },
  height: {
    value: '',
    isValid: true,
  },
  width: {
    value: '',
    isValid: true,
  },
  length: {
    value: '',
    isValid: true,
  },
  truckType: '',
  trailersCount: {
    value: '',
    isValid: true,
  },
  avoidturns: [],
};
