export const TRUCK_MAPPER = {
  'truck[tunnelCategory]': (val) => ({ tunnelCategory: val.toUpperCase() }),
  'truck[grossWeight]': (val) => ({ limitedWeight: kgToTFormatter(val) }),
  'truck[weightPerAxle]': (val) => ({ weightPerAxle: kgToTFormatter(val) }),
  'truck[shippedHazardousGoods]': (val) => ({
    shippedHazardousGoods: val.map((hazard) => {
      if (hazard === 'radioactive') {
        return 'radioActive';
      }
      return hazard;
    }),
  }),
  'truck[height]': (val) => ({ height: cmToMFormatter(val) }),
  'truck[width]': (val) => ({ width: cmToMFormatter(val) }),
  'truck[length]': (val) => ({ length: cmToMFormatter(val) }),
  'truck[axleCount]': (val) => ({ axlecount: getObj(val) }),
  'exclude[countries]': (val) => ({ excludecountries: val }),
  departureTime: routeDate('departure'),
  arrivalTime: routeDate('arrival'),
  'avoid[features]': (val) => {
    const res = {};
    if (val.indexOf('seasonalClosure') !== -1) {
      res.avoidseasonalclosures = 'true';
    }
    if (val.indexOf('difficultTurns') !== -1) {
      res.avoidturns = ['difficult'];
    }
    return res;
  }
};

export const ROUTING_MODE_MAPPER = {
  fast: 'fastest',
  short: 'balanced',
};

function getObj(value) {
  return {
    value,
    isValid: true,
  };
}

function cmToMFormatter(val) {
  const res = +val / 100;
  return getObj(res ? `${res}` : '');
}

function kgToTFormatter(val) {
  const res = +val / 1000;
  return getObj(res ? `${res}` : '');
}

function routeDate(key) {
  return (value) => {
    if (value) {
      return { routeDate: { key, value } };
    }

    return {};
  };
}
