import getMarker from './PointMarkerTemplate';
import roundMarker from './RoundMarkerTemplate';
import circleCenter from './CircleCenterTemplate';
import markerForLabel from './MarkerForLabelTemplate';

let uiHelper;

function initUiHelper(map) {
  uiHelper = new H.ui.UI(map);
}

export function createMainMarker(args = {}) {
  let { point, title, currentRoute, isWaypoint, color, strokeColor, fontSize } = args,
      coords = point.value.split(','),
      icon = new H.map.Icon(getMarker({ currentRoute, title, isWaypoint, color, strokeColor, fontSize }));
  return createMarker(coords, { icon, data: { type: 'MAIN' } });
}

export function createMarkerIcon(args = {}) {
  return new H.map.Icon(getMarker(args));
}

export function createIconForLabel(color) {
  return new H.map.Icon(markerForLabel(color));
}

export function createMappedMarker(point) {
  return createMarker([
    point.mappedPosition.latitude,
    point.mappedPosition.longitude,
  ], { icon: new H.map.Icon(roundMarker, { anchor: { x: 6, y: 6 } }) });
}

export function createCircleMarker(point, options = {}) {
  let { radius, color } = options;
  return createMarker([point.latitude, point.longitude],
    { icon: new H.map.Icon(circleCenter({ radius, color }),
      { anchor: { x: radius, y: radius } }) });
}

export function createCircle(coords, radius, options) {
  return new H.map.Circle({
    lat: coords[0],
    lng: coords[1],
  }, radius, options);
}

export function createInfoBubble(args) {
  let { map, coords, content } = args;
  let bubble = new H.ui.InfoBubble({
    lat: coords[0],
    lng: coords[1],
  }, { content });
  if (!uiHelper) {
    initUiHelper(map);
  }
  uiHelper.addBubble(bubble);
  return bubble;
}

export function removeInfoBubble(bubble) {
  if (uiHelper) {
    uiHelper.removeBubble(bubble);
  }
}

export function createChangeTransportMarker(coords, type) {
  const iconMap = {
    enter: 'icon-enter.png',
    leave: 'icon-leave.png',
    change: 'icon-change.png',
    park: 'icon-park.png',
  };
  let icon = new H.map.Icon(`/${iconMap[type]}`, { anchor: { x: 11, y: 11 } });

  return createMarker(coords, { icon });
}

export function isMarker(instance) {
  return instance instanceof H.map.Marker;
}

export function createMarker(coords, options) {
  return new H.map.Marker({
    lat: coords[0],
    lng: coords[1],
  }, options);
}

export function createText(coords, text, className) {
  let domElement = document.createElement('div');
  domElement.className = className;
  let textElement = document.createTextNode(text);
  domElement.appendChild(textElement);
  return new H.map.DomMarker({
    lat: coords[0],
    lng: coords[1],
  }, { icon: new H.map.DomIcon(domElement) });
}
