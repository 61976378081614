import * as types from '../constants';

export default function apiResponseOlsApi(state = {}, action) {
  switch (action.type) {
    case types.RECEIVE_DATA_OLS_API:
      return action.result || {};

    default:
      return state;
  }
}
