import defaultPresetsState, { DEFAULT_PRESET } from 'state/settingsPreset/defaultPresetsState';
import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';
import settingsUtils from 'utils/settings';

export default settingsUtils.getPresetSettings(
  modulesEnum.ROUTING,
  formsEnum.CALCULATE_ROUTE,
  { settingsPresets: defaultPresetsState },
  DEFAULT_PRESET
);
