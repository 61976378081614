import getConfig from 'config/form';

const config = getConfig();
const routeWaypointTypes = config.routeWaypointTypes;
const acceptableCategories = config.routeWaypointCategories;

export default function (keyVal) {
  let valueArr = keyVal[1].split('!');
  let category;
  if (acceptableCategories.indexOf(valueArr[0]) !== -1) {
    category = valueArr[0];
    valueArr = valueArr.slice(1);
  } else {
    category = 'geo';
  }

  switch (category) {
    case 'geo':
      return parseGeo(valueArr);
    case 'link':
      return parseLink(valueArr);
    case 'street':
      return parseStreet(valueArr);
    default:
      throw new Error(`Waypoint type '${category}' is not supported`);
  }
}

export function parseGeo(params) {
  let validatedVals = {};
  let typeBlock = null;
  let values = '';
  let evParams = '';

  if (params.length === 1) {
    if (isWaypointType(params[0])) {
      typeBlock = params[0];
    } else {
      values = params[0];
    }
  } else if (params.length > 1) {
    if (isWaypointType(params[0])) {
      typeBlock = params[0];
      values = params[1];
    } else if (isWaypointType(params[1])) {
      typeBlock = params[1];
    } else {
      values = params[1];
    }
    evParams = params[2] || '';
  } else {
    [typeBlock, ...values] = params;
    values = values.join('!');
  }

  let [position, transitRadius, userLabel, ...heading] = values.split(';');
  let [power = '', supplyType = '', voltage = '', current = '', phases = ''] = evParams.split(';');

  validatedVals.coords = { value: position, isValid: false };
  validatedVals.transitRadius = transitRadius;
  validatedVals.userLabel = userLabel;
  validatedVals.heading = { value: heading.join(';'), isValid: false };

  validatedVals.power = power;
  validatedVals.supplyType = supplyType;
  validatedVals.voltage = voltage;
  validatedVals.current = current;
  validatedVals.phases = phases;

  return Object.assign({ category: 'geo', ...parseType(typeBlock) }, validatedVals);
}

export function parseLink(params) {
  let parsed = parseStreetLinkCommon(params);

  return {
    category: 'link',
    link: { value: parsed.custom, isValid: false },
    displayPosition: { value: parsed.displayPosition, isValid: false },
    userLabel: parsed.userLabel,
    ...parseType(parsed.type),
  };
}

export function parseStreet(params) {
  let parsed = parseStreetLinkCommon(params);
  let streetPosition = parsed.custom ? parsed.custom.split('!')[0].split(';') : [];
  let [coordsValue, ...streetName] = streetPosition;

  if (!isWaypointType(params[0])) {
    params = ['', ...params];
  }

  const evParams = params[5] || '';
  const [power = '', supplyType = '', voltage = '', current = '', phases = ''] = evParams.split(';');

  return {
    category: 'street',
    coords: { value: coordsValue, isValid: false },
    street: { name: streetName.join(';'), value: coordsValue },
    displayPosition: { value: parsed.displayPosition, isValid: false },
    userLabel: parsed.userLabel,
    heading: { value: params[3] || '', isValid: false },
    transitRadius: params[4] || '',
    power,
    supplyType,
    voltage,
    current,
    phases,
    ...parseType(parsed.type),
  };
}

function parseStreetLinkCommon(params) {
  let custom;
  let type;
  let displayPosition = '';
  let userLabel = [];
  if (params.length === 1) {
    custom = params[0];
  } else if (params.length === 2) {
    let typeOrDisplayPositionLabel = params[0];
    if (isWaypointType(typeOrDisplayPositionLabel)) {
      type = typeOrDisplayPositionLabel;
    } else {
      [displayPosition, ...userLabel] = typeOrDisplayPositionLabel.split(';');
    }
    custom = params[1];
  } else {
    if (!isWaypointType(params[0])) {
      params = ['', ...params];
    }
    type = params[0];
    let displayPositionLabel = params[1];
    let other = params.slice(2);
    [displayPosition, ...userLabel] = displayPositionLabel.split(';');
    custom = other.join('!');
  }

  return {
    type,
    displayPosition,
    userLabel: userLabel.join(';'),
    custom,
  };
}

function isWaypointType(value) {
  return !!routeWaypointTypes.find(type => value.indexOf(type) !== -1);
}

function parseType(typeBlock) {
  const [type, stopOverDuration] = (typeBlock || '').split(',');

  return {
    type,
    stopOverDuration: {
      value: stopOverDuration,
      isValid: false,
    },
  };
}
