import { getPDETilesSettings, getPDEIndexSettings } from 'config/platformDataExtension';
import fetch from 'utils/fetch';
import { stringifyUrl, objectToQueryString } from 'utils/url';

export function getIndex(linkId = '', settings = { app_code: '', app_id: '', language: '' },
                         attributes = '', layer = '') {
  let indexParams = Object.assign({}, settings, {
    values: linkId.split('-').slice(-1),
    attributes,
    layer,
  });
  let indexUrl = stringifyUrl({
    queryParams: objectToQueryString(indexParams),
  }, getPDEIndexSettings()).apiUrl;

  return fetch(indexUrl);
}

export function getTiles(configs = [], settings = { app_code: '', app_id: '', language: '' },
                         index = { level: 0, tileXY: {}, functionalClass: 0 }) {
  let tilesParams = Object.assign({}, settings, getTilesParams(index, configs));
  let tilesUrl = stringifyUrl({
    queryParams: objectToQueryString(tilesParams),
  }, getPDETilesSettings()).apiUrl;

  return fetch(tilesUrl);
}

export function extractData(tiles = [], linkId = '', configs = []) {
  return configs.reduce((acc, config, index) => {
    const row = tiles[index].Rows.find((row) => {
      const rowLinkId = row.LINK_ID || row.LINK_IDS;

      return `-${rowLinkId.replace(/^[B\-+]/, '')}` === linkId;
    });

    return { ...acc, [config.key]: row };
  }, {});
}

export function parseCoords(coords) {
  let splitted = coords.split(',');
  let prev = parseInt(splitted[0]);
  let toCoord = val => val / 100000;
  let result = [toCoord(prev)];
  for (let i = 1; i < splitted.length; i++) {
    if (splitted[i]) {
      prev += parseInt(splitted[i]);
      result.push(toCoord(prev));
    } else {
      result.push(toCoord(prev));
    }
  }

  return result;
}

export function parseFlags(linkAttributes) {
  let flags = [];
  Object.keys(linkAttributes).forEach(key => {
    if (linkAttributes[key] === 'Y') {
      flags.push(key.toLowerCase());
    }
  });

  return flags;
}

function getTilesParams(index = { level: 0, tileXY: {}, functionalClass: 0 }, configs = []) {
  let levels = [];
  let tilexy = [];
  let layers = [];
  let tilexyStr = `${index.tileXY.x},${index.tileXY.y}`;
  configs.forEach(layer => {
    levels.push(index.level);
    tilexy.push(tilexyStr);
    layers.push(`${layer.key}${index.functionalClass}`);
  });

  return { layers, tilexy, levels };
}
