import { ZINDEX } from 'config/map';

import { createStrip } from './PolylineHelper';

export default {
  createPolygon(args = {}) {
    let {
      shape,
      strokeColor = '#05A',
      fillColor = '#05A',
      lineWidth = 2,
      pointArgsCount = 2,
      zIndex = ZINDEX.POLYGON,
    } = args;
    let strip = createStrip(shape, pointArgsCount);

    return new H.map.Polygon(strip, {
      style: {
        fillColor,
        strokeColor,
        lineWidth,
      },
      zIndex,
    });
  },
};
