import * as mapMarkers from '../../../utils/MarkersHelper';

import ModuleBase from '../../../modules/_Base';

import { ZINDEX } from 'config/map';

export default class HoveredPoint extends ModuleBase {
  coords = null;

  constructor(map, props) {
    super(map, props);
    this.MANEUVER_ZOOM_LEVEL = 17;
  }

  process(coords = '', tabColorPalette = {}) {
    if (coords !== this.coords) {
      this.clearGroup();
      let style = { lineWidth: 3, strokeColor: 'red', fillColor: tabColorPalette.primary },
        options = { style, zIndex: ZINDEX.SELECTED_POINT };

      if (!coords) {
        return false;
      }

      const coordsArr = String(coords).split(',');
      const circle = mapMarkers.createCircle(coordsArr, 20, options);
      this.getGroup().addObject(circle);
    }

    return true;
  }
}
