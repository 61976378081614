import UnitBase from '../_Base';
import Marker from './modules/Marker';
import Direction from './modules/Direction';

export default class SearchUnit extends UnitBase {

  constructor(map, refs = []) {
    super(map, refs);
    this.initModules();
  }

  initModules() {
    this.modules = {
      direction: new Direction(this.map),
      marker: new Marker(this.map),
    };
  }

  isLinks(results) {
    if (results.length > 0) {
      return results[0].linkId !== undefined;
    }
    return false;
  }

  process(searchState, onContextMenu) {
    this.modules.direction.clearGroups();
    this.modules.marker.clearGroups();
    if (this.isLinks(searchState.results)) {
      this.modules.direction.process(searchState);
    } else {
      this.modules.marker.process(searchState, onContextMenu);
    }
  }
}
