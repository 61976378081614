import numericValidation from 'utils/validation/numericValidation';
import utils from 'utils';

export default function dateTimeValidation(value) {
  let errorMessage = 'Invalid date format';
  if (value === 'now') {
    return [];
  }
  let [date = '', timeTimezone = ''] = value.split('T');
  let [time, timezone] = timeTimezone.split(/[+\-Z]/);
  let [year, day, month] = date.split('-');
  let length2Validator = val => !val || val.length !== 2;
  if (isNaN(new Date(`${date}T${time}`).getTime()) || year.length !== 4 || [day, month].some(length2Validator)) {
    return [errorMessage];
  }
  let [hour, minute, seconds] = time.split(':');
  if ([hour, minute, seconds].some(length2Validator)) {
    return [errorMessage];
  }
  if (!utils.isEmpty(timezone)) {
    let timezoneHoursValidation = numericValidation({ max: 12 });
    let [timezoneHours, timezoneMinutes = '00'] = timezone.split(':');
    if (!utils.isEmpty(timezone) && (!timezoneHoursValidation(timezoneHours) || +timezoneMinutes !== 0)) {
      return [errorMessage];
    }
  }
  return [];
}
