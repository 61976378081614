import React from 'react';
import PropTypes from 'prop-types';
import CustomInput from 'shared/input';
import format from 'utils/format';
import FormRow from 'shared/formRow';
import ExpandFormRow from 'shared/expandFormRow';
import './styles/olsSummary.scss';
import utils from 'utils';
////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////////////////////////////////
////////////////////////////////////////
//////////

const getSummary = ({ summary }) => summary;
const getTravelSummary = ({ travelSummary }) => travelSummary;
const detectSummaryGetter = (section) => section && [getSummary, getTravelSummary].find(method => method(section));

class OlsSummary extends React.Component {
  getCompareButtons = () => {
    const { formData } = this.props;
    return (
      <>
        <FormRow>
          <CustomButton onClick={openHlsTab.bind(this, this.props)} title="Compare to HLS" />
        </FormRow>
        <FormRow>
          <CompareGoogleButton
            formData={formData}
            getOptions={getOlsOptions}
            getWaypoints={getOlsWaypoints}
          />
        </FormRow>
        <FormRow>
          <CompareTomTomButton
            formData={formData}
            getOptions={getOlsOptions}
            getWaypoints={getOlsWaypoints}
          />
        </FormRow>
        <FormRow>
          <CompareMapFanButton
            formData={formData}
            getOptions={getOlsOptions}
            getWaypoints={getOlsWaypoints}
          />
        </FormRow>
      </>
    );
  }

  render() {
    const { route } = this.props;
    const sections = route.sections || [];

    let totalDuration = 0;
    let totalLength = 0;
    let totalMlDuration = 0;

    const getSummary = detectSummaryGetter(sections[0]);

    if (!getSummary) {
      return (
        <div className="rf-empty">
          No summary information was requested from server.<br />
          In order to fetch this information, please
          select<br />
          <b>Return options -&gt; Summary</b> or
          <b>Return options -&gt; Travel Summary</b>
        </div>
      );
    }

    const totalConsumption = sections.reduce((total, section) =>
      total + (!section.spans ? 0 : section.spans.reduce((sum, span) => sum + span.consumption, 0)), 0);

    const totalChargingDuration = sections.reduce((total, section) =>
      total + (!section.postActions ? 0 : section.postActions
      .filter(postAction => ['charging', 'chargingSetup'].indexOf(postAction.action) > -1)
      .reduce((sum, postAction) => sum + postAction.duration, 0)), 0);

    const totalTrafficDuration = sections.reduce((total, section) =>
      total + (utils.getObject(section, 'summary.duration', 0) -
      utils.getObject(section, 'summary.baseDuration', 0)), 0);

    const sectionsEls = sections.map((section, index) => {
      const { duration, length, mlDuration } = getSummary(section);
      totalDuration += duration;
      totalLength += length;
      if (mlDuration) {
        totalMlDuration += mlDuration;
      }
      const label = `Section ${index + 1}`;
      const consumption = !section.spans ? null : section.spans.reduce((sum, span) => sum + span.consumption, 0);
      const chargingDuration = !section.postActions ? null : section.postActions
        .filter(postAction => ['charging', 'chargingSetup'].indexOf(postAction.action) > -1)
        .reduce((sum, postAction) => sum + postAction.duration, 0);
      const trafficDuration = utils.getObject(section, 'summary.duration', 0) -
        utils.getObject(section, 'summary.baseDuration', 0);

      const notices = (section.notices || []).map((notice, index) => {
        const inputs = Object.keys(notice).map((key) => (
          <FormRow key={key}>
            {isString(notice[key]) ?
              <CustomInput
                label={key}
                isReadonly
                value={notice[key]}
              /> :
              <>
                <label className="rf-input rf-input_readonly">
                  <span className="rf-input__caption">details</span>
                </label>
                <Tree data={notice[key]} shouldExpandNode={() => true} />
              </>
            }

          </FormRow>
        ));

        return (
          <FormRow cssClasses="rf-section-notice__item" key={index}>
            {inputs}
          </FormRow>
        );
      });

      return (
        <ExpandFormRow isExpanded label={label} key={label}>
          {notices.length > 0 && (
            <FormRow cssClasses="rf-section-notice">
              <h5>Notices:</h5>
              <div>
                {notices}
              </div>
            </FormRow>
          )}
          <FormRow>
            <CustomInput
              type="text"
              label="Duration"
              isReadonly
              value={format.time(duration)}
            />
          </FormRow>
          {!!trafficDuration && <FormRow>
            <CustomInput
              type="text"
              label="Incl. traffic duration"
              isReadonly
              value={format.time(trafficDuration)}
            />
          </FormRow>}
          {mlDuration != null && <FormRow>
            <CustomInput
              type="text"
              label="ML duration"
              isReadonly
              value={format.time(mlDuration)}
            />
          </FormRow>}
          {!!chargingDuration && <FormRow>
            <CustomInput
              type="text"
              label="Incl. charging duration"
              isReadonly
              value={format.time(chargingDuration)}
            />
          </FormRow>}
          <FormRow>
            <CustomInput
              type="text"
              label="Length"
              isReadonly
              value={format.distance(length)}
            />
          </FormRow>
          {!!consumption && <FormRow>
            <CustomInput
              type="text"
              label="Consumption"
              isReadonly
              value={`${+consumption.toPrecision(5)} kWh`}
            />
          </FormRow>}
        </ExpandFormRow>
      );
    });

    let compareButtons = null;
////////////////////
//////////////////////////////////////////////
//////////////

    return (
      <div className="rf-ols-summary">
        {sectionsEls}
        <FormRow>
          <CustomInput
            type="text"
            label="Total Duration"
            isReadonly
            value={format.time(totalDuration)}
          />
        </FormRow>
        {!!totalTrafficDuration && <FormRow>
          <CustomInput
            type="text"
            label="Incl. total traffic duration"
            isReadonly
            value={format.time(totalTrafficDuration)}
          />
        </FormRow>}
        {!!totalChargingDuration && <FormRow>
          <CustomInput
            type="text"
            label="Incl. total charging duration"
            isReadonly
            value={format.time(totalChargingDuration)}
          />
        </FormRow>}
        {totalMlDuration > 0 && <FormRow>
          <CustomInput
            type="text"
            label="Total ML duration"
            isReadonly
            value={format.time(totalMlDuration)}
          />
        </FormRow>}
        <FormRow>
          <CustomInput
            type="text"
            label="Total Length"
            isReadonly
            value={format.distance(totalLength)}
          />
        </FormRow>
        {!!totalConsumption && <FormRow>
          <CustomInput
            type="text"
            label="Total consumption"
            isReadonly
            value={`${+totalConsumption.toPrecision(5)} kWh`}
          />
        </FormRow>}
        {compareButtons}
      </div>
    );
  }
}

OlsSummary.propTypes = {
  formData: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default OlsSummary;
