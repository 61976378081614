import * as mapMarkers from '../../../utils/MarkersHelper';

import ModuleBase from '../../../modules/_Base';

import { ZINDEX } from 'config/map';

export default class SelectedPoint extends ModuleBase {
  constructor(map, props) {
    super(map, props);
    this.MANEUVER_ZOOM_LEVEL = 17;
  }

  process(coords = '', zoomToLocation = false) {
    this.clearGroup();
    let style = { lineWidth: 3, strokeColor: '#f00', fillColor: 'transparent' },
        options = { style, zIndex: ZINDEX.SELECTED_POINT },
        coordsArr = null,
        circle = null;

    if (!coords) {
      return false;
    }

    coordsArr = String(coords).split(',');
    circle = mapMarkers.createCircle(coordsArr, 20, options);
    this.getGroup().addObject(circle);
    if (zoomToLocation) {
      this.zoomToLocation({
        lat: coordsArr[0],
        lng: coordsArr[1],
      });
    }

    return true;
  }

  zoomToLocation(coords) {
    this.map.setZoom(this.MANEUVER_ZOOM_LEVEL, false);
    this.map.setCenter(coords, false);
  }
}
