import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';

import CustomTooltip from 'shared/tooltip';
import CustomButton from 'shared/button';

import './styles/copyButton.scss';

export class CustomCopyButton extends Component {
  constructor(props) {
    super(props);
    this.onCopy = this.onCopy.bind(this);
    this.resetTooltipTitle = this.resetTooltipTitle.bind(this);
    this.tooltipTitles = {
      _default: 'Copy to Clipboard',
      copied: 'Copied!',
    };
    this.state = {
      tooltipCopy: this.tooltipTitles._default,
    };
  }

  onCopy(value) {
    this.setState({
      tooltipCopy: this.tooltipTitles.copied,
    });
    this.props.onCopy(value);
  }

  resetTooltipTitle() {
    setTimeout(() => {
      this.setState({
        tooltipCopy: this.tooltipTitles._default,
      });
    }, 100);
  }

  render() {
    let { title, value, btnClass } = this.props;

    return (
      <div className="rf-copy-button">
        <CustomTooltip
          cssClasses="rf-copy-button__tooltip"
          tooltip={this.state.tooltipCopy}
          placement="top"
        >
          <span onMouseOut={this.resetTooltipTitle}>
            <CopyToClipboard text={value} onCopy={this.onCopy}>
              <CustomButton title={title} cssClasses={btnClass} />
            </CopyToClipboard>
          </span>
        </CustomTooltip>
      </div>
    );
  }
}
CustomCopyButton.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  btnClass: PropTypes.string.isRequired,
  onCopy: PropTypes.func.isRequired,
};

CustomCopyButton.defaultProps = {
  title: '',
  btnClass: '',
  value: '',
  onCopy() {},
};

export default CustomCopyButton;
