export default {
  mode: {
    type: 'fastest',
    transport: 'car',
    traffic: '',
    features: {
      tollroad: { value: '-3', isChecked: false },
      motorway: { value: '-3', isChecked: false },
      boatFerry: { value: '-3', isChecked: false },
      railFerry: { value: '-3', isChecked: false },
      tunnel: { value: '-3', isChecked: false },
      dirtRoad: { value: '-3', isChecked: false },
      park: { value: '-3', isChecked: false },
    },
  },
  matrixAttributes: ['ix', 'su'],
  summaryAttributes: ['cf', 'tt', 'di'],
  searchRange: '',
  excludecountries: '',
  speedprofile: '',
  avoidlinks: {
    value: '',
    isValid: true,
  },
  routeDate: {
    key: '',
    value: '',
  },
  enhancedbicyclerouting: '',
  useNewPointsFormat: false,
};
