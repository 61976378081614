import FormBase from '../../_Base';
import { AvoidAreas } from '../../../modules';

import Markers from './Markers';
import Lines from './Lines';
import Polygon from './Polygon';

import utils from 'utils';

export default class Matrix extends FormBase {
  initModules() {
    return {
      markers: new Markers(this.map, this.state).initHandlers({
        onMarkerClick: this.onMarkerClick.bind(this),
      }),
      lines: new Lines(this.map, this.state),
      areas: new AvoidAreas(this.map, this.state),
      polygon: new Polygon(this.map, this.state),
    };
  }

  _handleDragging(method, args) {
    let targetData = args[0],
        _modules = {
          MAIN: this.modules.markers,
          RESIZE: this.modules.areas,
        };
    this.applyModule(_modules[targetData.type], method, args);
  }

  onDragStart(...args) {
    this._handleDragging('onDragStart', args);
  }

  onDrag(...args) {
    this._handleDragging('onDrag', args);
  }

  onDragEnd(...args) {
    this._handleDragging('onDragEnd', args);
  }

  _normalizeResponseKey(key) {
    return {
      start: 'startIndex',
      destination: 'destinationIndex',
    }[key];
  }

  setViewBounds(force) {
    super.setViewBounds(this.modules.lines.getGroup(), force);
  }

  onMarkerClick(markerData) {
    if (this.props.selectedTab === this.state.index) {
      let normalizedKey = this._normalizeResponseKey(markerData.key),
        marker = {
          key: normalizedKey,
          index: markerData.index,
          [normalizedKey]: markerData.index,
        };

      this.props.setSelectedEntity({ marker });
    }
  }

  process(oldProps, nextProps, forceRender = false) {
    super.process(oldProps, nextProps, forceRender);
    let { isError } = nextProps.tabData.response;
    if (isError) {
      this.clearGroups();
    }

    let isNewRequestReceived = this.isTimestampChanged(nextProps),
        isSelectedEntityChanged = this.isPropChanged(nextProps, 'tabData.formData.selectedEntity');
    const isSelectedTabChanged = this.isPropChanged(nextProps, 'selectedTab');
    if (forceRender || this.isMarkersChanged(nextProps) || isNewRequestReceived ||
      isSelectedEntityChanged || isSelectedTabChanged) {
      this.processLines(nextProps);
    }

    if (forceRender || isError || this.isCoordsChanged(nextProps) || isNewRequestReceived) {
      this.modules.markers.process(nextProps);
    }

    if (forceRender || this.isAvoidAreasChanged(nextProps)) {
      this.processAvoidAreas(nextProps);
    }

    if (forceRender || this.isIsolineWaypointsChanged(nextProps)) {
      this.processPolygons(nextProps);
    }

    this.setProps(nextProps);
  }

  processLines(props = this.props) {
    const matrixEntries = utils.getObject(props, 'tabData.response.data.response.matrixEntry');
    const selectedEntity = utils.getObject(props, 'tabData.formData.selectedEntity');
    const { formData, tabColorPalette } = props.tabData;
    const isSelectedTab = props.selectedTab === this.state.index;
    this.modules.lines.process(formData, tabColorPalette, matrixEntries, selectedEntity, isSelectedTab);
  }

  processAvoidAreas(props = this.props) {
    if (props.selectedTab === this.state.index) {
      this.modules.areas.process(props.tabData.formData.avoidAreas);
    } else {
      this.modules.areas.clearGroups();
    }
  }

  processPolygons(props = this.props) {
    this.modules.polygon.process(props);
  }

  isCoordsChanged(nextProps) {
    return this.isPropChanged(nextProps, 'formData.points');
  }

  isAvoidAreasChanged(nextProps) {
    return this.isPropChanged(nextProps, 'formData.avoidAreas');
  }

  isIsolineWaypointsChanged(nextProps) {
    return this.isPropChanged(nextProps, 'formData.isolineWaypoints');
  }

  isMarkersChanged(nextProps) {
    return this.isPropChanged(nextProps, this.getPath('matrixEntry'));
  }
}
