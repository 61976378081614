import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'rc-tooltip';

import CustomComponent from 'shared/component';

import 'rc-tooltip/assets/bootstrap.css';

import './styles/tooltip.scss';

class CustomTooltip extends CustomComponent {

  render() {
    let { tooltip, placement } = this.props;

    return (
        <ReactTooltip
          overlayClassName={this.getCssClasses(['rf-tooltip'])}
          overlay={tooltip}
          placement={placement}
        >
          <div className="rf-tooltip__children">
            {this.props.children}
          </div>
        </ReactTooltip>
    );
  }
}

CustomTooltip.defaultProps = {
  placement: 'left',
};

CustomTooltip.propTypes = {
  placement: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default CustomTooltip;
