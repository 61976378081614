import numericValidation from './numericValidation';
import consumptionDefaultState from 'state/tabs/tab/formData/routingTab/common/consumptionDefaultState';
import speedValidation from 'utils/validation/speedValidation';

const consumptionDetailsKeys = Object.keys(consumptionDefaultState.customconsumptiondetails);

export default function consumptionValidation(value) {
  let params = value.split(';');
  let errors = [];
  let consumptionParams = params.reduce((res, param) => {
    let paramArr = param.split(',');
    res[paramArr[0]] = paramArr.slice(1).join(',');
    return res;
  }, {});

  if (consumptionParams.speed && !speedValidation(consumptionParams.speed)) {
    errors.push(`Invalid speed value '${consumptionParams.speed}'`);
  }

  Object.keys(consumptionParams).forEach(key => {
    if (consumptionDetailsKeys.indexOf(key) === -1) {
      errors.push(`Unsupported consumption key '${key}'`);
    } else if (key !== 'speed') {
      errors = errors.concat(numericValidation({ key, min: 0 })(consumptionParams[key]));
    }
  });

  return errors;
}
