import * as types from './currentRouteConstants';

function setRoute(state = 0, action) {
  switch (action.type) {
    case types.SET_ROUTE:
      return action.currentRoute;

    default:
      return state;
  }
}

export default setRoute;
