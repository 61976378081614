import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormattedResultBtn from '../FormattedResultBtn';
import CustomInput from 'shared/input';
import FormRow from 'shared/formRow';

import utils from 'utils';

export default class ResponseTabComponent extends Component {
  timestamp() {
    if (!this.props.timestamp) {
      return null;
    }

    return <FormRow>
      <CustomInput
        label="Timestamp"
        isReadonly
        value={this.props.timestamp}
      />
    </FormRow>;
  }

  render() {
    let { responseData, onShowFormattedResult } = this.props;
    let serverTime = parseInt(utils.getObject(responseData, 'serverTime')) / 1000;

    return (
      <lui-default-theme>
      <div className="rf-result-panel__tabs-gap">
        <FormRow>
          <CustomInput
            label="Response Time"
            isReadonly
            value={`${responseData.time} ms`}
          />
        </FormRow>
        <FormRow isHidden={isNaN(serverTime)}>
          <CustomInput
            label="Backend Time"
            isReadonly
            value={`${Math.round(serverTime)} ms`}
          />
        </FormRow>
        {this.timestamp()}
        <FormRow>
          <FormattedResultBtn onClick={onShowFormattedResult} />
        </FormRow>
      </div>
      </lui-default-theme>
    );
  }
}

ResponseTabComponent.propTypes = {
  responseData: PropTypes.object.isRequired,
  timestamp: PropTypes.string,
  onShowFormattedResult: PropTypes.func.isRequired,
};
