export const AUTH_TYPE = {
  APP_ID_AND_APP_CODE: 'APP_ID_AND_APP_CODE',
  HERE_TOKEN: 'HERE_TOKEN',
  APIKEY: 'APIKEY',
};

export const HERE_TOKEN_ENDPOINTS = {
  PRODUCTION: 'https://account.api.here.com/oauth2/token',
  STAGING: 'https://stg.account.api.here.com/oauth2/token',
};
