export default {
  routeTypes: ['fastest', 'shortest'],
  rangeTypes: [
    { label: 'Distance', value: 'distance' },
    { label: 'Time', value: 'time' },
    { label: 'Consumption', value: 'consumption' },
  ],
  routeTransports: [
    { label: 'Car', value: 'car' },
    { label: 'Pedestrian', value: 'pedestrian' },
    { label: 'Truck', value: 'truck' },
  ],
};
