import stringifyAdvancedWaypoint from '../common/advancedWaypointParams';

export const olsEvParameters = [
  'freeFlowSpeedTable', 'trafficSpeedTable', 'auxiliaryConsumption', 'ascent', 'descent', 'acceleration',
  'deceleration', 'highSpeedThreshold', 'makeReachable', 'initialCharge', 'maxCharge', 'chargingCurve',
  'maxChargingVoltage', 'maxChargingCurrent', 'maxChargeAfterChargingStation', 'minChargeAtChargingStation',
  'minChargeAtDestination', 'chargingSetupDuration',
];

export default function exec(data) {
  const _data = Object.assign({}, data);

  _data.return = _data.olsReturn;
  delete _data.olsReturn;
  delete _data.selectedManeuver;
  delete _data.selectedTurnByTurnAction;
  delete _data.selectedSpan;
  delete _data.currentRoute;
  delete _data.highlightedRoute;

  if (_data.evEnabled) {
    olsEvParameters.forEach(param => {
      _data[`ev[${param}]`] = _data[param];
    });
  } else {
    delete _data['ev[connectorTypes]'];
  }

  if (_data.transportMode !== 'truck') {
    Object.keys(_data).forEach((key) => {
      if (key.startsWith('truck[')) {
        delete _data[key];
      }
    });
  }

  olsEvParameters.forEach(param => delete _data[param]);
  delete _data.evEnabled;

  const originAdvancedStr = stringifyAdvancedWaypoint(_data.originAdvanced);
  const destinationAdvancedStr = stringifyAdvancedWaypoint(_data.destinationAdvanced);
  _data.origin = {
    ..._data.origin,
    value: `${_data.origin.value}${originAdvancedStr}`,
  };
  _data.destination = {
    ..._data.destination,
    value: `${_data.destination.value}${destinationAdvancedStr}`,
  };

  delete _data.originAdvanced;
  delete _data.destinationAdvanced;

  return _data;
}
