import * as types from '../constants';

const DEFAULT_STATE = { data: { routes: [] } };

export default function apiResponse(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case types.RECEIVE_DATA_INTERMODAL:
      return action.result || DEFAULT_STATE;

    default:
      return state;
  }
}
