import localStorageReducerDecorator from 'utils/localStorageReducerDecorator';
import credentials from 'config/credentials';

const SET = 'appSettings/SET_OLS_SEARCH';

const reducer = (state, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default localStorageReducerDecorator(
  reducer,
  'appSettings.olsSearch.v1',
  {
    apiKey: credentials.OLS_SEARCH.apiKey,
  },
);

export const setOlsSearchSettings = (data) => ({ type: SET, payload: data });

export const getOlsSearchSettings = (state) => state.appSettings.olsSearch;
