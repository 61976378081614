import consumptionDefaultState from '../../common/consumptionDefaultState';

export default {
  routeDate: {
    key: '',
    value: '',
  },
  mode: {
    type: 'fastest',
    transport: 'car',
    traffic: '',
    features: {
      tollroad: { value: '-3', isChecked: false },
      motorway: { value: '-3', isChecked: false },
      boatFerry: { value: '-3', isChecked: false },
      railFerry: { value: '-3', isChecked: false },
      tunnel: { value: '-3', isChecked: false },
      dirtRoad: { value: '-3', isChecked: false },
      park: { value: '-3', isChecked: false },
    },
  },
  jsonattributes: {
    lowerCaseFirstCharacter: true,
    includeTypeElement: true,
    usePluralNaming: false,
    shapesToArraysWithPairs: true,
    shapesToArraysWithTriplets: false,
    jsonWrapper: false,
  },
  quality: {
    value: 1,
    isValid: true,
  },
  resolution: {
    value: '',
    isValid: true,
  },
  maxpoints: {
    value: '',
    isValid: true,
  },
  singlecomponent: '',
  rangetype: 'distance',
  speedprofile: '',
  range: {
    isValid: true,
    value: '',
  },
  extraparams: {
    value: '',
    isValid: true,
  },
  ...consumptionDefaultState,
};
