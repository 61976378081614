import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';
import RadioOption from './option';

import './styles/radioGroup.scss';

let radioCounter = 0;

class RadioGroup extends CustomComponent {
  onChange(e) {
    const { disabled, onChange } = this.props;
    if (!disabled) {
      onChange(e);
    }
  }

  render() {
    let { label, options, value, disabled, columns, name = `radio-${++radioCounter}` } = this.props,
      labelEl = label ? <span className="rf-radiogroup__caption">{label}</span> : '',
      optionElements = options.map((option, idx) => {
        let _value = option.value !== undefined ? option.value : option;

        return (
          <RadioOption
            key={idx}
            isChecked={value === _value}
            label={option.label || option}
            value={_value}
            name={name}
            tooltip={option.tooltip}
            onChange={::this.onChange}
            disabled={disabled}
          />
        );
      });

    return (
      <div className={this.getCssClasses(['rf-radiogroup', 'rf-clearfix'])}>
        {labelEl}
        <lui-radiobutton-group columns={columns}>
          {optionElements}
        </lui-radiobutton-group>
      </div>
    );
  }
}

RadioGroup.defaultProps = {
  label: '',
  value: '',
  disabled: false,
  columns: 2,
};

RadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string,
  columns: PropTypes.number.isRequired,
};

export default RadioGroup;
