import utils from 'utils';
import ModuleBase from '../../../modules/_Base';
import { createPolyline } from '../../../utils/PolylineHelper';
import * as mapMarkers from '../../../utils/MarkersHelper';

export default class RouteByPoints extends ModuleBase {
  process(nextProps) {
    this.clearGroups();
    this.renderRoute(nextProps);
  }

  renderRoute(nextProps) {
    const { points, showPoints, showPolyline } = nextProps.tabData.formData.routeFromPoints;
    if (points.value === '' || !points.isValid) {
      return;
    }
    const shape = points.value.split(/[;,]/);
    if (shape.length < 4) {
      return;
    }

    const group = this.getGroup();

    if (showPolyline) {
      const border = createPolyline({
        shape,
        strokeColor: nextProps.tabData.tabColorPalette.primaryDarker,
      });
      const route = createPolyline({
        shape,
        strokeColor: nextProps.tabData.tabColorPalette.primary,
        lineWidth: 5,
      });
      group.addObject(border);
      group.addObject(route);
    }

    if (showPoints) {
      const markers = utils.chunk(shape, 2).map(point => mapMarkers.createMappedMarker({
        mappedPosition: {
          latitude: point[0],
          longitude: point[1],
        },
      }));

      group.addObjects(markers);
    }
  }
}
