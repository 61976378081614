import RoutingForm from '../forms/routingTab/RoutingForm';
import MatrixForm from '../forms/routingTab/MatrixForm';
import IsolineForm from '../forms/routingTab/IsolineForm';
import RouteInfoForm from '../forms/routingTab/RouteInfoForm';

////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////////
import VisualizeDataPointsForm from '../forms/offlineToolsTab/VisualizeDataPointsForm';
import IntermodalForm from '../forms/transitTab/intermodalForm/intermodalRoutingForm';

import formsEnum from 'config/formsEnum';
import modulesEnum from 'config/modulesEnum';
import OlsForm from '../forms/routingTab/olsApi/OlsForm';

export const FORMS_LIST = {
  [modulesEnum.ROUTING]: {
    [formsEnum.CALCULATE_ROUTE]: RoutingForm,
    [formsEnum.CALCULATE_MATRIX]: MatrixForm,
    [formsEnum.CALCULATE_ISOLINE]: IsolineForm,
    [formsEnum.GET_ROUTE_INFO]: RouteInfoForm,
////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////
//////////////
    [formsEnum.OLS]: OlsForm,
  },
//////////////////
/////////////////////////
///////////////////////////////////
////
//////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////
////
/////////////////////////////
/////////////////////////////////////////////////
////
//////////////////////
/////////////////////////////
////
//////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////
////
//////////////////////////////
////////////////////////////////////////////
////
////////////
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: IntermodalForm,
  },
  [modulesEnum.OFFLINE_TOOLS]: {
    [formsEnum.VISUALIZE_DATA_POINTS]: VisualizeDataPointsForm,
  },
};
