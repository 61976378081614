import * as types from './constants';
import { assignTabDataAndDispatch } from '../../actions';

export function avoidAreaUpdate(data) {
  return assignTabDataAndDispatch({ type: types.AVOID_AREA_UPDATE, data });
}

export function avoidAreaUpdateAll(data) {
  return assignTabDataAndDispatch({ type: types.AVOID_AREA_UPDATE_ALL, data });
}

export function avoidAreaAdd(data) {
  return assignTabDataAndDispatch({ type: types.AVOID_AREA_ADD, data });
}

export function avoidAreaRemove(data) {
  return assignTabDataAndDispatch({ type: types.AVOID_AREA_REMOVE, data });
}
