import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BaseContextMenu from '../../_ContextMenu';
import { inspectLink } from 'state/inspectLink/actions';
import * as NotificationActions from 'state/notification/actions';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';
import { getCurrentFormData } from '../../../../state/tabs/tab/formData';
import { getInspectLinkSettings } from 'state/appSettings/inspectLink/reducer';

class ContextMenuContainer extends BaseContextMenu {
  addWaypoint() {
    let { formData: { fields: { shape } }, data: { geo } } = this.props;
    let trimmed = shape.value.replace(new RegExp(';*$'), '');
    if (trimmed.length) {
      trimmed += ';';
    }
    let value = `${trimmed}${geo}`;
    this.props.filterValueUpdate({ key: 'shape', currentForm: this.props.currentForm, value });
  }

  getMenuItems(data) {
    let items = [
      { label: 'Add waypoint', iconLabel: 'A', fn: ::this.addWaypoint },
    ];
    return items.concat(super.getMenuItems(data));
  }
}

ContextMenuContainer.defaultProps = {
  data: {},
};

ContextMenuContainer.propTypes = {
  inspectLink: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  settingsData: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentForm: getForm(state),
    module: getModule(state),
    settingsData: getSettingsData(state),
    formData: getCurrentFormData(state),
    inspectLinkSettings: getInspectLinkSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inspectLink: bindActionCreators(inspectLink, dispatch),
    textCopiedNotify: bindActionCreators(NotificationActions.textCopiedNotify, dispatch),
    requestErrorNotify: bindActionCreators(NotificationActions.requestErrorNotify, dispatch),
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextMenuContainer);
