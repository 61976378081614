import RoutingBase from '../../common/_RoutingBase';
import { AvoidAreas, Maneuver } from '../../../modules';
import Markers from './Markers';
import SelectedPoint from './SelectedPoint';
import Routes from './Routes';
import Polygon from './Polygon';
import HoveredPoint from './HoveredPoint';

export default class Routing extends RoutingBase {
  constructor(...args) {
    super(...args);
    this._onMove = ::this._onMove;
    this.isRubberBandingAllowed = true;
    this.map.addEventListener('pointermove', this._onMove, false);
  }

  destroy() {
    this.map.removeEventListener('pointermove', this._onMove);
    super.destroy();
  }

  initModules() {
    return {
      routes: new Routes(this.map, this.state),
      markers: new Markers(this.map, this.state),
      selectedPoint: new SelectedPoint(this.map, this.state),
      hoveredPoint: new HoveredPoint(this.map, this.state),
      areas: new AvoidAreas(this.map, this.state),
      polygon: new Polygon(this.map, this.state),
      maneuver: new Maneuver(this.map, this.state),
    };
  }

  setViewBounds(force) {
    let group = this.modules.routes.getGroup();
    if (!group.getBoundingBox()) {
      group = this.modules.markers.getGroup();
    }
    super._setViewBounds(group, force);
  }

  isIsolineWaypointsChanged(nextProps) {
    return this.isPropChanged(nextProps, 'formData.isolineWaypoints');
  }

  processPolygons(props = this.props) {
    this.modules.polygon.process(props);
  }

  process(props, nextProps, forceRender) {
    if (forceRender || this.isIsolineWaypointsChanged(nextProps)) {
      this.processPolygons(nextProps);
    }
    super.process(props, nextProps, forceRender);

    this.modules.hoveredPoint.process(
      _.get(nextProps, 'formData.fields.hoveredPoint'),
      _.get(nextProps, 'tabData.tabColorPalette'),
    );
  }

  _onMove(e) {
    this.modules.markers.showBubble(e);
  }
}
