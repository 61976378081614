import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BaseContextMenu from '../../_ContextMenu';

import * as MatrixPointsActions from 'state/tabs/tab/formData/routingTab/matrixForm/points/actions';
import { avoidAreaAdd } from 'state/tabs/tab/formData/common/avoidAreas/actions';
import { inspectLink } from 'state/inspectLink/actions';
import * as NotificationActions from 'state/notification/actions';

import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';
import * as TabsActions from 'state/tabs/actions';
import { getInspectLinkSettings } from 'state/appSettings/inspectLink/reducer';

class ContextMenuContainer extends BaseContextMenu {
  addPoint(key) {
    this.props.addPoint({
      key,
      value: {
        coords: {
          value: this.props.data.geo,
        },
      },
    });
    this.hide();
  }

  addAvoidArea() {
    let { currentForm, data, avoidAreaAdd } = this.props;
    let value = { value: data.avoidArea, isShown: true };
    avoidAreaAdd({ value, currentForm });
    this.hide();
  }

  openRoutingInNewTab(targetData) {
    this.props.openRoutingInNewTab(targetData.start, targetData.end);
  }

  getMenuItems(data) {
    let { targetData = {} } = data,
        items = [
          { label: 'Add Start', iconLabel: 'S', fn: this.addPoint.bind(this, 'start') },
          { label: 'Add Destination', iconLabel: 'D', fn: this.addPoint.bind(this, 'destination') },
          { label: 'Add Avoid Area', iconLabel: 'R', fn: this.addAvoidArea.bind(this) },
          {
            label: 'Calc Route in New Tab',
            iconLabel: 'T',
            fn: this.openRoutingInNewTab.bind(this, targetData),
            isDisabled: !targetData.isMatrixLine || !targetData.isSelectedTab,
          },
        ];
    return items.concat(super.getMenuItems(data));
  }
}

ContextMenuContainer.defaultProps = {
  data: {},
};

ContextMenuContainer.propTypes = {
  data: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  addPoint: PropTypes.func.isRequired,
  inspectLink: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentForm: getForm(state),
    settingsData: getSettingsData(state),
    module: getModule(state),
    inspectLinkSettings: getInspectLinkSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPoint: bindActionCreators(MatrixPointsActions.add, dispatch),
    avoidAreaAdd: bindActionCreators(avoidAreaAdd, dispatch),
    inspectLink: bindActionCreators(inspectLink, dispatch),
    textCopiedNotify: bindActionCreators(NotificationActions.textCopiedNotify, dispatch),
    requestErrorNotify: bindActionCreators(NotificationActions.requestErrorNotify, dispatch),
    openRoutingInNewTab: bindActionCreators(TabsActions.openRoutingInNewTab, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextMenuContainer);
