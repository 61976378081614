import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles/maneuvers.scss';

class Maneuvers extends React.Component {
  getDetails(maneuver) {
    if (!maneuver.details) {
      return null;
    }
    const detailsEl = Object.keys(maneuver.details).map((key) => (
      <div key={key}>{key}: {maneuver.details[key]}</div>
    ));
    return (
      <div className="rf-maneuvers__details">
        {detailsEl}
      </div>
    );
  }

  render() {
    const { maneuvers, onClick, selectedManeuver } = this.props;
    const maneuversEl = maneuvers.map((maneuver, index) => {
      const isSelected = selectedManeuver.index === index;
      const listItemClasses = classnames('rf-maneuvers', {
        'rf-maneuvers_selected': isSelected,
      });
      return (
        <li
          className={listItemClasses}
          onClick={onClick.bind(this, maneuver.shape, index)}
          key={`${maneuver.instruction}${index}`}
        >
          <div className="rf-maneuvers__inner">
            <div className="rf-maneuvers__content">
              {maneuver.instruction}
            </div>
          </div>
          {isSelected && this.getDetails(maneuver)}
        </li>
      );
    });

    return (
      <ul>
        {maneuversEl}
      </ul>
    );
  }
}

Maneuvers.propTypes = {
  maneuvers: PropTypes.arrayOf(
    PropTypes.shape({
      instruction: PropTypes.string.isRequired,
      shape: PropTypes.array,
      icon: PropTypes.element,
      details: PropTypes.object,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedManeuver: PropTypes.shape({
    shape: PropTypes.array,
    index: PropTypes.number,
  }).isRequired,
};

export default Maneuvers;
