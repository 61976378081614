import { ROUTING_MODE_MAPPER, TRUCK_MAPPER } from './hlsFieldsMapper';
import modulesEnum from '../../../../config/modulesEnum';
import { pointTemplate } from 'state/tabs/tab/formData/common/waypoints/defaultState';
import formsEnum from '../../../../config/formsEnum';
import { isCoordsValid } from '../../../../utils/validation/coordsValidation';
import routingDefaultState from 'state/tabs/tab/formData/routingTab/routingForm/fields/defaultState';

export default (props) => {
  const { addTab, filterValueMultiUpdate, formData: { fields }, setWaypoints } = props;
  const { transportMode, routingMode } = fields;

  addTab({ module: modulesEnum.ROUTING, form: formsEnum.CALCULATE_ROUTE });
  const getWaypoint = (coords, isWaypoint) => ({
    ...pointTemplate,
    coords: { value: coords, isValid: isCoordsValid(coords) },
    isWaypoint,
  });

  const origin = getWaypoint(fields.origin.value, false);
  const destination = getWaypoint(fields.destination.value, false);

  const viaCoords = fields.via.map(point => point.coords.split(','));
  const via = viaCoords.map(coord => getWaypoint(`${coord[0]},${coord[1]}`, true));

  let hlsTruckFields = {};

  Object.keys(fields).forEach((fieldKey) => {
    if (TRUCK_MAPPER[fieldKey]) {
      hlsTruckFields = { ...hlsTruckFields, ...TRUCK_MAPPER[fieldKey](fields[fieldKey]) };
    }
  });

  setWaypoints({ waypoints: [origin, ...via, destination], currentForm: formsEnum.CALCULATE_ROUTE });
  filterValueMultiUpdate(formsEnum.CALCULATE_ROUTE, {
    mode: {
      ...routingDefaultState.mode,
      type: ROUTING_MODE_MAPPER[routingMode] || 'fastest',
      transport: transportMode,
    },
    ...hlsTruckFields,
    alternatives: fields.alternatives.value,
  });
};
