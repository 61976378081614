import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import classnames from 'classnames';

import CustomComponent from 'shared/component';

import './styles/autocomplete.scss';

export default class CustomAutocomplete extends CustomComponent {

  getMenuNode(items) {
    return <div className="rf-autocomplete__menu">{items}</div>;
  }

  getMenuItemNode(item, isHighlighted) {
    let menuItemClasses = classnames('rf-autocomplete__menu-item', {
      'rf-autocomplete__menu-item_selected': isHighlighted,
    });
    return <div className={menuItemClasses} key={item.key}>{item.label}</div>;
  }

  render() {
    let { onChange, onSelect, value, placeholder, isValid, suggestions } = this.props;

    return <Autocomplete
      inputProps={{
        className: classnames('rf-input__field', {
          'rf-input__field_error': !isValid,
        }),
        placeholder,
        ref: 'input',
        ...this.props.inputProps,
      }}
      wrapperProps={{
        className: this.getCssClasses('rf-autocomplete'),
      }}
      value={value}
      items={suggestions}
      getItemValue={item => item.label}
      onSelect={(value, item) => onSelect(item)}
      onChange={(event, value) => onChange(value)}
      renderMenu={::this.getMenuNode}
      renderItem={::this.getMenuItemNode}
      ref="autocomplete"
      autoHighlight={false}
    />;
  }
}

CustomAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  suggestions: PropTypes.array.isRequired,
  inputProps: PropTypes.object,
};

CustomAutocomplete.defaultProps = {
  value: '',
  isValid: true,
  inputProps: {},
};
