import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ExpandFormRow from 'shared/expandFormRow';
import RadioGroup from 'shared/radioGroup';
import CustomCheckboxList from 'shared/checkboxList';

import './styles/attributesSet.scss';

class AttributesSet extends Component {
  constructor(props) {
    super(props);
    this.onAttributeChange = this.onAttributeChange.bind(this);
    this.onAggregationChange = this.onAggregationChange.bind(this);
  }

  onAttributeChange(e) {
    let attributes = Object.assign([], this.props.value.attributes),
        value = e.target.value,
        index = attributes.indexOf(value);

    if (e.target.checked) {
      if (index === -1) {
        attributes.push(value);
      }
    } else {
      attributes.splice(index, 1);
    }

    this.props.onChange({
      attributes,
    });
  }

  onAggregationChange(e) {
    let value = e.currentTarget.value,
        attributes = this.props.value.attributes;

    if (!value || value === 'none') {
      attributes = this.props.value._default;
    } else if (value === 'unset' || value === 'all') {
      attributes = [];
    }

    this.props.onChange({
      aggregation: value,
      attributes,
    });
  }

  getOptionsList(value) {
    let params = this.props.attributes.map((attr) => ({
        cssClasses: attr.hasIcon ? 'rf-checkbox_with-icon' : '',
        label: attr.label,
        onChange: this.onAttributeChange,
        disabled: value.aggregation === 'unset',
        value: attr.value,
        isChecked: _.get(value, 'attributes', []).indexOf(attr.value) !== -1,
      })
    );

    return (
      <CustomCheckboxList params={params} />
    );
  }

  render() {
    let { label, value, attributeAggregation } = this.props;

    return (
      <div className="rf-attributes-set">
        <ExpandFormRow
          label={label}
        >
          <div className="rf-clearfix">
            <RadioGroup
              options={attributeAggregation}
              value={value.aggregation}
              onChange={this.onAggregationChange}
            />
          </div>
          <div>
            {this.getOptionsList(value)}
          </div>
        </ExpandFormRow>
      </div>
    );
  }
}

AttributesSet.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  attributes: PropTypes.array.isRequired,
  attributeAggregation: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AttributesSet;
