export default function isExtraValid(extra) {
  if (!extra) return true;

  let keyVal = extra.split('&'),
      isValid = true;

  for (let i = 0; i < keyVal.length; ++i) {
    let pair = keyVal[i].split('=');

    if (pair.length !== 2 || !pair[0].length || !pair[1].length) {
      isValid = false;
      break;
    }
  }

  return isValid;
}
