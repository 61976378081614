import utils from 'utils';

export const pointTemplate = {
  category: 'geo',
  type: 'stopOver',
  userLabel: '',
  transitRadius: '',
  isWaypoint: false,
  saveFormatting: true,
  stopOverDuration: {
    value: '',
    isValid: true,
  },
  heading: {
    value: '',
    isValid: true,
  },
  displayPosition: {
    value: '',
    isValid: true,
  },
  coords: {
    value: '',
    isValid: true,
  },
  link: {
    value: '',
    isValid: true,
  },
  street: {
    value: '',
    name: '',
    isValid: true,
  },
  streetSuggestions: {},

  power: '',
  supplyType: '',
  voltage: '',
  current: '',
  phases: '',
};

export function isStreetEmpty(street) {
  return !street || utils.isEqual(street, pointTemplate.street);
}
