import utils from 'utils';

export stringifyQuery from './stringify/stringifyQuery';
export parseUrlParams from './parser/parseUrlParams';
export getUpdatedParams from './getUpdatedParams';

export function stringifyUrl(params = { queryParams: '' }, settings = null) {
  if (!settings || Object.keys(settings).length < 2) {
    return { apiUrl: '' };
  }
  let protocol = settings.useSSL ? 'https://' : 'http://',
      url = `${protocol}${settings.host}${settings.path}`;

  return { apiUrl: `${url}?${params.queryParams}` };
}

export function objectToQueryString(obj) {
  if (!utils.isObject(obj)) {
    return obj;
  }
  let result = [];
  Object.keys(obj).forEach(key => {
    result.push(`${key}=${obj[key]}`);
  });
  return result.join('&');
}

export function urlToObject(apiUrl) {
  let url = apiUrl.split('?')[1] || '';
  let urlParts = url.split('&');
  let result = {};
  urlParts.forEach(part => {
    let [key, val = ''] = part.split('=');
    result[key] = val;
  });
  return result;
}

const urlParamsMapping = {
  corridor: 'corridor.points',
  corridorwidth: 'corridor.width',
  maxresults: 'maxResults',
  offset: 'offset',
  connectortype: 'connectorType',
  powerfeedtype: 'powerFeedType',
  sortkey: 'sortKey',
  sortorder: 'sortOrder',
  shape: 'shape',
};

export function transformToPost(apiUrl) {
  const urlObj = urlToObject(apiUrl);
  const { params, postData } = Object.keys(urlObj).reduce((red, key) => {
    if (urlParamsMapping[key]) {
      _.set(red.postData, urlParamsMapping[key], urlObj[key]);
    } else {
      const urlParam = `${key}=${urlObj[key]}`;
      red.params = red.params ? `${red.params}&${urlParam}` : urlParam;
    }
    return red;
  }, { params: null, postData: {} });
  return { url: `${apiUrl.split('?')[0]}?${params}`, postData };
}

export function genUrl4Dnd(data) {
  let { coords, apiUrl, regExp } = data;

  let matched = apiUrl.match(regExp);
  let [oldCategory, oldType, ...extraParams] = matched ? (matched[1] || '').split('!') : [];
  let oldCoords = extraParams.slice(-1)[0] || null;
  if (oldCoords && oldCategory && oldType && coords) {
    let pointCoords = matched[0].replace(matched[1], `geo!${oldType}!${coords.lat},${coords.lng}`);
    return apiUrl.replace(matched[0], pointCoords);
  }
  return null;
}

export function parseUrlSettings(url) {
  const url_ = url.split('?')[0];
  const urlParts = url_.split('/');
  const protocol = urlParts[0];
  const host = urlParts[2];
  const pathStart = 3;
  const pathEnd = urlParts.length;
  let path = pathStart >= pathEnd ? '' : `/${urlParts.slice(pathStart, pathEnd).join('/').replace(/\/+$/g, '')}`;
  return {
    useSSL: protocol === 'https:',
    host,
    path,
  };
}

export function getHostName(url) {
  let match = url.match(/:\/\/([a-z.\-0-9]*:[0-9]+)|:\/\/([a-z.\-0-9]*)/i);
  if (match != null && match.length >= 2) {
    return match[1] || match[2];
  }
  return null;
}
