import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RadiobuttonList from '../../common/RadiobuttonList';
import ResultRouteLegendTable from './ResultRouteLegendTable';

import utils from 'utils';

class ResultRouteLegend extends Component {
  onMouseOver(id) {
    if (id !== this.props.currentRoute) {
      this.props.onMouseOver(id);
    }
  }

  onMouseOut(id) {
    if (id !== this.props.currentRoute) {
      this.props.onMouseOut(id);
    }
  }

  getTable() {
    return (<ResultRouteLegendTable {...this.props} />);
  }

  getColor(isActive, isHighlighted) {
    if (isActive) {
      return this.props.selectedTabData.tabColorPalette.primary;
    } else if (isHighlighted) {
      return this.props.selectedTabData.tabColorPalette.secondaryDarker;
    }
    return this.props.selectedTabData.tabColorPalette.secondary;
  }

  getList() {
    let { setRoute, currentRoute, responseData, highlightedRoute } = this.props;
    return (
      <RadiobuttonList
        onMouseOver={::this.onMouseOver}
        onMouseOut={::this.onMouseOut}
        data={responseData}
        onChange={setRoute}
        getColor={::this.getColor}
        currentIndex={currentRoute}
        highlightedIndex={highlightedRoute}
        itemLabel="Route"
      />
    );
  }

  render() {
    let publicTransportLine = utils.getObject(this.props, 'responseData[0].publicTransportLine');
    let legendEl = publicTransportLine !== undefined ? this.getTable() : this.getList();
    return (
      <div>
        <h4>Alternative routes:</h4>
        {legendEl}
      </div>
    );
  }
}

ResultRouteLegend.defaultProps = {
  onMouseOver: () => {},
  onMouseOut: () => {},
};

ResultRouteLegend.propTypes = {
  responseData: PropTypes.array.isRequired,
  currentRoute: PropTypes.number.isRequired,
  highlightedRoute: PropTypes.number.isRequired,
  setRoute: PropTypes.func.isRequired,
  selectedTabData: PropTypes.object.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
};

export default ResultRouteLegend;
