import React from 'react';
import PropTypes from 'prop-types';

import ExpandFormRow from 'shared/expandFormRow';
import FieldSet from 'shared/fieldset';
import CustomTabs from 'shared/tabs';

import ResultPanelFormBase from '../../_ResultPanelFormBase';

import responseDataShape from 'state/tabs/tab/response/matrix/responseDataShape';

import utils from 'utils';
import format from 'utils/format';

import InfoFields from '../routing/InfoFields';
import FormRow from 'shared/formRow';
import CustomButton from 'shared/button';
import CustomCopyButton from 'shared/copyButton';
import './styles/matrixResultPanel.scss';
import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';

class ResultPanelForm extends ResultPanelFormBase {
  openGetRouteTab = (routeId) => {
    const { addTab, filterValueMultiUpdate, formData: { fields } } = this.props;

    addTab({ module: modulesEnum.ROUTING, form: formsEnum.GET_ROUTE_INFO });

    filterValueMultiUpdate(formsEnum.GET_ROUTE_INFO, {
      mode: fields.mode,
      routeid: routeId,
      traffic: fields.mode.traffic,
    }, true);
  }

  getRouteInfo(item, route) {
    let { entry = {} } = this.props.selectedEntity || {};
    if (route && entry.startIndex === item.startIndex && entry.destinationIndex === item.destinationIndex) {
      return (
        <FieldSet legend="Route">
          <InfoFields route={route} />
        </FieldSet>
      );
    }

    return '';
  }

  prepareItemContent(item, route) {
    let
      { summary = {} } = item,
      routeInfoEl = this.getRouteInfo(item, route),
      fieldsBase = [
        { label: 'Status', key: 'status', ctx: item },
      ],
      fieldsSummary = [
        { label: 'Travel time', key: 'travelTime', ctx: summary, fn: (v) => format.time(v) },
        { label: 'Flags', key: 'flags', ctx: summary },
        { label: 'Distance', key: 'distance', ctx: summary, fn: (v) => format.distance(v, 'km+mi') },
        { label: 'Text', key: 'text', ctx: summary },
        { label: 'Co2Emission', key: 'co2Emission', ctx: summary },
        { label: 'Cost Factor', key: 'costFactor', ctx: summary },
      ];
    return (
      <div className="rf-matrix-result">
        {fieldsBase.map(this.getInputEl)}
        {fieldsSummary.map(this.getInputEl)}
        <FormRow isHidden={!summary.routeId} cssClasses="rf-matrix-result__route-id">
          <label>Route ID:</label>
          <div>{summary.routeId}</div>
        </FormRow>
        <FormRow isHidden={!summary.routeId} cssClasses="rf-matrix-result__route-id-btns">
          <CustomButton
            title="Open Get route tab"
            onClick={this.openGetRouteTab.bind(this, summary.routeId)}
          />
          <CustomCopyButton
            title="Copy route ID"
            value={summary.routeId}
          />
        </FormRow>
        {routeInfoEl}
      </div>
    );
  }

  prepareGroupContent(item, route) {
    return item.children.map((child, index) => (
        <FieldSet legend={this._getEntryIdentity(child)} key={`ResultPanelFormMatrixEntryGroup${index}`}>
          {this.prepareItemContent(child, route)}
        </FieldSet>
    ), this);
  }

  hasSelectedMarker() {
    return !!utils.getObject(this.props.selectedEntity, 'marker');
  }

  regroup(data = []) {
    let { marker } = this.props.selectedEntity || {},
        result = [],
        isGroupFilled = false;
    if (!marker) {
      return data;
    }

    data.forEach(item => {
      if (item.startIndex === marker.startIndex || item.destinationIndex === marker.destinationIndex) {
        if (!isGroupFilled) {
          isGroupFilled = true;
          result.unshift({ key: marker.key, index: marker.index, children: [] });
        }

        result[0].children.push(item);
      } else {
        result.push(item);
      }
    });
    return result;
  }

  _getMarkerIdentity(data) {
    return {
      startIndex: 'S',
      destinationIndex: 'D',
    }[data.key] + data.index;
  }

  _getEntryIdentity(data) {
    return `S${data.startIndex}-D${data.destinationIndex}`;
  }

  _getEntityIdentity(props = this.props) {
    let { entry, marker } = props.selectedEntity || {};
    if (entry) {
      return this._getEntryIdentity(entry);
    }

    if (marker) {
      return this._getMarkerIdentity(marker);
    }

    return null;
  }

  getMatrixEntryTabContent(data = [], routes = []) {
    let entityIdentity = this._getEntityIdentity();
    return this.regroup(data).map((item, index) => {
      let method = item.children ? 'prepareGroupContent' : 'prepareItemContent',
          identity = item.children ? this._getMarkerIdentity(item) : this._getEntryIdentity(item);
      return (
        <ExpandFormRow
          label={`Matrix Entry ${identity}`}
          key={`ResultPanelFormMatrixEntry${index}`}
          isExpanded={identity === entityIdentity}
          toggleProgrammatically
        >
          <div className="rf-result-panel__tabs-gap">
            {this[method](item, routes[0])}
          </div>
        </ExpandFormRow>
      );
    }, this);
  }

  getContent() {
    let { responseData = {} } = this.props,
        { response = {} } = responseData.data,
        { matrixEntry = [], route = [] } = response,
        tabs = [
          { title: 'Matrix Entry', content: this.getMatrixEntryTabContent(matrixEntry, route) },
          { title: 'Response', content: this.getResponseTabContent(response) },
        ];
    if (matrixEntry.length === 0) {
      return '';
    }
    return (
      <CustomTabs cssClasses="rf-result-panel__tabs" data={tabs} />
    );
  }

  render() {
    return (
      <div>
        {this.getContent()}
        {this.getInspectLinkData()}
        {this.getSearchResults()}
      </div>
    );
  }
}

ResultPanelForm.propTypes = {
  responseData: responseDataShape,
  onShowFormattedResult: PropTypes.func.isRequired,
  onRemoveInspectLink: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  selectedEntity: PropTypes.object,
  formData: PropTypes.object.isRequired,
};

export default ResultPanelForm;
