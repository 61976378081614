import utils from 'utils';

export default class ModuleBase {
  constructor(map, formState = {}) {
    this.map = map;
    this.formState = formState;
    this.groups = {};
    this.addGroup();
  }

  get hasDefaultGroup() {
    return true;
  }

  setData(data = {}) {
    Object.assign(this, data);
  }

  addGroup(key = '__main', options = {}) {
    let { index = 0, tabsCount = 0 } = this.formState;
    let zIndex = 5 + (tabsCount - index);
    this.groups[key] = new H.map.Group({ zIndex, ...options });
    this.map.addObject(this.groups[key]);
    return this.groups[key];
  }

  getGroup(key = '__main') {
    return this.groups[key];
  }

  clearGroup(key = '__main') {
    this.groups[key].removeAll();
  }

  clearGroups() {
    utils.iterObject(this.groups || {}, (group => {
      group.removeAll();
    }));
  }

  destroy() {
    this.clearGroups();
    this.groups = null;
    this.map = null;
  }

  // Main method for communication with outside
  process() {}
}
