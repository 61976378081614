import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomSelect from 'shared/select';
import CustomDateTime from 'shared/datetime';
import FormRow from 'shared/formRow';

import utilsDate from 'utils/date';

import './styles/dateTimeField.scss';

class DateTimeField extends Component {
  constructor(props) {
    super(props);
    this.onDateChange = this.onDateChange.bind(this);
    this.onTimezoneChange = this.onTimezoneChange.bind(this);
  }

  onTimezoneChange(e) {
    this.props.onChange(this.props.date, e.target.value);
  }

  onDateChange(date) {
    this.props.onChange(date, this.props.timezone);
  }

  render() {
    let { timezone, date } = this.props,
      timeZoneOptions = [{ key: '', label: 'local', value: '' }].concat(utilsDate.generateTimezoneList());

    return (
      <div className="rf-datetime-field">
        <FormRow>
          <CustomDateTime
            value={date}
            onChange={this.onDateChange.bind(this)}
          />
        </FormRow>
        <FormRow>
          <div className="rf-datetime-field__timezone">
            <CustomSelect
              label="Time Zone"
              value={timezone}
              options={timeZoneOptions}
              onChange={this.onTimezoneChange.bind(this)}
            />
          </div>
        </FormRow>
      </div>
    );
  }
}

DateTimeField.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateTimeField;
