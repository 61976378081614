import Immutable from 'immutable';

import * as types from './constants';

export function update(data) {
  return { type: types.UPDATE, data };
}

export default function isolineWaypoints(state, action) {
  let { data } = action;

  switch (action.type) {
    case types.UPDATE: {
      return Immutable.fromJS(state).update('value', () => data.value).toJS();
    }

    default:
      return state;
  }
}
