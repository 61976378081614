import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';

import './styles/closeButton.scss';

class CloseButton extends CustomComponent {
  render() {
    return <i
      className="rf-close-button rf-webfont-icon rf-webfont-icon_close"
      onClick={this.props.onClick}
      style={this.props.style}
    />;
  }
}

CloseButton.defaultProps = {
  style: {},
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
};

export default CloseButton;
