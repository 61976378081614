import PropTypes from 'prop-types';

let pointShape = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
});

let waypointShape = PropTypes.shape({
  label: PropTypes.string,
  linkId: PropTypes.string,
  mappedPosition: pointShape,
  mappedRoadName: PropTypes.string,
  originalPosition: pointShape,
  shapeIndex: PropTypes.number,
  sideOfStreet: PropTypes.string,
  spot: PropTypes.number,
  type: PropTypes.string,
});

let maneuverShape = PropTypes.shape({
  direction: PropTypes.string.isRequired,
  instruction: PropTypes.string.isRequired,
  firstManeuver: PropTypes.string.isRequired,
  lastManeuver: PropTypes.string.isRequired,
});

let legShape = PropTypes.shape({
  maneuver: PropTypes.arrayOf(maneuverShape),
});

let maneuverGroupShape = PropTypes.shape({
  arrivalDescription: PropTypes.string.isRequired,
  summaryDescription: PropTypes.string.isRequired,
});

let routeShape = PropTypes.shape({
  leg: PropTypes.arrayOf(legShape),
  maneuverGroup: PropTypes.arrayOf(maneuverGroupShape),
  mode: PropTypes.object,
  shape: PropTypes.arrayOf(PropTypes.number),
  summary: PropTypes.object,
  waypoint: PropTypes.arrayOf(waypointShape),
});

let dataShape = PropTypes.shape({
  language: PropTypes.string,
  metaInfo: PropTypes.object,
  route: PropTypes.arrayOf(routeShape),
});

let routeWrapperShape = PropTypes.shape({
  route: PropTypes.arrayOf(dataShape),
});

const responseDataShape = PropTypes.shape({
  isError: PropTypes.bool,
  data: PropTypes.shape({
    response: routeWrapperShape,
  }),
});

export default responseDataShape;
