import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FormattedResultBtn extends Component {
  render() {
    return (
      <lui-default-theme>
        <div className="rf-result-panel__centered">
          <lui-button secondary onClick={this.props.onClick}>{this.props.label}</lui-button>
        </div>
      </lui-default-theme>
    );
  }
}

FormattedResultBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};

FormattedResultBtn.defaultProps = {
  label: 'Show full response',
};

export default FormattedResultBtn;
