import * as types from './resultPanelConstants';

function setResultPanelState(state = false, action) {
  switch (action.type) {
    case types.TOGGLE_RESULT_PANEL:
      return action.flag === undefined ? state : action.flag;

    default:
      return state;
  }
}

export default setResultPanelState;
