import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModeFieldSet from '../common/ModeFieldSet';
import SpeedProfile from '../common/SpeedProfile';
import RouteDateField from '../common/RouteDateField';
import TruckProfileBlock from '../common/TruckProfileBlock';
import JsonAttributesBlock from '../common/JsonAttributesBlock';
import ConsumptionBlock from '../common/Consumption';
import IconsBar from '../common/IconsBar';

import WaypointsGroup from './isolineForm/WaypointsGroup';
import RangeField from './isolineForm/RangeField';

import FormRow from 'shared/formRow';
import ExpandFormRow from 'shared/expandFormRow';
import CustomInput from 'shared/input';
import CustomSelect from 'shared/select';
import Columns from 'shared/columns';

import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as WaypointsActions from 'state/tabs/tab/formData/common/waypoints/actions';
import * as NotificationActions from 'state/notification/actions';

import utils from 'utils';
import settings from 'utils/settings';
import getConfig from 'config/form';
import formsEnum from 'config/formsEnum';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';

export class IsolineForm extends Component {
  onChange(key, e) {
    this.props.filterValueUpdate({ key, currentForm: formsEnum.CALCULATE_ISOLINE, value: utils.extractData(e) });
  }

  onGetStreetAddress(index, coords) {
    let { module, currentForm, settingsData, getStreetAddress } = this.props;
    let settingsPreset = settings.getPresetSettings(module, currentForm, settingsData);
    getStreetAddress({
      settings: settingsPreset,
      currentForm,
      index,
      coords,
    });
  }

  onGetLinkId(index, coords) {
    let { getLinkId, currentForm, settingsData, module } = this.props;
    let settingsPreset = settings.getPresetSettings(module, currentForm, settingsData);
    getLinkId(coords, currentForm, settingsPreset, index);
  }

  onConsumptionChange(key, e) {
    if (key === 'consumptionmodel') {
      this.onChange(key, e);
      return;
    }
    this.onChange(`customconsumptiondetails`, { [key]: utils.extractData(e) });
  }

  onRouteDateChange = (data) => {
    const routeDate = { ...data };
    if (data.key === 'now') {
      routeDate.key = 'departure';
      routeDate.value = 'now';
    }
    this.onChange('routeDate', routeDate);
  }

  getTimeValue = (routeDate) => {
    if (routeDate.key === 'now') {
      return 'departure: now';
    } else if (routeDate.key === '') {
      return null;
    }
    return `${routeDate.key}: ${routeDate.value}`;
  }

  render() {
    let { fields = {}, waypoints = [] } = this.props.formData;
    let currentForm = formsEnum.CALCULATE_ISOLINE;
    let hideConsumptionBlock = fields.rangetype !== 'consumption';
    let {
      routeDate: fieldsRouteDate,
      mode = {},
      range,
      rangetype,
      quality = {},
      singlecomponent,
      resolution = {},
      maxpoints = {},
      extraparams = {},
      jsonattributes,
      speedprofile,
    } = fields;
    let isTruckTransportMode = mode.transport === 'truck';
    let formConfig = getConfig(currentForm);
    const routeDate = { value: fieldsRouteDate.value };
    routeDate.key = fieldsRouteDate.key;
    if (fieldsRouteDate.value === 'now') {
      routeDate.key = 'now';
    }
    return (
      <div>
        <IconsBar
          items={[
            { name: 'start-isoline', title: 'Start/Destination' },
            { name: 'range-isoline', title: 'Range' },
            { name: 'time', title: 'Route Time' },
            { name: 'mode', title: 'Mode' },
            { name: 'advanced', title: 'Optional Fields' },
            { name: 'json', title: 'Json Attributes' },
          ]}
          container={this}
        />
        <WaypointsGroup
          currentForm={currentForm}
          waypoints={waypoints}
          formConfig={formConfig}
          onChange={this.props.waypointUpdate}
          onGetStreetAddress={::this.onGetStreetAddress}
          onGetLinkId={::this.onGetLinkId}
          setNotification={this.props.setNotification}
          id="start-isoline"
        />
        <hr />
        <RangeField
          label="Range"
          type={rangetype}
          range={range}
          formConfig={formConfig}
          onChange={this.onChange.bind(this)}
        />
        <hr style={{ margin: '25px 0 30px 0' }} />
        <FormRow id="time">
          <RouteDateField
            label="Route Time"
            value={this.getTimeValue(routeDate)}
            formConfig={formConfig}
            routeDate={routeDate}
            onChange={this.onRouteDateChange}
          />
        </FormRow>
        <ModeFieldSet
          label="Mode"
          value={mode}
          formConfig={formConfig}
          onChange={::this.onChange}
        />
        <SpeedProfile
          formConfig={formConfig}
          value={speedprofile}
          onChange={this.onChange.bind(this, 'speedprofile')}
          isHidden={!isTruckTransportMode}
        />
        <TruckProfileBlock
          label="Truck Profile"
          fields={fields}
          formConfig={formConfig}
          isHidden={!isTruckTransportMode}
          setNotification={this.props.setNotification}
          onChange={this.onChange.bind(this)}
        />
        <ConsumptionBlock
          fields={fields}
          onChange={::this.onChange}
          label="Consumption"
          formConfig={formConfig}
          isHidden={hideConsumptionBlock}
          filterValueMultiUpdate={this.props.filterValueMultiUpdate}
          currentForm={this.props.currentForm}
          setNotification={this.props.setNotification}
        />
        <ExpandFormRow label="Optional Fields" cssClasses="rf-grey-box" id="advanced" isExpanded>
          <Columns>
            <FormRow>
              <CustomInput
                label="Quality"
                type="number"
                min="1"
                max="3"
                value={quality.value}
                isValid={quality.isValid}
                onBlur={this.onChange.bind(this, 'quality')}
                blurOnEnter
              />
            </FormRow>
            <FormRow>
              <CustomSelect
                label="Single Component"
                options={formConfig.bool}
                value={singlecomponent}
                addEmptyOption
                onChange={this.onChange.bind(this, 'singlecomponent')}
              />
            </FormRow>
            <FormRow>
              <CustomInput
                label="Resolution [m/px]"
                type="number"
                min="0"
                value={resolution.value}
                isValid={resolution.isValid}
                onBlur={this.onChange.bind(this, 'resolution')}
                blurOnEnter
              />
            </FormRow>
            <FormRow>
              <CustomInput
                label="Max Points"
                type="number"
                min="0"
                value={maxpoints.value}
                isValid={maxpoints.isValid}
                onBlur={this.onChange.bind(this, 'maxpoints')}
                blurOnEnter
              />
            </FormRow>
          </Columns>
          <FormRow>
            <CustomInput
              type="text"
              label="Extra params"
              placeholder="Enter parameners"
              isValid={extraparams.isValid}
              value={extraparams.value}
              onBlur={this.onChange.bind(this, 'extraparams')}
              blurOnEnter
            />
          </FormRow>
        </ExpandFormRow>
        <JsonAttributesBlock
          cssClasses="rf-grey-box"
          jsonAttributes={jsonattributes}
          onChange={::this.onChange}
          attributes={formConfig.jsonAttributes}
        />
      </div>
    );
  }
}

IsolineForm.propTypes = {
  formData: PropTypes.object.isRequired,
  settingsData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  waypointUpdate: PropTypes.func.isRequired,
  getStreetAddress: PropTypes.func.isRequired,
  getLinkId: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    formData: getCurrentFormData(state),
    settingsData: getSettingsData(state),
    currentForm: getForm(state),
    module: getModule(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
    waypointUpdate: bindActionCreators(WaypointsActions.update, dispatch),
    getStreetAddress: bindActionCreators(WaypointsActions.getStreetAddress, dispatch),
    getLinkId: bindActionCreators(WaypointsActions.getLinkId, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IsolineForm);
