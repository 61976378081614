import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ToggleButton from 'shared/toggleButton';

export default class MapFormToggler extends Component {
  constructor(...args) {
    super(...args);
    this.checkBlur = ::this.checkBlur;
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.checkBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.checkBlur);
  }

  checkBlur(e) {
    let { getFormRef, onBlur } = this.props;
    if (!getFormRef().contains(e.target)) {
      onBlur(e);
    }
  }

  render() {
    let { isActive, onToggle, renderToggler } = this.props;
    if (!renderToggler) {
      return null;
    }
    return <div className="rf-map__tooltip__toggler">
      <ToggleButton defaultChecked={isActive} onChange={onToggle} />
    </div>;
  }
}

MapFormToggler.defaultProps = {
  renderToggler: true,
  onToggle: () => {},
  isActive: false,
};

MapFormToggler.propTypes = {
  onToggle: PropTypes.func,
  onBlur: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  getFormRef: PropTypes.func.isRequired,
  renderToggler: PropTypes.bool,
};
