export const INIT_ZOOM = 12;
export const AVOID_AREA_DEFAULT_SIZE = 100;
export const MAP_TRUCK_LAYER_PARAMS = ['truckonlytile', 'normal.day', 256, 'png8'];
export const MAP_CREDENTIALS = {
  app_id: 'DemoAppId01082013GAL',
  app_code: 'AJKnXv84fjrb0KIHawS0Tg',
};

export const JAM_COLORS = {
  fast: '#84CA50',
  normal: '#F07D02',
  slow: '#E60000',
  dead: '#9E1313',
};

export const CONSUMPTION_COLORS = {
  hasNoEnergyColor: '#E60000',
  lowEnergyColor: '#F07D02',
};

export function addOpacityToHexColor(hex, opacity = 1) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ?
    `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${opacity})` : null;
}

export function getAvoidAreaColor(opacity = 1) {
  return `rgba(255, 147, 135, ${opacity})`;
}

export function getSelectionAreaColor(opacity = 1) {
  return `rgba(0, 0, 0, ${opacity})`;
}

export function getSearchResultsColor(opacity = 1) {
  return `rgba(86, 153, 86, ${opacity})`;
}

export function getLinkInfoRouteColor() {
  return '#7777DD';
}

const MEDIUM_TRAFFIC_COLOR = '#f07d02';

export function getColorByJF(jf) {
  // JF means jam factor
  if (jf < 4) {
    return 'green';
  } else if (jf < 8) {
    return MEDIUM_TRAFFIC_COLOR;
  } else if (jf < 10) {
    return 'red';
  }
  return 'black';
}

export function getColorBySpeed(speed) {
  if (speed === 0) {
    return 'black';
  } else if (speed > 0 && speed <= 10) {
    return 'red';
  } else if (speed > 10 && speed <= 30) {
    return MEDIUM_TRAFFIC_COLOR;
  }
  return 'green';
}

export const ZINDEX = {
  POLYGON: 100,
  HOVERED_POLYLINE: 101,
  POLYLINE: 102,
  DIRECTION: 105,
  ROUTE: 110,
  AVOID_AREA: 115,
  SELECTION_AREA: 116,
  ACTIVE_ROUTE: 120,
  SELECTED_POINT: 125,
  MARKER: 130,
};

export const DEFAULT_PT_COLOR = '#999';

const initCoordsLocalStorageKey = 'initCoords.v1';
const INIT_COORDS = { lat: 52.5159, lng: 13.3777 };


export const setInitCoords = (coords) => {
  localStorage.setItem(initCoordsLocalStorageKey, coords);
};

export const getInitCoords = () => {
  const initCoords = localStorage.getItem(initCoordsLocalStorageKey);
  if (initCoords) {
    const [lat, lng] = initCoords.split(',');
    return { lat, lng };
  }

  return INIT_COORDS;
};
