import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormRow from 'shared/formRow';
import CustomTextarea from 'shared/textarea';

import PointField from './PointField';

import pointSplit from 'utils/matrix/pointSplit';

import './styles/pointsFieldSet.scss';

class PointsFieldSet extends Component {

  onUpdateAll(e) {
    let value;
    if (e.target.value === '') {
      value = [];
    } else {
      value = e.target.value.split(';').map(pointSplit);
    }
    this.props.onUpdateAll(value);
  }

  getInputEl(data, index) {
    let { subLabel, onUpdate, onRemove } = this.props;
    return (
      <FormRow key={`PointFieldRow${index}`}>
        <PointField
          label={`${subLabel}${index}`}
          data={data}
          onUpdate={onUpdate.bind(this, index)}
          onRemove={onRemove.bind(this, index)}
        />
      </FormRow>
    );
  }

  render() {
    let { label, data } = this.props,
        itemsEl = data.map(::this.getInputEl),
        value = data.map(item => `${item.category}${item.coords.value}`).join(';');
    return (
      <div className="rf-points-fieldset" id="waypoints">
        <h3>{label}</h3>
        {itemsEl}
        <CustomTextarea
          label="Coordinates"
          cssClasses="rf-points-fieldset__coords"
          value={value}
          onBlur={this.onUpdateAll.bind(this)}
        />
        <hr />
      </div>
    );
  }
}

PointsFieldSet.defaultProps = {
  data: [],
};

PointsFieldSet.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateAll: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PointsFieldSet;
