import isNumericSequence from './numericsValidation';

function validate(values) {
  if (!values) {
    return false;
  }
  if (values.split(',').length % 2 === 1) {
    return false;
  }
  return isNumericSequence(values);
}

export default validate;
