export default {
  routeTimeModes: [
    { label: 'Any', value: '' },
    { label: 'Departure', value: 'departure' },
  ],
  routeTransports: [
    { label: 'Car', value: 'car' },
    { label: 'Car HOV/Carpool', value: 'carHOV' },
    { label: 'Truck', value: 'truck' },
    { label: 'Bicycle BETA', value: 'bicycle' },
    { label: 'Pedestrian', value: 'pedestrian' },
  ],
  matrixAttributes: [
    { label: 'Indices', value: 'ix', enumeration: 'indices' },
    { label: 'Summary', value: 'su', enumeration: 'summary' },
  ],
  summaryAttributes: [
    { label: 'Travel Time', value: 'tt', enumeration: 'traveltime' },
    { label: 'Cost Factor', value: 'cf', enumeration: 'сostаactor' },
    { label: 'Distance', value: 'di', enumeration: 'distance' },
    { label: 'Route ID', value: 'ri', enumeration: 'routeid' },
  ],
};
