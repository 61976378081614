import { TOGGLE_NEW_TAB } from '../isNewTabShown/constants';

const NS = 'ui';
const SET_LEFT_PANEL = `${NS}/SET_LEFT_PANEL`;

const initState = {
  isLeftPanelShown: true,
};

export default function ui(state = initState, action) {
  switch (action.type) {
    case SET_LEFT_PANEL:
      return {
        ...state,
        isLeftPanelShown: action.payload,
      };
    case TOGGLE_NEW_TAB:
      return {
        ...state,
        isLeftPanelShown: action.isShown ? true : state.isLeftPanelShown,
      };
    default:
      return state;
  }
}

export function setIsLeftPanelShown(isShown) {
  return { type: SET_LEFT_PANEL, payload: isShown };
}
