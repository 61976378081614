import utils from 'utils';
import modulesEnum from 'config/modulesEnum';

import RoutingValidation from './routing';
////////////////
//////////////////////////////////////////
//////////////////////////////////////////////
////////////////////////////////////////
//////////
import TransitValidation from './transit';

const URL_VALIDATION = {
  [modulesEnum.ROUTING]: RoutingValidation,
//////////////////
/////////////////////////////////////////
///////////////////////////////////////////
////////////////////////////////////////////////
////////////
  [modulesEnum.TRANSIT]: TransitValidation,
};

export default function validate(url, module, currentForm) {
  let [path, params] = url.split('?');
  if (!path || !params) {
    return [{
      key: 'API URL',
      value: url,
      errors: ['Url could not be parsed'],
    }];
  }

  let keyVals = params.split('&').map(param => param.split('='));
  let result = [];
  keyVals.forEach(keyVal => {
    let [key, value] = keyVal;
    let errors = validateKeyVal(keyVal, module, currentForm);
    if (!utils.isEmpty(errors)) {
      result.push({
        key,
        value,
        errors: validateKeyVal(keyVal, module, currentForm),
      });
    }
  });

  return result;
}

export function validateKeyVal(keyVal, module, form) {
  let [key, value] = keyVal;
  if (utils.isEmpty(value)) {
    return ['empty value will be removed from request'];
  }

  let keyNoIndex = key.replace(/\d+$/, '');
  let validation = utils.getObject(URL_VALIDATION, `${module}.${form}.${keyNoIndex}`) ||
    utils.getObject(URL_VALIDATION, `${module}.${form}.${key}`);
  if (validation) {
    return validation(value);
  }

  return [];
}
