import Routing from './routing';
import Matrix from './matrix';
import Isoline from './isoline';
import formsEnum from 'config/formsEnum';
import modulesEnum from 'config/modulesEnum';
import GetRoute from './getRoute';
import utils from 'utils';
import OlsAPI from './olsApi';
import Intermodal from './transitTab/intermodal';
import electricPresets from './electricPresets';

let base = {
  modules: [
    modulesEnum.ROUTING,
////////////////////
////////////////////////////////////
///////////////////////
///////////////////////////////
////////////////////////
//////////////////////////////
////////////////////
//////////////
    modulesEnum.TRANSIT,
    modulesEnum.OFFLINE_TOOLS,
  ],
  [modulesEnum.ROUTING]: {
    label: 'Routing',
    enabled: true,
    forms: [
      formsEnum.OLS,
      formsEnum.CALCULATE_ROUTE,
      formsEnum.CALCULATE_MATRIX,
      formsEnum.CALCULATE_ISOLINE,
      formsEnum.GET_ROUTE_INFO,
//////////////////////
////////////////////////////////////////
///////////////////////////////
////////////////
    ],
    formTemplates: {
      [formsEnum.CALCULATE_ROUTE]: { label: 'Calculate Route', shortLabel: 'Route' },
      [formsEnum.CALCULATE_MATRIX]: { label: 'Calculate Matrix', shortLabel: 'Matrix' },
      [formsEnum.CALCULATE_ISOLINE]: { label: 'Calculate Isoline', shortLabel: 'Isoline' },
      [formsEnum.GET_ROUTE_INFO]: { label: 'Get Route', shortLabel: 'Get Route' },
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
////////////////
      [formsEnum.OLS]: { label: 'OLS API', shortLabel: 'OLS' },
    },
  },
//////////////////
//////////////////////////////////////
//////////////////////////////////
//////////////////////////////////////
////////////
/////////////////////////
/////////////////////////
//////
////////////////////
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
//////
////
/////////////////////////
////////////////////////
//////////////////////////////////////
////////////
///////////////////////
//////
////////////////////
////////////////////////////////////////////////////////////////////
//////
////
//////////////////////////////
/////////////////////////////
//////////////////////////////////////
////////////
////////////////////////////
//////
////////////////////
///////////////////////////////////////////////////////////////////////////////////////
//////
////
//////////////////////////
/////////////////////
//////////////////////////////////////
////////////
//////////////////////////////
//////////////////////////
//////
////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////
////
/////////////////////////////
////////////////////////
//////////////////////////////////////
////////////
//////////////////////////
//////
////////////////////
//////////////////////////////////////////////////////////////////////////////
//////
////
//////////////////////
/////////////////
//////////////////////////////////////
////////////
////////////////////
//////
////////////////////
///////////////////////////////////////////////////////////
//////
////
////////////
  [modulesEnum.OFFLINE_TOOLS]: {
    label: 'Offline tools',
    enabled: true,
    forms: [
      formsEnum.VISUALIZE_DATA_POINTS,
    ],
    formTemplates: {
      [formsEnum.VISUALIZE_DATA_POINTS]: { label: 'Visualize Data Points', shortLabel: 'Data Points' },
    },
  },
  [modulesEnum.TRANSIT]: {
    label: 'Transit',
    enabled: true,
    forms: [
      formsEnum.INTERMODAL,
    ],
    formTemplates: {
      [formsEnum.INTERMODAL]: { label: 'Intermodal Routing', shortLabel: 'Intermodal' },
    },
  },
  routeTimeModes: [
    { label: 'Now', value: 'now' },
    { label: 'Any', value: '' },
    { label: 'Departure', value: 'departure' },
    { label: 'Arrival', value: 'arrival' },
  ],
  routeTypes: ['fastest', 'shortest', 'balanced'],
  routeWaypointCategories: ['geo', 'link', 'street'],
  routeWaypointTypes: ['stopOver', 'passThrough'],
  routeTraffics: [
    { label: 'Not Set', value: '' },
    { label: 'Default', value: 'traffic:default' },
    { label: 'Enabled', value: 'traffic:enabled' },
    { label: 'Disabled', value: 'traffic:disabled' },
  ],
  speedProfiles: [
    { label: 'Not Set', value: '' },
    { label: 'Default', value: 'default' },
    { label: 'Fast', value: 'fast' },
  ],
  features: [
    { label: 'Tollroads', value: 'tollroad' },
    { label: 'Motorways', value: 'motorway' },
    { label: 'Boat Ferries', value: 'boatFerry' },
    { label: 'Rail Ferries', value: 'railFerry' },
    { label: 'Tunnels', value: 'tunnel' },
    { label: 'Dirt Roads', value: 'dirtRoad' },
    { label: 'Parks', value: 'park' },
  ],
  featureSet: [
    { label: 'Strict exclude', value: '-3' },
    { label: 'Soft exclude', value: '-2' },
    { label: 'Avoid', value: '-1' },
    { label: 'Default', value: '0' },
  ],
  attributeAggregation: [
    { label: 'Default', value: '', tooltip: 'Request the DEFAULT service attributes, PLUS the following' },
    { label: 'All Except', value: 'all', tooltip: 'Request ALL the attributes, EXCEPT the following' },
    { label: 'Not set', value: 'unset', tooltip: 'Parameter not specified in the request' },
    { label: 'None', value: 'none', tooltip: 'Request ONLY the following attributes' },
  ],
  routeAttributes: [
    { label: 'Waypoints', value: 'wp', enumeration: 'waypoints' },
    { label: 'Summary', value: 'sm', enumeration: 'summary' },
    { label: 'Summary by Country', value: 'sc', enumeration: 'summaryByCountry' },
    { label: 'Shape ', value: 'sh', hasIcon: true, enumeration: 'shape' },
    { label: 'Bounding box', value: 'bb', enumeration: 'boundingBox' },
    { label: 'Legs', value: 'lg', enumeration: 'legs' },
    { label: 'Notes', value: 'no', enumeration: 'notes' },
    { label: 'Lines', value: 'li', enumeration: 'lines' },
    { label: 'Tickets', value: 'tx', enumeration: 'tickets' },
    { label: 'Labels', value: 'la', enumeration: 'labels' },
    { label: 'Route ID', value: 'ri', enumeration: 'routeId' },
    { label: 'Maneuver Groups', value: 'gr', hasIcon: true, enumeration: 'groups' },
    { label: 'Incidents', value: 'ic', enumeration: 'incidents' },
    { label: 'Zones', value: 'zo', enumeration: 'zones' },
  ],
  legAttributes: [
    { label: 'Waypoint', value: 'wp', enumeration: 'waypoint' },
    { label: 'Maneuvers', value: 'mn', enumeration: 'maneuvers' },
    { label: 'Links ', value: 'li', enumeration: 'links' },
    { label: 'Length', value: 'le', enumeration: 'length' },
    { label: 'Travel time', value: 'tt', enumeration: 'travelTime' },
    { label: 'Shape', value: 'sh', enumeration: 'shape' },
    { label: 'Indices', value: 'ix', enumeration: 'indices' },
    { label: 'Bounding box', value: 'bb', enumeration: 'boundingBox' },
    { label: 'Base time', value: 'bt', enumeration: 'baseTime' },
    { label: 'Traffic time', value: 'tm', enumeration: 'trafficTime' },
    { label: 'Summary', value: 'sm', enumeration: 'summary' },
    { label: 'Battery Charge', value: 'bc', enumeration: 'batteryCharge' },
    { label: 'Staying Time', value: 'st', enumeration: 'stayingTime' },
    { label: 'Turn by turn maneuvers', value: 'mm', enumeration: 'turnByTurnManeuvers' },
  ],
  maneuverAttributes: [
    { label: 'Position', value: 'po', hasIcon: true, enumeration: 'position' },
    { label: 'Shape', value: 'sh', enumeration: 'shape' },
    { label: 'Travel time', value: 'tt', enumeration: 'travelTime' },
    { label: 'Length', value: 'le', enumeration: 'length' },
    { label: 'Time', value: 'ti', hasIcon: true, enumeration: 'time' },
    { label: 'Links ', value: 'li', enumeration: 'link' },
    { label: 'Public transp. line', value: 'pt', hasIcon: true, enumeration: 'publicTransportLine' },
    { label: 'Public transp. tickets', value: 'tx', enumeration: 'publicTransportTickets' },
    { label: 'Platforms', value: 'pl', enumeration: 'platform' },
    { label: 'Road name', value: 'rn', enumeration: 'roadName' },
    { label: 'Next road name', value: 'nr', enumeration: 'nextRoadName' },
    { label: 'Road number', value: 'ru', enumeration: 'roadNumber' },
    { label: 'Next road number', value: 'nu', enumeration: 'nextRoadNumber' },
    { label: 'Sign post', value: 'sp', enumeration: 'signPost' },
    { label: 'Notes', value: 'no', enumeration: 'notes' },
    { label: 'Action', value: 'ac', hasIcon: true, enumeration: 'action' },
    { label: 'Direction', value: 'di', hasIcon: true, enumeration: 'direction' },
    { label: 'Next maneuver', value: 'nm', hasIcon: true, enumeration: 'nextManeuver' },
    { label: 'Freeway exit', value: 'fe', enumeration: 'freewayExit' },
    { label: 'Freeway junction', value: 'fj', hasIcon: true, enumeration: 'freewayJunction' },
    { label: 'Base time', value: 'bt', enumeration: 'baseTime' },
    { label: 'Traffic time', value: 'tm', enumeration: 'trafficTime' },
    { label: 'Indices', value: 'ix', enumeration: 'indices' },
    { label: 'Bounding box', value: 'bb', enumeration: 'boundingBox' },
    { label: 'Road shield', value: 'rs', enumeration: 'roadShield' },
    { label: 'Start angle', value: 'sa', enumeration: 'startAngle' },
    { label: 'Wait time', value: 'wt', enumeration: 'waitTime' },
    { label: 'Shape Quality', value: 'sq', enumeration: 'shapeQuality' },
    { label: 'Public transp. delays', value: 'td', enumeration: 'publicTransportDelays' },
  ],
  linkAttributes: [
    { label: 'Shape', value: 'sh', enumeration: 'shape' },
    { label: 'Length', value: 'le', enumeration: 'length' },
    { label: 'Speed limit', value: 'sl', enumeration: 'speedLimit' },
    { label: 'Dynamic speed info', value: 'ds', enumeration: 'dynamicSpeedInfo' },
    { label: 'Truck restrictions', value: 'tr', enumeration: 'truckRestrictions' },
    { label: 'Flags ', value: 'fl', enumeration: 'flags' },
    { label: 'Road number', value: 'rn', enumeration: 'roadNumber' },
    { label: 'Road name', value: 'ro', enumeration: 'roadName' },
    { label: 'Timezone', value: 'tz', enumeration: 'timezone' },
    { label: 'Next link', value: 'nl', enumeration: 'nextLink' },
    { label: 'Public transport line', value: 'pt', enumeration: 'publicTransportLine' },
    { label: 'Remain time', value: 'rt', enumeration: 'remainTime' },
    { label: 'Remain distance', value: 'rd', enumeration: 'remainDistance' },
    { label: 'Maneuver', value: 'ma', enumeration: 'maneuver' },
    { label: 'Functional class', value: 'fc', enumeration: 'functionalClass' },
    { label: 'Next stop name', value: 'ns', enumeration: 'nextStopName' },
    { label: 'Indices', value: 'ix', enumeration: 'indices' },
    { label: 'Consumption', value: 'cn', enumeration: 'consumption' },
    { label: 'Time dependent restriction', value: 'ti', enumeration: 'timeDependentRestriction' },
  ],
  lineAttributes: [
    { label: 'Foreground', value: 'fg', enumeration: 'foreground' },
    { label: 'Background', value: 'bg', enumeration: 'background' },
    { label: 'Line Style', value: 'ls', enumeration: 'lineStyle' },
    { label: 'Company Short Name', value: 'cs', enumeration: 'companyShortName' },
    { label: 'Company Logo', value: 'cl', enumeration: 'companyLogo' },
    { label: 'Flags', value: 'fl', enumeration: 'flags' },
    { label: 'Type Name', value: 'tn', enumeration: 'typeName' },
    { label: 'Line ID', value: 'li', enumeration: 'lineId' },
    { label: 'Company ID', value: 'ci', enumeration: 'companyId' },
    { label: 'System ID', value: 'si', enumeration: 'systemId' },
    { label: 'Stops', value: 'st', enumeration: 'stops' },
  ],
  metricsystems: [
    { label: 'Metric', value: 'metric' },
    { label: 'Imperial/US', value: 'imperial' },
  ],
  generalisationPresets: [
    { label: 'Not Set', value: '' },
    { label: 'Preset 1', value: '0.01, 0.0001, 0.000001' },
    { label: 'Preset 2', value: '0.01, 0.001, 0.0001, 0.00001, 0.000001' },
    { label: 'Other', value: '*' },
  ],
  truckTypes: [
    { label: 'Not Set', value: '' },
    { label: 'Truck', value: 'truck' },
    { label: 'Tractor Truck', value: 'tractorTruck' },
  ],
  shippedHazardousGoods: [
    { label: 'Explosive material', value: 'explosive' },
    { label: 'Gas', value: 'gas' },
    { label: 'Flammable material', value: 'flammable' },
    { label: 'Combustible material', value: 'combustible' },
    { label: 'Organic material', value: 'organic' },
    { label: 'Poison', value: 'poison' },
    { label: 'Radio-active material', value: 'radioActive' },
    { label: 'Corrosive material', value: 'corrosive' },
    { label: 'Poisonous upon inhalation material', value: 'poisonousInhalation' },
    { label: 'Harmful to Water material', value: 'harmfulToWater' },
    { label: 'Other material', value: 'other' },
  ],
  tunnelCategories: [
    { label: 'Not Set', value: '' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
  ],
  truckRestrictionPenalty: [
    { label: 'Not Set', value: '' },
    { label: 'Soft', value: 'soft' },
    { label: 'Strict', value: 'strict' },
  ],
  consumptionTypes: [
    { label: 'Default', value: 'default' },
    { label: 'Standard', value: 'standard' },
    { label: 'Electric', value: 'electric' },
  ],
  plugTypes: [
    { label: 'AC_DC_COMBO_MENNEKES_TYPE_2', value: 'AC_DC_COMBO_MENNEKES_TYPE_2' },
    { label: 'J1772_COMBO', value: 'J1772_COMBO' },
  ],
  groups: [
    { label: 'AUDI', value: 'AUDI' },
    { label: 'PORSCHE', value: 'PORSCHE' },
  ],
  jsonAttributes: [
    { value: 'lowerCaseFirstCharacter', label: 'Lower case first character of identifiers' },
    { value: 'includeTypeElement', label: 'Include _type element' },
    { value: 'usePluralNaming', label: 'Use plural naming for collections' },
    {
      value: 'shapesToArraysWithPairs',
      label: 'Flatten list of shape strings to double arrays containing lat/lon pairs',
    },
    {
      value: 'shapesToArraysWithTriplets',
      label: 'Flatten list of shape strings to double arrays containing lat/lon/alt triplets',
    },
    { value: 'jsonWrapper', label: 'Supress JSON response object wrapper' },
  ],
  bool: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  vehicleTypes: [
    { label: 'Diesel', value: 'diesel' },
    { label: 'Gasoline', value: 'gasoline' },
    { label: 'Electric', value: 'electric' },
  ],
  excludeZoneTypes: [
    { label: 'Vignette', value: 'vignette' },
    { label: 'Congestion Pricing', value: 'congestionPricing' },
    { label: 'Admin Class', value: 'adminClass' },
    { label: 'Environmental', value: 'environmental' },
  ],
  electricPresets,
  supplyTypes: [
    { label: 'AC', value: 'AC' },
    { label: 'DC', value: 'DC' },
  ],
};

const MAP = {
  [formsEnum.CALCULATE_ROUTE]: Routing,
  [formsEnum.GET_ROUTE_INFO]: GetRoute,
  [formsEnum.CALCULATE_MATRIX]: Matrix,
  [formsEnum.CALCULATE_ISOLINE]: Isoline,
  [formsEnum.INTERMODAL]: Intermodal,
  [formsEnum.OLS]: OlsAPI,
};

export default function get(currentForm) {
  return Object.assign({}, base, MAP[currentForm] || {});
}
