import { TILE_TYPES, TILE_URLS } from './constants';
import credentials from 'config/credentials';

const type = window.navigator.webdriver ? TILE_TYPES.RASTER : TILE_TYPES.VECTOR;

export default {
  type,
  raster: {
    type: 'maptile',
    scheme: 'normal.day',
    size: '256',
    format: 'png',
    additionalParameters: '',
    lg: 'eng',
    url: 'hereapi.com',
    apiKey: credentials.MAP.apiKey,
    appId: '',
    appCode: '',
  },
  vector: {
    url: TILE_URLS[0],
    path: '/v2/vectortiles/core/mc/',
    apiKey: credentials.MAP.apiKey,
    styleUrl: 'https://js.api.here.com/v3/3.1/styles/omv/normal.day.yaml',
  },
};
