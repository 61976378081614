export const emptyBatteryParameters = {
  maxcharge: {
    value: '',
    isValid: true,
  },
  initialcharge: {
    value: '',
    isValid: true,
  },
  minchargeatstop: {
    value: '',
    isValid: true,
  },
  chargingstopdeparturecharge: {
    value: '',
    isValid: true,
  },
  chargingdurationoffset: {
    value: '',
    isValid: true,
  },
  chargingcurve: {
    value: '',
    isValid: true,
  },
  maxvoltage: {
    value: '',
    isValid: true,
  },
  maxcurrent: {
    value: '',
    isValid: true,
  },
  minchargeatchargingstop: {
    value: '',
    isValid: true,
  },
  minchargeatfirstchargingstop: {
    value: '',
    isValid: true,
  },
  minchargeatdestination: {
    value: '',
    isValid: true,
  },
};

export const modelDefault = {
  label: 'Model Default',
  value: 'default',
  preset: {
    customconsumptiondetails: {
      ascent: {
        value: '9',
        isValid: true,
      },
      descent: {
        value: '4.3',
        isValid: true,
      },
      acceleration: {
        value: '0',
        isValid: true,
      },
      deceleration: {
        value: '0',
        isValid: true,
      },
      auxiliaryconsumption: {
        value: '1.8',
        isValid: true,
      },

      // standard
      speed: {
        value: '0,1.7,10,1.4,30,1.1,50,1.0,70,1.1,100,1.2,120,1.4,140,1.8',
        isValid: true,
      },
      turnpenalty: {
        value: '',
        isValid: true,
      },

      // electric:
      freeflowspeedtable: {
        value: '0,0.239,27,0.239,45,0.259,60,0.196,75,0.207,90,0.238,100,0.26,110,0.296,120,0.337,130,0.351,250,0.351',
        isValid: true,
      },
      trafficspeedtable: {
        value: '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
        isValid: true,
      },
      trafficscaletable: {
        value: '0.0,0.0,0.3,0.7,0.7,0.9,1.0,1.0',
        isValid: true,
      },
    },
    batteryparameters: {
      ...emptyBatteryParameters,
      chargingdurationoffset: {
        value: '120',
        isValid: true,
      },
      maxvoltage: {
        value: '400',
        isValid: true,
      },
    },
    makereachable: 'false',
    chargingstationfilters: {
      plugtype: {
        value: [],
        isValid: true,
      },
    },
    highspeedthreshold: {
      value: '100',
      isValid: true,
    },
  },
};

export const preset50kWh = {
  label: '50kWh car',
  value: '50kwh',
  preset: {
    ...modelDefault.preset,
    batteryparameters: {
      ...modelDefault.preset.batteryparameters,
      maxcharge: {
        value: '50',
        isValid: true,
      },
      initialcharge: {
        value: '35',
        isValid: true,
      },
      minchargeatstop: {
        value: '5',
        isValid: true,
      },
      chargingstopdeparturecharge: {
        value: '45',
        isValid: true,
      },
      chargingcurve: {
        value: '0,239,16,199,24,167,30,111,34,83,38,55,42,33,46,17,50,1',
        isValid: true,
      },
    },
    makereachable: 'true',
    chargingstationfilters: {
      plugtype: {
        value: ['AC_DC_COMBO_MENNEKES_TYPE_2', 'J1772_COMBO'],
        isValid: true,
      },
    },
  },
};

export const preset80kWh = {
  label: '80kWh car',
  value: '80kwh',
  preset: {
    ...modelDefault.preset,
    batteryparameters: {
      ...modelDefault.preset.batteryparameters,
      maxcharge: {
        value: '80',
        isValid: true,
      },
      initialcharge: {
        value: '48',
        isValid: true,
      },
      minchargeatstop: {
        value: '8',
        isValid: true,
      },
      chargingstopdeparturecharge: {
        value: '72',
        isValid: true,
      },
      chargingcurve: {
        value: '0,239,32,199,56,167,60,130,64,111,68,83,72,55,76,33,78,17,80,1',
        isValid: true,
      },
    },
    makereachable: 'true',
    chargingstationfilters: {
      plugtype: {
        value: ['AC_DC_COMBO_MENNEKES_TYPE_2', 'J1772_COMBO'],
        isValid: true,
      },
    },
  },
};

const electricPresets = [
  modelDefault,
  preset50kWh,
  preset80kWh,
];

export default electricPresets;
