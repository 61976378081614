import React from 'react';
import PropTypes from 'prop-types';
import CustomInput from '../../shared/input';
import FormRow from '../../shared/formRow';
import CustomTooltip from '../../shared/tooltip';
import CustomButton from '../../shared/button';
import linkInfoParsers from './linkInfoParsers';

export default class LinkInfoDataItem extends React.Component {
  static propTypes = {
    attrKey: PropTypes.string,
    layerKey: PropTypes.string.isRequired,
    value: PropTypes.string,
    tooltip: PropTypes.string.isRequired,
  };

  static defaultProps = {
    tooltip: '',
  };

  state = {
    showRaw: false,
  }

  toggleShowRaw = () => {
    this.setState({ showRaw: !this.state.showRaw });
  }

  render() {
    const tooltipHtml = this.props.tooltip.replace(/ /g, '&nbsp;');
    // eslint-disable-next-line react/no-danger
    const tooltip = <div dangerouslySetInnerHTML={{ __html: tooltipHtml }} />;
    const parser = _.get(linkInfoParsers, `${this.props.layerKey}.${this.props.attrKey}`);
    let value = this.props.value;
    if (parser) {
      value = this.state.showRaw ? this.props.value : parser(this.props.value);
    }

    return (
      <FormRow>
        <CustomInput
          label={this.props.attrKey}
          isReadonly
          value={value}
        />
        {parser ? (
          <CustomButton
            title={this.state.showRaw ? 'Show parsed' : 'Show raw'}
            onClick={this.toggleShowRaw}
          />
        ) : null}

        <CustomTooltip tooltip={tooltip}>
          <i className="fa fa-question" />
        </CustomTooltip>
      </FormRow>
    );
  }
}
