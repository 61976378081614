import { isCoordsValid } from 'utils/validation/coordsValidation';

export default function avoidAreasValidation(value) {
  let bboxs = value.split('!');
  if (bboxs.some(bbox => !isCoordsValid(bbox))) {
    return ['Invalid coords format'];
  }
  if (bboxs.some(bbox => bbox.split(';').filter(Boolean).length % 2 !== 0)) {
    return ['BottomRight is missing'];
  }
  return [];
}
