import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'shared/toggleButton';
import FormRow from 'shared/formRow';
import MapFormToggler from './MapFormToggler';

export default class MatchRouteConfigForm extends Component {
  render() {
    let { formData, onChange } = this.props;
    return <div className="rf-map__tooltip rf-map__matchRouteConfig-module" ref="form">
      <h2>Match Route Config</h2>
      <MapFormToggler getFormRef={() => this.refs.form} renderToggler={false} {...this.props} />
      <FormRow className="rf-render-route-plan">
        <span className="rf-render-route-plan__label">Render route plan</span>
        <ToggleButton
          checked={formData.mapConfig.renderRoutePlan}
          onChange={onChange.bind(this, 'renderRoutePlan')}
        />
      </FormRow>
      <FormRow className="rf-render-leg-splits">
        <span className="rf-render-leg-splits__label">Render leg splits</span>
        <ToggleButton
          checked={formData.mapConfig.renderLegSplits}
          onChange={onChange.bind(this, 'renderLegSplits')}
        />
      </FormRow>
    </div>;
  }
}

MatchRouteConfigForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
};
