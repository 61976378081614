import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ApiUrlContainer from '../apiUrl/ApiUrlContainer';
import FilterPanelContainer from '../filterPanel/FilterPanelContainer';
import ResultPanelContainer from '../resultPanel/ResultPanelContainer';
import NavContainer from '../navPanel/NavContainer';
import TabsContainer from '../tabsPanel/TabsContainer';
import MapContainer from '../map/MapContainer';
import contextMenus from '../contextMenu';

import { fetchData } from 'state/tabs/tab/response/actions';
import { filterValueUpdate } from 'state/tabs/tab/formData/common/fields/actions';

import './styles/normalize.scss';
import './styles/main.scss';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { restoreFromUrl } from 'state/tabs/tab/formData/actions';
import { UI_SIZE } from 'state/appSettings/base/constants';
import parseUrlParams from 'utils/url/parser/parseUrlParams';
import utils from 'utils';
import * as TabsAction from 'state/tabs/actions';
import parseHref from 'utils/url/parser/parseHref';
import { getTabs } from 'state/tabs';
import { setTabTitle } from 'state/tabs/tab/title/actions';
import { setTabColor } from 'state/tabs/tab/tabColorPalette/actions';
import { setIsTabActive } from 'state/tabs/tab/isActive/actions';
import { setSelectedTab } from 'state/selectedTab/actions';
import classnames from 'classnames';
import { getIsHistoryShown } from 'state/isHistoryShown';
import HistoryContainer from '../history/HistoryContainer';
import { routerActions } from 'react-router-redux';
import { parseUrlSettings, transformToPost } from '../../utils/url';
import CustomNotifications from 'shared/notification';
import hasResults from '../resultPanel/hasResults';
import { setSettingsValue } from '../../state/settingsPreset/settingsPresetActions';
import { getAppSettings } from 'state/appSettings/base';
import formsEnum from '../../config/formsEnum';
import modulesEnum from '../../config/modulesEnum';

const UI_SIZE_CLASSES = {
  [UI_SIZE.SMALL]: 'lui-small',
  [UI_SIZE.MEDIUM]: 'lui-medium',
  [UI_SIZE.LARGE]: 'lui-large',
};

class Main extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};
    if (nextProps.notification !== prevState.notification) {
      state.notification = nextProps.notification;
      prevState.addNotification(nextProps.notification);
    }
    const { urlHash = '' } = nextProps;
    if (urlHash.substr(1) && !urlHash.startsWith('#url') && nextProps.urlHash !== prevState.urlHash) {
      Main.restoreTabsFromUrl(nextProps);
    }
    state.urlHash = nextProps.urlHash;
    return state;
  }

  static restoreTabsFromUrl(props) {
    const { urlHash, tabs, restoreFromUrl, addTab, removeTab, request, push, setTabTitle, setTabColor,
      setSelectedTab, setIsTabActive, filterValueUpdate, setSettingsValue } = props;
    const decodedHash = decodeURIComponent(urlHash.slice(1));
    let tabsData;
    try {
      tabsData = JSON.parse(decodedHash);
    } catch (e) {
      return;
    }
    const urls = tabsData.map(tab => (tab.url ? decodeURIComponent(tab.url) : null));
    let lengthDiff = urls.length - tabs.length;
    if (lengthDiff >= 0) {
      utils.range(0, lengthDiff).forEach(() => addTab());
    } else {
      utils.range(0, Math.abs(lengthDiff)).forEach(() => removeTab(0));
    }
    const urlFormModuleMap = {};
    let selectedTab = 0;
    urls.forEach((url, index) => {
      let preset = null;
      let form = null;
      let module = null;
      if (url) {
        const data = parseHref.getData(url.substr(0, url.indexOf('?')));
        preset = tabsData[index].preset || data.preset || 'CUSTOM';
        form = tabsData[index].form || data.form || formsEnum.OLS;
        module = tabsData[index].module || data.module || modulesEnum.ROUTING;
        if (preset === 'CUSTOM') {
          setSettingsValue({
            currentPreset: preset,
            currentForm: form,
            value: parseUrlSettings(url, module, form),
          }, index);
        }
        urlFormModuleMap[url] = [form, module];
        restoreFromUrl({
          module,
          currentForm: form,
          currentPreset: preset,
          value: parseUrlParams(url.split('?')[1], form, module),
        }, index);
      } else {
        preset = '';
        form = tabsData[index].form;
        module = tabsData[index].module;
        restoreFromUrl({
          module,
          currentForm: form,
          currentPreset: preset,
          value: tabsData[index].formData,
        }, index);
      }

      if (tabsData[index].isSelected) {
        selectedTab = index;
      }

      const { usePostReq } = tabsData[index];

      if (usePostReq !== null) {
        filterValueUpdate({
          key: 'usePostReq',
          currentForm: form,
          value: usePostReq,
        });
      }

      if (usePostReq) {
        const postDataAndUrl = transformToPost(url);
        request(module, form, postDataAndUrl.url, index, postDataAndUrl.postData);
      } else {
        request(module, form, url, index);
      }
      setTabTitle(index, tabsData[index].title);
      setTabColor(index, tabsData[index].tabColorPalette);
      setIsTabActive(index, tabsData[index].isActive);
    });
    setSelectedTab(selectedTab);
    push('');
  }

  constructor(props) {
    super(props);
    this.state = {
      contextMenuData: {},
      notification: null,
      addNotification: notification => {
        if (this._notificationSystem) {
          this._notificationSystem.addNotification(notification);
        }
      },
    };
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  onContextMenu(contextMenuData) {
    if (contextMenuData === this.state.contextMenuData) {
      return;
    }
    this.setState({ contextMenuData });
  }

  getContextMenuEl(module, currentForm) {
    let ContextMenuContainerEl = utils.getObject(contextMenus, `${module}.${currentForm}`);
    if (!ContextMenuContainerEl) {
      return '';
    }

    return (
      <ContextMenuContainerEl data={this.state.contextMenuData} />
    );
  }

  render() {
    let { apiUrl, module, currentForm, isHistoryShown, uiSize } = this.props;

    const contentClasses = classnames('rf-main__content rf-clearfix', {
      'rf-main__content_history': isHistoryShown,
      'rf-main__content_no-api-url-bar': apiUrl === '',
    });

    const isResultPanelPresent = !!utils.getObject(hasResults, `${module}.${currentForm}`);

    const uiSizeClass = UI_SIZE_CLASSES[uiSize];

    return (
      <div className={`rf-main ${uiSizeClass}`}>
        <lui-default-theme dark="true">
            <CustomNotifications ref="notificationSystem" />
            <NavContainer />
            <TabsContainer />
            <div className={contentClasses}>
              <FilterPanelContainer />
              <MapContainer apiUrl={apiUrl} onContextMenu={this.onContextMenu.bind(this)} />
              {this.getContextMenuEl(module, currentForm)}
              {isResultPanelPresent && <ResultPanelContainer />}
            </div>
            {apiUrl && <ApiUrlContainer apiUrl={apiUrl} />}
            <HistoryContainer />
          </lui-default-theme>

      </div>
    );
  }
}

Main.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  currentForm: PropTypes.string.isRequired,
  urlHash: PropTypes.string.isRequired,
  request: PropTypes.func.isRequired,
  notification: PropTypes.object,
  tabs: PropTypes.array.isRequired,
  uiSize: PropTypes.number.isRequired,
  restoreFromUrl: PropTypes.func.isRequired,
  removeTab: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setTabTitle: PropTypes.func.isRequired,
  setTabColor: PropTypes.func.isRequired,
  setIsTabActive: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  isHistoryShown: PropTypes.bool.isRequired,
  isNewTabShown: PropTypes.bool.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  setSettingsValue: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    module: getModule(state),
    currentForm: getForm(state),
    urlHash: state.routing.locationBeforeTransitions.hash,
    notification: state.notification,
    tabs: getTabs(state),
    isHistoryShown: getIsHistoryShown(state),
    isNewTabShown: state.isNewTabShown,
    uiSize: getAppSettings(state).uiSize,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    request: bindActionCreators(fetchData, dispatch),
    restoreFromUrl: bindActionCreators(restoreFromUrl, dispatch),
    removeTab: bindActionCreators(TabsAction.removeTab, dispatch),
    addTab: bindActionCreators(TabsAction.addTab, dispatch),
    push: bindActionCreators(routerActions.push, dispatch),
    setTabTitle: bindActionCreators(setTabTitle, dispatch),
    setTabColor: bindActionCreators(setTabColor, dispatch),
    setIsTabActive: bindActionCreators(setIsTabActive, dispatch),
    setSelectedTab: bindActionCreators(setSelectedTab, dispatch),
    filterValueUpdate: bindActionCreators(filterValueUpdate, dispatch),
    setSettingsValue: bindActionCreators(setSettingsValue, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
