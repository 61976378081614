const NS = 'powerFilter';

export const SET = `${NS}/SET`;

const getDefaultState = () => ({
  from: '',
  to: '',
  plugTypes: [],
});

export default function (state = getDefaultState(), action) {
  switch (action.type) {
    case SET:
      return { ...state, ...action.value };
    default:
      return state;
  }
}
