import routing from './routing';
////////////////
//////////////////////////////
////////////////////////////////
//////////////////////////////////////////////////////
//////////

import modulesEnum from 'config/modulesEnum';

export default {
  [modulesEnum.ROUTING]: routing,
//////////////////
///////////////////////////////
/////////////////////////////////
////////////////////////////////////
////////////////////////////////////////////////////////
////////////
};
