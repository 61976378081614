import { createSelector } from 'reselect';
import { stringifyQuery } from 'utils/url';
import settingsUtils from 'utils/settings';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData, getPreset, getCurrentPreset } from 'state/settingsPreset';

export default createSelector(
  state => getCurrentFormData(state),
  state => settingsUtils.getCurrentCredentials(getSettingsData(state), getForm(state), getModule(state)),
  state => getModule(state),
  state => getForm(state),
  state => getPreset(state, getModule(state), getForm(state), getCurrentPreset(state)),
  stringifyQuery
);
