import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomComponent from 'shared/component';
import CustomCopyButton from 'shared/copyButton';
import Tooltip from '../tooltip';

import utils from 'utils';

import './styles/input.scss';

const CUSTOM_PROPS = ['isCopyAble', 'debounce', 'cssClasses', 'onChange', 'value', 'tooltip',
  'tooltipPlacement', 'blurOnEnter', 'isCompact'];

export class CustomInput extends CustomComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {
      onChange: nextProps.onChange,
    };
    if (prevState.value !== nextProps.value) {
      state.value = nextProps.value;
    }
    if ((nextProps.onChange !== prevState.onChange) || !prevState.debouncedOnChange) {
      state.debouncedOnChange = utils.debounce(nextProps.onChange, 300);
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.onChange = ::this.onChange;
    this.onKeyPress = ::this.onKeyPress;
  }

  state = {
    onChange: null,
    debouncedOnChange: null,
    value: null,
  };

  onChange(e) {
    e.persist();
    this.setState({ value: e.target.value });
    if (this.props.debounce) {
      this.state.debouncedOnChange(e);
    } else {
      this.props.onChange(e);
    }
  }

  onKeyPress(e) {
    if (this.props.blurOnEnter && e.key === 'Enter') {
      e.preventDefault();
      this.props.onBlur(e);
    }
  }

  getCopyIcon() {
    let { isCopyAble } = this.props;

    return (
      isCopyAble ?
        <CustomCopyButton
          value={this.state.value}
          btnClass="rf-input__btn-copy"
          onCopy={this.onCopy}
        /> :
        ''
    );
  }

  setCaretPosition(caretPos) {
    let elem = this.refs.input;
    if (elem.createTextRange) {
      let range = elem.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else if (elem.selectionStart) {
      elem.focus();
      elem.setSelectionRange(caretPos, caretPos);
    } else {
      elem.focus();
    }
  }

  getCaretPosition() {
    return this.refs.input.selectionStart;
  }

  getInputNode() {
    let { isReadonly, isValid, ...args } = this.props;
    let inputClasses = classnames('rf-input__field', {
      'rf-input__field_error': !isValid,
    });

    let attrs = utils.omit(args, CUSTOM_PROPS);

    if (!isReadonly) {
      if (this.state.value !== '') { // to avoid lui bug
        attrs.value = this.state.value;
      }
      return <lui-textfield class={inputClasses} type={attrs.type}>
        <input
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          ref="input"
          value={this.state.value}
          step="any"
          {...attrs}
        />
      </lui-textfield>;
    }
    return <div className={inputClasses}>{this.state.value}</div>;
  }

  getLabelElement() {
    let { label, tooltip, tooltipPlacement } = this.props;
    if (label) {
      if (tooltip) {
        let props = { tooltip };
        if (tooltipPlacement) {
          props.placement = tooltipPlacement;
        }
        return (
          <span className="rf-input__caption">
            <Tooltip {...props}><span>{label}:</span></Tooltip>
          </span>
        );
      }
      return <span className="rf-input__caption">{label}:</span>;
    }
    return null;
  }

  render() {
    let { isCopyAble, isReadonly, isCompact } = this.props;
    let cssClasses = classnames('rf-input', {
      'rf-input_copyable': isCopyAble,
      'rf-input_readonly': isReadonly,
      'rf-input_compact': isCompact,
    });

    return (
      <label className={this.getCssClasses(cssClasses)}>
        {this.getLabelElement()}
        <span className="rf-input__container">
          {this.getInputNode()}
          {this.getCopyIcon()}
        </span>
      </label>
    );
  }
}

CustomInput.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
  isValid: PropTypes.bool.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  isCopyAble: PropTypes.bool.isRequired,
  isCompact: PropTypes.bool.isRequired,
  debounce: PropTypes.bool.isRequired,
  blurOnEnter: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

CustomInput.defaultProps = {
  isReadonly: false,
  isCopyAble: false,
  isValid: true,
  isCompact: false,
  debounce: false,
  blurOnEnter: false,
  label: '',
  value: '',
  type: 'text',
  onChange() {},
  onBlur() {},
};

export default CustomInput;
