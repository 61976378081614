import { combineReducers } from 'redux';

import fieldsProxy from '../../common/fields/proxy';
import fieldsValidation from './fields/validation';
import defaultFieldsState from './fields/defaultState';

import formsEnum from 'config/formsEnum';
import currentRoute from '../../routingTab/routingForm/currentRoute/currentRouteReducer';
import highlightedRoute from '../../routingTab/routingForm/highlightedRoute/highlightedRouteReducer';
import highlightedManeuver from '../../routingTab/routingForm/highlightedManeuver/reducer';

let currentForm = formsEnum.INTERMODAL;
export default combineReducers(Object.assign({}, {
  fields: fieldsProxy({
    currentForm,
    defaultState: defaultFieldsState,
    validation: fieldsValidation,
  }),
  currentRoute,
  highlightedRoute,
  highlightedManeuver,
}));
