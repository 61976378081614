import getConfig from 'config/form';

export default function modeValidation(form) {
  return value => {
    const config = getConfig(form);
    let [type, mode, ...featureTypes] = value.split(';');
    let errors = [];

    if (config.routeTypes.indexOf(type) === -1) {
      errors.push(`Invalid route type: '${type}'`);
    }

    let routeTransportsValues = [...config.routeTransports.map(routeTransport => routeTransport.value), 'scooter'];
    if (routeTransportsValues.indexOf(mode) === -1) {
      errors.push(`Invalid mode type: '${mode}'`);
    }

    if (/^traffic:/.test(featureTypes[0])) {
      let trafficMode;
      [trafficMode, ...featureTypes] = featureTypes;
      let routeTrafficValues = config.routeTraffics.map(routeTraffic => routeTraffic.value);
      if (routeTrafficValues.indexOf(trafficMode) === -1) {
        errors.push(`Invalid traffic mode: '${trafficMode}'`);
      }
    }

    let featureKeys = config.features.map(feature => feature.value);
    let featureValues = config.featureSet.map(feature => feature.value);
    featureTypes.forEach(features => {
      features.split(',').forEach(featureType => {
        let splitedFeature = featureType.split(':');
        if (splitedFeature.length !== 2) {
          errors.push(`Incorrect feature: '${featureType}'`);
          return;
        }
        let [key, value] = splitedFeature;
        if (featureKeys.indexOf(key) === -1) {
          errors.push(`Incorrect feature key: '${key}'`);
        }
        if (featureValues.indexOf(value) === -1) {
          errors.push(`Incorrect feature value: '${value}'`);
        }
      });
    });

    return errors;
  };
}
