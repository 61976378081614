export const RECEIVE_DATA_ROUTING = 'RECEIVE_DATA_ROUTING';
export const RECEIVE_DATA_MATRIX = 'RECEIVE_DATA_MATRIX';
export const RECEIVE_DATA_ISOLINE = 'RECEIVE_DATA_ISOLINE';
export const RECEIVE_DATA_ROUTE_INFO = 'RECEIVE_DATA_ROUTE_INFO';

////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////////
//////////
export const RECEIVE_DATA_OLS_API = 'RECEIVE_DATA_OLS_API';
export const RECEIVE_DATA_INTERMODAL = 'RECEIVE_DATA_INTERMODAL';
