import React from 'react';
import OlsApiForm from './OlsApiForm';
import FormRow from '../../../shared/formRow';
import PropTypes from 'prop-types';
import getConfig from 'config/form';
import formsEnum from 'config/formsEnum';
import CheckboxAttributesSet from '../../common/CheckboxAttributesSet';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import { connect } from 'react-redux';
import utils from 'utils';
import RouteDateField from '../../common/RouteDateField';
import TransportMode from '../../common/modeFieldSet/TransportMode';
import CustomSelect from '../../../shared/select';
import OlsConsumption from '../../common/OlsConsumption';
import { getIsAutoRefreshOn } from 'state/appSettings/base';
import TruckParams from './TruckParams';
import CustomInput from 'shared/input';

const formConfig = getConfig(formsEnum.OLS);

const routeDateConfigs = {
  routeTimeModes: [
    { label: 'Now', value: '' },
    { label: 'Any', value: 'any' },
    { label: 'Departure', value: 'departure' },
    { label: 'Arrival', value: 'arrival' },
  ]
};

class OlsForm extends React.Component {
  onChange = (key, e) => {
    this.props.filterValueUpdate({ key, currentForm: formsEnum.OLS, value: utils.extractData(e) });
  }

  onMultiUpdate = (params) => {
    this.props.filterValueMultiUpdate(formsEnum.OLS, params);
  }

  onChangeTransportMode = (e) => {
    this.onChange('transportMode', e);
    this.onChange('spans', []);
  }

  onTimeChange = (data) => {
    let departure = '';
    if (data.key === 'departure') {
      departure = data.value;
    }
    if (data.key === 'any') {
      departure = 'any';
    }
    this.props.filterValueUpdate({
      key: 'departureTime',
      currentForm: formsEnum.OLS,
      value: departure,
    });
    this.props.filterValueUpdate({
      key: 'arrivalTime',
      currentForm: formsEnum.OLS,
      value: data.key === 'arrival' ? data.value : '',
    });
  }

  getTimeValue = (routeDate) => {
    if (routeDate.key === '') {
      return null;
    } else if (routeDate.key === 'any') {
      return 'departureTime: any';
    }
    return `${routeDate.key}Time: ${routeDate.value}`;
  }

  render() {
    const { formData = {}, isAutoRefreshOn } = this.props;
    const { fields = {} } = formData;
    const { departureTime, arrivalTime, spans, transportMode, routingMode } = fields;
    let routeDateKey = '';
    if (departureTime) {
      routeDateKey = 'departure';
    } else if (arrivalTime) {
      routeDateKey = 'arrival';
    }
    const routeDate = {
      value: departureTime || arrivalTime || '',
      key: departureTime === 'any' ? 'any' : routeDateKey,
    };

    return (
      <OlsApiForm
        onChange={this.onChange}
        filterValueMultiUpdate={this.props.filterValueMultiUpdate}
        formData={formData}
        isAutoRefreshOn={isAutoRefreshOn}
      >
        <FormRow cssClasses="rf-grey-box">
          <TransportMode
            routeTransports={formConfig.routeTransports}
            value={transportMode}
            scooterEnabled
            onChange={this.onChangeTransportMode}
          />
          <CustomSelect
            label="Routing Mode"
            value={routingMode}
            options={formConfig.routingModes}
            onChange={e => this.onChange('routingMode', e)}
            addEmptyOption
          />
          <CustomInput
            label="HOV occupancy"
            placeholder="HOV occupancy (min 2)"
            value={fields['vehicle[hovOccupancy]']}
            isValid={fields['vehicle[hovOccupancy]'] === '' || +fields['vehicle[hovOccupancy]'] >= 2}
            onBlur={e => this.onChange('vehicle[hovOccupancy]', e)}
            type="number"
            blurOnEnter
          />
          <TruckParams
            fields={fields}
            onChange={this.onChange}
            isShown={transportMode === 'truck'}
          />
        </FormRow>
        <FormRow>
          <CheckboxAttributesSet
            label="Spans"
            options={formConfig.spans}
            data={spans}
            isExpanded
            onChange={this.onChange.bind(this, 'spans')}
          />
          <FormRow cssClasses="rf-grey-box rf-route-time" id="time">
            <RouteDateField
              label="Time"
              value={this.getTimeValue(routeDate)}
              formConfig={routeDateConfigs}
              routeDate={routeDate}
              onChange={this.onTimeChange}
            />
          </FormRow>
        </FormRow>
        <OlsConsumption
          formData={formData}
          onChange={this.onChange}
          formConfig={formConfig}
          onMultiUpdate={this.onMultiUpdate}
        />
      </OlsApiForm>
    );
  }
}

OlsForm.propTypes = {
  formData: PropTypes.object.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  isAutoRefreshOn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  formData: getCurrentFormData(state),
  isAutoRefreshOn: getIsAutoRefreshOn(state),
});

const mapDispatchToProps = {
  filterValueUpdate: FieldsActions.filterValueUpdate,
  filterValueMultiUpdate: FieldsActions.filterValueMultiUpdate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OlsForm);
