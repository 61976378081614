import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomComponent from 'shared/component';

import utils from 'utils';

import './styles/textarea.scss';

class CustomTextarea extends CustomComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    let { value } = nextProps;
    if (prevState.value !== value) {
      return { value };
    }
    return {};
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  state = {
    value: null,
  };

  onChange(e) {
    e.persist();
    this.setState({ value: e.target.value });
    this.props.onChange(e);
  }

  getLabelEl() {
    let l = this.props.label;
    return l ? <span className="rf-textarea__caption">{l}</span> : '';
  }

  render() {
    let { label, isValid, ...args } = this.props,
      fieldClasses = classnames('rf-textarea__field', {
        'rf-textarea__field_error': !isValid,
      });

    let attrs = utils.omit(args, ['cssClasses', 'value', 'onChange']);

    return (
      <label className={this.getCssClasses(['rf-textarea'])}>
        {this.getLabelEl()}
        <textarea
          className={fieldClasses}
          value={this.state.value}
          onChange={this.onChange}
          {...attrs}
        />
      </label>
    );
  }
}

CustomTextarea.defaultProps = {
  label: '',
  value: '',
  isValid: true,
  onChange() {},
  onKeyPress() {},
};

CustomTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};

export default CustomTextarea;
