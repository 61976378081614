import React from 'react';
import './styles/dragIcon.scss';

class DragIcon extends React.Component {
  render() {
    return (
      <i className="rf-drag-icon" />
    );
  }
}

export default DragIcon;
