import { LOCATION_TYPES } from 'state/tabs/tab/formData/routingTab/approachPathRouterForm/fields/constants';

export const locationTypeToFieldName = {
  [LOCATION_TYPES.GEO]: 'locationGeo',
  [LOCATION_TYPES.LINK]: 'locationLink',
  [LOCATION_TYPES.STREET]: 'locationStreet',
  [LOCATION_TYPES.OPEN_LR]: 'locationOpenLR',
  [LOCATION_TYPES.TMC]: 'locationTMC',
  [LOCATION_TYPES.ETL]: 'locationETL',
};

export default function exec(formData) {
  const location = formData.fields[locationTypeToFieldName[formData.fields.locationType]];
  return location.value && location.isValid;
}
