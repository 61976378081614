import React from 'react';
import PropTypes from 'prop-types';

import RadiobuttonList from '../../common/RadiobuttonList';
import ResultPanelFormBase from '../../_ResultPanelFormBase';

class ResultPanelForm extends ResultPanelFormBase {
  getIsolines(isolines = []) {
    let { setIsoline, currentIsoline } = this.props;
    return (
      <div>
        <h4>Isolines:</h4>
        <RadiobuttonList
          data={isolines}
          onChange={setIsoline}
          getColor={::this.getTabColor}
          currentIndex={currentIsoline}
          itemLabel="Isoline"
        />
      </div>
    );
  }

  getContent() {
    let { responseData = {} } = this.props,
        { response = {} } = responseData.data,
        { isoline = [] } = response;
    if (isoline.length === 0) {
      return '';
    }
    return (
      <div>
        {this.getIsolines(isoline)}
        {this.getResponseTabContent(response)}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.getContent()}
        {this.getInspectLinkData()}
        {this.getSearchResults()}
      </div>
    );
  }
}

ResultPanelForm.propTypes = {
  onShowFormattedResult: PropTypes.func.isRequired,
  onRemoveInspectLink: PropTypes.func.isRequired,
};

export default ResultPanelForm;
