import React from 'react';

import Item from './Item';

class LinkItem extends Item {
  static getDerivedStateFromProps(nextProps, prevState) {
    let cCoords = Item.getCoords({ data: prevState.data });
    let nCoords = Item.getCoords(nextProps);

    if (nCoords && cCoords !== nCoords && nextProps.onGetLinkId) {
      nextProps.onGetLinkId(nCoords);
    }

    return {
      data: nextProps.data,
    };
  }

  componentDidMount() {
    let cCoords = Item.getCoords(this.props);
    if (cCoords && this.props.onGetLinkId) {
      this.props.onGetLinkId(cCoords);
    }
  }

  getEVContent() {
    return null;
  }

  getMainContent() {
    let { data = {} } = this.props;
    let { link = {} } = data;

    return (
      <div>
        {super.getMainContent()}
        {this.renderInput('link', link.value, 'Link Position', link.isValid)}
      </div>
    );
  }

  getAdvancedContent() {
    let { data = {} } = this.props;
    let { displayPosition = {} } = data;

    return (
      <div>
        {super.getAdvancedContent()}
        {this.renderInput('displayPosition', displayPosition.value, 'Display Position',
          displayPosition.isValid)}
      </div>
    );
  }
}

export default LinkItem;
