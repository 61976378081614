import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';

import Tree from 'shared/tree';
import Modal from 'shared/modal';
import CustomCopyButton from 'shared/copyButton';

import './styles/rawResponse.scss';
import adjustLUIStyle from 'utils/adjustLUIStyle';

class RawResponse extends Component {
  shouldComponentUpdate(nextProps) {
    return utils.isPropChanged(nextProps, this.props, 'isModalShown')
      || utils.isPropChanged(nextProps, this.props, 'responseData');
  }

  componentDidUpdate() {
    adjustLUIStyle(document.querySelectorAll('.rf-main__content lui-overlay'), 'lui-overlay');
  }

  render() {
    const response = _.cloneDeep(this.props.responseData) || {};
    _.unset(response, 'data.response.time');
    _.unset(response, 'data.response.serverTime');
    const res = _.get(response, 'data.response', {});
    if (Object.keys(res).length === 0) {
      _.unset(response, 'data.response');
    }
    let shouldExpandNode = () => true;
    let dataString = '';

    try {
      dataString = JSON.stringify(response.data);
    } catch (e) {
      console.error('Can`t stringify JSON', e);
    }

    if (!this.props.isModalShown) {
      return null;
    }

    return (
      <lui-default-theme dark>
      <Modal
        title="Api response:"
        cssClasses="rf-raw-response"
        isShown={this.props.isModalShown}
      >
        <div className="rf-raw-response__container">
          <Tree data={response.data} shouldExpandNode={shouldExpandNode} />
        </div>
        <lui-button-group class="rf-raw-response__button-group">
          <CustomCopyButton
            title="Copy to clipboard"
            value={dataString}
            btnClass="rf-button_secondary"
          />
          <lui-button onClick={::this.props.closeModal} secondary>Cancel</lui-button>
        </lui-button-group>
      </Modal>
      </lui-default-theme>
    );
  }
}

RawResponse.propTypes = {
  responseData: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalShown: PropTypes.bool.isRequired,
};

export default RawResponse;
