import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settingsPresetActions from 'state/settingsPreset/settingsPresetActions';
import * as apiActions from 'state/tabs/tab/response/actions';
import * as NotificationActions from 'state/notification/actions';
import SettingsFormContainer from './SettingsFormContainer';

import utils from 'utils';
import classnames from 'classnames';

import './styles/settings.scss';
import { getSelectedTabs } from 'state/tabs';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';

export class SettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onHidePopup = this.onHidePopup.bind(this);
    this.closeOnEsc = this.closeOnEsc.bind(this);
    this.state = {
      isSettingsShown: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.onHidePopup, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onHidePopup, false);
  }

  onHidePopup(e) {
    let popup = this.refs.popup.getDomNode();
    if (!popup.contains(e.target) && this.state.isSettingsShown) {
      this.close();
    }
  }

  onClick(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    this.setState({
      isSettingsShown: !this.state.isSettingsShown,
    });
  }

  closeOnEsc(e) {
    let keyCode = e.which || e.keyCode || 0;
    if (keyCode === 27) {
      this.close();
    }
  }

  close() {
    this.setState({
      isSettingsShown: false,
    });
  }

  render() {
    let { settingsData, setSettingsValue, setCurrentSettingsPreset, currentForm, module, fetchToken,
          setNotification, currentTabData } = this.props;

    if (!utils.getObject(settingsData.settingsPresets, `${module}.${currentForm}`)) {
      return null;
    }

    let btnClassName = classnames('rf-settings__btn', {
      'rf-settings__btn_active': this.state.isSettingsShown,
    });

    return (
        <div className="rf-settings" onKeyDown={this.closeOnEsc}>
          <div className={btnClassName} onClick={this.onClick} />
          <SettingsFormContainer
            ref="popup"
            currentForm={currentForm}
            module={module}
            isSettingsShown={this.state.isSettingsShown}
            settingsData={settingsData}
            setSettingsValue={setSettingsValue}
            setCurrentSettingsPreset={setCurrentSettingsPreset}
            canResetCredentials={!utils.isExternalBuild()}
            close={::this.close}
            fetchToken={fetchToken}
            setNotification={setNotification}
            color={currentTabData.tabColorPalette.primary}
          />
        </div>
    );
  }
}

SettingsContainer.propTypes = {
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  settingsData: PropTypes.object.isRequired,
  currentTabData: PropTypes.object.isRequired,
  setSettingsValue: PropTypes.func.isRequired,
  setCurrentSettingsPreset: PropTypes.func.isRequired,
  fetchToken: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    settingsData: getSettingsData(state),
    currentForm: getForm(state),
    module: getModule(state),
    currentTabData: getSelectedTabs(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSettingsValue: bindActionCreators(settingsPresetActions.setSettingsValue, dispatch),
    setCurrentSettingsPreset: bindActionCreators(settingsPresetActions.setCurrentSettingsPreset, dispatch),
    fetchToken: bindActionCreators(apiActions.fetchToken, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsContainer);
