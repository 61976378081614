export function createTrafficOverlayLayer(args) {
  let { imagesize, imageformat, extraparams, profile, settings = {}, host } = args;
  let provider = new H.map.provider.ImageTileProvider({
    min: 0,
    max: 21,
    getURL: (x, y, z) => {
      let zxy = `${z}/${x}/${y}`;
      let params = ['app_id', 'app_code'].map(key => `${key}=${settings[key]}`);
      if (profile) {
        params.push(`profile=${profile}`);
      }
      if (extraparams) {
        params.push(extraparams);
      }
      return `${host}/${zxy}/${imagesize}/${imageformat}?${params.join('&')}`;
    },
  });
  return new H.map.layer.TileLayer(provider);
}

export const vectorTrafficStyles = `
  sources:
    traffic:
      type: OMV
      max_zoom: 20
      min_display_zoom: 1
  layers:
    traffic_flow:
      data: {source: traffic, layer: traffic_flow}
      draw:
        lines:
          width: 2px
          order: 1
      free:
        filter:
          kind: free
        draw:
          lines:
            color: "#00ff00"
      minor:
        filter:
          kind: minor
        draw:
          lines:
            color: "#aaaa00"
      slow:
        filter:
          kind: slow
        draw:
          lines:
            color: "#ff6600"
      stationary:
        filter:
          kind: stationary
        draw:
          lines:
            color: "#ff0000"
      queuing:
        filter:
          kind: queuing
        draw:
          lines:
            color: "#ff0000"
`;
