import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { set as setNotification } from 'state/notification/actions';
import { setSettings } from 'state/appSettings/base/actions';

import getConfig from 'config/form';
import Fieldset from 'shared/fieldset';
import * as TabsActions from 'state/tabs/actions';

import './styles/newTab.scss';
import { toggleNewTabShown } from 'state/isNewTabShown/actions';
import { getDefaultForm } from 'state/appSettings/base';

class NewTab extends Component {
  setDefaultForm = (module, form, e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const { setNotification, setSettings } = this.props;
    setSettings({ defaultForm: { module, form } });
    setNotification({
      message: `"${form}" form in module ${module} has been set as default.`,
      impact: 'positive',
      autoDismiss: 2,
    });
  }

  getItem(formTemplates, module, form, key) {
    const { defaultForm } = this.props;
    const onClick = this.setDefaultForm.bind(this, module, form);
    const btnProps = { onClick };
    let btnText = 'Set Default';

    if (module === defaultForm.module && form === defaultForm.form) {
      btnProps.disabled = true;
      btnText = 'Default';
    }
    return <div
      className={`rf-new-tab__group__item rf-new-tab__group__item_${module}-${form}`}
      key={key}
      onClick={this.addTab.bind(this, { module, form })}
    >
      <div className="rf-new-tab__group__item__label">{formTemplates[form].label}</div>
      <lui-button class="rf-new-tab__group__item__btn lui-tiny" {...btnProps}>{btnText}</lui-button>
    </div>;
  }

  getItems() {
    const config = getConfig();
    const moduleConfigs = config.modules.map(module => ({ key: module, config: config[module] }));
    return moduleConfigs.filter(module => module.config.enabled)
      .map((module, key) => (
        <Fieldset legend={module.config.label} cssClasses="rf-new-tab__group" key={key}>
          {module.config.forms.map(this.getItem.bind(this, module.config.formTemplates, module.key))}
        </Fieldset>
      ));
  }

  addTab(data) {
    let { toggleNewTabShown, addTab } = this.props;
    addTab(data);
    toggleNewTabShown(false);
  }

  render() {
    return (
      <div className="rf-new-tab">
        <ul className="rf-icons-bar" />
        <p className="rf-new-tab__description">Please select the tab type</p>
        {this.getItems()}
      </div>
    );
  }
}

NewTab.propTypes = {
  defaultForm: PropTypes.object.isRequired,
  addTab: PropTypes.func.isRequired,
  toggleNewTabShown: PropTypes.func.isRequired,
  setSettings: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  defaultForm: getDefaultForm(state),
});

const mapDispatchToProps = {
  addTab: TabsActions.addTab,
  toggleNewTabShown,
  setSettings,
  setNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTab);
