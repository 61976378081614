import * as types from './constants';
import * as pdeUtils from 'utils/api/platformDataExtension';
import * as geocoderUtils from 'utils/api/geocoder';
import { pdeApiConfig } from 'config/platformDataExtension';
import * as resultPanelActions from 'state/resultPanel/resultPanelActions';
import { requestErrorNotify } from 'state/notification/actions';

import utils from 'utils';

export function storeResult(data) {
  return { type: types.STORE_RESULT, data };
}

export function remove() {
  return { type: types.REMOVE };
}

export function inspectLink(coords, settings) {
  return async (dispatch) => {
    let { app_code, app_id } = settings || {};
    let params = { app_code, app_id };
    let linkId,
      functionalClass;

    const defaultErrorMessage = 'Failed to inspect link.';
    const handleError = (res) => {
      if (res.isError) {
        dispatch(requestErrorNotify(`${defaultErrorMessage} "${res.data}"`));
        return true;
      }

      return false;
    };
    try {
      const reverseGeocodeRes = await geocoderUtils.reverseGeocode(coords, params);
      if (handleError(reverseGeocodeRes)) {
        return;
      }
      let LINK_ID_PATH = 'data.response.view[0].result[0].location.mapReference.referenceId';
      let referenceId = utils.getObject(reverseGeocodeRes, LINK_ID_PATH);
      linkId = `-${referenceId}`;
      const pdeIndexRes = await pdeUtils.getIndex(linkId, params, 'LINK_ID', 'ROAD_GEOM_FCn');
      if (handleError(pdeIndexRes)) {
        return;
      }
      let layer = pdeIndexRes.data.Layers[0];
      functionalClass = layer.layer.split('_FC').slice(-1)[0];
      let index = {
        functionalClass,
        level: layer.level,
        tileXY: layer.tileXYs[0],
      };

      const pdeTilesRes = await pdeUtils.getTiles(pdeApiConfig.inspectLink, params, index);
      if (handleError(pdeTilesRes)) {
        return;
      }
      let parsedPDEResponse = pdeUtils.extractData(pdeTilesRes.data.Tiles, linkId, pdeApiConfig.inspectLink);

      let lats = pdeUtils.parseCoords(_.get(parsedPDEResponse, 'ROAD_GEOM_FC.LAT'));
      let lngs = pdeUtils.parseCoords(_.get(parsedPDEResponse, 'ROAD_GEOM_FC.LON'));
      let shape = [];
      utils.range(lats.length).forEach(index => {
        shape.push(lats[index]);
        shape.push(lngs[index]);
      });
      let flags = pdeUtils.parseFlags(parsedPDEResponse.LINK_ATTRIBUTE_FC);
      let linkInfo = {
        linkId,
        shape,
        flags,
        functionalClass,
        roadName: _.get(parsedPDEResponse, 'ROAD_GEOM_FC.NAME'),
        speedLimit: _.get(parsedPDEResponse, 'SPEED_LIMITS_FC.TO_REF_SPEED_LIMIT'),
        length: _.get(parsedPDEResponse, 'LINK_FC.LINK_LENGTH'),
        pdeResponse: parsedPDEResponse,
      };
      let data = { data: { response: { link: [linkInfo] } }, isError: pdeTilesRes.isError };
      dispatch(storeResult({ response: data }));
      dispatch(resultPanelActions.setVisibility(true));
    } catch (err) {
      dispatch(requestErrorNotify(defaultErrorMessage));
    }
  };
}
