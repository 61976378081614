import { validateLat, validateLon } from 'utils/validation/coordsValidation';
import { validateLinkId, validateSpot } from 'utils/validation/linkValidation';
import formConfig from 'config/form';
import utils from 'utils';
import numericValidation from './numericValidation';

const config = formConfig();
const numericValidation_ = numericValidation();

export default function waypointValidation(value) {
  let splitValue = value.split('!');
  let waypointCategory = !isWaypointCategory(splitValue[0]) ? 'geo' : splitValue[0];

  switch (waypointCategory) {
    case 'geo':
      return geoWaypointValidation(splitValue);
    case 'link':
      return linkWaypointValidation(splitValue);
    case 'street':
      return streetWaypointValidation(splitValue);
    default:
      throw new Error(`Unsupported waypoint category: '${waypointCategory}'`);
  }
}

function geoWaypointValidation(splitValue) {
  let errors = [];
  let mainValue;
  if (splitValue.length > 2) {
    let [category, type] = splitValue;
    if (!isWaypointCategory(category)) {
      errors.push(`Unsupported value for waypoint category: '${category}'`);
    }
    errors = errors.concat(waypointTypeValidation(type));
    mainValue = splitValue[2];
  } else if (splitValue.length === 2) {
    let [categoryOrType] = splitValue;
    if (!isWaypointCategory(categoryOrType) && !isWaypointType(categoryOrType)) {
      errors.push(`Unsupported value for waypoint category or type: '${categoryOrType}'`);
    }
    mainValue = splitValue[1];
  } else {
    mainValue = splitValue[0];
  }

  // eslint-disable-next-line no-unused-vars
  let [position, transitRadius, label, ...other] = mainValue.split(';');
  errors = errors.concat(waypointPositionValidation(position));
  if (!utils.isEmpty(transitRadius) && !utils.isFinite(+transitRadius)) {
    errors.push(`Unsupported value for transit radius: '${transitRadius}'`);
  }
  const headingValidation = numericValidation({ min: 0, max: 360, key: 'heading' });
  const heading = other.join(';');

  return errors.concat(headingValidation(heading));
}

function linkWaypointValidation(splitValue) {
  let errors = [];
  let linkPosition;
  splitValue = splitValue.slice(1);
  if (splitValue.length === 3) {
    let [type, displayPositionLabel] = splitValue;
    errors = errors.concat(waypointTypeValidation(type));
    errors = errors.concat(waypointDisplayPositionLabelValidation(displayPositionLabel));
    linkPosition = splitValue[2];
  } else if (splitValue.length === 2) {
    let typeOrDisplayPositionLabel = splitValue[0];
    let typeErrors = waypointTypeValidation(typeOrDisplayPositionLabel);
    if (!utils.isEmpty(typeErrors)) {
      errors = errors.concat(waypointDisplayPositionLabelValidation(typeOrDisplayPositionLabel));
    }
    linkPosition = splitValue[1];
  } else {
    linkPosition = splitValue[0];
  }
  let [linkId, spot] = linkPosition.split(',');
  if (!validateLinkId(linkId)) {
    errors.push(`Invalid link id: '${linkId}'`);
  }
  if (spot !== undefined && !validateSpot(spot)) {
    errors.push(`Invalid spot value: '${spot}'`);
  }
  return errors;
}

function streetWaypointValidation(splitValue) {
  let errors = [];
  let streetPosition;
  splitValue = splitValue.slice(1);

  if (splitValue.length > 2) {
    let [type, displayPositionLabel] = splitValue;
    errors = errors.concat(waypointTypeValidation(type));
    errors = errors.concat(waypointDisplayPositionLabelValidation(displayPositionLabel));
    streetPosition = splitValue[2];
  } else if (splitValue.length === 2) {
    let typeOrDisplayPositionLabel = splitValue[0];
    let typeErrors = waypointTypeValidation(typeOrDisplayPositionLabel);
    if (!utils.isEmpty(typeErrors)) {
      errors = errors.concat(waypointDisplayPositionLabelValidation(typeOrDisplayPositionLabel));
    }
    streetPosition = splitValue[1];
  } else {
    streetPosition = splitValue[0];
  }
  let [position, ...other] = streetPosition.split(';');
  errors = errors.concat(waypointPositionValidation(position));
  if (other.length > 1) {
    errors.push(`Street name shouldn't contain ';' character`);
  }
  return errors;
}

function isWaypointCategory(value) {
  return config.routeWaypointCategories.indexOf(value) !== -1;
}

function isWaypointType(value) {
  return config.routeWaypointTypes.indexOf(value) !== -1;
}

function waypointPositionValidation(value) {
  let [lat, lng, ...altitude] = value.split(',');
  let errors = [];
  if (utils.isEmpty(lat) || !validateLat(+lat)) {
    errors.push('Invalid latitude');
  }
  if (utils.isEmpty(lng) || !validateLon(+lng)) {
    errors.push('Invalid longitude');
  }
  if (altitude.length > 1 ||
    (altitude.length === 1 && (utils.isEmpty(altitude[0]) || !utils.isFinite(+altitude[0])))) {
    errors.push('Invalid altitude');
  }

  return errors;
}

function waypointTypeValidation(value) {
  let errors = [];
  const [type, stopOverDuration] = value.split(',');
  if (!isWaypointType(type)) {
    errors.push(`Unsupported value for waypoint type: '${value}'`);
  }
  if (stopOverDuration) {
    errors = errors.concat(numericValidation_(stopOverDuration));
  }
  return errors;
}

function waypointDisplayPositionLabelValidation(value) {
  let errors = [];
  let [displayPosition, ...other] = value.split(';');
  if (!utils.isEmpty(displayPosition)) {
    errors = errors.concat(waypointPositionValidation(displayPosition));
  }
  if (other.length > 1) {
    errors.push(`Label shouldn't contain ';' character`);
  }
  return errors;
}
