import Color from 'color';
import tabColors from 'config/tabColors';

export default ({ currentRoute = 0, title = '', isWaypoint, color, strokeColor, fontSize = 15 }) => {
  let fillColor = tabColors[currentRoute % 8].primary;

  if (isWaypoint) {
    fillColor = Color(fillColor).lighten(0.1).hexString();
  }

  if (color) {
    fillColor = color;
  }

  if (!strokeColor) {
    strokeColor = fillColor;
  }

  return `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="28">
      <path stroke="${strokeColor}" stroke-width="1px" fill="${fillColor}"
            d="M11.50000010727088,0.22302245725223135 c-6.223700517201001,0 -11.268858545860132,5.045158028659132 \
-11.268858545860132,11.268858545860132 c0,6.320104810742259 5.478977349594789,10.488787137288792 \
11.268858545860132,16.285095286456887 c5.789881196265344,-5.797111518280938 11.268858545860132,-9.964990475714629 \
11.268858545860132,-16.285095286456887 C22.768858653131016,5.268180485911363 17.72370062447188,0.22302245725223135 \
11.50000010727088,0.22302245725223135 "
            id="svg_1" class=""/>
      <text x="11.5" y="${10 + (fontSize / 2)}" font-size="${fontSize}px" font-family="Arial" font-weight="bold"
            text-anchor="middle" fill="white">${title}</text>
  </svg>`;
};
