import { combineReducers } from 'redux';

import fieldsProxy from '../../common/fields/proxy';
import fieldsValidation from './fields/validation';
import defaultFieldsState from './fields/defaultState';

import points from './points/reducer';
import avoidAreasProxy from '../../common/avoidAreas/proxy';
import isolineWaypointsProxy from '../../common/isolineWaypoints/proxy';
import formsEnum from 'config/formsEnum';
import selectedEntity from './selectedEntity/reducer';

let currentForm = formsEnum.CALCULATE_MATRIX;
export default combineReducers(Object.assign({}, {
  fields: fieldsProxy({
    currentForm,
    hasTruckFields: true,
    defaultState: defaultFieldsState,
    validation: fieldsValidation,
  }),
  points,
  isolineWaypoints: isolineWaypointsProxy(currentForm),
  avoidAreas: avoidAreasProxy(currentForm),
  selectedEntity,
}));
