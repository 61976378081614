import FormBase from '../../_Base';
import IntermodalMarkers from './IntermodalMarkers';
import IntermodalRoutes from './IntermodalRoutes';
import utils from 'utils';

export default class Intermodal extends FormBase {

  initModules() {
    return {
      markers: new IntermodalMarkers(this.map, this.state),
      routes: new IntermodalRoutes(this.map, this.state),
    };
  }

  process(props, nextProps, forceRender = false) {
    super.process(props, nextProps, forceRender);

    const isSelectedTab = nextProps.selectedTab === this.state.index;

    if (forceRender && isSelectedTab) {
      this.setViewBounds(true, nextProps);
    }

    if (forceRender ||
      utils.isPropChanged(this.props, nextProps, 'tabData.response.data.response') ||
      utils.isPropChanged(this.props, nextProps, 'tabData.formData.currentRoute')) {
      let { tabData } = nextProps;
      this.modules.routes.setAdditionalData(_.get(tabData, 'formData.currentRoute'));
      let response = _.get(tabData, 'response.data', {});
      this.modules.routes.process(response);
    }

    if (!nextProps.tabData.response.isError) {
      const currentRoute = nextProps.tabData.formData.currentRoute,
            oldHighlighted = this.props.tabData.formData.highlightedRoute,
            nextHighlighted = nextProps.tabData.formData.highlightedRoute;
      if (oldHighlighted !== nextHighlighted) {
        const palette = nextProps.tabData.tabColorPalette;
        this.modules.routes.highlight(oldHighlighted, nextHighlighted, currentRoute, palette);
      }
    }

    if (forceRender || this.shouldUpdateCurrent(nextProps)) {
      this.setProps(nextProps);
      let waypoints = [
        _.get(nextProps, 'tabData.formData.fields.origin'),
        _.get(nextProps, 'tabData.formData.fields.destination'),
      ];
      this.modules.markers.process(waypoints.map(point => ({ coords: point })));
      return;
    }

    this.setProps(nextProps);
  }

  shouldUpdateCurrent(nextProps) {
    let isHistoryChanged = this.props.historyLength !== nextProps.historyLength;
    return isHistoryChanged ||
      utils.isPropChanged(this.props, nextProps, 'formData.fields.origin') ||
      utils.isPropChanged(this.props, nextProps, 'formData.fields.destination') ||
      utils.isPropChanged(this.props, nextProps, 'selectedTab');
  }

  setViewBounds(force, props) {
    super.setViewBounds(this.modules.markers.getGroup(props), force);
  }

  _handleDragging(method, args, modules = {}) {
    let targetData = args[0],
        _modules = Object.assign({
          MAIN: this.modules.markers,
        }, modules);
    this.applyModule(_modules[targetData.type], method, args);
  }

  onDragStart(...args) {
    this._handleDragging('onDragStart', args);
  }

  onDragEnd(...args) {
    this._handleDragging('onDragEnd', args);
  }
}
