import * as types from './constants';
import defaultState from './defaultState';

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.SET_DATA: {
      const { type, data } = action.payload;
      return {
        ...state,
        [type]: { ...state[type], ...data },
      };
    }
    case types.SET_TYPE:
      return {
        ...state,
        type: action.payload.type,
      };
    case types.SET_IS_ACTIVE:
      return {
        ...state,
        isActive: action.payload.isActive,
      };
    default:
      return state;
  }
}
