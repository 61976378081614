import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import copyToClipboard from 'copy-to-clipboard';

import utils from 'utils';
import settingsUtils from 'utils/settings';

import { asyncCopyAddress } from 'state/asyncCopy/actions';

import './routing/styles/contextMenu.scss';
import { setInitCoords } from '../../config/map';

class ContextMenuContainer extends Component {
  labels = {
    copyAddress: 'Copy Address',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {
      data: nextProps.data,
    };

    if (prevState.data !== null && !utils.isEqual(prevState.data, nextProps.data)) {
      state.isHidden = !Object.keys(nextProps.data).length;
      state.addressToCopy = null;
    }

    return state;
  }

  constructor(props) {
    super(props);
    this.hide = this.hide.bind(this);
  }

  state = {
    data: null,
    isHidden: true,
    addressToCopy: null,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.hide, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.hide, false);
  }

  onCopyAddress = () => {
    copyToClipboard(this.state.addressToCopy);
    this.props.textCopiedNotify('Address');
    this.hide();
  };

  getMenuItemEl(data, idx) {
    let { label, iconLabel, fn, children, isDisabled = false } = data;
    let cssClasses = classnames('rf-context-menu__list-item', {
      'rf-context-menu__list-item_disabled': isDisabled,
    });
    let submenu = Array.isArray(children) ? this._generateMenuList(children, { isSubmenu: true }) : '';
    return (
      <li className={cssClasses} key={idx} onMouseDown={isDisabled ? this.stay : fn}>
        <span className={`rf-context-menu__list-icon rf-context-menu__list-icon_${iconLabel}`} />
        {label}
        {submenu}
      </li>
    );
  }

  getMenuItems() {
    const copyAddressSubmenu = [{
      label: `${this.state.addressToCopy === '...' ? '' : 'Copy: '}${this.state.addressToCopy}`,
      iconLabel: 'C',
      fn: this.onCopyAddress,
    }];

    const copyAddress = { label: this.labels.copyAddress, iconLabel: 'C', fn: ::this.copyAddress };
    if (this.state.addressToCopy !== null) {
      copyAddress.children = copyAddressSubmenu;
    }

    let copySubmenu = [
      { label: 'Copy Latlon', iconLabel: 'C', fn: ::this.copyLatlon },
      copyAddress,
    ];

    return [
      { label: 'Inspect Link', iconLabel: 'L', fn: ::this.inspectLink },
      { label: 'Copy...', iconLabel: 'C', fn: ::this.stay, children: copySubmenu },
      { label: 'Set as Default Map Center', iconLabel: 'D', fn: this.setDefaultMapCenter },
    ];
  }

  getMenuContainer(data) {
    let { screenX, screenY } = data;
    let itemsList = this._generateMenuList(this.getMenuItems(data));
    return (
      <div style={{ left: screenX, top: screenY }} className="rf-context-menu">
        <div className="rf-context-menu__inner">
          {itemsList}
        </div>
      </div>
    );
  }

  setDefaultMapCenter = () => {
    const { data: { geo }, setNotification } = this.props;
    setInitCoords(geo);
    setNotification({
      message: `Default map center has been set.`,
      impact: 'positive',
      autoDismiss: 2,
    });
  }

  _generateMenuList(items, args = {}) {
    let itemsListEl = items.map(::this.getMenuItemEl);
    let cssClasses = classnames('rf-context-menu__list', {
      'rf-context-menu__list_submenu': !!args.isSubmenu,
    });
    return (
      <ul className={cssClasses}>
        {itemsListEl}
      </ul>
    );
  }

  _getSettings(module = this.props.module, form = this.props.currentForm) {
    return settingsUtils.getPresetSettings(module, form, this.props.settingsData);
  }

  inspectLink() {
    let { data, inspectLinkSettings } = this.props;
    const { appId, appCode } = inspectLinkSettings;
    this.props.inspectLink(data.geo, { app_id: appId, app_code: appCode });
  }

  copyLatlon() {
    let { data, textCopiedNotify } = this.props;
    copyToClipboard(data.geo);
    textCopiedNotify('Latlon');
    this.hide();
  }

  copyAddress() {
    let { data, textCopiedNotify, requestErrorNotify } = this.props;
    let settings = this._getSettings(this.props.module, this.props.currentForm);
    asyncCopyAddress(data.geo, settings).then(() => textCopiedNotify('Address'))
      .catch(err => requestErrorNotify(err));
    this.hide();
  }

  stay(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }

  hide() {
    this.setState({
      isHidden: true,
      addressToCopy: false,
    });
  }

  render() {
    let menuEl = this.state.isHidden ? '' : this.getMenuContainer(this.props.data);
    return (
      <div>
        {menuEl}
      </div>
    );
  }
}

ContextMenuContainer.propTypes = {
  data: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  settingsData: PropTypes.object.isRequired,
  inspectLink: PropTypes.func.isRequired,
  textCopiedNotify: PropTypes.func.isRequired,
  requestErrorNotify: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  inspectLinkSettings: PropTypes.object.isRequired,
};

export default ContextMenuContainer;
