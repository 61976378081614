import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as appSettingsActions from 'state/appSettings/base/actions';
import { getPreset } from 'state/settingsPreset';

import SettingsContainer from '../settingsPanel/SettingsContainer';
import SearchBar from './SearchBar';
import AutoRefreshToggler from './AutoRefreshToggler';
import AutoZoomToggler from './AutoZoomToggler';
import UIZoom from './UIZoom';

import './styles/nav.scss';
import { getIsAutoRefreshOn, getAppSettings } from 'state/appSettings/base';
import adjustLUIStyle from 'utils/adjustLUIStyle';

class NavContainer extends Component {
    componentDidMount() {
      adjustLUIStyle(document.querySelectorAll('.rf-new-refclient-link lui-button'), 'lui-button');
    }

    openNewRefclient = () => {
//////////////////////
////////////////////////////////////////////////////////////////
///////////////
      window.open('https://demo.routing.ext.here.com', '_blank');
////////////////
    }

    render() {
      const newRefClientLinkEl = (
        <div className="rf-new-refclient-link">
          <lui-button onClick={this.openNewRefclient}>New RefClient</lui-button>
        </div>
      );
      return (
        <nav className="rf-navbar">
          <div className="rf-navbar__logo"> </div>
          {newRefClientLinkEl}
          <SearchBar />
          <AutoRefreshToggler setSettings={this.props.setSettings} isAutoRefreshOn={this.props.isAutoRefreshOn} />
          <AutoZoomToggler setSettings={this.props.setSettings} isAutoZoomOn={this.props.isAutoZoomOn} />
          <UIZoom value={this.props.uiSize} setSettings={this.props.setSettings} />
          {this.props.preset && <SettingsContainer />}
          <a className="rf-help-link" href="https://developer.here.com/" target="blank">Help</a>
        </nav>
      );
    }
}

NavContainer.propTypes = {
  isAutoRefreshOn: PropTypes.bool.isRequired,
  isAutoZoomOn: PropTypes.bool.isRequired,
  uiSize: PropTypes.number.isRequired,
  setSettings: PropTypes.func.isRequired,
  preset: PropTypes.object,
};

function mapStateToProps(state) {
  const appSettings = getAppSettings(state);
  return {
    isAutoRefreshOn: getIsAutoRefreshOn(state),
    isAutoZoomOn: appSettings.isAutoZoomOn,
    uiSize: appSettings.uiSize,
    preset: getPreset(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSettings: bindActionCreators(appSettingsActions.setSettings, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavContainer);
