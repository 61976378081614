import React from 'react';
import { parseString } from 'xml2js';
import { pointTemplate } from 'state/tabs/tab/formData/common/waypoints/defaultState';
import { isCoordsValid } from 'utils/validation/coordsValidation';
import { WAYPOINT_TYPES } from './importExportMapping';
import PropTypes from 'prop-types';
import routingFields from 'state/tabs/tab/formData/routingTab/routingForm/fields/defaultState';
import truckFields from 'state/tabs/tab/formData/common/fields/truckProfileDefaultState';
import { parseDeparture, parseRouteOptions } from './importExportHelpers';

const defaultFields = { ...routingFields, ...truckFields };

export default class ImportRoute extends React.Component {
  static propTypes = {
    currentForm: PropTypes.string.isRequired,
    setWaypoints: PropTypes.func.isRequired,
    onImport: PropTypes.func.isRequired,
  }

  readFile = (e) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      parseString(e.target.result, (err, res) => {
        if (err) {
          console.error(err);
        }
        const rawWaypoints = _.get(res, 'route_plan.waypoint', []);
        const waypoints = rawWaypoints.map((waypoint, index) => {
          const { lat, lng, type } = waypoint.$;
          const value = `${lat},${lng}`;
          return {
            ...pointTemplate,
            coords: { value, isValid: isCoordsValid(value) },
            type: WAYPOINT_TYPES[type],
            category: 'geo',
            isWaypoint: index !== 0 && index !== rawWaypoints.length - 1,
          };
        });

        this.props.setWaypoints({ waypoints, currentForm: this.props.currentForm });

        let fields = { ...defaultFields };
        fields = parseDeparture(res, fields);
        fields = parseRouteOptions(res, fields);

        this.props.onImport({ currentForm: this.props.currentForm, fields });
      });
    };
    reader.readAsText(e.target.files[0]);
  }

  render() {
    return (
      <div>
        <h3>Import Route: </h3>
        <input type="file" onChange={this.readFile} />
      </div>
    );
  }
}
