import React from 'react';
import ReactDOM, { render } from 'react-dom';

import * as mapMarkers from '../utils/MarkersHelper';
import ModuleBase from './_Base';

export default class MarkersBase extends ModuleBase {
  applyLabel(marker, point, color) {
    let group = this.getGroup();

    marker.addEventListener('pointerenter', () => {
      if (this.isLabelShown || !point.userLabel) {
        return;
      }

      let [lat, lng] = point.coords.value.split(',');
      let scrCoords = this.map.geoToScreen({ lat, lng });
      let icon = mapMarkers.createIconForLabel(color);
      this.labelMarker = new H.map.Marker(this.map.screenToGeo(scrCoords.x + 11, scrCoords.y - 4), { icon });
      group.addObject(this.labelMarker);

      render(
        <div className="rf-marker-label" style={{ left: scrCoords.x, top: scrCoords.y }}>
          <div className="rf-marker-label__content" style={{ backgroundColor: color }}>{point.userLabel}</div>
        </div>,
        document.querySelector('.rf-marker-labels'),
      );
      this.isLabelShown = true;
    });

    let closeLabel = () => {
      if (!this.isLabelShown) {
        return;
      }
      ReactDOM.unmountComponentAtNode(document.querySelector('.rf-marker-labels'));
      group.removeObject(this.labelMarker);
      this.labelMarker.dispose();
      this.isLabelShown = false;
    };

    marker.addEventListener('pointerleave', closeLabel);
    marker.addEventListener('pointerdown', closeLabel);
    marker.addEventListener('wheel', closeLabel);
  }
}
