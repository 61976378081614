import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TransportTypeIcon from './TransportTypeIcon';
import CustomInput from 'shared/input';
import FormRow from 'shared/formRow';

import './styles/productsField.scss';

export class ProductsField extends Component {

  getIconsListEl() {
    return (this.props.value.publicTransportLine || []).map((data, idx) => {
      let transportType = data.type || 'notype',
        prefix = [data.companyName, data.lineName].filter(Boolean).join(' - '),
        tooltip = [prefix, `[${transportType}]`].filter(Boolean).join(' ');

      return (
        <TransportTypeIcon
          key={`transport-type-icon_${idx}`}
          transportType={transportType}
          tooltip={tooltip}
        />
      );
    });
  }

  render() {
    let IconsList = this.getIconsListEl();

    return (
      <FormRow isHidden={IconsList.length === 0}>
        <CustomInput
          cssClasses="rf-products-field"
          type="text"
          label={this.props.label}
          isReadonly
          value={IconsList}
        />
      </FormRow>
    );
  }
}

ProductsField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
};

export default ProductsField;
