export default function (color) {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="24">
    <defs>
      <mask id="maskedtext">
        <rect x="0px" y="0px" width="23px" height="28px" fill="white" />
        <path
          clip-rule="evenodd"
          transform="translate(-11.5, 0)"
          d="M11.50000010727088,0.22302245725223135 c-6.223700517201001,0 -11.268858545860132,5.045158028659132 \
-11.268858545860132,11.268858545860132 c0,6.320104810742259 5.478977349594789,10.488787137288792 \
11.268858545860132,16.285095286456887 c5.789881196265344,-5.797111518280938 11.268858545860132,-9.964990475714629 \
11.268858545860132,-16.285095286456887 C22.768858653131016,5.268180485911363 17.72370062447188,0.22302245725223135 \
11.50000010727088,0.22302245725223135"
        />
      </mask>
    </defs>
    <rect x="0px" y="0px" width="23px" height="28px" fill="${color}" mask="url(#maskedtext)" />
  </svg>`;
}
