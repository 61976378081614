import routing from './routing';
////////////////
//////////////////////////////
////////////////////////////////
////////////////////////////////////
////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////
//////////
import intermodal from './transit/intermodalApi';

export default {
  routing,
//////////////////
/////////
//////////
////////////
//////
/////////////////////
/////////////
////////////
  transit: { intermodal },
};
