import { isWaypointPerformed } from '../../helpers';

export default function exec(formData) {
  let waypoint = formData.waypoints[0];
  if (!isWaypointPerformed(waypoint)) {
    return false;
  }
  let range = formData.fields.range;
  return range.isValid && range.value !== '';
}
