import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Fieldset from 'shared/fieldset';
import CheckboxList from 'shared/checkboxList';

export default class MultiCheckboxes extends Component {
  onChange(index, e) {
    let { dataKey } = this.props;
    let key = dataKey === '' ? '' : `${dataKey}.`;
    this.props.onChange(key + index, e);
  }

  render() {
    let { values, config, legend } = this.props;
    let checkboxParams = config.map(conf => Object.assign({}, conf, {
      isChecked: values[conf.value],
      onChange: this.onChange.bind(this, conf.value),
    }));

    let checkboxListNode = <CheckboxList params={checkboxParams} />;
    if (legend === '') {
      return checkboxListNode;
    }

    return <Fieldset legend={legend}>{checkboxListNode}</Fieldset>;
  }
}

MultiCheckboxes.defaultProps = {
  values: {},
  config: [],
  legend: '',
  dataKey: '',
};

MultiCheckboxes.propTypes = {
  values: PropTypes.object,
  config: PropTypes.array,
  legend: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dataKey: PropTypes.string,
};
