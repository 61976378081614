import utils from 'utils';
import * as types from './constants';
import { assignTabDataAndDispatch } from '../../actions';
import { getSelectedTabs } from '../../../../index';
import { getForm } from '../../../form';
import formsEnum from '../../../../../../config/formsEnum';

export const RoRToAPIValuesMap = {
  [formsEnum.CALCULATE_ROUTE]: {
    isMaxChargeActive: {
      rorValue: 'maxcharge',
      apiValue: 'batteryparameters.maxcharge',
    },
    isInitialChargeActive: {
      rorValue: 'initialcharge',
      apiValue: 'batteryparameters.initialcharge',
    },
    isMinChargeAtStopActive: {
      rorValue: 'minchargeatstop',
      apiValue: 'batteryparameters.minchargeatstop',
    },
  },
  [formsEnum.OLS]: {
    isMaxChargeActive: {
      rorValue: 'maxcharge',
      apiValue: 'maxCharge',
    },
    isInitialChargeActive: {
      rorValue: 'initialcharge',
      apiValue: 'initialCharge',
    },
    isMinChargeAtStopActive: {
      rorValue: 'minchargeatstop',
      apiValue: 'minChargeAtChargingStation',
    },
  },
};

export function setData(data = {}) {
  return (dispatch, getState) => {
    data = { ...data };
    const state = getState();
    const tabData = getSelectedTabs(state);
    const currentForm = getForm(state);

    Object.keys(data).forEach(key => {
      if (Object.keys(RoRToAPIValuesMap[currentForm]).indexOf(key) > -1 && data[key] === false) {
        data[RoRToAPIValuesMap[currentForm][key].rorValue] =
          +utils.getObject(tabData.formData.fields, RoRToAPIValuesMap[currentForm][key].apiValue, 0).value;
      }
    });

    dispatch(assignTabDataAndDispatch({
      type: types.SET_DATA,
      data
    }));
  };
}
