import * as types from './constants';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case types.STORE_RESULT: {
      let { response } = action.data || {};
      return response;
    }
    case types.REMOVE:
      return {};
    default:
      return state;
  }
}
