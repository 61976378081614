export function isLinkValid(link = '') {
  if (!link) return true;

  let linkParams = link.split(',');

  if (linkParams.length === 1) {
    return validateLinkId(linkParams[0]);
  } else if (linkParams.length > 1) {
    return validateLinkId(linkParams[0]) && validateSpot(linkParams[1]);
  }
  return false;
}

export function isLinkListValid(links = '') {
  if (!links) return true;

  return links.split(',').every(link => validateLinkId(link));
}

export function validateLinkId(linkId) {
  return /^[-+*]\d+$/.test(linkId);
}

export function validateSpot(spot) {
  return (spot >= 0) && (spot <= 1) && !/ +/.test(spot);
}
