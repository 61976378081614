import React from 'react';
import ReactDOM from 'react-dom';

import Item from './Item';
import CustomAutocomplete from 'shared/autocomplete';
import FormRow from 'shared/formRow';
import { isStreetEmpty } from 'state/tabs/tab/formData/common/waypoints/defaultState';

import utils from 'utils';

import './styles/streetItem.scss';

class StreetItem extends Item {
  static getDerivedStateFromProps(nextProps, prevState) {
    let cCoords = Item.getCoords({ data: prevState.data });
    let nCoords = Item.getCoords(nextProps);

    if ((cCoords !== nCoords || (utils.isEmpty(nextProps.data.streetSuggestions) && nCoords)) && (cCoords || nCoords)) {
      nextProps.onGetStreetAddress(nCoords);
    }

    return {
      data: nextProps.data,
    };
  }

  getMainContent() {
    let { data = {} } = this.props,
        { coords = {} } = data;
    return (
      <div>
        {super.getMainContent()}
        {this.renderInput('coords', coords.value, 'Coordinates', coords.isValid)}
        {this.renderStreets()}
      </div>
    );
  }

  onSelect(value) {
    this.props.onChange({
      street: {
        name: value.label,
        value: value.coords,
      },
    });
  }

  onChangeStreet(name) {
    let { value } = this.props.data.coords;
    this.props.onChange({
      street: { name, value },
    });
  }

  renderStreets() {
    let { streetSuggestions = {}, street } = this.props.data;
    let streets = Object.keys(streetSuggestions)
      .filter(suggestion => suggestion.indexOf(street.name || '') === 0)
      .map((streetName, index) => ({
        label: streetName,
        coords: streetSuggestions[streetName],
        key: index,
      }));

    return (
      <FormRow cssClasses="rf-street-item">
        <label className="rf-input__caption">Street address</label>
        <CustomAutocomplete
          suggestions={streets}
          isValid={!!street.name}
          placeholder="Street name"
          onSelect={::this.onSelect}
          onChange={::this.onChangeStreet}
          value={street.name}
          ref="customAutocomplete"
        />
      </FormRow>
    );
  }

  componentDidMount() {
    let cCoords = Item.getCoords(this.props);
    let { index, route } = this.props;
    if (!cCoords && this.props.route) {
      let routeWaypointPos = utils.getObject(route, `waypoint[${index}].mappedPosition`) || {};
      let linkCoords = `${routeWaypointPos.latitude},${routeWaypointPos.longitude}`;
      this.onChange('coords', { target: { value: linkCoords } });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.shouldFocusStreet(prevProps)) {
      ReactDOM.findDOMNode(this.refs.customAutocomplete.refs.autocomplete.refs.input).focus();
    }
  }

  shouldFocusStreet(prevProps) {
    let pStreetSuggestions = prevProps.data.streetSuggestions;
    let cStreetSuggestions = this.props.data.streetSuggestions;

    // Don't focus if street has value set
    if (!isStreetEmpty(this.props.data.street)) {
      return false;
    }
    // Focus if prev street suggestions was empty and current have values
    if (utils.isEmpty(pStreetSuggestions) && !utils.isEmpty(cStreetSuggestions)) {
      return true;
    }
    // Or both have values but not equal
    return !utils.isEmpty(pStreetSuggestions) && !utils.isEmpty(cStreetSuggestions) &&
      !utils.isEqual(pStreetSuggestions, cStreetSuggestions);
  }

  getAdvancedContent() {
    let { data = {} } = this.props;
    let { displayPosition = {}, heading = {} } = data;

    return (
      <div>
        {super.getAdvancedContent()}
        {this.renderInput('displayPosition', displayPosition.value, 'Display Position',
          displayPosition.isValid)}
        {this.renderInput('heading', heading.value, 'Heading (degrees)', heading.isValid)}
        {this.renderInput('transitRadius', data.transitRadius, 'Transit Radius (meters)')}
      </div>
    );
  }
}

export default StreetItem;
