import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as TrafficOverlaysActions from 'state/map/trafficOverlays/actions';
import { TILE_TYPES } from 'state/map/tiles/constants';

import LayerToggle from '../forms/common/LayerToggle';
import TrafficOverlaysForm from './TrafficOverlaysForm';
import VectorTrafficOverlaysForm from './VectorTrafficOverlaysForm';
import utils from 'utils';

export class TrafficOverlays extends Component {
  constructor(...args) {
    super(...args);
    this.state = { formExpanded: false };
  }

  onToggle() {
    let isActive = utils.getObject(this.props, 'data.isActive');
    this.props.toggle(!isActive);
  }

  onToggleForm() {
    this.setState({
      formExpanded: !this.state.formExpanded,
    });
  }

  onChange = (type, prop, event) => {
    this.props.setData(type, { [prop]: event.target.value });
  };

  renderForm(isActive) {
    const currentData = this.props.data[this.props.data.type];
    const type = this.props.data.type;
    if (type === TILE_TYPES.RASTER) {
      return <TrafficOverlaysForm
        onChange={(...args) => this.onChange(type, ...args)}
        data={currentData}
        isActive={isActive}
        onToggle={::this.onToggle}
        onBlur={::this.onToggleForm}
        changeTrafficType={() => this.props.setType(TILE_TYPES.VECTOR)}
      />;
    } else {
      return <VectorTrafficOverlaysForm
        onChange={(...args) => this.onChange(type, ...args)}
        data={currentData}
        isActive={isActive}
        onToggle={::this.onToggle}
        onBlur={::this.onToggleForm}
        changeTrafficType={() => this.props.setType(TILE_TYPES.RASTER)}
      />;
    }
  }

  render() {
    let isActive = utils.getObject(this.props, 'data.isActive');
    let tooltipText = this.state.formExpanded ? undefined : 'Traffic Overlays';

    return <div>
      <div className="rf-map__traffic-overlays">
        <LayerToggle
          active={isActive}
          onChange={::this.onToggleForm}
          label="TO"
          tooltipText={tooltipText}
        />
      </div>
      {this.state.formExpanded && this.renderForm(isActive)}
    </div>;
  }
}

TrafficOverlays.propTypes = {
  setData: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    data: state.map.trafficOverlays,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    setData: bindActionCreators(TrafficOverlaysActions.setData, dispatch),
    setType: bindActionCreators(TrafficOverlaysActions.setType, dispatch),
    toggle: bindActionCreators(TrafficOverlaysActions.toggle, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrafficOverlays);
