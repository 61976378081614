import * as mapMarkers from '../../../utils/MarkersHelper';
import { createBoundsBox } from '../../../utils/PolylineHelper';

import ModuleBase from '../../../modules/_Base';
import { getBoundsRect } from '../../../utils';
import { getSearchResultsColor } from 'config/map';

export default class Marker extends ModuleBase {
  process(searchState, onContextMenu) {
    if (!searchState.results || searchState.results.length === 0) return;

    let selectedCoords = null;
    let suggestionsCoords = searchState.results.map(searchResult => {
      if (searchResult.selected) {
        selectedCoords = searchResult.coords;
      }
      return searchResult.coords;
    });

    let boundsRect = getBoundsRect(suggestionsCoords);
    suggestionsCoords.forEach((coords, index) => {
      let title = index + 1;
      let icon = mapMarkers.createMarkerIcon({ color: getSearchResultsColor(0.7), title });
      let iconHovered = mapMarkers.createMarkerIcon({ color: getSearchResultsColor(), title });
      let marker = mapMarkers.createMarker(coords, { icon });

      marker.addEventListener('contextmenu', ev => {
        ev.stopPropagation();
        ev.originalEvent.stopPropagation();

        onContextMenu(ev, coords);
      });
      marker.addEventListener('pointerenter', () => {
        marker.setIcon(iconHovered);
        this.map.getElement().style.cursor = 'pointer';
      });
      marker.addEventListener('pointerleave', () => {
        marker.setIcon(icon);
        this.map.getElement().style.cursor = '';
      });

      this.getGroup().addObject(marker);
    });

    if (!selectedCoords && suggestionsCoords.length > 1) {
      this.setViewBounds(boundsRect);
    } else {
      let [lat, lng] = selectedCoords || suggestionsCoords[0];
      this.map.setCenter({ lat, lng });
      this.map.setZoom(15);
    }
  }

  setViewBounds(coords) {
    let boundsBox = createBoundsBox(...coords);
    this.map.getViewModel().setLookAtData({
      bounds: boundsBox
    });
  }
}
