// We need this 'require' hack for running tests
// eslint-disable-next-line import/no-mutable-exports
let credentials = require('config/externalCredentials').default;
////////////////
////////////////////////////////////////////////////////////
//////////

credentials = _.merge(_.cloneDeep(credentials), window.CREDENTIALS || {});

export default credentials;
