import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CustomInput from 'shared/input';
import utils from 'utils';
import { validateKeyVal } from 'utils/url/validation';

import './styles/errorBlock.scss';

export class ErrorBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rawValue: props.rawValue,
      oldValue: props.rawValue,
      errors: props.errors,
    };
  }

  onRawValueChange(e) {
    let newRawValue = e.target.value;
    let { errorKey, module, form } = this.props;

    this.setState({
      rawValue: newRawValue,
      errors: validateKeyVal([errorKey, newRawValue], module, form),
    });
  }

  onBlur() {
    let { errorKey, onChange } = this.props;
    let { oldValue, rawValue } = this.state;
    if (oldValue !== rawValue) {
      onChange(errorKey, oldValue, rawValue);
    }
  }

  render() {
    let { errorKey } = this.props;
    let { errors, rawValue } = this.state;
    let errorMessages = errors.map((error, index) => (
      <li className="rf-error-block__list__item" key={index}>{error}</li>
    ));
    return (
      <div className="rf-error-block">
        <h3>{errorKey}</h3>
        <ul className="rf-error-block__list">
          {errorMessages}
        </ul>
        <CustomInput
          value={rawValue}
          onChange={::this.onRawValueChange}
          onBlur={::this.onBlur}
          isValid={utils.isEmpty(errors)}
        />
      </div>
    );
  }
}

ErrorBlock.propTypes = {
  errors: PropTypes.array.isRequired,
  rawValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorKey: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
};
