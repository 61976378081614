export default {
  routeDate: {
    key: '',
    value: '',
  },
  selectedManeuver: {
    shape: null,
    index: null,
  },
  selectedPoint: '',
  hoveredPoint: '',
  units: 'metric',
  lang: 'en',
  origin: {
    value: '',
    isValid: false,
  },
  destination: {
    value: '',
    isValid: false,
  },
  departureTime: '',
  alternatives: {
    value: '',
    isValid: true,
  },
  changes: {
    value: '',
    isValid: true,
  },
  pnrChangeStrategy: 'diverseChoices',
  intermodalModes: {
    car: false,
    taxi: true,
    bicycleShare: false,
    bicycle: true,
  },
  transitModes: {
    highSpeedTrain: true,
    intercityTrain: true,
    interRegionalTrain: true,
    regionalTrain: true,
    cityTrain: true,
    bus: true,
    ferry: true,
    subway: true,
    lightRail: true,
    privateBus: true,
    inclined: true,
    aerial: true,
    busRapid: true,
    monorail: true,
    flight: true,
  },
  olsReturn: ['polyline', 'actions', 'fares', 'intermediate', 'travelSummary'],
};
