import utils from 'utils';
import Routing from './routing/resultReducer';
import Matrix from './matrix/resultReducer';
import Isoline from './isoline/resultReducer';
import RouteInfo from './routeInfo/resultReducer';

////////////////
////////////////////////////////////////////

///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////

////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////

////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////
//////////
import OlsMergedApi from './olsMergedApi/resultReducer';
import Intermodal from './intermodal/resultReducer';

import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';
import { RESTORE_FROM_URL } from 'state/tabs/tab/formData/constants';

const routingResponses = {
  [formsEnum.CALCULATE_ROUTE]: Routing,
  [formsEnum.CALCULATE_MATRIX]: Matrix,
  [formsEnum.CALCULATE_ISOLINE]: Isoline,
  [formsEnum.GET_ROUTE_INFO]: RouteInfo,
//////////////////
///////////////////////////////////////////////////////
///////////////////////////////////
////////////
  [formsEnum.OLS]: OlsMergedApi,
};

////////////////
/////////////////////////
/////////////////////////////
//
//////////////////////////
//////////////////////////////////////////
//////////////////////////////////
//
////////////////////////////
//////////////////////////////////
//
//////////////////////
///////////////////////
//
/////////////////////////////////////
/////////////////////////////////
////////////////////////////////
//
/////////////////////////////
//////////////////////////////////////
//
//////////
const transitResponses = {
  [formsEnum.INTERMODAL]: Intermodal,
};

const response = {
  [modulesEnum.ROUTING]: routingResponses,
//////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////////
//////////////////////////////////
////////////
  [modulesEnum.TRANSIT]: transitResponses,
};

export default (state, action) => {
  switch (action.type) {
    case RESTORE_FROM_URL: {
      let { module, currentForm } = action.data;
      if (!utils.getObject(response, `${module}.${currentForm}`)) {
        return {};
      }
      return response[module][currentForm](undefined, {});
    }
    default: {
      let { module = modulesEnum.ROUTING, form = formsEnum.CALCULATE_ROUTE } = action.tabData || {};
      if (!utils.getObject(response, `${module}.${form}`)) {
        return {};
      }
      return response[module][form](state, action);
    }
  }
};
