import Tooltip from '../../shared/tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import './styles/reverseIcon.scss';

function ReverseIcon({ label, reverse }) {
  return (
    <Tooltip tooltip={label}>
      <i className="rf-reverse" onClick={reverse} />
    </Tooltip>
  );
}

ReverseIcon.propTypes = {
  label: PropTypes.string.isRequired,
  reverse: PropTypes.func.isRequired,
};

export default ReverseIcon;
