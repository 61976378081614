import { stringifyUrl, stringifyQuery, transformToPost } from 'utils/url';
import { fetchData } from 'state/tabs/tab/response/actions';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getModule } from 'state/tabs/tab/module';
import { getForm } from 'state/tabs/tab/form';
import { getSettingsData } from '../settingsPreset/index';
import settingsUtils from 'utils/settings';

export default store => next => action => {
  let result = next(action);

  if (action.data && action.data.needsRerender) {
    let state = store.getState(),
        currentForm = getForm(state),
        module = getModule(state),
        formData = getCurrentFormData(state),
        { fields = {} } = formData,
        settings = settingsUtils.getPresetSettings(module, currentForm, getSettingsData(state)),
        queryParams = stringifyQuery(formData, settings.urlQuery, module, currentForm),
        urlData = stringifyUrl(queryParams, settings);

    if (checkWaypoints(formData.waypoints)) {
      if (fields.usePostReq) {
        const { url, postData } = transformToPost(urlData.apiUrl);
        store.dispatch(fetchData(module, currentForm, url, undefined, postData));
      } else {
        store.dispatch(fetchData(module, currentForm, urlData.apiUrl));
      }
    }
  }

  return result;
};

function checkWaypoints(waypoints = []) {
  return waypoints.every(point => {
    let data = point[point.category === 'geo' ? 'coords' : 'link'] || {};
    return data.value && data.isValid;
  });
}
