import ifetch from 'isomorphic-fetch';
import fetchJsonp from 'fetch-jsonp';
import utils from 'utils';

// TODO: temporary demo data (uncomment to use test data)
// import apRouterDemoData from './apRouterDemoData';
// import olsApiDemoData from './olsApiDemoData';
// import matchRouteDemoData from './matchRouteDemoData';

function encodeUrl(url) {
  let requestArr = url.split('?'),
      params = (requestArr[1] || '').split('&'),
      encodedParams = params
      .filter(Boolean)
      .map(keyVal => {
        let pair = keyVal.split('=');
        pair[1] = encodeURIComponent(pair[1]);

        return pair.join('=');
      }).join('&');

  return [requestArr[0], encodedParams].filter(Boolean).join('?');
}

export default function fetch(apiUrl, jsonp = false, proxy = {}, postData, customHeaders) {
  let isError = false,
      isXml = false,
      headers = null,
      timestamp = new Date().getTime(),
      statusCode = null,
      statusText = '';
  let adjustErrorResponse = (res, isXml) => {
    if (isXml) {
      return xmlErrorParse(res);
    }
    return (utils.isObject(res.response) ? res.response : res);
  };

  let adjustSuccessResponse = json => {
    let timedelta = new Date().getTime() - timestamp;
    let serverTime = headers && headers.get('X-NLP-IRT');
    utils.setObject(json, 'response.time', timedelta);
    if (serverTime) {
      utils.setObject(json, 'response.serverTime', serverTime);
    }

    return json;
  };

  function handleResponse(res) {
    return new Promise((resolve) => {
      let data = isError ? adjustErrorResponse(res, isXml) : adjustSuccessResponse(res);
      resolve({ isError, data, statusCode, statusText });
    });
  }

  function handleError() {
    isError = true;
    statusCode = null;
    statusText = '';
    let data = {
      type: 'ServerUnavailableError',
      details: `The REST api endpoint is not available`,
    };
    return Promise.resolve(data);
  }

  // TODO: temporary demo data (uncomment to use test data)
  // if (apiUrl.indexOf('calculateapproachpath.json') > -1) {
  //   return Promise.resolve(apRouterDemoData);
  // }
  // if (apiUrl.indexOf('/routes/pedestrian') > -1) {
  //   return Promise.resolve(olsApiDemoData);
  // }
  // if (apiUrl.indexOf('matchroute.json') > -1) {
  //   return Promise.resolve(matchRouteDemoData);
  // }

  let fetchApiUrl;
  let reqOptions = {};
  if (postData) {
    reqOptions = {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: { 'Content-Type': 'application/json' },
    };
  }
  reqOptions.headers = { ...reqOptions.headers, ...customHeaders };
  if (proxy.url) {
    const [hostAndPath = '', params = ''] = apiUrl.split('?');
    const encodedParams = params.split('&').map((param) => {
      const [key, value] = param.split(/=(.*)/);
      return `${key}=${encodeURIComponent(value)}`;
    }).join('&');
    const encodedApiUrl = `${hostAndPath}?${encodedParams}`;
    const url = proxy.skipProxy ? `${encodedApiUrl}` :
      `${proxy.url}proxy?url=${encodeURIComponent(`${encodedApiUrl}`)}`;
    if (proxy.token) {
      fetchApiUrl = ifetch(url, {
        headers: { Authorization: `Bearer ${proxy.token}` },
      });
    } else {
      fetchApiUrl = ifetch(url, reqOptions);
    }
  } else if (jsonp) {
    return fetchJsonp(encodeUrl(apiUrl), { jsonpCallback: 'jsoncallback' })
      .then(response => response.json())
      .then(response => {
        isError = response.type === 'ApplicationError';
        statusCode = null;
        statusText = '';
        response.time = new Date().getTime() - timestamp;
        response.details = response.Details;
        return handleResponse({ response });
      })
      .catch(handleJsonpError);
  } else {
    fetchApiUrl = ifetch(encodeUrl(apiUrl), reqOptions);
  }

  return fetchApiUrl
    .then(response => {
      isError = !response.ok;
      headers = response.headers;
      statusCode = response.status;
      statusText = response.statusText;
      const contentType = headers ? headers.get('content-type') : null;
      if (contentType && contentType.indexOf('xml') !== -1) {
        isXml = true;
        return response.text();
      }
      if (response.status === 403) {
        return '';
      }
      return response.json();
    },
    handleError)
    .then(handleResponse)
    .catch(e => {
      console.error(e);
    });
}

export function xmlErrorParse(data) {
  let regexp = /<Details>(.*)<\/Details>/;
  let found = data.match(regexp);
  return found[1] || data;
}

function handleJsonpError() {
  let isError = true;
  let data = {
    type: 'ServerUnavailableError',
    details: `The REST api endpoint is not available`,
  };
  return Promise.resolve({ isError, data });
}
