export default {
  CALCULATE_ROUTE: 'CalculateRoute',
  CALCULATE_MATRIX: 'CalculateMatrix',
  CALCULATE_ISOLINE: 'CalculateIsoline',
  GET_ROUTE_INFO: 'GetRouteInfo',
//////////////////
///////////////////////
//////////////////////
///////////////////
////////////////////////////
////////////////////////////////
////////////////////////
/////////////
/////////////////////////////////////////////
////////////////////////////
////////////
  OLS: 'OLS',
  VISUALIZE_DATA_POINTS: 'VisualizeDataPoints',
  INTERMODAL: 'IntermodalRouting',
};
