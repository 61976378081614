import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import utils from 'utils';

import MultiCheckboxes from '../../common/MultiCheckboxes';

import FormRow from 'shared/formRow';
import ExpandFormRow from 'shared/expandFormRow';
import CustomSelect from 'shared/select';
import CustomInput from 'shared/input';
import RadioGroup from 'shared/radioGroup';

import CustomDateTime from 'shared/datetime';

import _RoutingFormBase from '../../common/_RoutingFormBase';
import ReverseIcon from '../../common/ReverseIcon';
import CheckboxAttributesSet from '../../common/CheckboxAttributesSet';

import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as NotificationActions from 'state/notification/actions';

import { getResponseData } from 'state/tabs/tab/response';
import { getCurrentFormData } from 'state/tabs/tab/formData';

import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';

import intermodalConfig from 'config/form/transitTab/intermodal';
import languagesList from 'config/languagesList';

import { isCoordValidAndDefined } from 'utils/validation/coordsValidation';

import './styles/intermodalForm.scss';

export class IntermodalRoutingForm extends _RoutingFormBase {

  componentDidMount() {
    this.dateTimeComponent.onDateTimeChange(moment());
  }

  getResponseTabContent(_, formConfig) {
    let { formData } = this.props,
        { fields = {} } = formData,
        { olsReturn } = fields;

    return (
      <div className="rf-return">
        <CheckboxAttributesSet
          label="Return options"
          options={formConfig.olsReturn}
          data={olsReturn}
          isExpanded
          onChange={e => this.onChange('olsReturn', e)}
        />
      </div>
    );
  }

  onCoordinateChange(key, e) {
    let newValue = utils.extractData(e);
    let value = {
      value: newValue,
      isValid: isCoordValidAndDefined(newValue),
    };
    this.props.filterValueUpdate({ key, currentForm: this.props.currentForm, value });
  }

  reverse() {
    const { formData: { fields: { origin, destination } = {} } = {} } = this.props;
    this.onChange('origin', destination);
    this.onChange('destination', origin, true);
  }

  getRequestTabContent(currentForm, formConfig) {
    let { formData, isAutoRefreshOn } = this.props;
    let { fields = {} } = formData,
      {
        origin,
        destination,
        departureTime,
        alternatives,
        changes,
        lang,
        units,
        intermodalModes,
        transitModes,
        pnrChangeStrategy,
      } = fields;

    let alternativesProps;
    let onChange = this.onChange.bind(this);
    if (isAutoRefreshOn) {
      const onChangeDebounced = _.debounce(onChange, 500);
      alternativesProps = { onChange: (e) => onChangeDebounced('alternatives', e) };
    } else {
      alternativesProps = { onChange: (e) => onChange('alternatives', e) };
    }

    return (
      <div>
        <FormRow cssClasses="rf-olsApiForm__origin">
          <div className="rf-olsApiForm__origin__reverse">
            <ReverseIcon label="Reverse origin, destination" reverse={::this.reverse} />
          </div>
          <CustomInput
            label="Origin"
            value={origin.value}
            isValid={origin.isValid}
            onBlur={this.onCoordinateChange.bind(this, 'origin')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Destination"
            value={destination.value}
            isValid={destination.isValid}
            onBlur={this.onCoordinateChange.bind(this, 'destination')}
            blurOnEnter
          />
        </FormRow>
        <FormRow cssClasses="rf-route-time">
          <span className="rf-datetime__caption">Departure time</span>
          <CustomDateTime
            ref={(customDateTime) => {
              this.dateTimeComponent = customDateTime;
            }}
            value={departureTime.split('.')[0]}
            onChange={e => this.onChange('departureTime', e.split('.')[0])}
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Alternatives"
            value={alternatives.value}
            isValid={alternatives.isValid}
            type="number"
            blurOnEnter
            {...alternativesProps}
          />
        </FormRow>
        <FormRow>
          <CustomInput
            label="Changes"
            value={changes.value}
            isValid={changes.isValid}
            type="number"
            blurOnEnter
            onChange={e => this.onChange('changes', e)}
          />
        </FormRow>
        <ExpandFormRow
          label="Intermodal Options"
          cssClasses="rf-expanded-form__no-border"
          isExpanded
        >
          <MultiCheckboxes
            values={intermodalModes}
            config={intermodalConfig.intermodalModes}
            onChange={::this.onChange}
            dataKey="intermodalModes"
          />
        </ExpandFormRow>

        <ExpandFormRow
          label="Transit Modes"
          cssClasses="rf-grey-box rf-expanded-form__no-border"
        >
          <MultiCheckboxes
            values={transitModes}
            config={intermodalConfig.transitModes}
            onChange={::this.onChange}
            dataKey="transitModes"
          />
        </ExpandFormRow>
        <ExpandFormRow
          label="Advanced Parameters"
          cssClasses="rf-grey-box rf-advanced-params"
          isExpanded
        >
          <FormRow>
            <CustomSelect
              label="Park & Ride Strategy"
              value={pnrChangeStrategy}
              options={intermodalConfig.pnrChangeStrategy}
              onChange={e => this.onChange('pnrChangeStrategy', e)}
            />
          </FormRow>
          <FormRow>
            <RadioGroup
              label="Units"
              options={formConfig.units}
              value={units}
              onChange={this.onChange.bind(this, 'units')}
            />
          </FormRow>
          <FormRow>
            <CustomSelect
              label="Language"
              value={lang}
              options={languagesList}
              onChange={e => this.onChange('lang', e)}
            />
          </FormRow>
        </ExpandFormRow>
      </div>
    );
  }

  render() {
    let content = super.render();
    return (
      <div className="rf-form-content rf-transit-form">
        {content}
      </div>
    );
  }
}

IntermodalRoutingForm.propTypes = {
  formData: PropTypes.object.isRequired,
  settingsData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    responseData: getResponseData(state),
    formData: getCurrentFormData(state),
    settingsData: getSettingsData(state),
    module: getModule(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntermodalRoutingForm);
