import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BaseContextMenu from '../../_ContextMenu';
import { inspectLink } from 'state/inspectLink/actions';
import * as NotificationActions from 'state/notification/actions';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import { getSettingsData } from 'state/settingsPreset';
import { LOCATION_TYPES } from 'state/tabs/tab/formData/routingTab/approachPathRouterForm/fields/constants';
import { getInspectLinkSettings } from 'state/appSettings/inspectLink/reducer';

class ContextMenuContainer extends BaseContextMenu {
  setLocation() {
    this.props.filterValueMultiUpdate(this.props.currentForm, {
      locationType: LOCATION_TYPES.GEO,
      locationGeo: this.props.data.geo,
    });
  }

  getMenuItems(data) {
    let items = [
      { label: 'Set Location', iconLabel: 'A', fn: ::this.setLocation },
    ];
    return items.concat(super.getMenuItems(data));
  }
}

ContextMenuContainer.defaultProps = {
  data: {},
};

ContextMenuContainer.propTypes = {
  inspectLink: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  settingsData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  filterValueMultiUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentForm: getForm(state),
    module: getModule(state),
    settingsData: getSettingsData(state),
    inspectLinkSettings: getInspectLinkSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inspectLink: bindActionCreators(inspectLink, dispatch),
    textCopiedNotify: bindActionCreators(NotificationActions.textCopiedNotify, dispatch),
    requestErrorNotify: bindActionCreators(NotificationActions.requestErrorNotify, dispatch),
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextMenuContainer);
