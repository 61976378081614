import utils from 'utils';

function validate(number, args = {}) {
  const VALIDATORS = {
    min: v => number >= v,
    max: v => number <= v,
  };
  return Object.keys(args).every(key => {
    let fn = VALIDATORS[key];
    return utils.isFunction(fn) ? fn(args[key]) : true;
  });
}

export default function wrapper(args = {}) {
  return function numericValidation(value = '') {
    if (!value) {
      return true;
    }

    let number = +value;
    return utils.isFinite(number) && validate(number, args);
  };
}
