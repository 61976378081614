import * as types from './constants';
import defaultState from './defaultState';
import defaultStateOls from './defaultStateOls';
import * as formDataTypes from '../../constants';
import * as fieldsDataTypes from '../fields/constants';
import { RoRToAPIValuesMap } from './actions';
import utils from 'utils';
import formsEnum from '../../../../../../config/formsEnum';

const defaultStates = {
  [formsEnum.CALCULATE_ROUTE]: defaultState,
  [formsEnum.OLS]: defaultStateOls,
};

const apiValuesToRoR = {};

Object.keys(RoRToAPIValuesMap).forEach(formName => {
  apiValuesToRoR[formName] = {};
  Object.keys(RoRToAPIValuesMap[formName]).forEach(key => {
    apiValuesToRoR[formName][RoRToAPIValuesMap[formName][key].apiValue] = {
      isActiveField: key,
      rorValue: RoRToAPIValuesMap[formName][key].rorValue,
    };
  });
});

function getDefaultState(currentForm) {
  return utils.getObject(defaultStates, currentForm, defaultStates[formsEnum.CALCULATE_ROUTE]);
}

function setRoR(state, action) {
  const currentForm = utils.getObject(action, 'tabData.form');

  if (!state) {
    state = getDefaultState(currentForm);
  }

  switch (action.type) {
    case types.SET_DATA:
      return Object.assign({}, state, action.data);

    case fieldsDataTypes.UPDATE:
      if (apiValuesToRoR[currentForm] && apiValuesToRoR[currentForm][action.data.key] &&
        !state[apiValuesToRoR[currentForm][action.data.key].isActiveField]) {
        return { ...state, [apiValuesToRoR[currentForm][action.data.key].rorValue]: +action.data.value };
      }
      return state;

    case fieldsDataTypes.MULTI_UPDATE:
      state = { ...state };
      if (apiValuesToRoR[currentForm]) {
        Object.keys(apiValuesToRoR[currentForm])
          .forEach(key => {
            const data = apiValuesToRoR[currentForm][key];
            if (utils.getObject(action.params, key) && !state[data.isActiveField]) {
              state[data.rorValue] = utils.getObject(action.params, key).value;
            }
          });
      }
      return state;

    case formDataTypes.RESTORE_FROM_URL: {
      let ror = utils.getObject(action.data.value, `rangeOnRoute`);
      return Object.assign({}, state, ror);
    }

    default:
      return Object.assign({}, state);
  }
}

export default setRoR;
