import React from 'react';
import PropTypes from 'prop-types';
import FormRow from '../../../shared/formRow';
import AvoidAreasGroup from '../../common/AvoidAreasGroup';
import formsEnum from '../../../../config/formsEnum';
import { olsBboxValidation } from 'utils/validation/bboxValidation';

const defaultAvoidArea = {
  value: '',
  isValid: true,
  isShown: false,
};

class OlsAvoidAreas extends React.Component {
  onChange = (value) => {
    const { onChange } = this.props;
    onChange('avoid[areas]', value);
  }

  onAdd = () => {
    const { value } = this.props;
    if (!_.isEqual(value[value.length - 1], defaultAvoidArea)) {
      this.onChange([...value, { ...defaultAvoidArea }]);
    }
  }

  onRemove = (data) => {
    const { value } = this.props;
    this.onChange([...value.slice(0, data.index), ...value.slice(data.index + 1)]);
  }

  onUpdate = (data) => {
    const { value } = this.props;
    const updatedValue = {
      ...value[data.index],
      ...data.value,
    };
    updatedValue.isValid = olsBboxValidation(updatedValue.value);
    this.onChange([...value.slice(0, data.index), updatedValue, ...value.slice(data.index + 1)]);
  }

  onUpdateAll = (data) => {
    const { value } = this.props;
    const newValue = value.map((val, index) => {
      const newVal = {
        ...val,
        ...data.value[index],
      };
      newVal.isValid = olsBboxValidation(newVal.value);
      return newVal;
    });
    this.onChange(newValue);
  }

  render() {
    const { value } = this.props;

    return (
      <FormRow>
        <AvoidAreasGroup
          currentForm={formsEnum.OLS}
          value={value}
          onAdd={this.onAdd}
          onUpdate={this.onUpdate}
          onUpdateAll={this.onUpdateAll}
          onRemove={this.onRemove}
        />
      </FormRow>
    );
  }
}

OlsAvoidAreas.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OlsAvoidAreas;
