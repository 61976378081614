import { combineReducers } from 'redux';

import formData from './formData/reducer';
import form from './form/reducer';
import module from './module/reducer';
import response from './response/reducer';
import isActive from './isActive/reducer';
import tabColorPalette from './tabColorPalette/reducer';
import settings from './settings/reducer';
import title from './title/reducer';
import history from './history/reducer';
import selectedHistory from './selectedHistory/reducer';
import isLoading from './isLoading/reducer';
import id from './id/reducer';

export default combineReducers({
  id,
  formData,
  form,
  module,
  response,
  isActive,
  tabColorPalette,
  settings,
  title,
  history,
  selectedHistory,
  isLoading,
});
