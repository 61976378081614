import { UPDATE } from '../../../common/fields/constants';

const NS = 'operableStatus';

export const SET = `${NS}/SET`;

export default function (state = null, action) {
  switch (action.type) {
    case SET:
      return action.data;
    case UPDATE:
      if (action.data.key === 'requestOperableStatus' && action.data.value === false) {
        return null;
      }
      return state;
    default:
      return state;
  }
}
