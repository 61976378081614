import Markers from '../../routing/routing/Markers';

export default class IntermodalMarkers extends Markers {

  onDragEnd(data, value) {
    let { currentForm, filterValueUpdate } = this.props;
    let key = data.index === 0 ? 'origin' : 'destination';
    filterValueUpdate({ key, currentForm: currentForm.present, value: { value, isValid: true }, needsRerender: true });
  }
}
