import utils from 'utils';

import { InspectLink } from '../modules';
import SelectedPoint from './routing/routeInfo/SelectedPoint';

const STORE = {
  matrixEntry: 'tabData.response.data.response.matrixEntry',
  timestamp: 'tabData.response.data.response.metaInfo.timestamp',
  route: 'tabData.response.data.response.route',
  inspectLink: 'inspectLink.data.response.link',
  reverseGeocode: 'reverseGeocode',
};

export default class FormBase {
  constructor(map, state = {}) {
    this.map = map;
    this.state = state;
    this.modules = this.initModules();
    Object.assign(this.modules, {
      inspectLink: new InspectLink(map, state),
      selectedPoint: new SelectedPoint(map, state),
    });
  }

  setProps(props) {
    this.props = props;
    utils.iterObject(this.modules, m => {
      if (m.setProps) {
        m.setProps(props);
      } else {
        m.props = props;
      }
    });
  }

  isPropChanged(nextProps, path) {
    return utils.isPropChanged(nextProps, this.props, path);
  }

  isTimestampChanged(nextProps) {
    return this.isPropChanged(nextProps, this.getPath('timestamp'));
  }

  isInspectLinkChanged(nextProps) {
    return this.isPropChanged(nextProps, this.getPath('inspectLink'));
  }

  isReverseGeocodeChanged(nextProps) {
    return this.isPropChanged(nextProps, this.getPath('reverseGeocode'));
  }

  apply(method, args = []) {
    if (utils.isFunction(this[method])) {
      this[method](...args);
    }
  }

  applyModule(_module, method, args) {
    if (utils.isFunction(_module[method])) {
      _module[method](...args);
    }
  }

  clearGroups() {
    utils.iterObject(this.modules, m => m.clearGroups());
  }

  destroy() {
    utils.iterObject(this.modules, m => m.destroy());
    this.modules = null;
    this.map = null;
  }

  setViewBounds(...args) {
    this._setViewBounds(...args);
  }

  _setViewBounds(group, force = false) {
    if (!group) {
      return false;
    }

    let viewBounds = this.map.getViewModel().getLookAtData().bounds.getBoundingBox(),
        bounds = group instanceof H.geo.Rect ? group : group.getBoundingBox();
    if (bounds && (force || !viewBounds.containsRect(bounds))) {
      this.map.getViewModel().setLookAtData({ bounds });
    }
    return true;
  }

  getPath(key, childStore = {}) {
    return Object.assign({}, STORE, childStore)[key];
  }

  initModules() {
    return {};
  }

  getContextMenuData() {}

  process(props, nextProps) {
    this.setProps(props);
    this.processInspectLink(nextProps);
    if (this.isInspectLinkChanged(nextProps)) {
      this._setViewBounds(this.modules.inspectLink.getGroup(), true);
    } else if (this.isReverseGeocodeChanged(nextProps)) {
      this.processReverseGeocode(nextProps);
    }
  }

  processInspectLink(props) {
    this.modules.inspectLink.process(props);
  }

  processReverseGeocode(props) {
    let coords;
    if (props.reverseGeocode.length) {
      const { position } = props.reverseGeocode[0] || {};
      coords = `${position.lat},${position.lng}`;
    }
    this.modules.selectedPoint.process(coords, true);
  }
}
