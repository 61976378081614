import Routing from './routingTab/routingForm/reducer';
import Matrix from './routingTab/matrixForm/reducer';
import Isoline from './routingTab/isolineForm/reducer';
import RouteInfo from './routingTab/routeInfoForm/reducer';

////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////
/////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////
import OlsForm from './routingTab/OlsForm/reducer';
import VisualizeDataPoints from './offlineToolsTab/visualizeDataPointsForm/reducer';
import Intermodal from './transitTab/intermodalForm/reducer';

import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';
import { RESTORE_FROM_URL } from './constants';

const routingForms = {
  [formsEnum.CALCULATE_ROUTE]: Routing,
  [formsEnum.CALCULATE_MATRIX]: Matrix,
  [formsEnum.CALCULATE_ISOLINE]: Isoline,
  [formsEnum.GET_ROUTE_INFO]: RouteInfo,
  [formsEnum.OLS]: OlsForm,
//////////////////
///////////////////////////////////////////////////////////
//////////////////////////////////////////
////////////
};
////////////////
/////////////////////
/////////////////////////////
//
/////////////////////////
//////////////////////////////////////
//
//////////////////////
//////////////////////////////////////////
//////////////////////////////////
//
////////////////////////
///////////////////////////////////////////
//
//////////////////
///////////////////////
//
/////////////////////////////////
/////////////////////////////////
////////////////////////////////
//
//////////
const transitForms = {
  [formsEnum.INTERMODAL]: Intermodal,
};
const offlineToolsForms = {
  [formsEnum.VISUALIZE_DATA_POINTS]: VisualizeDataPoints,
};

const formData = {
  [modulesEnum.ROUTING]: routingForms,
//////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////
/////////////////////////////////////////////
//////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////
////////////
  [modulesEnum.TRANSIT]: transitForms,
  [modulesEnum.OFFLINE_TOOLS]: offlineToolsForms,
};

export default (state, action) => {
  switch (action.type) {
    case RESTORE_FROM_URL: {
      let { module, currentForm } = action.data;
      return formData[module][currentForm](undefined, action);
    }
    default: {
      let { module = modulesEnum.ROUTING, form = formsEnum.CALCULATE_ROUTE } = action.tabData || {};
      return formData[module][form](state, action);
    }
  }
};
