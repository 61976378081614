const defaultConnectorTypes = {
  'ev[connectorTypes]': ['iec62196Type1Combo', 'iec62196Type2Combo', 'Chademo', 'Tesla'],
};

export const modelDefault = {
  label: 'Model Default',
  value: 'default',
  preset: {
    evEnabled: true,

    // Consumption Parameters
    freeFlowSpeedTable: {
      value: '0,0.239,27,0.239,45,0.259,60,0.196,75,0.207,90,0.238,100,0.26,110,0.296,120,0.337,130,0.351,250,0.351',
      isValid: true,
    },
    trafficSpeedTable: {
      value: '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
      isValid: true,
    },
    auxiliaryConsumption: {
      value: '1.8',
      isValid: true,
    },
    ascent: {
      value: '9',
      isValid: true,
    },
    descent: {
      value: '4.3',
      isValid: true,
    },
    acceleration: {
      value: '',
      isValid: true,
    },
    deceleration: {
      value: '',
      isValid: true,
    },
    highSpeedThreshold: {
      value: '',
      isValid: true,
    },

    // Battery Parameters
    initialCharge: {
      value: '',
      isValid: true,
    },
    maxCharge: {
      value: '',
      isValid: true,
    },
    chargingCurve: {
      value: '',
      isValid: true,
    },
    maxChargingVoltage: {
      value: '400',
      isValid: true,
    },
    maxChargingCurrent: {
      value: '',
      isValid: true,
    },

    // Charging Parameters
    maxChargeAfterChargingStation: {
      value: '',
      isValid: true,
    },
    minChargeAtChargingStation: {
      value: '',
      isValid: true,
    },
    minChargeAtDestination: {
      value: '',
      isValid: true,
    },
    chargingSetupDuration: {
      value: '300',
      isValid: true,
    },

    // Enable ECAR
    makeReachable: 'true',
    ...defaultConnectorTypes,
  },
};

export const preset50kWh = {
  label: '50kWh car',
  value: '50kwh',
  preset: {
    evEnabled: true,

    // Consumption Parameters
    freeFlowSpeedTable: {
      value: '0,0.239,27,0.239,45,0.259,60,0.196,75,0.207,90,0.238,100,0.26,110,0.296,120,0.337,130,0.351,250,0.351',
      isValid: true,
    },
    trafficSpeedTable: {
      value: '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
      isValid: true,
    },
    auxiliaryConsumption: {
      value: '1.8',
      isValid: true,
    },
    ascent: {
      value: '9',
      isValid: true,
    },
    descent: {
      value: '4.3',
      isValid: true,
    },
    acceleration: {
      value: '',
      isValid: true,
    },
    deceleration: {
      value: '',
      isValid: true,
    },
    highSpeedThreshold: {
      value: '',
      isValid: true,
    },

    // Battery Parameters
    initialCharge: {
      value: '35',
      isValid: true,
    },
    maxCharge: {
      value: '50',
      isValid: true,
    },
    chargingCurve: {
      value: '0,239,16,199,24,167,30,111,34,83,38,55,42,33,46,17,50,1',
      isValid: true,
    },
    maxChargingVoltage: {
      value: '400',
      isValid: true,
    },
    maxChargingCurrent: {
      value: '',
      isValid: true,
    },

    // Charging Parameters
    maxChargeAfterChargingStation: {
      value: '45',
      isValid: true,
    },
    minChargeAtChargingStation: {
      value: '5',
      isValid: true,
    },
    minChargeAtDestination: {
      value: '5',
      isValid: true,
    },
    chargingSetupDuration: {
      value: '300',
      isValid: true,
    },

    // Enable ECAR
    makeReachable: 'true',
    ...defaultConnectorTypes,
  },
};

export const preset80kWh = {
  label: '80kWh car',
  value: '80kwh',
  preset: {
    evEnabled: true,

    // Consumption Parameters
    freeFlowSpeedTable: {
      value: '0,0.239,27,0.239,45,0.259,60,0.196,75,0.207,90,0.238,100,0.26,110,0.296,120,0.337,130,0.351,250,0.351',
      isValid: true,
    },
    trafficSpeedTable: {
      value: '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
      isValid: true,
    },
    auxiliaryConsumption: {
      value: '1.8',
      isValid: true,
    },
    ascent: {
      value: '9',
      isValid: true,
    },
    descent: {
      value: '4.3',
      isValid: true,
    },
    acceleration: {
      value: '',
      isValid: true,
    },
    deceleration: {
      value: '',
      isValid: true,
    },
    highSpeedThreshold: {
      value: '',
      isValid: true,
    },

    // Battery Parameters
    initialCharge: {
      value: '48',
      isValid: true,
    },
    maxCharge: {
      value: '80',
      isValid: true,
    },
    chargingCurve: {
      value: '0,239,32,199,56,167,60,130,64,111,68,83,72,55,76,33,78,17,80,1',
      isValid: true,
    },
    maxChargingVoltage: {
      value: '400',
      isValid: true,
    },
    maxChargingCurrent: {
      value: '',
      isValid: true,
    },

    // Charging Parameters
    maxChargeAfterChargingStation: {
      value: '72',
      isValid: true,
    },
    minChargeAtChargingStation: {
      value: '8',
      isValid: true,
    },
    minChargeAtDestination: {
      value: '8',
      isValid: true,
    },
    chargingSetupDuration: {
      value: '300',
      isValid: true,
    },

    // Enable ECAR
    makeReachable: 'true',
    ...defaultConnectorTypes,
  },
};

const electricPresets = [
  modelDefault,
  preset50kWh,
  preset80kWh,
];

export default electricPresets;
