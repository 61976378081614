import utils from 'utils';
import { genUrl4Dnd } from 'utils/url';
import fetch from 'utils/fetch';

import { normalizeRoutingData } from 'state/tabs/tab/response/responseHandlers';
import BaseRoutes from '../../../modules/Routes';

export default class Routes extends BaseRoutes {
  constructor(...args) {
    super(...args);
    this.onDrag = utils.debounce(::this._onDrag, 50);
  }

  _onDrag(targetData, coords) {
    if (!utils.isNumber(targetData.index) || this.currentRoute === undefined) {
      return false;
    }

    let url = genUrl4Dnd({
      coords,
      apiUrl: this.props.apiUrl,
      regExp: new RegExp(`waypoint${targetData.index}=([^&]*)`),
    });

    if (url) {
      fetch(url).then((responseData = {}) => {
        let { response = {} } = normalizeRoutingData(responseData).data;
        this.process(response.route);
      });
    }
    return true;
  }
}
