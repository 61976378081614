import localStorageReducerDecorator from 'utils/localStorageReducerDecorator';
import credentials from 'config/credentials';

const SET = 'appSettings/SET_INSPECT_LINK';

const reducer = (state, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default localStorageReducerDecorator(
  reducer,
  'appSettings.inspectLink.v1',
  {
    appId: credentials.INSPECT_LINK.appId,
    appCode: credentials.INSPECT_LINK.appCode,
  },
);

export const setInspectLinkSettings = (data) => ({ type: SET, payload: data });

export const getInspectLinkSettings = (state) => state.appSettings.inspectLink;
