import PolygonBase from '../../../modules/Polygon';
import { normalizeRoutingData } from 'state/tabs/tab/response/responseHandlers';

import utils from 'utils';
import { genUrl4Dnd } from 'utils/url';
import fetch from 'utils/fetch';

export default class IsolinePolygon extends PolygonBase {
  constructor(...args) {
    super(...args);
    this.onDrag = utils.debounce(::this._onDrag, 50);
  }

  _onDrag(targetData, coords) {
    let url = genUrl4Dnd({
      coords,
      apiUrl: this.props.apiUrl,
      regExp: new RegExp(`${targetData.key}=([^&]*)`),
    });

    if (url) {
      fetch(url).then((responseData = {}) => this.process({
        tabData: {
          response: normalizeRoutingData(responseData),
        },
      }));
    }
  }

  onDragEnd(data, value) {
    this.props.waypointUpdate({
      index: data.index,
      value: { coords: { value } },
      needsRerender: true,
      currentForm: this.props.currentForm.present,
    });
  }

  getData(nextProps) {
    return utils.getObject(nextProps, 'tabData.response.data.response.isoline') || [];
  }
}
