import * as mapMarkers from '../../../utils/MarkersHelper';

import MarkersBase from '../../../modules/_MarkersBase';

export default class IsolineMarkers extends MarkersBase {
  process(nextProps) {
    this.clearGroups();
    this.renderMarker(nextProps);
  }

  renderMarker(nextProps) {
    let group = this.getGroup();
    let { waypoints = [] } = nextProps.tabData.formData;
    let [item = {}] = waypoints;
    let { coords = {} } = item;
    if (coords.isValid && coords.value) {
      let title = {
        start: 'S',
        destination: 'D',
      }[item.key];
      let color = '#383b45';
      let strokeColor = '#1b1d21';
      let marker = mapMarkers.createMainMarker({ point: { value: coords.value }, title, color, strokeColor });
      if (nextProps.selectedTab === this.formState.index) {
        marker.draggable = true;
      }
      marker.setData(Object.assign({}, marker.getData() || {}, { key: item.key, index: 0, type: 'MAIN' }));
      this.applyLabel(marker, item, strokeColor);
      group.addObject(marker);
    }
  }
}
