import utils from 'utils';
import RoutingBase from '../../common/_RoutingBase';
import { Maneuver } from '../../../modules';
import Routes from '../routing/Routes';
import Markers from '../routing/Markers';
import SelectedPoint from '../routeInfo/SelectedPoint';
import HoveredPoint from '../routing/HoveredPoint';
import { isCoordValid } from 'utils/validation/coordsValidation';
import formsEnum from 'config/formsEnum';
import OlsAvoidAreas from './OlsAvoidAreas';
import { decode } from '../../../../../utils/flexPolyline';

export default class Routing extends RoutingBase {
  initModules() {
    return {
      routes: new Routes(this.map, this.state),
      markers: new Markers(this.map, this.state),
      selectedPoint: new SelectedPoint(this.map, this.state),
      hoveredPoint: new HoveredPoint(this.map, this.state),
      maneuver: new Maneuver(this.map, this.state),
      spans: new Maneuver(this.map, this.state),
      turnByTurnAction: new Maneuver(this.map, this.state),
      avoidAreas: new OlsAvoidAreas(this.map, this.state),
    };
  }

  process(props, nextProps, forceRender = false) {
    super.process(props, nextProps, forceRender);
    const avoidAreasPath = ['tabData', 'formData', 'fields', 'avoid[areas]'];

    if (utils.isPropChanged(props, nextProps, avoidAreasPath)) {
      this.modules.avoidAreas.process(_.get(nextProps, avoidAreasPath));
    }
  }

  getRoutes(props) {
    return utils.getObject(props, `tabData.response.data.response.routes`);
  }

  shouldUpdateRoutes(nextProps) {
    const isCurrentRouteChanged = nextProps.tabData.formData.currentRoute !== this.props.tabData.formData.currentRoute;

    return isCurrentRouteChanged
      || !_.isEqual(this.props.tabData.response, nextProps.tabData.response)
      || this.isPropChanged(this.props, nextProps, 'responseData')
      || this.isPropChanged(nextProps, `tabData.formData.rangeOnRoute`)
      || utils.isPropChanged(this.props, nextProps, 'tabData.formData.fields.selectedPoint')
      || utils.isPropChanged(this.props, nextProps, 'tabData.formData.fields.hoveredPoint');
  }

  shouldUpdateCurrent(nextProps) {
    let isHistoryChanged = this.props.historyLength !== nextProps.historyLength;
    return isHistoryChanged || utils.isPropChanged(this.props, nextProps, 'tabData.response.data.routes') ||
      utils.isPropChanged(this.props, nextProps, 'tabData.formData.fields.origin') ||
      utils.isPropChanged(this.props, nextProps, 'tabData.formData.fields.via') ||
      utils.isPropChanged(this.props, nextProps, 'tabData.formData.fields.destination');
  }

  renderResults(args = {}) {
    let {
        responseData = {},
        formData = {},
        isSelectedPointChanged = false,
        tabColorPalette,
        currentRoute,
      } = args,
      route = _.get(responseData, 'data.routes', []);
    if (route) {
      const routes = [];

      if (route) {
        route.forEach(routeData => {
          if (!routeData.sections) {
            return;
          }
          let shape = [];
          routeData.sections.forEach(sectionData => {
            const encodedPolyline = sectionData.polyline;
            if (!encodedPolyline) {
              return;
            }
            const decodedPolyline = encodedPolyline ? decode(encodedPolyline) : [];
            const latLonPolyline = decodedPolyline.map(point => point.slice(0, 2));
            shape = [...shape, ...utils.flatten(latLonPolyline)];
          });
          routes.push({ shape, sections: routeData.sections });
        });
      }

      this.processRoutes(routes, currentRoute, formData.fields, args.formData.rangeOnRoute, tabColorPalette);
      this.modules.selectedPoint.process(formData.fields.selectedPoint, isSelectedPointChanged);
      this.modules.hoveredPoint.process(formData.fields.hoveredPoint, tabColorPalette);
    }
    return true;
  }

  renderCurrent(props = {}, routes = {}) {
    const { currentRoute = 0 } = props;
    const { origin, destination, via } = props.tabData.formData.fields;

    if (!origin.isValid && !destination.isValid) {
      return;
    }

    const route = this.getRouteByIndex(routes, currentRoute);

    const viaPoints = [];
    via.forEach((value) => {
      viaPoints.push({
        coords: { value: value.coords, isValid: isCoordValid(value.coords) },
        isWaypoint: true,
      });
    });

    const chargingPoints = utils.getObject(route, 'sections', [])
      .filter(section => section.arrival.place.type === 'chargingStation')
      .map(section => {
        const { lat, lng } = section.arrival.place.location;
        return { coords: { isValid: true, value: `${lat},${lng}`, }, isChargingStation: true };
      });

    const points = [{ coords: origin }, ...viaPoints, ...chargingPoints, { coords: destination }];

    this.modules.markers.process(points, currentRoute, route);
  }

  renderManeuvers(props) {
    const selectedManeuverPath = 'tabData.formData.fields.selectedManeuver';
    const selectedSpanPath = 'tabData.formData.fields.selectedSpan';
    const selectedTurnByTurnActionPath = 'tabData.formData.fields.selectedTurnByTurnAction';
    const selectedManeuver = _.get(props, selectedManeuverPath, {});
    const selectedSpan = _.get(props, selectedSpanPath, {});
    const selectedTurnByTurnAction = _.get(props, selectedTurnByTurnActionPath, {});

    if (this.isPropChanged(props, selectedManeuverPath)) {
      this.modules.maneuver.process({
        shape: flattenShape(selectedManeuver.shape),
        color: props.tabData.tabColorPalette.primaryDarker,
      });
    }

    if (this.isPropChanged(props, selectedSpanPath)) {
      this.modules.spans.process({
        shape: flattenShape(selectedSpan.shape),
        color: props.tabData.tabColorPalette.primaryDarker,
      });
    }

    if (this.isPropChanged(props, selectedTurnByTurnActionPath)) {
      this.modules.turnByTurnAction.process({
        shape: flattenShape(selectedTurnByTurnAction.shape),
        color: props.tabData.tabColorPalette.primaryDarker,
      });
    }
  }

  onDragStart() {}

  onDragEnd(data, coords) {
    const { fields } = this.props.formData;
    const { via } = fields;
    const { index } = data;
    let totalLength = 2;
    if (fields.via.length) {
      totalLength += via.length;
    }
    let key;
    let value = coords;
    if (index === 0) {
      key = 'origin';
    } else if (index === totalLength - 1) {
      key = 'destination';
    } else {
      key = 'via';
      value = [...via.slice(0, index - 1), { ...via[index - 1], coords }, ...via.slice(index)];
    }
    this.props.filterValueUpdate({ key, currentForm: formsEnum.OLS, value });
  }

  onDrag() {}
}

function flattenShape(shape) {
  if (shape) {
    return shape.reduce((acc, value) => [...acc, value[0], value[1]], []);
  }
  return null;
}
