import waypointValidation from '../common/waypointValidation';
import numericsValidation from '../../../validation/numericsValidation';
import formConfig from 'config/form';
import formsEnum from 'config/formsEnum';
import { enumValidation, boolValidation } from '../common';
import modeValidation from '../common/modeValidation';
import dateTimeValidation from '../common/dateTimeValidation';
import numericValidation from '../common/numericValidation';
import consumptionValidation from '../common/consumptionValidation';
import getTruckValidation from '../common/truckValidation';

const config = formConfig(formsEnum.CALCULATE_ISOLINE);
let mapValue = val => val.value;

export default {
  ...getTruckValidation(config),
  start: waypointValidation,
  destination: waypointValidation,
  range: rangeValidation,
  rangetype: enumValidation('rangetype', config.rangeTypes.map(mapValue)),
  mode: modeValidation(formsEnum.CALCULATE_ISOLINE),
  departure: dateTimeValidation,
  arrival: dateTimeValidation,
  singlecomponent: boolValidation,
  resolution: numericValidation(),
  maxpoints: numericValidation(),
  quality: enumValidation('quality', ['1', '2', '3']),
  jsonattributes: numericValidation(),
  consumptionmodel: enumValidation('consumptionmodel', config.consumptionTypes.map(mapValue)),
  customconsumptiondetails: consumptionValidation,
};

function rangeValidation(value) {
  if (!numericsValidation(value)) {
    return [`Invalid range value '${value}'`];
  }

  return [];
}
