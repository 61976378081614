function getHost(key = 'geocode', type = 'hls') {
  return {
    hls: {
      geocode: 'geocoder.api.here.com',
      search: 'geocoder.api.here.com',
      reversegeocode: 'reverse.geocoder.api.here.com',
    },
    ols: {
      geocode: 'geocode.search.hereapi.com',
      search: 'browse.search.hereapi.com',
      reversegeocode: 'revgeocode.search.hereapi.com',
    },
  }[type][key];
}

const configs = {
  hls: {
    sources: {
      geocode: 'geocode.json',
      search: 'search.json',
      reversegeocode: 'reversegeocode.json',
    },
    url: {
      jsonp: true,
      useSSL: true,
      path: '/6.2/',
    }
  },
  ols: {
    sources: {
      geocode: 'geocode',
      search: 'browse',
      reversegeocode: 'revgeocode',
    },
    url: {
      jsonp: false,
      useSSL: true,
      path: '/v1/',
    }
  },
};

export default function getGeocoderSettings(key = 'geocode', type = 'hls') {
  const config = configs[type];
  const url = {
    ...config.url,
    host: getHost(key, type),
    path: `${config.url.path}${config.sources[key]}`,
  };
  return { url };
}
