import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomInput from 'shared/input';
import CloseButton from 'shared/closeButton';

import pointSplit from 'utils/matrix/pointSplit';

import './styles/pointField.scss';

class PointField extends Component {
  onChange(e) {
    this.props.onUpdate(pointSplit(e.target.value));
  }

  render() {
    let { label, data, onRemove } = this.props;
    return (
      <div className="rf-point-field">
        <CustomInput
          isValid={data.coords.isValid}
          label={label}
          value={`${data.category}${data.coords.value}`}
          onBlur={this.onChange.bind(this)}
          blurOnEnter
        />
        <CloseButton onClick={onRemove} />
      </div>
    );
  }
}

PointField.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PointField;
