import utils from 'utils';

import postprocessors from './postprocessors';
import extraParams from './extraParams';
import formsParseMap from './formsParseMap';
import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';

const urlMapping = {
  trailerscount: 'trailersCount',
  truckrestrictionpenalty: 'truckRestrictionPenalty',
  tunnelcategory: 'tunnelCategory',
  limitedweight: 'limitedWeight',
  weightperaxle: 'weightPerAxle',
  weightsperaxlegroup: 'weightsPerAxleGroup',
  trucktype: 'truckType',
  starttime: 'startTime',
  endtime: 'endTime',
  departuretime: 'departureTime',
  arrivaltime: 'arrivalTime',
  routingmode: 'routingMode',
  transportmode: 'transportMode',

  freeflowspeedtable: 'freeFlowSpeedTable',
  trafficspeedtable: 'trafficSpeedTable',
  auxiliaryconsumption: 'auxiliaryConsumption',
  highspeedthreshold: 'highSpeedThreshold',
  initialcharge: 'initialCharge',
  maxcharge: 'maxCharge',
  chargingcurve: 'chargingCurve',
  maxchargingvoltage: 'maxChargingVoltage',
  maxchargingcurrent: 'maxChargingCurrent',
  maxchargeafterchargingstation: 'maxChargeAfterChargingStation',
  minchargeatchargingstation: 'minChargeAtChargingStation',
  minchargeatdestination: 'minChargeAtDestination',
  chargingsetupduration: 'chargingSetupDuration',
};

const base64Params = ['location', 'routeid'];

function execPostProcessor(module, currentForm, data) {
  let fn = postprocessors[module] ? postprocessors[module][currentForm] : null;
  return utils.isFunction(fn) ? fn(data) : data;
}

function parser(keyVal = [], result = {}, currentForm = formsEnum.CALCULATE_ROUTE, module = modulesEnum.ROUTING) {
  let PARSE_MAP_PATH = `${module}.${currentForm}["${keyVal[0].replace(/\d+/g, '')}"]`;
  let options = utils.getObject(formsParseMap, PARSE_MAP_PATH);
  keyVal[0] = urlMapping[keyVal[0]] || keyVal[0];
  if (options) {
    if (!Array.isArray(options)) {
      options = [options];
    }
    options.forEach(paramOptions => {
      let dest = paramOptions.dest || keyVal[0];
      let value = paramOptions.fn ? paramOptions.fn(keyVal) : keyVal[1];
      if (!result[dest] && paramOptions.accumulated) {
        result[dest] = [];
      }
      result[dest] = paramOptions.accumulated ? result[dest].concat([value]) : value;
    });
  } else if (extraParams.isSupported(currentForm) && extraParams.isExtraParam(module, currentForm, keyVal[0])) {
    result = extraParams.collect(keyVal, result);
  } else {
    result[keyVal[0]] = keyVal[1];
  }

  return result;
}

let matchUrlParam = str => str.split(/(\d+)$/g).slice(0, -1);

function sortUrlParams(param1, param2) {
  let key1 = param1.split('=')[0];
  let key2 = param2.split('=')[0];

  let matches1 = matchUrlParam(key1);
  let matches2 = matchUrlParam(key2);

  if (matches1.length === 2 && matches2.length === 2 && matches1[0] === matches2[0]) {
    return matches1[1] - matches2[1];
  }

  return 0;
}

export default function parseUrlParams(urlParams = '', form, module) {
  if (!urlParams) {
    return {};
  }

  let result = {},
      params = urlParams.split('&');

  params
      .sort(sortUrlParams)
      .map(param => {
        let [key, ...val] = param.split('=');
        val = val.join('=');
        if (base64Params.indexOf(key) !== -1) {
          return [key, val];
        }
        return [key, val];
      })
      .filter(keyVal => !!keyVal[0])
      .map(keyVal => [keyVal[0].toLowerCase(), keyVal[1]])
      .forEach(keyVal => {
        result = parser(keyVal, result, form, module);
      });

  result = execPostProcessor(module, form, result);

  return Object.assign({}, result);
}
