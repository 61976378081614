import React from 'react';

import Item from './Item';

import utils from 'utils';

class GeoItem extends Item {
  componentDidMount() {
    let coords = Item.getCoords(this.props);
    let { index, route } = this.props;
    if (!coords && this.props.route) {
      let routeWaypointPos = utils.getObject(route, `waypoint[${index}].mappedPosition`) || {};
      let linkCoords = `${routeWaypointPos.latitude},${routeWaypointPos.longitude}`;
      this.onChange('coords', { target: { value: linkCoords } });
    }
  }

  getMainContent() {
    let { data = {} } = this.props,
        { coords = {} } = data;

    return (
      <div>
        {super.getMainContent()}
        {this.renderInput('coords', coords.value, 'Coordinates', coords.isValid)}
      </div>
    );
  }

  getAdvancedContent() {
    let { data } = this.props;
    const { heading = {} } = data;

    return (
      <div>
        {super.getAdvancedContent()}
        {this.renderInput('transitRadius', data.transitRadius, 'Transit Radius (meters)')}
        {this.renderInput('heading', heading.value, 'Heading (degrees)', heading.isValid)}
      </div>
    );
  }
}

export default GeoItem;
