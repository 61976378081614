import { CONSUMPTION_COLORS, JAM_COLORS, ZINDEX } from 'config/map';
import { calculateBearing } from './index';

export function createMappedLine(point) {
  return createPolyline({
    shape: [
      point.originalPosition.latitude,
      point.originalPosition.longitude,
      point.mappedPosition.latitude,
      point.mappedPosition.longitude,
    ],
    strokeColor: '#969696',
    lineWidth: 5,
    isDashed: true,
    zIndex: ZINDEX.POLYLINE,
  });
}

export function isLine(instance) {
  return instance instanceof H.map.Polyline;
}

export function createRoute(args = {}) {
  let { shape, tabColorPalette, color, pointArgsCount, lineWidth, isDashed = false, isActive = false } = args;
  let strokeColor = color || (isActive ? tabColorPalette.primary : tabColorPalette.secondary);
  lineWidth = lineWidth || 5;
  let zIndex = isActive ? ZINDEX.ACTIVE_ROUTE : ZINDEX.ROUTE;
  let data = { type: 'ROUTE' };

  return createPolyline({ shape, strokeColor, lineWidth, zIndex, isDashed, pointArgsCount, data });
}

export function createPolyline(args = {}) {
  let {
    shape,
    strokeColor = '#05A',
    lineWidth = 7,
    lineDash = [1, 10],
    pointArgsCount = 2,
    zIndex = ZINDEX.POLYLINE,
    isDashed = false,
    data = {},
  } = args,
      strip = createStrip(shape, pointArgsCount);

  if (shape.length < 4) {
    return null;
  }

  return new H.map.Polyline(strip, {
    style: {
      strokeColor,
      lineWidth,
      lineDash: isDashed ? lineDash : [],
      lineJoin: 'round',
    },
    zIndex,
    data,
  });
}

export function polylineToCorridor(shape, width, options) {
  let shapes = [];
  for (let k = 0; k < shape.length; k++) {
    let circle = new H.map.Circle(shape[k], width / 2, options);
    shapes.push(circle);
    if (k !== 0) {
      let point1 = shape[k - 1];
      let point2 = shape[k];
      let bearing = calculateBearing(point1.lat, point1.lng, point2.lat, point2.lng);
      let corner1 = point1.walk(bearing + 90, width / 2);
      let corner2 = point1.walk(bearing + 270, width / 2);
      let corner3 = point2.walk(bearing + 270, width / 2);
      let corner4 = point2.walk(bearing + 90, width / 2);
      let strip = new H.geo.LineString([
        corner1.lat, corner1.lng, 0,
        corner2.lat, corner2.lng, 0,
        corner3.lat, corner3.lng, 0,
        corner4.lat, corner4.lng, 0,
      ]);
      let polygon = new H.map.Polygon(strip, options);
      shapes.push(polygon);
    }
  }

  return shapes;
}

export function point2str(point) {
  return `${point.lat.toFixed(6)},${point.lng.toFixed(6)}`;
}

export function createStrip(shape = [], pointArgsCount = 2) {
  let strip = new H.geo.LineString(),
      storedPoint = 0;

  shape.forEach((point, idx) => {
    if (idx % pointArgsCount === 0) {
      storedPoint = point;
    } else if (idx % pointArgsCount === 1) {
      strip.pushLatLngAlt(storedPoint, point);
    }
  });

  return strip;
}

export function createBoundsBox(...coords) {
  return new H.geo.Rect(...coords);
}

export function createRect(coords, options = {}) {
  let bounds = createBoundsBox(...coords);
  return new H.map.Rect(bounds, options);
}

export function getJamFactorColor(jamFactor) {
  let jf = Math.round(jamFactor),
      color = null;

  if (jf < 4) {
    color = JAM_COLORS.fast;
  } else if (jf < 8) {
    color = JAM_COLORS.normal;
  } else if (jf < 10) {
    color = JAM_COLORS.slow;
  } else {
    color = JAM_COLORS.dead;
  }

  return color;
}

export function getConsumptionFactorColor(energy) {
  if (energy < 0) {
    return CONSUMPTION_COLORS.hasNoEnergyColor;
  }
  return CONSUMPTION_COLORS.hasEnergyColor;
}
