import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';

import './styles/tabsControl.scss';

let increment = 0;

class TabsControl extends CustomComponent {
  constructor(...args) {
    super(...args);
    this.increment = ++increment;
  }

  getTabNode(tabData) {
    let { label, name } = tabData;
    let { currentTabName, onChange } = this.props;
    return <li key={name}>
      <input
        id={`tabsControl_${name}_${this.increment}`}
        type="radio"
        checked={currentTabName === name}
        onChange={() => onChange(name)}
      />
      <label htmlFor={`tabsControl_${name}_${this.increment}`}>{label}</label>
    </li>;
  }

  render() {
    let { tabsData } = this.props;
    let className = this.getCssClasses(['rf-tabs-control', {
      'rf-tabs-control__two_rows': tabsData.length === 2,
    }]);

    return (
      <ul className={className}>
        {tabsData.map(::this.getTabNode)}
      </ul>
    );
  }
}

TabsControl.propTypes = {
  tabsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentTabName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TabsControl;
