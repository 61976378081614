import { types } from './routeFromPointsActions';
import { defaultRouteFromPointsState as defaultState } from './defaultState';
import { isCoordsValid } from 'utils/validation/coordsValidation';
import { RESTORE_FROM_URL } from '../../../constants';

function routeFromPoints(state = defaultState, action) {
  switch (action.type) {
    case types.SET_DATA: {
      const newState = { ...state, ...action.data };
      if (action.data.points !== undefined) {
        newState.points = {
          value: action.data.points,
          isValid: isCoordsValid(action.data.points, ';', false),
        };
      }
      return newState;
    }
    case RESTORE_FROM_URL: {
      return { ...action.data.value.routeFromPoints };
    }
    default:
      return state;
  }
}

export default routeFromPoints;
