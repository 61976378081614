import utils from 'utils';

export default function exec(formData) {
  let hasWrongPoint =
    formData.points.start.length === 0 ||
    formData.points.destination.length === 0 ||
    utils.some(formData.points.start, point => !point.coords.isValid || point.coords.value === '') ||
    utils.some(formData.points.destination, point => !point.coords.isValid || point.coords.value === '');
  return !hasWrongPoint;
}
