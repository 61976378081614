import * as mapPolyline from '../../../utils/PolylineHelper';

import ModuleBase from '../../../modules/_Base';

export default class Lines extends ModuleBase {
  process(...args) {
    this.clearGroups();
    this.renderLines(...args);
  }

  _hoverLine(e) {
    const line = e.target;
    const lineData = line.getData() || {};
    const { lineWidth } = line.getStyle();
    const zIndex = line.getZIndex() - 0.5;
    const { shape } = lineData;
    this.bottomLine = mapPolyline.createPolyline({ shape, zIndex, strokeColor: '#000', lineWidth: lineWidth + 2 });
    this.bottomLine.setData(lineData);
    this.getGroup().addObject(this.bottomLine);
  }

  _unhoverLine() {
    this.getGroup().removeObject(this.bottomLine);
  }

  getLineOptions(color) {
    return { strokeColor: color.primaryDarker, lineDash: [1, 6] };
  }

  checkSelected(_entry, selectedEntity = null) {
    let { marker, entry } = selectedEntity || {};

    if (marker) {
      return _entry[marker.key] === marker.index;
    }

    if (entry) {
      return ['startIndex', 'destinationIndex'].every(key => entry[key] === _entry[key]);
    }

    return true;
  }

  renderLines(formData, color, matrixEntries = [], selectedEntity = null, isSelectedTab) {
    let group = this.getGroup(),
        points = formData.points,
        lines = [];
    matrixEntries.forEach(entry => {
      let startPoint = points.start[entry.startIndex],
          destinationPoint = points.destination[entry.destinationIndex];

      if (!startPoint.coords.isValid || !destinationPoint.coords.isValid ||
        !this.checkSelected(entry, selectedEntity)) {
        return false;
      }

      let shape = `${startPoint.coords.value},${destinationPoint.coords.value}`.split(',').map(i => parseFloat(i)),
          lineParams = { shape, isDashed: !selectedEntity, lineWidth: selectedEntity ? 5 : 3 };
      const line = this.renderLine({ entry, color, lineParams, isSelectedTab });
      if (isSelectedTab && selectedEntity) {
        line.addEventListener('pointerenter', ::this._hoverLine);
        line.addEventListener('pointerleave', ::this._unhoverLine);
      }
      lines.push(line);
      return true;
    }, this);
    if (lines.length) {
      group.addObjects(lines);
    }
  }

  renderLine(args) {
    let { entry, color, lineParams = {}, isSelectedTab } = args,
        { shape = [] } = lineParams,
        _lineParams = Object.assign(this.getLineOptions(color), lineParams),
        line = mapPolyline.createPolyline(_lineParams),
        data = {
          shape,
          start: shape.slice(0, 2).join(','),
          end: shape.slice(-2).join(','),
          entry: { startIndex: entry.startIndex, destinationIndex: entry.destinationIndex },
          isMatrixLine: true,
          lineId: line.getId(),
          isSelectedTab,
        };
    line.setData(Object.assign({}, line.getData(), data));
    return line;
  }
}
