import { Component } from 'react';
import PropTypes from 'prop-types';


import classnames from 'classnames';

class CustomComponent extends Component {
  constructor() {
    super();
    this.constructor.defaultProps = Object.assign({}, CustomComponent.defaultProps, this.constructor.defaultProps);
    this.constructor.propTypes = Object.assign({}, CustomComponent.propTypes, this.constructor.propTypes);
  }

  getCssClasses(cssClasses = []) {
    let pCssClasses = this.props.cssClasses,
        normalizedCssClasses = Array.isArray(pCssClasses) ? pCssClasses : [pCssClasses];

    return classnames(...normalizedCssClasses.concat(cssClasses));
  }
}

CustomComponent.defaultProps = {
  cssClasses: {},
};

CustomComponent.propTypes = {
  cssClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]).isRequired,
};

export default CustomComponent;
