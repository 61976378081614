import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import CustomSelect from 'shared/select';
import CustomTextarea from 'shared/textarea';

import './styles/generalisationsField.scss';

class GeneralisationsField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onPresetChange = this.onPresetChange.bind(this);
  }

  onPresetChange(e) {
    let value = e.target.value;
    this.props.onChange({
      isPreset: value !== '',
      tolerance: value === '*' ? '' : value,
    });
  }

  onChange(e) {
    this.props.onChange({
      tolerance: e.target.value,
    });
  }

  render() {
    let { label, options, data } = this.props,
      value = data.value,
      cssClasses = classnames('rf-generalisations-field', {
        'rf-generalisations-field_empty': !value.isPreset,
      }),
      preset = options.map(item => item.value)
        .filter(v => v === value.tolerance)[0] || '';

    if (!preset && value.isPreset) {
      preset = '*';
    }

    return (
      <div className={cssClasses}>
          <CustomSelect
            label={label}
            options={options}
            value={preset}
            onChange={this.onPresetChange}
          />
          <CustomTextarea
            value={value.tolerance}
            isValid={data.isValid}
            onBlur={this.onChange}
          />
      </div>
    );
  }
}

GeneralisationsField.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GeneralisationsField;
