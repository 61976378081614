import * as types from '../constants';

const DEFAULT_STATE = { data: { response: {} } };

export default function apiResponseMatrix(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case types.RECEIVE_DATA_MATRIX:
      return action.result || DEFAULT_STATE;

    default:
      return state;
  }
}
