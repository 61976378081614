import utils from 'utils';
import stringifyAdvancedWaypoint from '../preprocessor/common/advancedWaypointParams';

function keepEmpty(value) {
  // eslint-disable-next-line no-new-wrappers
  return new String(value == null ? '' : value); // transform to object to pass .filter(Boolean)
}

const customHandlers = {
  modeFormatter(value = {}) {
    const attrs = ['type', 'transport', 'traffic', 'features'];

    return attrs
      .map(attr => {
        if (attr === 'features') {
          return Object.keys(value[attr] || {})
            .filter(key => value[attr][key].isChecked)
            .map((key) => `${key}:${value[attr][key].value}`)
            .join(',');
        }

        return value[attr];
      })
      .filter(Boolean)
      .join(';');
  },

  attributesFormatter(value = {}) {
    const aggregationMap = { all: 'all', none: 'none' };
    let attributes = utils.getObject(value, 'attributes', [])
      .map(attr => (value.aggregation === 'all' ? `-${attr}` : attr));

    if (attributes.length === 0 && !aggregationMap[value.aggregation]) {
      return null;
    }

    return [aggregationMap[value.aggregation]]
      .concat(attributes)
      .filter(Boolean)
      .join(',');
  },

  jsonattributesFormatter(value = {}) {
    return (value.lowerCaseFirstCharacter ? 1 : 0) +
      (value.includeTypeElement ? 8 : 0) +
      (value.usePluralNaming ? 16 : 0) +
      (value.shapesToArraysWithPairs ? 32 : 0) +
      (value.shapesToArraysWithTriplets ? 64 : 0) +
      (value.jsonWrapper ? 128 : 0);
  },

  resolutionFormatter(value = {}) {
    const { snap = {}, view = {} } = value;
    let res = null;
    if (view.isChecked && view.value) {
      res = `${view.value}`;
    }
    if (snap.isChecked && snap.value) {
      res = res === null ? `:${snap.value}` : `${res}:${snap.value}`;
    }

    return res;
  },

  generalisationsFormatter(value = {}) {
    return (value.tolerance || '').replace(/\s/g, '') || null;
  },

  evFormatter(ev) {
    const evArr = [];
    ev.reverse().forEach(val => {
      if (val !== '' && val !== undefined) {
        evArr.push(val);
      } else if (evArr.length) {
        evArr.push('');
      }
    });
    if (evArr.length) {
      return `!${evArr.reverse().join(';')}`;
    }
    return '';
  },

  waypointsFormatter(points = [], formData = {}) {
    let
        joinAttrs = (attrs, delimiter) => attrs.filter(Boolean).map(item => String(item).trim())
          .join(delimiter).replace(/!+$/, ''),

        getGeoStr = function (base, extra, ev) {
          // waypoint=[geo!][Type[,StopOverDuration]!]Position[;TransitRadius[;UserLabel[;Heading]]
          let str = joinAttrs(base, '!');
          let extraStr = extra.some(Boolean) ? extra.join(';') : null;

          let resultStr = [str, extraStr].filter(Boolean).join(';').replace(/;*$/, '');

          return resultStr + customHandlers.evFormatter(ev);
        },

        getLinkStr = function (base, extra) {
          // waypoint=link[!Type[,StopOverDuration]][![Position][;UserLabel]]!LinkPosition - we omit rendering position
          return joinAttrs([base.filter(Boolean).join('!'), extra], '!');
        },

        getStreetStr = function (base, extra, ev) {
          // waypoint=street![Type[,StopOverDuration]!][DisplayPosition[;UserLabel]]!StreetPosition
          // [!Heading[!TransitRadius]]
          let baseCopy = [...base];
          if (baseCopy[1] === '') {
            baseCopy.splice(1, 1);
          }
          let resultStr = joinAttrs([baseCopy.join('!'), extra], '!');

          const evStr = customHandlers.evFormatter(ev);
          if (evStr) {
            const extraParamsCount = (extra.match(/!/g) || []).length + 1;
            for (let i = extraParamsCount; i < 3; i++) {
              resultStr += '!';
            }
            resultStr += evStr;
          }

          return resultStr;
        },

        handlePoint = function (data, index) {
          let coords = utils.getObject(data, 'coords.value', '').replace(/\s/g, '');
          let getUserLabel = (data) => {
            let userLabel = joinAttrs([data.userLabel]);
            return userLabel ? `;${userLabel}` : userLabel;
          };
          let { street = {}, stopOverDuration = {} } = data;
          const type = stopOverDuration.value ? `${data.type},${stopOverDuration.value}` : data.type;
          let typeHandler = {
                geo: {
                  fn: getGeoStr,
                  base: [data.category, type, coords],
                  extra: [data.transitRadius, data.userLabel, utils.getObject(data, 'heading.value')],
                  ev: [data.power, data.supplyType, data.voltage, data.current, data.phases],
                },
                link: {
                  fn: getLinkStr,
                  base: [data.category, type,
                    joinAttrs([utils.getObject(data, 'displayPosition.value'), getUserLabel(data)], '')],
                  extra: [utils.getObject(data, 'link.value', '').replace(/\s/g, '')],
                },
                street: {
                  fn: getStreetStr,
                  base: [data.category, type,
                    joinAttrs([utils.getObject(data, 'displayPosition.value'), getUserLabel(data)], '')],
                  extra: joinAttrs([joinAttrs([coords, street.name], ';'),
                    keepEmpty(utils.getObject(data, 'heading.value')), data.transitRadius], '!'),
                  ev: [data.power, data.supplyType, data.voltage, data.current, data.phases],
                },
              }[data.category],
              value = typeHandler.fn(typeHandler.base, typeHandler.extra, typeHandler.ev);

          if (formData.onlyValue) {
            return value || false;
          }

          index = formData.fields.useNewWaypointsFormat ? '' : index;
          return value ? `waypoint${index}=${value}` : false;
        };

    return points
      .map(handlePoint)
      .filter(Boolean)
      .join('&');
  },

  objectFormatter(data = {}) {
    return data.length ? data : null;
  },

  arrayFormatter(data = []) {
    return data.join(',');
  },

  avoidAreasFormatter(data = []) {
    return data.map(item => String(item.value).replace(/\s+/g, '')).filter(Boolean).join('!') || null;
  },

  pointsFormatter(key, data = [], formData = {}) {
    return data.map((item, index) => {
      let value = (`${item.category}${item.coords.value}`).replace(/\s/g, '');
      index = formData.fields.useNewPointsFormat ? '' : index;
      return `${key}${index}=${value}`;
    }).join('&');
  },

  consumptionFormatter(consumptionParams, formData) {
    const keyValSeparator = formData.fields.consumptionmodel === 'electric' ? '=' : ',';
    const standardFields = ['ascent', 'descent', 'acceleration', 'deceleration', 'auxiliaryconsumption', 'speed',
      'turnpenalty'];
    const electricFields = ['ascent', 'descent', 'acceleration', 'deceleration', 'auxiliaryconsumption',
      'freeflowspeedtable', 'trafficspeedtable', 'trafficscaletable'];
    const filterKeys = formData.fields.consumptionmodel === 'electric' ? electricFields : standardFields;
    return customHandlers.complexFormatter(keyValSeparator, filterKeys)(consumptionParams);
  },

  complexFormatter(keyValSeparator = '=', filterKeys = null) {
    return consumptionParams => Object.keys(consumptionParams)
      .filter(key => (filterKeys === null || filterKeys.indexOf(key.toLowerCase()) > -1)
        && consumptionParams[key].value !== undefined && consumptionParams[key].value !== '')
      .map(key => {
        const value = `${consumptionParams[key].value}`;
        if (value === '') {
          return false;
        }
        return `${key}${keyValSeparator}${value}`;
      })
      .filter(Boolean)
      .join(';');
  },

  multiCheckboxesFormatter(params) {
    let result = [];
    Object.keys(params).forEach((index) => {
      if (params[index]) {
        result.push(index);
      }
    });

    return result.join(',');
  },

  boundingBox(params) {
    let { selectionNWCorner, selectionSECorner } = params;
    return `${selectionNWCorner.value};${selectionSECorner.value}`;
  },

  boundingBoxTrafficDB(params) {
    let { selectionNWCorner, selectionSECorner } = params;

    const nwCoords = selectionNWCorner.value.split(',');
    const seCoords = selectionSECorner.value.split(',');
    return [nwCoords[1], seCoords[0], seCoords[1], nwCoords[0]].join(',');
  },

  inHandler(value = {}) {
    const { selectionNWCorner, selectionSECorner, center, radius } = value;
    if (selectionSECorner && selectionNWCorner) {
      const [north, west] = selectionNWCorner.value.split(',');
      const [south, east] = selectionSECorner.value.split(',');
      if (north && west && south && east) {
        return `${west},${south},${east},${north}`;
      }
    } else if (center && radius) {
      return `${center.value};r=${radius}`;
    }
    return '';
  },

  proximity(params) {
    let { center, radius } = params;
    return `${center.value},${radius}`;
  },

  corridor(params) {
    let { points, width } = params;
    return width ? `${points.value};${width}` : points.value;
  },

  vehicleType(params) {
    const { type, consumption = {} } = params;
    return `${type},${consumption.value}`;
  },

  licensePlate(value) {
    return value ? `lastcharacter:${value}` : '';
  },

  groups(values) {
    return Object.keys(values).map(group => {
      if (!values[group].isValid || values[group].value === '') {
        return null;
      }
      return `${group}:${values[group].value}`;
    }).filter(Boolean).join(';');
  },

  multiParamsArray(value) {
    return value.split(';').map((latLng) => `via=${latLng}`).join('&');
  },

  via(values) {
    return values.map(value => `via=${value.coords}${stringifyAdvancedWaypoint(value.advanced)}`).join('&');
  }
};

export default customHandlers;
