import ResultPanelFormRouting from './routingTab/routing/ResultPanelForm';
import ResultPanelFormMatrix from './routingTab/matrix/ResultPanelForm';
import ResultPanelFormIsoline from './routingTab/isoline/ResultPanelForm';
import ResultPanelFormRouteInfo from './routingTab/routeInfo/ResultPanelForm';

////////////////
///////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////
import ResultPanelFormOlsApi from './routingTab/olsApi/OlsApiResults';

import modulesEnum from 'config/modulesEnum';
import formsEnum from 'config/formsEnum';

export const RESULT_FORMS = {
  [modulesEnum.ROUTING]: {
    [formsEnum.CALCULATE_ROUTE]: ResultPanelFormRouting,
    [formsEnum.CALCULATE_MATRIX]: ResultPanelFormMatrix,
    [formsEnum.CALCULATE_ISOLINE]: ResultPanelFormIsoline,
    [formsEnum.GET_ROUTE_INFO]: ResultPanelFormRouteInfo,
////////////////////
////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////
//////////////
    [formsEnum.OLS]: ResultPanelFormOlsApi,
  },
//////////////////
/////////////////////////
/////////////////////////////////////
////
//////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////
////
/////////////////////////////
/////////////////////////////////////////////
////
//////////////////////
///////////////////////////////
////
//////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////
////
//////////////////////////////
//////////////////////////////////////////////
////
////////////
  [modulesEnum.TRANSIT]: {
    [formsEnum.INTERMODAL]: ResultPanelFormOlsApi,
  },
};
