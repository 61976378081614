import _ from 'lodash';
import { FIELDS_MAPPER, ROUTING_MODE_MAPPER } from './olsFieldsMapper';

const prefix =
  'https://demo.routing.ext.here.com/#url=https://router.hereapi.com/v8/routes';

const getParam = (key, value) => ({ key, value });

export default (props) => {
  const { formData = {} } = props;
  const { waypoints = [], fields = {}, avoidAreas = [] } = formData;

  // waypoints
  const originValue = _.get(waypoints[0], 'coords.value', '');
  const destinationValue = _.get(
    waypoints[waypoints.length - 1],
    'coords.value',
    ''
  );
  const params = [
    { key: 'apikey', value: 'DEFAULT_API_KEY' },
    { key: 'return', value: 'polyline,summary,actions,instructions' },
  ];
  params.push({ key: 'origin', value: originValue });
  params.push({ key: 'destination', value: destinationValue });

  waypoints
    .slice(1, -1)
    .map((waypoint) => _.get(waypoint, 'coords.value', ''))
    .forEach((via) => {
      params.push({ key: 'via', value: via });
    });

  // mode
  const { transport: transportMode, type: routingMode } = fields.mode || {};
  params.push(getParam('transportMode', transportMode === 'carHOV' ? 'car' : transportMode));
  params.push(getParam('routingMode', ROUTING_MODE_MAPPER[routingMode] || ''));

  // avoidAreas
  const areas = [];
  avoidAreas.forEach((area) => {
    if (area.isShown) {
      areas.push(`bbox:${_.get(area, 'value', '').replace(';', ',')}`);
    }
  });
  params.push(getParam('avoid[areas]', areas.join('|')));

  // avoid[features]
  const features = [];
  if (fields.avoidseasonalclosures) {
    features.push('seasonalClosure');
  }
  if (_.get(fields, 'avoidturns[0]') === 'difficult') {
    features.push('difficultTurns');
  }
  const handleModeFeature = (sourceKey, destKey) => {
    if (
      _.get(fields, `mode.features.${sourceKey}.value`) === '-2' &&
      _.get(fields, `mode.features.${sourceKey}.isChecked`)
    ) {
      features.push(destKey);
    }
  };
  handleModeFeature('tollroad', 'tollRoad');
  handleModeFeature('motorway', 'controlledAccessHighway');
  handleModeFeature('boatFerry', 'ferry');
  handleModeFeature('railFerry', 'carShuttleTrain');
  handleModeFeature('tunnel', 'tunnel');
  handleModeFeature('dirtRoad', 'dirtRoad');
  params.push({ key: 'avoid[features]', value: features.join(',') });

  // departure
  const departureValue = _.get(fields, 'routeDate.value');
  if (
    _.get(fields, 'routeDate.key') === 'departure' &&
    departureValue &&
    departureValue !== 'now'
  ) {
    params.push({ key: 'departureTime', value: departureValue });
  }

  // excludeCountries, alternatives, metricSystem, language and truck fields
  let olsFields = {};

  Object.keys(FIELDS_MAPPER).forEach((fieldKey) => {
    if (fields[fieldKey]) {
      olsFields = {
        ...olsFields,
        ...FIELDS_MAPPER[fieldKey](fields[fieldKey]),
      };
    }
  });

  Object.keys(olsFields).forEach((key) => {
    params.push({ key: (key || '').replace('truck[', 'vehicle['), value: olsFields[key] });
  });

  const url = `${prefix}?${params
    .filter((param) => param.value)
    .map((param) => `${param.key}=${param.value}`)
    .join('&')}`;
  const win = window.open(url, '_blank');
  win.focus();
};
