import utils from 'utils';
import RoutingBase from '../../common/_RoutingBase';
import { Maneuver } from '../../../modules';
import Routes from '../routing/Routes';
import Markers from '../routing/Markers';
import SelectedPoint from '../routeInfo/SelectedPoint';
import LegSplits from './LegSplits';

export default class Routing extends RoutingBase {
  initModules() {
    const markers = new Markers(this.map, this.state);
    markers.isMarkerDraggable = false;

    return {
      routes: new Routes(this.map, this.state),
      markers,
      selectedPoint: new SelectedPoint(this.map, this.state),
      maneuver: new Maneuver(this.map, this.state),
      legSplits: new LegSplits(this.map, this.state),
    };
  }

  shouldUpdateCurrent(nextProps) {
    let isHistoryChanged = this.props.historyLength !== nextProps.historyLength;
    return isHistoryChanged || utils.isPropChanged(this.props, nextProps, 'tabData.response.data.response.route') ||
      utils.isPropChanged(this.props, nextProps, 'tabData.formData.fields.shape') ||
      utils.isPropChanged(this.props, nextProps, 'tabData.formData.mapConfig');
  }

  renderResults(args = {}) {
    let {
        responseData = {},
        formData = {},
        isSelectedPointChanged = false,
        currentRoute,
        tabColorPalette,
      } = args,
      { response = {} } = responseData.data,
      route = response.route;
    if (route) {
      this.processRoutes(route, currentRoute, formData.fields, null, tabColorPalette);
      this.modules.selectedPoint.process(formData.fields.selectedPoint, isSelectedPointChanged);
    }
    return true;
  }

  renderCurrent(props = {}, routes = {}) {
    const { currentRoute = 0 } = props;
    const formData = props.tabData.formData;

    if (!props.tabData.formData.fields.shape.isValid) {
      return;
    }

    const route = this.getRouteByIndex(routes, currentRoute);
    const points = formData.fields.shape.value.split(';').map((value, index, allPoints) =>
      ({ coords: { value, isValid: true }, isWaypoint: index !== 0 && index !== allPoints.length - 1 }));

    this.modules.markers.process(formData.mapConfig.renderRoutePlan ?
      points : [points[0], points[points.length - 1]], currentRoute, route);

    this.modules.legSplits.process(route, formData.mapConfig);
  }

  onDragStart() {}
  onDragEnd() {}
  onDrag() {}
}
