import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomSelect from 'shared/select';
import FormRow from 'shared/formRow';

class DataSwitcher extends Component {
  render() {
    let { formConfig, data, onChange } = this.props;
    return (
      <div className="rf-data-switcher">
        <FormRow>
          <CustomSelect
            value={data.category}
            options={formConfig.routeWaypointCategories}
            onChange={onChange.bind(this, 'category')}
          />
        </FormRow>
        <FormRow>
          <CustomSelect
            value={data.type}
            options={formConfig.routeWaypointTypes}
            isReadonly={!data.isWaypoint}
            onChange={onChange.bind(this, 'type')}
          />
        </FormRow>
      </div>
    );
  }
}

DataSwitcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
};

export default DataSwitcher;
