import waypointValidation from '../common/waypointValidation';
import modeValidation from '../common/modeValidation';
import formsEnum from 'config/formsEnum';
import countryCodes from 'config/countryCodes';
import numericValidation from '../common/numericValidation';
import avoidAreasValidation from '../common/avoidAreasValidation';
import avoidLinksValidation from '../common/avoidLinksValidation';
import dateTimeValidation from '../common/dateTimeValidation';
import { listValidation } from '../common';
import formConfig from 'config/form';
import getTruckValidation from '../common/truckValidation';

const config = formConfig(formsEnum.CALCULATE_MATRIX);
let mapValue = val => val.value;

export default {
  ...getTruckValidation(config),
  start: waypointValidation,
  destination: waypointValidation,
  mode: modeValidation(formsEnum.CALCULATE_MATRIX),
  searchrange: numericValidation(),
  avoidareas: avoidAreasValidation,
  avoidlinks: avoidLinksValidation,
  departure: dateTimeValidation,
  matrixattributes: listValidation('matrixattributes', config.matrixAttributes.map(mapValue)),
  summaryattributes: listValidation('summaryattributes', config.summaryAttributes.map(mapValue)),
  excludecountries: listValidation('excludecountries', countryCodes),
};
