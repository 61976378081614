export const PUBLIC_TRANSPORTS = [
  { label: 'Public Transport', value: 'publicTransport' },
  { label: 'Public Transport Timetable', value: 'publicTransportTimeTable' },
];

export default {
  routeTransports: [
    { label: 'Car', value: 'car' },
    { label: 'Car HOV/Carpool', value: 'carHOV' },
    { label: 'Truck', value: 'truck' },
    { label: 'Bicycle BETA', value: 'bicycle' },
    { label: 'Pedestrian', value: 'pedestrian' },
  ],
  transportTypes: [
    { value: 'busPublic', label: 'Bus Public' },
    { value: 'busTouristic', label: 'Bus Touristic' },
    { value: 'busIntercity', label: 'Bus Intercity' },
    { value: 'busExpress', label: 'Bus Express' },
    { value: 'railMetro', label: 'Rail Metro' },
    { value: 'railMetroRegional', label: 'Rail Metro Regional' },
    { value: 'railLight', label: 'Rail Light' },
    { value: 'railRegional', label: 'Rail Regional' },
    { value: 'trainRegional', label: 'Train Regional' },
    { value: 'trainIntercity', label: 'Train Intercity' },
    { value: 'trainHighSpeed', label: 'Train High Speed' },
    { value: 'monoRail', label: 'Mono Rail' },
    { value: 'aerial', label: 'Aerial' },
    { value: 'inclined', label: 'Inclined' },
    { value: 'water', label: 'Water' },
    { value: 'privateService', label: 'Private Service' },
  ],
};
