import utils from 'utils';

export default {
  isAnyRouteChanged(routeSet1, routeSet2) {
    if (!Array.isArray(routeSet2)) {
      return false;
    }

    if (!Array.isArray(routeSet1)) {
      return true;
    }

    if (routeSet1.length !== routeSet2.length) {
      return true;
    }

    if (routeSet1.some((route = { shape: [] }, index) => !utils.isEqual(route.shape, routeSet2[index].shape)
        || !utils.isEqual(route.leg, routeSet2[index].leg)
      )) {
      return true;
    }
    return routeSet1.some((route = {}, index) => {
      const links1 = utils.getObject(route, 'leg[0].link');
      const links2 = utils.getObject(routeSet2[index], 'leg[0].link');
      return !utils.isEqual(links1, links2);
    });
  },

  /**
   * Add new waypoint to API url at {waypointIndex}
   */
  addNewWaypointToUrl(url, waypointPosition, waypointIndex, useNewWaypointsFormat = false) {
    let { lat, lng } = waypointPosition;
    if (!useNewWaypointsFormat) {
      let waypointCount = url.match(/waypoint(\d+)?=/g).length;
      for (let i = waypointCount - 1; i > waypointIndex; i--) {
        url = url.replace(`waypoint${i}=`, `waypoint${i + 1}=`);
      }
      url = url.replace(`waypoint${waypointIndex}=`,
        `waypoint${waypointIndex}=geo!passThrough!${lat},${lng}&waypoint${waypointIndex + 1}=`);
    } else {
      url = url.replace(new RegExp(`(&?waypoint=[^&]*){${waypointIndex}}`),
        `$&&waypoint=geo!passThrough!${lat},${lng}`);
    }
    return url;
  },

  getTotalConsumptionByLegs(legs) {
    try {
      let consumption = 0;
      legs.forEach(leg => {
        leg.link.forEach(link => {
          consumption += link.consumption;
        });
      });
      return isNaN(consumption) ? null : consumption;
    } catch (e) {
      return null;
    }
  },
};
