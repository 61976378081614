import utils from 'utils';

export function isWaypointEmpty(waypoint) {
  return (utils.getObject(waypoint, 'category') === 'geo' && utils.getObject(waypoint, 'coords.value') === '') ||
    (utils.getObject(waypoint, 'category') === 'link' && utils.getObject(waypoint, 'link.value') === '');
}

export function isAnyWaypointEmpty(waypoints) {
  return utils.some(waypoints, isWaypointEmpty);
}
