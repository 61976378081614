import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomInput from 'shared/input';
import FormRow from 'shared/formRow';
import CustomSelect from 'shared/select';
import CustomCheckbox from 'shared/checkbox';
import MapFormToggler from './MapFormToggler';

import trafficOverlaysConfig from 'config/trafficOverlays';

export default class TrafficOverlaysForm extends Component {
  render() {
    let { data = {}, onChange, changeTrafficType } = this.props;
    let { imagesize, imageformat, profile, extraparams, host } = data;
    return <div className="rf-map__tooltip rf-map__to-module" ref="form">
      <h2>Traffic Overlays</h2>
      <MapFormToggler getFormRef={() => this.refs.form} {...this.props} />
      <FormRow>
        <CustomCheckbox
          label="Use Vector Tiles"
          isChecked={false}
          onChange={changeTrafficType}
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Host"
          value={host}
          onBlur={onChange.bind(this, 'host')}
          blurOnEnter
        />
      </FormRow>
      <FormRow>
        <CustomSelect
          label="Image Size"
          options={trafficOverlaysConfig.imageSizes}
          value={imagesize}
          onChange={onChange.bind(this, 'imagesize')}
        />
      </FormRow>
      <FormRow>
        <CustomSelect
          label="Image Format"
          options={trafficOverlaysConfig.imageFormats}
          value={imageformat}
          onChange={onChange.bind(this, 'imageformat')}
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Profile"
          value={profile}
          onBlur={onChange.bind(this, 'profile')}
          blurOnEnter
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Extraparams"
          value={extraparams}
          onBlur={onChange.bind(this, 'extraparams')}
          blurOnEnter
        />
      </FormRow>
    </div>;
  }
}

TrafficOverlaysForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  changeTrafficType: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};
