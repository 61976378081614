import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as NotificationActions from 'state/notification/actions';
import * as RangeOnRouteActions from 'state/tabs/tab/formData/common/rangeOnRoute/actions';
import LayerToggle from '../forms/common/LayerToggle';
import RoRForm from './RoRForm';
import RoRFormElectric from './RoRFormElectric';
import { getCurrentFormData } from 'state/tabs/tab/formData';

import utils from 'utils';
import { getForm } from 'state/tabs/tab/form';
import formsEnum from '../../config/formsEnum';
import { RoRToAPIValuesMap } from '../../state/tabs/tab/formData/common/rangeOnRoute/actions';

export class RoRContainer extends Component {
  constructor(...args) {
    super(...args);
    this.state = { formExpanded: false };
  }

  // match ROR values with API values:
  shouldComponentUpdate(nextProps) {
    let { formData, currentForm, setRangeOnRouteData } = nextProps;
    const fieldsToUpdate = {};
    if (!RoRToAPIValuesMap[currentForm]) {
      return true;
    }
    Object.keys(RoRToAPIValuesMap[currentForm]).forEach(isActiveField => {
      const fieldNames = RoRToAPIValuesMap[currentForm][isActiveField];
      const isActive = formData.rangeOnRoute[isActiveField];
      if (isActive) {
        return;
      }
      const apiValue = +utils.getObject(formData.fields, fieldNames.apiValue, 0).value;
      const rorValue = +utils.getObject(formData.rangeOnRoute, fieldNames.rorValue, 0);
      if (apiValue !== rorValue) {
        fieldsToUpdate[fieldNames.rorValue] = apiValue;
      }
    });
    if (Object.keys(fieldsToUpdate).length) {
      setRangeOnRouteData(fieldsToUpdate);
      return false;
    }
    return true;
  }

  onToggleForm() {
    this.setState({
      formExpanded: !this.state.formExpanded,
    });
  }

  onRoRToggle() {
    let isActive = !utils.getObject(this.props, 'formData.rangeOnRoute.isActive');
    let { setRangeOnRouteData, setNotification, formData } = this.props;
    let attributesChanged = false;

    setRangeOnRouteData({ isActive });

    if (!formData.fields.linkattributes || !formData.fields.legattributes) {
      return;
    }

    let linkAttributes = formData.fields.linkattributes.attributes.slice();
    let legAttributes = formData.fields.legattributes.attributes.slice();
    if (isActive) {
      if (linkAttributes.indexOf('cn') === -1) {
        linkAttributes.push('cn');
        this.updateFilterValue('linkattributes', { attributes: linkAttributes });
        attributesChanged = true;
      }
      if (legAttributes.indexOf('li') === -1) {
        legAttributes.push('li');
        this.updateFilterValue('legattributes', { attributes: legAttributes });
        attributesChanged = true;
      }
    } else {
      let cnIndex = linkAttributes.indexOf('cn');
      let liIndex = legAttributes.indexOf('li');
      if (cnIndex !== -1) {
        linkAttributes.splice(cnIndex, 1);
        this.updateFilterValue('linkattributes', { attributes: linkAttributes });
        attributesChanged = true;
      }
      if (liIndex !== -1) {
        legAttributes.splice(liIndex, 1);
        this.updateFilterValue('legattributes', { attributes: legAttributes });
        attributesChanged = true;
      }
    }
    if (attributesChanged) {
      setNotification({
        children: <div>
          The following output params were automatically {isActive ? 'enabled' : 'disabled'} by this action:
          <ul>
            <li>Leg Attributes: Links</li>
            <li>Link Attributes: Consumption</li>
          </ul>
        </div>,
        impact: 'significant',
      });
    }
  }

  onRoRChange(prop, event) {
    this.props.setRangeOnRouteData({ [prop]: +event.target.value });
  }

  onCheckboxChange(prop, event) {
    this.props.setRangeOnRouteData({ [prop]: utils.extractData(event) });
  }

  updateFilterValue(key, value) {
    let { filterValueUpdate, currentForm } = this.props;
    filterValueUpdate({ currentForm, key, value });
  }

  renderRoRForm(isActive) {
    let { totalCapacity, batteryLevel } = this.props.formData.rangeOnRoute;
    if (this.props.currentForm === formsEnum.OLS || this.props.formData.fields.consumptionmodel === 'electric') {
      return <RoRFormElectric
        formData={this.props.formData}
        totalCapacity={totalCapacity}
        batteryLevel={batteryLevel}
        isActive={isActive}
        onChange={::this.onRoRChange}
        onCheckboxChange={::this.onCheckboxChange}
        onToggle={::this.onRoRToggle}
        onBlur={::this.onToggleForm}
      />;
    }
    return <RoRForm
      totalCapacity={totalCapacity}
      batteryLevel={batteryLevel}
      isActive={isActive}
      onChange={::this.onRoRChange}
      onToggle={::this.onRoRToggle}
      onBlur={::this.onToggleForm}
    />;
  }

  render() {
    let isDisabled = !utils.getObject(this.props, 'formData.rangeOnRoute') && this.props.currentForm !== formsEnum.OLS;
    if (isDisabled) {
      return null;
    }
    let isActive = !!utils.getObject(this.props, 'formData.rangeOnRoute.isActive');
    let tooltipText = this.state.formExpanded ? undefined : 'Range on Route';

    return <div>
      <div className="rf-map__ror-toggle">
        <LayerToggle
          active={isActive}
          disabled={false}
          onChange={::this.onToggleForm}
          tooltipText={tooltipText}
        />
      </div>
      {this.state.formExpanded && this.renderRoRForm(isActive)}
    </div>;
  }
}

RoRContainer.propTypes = {
  formData: PropTypes.object.isRequired,
  currentForm: PropTypes.string.isRequired,
  setRangeOnRouteData: PropTypes.func.isRequired,
  filterValueUpdate: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    formData: getCurrentFormData(state),
    currentForm: getForm(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
    setRangeOnRouteData: bindActionCreators(RangeOnRouteActions.setData, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoRContainer);

