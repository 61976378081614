const productionProxyUrl = 'https://proxy.refclient.ext.here.com/';

export default {
  PRODUCTION: {
    app_id: 'cgZPrYfgRePXzXC3PbBp',
    app_code: 'pxIXqdtgOSwQDXSDfjLQpw',
    vwg_user: '',
    vwg_pass: '',
    auth_server: '',
    proxy_url: '',
    consumer_key: 'wmZOoiuYraBBPQpSCNAqkg',
    consumer_secret:
      'IjFD4MeNdm4sqX9Z4MxYj86hVBttdH5sNZrBzAb94PhkiRDHQVRMtiRaIEgDkAAmq0Z9XT583d41PYVpUeLL2w',
    apikey: '-2tUjsluW_sYRxJK8MewPG0ug4AfXEUC7I1aPAd5RV4',
  },
  OLS: {
    proxy_url: productionProxyUrl,
    consumer_key: '',
    consumer_secret: '',
  },
  CIT: {
    app_id: '',
    app_code: '',
    vwg_user: '',
    vwg_pass: '',
    auth_server: '',
    proxy_url: '',
  },
  SNAPSHOT: {
    app_id: '',
    app_code: '',
    vwg_user: '',
    vwg_pass: '',
    auth_server: '',
    proxy_url: '',
  },
  EV_PROXY: {
    app_id: '',
    app_code: '',
    vwg_user: '',
    vwg_pass: '',
    auth_server: '',
    proxy_url: '',
  },
  MAP: {
    apiKey: 'cJ8a29dYDCigcLptL0jgSJlY8pZBJA3lnR-m1NP8r8k',
  },
  INSPECT_LINK: {
    appId: '',
    appCode: '',
  },
  OLS_SEARCH: {
    apiKey: '-2tUjsluW_sYRxJK8MewPG0ug4AfXEUC7I1aPAd5RV4',
  },
};
