import React, { Component } from 'react';
import PropTypes from 'prop-types';

import utils from 'utils';
import CustomInput from 'shared/input';
import FormRow from 'shared/formRow';
import CustomCheckbox from 'shared/checkbox';
import MapFormToggler from './MapFormToggler';

export default class TrafficOverlaysForm extends Component {
  render() {
    let { data = {}, onChange, changeTrafficType } = this.props;
    let { url, path, refreshInterval } = data;
    return <div className="rf-map__tooltip rf-map__to-module" ref="form">
      <h2>Traffic Overlays</h2>
      <MapFormToggler getFormRef={() => this.refs.form} {...this.props} />
      <FormRow>
        <CustomCheckbox
          label="Use Vector Tiles"
          isChecked
          onChange={changeTrafficType}
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Host"
          value={url}
          onBlur={onChange.bind(this, 'url')}
          blurOnEnter
        />
      </FormRow>
      <FormRow>
        <CustomInput
          label="Path"
          value={path}
          onBlur={onChange.bind(this, 'path')}
          blurOnEnter
        />
      </FormRow>
      <FormRow>
        <CustomInput
          type="number"
          min="0"
          label="Refresh Interval (seconds)"
          value={refreshInterval}
          onChange={utils.debounce(onChange, 300).bind(this, 'refreshInterval')}
          blurOnEnter
        />
      </FormRow>
    </div>;
  }
}

TrafficOverlaysForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  changeTrafficType: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
};
