import React from 'react';
import PropTypes from 'prop-types';
import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import { TILE_TYPES, TILE_URLS, TILE_STYLE_URLS } from 'state/map/tiles/constants';
import defaultState from 'state/map/tiles/defaultState';
import SelectWithCustom from './SelectWithCustom';

const vectorDefaultState = defaultState[TILE_TYPES.VECTOR];

class VectorTilesForm extends React.Component {
  onChange = (key, e) => {
    const { onChange } = this.props;
    onChange({ [key]: e.target.value });
  }

  resetSettings = () => {
    this.props.onChange(vectorDefaultState);
  }

  onChangeUrl = (value) => {
    const { onChange } = this.props;
    onChange({ url: value });
  }

  onChangeStyleUrl = (value) => {
    const { onChange } = this.props;
    onChange({ styleUrl: value });
  }

  render() {
    const { url, apiKey, path, styleUrl } = this.props;
    const canResetSettings = !_.isEqual(vectorDefaultState, {
      url, apiKey, path, styleUrl
    });

    return (
      <div>
        <h2>Vector Map Tiles</h2>
        <SelectWithCustom
          options={TILE_URLS}
          value={url}
          label="Url"
          onChange={this.onChangeUrl}
        />
        <FormRow>
        <CustomInput
          cssClasses="rf-clearfix"
          label="Path"
          placeholder="Path"
          isValid
          value={path}
          onBlur={this.onChange.bind(this, 'path')}
          blurOnEnter
        />
        </FormRow>
        <FormRow>
          <CustomInput
            cssClasses="rf-clearfix"
            label="Api Key"
            placeholder="Api Key"
            isValid
            value={apiKey}
            onBlur={this.onChange.bind(this, 'apiKey')}
            blurOnEnter
          />
        </FormRow>
        <SelectWithCustom
          options={TILE_STYLE_URLS}
          value={styleUrl}
          label="Style Url"
          onChange={this.onChangeStyleUrl}
        />
        {canResetSettings && (
          <FormRow>
            <lui-button secondary onClick={this.resetSettings}>Reset Defaults</lui-button>
          </FormRow>
        )}
      </div>
    );
  }
}

VectorTilesForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  styleUrl: PropTypes.string.isRequired,
};

export default VectorTilesForm;
