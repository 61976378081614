import { combineReducers } from 'redux';

import fieldsProxy from '../../common/fields/proxy';
import fieldsValidation from './fields/validation';
import defaultFieldsState from './fields/defaultState';

import waypointsProxy from '../../common/waypoints/proxy';
import isolineWaypointsProxy from '../../common/isolineWaypoints/proxy';
import rangeOnRoute from '../../common/rangeOnRoute/reducer';
import { defaultWaypointsState } from './waypoints/defaultState';

import avoidAreasProxy from '../../common/avoidAreas/proxy';
import formsEnum from 'config/formsEnum';
import currentRoute from './currentRoute/currentRouteReducer';
import highlightedRoute from './highlightedRoute/highlightedRouteReducer';
import highlightedManeuver from './highlightedManeuver/reducer';

let currentForm = formsEnum.CALCULATE_ROUTE;
export default combineReducers(Object.assign({}, {
  fields: fieldsProxy({
    currentForm,
    hasTruckFields: true,
    defaultState: defaultFieldsState,
    validation: fieldsValidation,
  }),
  waypoints: waypointsProxy({ currentForm, defaultState: defaultWaypointsState }),
  isolineWaypoints: isolineWaypointsProxy(currentForm),
  avoidAreas: avoidAreasProxy(currentForm),
  rangeOnRoute,
  currentRoute,
  highlightedRoute,
  highlightedManeuver,
}));
