import { combineReducers } from 'redux';

import formsEnum from 'config/formsEnum';
import defaultFieldsState from './fields/defaultState';
import fieldsValidation from './fields/validation';
import fieldsProxy from '../../common/fields/proxy';
import currentRoute from '../routingForm/currentRoute/currentRouteReducer';
import highlightedRoute from '../routingForm/highlightedRoute/highlightedRouteReducer';
import rangeOnRoute from '../../common/rangeOnRoute/reducer';

let currentForm = formsEnum.OLS;
export default combineReducers(Object.assign({}, {
  fields: fieldsProxy({ currentForm, defaultState: defaultFieldsState, validation: fieldsValidation }),
  currentRoute,
  highlightedRoute,
  rangeOnRoute,
}));
