import React from 'react';
import PropTypes from 'prop-types';
import OlsWaypointAdvanced from './OlsWaypointAdvanced';
import AdvancedPointFields from '../../../shared/advancedPointFields';
import utils from 'utils';
import { getAdvancedWaypointParams } from '../../../../state/tabs/tab/formData/routingTab/common/commonOlsDefaultState';
import handlers from 'utils/url/stringify/formatter/customHandlers';
import { splitAdvancedWaypointParams } from '../../../../utils/url/parser/postprocessors/routing/ols';
import { advancedWaypointValidation } from '../../../../state/tabs/tab/formData/routingTab/common/commonOlsValidation';

class AdvancedViaFields extends AdvancedPointFields {
  parsePointsArr(value) {
    return [...value];
  }

  parseCoords(values) {
    return values.map(value => value.coords);
  }

  stringifyPoints(value) {
    return handlers.via(value);
  }

  onChangePoint(num, e) {
    let coords = utils.extractData(e).replace(' ', '');
    const { value, onChange } = this.props;
    const pointsArr = this.parsePointsArr(value);
    pointsArr[num] = { ...pointsArr[num], coords };
    onChange(pointsArr);
  }

  onRemovePoint(num) {
    const { value, onChange } = this.props;
    const pointsArr = this.parsePointsArr(value);
    pointsArr.splice(num, 1);
    onChange(pointsArr);
  }

  onAddPoint() {
    const { value, onChange } = this.props;
    onChange([...value, { coords: '', advanced: getAdvancedWaypointParams(true) }]);
  }

  onAdvancedChanged(num, key, e) {
    const via = [...this.props.value];
    let value = utils.extractData(e);
    const validation = advancedWaypointValidation();
    const validator = validation[key];
    if (validator) {
      value = {
        value,
        isValid: validator(value),
      };
    }

    via[num].advanced[key] = value;
    this.props.onChange(via);
  }

  setPoints(e) {
    const points = e.target.value;
    const value = points
      .split('&')
      .map(val => val.replace(/^via=/, ''))
      .filter(Boolean)
      .map(val => splitAdvancedWaypointParams(val));
    this.props.onChange(value);
  }

  handleSort = (pointsArr) => {
    const { onChange } = this.props;
    onChange(pointsArr);
  };

  getAdvancedWaypointContent(point, num) {
    return <OlsWaypointAdvanced
      data={this.props.value[num].advanced}
      onChange={this.onAdvancedChanged.bind(this, num)}
    />;
  }
}

AdvancedViaFields.propTypes = {
  value: PropTypes.array.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AdvancedViaFields;
