import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';

import './styles/formRow.scss';

class FormRow extends CustomComponent {
  render() {
    let { cssStyle, isHidden, children } = this.props,
        cssClasses = ['rf-form-row', 'rf-clearfix'];
    if (isHidden) {
      return null;
    }

    return (
      <div className={this.getCssClasses(cssClasses)} id={this.props.id} style={cssStyle}>
        {children}
      </div>
    );
  }
}

FormRow.defaultProps = {
  cssStyle: {},
  isHidden: false,
  id: '',
};

FormRow.propTypes = {
  cssStyle: PropTypes.object.isRequired,
  isHidden: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default FormRow;
