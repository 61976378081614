export default function isNumericSequence(value = '', separator = ',') {
  let valueArr;
  if (typeof value !== 'string') {
    return false;
  }

  if (value.trim() === '') {
    return true;
  }

  valueArr = value.trim().split(separator);

  return valueArr.every(item => item && !isNaN(item));
}
