import utils from 'utils';
import RoutingBase from '../../common/_RoutingBase';
import { Maneuver } from '../../../modules';
import Routes from './Routes';
import Markers from './Markers';
import SelectedPoint from './SelectedPoint';

export default class RouteInfo extends RoutingBase {
  initModules() {
    return {
      routes: new Routes(this.map, this.state),
      markers: new Markers(this.map, this.state),
      selectedPoint: new SelectedPoint(this.map, this.state),
      maneuver: new Maneuver(this.map, this.state),
    };
  }

  getRouteByIndex(route) {
    return route;
  }

  getRoutes(props) {
    return [utils.getObject(props, `tabData.response.data.response.route`, {})];
  }

  shouldUpdateCurrent(nextProps) {
    let isHistoryChanged = this.props.historyLength !== nextProps.historyLength;
    return isHistoryChanged ||
      utils.isPropChanged(this.props, nextProps, 'tabData.response.data.response.route');
  }

  renderResults(args = {}) {
    let {
          responseData = {},
          formData = {},
          isSelectedPointChanged = false,
          currentRoute,
          tabColorPalette,
        } = args,
        { response = {} } = responseData.data,
        route = response.route;
    if (route) {
      this.processRoutes(route, currentRoute, formData.fields, null, tabColorPalette);
      this.modules.selectedPoint.process(formData.fields.selectedPoint, isSelectedPointChanged);
    }
    return true;
  }

  renderCurrent(props = {}, route = {}) {
    let waypoints = (route.waypoint || []).map(() => ({}));
    this.modules.markers.process(waypoints, props.currentRoute, route);
  }

  onDragStart() {}
  onDragEnd() {}
  onDrag() {}
}
