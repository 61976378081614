import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'utils/format';
import moment from 'moment';
import classnames from 'classnames';

import TransportTypeIcon from '../routing/TransportTypeIcon';

import '../routing/styles/resultLegendTable.scss';

class ResultRouteLegendTable extends Component {
  getTransportIcons(sections) {
    const modes = [];
    sections.forEach((section) => {
      let mode = section.type;
      if (mode === 'pedestrian') {
        mode = 'walk';
      } else if (mode === 'taxi') {
        mode = 'taxi';
      } else if (section.transport) {
        mode = section.transport.mode;
        if (section.type === 'rented') {
          mode += 'Share';
        }
      }
      if (mode) {
        if (mode === 'walk' && modes[modes.length - 1] === 'walk') {
          return;
        }
        modes.push(mode);
      }
    });
    return modes.map((mode, idx) => (
      <TransportTypeIcon
        key={`transport-type-icon_${idx}`}
        transportType={mode}
      />
    ));
  }

  getTableRows() {
    const { currentRoute, setRoute, highlightedRoute, setHighlightRoute } = this.props;

    return this.props.responseData.map((route, id) => {
      const isActive = currentRoute === id,
          isHighlighted = highlightedRoute === id,
          sections = route.sections,
          departure = sections[0].departure.time,
          arrival = sections[sections.length - 1].arrival.time,
          rowClasses = classnames({
            'rf-result-legend__table-row': true,
            'rf-result-legend__table-row-active': isActive || isHighlighted,
          });

      const color = this.getColor(isActive, isHighlighted);
      const additionalStyle = color ? { backgroundColor: color } : {};

      const duration = this.calculateDuration(sections, 'summary');
      const travelDuration = this.calculateDuration(sections, 'travelSummary');

      return (
        <tr
          key={`ResultRouteLegendTableRow${id}`}
          className={rowClasses}
          onClick={() => setRoute(id)}
          onMouseEnter={() => setHighlightRoute(id)}
        >
          <td className="rf-result-legend__table-row-timecol">{this.formatDuration(duration, travelDuration)}</td>
          <td>{departure ? moment(departure).format('HH:mm:ss') : 'N/A'}</td>
          <td>{arrival ? moment(arrival).format('HH:mm:ss') : 'N/A'}</td>
          <td className="rf-result-legend__table-row-transportcol" style={additionalStyle}>
            {this.getTransportIcons(sections)}
          </td>
        </tr>
      );
    });
  }

  getColor(isActive, isHighlighted) {
    const palette = this.props.colorPalette;
    if (!palette) {
      return null;
    }

    if (isActive) {
      return palette.primary;
    } else if (isHighlighted) {
      return palette.secondaryDarker;
    }
    return palette.secondary;
  }

  calculateDuration(sections, summaryField) {
    return sections.reduce((memo, section) => memo + (section[summaryField] || {}).duration, 0);
  }

  formatDuration(duration, travelDuration) {
    const positiveDurations = [duration, travelDuration].filter(d => d > 0);
    switch (positiveDurations.length) {
      case 1:
        return format.time(positiveDurations[0], 'short');
      case 2:
        return `${format.time(duration, 'short')} (${format.time(travelDuration, 'short')})`;
      default:
        return format.time(0, 'short');
    }
  }

  render() {
    return (
      <table className="rf-result-legend__table" onMouseLeave={() => this.props.setUnhighlightRoute()}>
        <tbody>
          <tr className="rf-result-legend__table-header">
            <th>Travel Time</th>
            <th>Departure</th>
            <th>Arrival</th>
            <th>Transport</th>
          </tr>
          {this.getTableRows()}
        </tbody>
      </table>
    );
  }
}

ResultRouteLegendTable.defaultProps = {
  setHighlightRoute: () => {},
  setUnhighlightRoute: () => {},
};

ResultRouteLegendTable.propTypes = {
  responseData: PropTypes.array.isRequired,
  currentRoute: PropTypes.number.isRequired,
  setRoute: PropTypes.func.isRequired,
  highlightedRoute: PropTypes.number,
  setHighlightRoute: PropTypes.func,
  setUnhighlightRoute: PropTypes.func,
  colorPalette: PropTypes.object,
};

export default ResultRouteLegendTable;
