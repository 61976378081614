import utils from 'utils';
import uPointArgsCount from 'utils/response/pointArgsCount';

import mapRoute from '../../utils/RouteHelper';

import FormBase from '../_Base';
import React from 'react';

export default class _RoutingBase extends FormBase {
  dragStarted = false;

  process(props, nextProps, forceRender = false) {
    super.process(props, nextProps, forceRender);

    let { tabData } = nextProps;
    const data = tabData.response.data || {};
    const response = data.response || {};
    let nextRoutes = response.route || data.routes || [];

    if (!tabData.response.isError) {
      let isCurrentHistoryRecChanged = nextProps.currentHistoryRec !== this.props.currentHistoryRec;

      if (this.isRubberBandingAllowed === true) {
        this.modules.routes.initRubberBanding();
      }

      this.formData = tabData.formData;
      const currentRoute = nextProps.tabData.formData.currentRoute;
      const isSelectedPointChanged = this.isPropChanged(nextProps, `tabData.formData.fields.selectedPoint`);
      if (forceRender || this.shouldUpdateRoutes(nextProps, isSelectedPointChanged, isCurrentHistoryRecChanged)) {
        this.renderResults({
          responseData: tabData.response,
          formData: tabData.formData,
          currentRoute,
          isSelectedPointChanged,
          tabColorPalette: tabData.tabColorPalette,
        });
      }
      this.showNotesNotifications(props, nextProps);

      const oldHighlighted = this.props.tabData.formData.highlightedRoute,
            nextHighlighted = nextProps.tabData.formData.highlightedRoute;
      if (oldHighlighted !== nextHighlighted) {
        const palette = nextProps.tabData.tabColorPalette;
        this.modules.routes.highlight(oldHighlighted, nextHighlighted, currentRoute, palette);
      }

      if (this.renderManeuvers) {
        this.renderManeuvers(nextProps);
      } else if (this.modules.maneuver && nextProps.tabData.formData.highlightedManeuver &&
        this.isPropChanged(nextProps, 'tabData.formData.highlightedManeuver.shape')) {
        this.modules.maneuver.process({
          ...nextProps.tabData.formData.highlightedManeuver,
          color: nextProps.tabData.tabColorPalette.primaryDarker,
        });
      }
    } else {
      this.modules.routes.clearGroups();
    }

    if (forceRender || this.shouldUpdateCurrent(nextProps)) {
      this.setProps(nextProps);
      this.renderCurrent(nextProps, nextRoutes);
      return;
    }

    this.setProps(nextProps);
  }

  getRouteByIndex(routes, currentRoute) {
    return routes[currentRoute];
  }

  getRoutes(props) {
    return utils.getObject(props, `tabData.response.data.response.route`);
  }

  setViewBounds(force) {
    super.setViewBounds(this.modules.routes.getGroup(), force);
  }

  shouldUpdateRoutes(nextProps, isSelectedPointChanged) {
    let isCurrentRouteChanged = nextProps.tabData.formData.currentRoute !== this.props.tabData.formData.currentRoute;
    let isRangeOnRouteChanged = this.isPropChanged(nextProps, `tabData.formData.rangeOnRoute`);

    return isRangeOnRouteChanged ||
      isCurrentRouteChanged ||
      isSelectedPointChanged ||
      utils.isPropChanged(this.props, nextProps, 'tabData.tabColorPalette') ||
      utils.isPropChanged(this.props, nextProps, 'responseData') ||
      mapRoute.isAnyRouteChanged(this.getRoutes(this.props), this.getRoutes(nextProps),
        uPointArgsCount(nextProps.tabData.formData.fields.jsonattributes,
          this.props.tabData.formData.fields.returnelevation));
  }

  shouldUpdateCurrent(nextProps) {
    let isHistoryChanged = this.props.historyLength !== nextProps.historyLength;
    return isHistoryChanged ||
          utils.isPropChanged(this.props, nextProps, 'tabData.tabColorPalette') ||
          utils.isPropChanged(this.props, nextProps, 'currentRoute') ||
          utils.isPropChanged(this.props, nextProps, 'formData.avoidAreas') ||
          utils.isPropChanged(this.props, nextProps, 'formData.waypoints') ||
          utils.isPropChanged(this.props, nextProps, 'formData.waypoints') ||
          utils.isPropChanged(this.props, nextProps, 'formData.fields.makereachable') ||
          utils.isPropChanged(this.props, nextProps,
            `tabData.response.data.response.route[${this.props.currentRoute}].waypoint`);
  }

  renderCurrent(props, routes) {
    if (props.selectedTab === this.state.index) {
      this.modules.areas.process(props.tabData.formData.avoidAreas);
    } else {
      this.modules.areas.clearGroups();
    }
    this.processMarkers(props, routes);
  }

  processMarkers(props = {}, routes) {
    let { currentRoute } = props.tabData.formData;
    let formData = props.tabData.formData;
    let route = this.getRouteByIndex(routes, currentRoute);
    this.modules.markers.process(formData.waypoints, currentRoute, route);
  }

  renderResults(args = {}) {
    let {
          responseData = {},
          formData = {},
          currentRoute,
          isSelectedPointChanged = false,
          tabColorPalette,
        } = args,
        { response = {} } = responseData.data,
        routes = response.route || [];

    if (!routes.length) {
      return false;
    }

    let energy;
    let {
      isActive, totalCapacity, batteryLevel, initialcharge, minchargeatstop, maxcharge, isChargingAllowed
    } = formData.rangeOnRoute;
    if (isActive) {
      if (formData.fields.consumptionmodel === 'electric') {
        energy = { initialcharge, minchargeatstop, maxcharge, isChargingAllowed };
      } else {
        energy = { initialcharge: (totalCapacity / 100) * batteryLevel, maxcharge: batteryLevel };
      }
    }

    this.processRoutes(routes, currentRoute, formData.fields, energy, tabColorPalette);
    this.modules.selectedPoint.process(formData.fields.selectedPoint, isSelectedPointChanged);

    return true;
  }

  processRoutes(routes, currentRoute, { jsonattributes, returnelevation }, energy = {}, tabColorPalette = null) {
    this.modules.routes.setAdditionalData(currentRoute, uPointArgsCount(jsonattributes, returnelevation), energy);
    this.modules.routes.process(routes, tabColorPalette);
  }

  showNotesNotifications(props, nextProps) {
    const TIMESTAMP_PATH = 'tabData.response.data.response.metaInfo.timestamp';
    if (_.get(props, TIMESTAMP_PATH) === _.get(nextProps, TIMESTAMP_PATH)) {
      return;
    }

    let routes = _.get(nextProps, 'tabData.response.data.response.route', []);
    if (!utils.isArray(routes)) { // converting for GetRoute form response
      routes = [routes];
    }
    routes.forEach(route => {
      _.get(route, 'note', []).forEach(note => {
        this.props.setNotification({
          children: <div>
            Route note:
            <ul>
              <li>Code: {note.code}</li>
              <li>Text: {note.text}</li>
            </ul>
          </div>,
          impact: note.type === 'warning' ? 'significant' : 'positive',
          autoDismiss: 5,
        });
      });
    });
  }

  _handleDragging(method, args, modules = {}) {
    let targetData = args[0],
        _modules = Object.assign({
          MAIN: this.modules.markers,
          RESIZE: this.modules.areas,
        }, modules);
    this.applyModule(_modules[targetData.type], method, args);
  }

  onDragStart(...args) {
    this.dragStarted = true;
    this._handleDragging('onDragStart', args);
  }

  onDrag(...args) {
    this._handleDragging('onDrag', args, {
      MAIN: this.modules.routes,
    });
  }

  onDragEnd(...args) {
    if (!this.dragStarted) {
      return;
    }
    this._handleDragging('onDragEnd', args);
    this.dragStarted = false;
  }
}
