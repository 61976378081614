import React from 'react';
import PropTypes from 'prop-types';

import FormRow from 'shared/formRow';
import CustomInput from 'shared/input';
import responseErrorShape from 'state/tabs/tab/response/responseErrorShape';

import FormattedResultBtn from './FormattedResultBtn';
import ResultPanelFormBase from './_ResultPanelFormBase';

class ResultPanelErrors extends ResultPanelFormBase {
  getContent() {
    let responseData = this.props.responseData || {},
        data = responseData.data || {};

    return (
      <div className="rf-sidebar__error">
        <h4 className="rf-sidebar__header rf-sidebar__header_error">Error</h4>
        <div>
          <FormRow isHidden={!data.type}>
            <CustomInput
              type="text"
              label="Type"
              value={data.type}
              isReadonly
            />
          </FormRow>
          <FormRow isHidden={!data.subtype}>
            <CustomInput
              type="text"
              label="Subtype"
              value={data.subtype}
              isReadonly
            />
          </FormRow>
          <FormRow>
            <CustomInput
              type="text"
              label="Details"
              value={data.details || data.message || data.title || responseData.statusText}
              isReadonly
            />
          </FormRow>
          <FormRow>
            <FormattedResultBtn onClick={this.props.onShowFormattedResult} />
          </FormRow>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.getContent()}
        {this.getInspectLinkData()}
        {this.getSearchResults()}
      </div>
    );
  }
}

ResultPanelErrors.propTypes = {
  responseData: responseErrorShape,
  onShowFormattedResult: PropTypes.func.isRequired,
};

export default ResultPanelErrors;
