import utils from 'utils';
import tabs from './tabs';
import silentParams from 'config/silentParams';
import { urlToObject } from 'utils/url';

export default function isFormPerformedForRequest(module, currentForm, formData, apiUrl, oldApiUrl) {
  if (apiUrl && oldApiUrl) {
    let diffKeys = utils.objectDiffKeys(urlToObject(apiUrl), urlToObject(oldApiUrl));
    if (!utils.without(diffKeys, ...silentParams).length) {
      return false;
    }
  }
  let exec = utils.getObject(tabs, `${module}.${currentForm}`);
  return utils.isFunction(exec) ? exec(formData) : true;
}
