import execTruckParams from '../common/truck';

export default function exec(data) {
  const _data = execTruckParams(data);

  if (_data.rangetype !== 'consumption') {
    delete _data.consumptionmodel;
  }

  if (!_data.consumptionmodel || _data.consumptionmodel === 'default') {
    delete _data.customconsumptiondetails;
  }
  if (_data.consumptionmodel !== 'electric') {
    delete _data.batteryparameters;
    delete _data.chargingstationfilters;
    delete _data.makereachable;
    delete _data.highspeedthreshold;
  }

  return _data;
}
