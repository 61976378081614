import getGeocoderSettings from 'config/geocoder';
import { stringifyUrl, objectToQueryString } from 'utils/url';
import fetch from 'utils/fetch';

const defauldSettings = { app_code: '', app_id: '', language: '' };

export function reverseGeocode(coords = '', settings, isOls = false) {
  if (!settings) {
    settings = { ...defauldSettings };
  }

  let reverseParams = { ...settings };

  if (!isOls) {
    reverseParams = {
      ...reverseParams,
      prox: coords,
      mode: 'retrieveAddresses',
      maxresults: 1,
      jsonattributes: 1,
    };
  } else {
    reverseParams = {
      ...reverseParams,
      at: coords,
    };
  }

  const urlData = getGeocoderSettings('reversegeocode', isOls ? 'ols' : 'hls').url;

  let reverseUrl = stringifyUrl({
    queryParams: objectToQueryString(reverseParams),
  }, urlData).apiUrl;

  return fetch(reverseUrl, false);
}
