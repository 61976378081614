import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ExpandFormRow from 'shared/expandFormRow';
import Columns from 'shared/columns';

import FeatureRow from './FeatureRow';

class FeaturesGroup extends Component {
  getFeatureRows() {
    let { features = {}, formConfig } = this.props;

    return formConfig.features.map((feature, id) => {
      let data = features[feature.value] || {};
      return <FeatureRow
        key={id}
        label={feature.label}
        value={feature.value}
        selectValue={data.value}
        isChecked={data.isChecked}
        formConfig={formConfig}
        onChange={this.props.onChange}
      />;
    });
  }

  render() {
    return (
      <ExpandFormRow label={this.props.label} cssClasses="rf-features-group">
        <Columns>
          {this.getFeatureRows()}
        </Columns>
      </ExpandFormRow>
    );
  }
}

FeaturesGroup.defaultProps = {
  features: {},
};

FeaturesGroup.propTypes = {
  label: PropTypes.string.isRequired,
  features: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FeaturesGroup;
