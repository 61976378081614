import { LOCATION_TYPES } from 'state/tabs/tab/formData/routingTab/approachPathRouterForm/fields/constants';
import handlers from '../../formatter/customHandlers';
import { locationTypeToFieldName } from '../../../../smartRequestTriggering/tabs/routing/approachPathRouter';

export default function exec(data) {
  const _data = Object.assign({}, data);

  switch (_data.locationType) {
    case LOCATION_TYPES.GEO:
    case LOCATION_TYPES.LINK:
    case LOCATION_TYPES.STREET:
      _data.location = handlers.waypointsFormatter([toWaypointFormat({
        category: _data.locationType,
        type: '',
        userLabel: _data.userLabel,
        transitRadius: _data.transitRadius,
        stopOverDuration: data.stopOverDuration.value,
        heading: _data.heading.value,
        displayPosition: _data.displayPosition.value,
        coords: _data[locationTypeToFieldName[_data.locationType]].value,
        link: _data.locationLink.value,
        street: _data.street,
      })], { ..._data, onlyValue: true });
      break;
    case LOCATION_TYPES.OPEN_LR:
    case LOCATION_TYPES.ETL:
      _data.location = `${_data.locationType}!${_data[locationTypeToFieldName[_data.locationType]].value}`;
      break;
    case LOCATION_TYPES.TMC:
      _data.location = `${_data.locationType}!${_data[locationTypeToFieldName[_data.locationType]].value}`;
      if (_data.TMCExtent.value) {
        _data.location += `e${_data.TMCExtent.value}`;
      }
      if (_data.TMCOffset.value) {
        _data.location += `,${_data.TMCOffset.value}`;
      } else if (_data.TMCLength.value) {
        _data.location += ',0';
      }
      if (_data.TMCLength.value) {
        _data.location += `,${_data.TMCLength.value}`;
      }
      break;
    default:
      throw new Error(`Unknown location type - ${_data.locationType}`);
  }

  delete _data.locationType;
  delete _data.locationGeo;
  delete _data.locationLink;
  delete _data.locationStreet;
  delete _data.locationOpenLR;
  delete _data.locationTMC;
  delete _data.locationETL;
  delete _data.heading;
  delete _data.userLabel;
  delete _data.stopOverDuration;
  delete _data.transitRadius;
  delete _data.displayPosition;
  delete _data.street;
  delete _data.streetPosition;
  delete _data.TMCExtent;
  delete _data.TMCOffset;
  delete _data.TMCLength;

  return _data;
}

function toWaypointFormat(data) {
  const {
    category, type, userLabel, transitRadius, stopOverDuration, heading, displayPosition, coords, link, street,
  } = data;
  return {
    category,
    type,
    userLabel,
    transitRadius,
    stopOverDuration: {
      value: stopOverDuration,
    },
    heading: {
      value: heading,
    },
    displayPosition: {
      value: displayPosition,
    },
    coords: {
      value: coords,
    },
    link: {
      value: link,
    },
    street: {
      name: street,
    },
  };
}
