import Routing from './routing';
import Matrix from './matrix';
import Isoline from './isoline';
import RouteInfo from './routeInfo';
import ApproachPathRouter from './approachPathRouter';
import ols from './ols';

import formsEnum from 'config/formsEnum';

export default {
  [formsEnum.CALCULATE_ROUTE]: Routing,
  [formsEnum.CALCULATE_MATRIX]: Matrix,
  [formsEnum.CALCULATE_ISOLINE]: Isoline,
  [formsEnum.GET_ROUTE_INFO]: RouteInfo,
  [formsEnum.APPROACH_PATH_ROUTER]: ApproachPathRouter,
  [formsEnum.OLS]: ols,
};
