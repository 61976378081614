import utils from 'utils';
import * as types from './constants';
import { getSelectedTab } from 'state/selectedTab';

export function restoreFromUrl(data, tabIndex) {
    return assignTabDataAndDispatch({ type: types.RESTORE_FROM_URL, data }, tabIndex);
}

export function assignTabDataAndDispatch(action, tabIndex) {
  return (dispatch, getState) => {
    let state = getState();
    let tabId = action.tabData && action.tabData.tabId;
    let index;
    if (!tabIndex && !tabId) {
      index = tabIndex === undefined ? getSelectedTab(state) : tabIndex;
    } else if (tabId) {
     index = utils.findIndex(state.tabs, { id: tabId });
    } else {
      index = tabIndex;
    }
    let { form, module } = state.tabs[index];
    dispatch({ ...action, tabData: { ...(action.tabData || {}), index, form, module } });
  };
}
