import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-c3-component';

import CustomComponent from 'shared/component';

import 'c3/c3.css';
import './styles/chart.scss';

class CustomChart extends CustomComponent {
  point = null;

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.data.columns, nextProps.data.columns);
  }

  getDefaultOptions() {
    return {
      zoom: {
        enabled: true,
        rescale: true,
      },
      grid: {
        x: { show: true },
        y: { show: true },
      },
      legend: { show: false },
      tooltip: { show: false },
    };
  }

  onMouseOver(obj) {
    this.point = obj;

    const { onMouseOver } = this.props;
    if (onMouseOver) {
      onMouseOver(obj);
    }
  }

  onMouseOut() {
    this.point = null;

    const { onMouseOut } = this.props;
    if (onMouseOut) {
      onMouseOut();
    }
  }

  // We need this on click handling because the data.onclick from c3.js does not work.
  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.point);
    }
  }

  render() {
    let { data, options } = this.props,
      opts = Object.assign(this.getDefaultOptions(), options),
      config = Object.assign({}, opts, { data });
    data.onmouseover = ::this.onMouseOver;
    data.onmouseout = ::this.onMouseOut;

    return (
      <Chart
        className="rf-chart"
        config={config}
        onClick={::this.onClick}
      />
    );
  }
}

CustomChart.defaultProps = {
  options: {},
};

CustomChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

export default CustomChart;
