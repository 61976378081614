import WaypointsGroupBase from '../../common/WaypointsGroup';
import LinkItem from './waypointsGroup/LinkItem';
import GeoItem from './waypointsGroup/GeoItem';
import StreetItem from './waypointsGroup/StreetItem';

class WaypointsGroup extends WaypointsGroupBase {
  getGroupElClass(category) {
    return {
      geo: GeoItem,
      link: LinkItem,
      street: StreetItem,
    }[category];
  }

  getLabel(point) {
    return point.key;
  }
}

export default WaypointsGroup;
