import handlers from './customHandlers';

export default {
  return: handlers.arrayFormatter,
  spans: handlers.arrayFormatter,
  avoid: handlers.arrayFormatter,
  'avoid[features]': handlers.arrayFormatter,
  'avoid[areas]': olsAvoidAreasFormatter,
  'truck[shippedHazardousGoods]': handlers.arrayFormatter,
  mode: handlers.modeFormatter,
  via: handlers.via,
  'ev[connectorTypes]': handlers.arrayFormatter,
};

function olsAvoidAreasFormatter(avoidAreas) {
  return avoidAreas
    .filter((avoidArea) => avoidArea.isValid && avoidArea.value)
    .map((avoidArea) => avoidArea.value)
    .join('|');
}
