import * as types from './constants';

export default function (state = false, action) {
  switch (action.type) {
    case types.SET_IS_HISTORY_SHOWN:
      return action.isShown;
    default:
      return state;
  }
}
