import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import utils from 'utils';

import ResultPanelErrors from './ResultPanelErrors';
import Sidebar from '../sidebar';
import hasResults from './hasResults';
import { RESULT_FORMS } from './resultForms';

import * as currentRouteActions from 'state/tabs/tab/formData/routingTab/routingForm/currentRoute/currentRouteActions';
import * as highlightedRouteActions from
  'state/tabs/tab/formData/routingTab/routingForm/highlightedRoute/highlightedRouteActions';
import { setIsoline } from 'state/tabs/tab/formData/routingTab/isolineForm/currentIsoline';
import * as resultPanelActions from 'state/resultPanel/resultPanelActions';
import * as FieldsActions from 'state/tabs/tab/formData/common/fields/actions';
import * as inspectLinkActions from 'state/inspectLink/actions';
import * as reverseGeocodeActions from 'state/reverseGeocode/actions';
import * as highlightedManeuverActions
  from 'state/tabs/tab/formData/routingTab/routingForm/highlightedManeuver/actions';
import * as NotificationActions from 'state/notification/actions';
import { getInspectLink } from 'state/inspectLink';

import './styles/resultPanel.scss';
import { getCurrentFormData } from 'state/tabs/tab/formData';
import { getResponseData } from 'state/tabs/tab/response';
import { getForm } from 'state/tabs/tab/form';
import { getModule } from 'state/tabs/tab/module';
import RawResponse from '../rawResponse/RawResponse';
import Loader from 'shared/loader';
import { getIsLoading } from 'state/tabs/tab/isLoading';
import { setSelectedTrace } from 'state/tabs/tab/formData/routingTab/approachPathRouterForm/selectedTrace/actions';
import { addTab } from 'state/tabs/actions';
import { getReverseGeocodeInfo } from '../../state/reverseGeocode';
import { set as setWaypoints } from 'state/tabs/tab/formData/common/waypoints/actions';

export class ResultPanelContainer extends Component {
  constructor(props) {
    super(props);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.state = {
      isFormattedResultShown: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isResultPanelShown && !this.hasAnyResult(nextProps)) {
      this.props.setVisibility(false);
      return false;
    }
    return true;
  }

  onShowFormattedResult() {
    this.setState({ isFormattedResultShown: true });
  }

  onHideFormattedResult() {
    this.setState({ isFormattedResultShown: false });
  }

  getResultComponentEl(responseData) {
    let { currentForm, module } = this.props;
    let ResultComponent = responseData.isError ? ResultPanelErrors : RESULT_FORMS[module][currentForm];
    if (!ResultComponent || !this.hasAnyResult(this.props)) {
      return '';
    }

    return (
      <ResultComponent
        onShowFormattedResult={::this.onShowFormattedResult}
        onRemoveInspectLink={::this.removeInspectLink}
        onRemoveReverseGeocode={::this.removeReverseGeocode}
        {...this.props}
      />
    );
  }

  getLoader() {
    if (this.props.isLoading) {
      return (
        <div className="rf-result-panel__loader">
          <Loader />
        </div>
      );
    }
    return null;
  }

  removeInspectLink() {
    let { removeInspectLink, currentForm } = this.props;
    removeInspectLink({ currentForm });
  }

  removeReverseGeocode() {
    let { removeReverseGeocode, currentForm } = this.props;
    removeReverseGeocode({ currentForm });
  }

  toggleVisibility(e) {
    e.preventDefault();
    this.props.setVisibility(!this.props.isResultPanelShown);
  }

  hasInspectLinkData(props) {
    let { inspectLink = {} } = props;
    return Object.keys(inspectLink).length > 0;
  }

  hasReverseGeocodeData(props) {
    let { reverseGeocode = [] } = props;
    return reverseGeocode.length > 0;
  }

  hasSearchResults(props) {
    return props.search.results.length > 0;
  }

  hasAnyResult(props) {
    if (this.hasInspectLinkData(props) || this.hasReverseGeocodeData(props) || this.hasSearchResults(props)) {
      return true;
    }
    let { currentForm, module } = props;
    let hasResults_ = hasResults[module][currentForm];

    if (!hasResults_) {
      throw Error(`Can't find ${currentForm} form on module ${module}.
                   Please check whether a method was added to './hasResults.js'`);
    }
    return hasResults_(props);
  }

  render() {
    const { module, currentForm } = this.props;
    const isResultPanelPresent = !!utils.getObject(hasResults, `${module}.${currentForm}`);
    if (!isResultPanelPresent) {
      return null;
    }

    let classes = ['rf-result-panel'],
        responseData = this.props.responseData || {},
        btnClasses = classnames('rf-result-panel__toggler', {
          'rf-result-panel__toggler_visible': this.hasAnyResult(this.props),
        });

    if (this.props.isResultPanelShown) {
      classes.push('rf-result-panel_fadeout');
    }

    return (
      <div>
        <Sidebar cssClasses={classes}>
          {this.getLoader()}
          <div className={btnClasses} onClick={this.toggleVisibility}></div>
          {this.getResultComponentEl(responseData)}
        </Sidebar>
        <RawResponse
          closeModal={::this.onHideFormattedResult}
          responseData={responseData}
          isModalShown={this.state.isFormattedResultShown}
        />
      </div>
    );
  }
}

ResultPanelContainer.defaultProps = {
  formData: {},
};

ResultPanelContainer.propTypes = {
  setVisibility: PropTypes.func.isRequired,
  setRoute: PropTypes.func.isRequired,
  setHighlightedRoute: PropTypes.func.isRequired,
  mapObjectUpdate: PropTypes.func.isRequired,
  removeInspectLink: PropTypes.func.isRequired,
  removeReverseGeocode: PropTypes.func.isRequired,
  setSelectedTrace: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  isResultPanelShown: PropTypes.bool.isRequired,
  currentRoute: PropTypes.number,
  highlightedRoute: PropTypes.number,
  currentIsoline: PropTypes.number,
  currentForm: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  inspectLink: PropTypes.object.isRequired,
  reverseGeocode: PropTypes.array.isRequired,
  selectedTabData: PropTypes.object.isRequired,
  responseData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  let formData = getCurrentFormData(state);
  return {
    responseData: getResponseData(state),
    formData,
    isResultPanelShown: state.isResultPanelShown,
    currentRoute: formData.currentRoute,
    highlightedRoute: formData.highlightedRoute,
    currentIsoline: formData.currentIsoline,
    currentForm: getForm(state),
    module: getModule(state),
    selectedEntity: state.selectedEntity,
    inspectLink: getInspectLink(state),
    reverseGeocode: getReverseGeocodeInfo(state),
    search: state.search,
////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
//////////////
    selectedNearbyStn: formData.selectedNearbyStn,

    selectedTabData: state.tabs[state.selectedTab],
    isLoading: getIsLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mapObjectUpdate: bindActionCreators(FieldsActions.mapObjectUpdate, dispatch),
    setVisibility: bindActionCreators(resultPanelActions.setVisibility, dispatch),
    setRoute: bindActionCreators(currentRouteActions.setRoute, dispatch),
    setHighlightedRoute: bindActionCreators(highlightedRouteActions.setHighlightedRoute, dispatch),
    removeReverseGeocode: bindActionCreators(reverseGeocodeActions.remove, dispatch),
    removeInspectLink: bindActionCreators(inspectLinkActions.remove, dispatch),
    highlightManeuver: bindActionCreators(highlightedManeuverActions.highlightManeuver, dispatch),
    setIsoline: bindActionCreators(setIsoline, dispatch),
    filterValueUpdate: bindActionCreators(FieldsActions.filterValueUpdate, dispatch),
    filterValueMultiUpdate: bindActionCreators(FieldsActions.filterValueMultiUpdate, dispatch),
    setSelectedTrace: bindActionCreators(setSelectedTrace, dispatch),
    addTab: bindActionCreators(addTab, dispatch),
    setNotification: bindActionCreators(NotificationActions.set, dispatch),
    setWaypoints: bindActionCreators(setWaypoints, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultPanelContainer);
