import isNumberValid from 'utils/validation/numericValidation';
import speedValidation from 'utils/validation/speedValidation';
import listValidation from 'utils/validation/listValidation';
import formConfig from 'config/form';
import formsEnum from 'config/formsEnum';

const config = formConfig(formsEnum.CALCULATE_ROUTE);

let isPositiveNumber = isNumberValid({ min: 0 });

export default {
  'customconsumptiondetails.ascent': isPositiveNumber,
  'customconsumptiondetails.descent': isPositiveNumber,
  'customconsumptiondetails.speed': speedValidation,
  'customconsumptiondetails.auxiliaryconsumption': isPositiveNumber,
  'customconsumptiondetails.deceleration': isPositiveNumber,
  'customconsumptiondetails.acceleration': isPositiveNumber,
  'customconsumptiondetails.turnpenalty': isPositiveNumber,

  'customconsumptiondetails.freeflowspeedtable': speedValidation,
  'customconsumptiondetails.trafficspeedtable': speedValidation,
  'customconsumptiondetails.trafficscaletable': speedValidation,

  'batteryparameters.maxcharge': isPositiveNumber,
  'batteryparameters.initialcharge': isPositiveNumber,
  'batteryparameters.minchargeatstop': isPositiveNumber,
  'batteryparameters.chargingstopdeparturecharge': isPositiveNumber,
  'batteryparameters.chargingdurationoffset': isPositiveNumber,
  'batteryparameters.maxvoltage': isPositiveNumber,
  'batteryparameters.maxcurrent': isPositiveNumber,
  'batteryparameters.minchargeatchargingstop': isPositiveNumber,
  'batteryparameters.minchargeatfirstchargingstop': isPositiveNumber,
  'batteryparameters.minchargeatdestination': isPositiveNumber,
  'batteryparameters.chargingcurve': speedValidation,

  'chargingstationfilters.plugtype': listValidation(config.plugTypes),

  highspeedthreshold: isPositiveNumber,
};
