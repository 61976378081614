import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../../shared/button';
import vkBeautify from 'vkbeautify';
import { xmlifyDeparture, xmlifyRouteOptions, xmlifyWaypoints } from './importExportHelpers';
import utils from 'utils';

export default class ExportRoute extends React.Component {
  static propTypes = {
    formData: PropTypes.object.isRequired,
  };

  onExportRoute = () => {
    const { waypoints, fields } = this.props.formData;
    const xml = document.createElementNS(null, 'route_plan');
    xmlifyWaypoints(waypoints).forEach((el) => {
      xml.appendChild(el);
    });
    const departureEl = xmlifyDeparture(fields);
    if (departureEl) {
      xml.appendChild(departureEl);
    }
    xml.appendChild(xmlifyRouteOptions(fields));

    const serializer = new XMLSerializer();
    utils.downloadFile(
      `route-${new Date().getTime()}.xml`,
      vkBeautify.xml(`<?xml version='1.0' encoding='UTF-8'?>${serializer.serializeToString(xml)}`, 2),
    );
  }

  render() {
    return (
      <CustomButton
        title="Export route to xml"
        onClick={this.onExportRoute}
      />
    );
  }
}
