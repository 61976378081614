export default {
  'truck[shippedHazardousGoods]': [],
  'truck[tunnelCategory]': '',
  'truck[axleCount]': '',
  'truck[length]': '',
  'truck[width]': '',
  'truck[height]': '',
  'truck[weightPerAxle]': '',
  'truck[grossWeight]': '',
};
