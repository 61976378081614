import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DateTimeField from './routeDateField/DateTimeField';
import TabsControl from 'shared/tabsControl';

import utilsDate from 'utils/date';

import './styles/routeDateField.scss';

class RouteDateField extends Component {
  constructor(props) {
    super(props);
    this.onKeyChange = this.onKeyChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  onKeyChange(key) {
    this.props.onChange({
      key,
      value: key === '' ? '' : utilsDate.getLocalDate().toISOString().split('.')[0],
    });
  }

  onDateChange(date = '', timezone) {
    this.props.onChange({
      key: this.props.routeDate.key,
      value: this.generateDate(date.split('.')[0], timezone),
    });
  }

  getDateTimeEl(routeDate = {}) {
    let { date = '', timezone = '' } = utilsDate.splitDate(routeDate.value);
    if (routeDate.key === '' || routeDate.key === 'any' || routeDate.key === 'now') {
      return null;
    }
    return (
      <DateTimeField
        date={date}
        timezone={timezone}
        onChange={this.onDateChange}
      />
    );
  }

  generateDate(date, timezone) {
    return `${date}${timezone}`;
  }

  render() {
    let { label, routeDate, formConfig, value } = this.props;

    let tabsData = formConfig.routeTimeModes.map(mode => ({
      label: mode.label,
      name: mode.value,
    }));

    return (
      <div className="rf-routedate-field">
        <h3>{label}</h3>
        {value && <h4>{value}</h4>}
        <TabsControl currentTabName={routeDate.key} onChange={::this.onKeyChange} tabsData={tabsData} />
        <div className="rf-routedate-field__value">
          {this.getDateTimeEl(routeDate)}
        </div>
      </div>
    );
  }
}

RouteDateField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  routeDate: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RouteDateField;
