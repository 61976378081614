import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/historyList.scss';

export class HistoryList extends Component {
  getResultIcon(tabData) {
    if (tabData.response.isError) {
      return <i className="fa fa-exclamation-circle"> {tabData.response.statusCode}</i>;
    }
    return <i className="fa fa-check-circle"> {tabData.response.statusCode}</i>;
  }

  render() {
    let { selectedHistory, history, isHistoryShown, onClick } = this.props;
    const historyClasses = classnames('rf-history-list', {
      'rf-history-list_hidden': !isHistoryShown,
    });
    let historyItems = [...history].reverse().map((item, idx) => {
      const historyIndex = history.length - idx - 1;
      let classes = classnames({
        'rf-history-list__item': true,
        'rf-history-list__item_active': historyIndex === selectedHistory,
      });

      return (
        <li className={classes} key={idx} onClick={onClick.bind(this, historyIndex)}>
          <span className="rf-history-list__item-number">{historyIndex + 1}</span>
          <span className="rf-history-list__item-status">
            {this.getResultIcon(item.tabData)}
          </span>
          <span className="rf-history-list__item-url">{item.url}</span>
        </li>
      );
    });

    return (
      <ul className={historyClasses}>
        {historyItems}
      </ul>
    );
  }
}

HistoryList.propTypes = {
  history: PropTypes.array.isRequired,
  selectedHistory: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isHistoryShown: PropTypes.bool.isRequired,
};

export default HistoryList;
