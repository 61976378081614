import { getAvoidAreaColor, ZINDEX } from 'config/map';
import ModuleBase from './_Base';
import { Rect } from './Rect';
import * as mapMarkers from '../utils/MarkersHelper';

export default class AvoidAreas extends ModuleBase {
  process(areas = []) {
    this.clearGroups();
    let options = { style: this.getRectStyle() };
    let mainGroup = this.getGroup();
    this.markerTextArr = [];
    areas.forEach((area, index) => {
      if (area.isValid && area.value && area.isShown) {
        let [topLeft, bottomRight] = area.value.split(';');
        let [top, left] = topLeft.split(',').map(value => +value);
        let [bottom, right] = bottomRight.split(',').map(value => +value);
        let bounds = new H.geo.Rect(top, left, bottom, right);
        let rectObj = new Rect(this.map, { bounds, options },
          this.onChange.bind(this, index), this.onDrag.bind(this, index));
        mainGroup.addObject(rectObj.rect);
        this.markerTextArr[index] = this.addLabel(bounds, index);
      }
    });
  }

  onDrag(index, bounds) {
    this.markerTextArr[index].setGeometry({ lat: bounds.getTop(), lng: bounds.getRight() });
  }

  getRectStyle() {
    return {
      strokeColor: getAvoidAreaColor(),
      fillColor: getAvoidAreaColor(0.4),
      zIndex: ZINDEX.AVOID_AREA,
    };
  }

  onChange(index, bounds) {
    let area = Object.assign({}, this.props.tabData.formData.avoidAreas[index]);
    let boundsStr = `${bounds.getTop().toFixed(6)},${bounds.getLeft().toFixed(6)};` +
                    `${bounds.getBottom().toFixed(6)},${bounds.getRight().toFixed(6)}`;
    this.props.avoidAreaUpdate({
      index,
      currentForm: this.props.currentForm.present,
      value: {
        isShown: area.isShown,
        value: boundsStr,
      },
    });
  }

  addLabel(bounds, areaIndex) {
    let text = mapMarkers.createText([bounds.getTop(), bounds.getRight()], areaIndex + 1, 'rf-map__avoid-area-number');
    this.getGroup().addObject(text);
    return text;
  }
}
